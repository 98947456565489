export default {
  "ar": {
    "a_day": "يوم واحد",
    "a_few_hours": "بضع ساعات",
    "a_few_minutes": "بضع دقائق",
    "a_week": "أسبوع",
    "active_state": "نشط",
    "ai_answer": "الإجابة مقدمة بواسطة الذكاء الاصطناعي.",
    "ai_answer_information": "معلومات عن إجابة الذكاء الاصطناعي",
    "ai_bot": "روبوت الذكاء الاصطناعي",
    "almost_there": "أوشكت على الانتهاء...",
    "answer": "الإجابة",
    "answer_bot_acknowledge_duplicate_content": ".لقد رأيت هذه الإجابة من قبل، لكنها أفضل إجابة متاحة",
    "answer_bot_all_done": "👍 لقد كان هذا مفيدًا",
    "answer_bot_ask_another_question": "🔁 اطرح سؤالًا آخر",
    "answer_bot_ask_something_else": "🔁 اطرح سؤالًا آخر",
    "answer_bot_connect_to_an_agent": "👤 تحدث مع مندوب خدمة العملاء",
    "answer_bot_did_that_help": "هل ساعدتك تلك الإجابة أم أنك تبحث عن شيء آخر؟",
    "answer_bot_followup_expectations_prefix": ".سيعاود الفريق التواصل معك بهذا بخصوص",
    "answer_bot_get_more_help": "🌐 مزيد من المساعدة",
    "answer_bot_get_more_help_followup": ":قد يساعدك هذا الرابط",
    "answer_bot_i_have_another_question": "🔁 لديَّ سؤال آخر",
    "answer_bot_im_not_done_yet": "🔁 لم أنتهي بعد",
    "answer_bot_keep_waiting": "كلا، لم تساعدني، وما زلت بحاجة إلى مساعدة من 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "يمكنك إعادة صياغة سؤالك، أو الانتقال بك إلى وسيلة مساعدة أخرى",
    "answer_bot_looping_first_no_answer": ".أنا آسف، لا أستطيع أن أجد جوابًا عن ذلك",
    "answer_bot_looping_first_no_answer_followup": "يمكنك إعادة صياغة سؤالك، أو التحدث مع أحد أعضاء الفريق لدينا",
    "answer_bot_looping_no_answer": "عذرًا، لا أستطيع أن أجد إجابة لذلك، إليك ما يمكنك فعله",
    "answer_bot_more_answers": "👀 عرض المزيد",
    "answer_bot_no_answer_found": ".يؤسفنا أننا ليست لدينا إجابة سريعة عن هذا. إليك بعض الخيارات الأخرى",
    "answer_bot_resolve_followup": ".يمكنك الرد هنا في أي وقت، حال حدوث أي شيء آخر",
    "answer_bot_talk_to_person": "👤 تحدَّث إلى أحد الأشخاص",
    "answer_bot_that_answered_my_question": "👍 أجاب هذا عن سؤالي",
    "answer_bot_thats_it": "👍 هذا هو المطلوب",
    "answer_bot_wait_for_the_team": "💬 انتظار الفريق",
    "answerbot_ask_for_more_context": "بالتأكيد. خلال فترة الانتظار، هل يمكنك مشاركة المزيد من التفاصيل عن مشكلتك أو سؤالك؟ هذا يساعدنا على الإجابة أسرع، وربما أتمكن من تقديم مزيد من المعلومات إليك.",
    "answerbot_introduce_sneaky_answer": "أشكرك على هذا السياق الإضافي؛ لقد وجدت بعض المعلومات الجديدة التي قد تساعدك.",
    "article_search_hint_text": "...ابحث المقالات",
    "ask_a_question": "أطرح سؤالًا",
    "ask_a_question_placeholder": "أطرح سؤالًا...",
    "ask_a_question_with_ellipsis": "اطرح سؤالا...",
    "ask_another_question_with_ellipsis": "اطرح سؤالا آخر...",
    "ask_for_the_team_if_needed": "اطلب التحدث لأحد أعضاء الفريق إن احتجت لذلك",
    "ask_me_a_question": "اطرح عليَّ سؤالاً",
    "ask_our_bot_a_question": "اطرح سؤالًا على الروبوت الخاص بنا",
    "avatar": "الصورة الرمزية",
    "avatars": "الصور الرمزية",
    "bot": "Bot",
    "bot_answers_instantly": "سوف يقوم الروبوت بالرد على الفور",
    "bot_expectation_text": "يستطيع الروبوت الخاص بنا الإجابة على العديد من الأسئلة الشائعة",
    "bot_intro_default_part_1": ".مرحبًا! أنا الروبوت، أتحدث إليك 👋 أنا هنا للإجابة عن أسئلتك، لكن يمكنك التحدث مع أحد أعضاء الفريق لدينا في أي وقت إن قررت ذلك",
    "bot_intro_default_part_2": "بماذا تحتاج المساعدة اليوم؟",
    "bot_is_still_thinking": "لا زال الروبوت يفكر...",
    "bot_is_thinking": "الربوت يفكر...",
    "browse_collections": "تصفح المجموعات",
    "chat_with_us": "دردش معنا",
    "check_later_for_updates": "للاطلاع على التحديثات؛ تحقق مرة أخرى لاحقًا.",
    "checklist_card_first_step": "الخطوة الأولى",
    "checklist_card_next_step": "الخطوة التالية",
    "checklist_completed": "اكتملت",
    "checklist_in_progress_time_remaining_plural": "{minutes} دقائق متبقية",
    "checklist_in_progress_time_remaining_singular": "دقيقة واحدة تقريبًا متبقية",
    "checklist_mark_step_as_complete": "ضع علامة كمكتملة",
    "checklist_not_started_time_remaining_plural": "{minutes} دقيقة تقريبًا",
    "checklist_not_started_time_remaining_singular": "دقيقة واحدة تقريبًا",
    "checklist_number_of_steps_plural": "{number_of_steps} خطوات",
    "checklist_number_of_steps_progress_plural": "اكتملت {steps_completed} خطوات من {total_steps}",
    "checklist_number_of_steps_progress_singular": "تم الانتهاء من خطوة واحدة من أصل {total_steps}",
    "checklist_number_of_steps_singular": "خطوة واحدة",
    "checklist_sender_name": "قام {name} بالمشاركة",
    "checklist_step_action_button": "زر إجراء الخطوة",
    "checklist_step_completed": "اكتملت",
    "contact_support": "الاتصال بفريق الدعم",
    "contact_us": "اتصل بنا",
    "continue_the_conversation": "إكمال المحادثة",
    "conversation_card_office_hours_header": "موعد عودتنا إلى الإنترنت",
    "conversation_card_reply_time_header": "وقت الرد المعتاد لدينا",
    "conversation_card_reply_time_header_m5": "سنعود: غدًا",
    "conversation_card_unknown_response_time": "سنرد بأقرب وقت ممكن",
    "conversation_part_sample_text": "مرحبًا، كيف يمكنني المساعدة؟ سيتوفر لك دائمًا خيار التحدث إلى فريقنا إذا لزم الأمر.",
    "conversations_space_title": "المحادثات",
    "create_a_ticket": "إنشاء تذكرة",
    "currently_no_help_articles": "لا توجد مقالات مساعدة متاحة في الوقت الحالي.",
    "customer_sends_their_first_message": "العميل بصدد إرسال رسالته الأولى...",
    "expected_response_delay_10800": "أقل من 3 ساعات",
    "expected_response_delay_120": "أقل من دقيقتين",
    "expected_response_delay_1200": "أقل من 20 دقيقة",
    "expected_response_delay_14400": "أقل من 4 ساعات",
    "expected_response_delay_172800": "يومان",
    "expected_response_delay_180": "أقل من 3 دقائق",
    "expected_response_delay_1800": "أقل من 30 دقيقة",
    "expected_response_delay_18000": "أقل من 5 ساعات",
    "expected_response_delay_21600": "أقل من 6 ساعات",
    "expected_response_delay_240": "أقل من 4 دقائق",
    "expected_response_delay_259200": "3 أيام",
    "expected_response_delay_300": "أقل من 5 دقائق",
    "expected_response_delay_345600": "4 أيام",
    "expected_response_delay_3600": "أقل من ساعة",
    "expected_response_delay_43200": "أقل من 12 ساعة",
    "expected_response_delay_432000": "5 أيام",
    "expected_response_delay_518400": "6 أيام",
    "expected_response_delay_60": "أقل من دقيقة واحدة",
    "expected_response_delay_600": "أقل من 10 دقائق",
    "expected_response_delay_604800": "أقل من أسبوع",
    "expected_response_delay_7200": "أقل من ساعتين",
    "expected_response_delay_86400": "يوم واحد",
    "expected_response_delay_900": "أقل من 15 دقيقة",
    "expected_response_delay_unknown": "في أقرب وقت ممكن لنا",
    "extra_labels_count": "+ {labels_count} عنوان",
    "for_best_results_provide_detail": "للحصول على أفضل النتائج؛ وفِّر أكبر قدر ممكن من المعلومات",
    "generating_answer": "جارٍ تكوين الإجابة...",
    "generic_triage_default": "كي نتمكن من تحويلك إلى الشخص المناسب نحن بحاجة إلى مزيد من المعلومات",
    "get_an_instant_answer": "احصل على إجابة فورية",
    "get_help": "الحصول على مساعدة",
    "go_to": "الانتقال إلى {app_name}",
    "help_space_title": "المساعدة",
    "home_header_mobile_hello_firstname": "مرحبًا يا {firstname}.",
    "home_header_mobile_hello_there": "مرحبًا",
    "home_space_title": "الصفحة الرئيسية",
    "how_can_we_help": "كيف يمكننا مساعدتك؟",
    "how_would_you_rate_this": "ما تقييمك لهذا؟",
    "learn_more": "تعرف على المزيد",
    "message_placeholder": "اكتب رسالة...",
    "messages_space_title": "الرسائل",
    "messenger_bot_reply_time": "وسيرد برنامج الروبوت عليها على الفور",
    "messenger_settings_introduction": "كيف يمكننا مساعدتك؟",
    "multiple_articles": "{total_articles} مقالات",
    "multiple_collections": "{total_collection} مجموعات",
    "new_conversation": "محادثة جديدة",
    "news": "الأخبار",
    "news_empty_title": "لا من أخبار حتى الآن",
    "news_latest_subtitle": "من فريق {team_name}",
    "news_latest_title": "آخر الأخبار",
    "news_older_title": "الأقدم",
    "news_space_title": "الأخبار",
    "newsfeed": "موجز الأخبار",
    "no_articles_to_display": "لا توجد مقالات حتى الآن",
    "no_help_articles_to_display": "لا توجد مقالات مساعدة",
    "no_results_for_searchterm": "لا توجد نتائج لعملية البحث {searchTerm}",
    "no_tasks": "لا من مهام",
    "no_tasks_available_currently": "لا من مهام متاحة حاليًا.",
    "ok_the_team_typically_replies_in_day": ".حسنًا، عادةً ما يرد الفريق في غضون يوم",
    "ok_the_team_typically_replies_in_hours": ".حسنًا، عادةً ما يرد الفريق في غضون بضع ساعات",
    "ok_the_team_typically_replies_in_minutes": ".حسنًا، عادةً ما يرد الفريق في غضون بضع دقائق",
    "ok_the_team_typically_replies_in_under_n_hours": ".ساعة {hours} حسنًا، عادةً ما يرد الفريق في أقل من",
    "ok_the_team_typically_replies_in_under_n_min": ".دقيقة {minutes} حسنًا، عادةً ما يرد الفريق في أقل من",
    "ok_the_team_typically_replies_in_week": "يرد الفريق عادةً في غضون أسبوع.",
    "our_bot_and_team_can_help": "يمكن للروبوت وفريق العمل لدينا مساعدتك",
    "our_bot_answers_instantly": "الروبوت الخاص بنا يردُ على الفور",
    "powered_by_intercom": "مشغل بواسطة Intercom",
    "previous_bot_hands_over": "محتوى من الروبوت السابق...",
    "proactive_expected_response_delay_10800": "عادةً ما يرد في أقل من 3 ساعات",
    "proactive_expected_response_delay_120": "يَردُّ عادةً في أقل من دقيقتين",
    "proactive_expected_response_delay_1200": "عادةً ما يرد في أقل من 20 دقيقة",
    "proactive_expected_response_delay_14400": "عادةً ما يرد في أقل من 4 ساعات",
    "proactive_expected_response_delay_172800": "عادةً ما يرد في غضون يومين",
    "proactive_expected_response_delay_180": "يَردُّ عادةً في أقل من 3 دقائق",
    "proactive_expected_response_delay_1800": "عادةً ما يرد في أقل من 30 دقيقة",
    "proactive_expected_response_delay_18000": "عادةً ما يرد في أقل من 5 ساعات",
    "proactive_expected_response_delay_21600": "عادةً ما يرد في أقل من 6 ساعات",
    "proactive_expected_response_delay_240": "يَردُّ عادةً في أقل من 4 دقائق",
    "proactive_expected_response_delay_259200": "يردُ عادةً في غضون 3 أيام",
    "proactive_expected_response_delay_300": "عادةً ما يرد في أقل من 5 دقائق",
    "proactive_expected_response_delay_345600": "يردُ عادةً في غضون 4 أيام",
    "proactive_expected_response_delay_3600": "عادةً ما يرد في أقل من ساعة",
    "proactive_expected_response_delay_43200": "يردُ عادةً في غضون 12 ساعة",
    "proactive_expected_response_delay_432000": "يردُ عادةً في غضون 5 أيام",
    "proactive_expected_response_delay_518400": "يردُ عادةً في غضون 6 أيام",
    "proactive_expected_response_delay_60": "يَردُّ عادةً في أقل من دقيقة واحدة",
    "proactive_expected_response_delay_600": "عادةً ما يرد في أقل من 10 دقائق",
    "proactive_expected_response_delay_604800": "عادةً ما يرد في أقل من أسبوع",
    "proactive_expected_response_delay_7200": "عادةً ما يرد في أقل من ساعتين",
    "proactive_expected_response_delay_86400": "عادةً ما يرد في غضون يوم",
    "proactive_expected_response_delay_900": "يَردُّ عادةً في أقل من 15 دقيقة",
    "proactive_expected_response_delay_unknown": "سيرد في أقرب وقت ممكن",
    "profile_location": "{time} في {location}",
    "recent_ticket": "التذكرة الأخيرة",
    "recent_tickets": "التذاكر الأخيرة",
    "search_browse_empty_state_text": ".المعذرة، لا أستطيع أن أجد جوابًا عن ذلك. حاول أن تُعيد صياغة مصطلح البحث الذي أدخلته",
    "search_for_help": "البحث عن مساعدة",
    "see_all": "رؤية الكل",
    "see_all_conversations": "مشاهدة جميع محادثاتك",
    "see_previous_conversations": "عرض محادثاتك السابقة",
    "send": "إرسال",
    "send_a_message": "…إرسال رسالة",
    "send_a_message_reply_time": "وقت الرد المعتاد هو",
    "send_us_a_message": "أرسل لنا رسالة",
    "set_expectations_bot_email_header": "ستتلقى الردود هنا وفي بريدك الإلكتروني:",
    "set_expectations_bot_office_hours_header": "متى سيعود الفريق:",
    "set_expectations_bot_sms_header": "ستتلقى الردود هنا ومن خلال رسائل SMS:",
    "set_expectations_replies_to_email": "ستصلك الردود هنا وعلى هذا العنوان: <b>{user_email}</b> .",
    "set_expectations_replies_to_email_without_bold_text": "ستصلك الردود هنا، وعلى: {user_email}",
    "set_expectations_team_reply_asap": "سيرد عليك الفريق المختص في أقرب وقت ممكن.",
    "shared_by": "تمت المشاركة بواسطة",
    "show_less": "عرض أقل",
    "single_article": "مقال واحد",
    "single_collection": "مجموعة واحدة",
    "source": "مصدر",
    "sources": "المصادر",
    "start_a_conversation": "بدء محادثة",
    "start_conversation": "بدء محادثة",
    "still_generating_answer": "عملية تكوين الإجابة لا زالت جارية...",
    "still_thinking": "ما زال التفكير جارٍ...",
    "still_working_on_it": "مازال العمل جاري علي اجابة",
    "tasks_space_title": "المهام",
    "team_name_proactive_expected_response_delay_unknown": "في أقرب وقت ممكن {app_name} سيرد",
    "teammate_changes_team_assignment_of_a_conversation": "غيّرَ زميلٌ إعدادات تعيين هذه المحادثة...",
    "tell_us_what_you_need": "أخبرنا بما تحتاج إلى مساعدة فيه، وسيبذل الروبوت الخاص بنا قصارى جهده ليقدم لك أفضل إجابة",
    "the_team_can_help_if_needed": "يستطيع الفريق تقديم مساعدة إذا لزم الأمر",
    "the_team_typically_replies_in_10800": "عادةً ما يرد الفريق في أقل من 3 ساعات",
    "the_team_typically_replies_in_120": "يَردُّ الفريق عادةً في أقل من دقيقتين",
    "the_team_typically_replies_in_1200": "عادةً ما يرد الفريق في أقل من 20 دقيقة",
    "the_team_typically_replies_in_14400": "عادةً ما يرد الفريق في أقل من 4 ساعات",
    "the_team_typically_replies_in_172800": "عادةً ما يرد الفريق في غضون يومين",
    "the_team_typically_replies_in_180": "يردُّ الفريق عادةً في أقل من 3 دقائق",
    "the_team_typically_replies_in_1800": "عادةً ما يرد الفريق في أقل من 30 دقيقة",
    "the_team_typically_replies_in_18000": "عادةً ما يرد الفريق في أقل من 5 ساعات",
    "the_team_typically_replies_in_21600": "عادةً ما يرد الفريق في أقل من 6 ساعات",
    "the_team_typically_replies_in_240": "يردُّ الفريق عادةً في أقل من 4 دقائق",
    "the_team_typically_replies_in_259200": "يردُ الفريق عادةً في أقل من 3 أيام",
    "the_team_typically_replies_in_300": "عادةً ما يرد الفريق في أقل من 5 دقائق",
    "the_team_typically_replies_in_345600": "يردُ الفريق عادةً في أقل من 4 أيام",
    "the_team_typically_replies_in_3600": "عادةً ما يرد الفريق في أقل من ساعة",
    "the_team_typically_replies_in_43200": "يردُ الفريق عادةً في أقل من 12 ساعة",
    "the_team_typically_replies_in_432000": "يردُ الفريق عادةً في أقل من 5 أيام",
    "the_team_typically_replies_in_518400": "يردُ الفريق عادةً في أقل من 6 أيام",
    "the_team_typically_replies_in_60": "يردُّ الفريق عادةً في أقل من دقيقة واحدة",
    "the_team_typically_replies_in_600": "عادةً ما يرد الفريق في أقل من 10 دقائق",
    "the_team_typically_replies_in_604800": "عادةً ما يرد الفريق في أقل من أسبوع",
    "the_team_typically_replies_in_7200": "عادةً ما يرد الفريق في أقل من ساعتين",
    "the_team_typically_replies_in_86400": "عادةً ما يرد الفريق في غضون يوم",
    "the_team_typically_replies_in_900": "يَردُّ الفريق عادةً في أقل من 15 دقيقة",
    "the_team_typically_replies_in_day": ".عادةً ما يرد الفريق في غضون يوم",
    "the_team_typically_replies_in_hours": ".عادةً ما يرد الفريق في غضون بضع ساعات",
    "the_team_typically_replies_in_minutes": ".عادةً ما يرد الفريق في غضون بضع دقائق",
    "the_team_typically_replies_in_under_n_hours": ".ساعة {hours} عادةً ما يرد الفريق في أقل من",
    "the_team_typically_replies_in_under_n_min": ".دقيقة {minutes} عادةً ما يرد الفريق في أقل من",
    "the_team_typically_replies_in_unknown": "سيرد الفريق في أقرب وقت ممكن",
    "the_team_typically_replies_in_week": "يرد الفريق عادةً في غضون أسبوع.",
    "thinking": "التفكير جارٍ...",
    "tickets_cta_text": "هل لديك أي سؤال عن هذه التذكرة؟",
    "tickets_have_a_question": "هل لديك سؤال بخصوص هذه المشكلة؟",
    "tickets_space_title": "التذاكر",
    "time_future_1h": "ساعة واحدة",
    "time_future_2h": "ساعتان",
    "time_future_30m": "30 دقيقة",
    "time_future_3h": "3 ساعات",
    "time_future_day_0": "الإثنين",
    "time_future_day_1": "الثلاثاء",
    "time_future_day_2": "الأربعاء",
    "time_future_day_3": "الخميس",
    "time_future_day_4": "الجمعة",
    "time_future_day_5": "السبت",
    "time_future_day_6": "الأحد",
    "time_future_later_today": "اليوم بوقت لاحق",
    "time_future_next_week": "الأسبوع التالي",
    "time_future_tomorrow": "غدًا",
    "try_use_different_keywords": "جرِّب استخدام كلمات مختلفة أو اكتب سؤالك كاملًا",
    "upfront_collection_form_close_out": ".يمكنك العودة هنا بأي وقت لمشاهدة الردود وإرسال التحديثات",
    "upfront_collection_form_introduction": ".قم بمشاركة بعض المحتويات مع أعضاء الفريق لمساعدتهم على الرد بشكل أسرع وأفضل",
    "upfront_collection_form_submission_confirmation": ".شكرًا على إرسال هذه المحتويات. لتوفير الوقت لاحقًا، لا تتردد بإضافة مزيد من التفاصيل الآن",
    "upfront_collection_form_subtitle": "إرسال بعض المحتويات إلى أعضاء الفريق",
    "upfront_collection_form_subtitle_submitted": "سيأخذون هذه المحتويات بعين الاعتبار",
    "upfront_collection_form_title": "الحصول على المساعدة بشكل أسرع",
    "upfront_collection_form_title_submitted": "!تم الإرسال إلى الفريق",
    "view_all": "عرض الكل",
    "view_conversation": "عرض المحادثة",
    "we_are_here_to_help": "نحن هنا لمساعدتك",
    "we_run_on_intercom": "يتم إجراء المحادثة من Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "عندما يغير أحد زملاء الفريق حالة المحادثة...",
    "working_on_it": "جاري العمل علي اجابة",
    "you_are_all_up_to_date": "وكان هذا كل شيء!",
    "your_checklists": "قوائم المراجعة الخاصة بك",
    "your_conversations": "محادثاتك",
    "your_recent_conversations": "المحادثات الأخيرة",
    "your_recent_messages": "الرسائل الأخيرة"
  },
  "bg": {
    "a_day": "Един ден",
    "a_few_hours": "Няколко часа",
    "a_few_minutes": "Няколко минути",
    "a_week": "Седмица",
    "active_state": "Активен",
    "ai_answer": "Отговор от изкуствен интелект",
    "ai_answer_information": "Отговор от изкуствен интелект, информация",
    "ai_bot": "Бот с изкуствен интелект",
    "almost_there": "Почти е готово...",
    "answer": "Отговор",
    "answer_bot_acknowledge_duplicate_content": "Вече сте виждали това съдържание, но това е най-подходящият наличен отговор.",
    "answer_bot_all_done": "Това ми помогна 👍",
    "answer_bot_ask_another_question": "Задайте друг въпрос 🔁",
    "answer_bot_ask_something_else": "Задайте друг въпрос 🔁",
    "answer_bot_connect_to_an_agent": "Свържете се с агент 👤",
    "answer_bot_did_that_help": "Отговорът помогна ли за разрешаване на проблема или не е точно това, което търсите?",
    "answer_bot_followup_expectations_prefix": "Екипът ще се свърже с Вас по този въпрос.",
    "answer_bot_get_more_help": "Допълнителна помощ 🌐",
    "answer_bot_get_more_help_followup": "Тази връзка може да помогне:",
    "answer_bot_i_have_another_question": "Имам друг въпрос 🔁",
    "answer_bot_im_not_done_yet": "Това не е всичко 🔁",
    "answer_bot_keep_waiting": "Не, все още се нуждая от помощ 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Можете да перифразирате въпроса или да получите допълнителна помощ",
    "answer_bot_looping_first_no_answer": "За съжаление, като бот, не можах да намеря отговор на този въпрос.",
    "answer_bot_looping_first_no_answer_followup": "Можете да перифразирате въпроса или да разговаряте с нашия екип",
    "answer_bot_looping_no_answer": "За съжаление, като бот, не можах да намеря отговор на този въпрос. Ето какво можете да направите",
    "answer_bot_more_answers": "Покажи ми още 👀",
    "answer_bot_no_answer_found": "За съжаление нямаме бърз отговор на този въпрос. Ето някои други възможности.",
    "answer_bot_resolve_followup": "Винаги можете да отговорите тук, ако се появи нещо друго.",
    "answer_bot_talk_to_person": "Говорете с човек 👤",
    "answer_bot_that_answered_my_question": "Това отговори на въпроса ми 👍",
    "answer_bot_thats_it": "Това е 👍",
    "answer_bot_wait_for_the_team": "Ще изчакам екипа 💬",
    "answerbot_ask_for_more_context": "Разбира се. Докато чакаме, бихте ли могли да споделите повече подробности за своя казус или въпрос? Така ще можем да отговорим по-бързо, а аз може да намеря повече информация, която да е от полза.",
    "answerbot_introduce_sneaky_answer": "Благодаря за този допълнителен контекст. Намерих нова информация, която може да Ви помогне.",
    "article_search_hint_text": "Търсене в статии...",
    "ask_a_question": "Задайте въпрос",
    "ask_a_question_placeholder": "Задайте въпрос...",
    "ask_a_question_with_ellipsis": "Задайте въпрос...",
    "ask_another_question_with_ellipsis": "Задайте друг въпрос...",
    "ask_for_the_team_if_needed": "Свържете се с екипа, ако е необходимо",
    "ask_me_a_question": "Задайте ми въпрос",
    "ask_our_bot_a_question": "Задайте въпрос на нашия бот",
    "avatar": "Аватар",
    "avatars": "Аватари",
    "bot": "Бот",
    "bot_answers_instantly": "Ботът отговаря незабавно",
    "bot_expectation_text": "Нашият бот може да отговори на много от често срещаните въпроси",
    "bot_intro_default_part_1": "Здравейте! Разговаряте с бот. Тук съм, за да отговоря на Вашите въпроси, но винаги можете да говорите и с нашия екип.",
    "bot_intro_default_part_2": "И така, защо сте тук днес?",
    "bot_is_still_thinking": "Ботът все още мисли...",
    "bot_is_thinking": "Ботът мисли...",
    "browse_collections": "Преглед на колекции",
    "chat_with_us": "Чат с нас",
    "check_later_for_updates": "Проверете отново по-късно за актуализации.",
    "checklist_card_first_step": "Първа стъпка",
    "checklist_card_next_step": "Следваща стъпка",
    "checklist_completed": "Завършен",
    "checklist_in_progress_time_remaining_plural": "Остават около {minutes} минути",
    "checklist_in_progress_time_remaining_singular": "Остава около 1 минута",
    "checklist_mark_step_as_complete": "Маркиране като завършена",
    "checklist_not_started_time_remaining_plural": "Около {minutes} минути",
    "checklist_not_started_time_remaining_singular": "Около 1 минута",
    "checklist_number_of_steps_plural": "{number_of_steps} стъпки",
    "checklist_number_of_steps_progress_plural": "Извършени {steps_completed} от общо {total_steps}",
    "checklist_number_of_steps_progress_singular": "Извършена 1 от общо {total_steps}",
    "checklist_number_of_steps_singular": "1 стъпка",
    "checklist_sender_name": "Споделен от {name}",
    "checklist_step_action_button": "Бутон за действие по стъпка",
    "checklist_step_completed": "Завършена",
    "contact_support": "Връзка с екипа за поддръжка",
    "contact_us": "Връзка с нас",
    "continue_the_conversation": "Продължаване на разговора",
    "conversation_card_office_hours_header": "Ще бъдем отново онлайн",
    "conversation_card_reply_time_header": "Обичайно време за отговор",
    "conversation_card_reply_time_header_m5": "Обичайно време за отговор",
    "conversation_card_unknown_response_time": "Ще отговорим при първа възможност",
    "conversation_part_sample_text": "Здравейте, с какво мога да помогна? Винаги може да говорите с нашия екип, ако е необходимо.",
    "conversations_space_title": "Разговори",
    "create_a_ticket": "Създаване на билет",
    "currently_no_help_articles": "В момента няма налични статии за помощ.",
    "customer_sends_their_first_message": "Клиентът изпраща първото си съобщение...с",
    "expected_response_delay_10800": "до 3 часа",
    "expected_response_delay_120": "до 2 минути",
    "expected_response_delay_1200": "до 20 минути",
    "expected_response_delay_14400": "до 4 часа",
    "expected_response_delay_172800": "2 дни",
    "expected_response_delay_180": "до 3 минути",
    "expected_response_delay_1800": "до 30 минути",
    "expected_response_delay_18000": "до 5 часа",
    "expected_response_delay_21600": "до 6 часа",
    "expected_response_delay_240": "до 4 минути",
    "expected_response_delay_259200": "3 дни",
    "expected_response_delay_300": "до 5 минути",
    "expected_response_delay_345600": "4 дни",
    "expected_response_delay_3600": "до 1 час",
    "expected_response_delay_43200": "до 12 часа",
    "expected_response_delay_432000": "5 дни",
    "expected_response_delay_518400": "6 дни",
    "expected_response_delay_60": "до 1 минута",
    "expected_response_delay_600": "до 10 минути",
    "expected_response_delay_604800": "до 1 седмица",
    "expected_response_delay_7200": "до 2 часа",
    "expected_response_delay_86400": "1 ден",
    "expected_response_delay_900": "до 15 минути",
    "expected_response_delay_unknown": "Възможно най-скоро",
    "extra_labels_count": "+ {labels_count} етикета",
    "for_best_results_provide_detail": "За най-добри резултати дайте възможно най-много подробности",
    "generating_answer": "Генерирам отговор...",
    "generic_triage_default": "Необходима ни е само още малко информация, за да Ви свържем с подходящия човек",
    "get_an_instant_answer": "Незабавен отговор",
    "get_help": "Търсене на помощ",
    "go_to": "Влезте в {app_name}",
    "help_space_title": "Помощ",
    "home_header_mobile_hello_firstname": "Здравейте, {firstname}.",
    "home_header_mobile_hello_there": "Здравейте",
    "home_space_title": "Начало",
    "how_can_we_help": "С какво можем да помогнем?",
    "how_would_you_rate_this": "Как бихте оценили оцените това?",
    "learn_more": "Научете повече",
    "message_placeholder": "Изпратете съобщение...",
    "messages_space_title": "Съобщения",
    "messenger_bot_reply_time": "Нашият бот ще отговори незабавно",
    "messenger_settings_introduction": "Как можем да помогнем?",
    "multiple_articles": "{total_articles} статии",
    "multiple_collections": "{total_collection} колекции",
    "new_conversation": "Нов разговор",
    "news": "Новини",
    "news_empty_title": "Все още няма новини",
    "news_latest_subtitle": "От екип {team_name}",
    "news_latest_title": "Най-новото от екип",
    "news_older_title": "По-стари",
    "news_space_title": "Новини",
    "newsfeed": "Новини",
    "no_articles_to_display": "Все още няма статии",
    "no_help_articles_to_display": "Няма статии за помощ",
    "no_results_for_searchterm": "Няма резултати за {searchTerm}",
    "no_tasks": "Няма задачи",
    "no_tasks_available_currently": "В момента няма налични задачи.",
    "ok_the_team_typically_replies_in_day": "ОК, екипът обикновено отговаря в рамките на деня.",
    "ok_the_team_typically_replies_in_hours": "ОК, екипът обикновено отговаря в рамките на няколко часа.",
    "ok_the_team_typically_replies_in_minutes": "ОК, екипът обикновено отговаря в рамките на няколко минути.",
    "ok_the_team_typically_replies_in_under_n_hours": "ОК, екипът обикновено отговаря след по-малко от {hours}часа.",
    "ok_the_team_typically_replies_in_under_n_min": "ОК, екипът обикновено отговаря след по-малко от {minutes}минути.",
    "ok_the_team_typically_replies_in_week": "ОК, екипът обикновено отговаря в рамките на седмица.",
    "our_bot_and_team_can_help": "Нашият бот и екип могат да помогнат",
    "our_bot_answers_instantly": "Нашият бот отговаря незабавно",
    "powered_by_intercom": "Предоставено от Intercom",
    "previous_bot_hands_over": "Съдържание от предишния бот...",
    "proactive_expected_response_delay_10800": "Обикновено отговаря след по-малко от 3 часа",
    "proactive_expected_response_delay_120": "Обикновено отговаря след по-малко от 2 минути",
    "proactive_expected_response_delay_1200": "Обикновено отговаря след по-малко от 20 минути",
    "proactive_expected_response_delay_14400": "Обикновено отговаря след по-малко от 4 часа",
    "proactive_expected_response_delay_172800": "Обикновено отговаря в рамките на 2 дни",
    "proactive_expected_response_delay_180": "Обикновено отговаря след по-малко от 3 минути",
    "proactive_expected_response_delay_1800": "Обикновено отговаря след по-малко от 30 минути",
    "proactive_expected_response_delay_18000": "Обикновено отговаря след по-малко от 5 часа",
    "proactive_expected_response_delay_21600": "Обикновено отговаря след по-малко от 6 часа",
    "proactive_expected_response_delay_240": "Обикновено отговаря след по-малко от 4 минути",
    "proactive_expected_response_delay_259200": "Обикновено отговаря в рамките на 3 дни",
    "proactive_expected_response_delay_300": "Обикновено отговаря след по-малко от 5 минути",
    "proactive_expected_response_delay_345600": "Обикновено отговаря в рамките на 4 дни",
    "proactive_expected_response_delay_3600": "Обикновено отговаря след по-малко от 1 час",
    "proactive_expected_response_delay_43200": "Обикновено отговаря в рамките на 12 часа",
    "proactive_expected_response_delay_432000": "Обикновено отговаря в рамките на 5 дни",
    "proactive_expected_response_delay_518400": "Обикновено отговаря в рамките на 6 дни",
    "proactive_expected_response_delay_60": "Обикновено отговаря след по-малко от 1 минута",
    "proactive_expected_response_delay_600": "Обикновено отговаря след по-малко от 10 минути",
    "proactive_expected_response_delay_604800": "Обикновено отговаря след по-малко от 1 седмица",
    "proactive_expected_response_delay_7200": "Обикновено отговаря след по-малко от 2 часа",
    "proactive_expected_response_delay_86400": "Обикновено отговаря в рамките на 1 ден",
    "proactive_expected_response_delay_900": "Обикновено отговаря след по-малко от 15 минути",
    "proactive_expected_response_delay_unknown": "Ще отговори възможно най-скоро",
    "profile_location": "{time} в {location}",
    "recent_ticket": "Последен билет",
    "recent_tickets": "Последни билети",
    "search_browse_empty_state_text": "За съжаление не успяхме да намерим резултати за това търсене. Опитайте да перифразирате търсенето.",
    "search_for_help": "Търсене на помощни статии",
    "see_all": "Показване на всички",
    "see_all_conversations": "Преглед на всички Ваши разговори",
    "see_previous_conversations": "Вижте предишните разговори",
    "send": "Изпращане",
    "send_a_message": "Изпращане на съобщение…",
    "send_a_message_reply_time": "Обичайното време за отговор е",
    "send_us_a_message": "Изпратете ни съобщение",
    "set_expectations_bot_email_header": "Ще получите отговорите тук или на своя имейл:",
    "set_expectations_bot_office_hours_header": "Екипът ще бъде отново на линия:",
    "set_expectations_bot_sms_header": "Ще получите отговорите тук или чрез SMS:",
    "set_expectations_replies_to_email": "Ще получите отговорите тук и на <b>{user_email}</b> .",
    "set_expectations_replies_to_email_without_bold_text": "Ще получите отговорите тук и на {user_email}.",
    "set_expectations_team_reply_asap": "Екипът ще отговори възможно най-скоро.",
    "shared_by": "Споделено от",
    "show_less": "Покажи по-малко",
    "single_article": "1 статия",
    "single_collection": "1 колекция",
    "source": "Източник",
    "sources": "Източници",
    "start_a_conversation": "Започване на разговор",
    "start_conversation": "Започнете разговор",
    "still_generating_answer": "Все още генерирам отговор...",
    "still_thinking": "Все още мисля...",
    "still_working_on_it": "Все още работя по въпроса...",
    "tasks_space_title": "Задачи",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} ще отговори възможно най-скоро.",
    "teammate_changes_team_assignment_of_a_conversation": "Колега от екипа зададе този разговор на друг екип...",
    "tell_us_what_you_need": "Кажете ни каква помощ ви е необходима и нашият bot ще се постарае да ви отговори",
    "the_team_can_help_if_needed": "Екипът също може да помогне, ако е необходимо",
    "the_team_typically_replies_in_10800": "Екипът обикновено отговаря след по-малко от 3 часа.",
    "the_team_typically_replies_in_120": "Екипът обикновено отговаря след по-малко от 2 минути",
    "the_team_typically_replies_in_1200": "Екипът обикновено отговаря след по-малко от 20 мин.",
    "the_team_typically_replies_in_14400": "Екипът обикновено отговаря след по-малко от 4 часа.",
    "the_team_typically_replies_in_172800": "Екипът обикновено отговаря след по-малко от 2 дни.",
    "the_team_typically_replies_in_180": "Екипът обикновено отговаря след по-малко от 3 минути",
    "the_team_typically_replies_in_1800": "Екипът обикновено отговаря след по-малко от 30 мин.",
    "the_team_typically_replies_in_18000": "Екипът обикновено отговаря след по-малко от 5 часа.",
    "the_team_typically_replies_in_21600": "Екипът обикновено отговаря след по-малко от 6 часа.",
    "the_team_typically_replies_in_240": "Екипът обикновено отговаря след по-малко от 4 минути",
    "the_team_typically_replies_in_259200": "Екипът обикновено отговаря в рамките на 3 дни",
    "the_team_typically_replies_in_300": "Екипът обикновено отговаря след по-малко от 5 мин.",
    "the_team_typically_replies_in_345600": "Екипът обикновено отговаря в рамките на 4 дни",
    "the_team_typically_replies_in_3600": "Екипът обикновено отговаря след по-малко от 1 час.",
    "the_team_typically_replies_in_43200": "Екипът обикновено отговаря след по-малко от 12 часа",
    "the_team_typically_replies_in_432000": "Екипът обикновено отговаря в рамките на 5 дни",
    "the_team_typically_replies_in_518400": "Екипът обикновено отговаря в рамките на 6 дни",
    "the_team_typically_replies_in_60": "Екипът обикновено отговаря след по-малко от 1 минута",
    "the_team_typically_replies_in_600": "Екипът обикновено отговаря след по-малко от 10 мин.",
    "the_team_typically_replies_in_604800": "Екипът обикновено отговаря след по-малко от 1 седмица.",
    "the_team_typically_replies_in_7200": "Екипът обикновено отговаря след по-малко от 2 часа.",
    "the_team_typically_replies_in_86400": "Екипът обикновено отговаря след по-малко от 1 ден.",
    "the_team_typically_replies_in_900": "Екипът обикновено отговаря след по-малко от 15 минути",
    "the_team_typically_replies_in_day": "Екипът обикновено отговаря в рамките на деня.",
    "the_team_typically_replies_in_hours": "Екипът обикновено отговаря в рамките на няколко часа.",
    "the_team_typically_replies_in_minutes": "Екипът обикновено отговаря в рамките на няколко минути.",
    "the_team_typically_replies_in_under_n_hours": "Екипът обикновено отговаря след по-малко от {hours} часа.",
    "the_team_typically_replies_in_under_n_min": "Екипът обикновено отговаря след по-малко от {minutes} минути.",
    "the_team_typically_replies_in_unknown": "Екипът ще отговори възможно най-скоро.",
    "the_team_typically_replies_in_week": "Екипът обикновено отговаря в рамките на седмица.",
    "thinking": "Мисля...",
    "tickets_cta_text": "Имате въпрос във връзка с този билет?",
    "tickets_have_a_question": "Имате въпрос във връзка с този проблем?",
    "tickets_space_title": "Билети",
    "time_future_1h": "след 1 час",
    "time_future_2h": "след 2 часа",
    "time_future_30m": "след 30 минути",
    "time_future_3h": "след 3 часа",
    "time_future_day_0": "понеделник",
    "time_future_day_1": "вторник",
    "time_future_day_2": "сряда",
    "time_future_day_3": "четвъртък",
    "time_future_day_4": "петък",
    "time_future_day_5": "събота",
    "time_future_day_6": "неделя",
    "time_future_later_today": "по-късно днес",
    "time_future_next_week": "следващата седмица",
    "time_future_tomorrow": "утре",
    "try_use_different_keywords": "Опитайте да използвате други ключови думи или въведете пълния текст на въпроса",
    "upfront_collection_form_close_out": "Може да се върнете тук по всяко време, за да видите отговорите и да изпратите допълнителна информация.",
    "upfront_collection_form_introduction": "Споделете контекст, за да помогнете на екипа да отговори по-точно и по-бързо.",
    "upfront_collection_form_submission_confirmation": "Благодарим Ви, че изпратихте контекст. За да спестите време по-късно, не се колебайте да добавите повече подробности сега.",
    "upfront_collection_form_subtitle": "Изпращане на контекст на екипа",
    "upfront_collection_form_subtitle_submitted": "Те ще вземат предвид този контекст",
    "upfront_collection_form_title": "По-бързо получаване на помощ",
    "upfront_collection_form_title_submitted": "Изпратено на екипа!",
    "view_all": "Преглед на всички",
    "view_conversation": "Преглед на разговора",
    "we_are_here_to_help": "Насреща сме да помогнем",
    "we_run_on_intercom": "Ние използваме Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Когато колега от екипа промени статуса на разговора...",
    "working_on_it": "Работя по въпроса...",
    "you_are_all_up_to_date": "Вече сте в крак с новостите!",
    "your_checklists": "Вашите контролни списъци",
    "your_conversations": "Вашите разговори",
    "your_recent_conversations": "Последни разговори",
    "your_recent_messages": "Последни съобщения"
  },
  "bn": {
    "a_day": "এক দিন",
    "a_few_hours": "কয়েক ঘণ্টা",
    "a_few_minutes": "কয়েক মিনিট",
    "a_week": "এক সপ্তাহ",
    "active_state": "সক্রিয়",
    "ai_answer": "AI উত্তর",
    "ai_answer_information": "AI উত্তর, তথ্য",
    "ai_bot": "AI Bot",
    "almost_there": "প্রায় পৌঁছে গেছি...",
    "answer": "উত্তর",
    "answer_bot_acknowledge_duplicate_content": "আপনি ইতিমধ্যে এটি দেখেছেন, তবে এটিই সেরা উপলভ্য উত্তর।",
    "answer_bot_all_done": "এটা সাহায্য করেছে👍",
    "answer_bot_ask_another_question": "আরেকটি প্রশ্ন জিজ্ঞাসা করুন🔁",
    "answer_bot_ask_something_else": "অন্য কিছু জিজ্ঞাসা করুন🔁",
    "answer_bot_connect_to_an_agent": "একজন এজেন্টের সাথে যুক্ত হন👤",
    "answer_bot_did_that_help": "এই উত্তরটি কি সাহায্য করেছে, নাকি আপনি অন্য কিছু খুঁজছেন?",
    "answer_bot_followup_expectations_prefix": "টিম এই বিষয়ে আপনার কাছে ফিরে আসবে।",
    "answer_bot_get_more_help": "আরও সহায়তা নিন 🌐",
    "answer_bot_get_more_help_followup": "এই লিঙ্কটি সাহায্য করতে পারে:",
    "answer_bot_i_have_another_question": "আমার আরেকটি প্রশ্ন আছে🔁",
    "answer_bot_im_not_done_yet": "আমি এখনও শেষ করিনি🔁",
    "answer_bot_keep_waiting": "না, আমার এখনও সাহায্য প্রয়োজন 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "আপনি আপনার প্রশ্ন অন্যভাবে লিখতে পারেন, বা অতিরিক্ত সাহায্য পেতে পারেন",
    "answer_bot_looping_first_no_answer": "দুঃখিত, একটি বট হিসাবে আমি এর কোনও উত্তর খুঁজে পাইনি।",
    "answer_bot_looping_first_no_answer_followup": "আপনি আপনার প্রশ্ন অন্যভাবে লিখতে পারেন, অথবা আমাদের টিমের সাথে কথা বলতে পারেন",
    "answer_bot_looping_no_answer": "দুঃখিত, বট হিসাবে আমি এর কোনো উত্তর খুঁজে পাইনি। আপনি যা করতে পারেন তা এখানে দেওয়া হয়েছে",
    "answer_bot_more_answers": "আমাকে আরও দেখান👀",
    "answer_bot_no_answer_found": "দুঃখিত, আমাদের কাছে এর জন্য কোনও চটজলদি উত্তর নেই। এখানে কিছু অন্যান্য বিকল্প আছে।",
    "answer_bot_resolve_followup": "আপনি সবসময় এখানে উত্তর দিতে পারেন, যদি অন্য কিছু আসে।",
    "answer_bot_talk_to_person": "একজন ব্যক্তির সাথে কথা বলুন👤",
    "answer_bot_that_answered_my_question": "এটি আমার প্রশ্নের উত্তর দিয়েছে👍",
    "answer_bot_thats_it": "ব্যাস এটাই👍",
    "answer_bot_wait_for_the_team": "টিমের জন্য অপেক্ষা করুন💬",
    "answerbot_ask_for_more_context": "অবশ্যই। আমরা অপেক্ষা করার সময়, আপনি কি আপনার পরিস্থিতি বা প্রশ্ন সম্পর্কে আরও বিশদ বিবরণ শেয়ার করতে পারেন? এটি আমাদের আরও দ্রুত একটি উত্তর খুঁজে পেতে সাহায্য করবে এবং আমি আপনার জন্য আরও তথ্য খুঁজে পেতে পারি।",
    "answerbot_introduce_sneaky_answer": "সেই অতিরিক্ত প্রসঙ্গটির জন্য ধন্যবাদ। আমি কিছু নতুন তথ্য পেয়েছি যা আপনাকে সাহায্য করতে পারে।",
    "article_search_hint_text": "নিবন্ধগুলি অনুসন্ধান করুন...",
    "ask_a_question": "একটি প্রশ্ন জিজ্ঞাসা করুন",
    "ask_a_question_placeholder": "একটি প্রশ্ন জিজ্ঞাসা করুন...",
    "ask_a_question_with_ellipsis": "একটি প্রশ্ন জিজ্ঞাসা করুন...",
    "ask_another_question_with_ellipsis": "আরেকটি প্রশ্ন জিজ্ঞাসা করুন...",
    "ask_for_the_team_if_needed": "প্রয়োজনে দলের সঙ্গে কথা বলতে পারেন",
    "ask_me_a_question": "আমাকে একটি প্রশ্ন জিজ্ঞাসা করুন",
    "ask_our_bot_a_question": "আমাদের বটকে একটি প্রশ্ন জিজ্ঞাসা করুন",
    "avatar": "অবতার",
    "avatars": "অবতারগুলি",
    "bot": "বট",
    "bot_answers_instantly": "বট তাত্ক্ষণিকভাবে উত্তর দেয়",
    "bot_expectation_text": "আমাদের বট অনেক সাধারণ প্রশ্নের উত্তর দিতে পারে",
    "bot_intro_default_part_1": "হাই! একটি বট কথা বলছে। আমি আপনার প্রশ্নগুলির উত্তর দেওয়ার জন্য এখানে আছি, কিন্তু আপনার সবসময়ই আমাদের টিমের সাথে কথা বলার বিকল্প থাকবে।",
    "bot_intro_default_part_2": "তাহলে আপনি আজ কিসের জন্য এখানে এসেছেন?",
    "bot_is_still_thinking": "বট এখনও ভাবছে...",
    "bot_is_thinking": "বট ভাবছে...",
    "browse_collections": "সংগ্রহগুলি ব্রাউজ করুন",
    "chat_with_us": "আমাদের সাথে চ্যাট করুন",
    "check_later_for_updates": "আপডেটের জন্য পরে আবার দেখে নিন।",
    "checklist_card_first_step": "প্রথম ধাপ",
    "checklist_card_next_step": "পরবর্তী ধাপ",
    "checklist_completed": "সম্পূর্ণ",
    "checklist_in_progress_time_remaining_plural": "প্রায় {minutes} মিনিট বাকি",
    "checklist_in_progress_time_remaining_singular": "প্রায় 1 মিনিট বাকি",
    "checklist_mark_step_as_complete": "সম্পূর্ণ হিসাবে চিহ্নিত করুন",
    "checklist_not_started_time_remaining_plural": "প্রায় {minutes} মিনিট",
    "checklist_not_started_time_remaining_singular": "প্রায় 1 মিনিট",
    "checklist_number_of_steps_plural": "{number_of_steps} টি ধাপ",
    "checklist_number_of_steps_progress_plural": "{total_steps} টির মধ্যে {steps_completed} টি সম্পন্ন হয়েছে",
    "checklist_number_of_steps_progress_singular": "{total_steps} টির মধ্যে 1 টি সম্পন্ন হয়েছে",
    "checklist_number_of_steps_singular": "1 টি ধাপ",
    "checklist_sender_name": "{name} দ্বারা শেয়ার করা হয়েছে",
    "checklist_step_action_button": "ধাপের অ্যাকশন বোতাম",
    "checklist_step_completed": "সম্পূর্ণ",
    "contact_support": "সহায়তার সাথে যোগাযোগ করুন",
    "contact_us": "আমাদের সাথে যোগাযোগ করুন",
    "continue_the_conversation": "কথোপকথন চালিয়ে যান",
    "conversation_card_office_hours_header": "আমরা অনলাইনে ফিরে আসব",
    "conversation_card_reply_time_header": "আমাদের উত্তরের স্বাভাবিক সময়",
    "conversation_card_reply_time_header_m5": "উত্তরের স্বাভাবিক সময়",
    "conversation_card_unknown_response_time": "আমরা যত তাড়াতাড়ি সম্ভব উত্তর দেব",
    "conversation_part_sample_text": "হাই, আমি কীভাবে সাহায্য করতে পারি? প্রয়োজন হলে, আমাদের দলের সাথে কথা বলার বিকল্প আপনার কাছে সর্বদাই থাকবে।",
    "conversations_space_title": "কথোপকথন",
    "create_a_ticket": "একটি টিকিট তৈরি করুন",
    "currently_no_help_articles": "বর্তমানে কোনও সাহায্য সহায়তা উপলভ্য নেই।",
    "customer_sends_their_first_message": "গ্রাহক তার প্রথম মেসেজ পাঠান...",
    "expected_response_delay_10800": "3 ঘণ্টার মধ্যে",
    "expected_response_delay_120": "2 মিনিটের মধ্যে",
    "expected_response_delay_1200": "20 মিনিটের মধ্যে",
    "expected_response_delay_14400": "4 ঘণ্টার মধ্যে",
    "expected_response_delay_172800": "2 দিন",
    "expected_response_delay_180": "3 মিনিটের মধ্যে",
    "expected_response_delay_1800": "30 মিনিটের মধ্যে",
    "expected_response_delay_18000": "5 ঘণ্টার মধ্যে",
    "expected_response_delay_21600": "6 ঘণ্টার মধ্যে",
    "expected_response_delay_240": "4 মিনিটের মধ্যে",
    "expected_response_delay_259200": "3 দিন",
    "expected_response_delay_300": "5 মিনিটের মধ্যে",
    "expected_response_delay_345600": "4 দিন",
    "expected_response_delay_3600": "1 ঘণ্টার মধ্যে",
    "expected_response_delay_43200": "12 ঘণ্টার মধ্যে",
    "expected_response_delay_432000": "5 দিন",
    "expected_response_delay_518400": "6 দিন",
    "expected_response_delay_60": "1 মিনিটের মধ্যে",
    "expected_response_delay_600": "10 মিনিটের মধ্যে",
    "expected_response_delay_604800": "1 সপ্তাহের মধ্যে",
    "expected_response_delay_7200": "2 ঘণ্টার মধ্যে",
    "expected_response_delay_86400": "1 দিন",
    "expected_response_delay_900": "15 মিনিটের মধ্যে",
    "expected_response_delay_unknown": "আমাদের পক্ষে যত তাড়াতাড়ি সম্ভব",
    "extra_labels_count": "+ {labels_count} টি লেবেল",
    "for_best_results_provide_detail": "সেরা ফলাফলের জন্য, যতটা সম্ভব বিবরণ দিন",
    "generating_answer": "উত্তর তৈরি করা হচ্ছে...",
    "generic_triage_default": "আপনাকে সঠিক ব্যক্তির সাথে যুক্ত করার জন্য আমাদের আরও কিছু তথ্যের প্রয়োজন",
    "get_an_instant_answer": "একটি তাৎক্ষণিক উত্তর পান",
    "get_help": "সাহায্য নিন",
    "go_to": "{app_name}-এ যান",
    "help_space_title": "সহায়তা",
    "home_header_mobile_hello_firstname": "হ্যালো, {firstname}।",
    "home_header_mobile_hello_there": "হ্যালো",
    "home_space_title": "হোম",
    "how_can_we_help": "আমরা কিভাবে সাহায্য করতে পারি?",
    "how_would_you_rate_this": "আপনি কিভাবে এটিকে রেট করবেন?",
    "learn_more": "আরও জানুন",
    "message_placeholder": "মেসেজ...",
    "messages_space_title": "মেসেজ",
    "messenger_bot_reply_time": "আমাদের বট তাৎক্ষণিকভাবে উত্তর দেবে",
    "messenger_settings_introduction": "আমরা কিভাবে সাহায্য করতে পারি?",
    "multiple_articles": "{total_articles} টি নিবন্ধ",
    "multiple_collections": "{total_collection} টি সংগ্রহ",
    "new_conversation": "নতুন কথোপকথন",
    "news": "নিউজ",
    "news_empty_title": "এখনও কোনও খবর নেই",
    "news_latest_subtitle": "টিম {team_name}-এর থেকে",
    "news_latest_title": "সাম্প্রতিকতম",
    "news_older_title": "পুরোনো",
    "news_space_title": "নিউজ",
    "newsfeed": "নিউজফিড",
    "no_articles_to_display": "এখনও কোনও নিবন্ধ নেই",
    "no_help_articles_to_display": "কোনও সহায়তা নিবন্ধ নেই",
    "no_results_for_searchterm": "{searchTerm} এর জন্য কোনও ফলাফল নেই",
    "no_tasks": "কোনও কাজ নেই",
    "no_tasks_available_currently": "বর্তমানে কোনও কাজ উপলভ্য নেই।",
    "ok_the_team_typically_replies_in_day": "ঠিক আছে, টিম সাধারণত একদিনের মধ্যে উত্তর দেয়।",
    "ok_the_team_typically_replies_in_hours": "ঠিক আছে, টিম সাধারণত কয়েক ঘণ্টার মধ্যে উত্তর দেয়।",
    "ok_the_team_typically_replies_in_minutes": "ঠিক আছে, টিম সাধারণত কয়েক মিনিটের মধ্যে উত্তর দেয়।",
    "ok_the_team_typically_replies_in_under_n_hours": "ঠিক আছে, টিম সাধারণত {hours} ঘণ্টার কম সময়ে উত্তর দেয়।",
    "ok_the_team_typically_replies_in_under_n_min": "ঠিক আছে, টিম সাধারণত {minutes} মিনিটের কম সময়ে উত্তর দেয়।",
    "ok_the_team_typically_replies_in_week": "ঠিক আছে, টিম সাধারণত এক সপ্তাহের মধ্যে উত্তর দেয়।",
    "our_bot_and_team_can_help": "আমাদের বট এবং দল সাহায্য করতে পারে",
    "our_bot_answers_instantly": "আমাদের বট তাত্ক্ষণিকভাবে উত্তর দেয়",
    "powered_by_intercom": "Intercom দ্বারা চালিত",
    "previous_bot_hands_over": "পূর্ববর্তী বটের কন্টেন্ট...",
    "proactive_expected_response_delay_10800": "সাধারণত 3 ঘণ্টার মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_120": "সাধারণত 2 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_1200": "সাধারণত 20 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_14400": "সাধারণত 4 ঘণ্টার মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_172800": "সাধারণত 2 দিনে উত্তর দেয়",
    "proactive_expected_response_delay_180": "সাধারণত 3 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_1800": "সাধারণত 30 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_18000": "সাধারণত 5 ঘণ্টার মধ্যে উত্তর দেয়়",
    "proactive_expected_response_delay_21600": "সাধারণত 6 ঘণ্টার মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_240": "সাধারণত 4 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_259200": "সাধারণত 3 দিনের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_300": "সাধারণত 5 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_345600": "সাধারণত 4 দিনের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_3600": "সাধারণত 1 ঘণ্টার মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_43200": "সাধারণত 12 ঘণ্টার মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_432000": "সাধারণত 5 দিনের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_518400": "সাধারণত 6 দিনের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_60": "সাধারণত 1 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_600": "সাধারণত 10 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_604800": "সাধারণত 1 সপ্তাহের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_7200": "সাধারণত 2 ঘণ্টার মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_86400": "সাধারণত 1 দিনের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_900": "সাধারণত 15 মিনিটের মধ্যে উত্তর দেয়",
    "proactive_expected_response_delay_unknown": "তারা যত তাড়াতাড়ি সম্ভব উত্তর দেবে",
    "profile_location": "{location} এ {time}",
    "recent_ticket": "সাম্প্রতিক টিকিট",
    "recent_tickets": "সাম্প্রতিক টিকিটগুলি",
    "search_browse_empty_state_text": "দুঃখিত, আমরা এর জন্য কোনো ফলাফল খুঁজে পাইনি। আপনার অনুসন্ধানটি অন্য শব্দে লেখার চেষ্টা করুন।",
    "search_for_help": "সহায়তার জন্য অনুসন্ধান করুন",
    "see_all": "সব দেখুন",
    "see_all_conversations": "আপনার সমস্ত কথোপকথন দেখুন",
    "see_previous_conversations": "আপনার পূর্ববর্তী কথোপকথনগুলি দেখুন",
    "send": "পাঠান",
    "send_a_message": "একটি মেসেজ পাঠান...",
    "send_a_message_reply_time": "উত্তরের স্বাভাবিক সময় হল",
    "send_us_a_message": "আমাদের একটি মেসেজ পাঠান",
    "set_expectations_bot_email_header": "আপনি এখানে এবং আপনার ইমেলে উত্তর পাবেন:",
    "set_expectations_bot_office_hours_header": "টিম ফিরে আসবে",
    "set_expectations_bot_sms_header": "আপনি এখানে এবং SMS এর মাধ্যমে উত্তর পাবেন:",
    "set_expectations_replies_to_email": "আপনি এখানে এবং <b>{user_email}</b>-এ উত্তর পাবেন।",
    "set_expectations_replies_to_email_without_bold_text": "আপনি এখানে এবং {user_email}-এ উত্তর পাবেন",
    "set_expectations_team_reply_asap": "টিম যত তাড়াতাড়ি সম্ভব জবাব দেবে।",
    "shared_by": "শেয়ার করেছেন",
    "show_less": "কম দেখান",
    "single_article": "1 টি নিবন্ধ",
    "single_collection": "1 টি সংগ্রহ",
    "source": "উত্স",
    "sources": "উত্স",
    "start_a_conversation": "একটি কথোপকথন শুরু করুন",
    "start_conversation": "একটি কথোপকথন শুরু করুন...",
    "still_generating_answer": "এখনও উত্তর তৈরি করা হচ্ছে...",
    "still_thinking": "এখনো ভাবছি...",
    "still_working_on_it": "এখনো এটা নিয়ে কাজ করা হচ্ছে...",
    "tasks_space_title": "কাজ",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} যত তাড়াতাড়ি সম্ভব উত্তর দেবে",
    "teammate_changes_team_assignment_of_a_conversation": "টিমের সতীর্থ কথোপকথনের অ্যাসাইনমেন্ট পরিবর্তন করেছেন...",
    "tell_us_what_you_need": "আপনার কী বিষয়ে সহায়তা প্রয়োজন তা আমাদের বলুন এবং আমাদের বট উত্তর দেওয়ার জন্য যথাসাধ্য চেষ্টা করবে",
    "the_team_can_help_if_needed": "প্রয়োজন হলে, টিম সাহায্য করতে পারে",
    "the_team_typically_replies_in_10800": "টিম সাধারণত 3 ঘণ্টার মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_120": "টিম সাধারণত 2 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_1200": "টিম সাধারণত 20 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_14400": "টিম সাধারণত 4 ঘণ্টার মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_172800": "টিম সাধারণত 2 দিনে উত্তর দেয়",
    "the_team_typically_replies_in_180": "টিম সাধারণত 3 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_1800": "টিম সাধারণত 30 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_18000": "টিম সাধারণত 5 ঘণ্টার মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_21600": "টিম সাধারণত 6 ঘণ্টার মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_240": "টিম সাধারণত 4 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_259200": "টিম সাধারণত 3 দিনের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_300": "টিম সাধারণত 5 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_345600": "টিম সাধারণত 4 দিনের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_3600": "টিম সাধারণত 1 ঘণ্টার মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_43200": "টিম সাধারণত 12 ঘন্টার নিচে উত্তর দেয়",
    "the_team_typically_replies_in_432000": "টিম সাধারণত 5 দিনের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_518400": "টিম সাধারণত 6 দিনের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_60": "টিম সাধারণত 1 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_600": "টিম সাধারণত 10 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_604800": "টিম সাধারণত 1 সপ্তাহের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_7200": "টিম সাধারণত 2 ঘণ্টার মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_86400": "টিম সাধারণত 1 দিনে উত্তর দেয়",
    "the_team_typically_replies_in_900": "টিম সাধারণত 15 মিনিটের মধ্যে উত্তর দেয়",
    "the_team_typically_replies_in_day": "টিম সাধারণত একদিনে উত্তর দেয়।",
    "the_team_typically_replies_in_hours": "টিম সাধারণত কয়েক ঘণ্টার মধ্যে উত্তর দেয়।",
    "the_team_typically_replies_in_minutes": "টিম সাধারণত কয়েক মিনিটের মধ্যে উত্তর দেয়।",
    "the_team_typically_replies_in_under_n_hours": "টিম সাধারণত {hours} ঘণ্টার মধ্যে উত্তর দেয়।",
    "the_team_typically_replies_in_under_n_min": "টিম সাধারণত {minutes} মিনিটের মধ্যে উত্তর দেয়।",
    "the_team_typically_replies_in_unknown": "টিম যত তাড়াতাড়ি সম্ভব জবাব দেবে",
    "the_team_typically_replies_in_week": "টিমটি সাধারণত এক সপ্তাহের মধ্যে উত্তর দেয়।",
    "thinking": "ভাবছি...",
    "tickets_cta_text": "এই টিকিট সম্পর্কে কোনো প্রশ্ন আছে কি?",
    "tickets_have_a_question": "এই সমস্যা সম্পর্কে কোনো প্রশ্ন আছে কি?",
    "tickets_space_title": "টিকিট",
    "time_future_1h": "1 ঘণ্টার মধ্যে",
    "time_future_2h": "2 ঘণ্টার মধ্যে",
    "time_future_30m": "30 মিনিটের মধ্যে",
    "time_future_3h": "3 ঘণ্টার মধ্যে",
    "time_future_day_0": "সোমবার",
    "time_future_day_1": "মঙ্গলবার",
    "time_future_day_2": "বুধবার",
    "time_future_day_3": "বৃহস্পতিবার",
    "time_future_day_4": "শুক্রবার",
    "time_future_day_5": "শনিবার",
    "time_future_day_6": "রবিবার",
    "time_future_later_today": "আজ পরে",
    "time_future_next_week": "আগামী সপ্তাহে",
    "time_future_tomorrow": "আগামীকাল",
    "try_use_different_keywords": "ভিন্ন কীওয়ার্ডগুলি চেষ্টা করে দেখুন বা আপনার পুরো প্রশ্নটি টাইপ করুন",
    "upfront_collection_form_close_out": "আপনি প্রতিক্রিয়াগুলি দেখতে এবং আপডেট পাঠাতে যে কোনো সময় এখানে ফিরে আসতে পারেন।",
    "upfront_collection_form_introduction": "টিমকে আরও ভাল এবং আরও দ্রুত উত্তর দিতে সাহায্য করার জন্য কিছু প্রসঙ্গ শেয়ার করুন।",
    "upfront_collection_form_submission_confirmation": "সেই প্রসঙ্গটি পাঠানোর জন্য ধন্যবাদ। পরে সময় বাঁচাতে, এখনই নির্দ্বিধায় আরও বিশদ বিবরণ যোগ করুন।",
    "upfront_collection_form_subtitle": "টিমকে কিছু প্রসঙ্গ পাঠান",
    "upfront_collection_form_subtitle_submitted": "তারা এই প্রসঙ্গটি মনে রাখবে",
    "upfront_collection_form_title": "দ্রুত সহায়তা নিন",
    "upfront_collection_form_title_submitted": "টিমকে পাঠানো হয়েছে!",
    "view_all": "সবগুলি দেখুন",
    "view_conversation": "কথোপকথন দেখুন",
    "we_are_here_to_help": "আমরা এখানে সাহায্য করতে রয়েছি",
    "we_run_on_intercom": "আমরা Intercom-এ চালিত হই",
    "when_a_teammate_changes_the_state_of_a_conversation": "যখন একজন টিমমেট কথোপকথনের অবস্থা পরিবর্তন করে...",
    "working_on_it": "এটা নিয়ে কাজ করা হচ্ছে...",
    "you_are_all_up_to_date": "আপনি প্রয়োজনীয় কাজটি করে ফেলেছেন!",
    "your_checklists": "আপনার চেকলিস্ট",
    "your_conversations": "আপনার কথোপকথন",
    "your_recent_conversations": "সাম্প্রতিক কথোপকথন",
    "your_recent_messages": "সাম্প্রতিক মেসেজ"
  },
  "bs": {
    "a_day": "Jedan dan",
    "a_few_hours": "Par sati",
    "a_few_minutes": "Par minuta",
    "a_week": "Jedna sedmica",
    "active_state": "Активан",
    "ai_answer": "AI odgovor",
    "ai_answer_information": "AI odgovor, informacije",
    "ai_bot": "AI bot",
    "almost_there": "Skoro je gotovo...",
    "answer": "Odgovor",
    "answer_bot_acknowledge_duplicate_content": "Ovo ste već vidjeli, ali ovo je najbolji dostupni odgovor.",
    "answer_bot_all_done": "To je pomoglo 👍",
    "answer_bot_ask_another_question": "Postavite drugo pitanje 🔁",
    "answer_bot_ask_something_else": "Pitajte nešto drugo 🔁",
    "answer_bot_connect_to_an_agent": "Povežite se s agentom 👤",
    "answer_bot_did_that_help": "Je li taj odgovor pomogao ili tražite nešto drugo?",
    "answer_bot_followup_expectations_prefix": "Tim će da vas kontaktira u vezi ovoga.",
    "answer_bot_get_more_help": "Dobijte dodatnu pomoć 🌐",
    "answer_bot_get_more_help_followup": "Mogla bi pomoći ova veza:",
    "answer_bot_i_have_another_question": "Imam drugo pitanje 🔁",
    "answer_bot_im_not_done_yet": "Još nisam završio 🔁",
    "answer_bot_keep_waiting": "Ne, i dalje mi treba pomoć 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Možete preformulisati svoje pitanje ili dobiti dodatnu pomoć",
    "answer_bot_looping_first_no_answer": "Žao mi je, kao bot nisam uspio pronaći odgovor na to.",
    "answer_bot_looping_first_no_answer_followup": "Možete preformulisati svoje pitanje ili razgovarati sa našim timom",
    "answer_bot_looping_no_answer": "Žao mi je, kao bot nisam uspio pronaći odgovor na to. Evo šta možete uraditi",
    "answer_bot_more_answers": "Prikaži mi više 👀",
    "answer_bot_no_answer_found": "Žao nam je, nemamo brz odgovor na to. Ovdje su neke opcije.",
    "answer_bot_resolve_followup": "Uvijek možete odgovoriti ovdje, ukoliko se pojavi još nešto.",
    "answer_bot_talk_to_person": "Razgovarajte s osobom 👤",
    "answer_bot_that_answered_my_question": "To je bio odgovor na moje pitanje 👍",
    "answer_bot_thats_it": "To je to 👍",
    "answer_bot_wait_for_the_team": "Pričekajte tim 💬",
    "answerbot_ask_for_more_context": "Naravno. Dok čekamo, možete li podijeliti više pojedinosti o svojoj situaciji ili postaviti pitanje? To će nam pomoći da brže pronađemo odgovor, a možda ću pronaći više informacija za vas.",
    "answerbot_introduce_sneaky_answer": "Hvala na tom dodatnom kontekstu. Našao sam neke nove informacije koje bi vam mogle pomoći.",
    "article_search_hint_text": "Pretraži članke...",
    "ask_a_question": "Postavite pitanje",
    "ask_a_question_placeholder": "Postavite pitanje...",
    "ask_a_question_with_ellipsis": "Postavite pitanje...",
    "ask_another_question_with_ellipsis": "Postavite još jedno pitanje...",
    "ask_for_the_team_if_needed": "Zatražite tim ako je potrebno",
    "ask_me_a_question": "Postavite mi pitanje",
    "ask_our_bot_a_question": "Postavite pitanjem našem botu",
    "avatar": "Avatar",
    "avatars": "Avatari",
    "bot": "Bot",
    "bot_answers_instantly": "Bot odgovara odmah",
    "bot_expectation_text": "Naš bot može odgovoriti na brojna pitanja",
    "bot_intro_default_part_1": "Zdravo! Ovo govori bot. Ovdje sam kako bih odgovorio na vaša pitanja, ali uvijek imate mogućnost da razgovarate sa našim timom.",
    "bot_intro_default_part_2": "Šta vas danas dovodi ovdje?",
    "bot_is_still_thinking": "Bot još uvek razmišlja...",
    "bot_is_thinking": "Bot razmišlja...",
    "browse_collections": "Pregledajte kolekcije",
    "chat_with_us": "Razgovarajte s nama",
    "check_later_for_updates": "Provjerite kasnije ima li novosti.",
    "checklist_card_first_step": "Prvi korak",
    "checklist_card_next_step": "Sljedeći korak",
    "checklist_completed": "Završeno",
    "checklist_in_progress_time_remaining_plural": "Preostalo oko {minutes} minuta",
    "checklist_in_progress_time_remaining_singular": "Preostalo je oko 1 minute",
    "checklist_mark_step_as_complete": "Označi kao završeno",
    "checklist_not_started_time_remaining_plural": "Oko {minutes} minuta",
    "checklist_not_started_time_remaining_singular": "Oko 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} koraka",
    "checklist_number_of_steps_progress_plural": "{steps_completed} od {total_steps} gotovo",
    "checklist_number_of_steps_progress_singular": "1 od {total_steps} gotovo",
    "checklist_number_of_steps_singular": "1 korak",
    "checklist_sender_name": "Listu šalje {name}",
    "checklist_step_action_button": "Dugme za korak radnje",
    "checklist_step_completed": "Završeno",
    "contact_support": "Kontaktirajte podršku",
    "contact_us": "Kontaktirajte nas",
    "continue_the_conversation": "Nastavite razgovor",
    "conversation_card_office_hours_header": "Ponovno smo online",
    "conversation_card_reply_time_header": "Naše uobičajeno vrijeme odgovaranja",
    "conversation_card_reply_time_header_m5": "Uobičajeno vrijeme za odgovor",
    "conversation_card_unknown_response_time": "Odgovorićemo što prije budemo mogli",
    "conversation_part_sample_text": "Zdravo, kako vam mogu pomoći? Uvijek ćete imati mogućnost da razgovarate s našim timom, ako je potrebno.",
    "conversations_space_title": "Razgovori",
    "create_a_ticket": "Kreiraj tiket",
    "currently_no_help_articles": "Trenutno nema dostupnih članaka za pomoć.",
    "customer_sends_their_first_message": "Kupac šalje svoju prvu poruku...",
    "expected_response_delay_10800": "manje od 3 sata",
    "expected_response_delay_120": "manje od 2 minuta",
    "expected_response_delay_1200": "manje od 20 minuta",
    "expected_response_delay_14400": "manje od 4 sata",
    "expected_response_delay_172800": "manje od 2 dana",
    "expected_response_delay_180": "manje od 3 minuta",
    "expected_response_delay_1800": "manje od 30 minuta",
    "expected_response_delay_18000": "manje od 5 sati",
    "expected_response_delay_21600": "manje od 6 sati",
    "expected_response_delay_240": "manje od 4 minuta",
    "expected_response_delay_259200": "3 dana",
    "expected_response_delay_300": "manje od 5 minuta",
    "expected_response_delay_345600": "4 dana",
    "expected_response_delay_3600": "manje od 1 sat",
    "expected_response_delay_43200": "manje od 12 sati",
    "expected_response_delay_432000": "5 dana",
    "expected_response_delay_518400": "6 dana",
    "expected_response_delay_60": "manje od 1 minut",
    "expected_response_delay_600": "manje od 10 minuta",
    "expected_response_delay_604800": "manje od 1 sedmice",
    "expected_response_delay_7200": "manje od 2 sata",
    "expected_response_delay_86400": "1 dan",
    "expected_response_delay_900": "manje od 15 minuta",
    "expected_response_delay_unknown": "Čim budemo u mogućnosti",
    "extra_labels_count": "+ {labels_count} oznake",
    "for_best_results_provide_detail": "Za najbolje rezultate navedite što više podataka",
    "generating_answer": "Generišem odgovor...",
    "generic_triage_default": "Potrebno nam je samo još malo informacija kako bismo vas povezali s pravom osobom",
    "get_an_instant_answer": "Odmah dobijte odgovor",
    "get_help": "Potražite pomoć",
    "go_to": "Idi na {app_name}",
    "help_space_title": "Pomoć",
    "home_header_mobile_hello_firstname": "Zdravo, {firstname}.",
    "home_header_mobile_hello_there": "Zdravo",
    "home_space_title": "Početna",
    "how_can_we_help": "Kako vam možemo po moći?",
    "how_would_you_rate_this": "Kako biste ovo ocijenili?",
    "learn_more": "Saznajte više",
    "message_placeholder": "Poruka...",
    "messages_space_title": "Poruke",
    "messenger_bot_reply_time": "Naš robot će odmah odgovoriti",
    "messenger_settings_introduction": "Kako vam možemo po moći?",
    "multiple_articles": "{total_articles} članaka",
    "multiple_collections": "{total_collection} kolekcije",
    "new_conversation": "Novi razgovor",
    "news": "Novosti",
    "news_empty_title": "Još nema novosti",
    "news_latest_subtitle": "Iz tima {team_name}",
    "news_latest_title": "Najnoviji",
    "news_older_title": "Stariji",
    "news_space_title": "Novosti",
    "newsfeed": "Sažetak sadržaja vijesti",
    "no_articles_to_display": "Još nema članaka",
    "no_help_articles_to_display": "Nema članaka za pomoć",
    "no_results_for_searchterm": "Nema rezultata za {searchTerm}",
    "no_tasks": "Nema zadataka",
    "no_tasks_available_currently": "Trenutno nema dostupnih zadataka.",
    "ok_the_team_typically_replies_in_day": "У реду, тим обично одговори за један дан.",
    "ok_the_team_typically_replies_in_hours": "У реду, тим обично одговори за неколико сати.",
    "ok_the_team_typically_replies_in_minutes": "У реду, тим обично одговори за неколико минута.",
    "ok_the_team_typically_replies_in_under_n_hours": "У реду, тим обично одговори за манје од {hours} h.",
    "ok_the_team_typically_replies_in_under_n_min": "У реду, тим обично одговори за манје од {minutes} мин.",
    "ok_the_team_typically_replies_in_week": "U redu, tim obično odgovori tokom jedne sedmice.",
    "our_bot_and_team_can_help": "Naš bot i tim mogu da pomognu",
    "our_bot_answers_instantly": "Naš bot odgovara odmah",
    "powered_by_intercom": "Koristimo tehnologiju kompanije Intercom",
    "previous_bot_hands_over": "Sadržaj od prethodnog bota...",
    "proactive_expected_response_delay_10800": "Обично одговори за мање од 3(три) сата",
    "proactive_expected_response_delay_120": "Оbično odgovori za manje od 2 min.",
    "proactive_expected_response_delay_1200": "Обично одговори за мање од 20 мин.",
    "proactive_expected_response_delay_14400": "Обично одговори за мање од 4(четири) сата",
    "proactive_expected_response_delay_172800": "Обично одговори за 2(два) дана",
    "proactive_expected_response_delay_180": "Оbično odgovori za manje od 3 min.",
    "proactive_expected_response_delay_1800": "Обично одговори за мање од 30 мин.",
    "proactive_expected_response_delay_18000": "Обично одговори за мање од 5(пет) сати",
    "proactive_expected_response_delay_21600": "Обично одговори за мање од 6(шест) сати",
    "proactive_expected_response_delay_240": "Оbično odgovori za manje od 4 min.",
    "proactive_expected_response_delay_259200": "Obično odgovori u roku od 3 dana",
    "proactive_expected_response_delay_300": "Обично одговори за мање од 5 мин.",
    "proactive_expected_response_delay_345600": "Obično odgovori u roku od 4 dana",
    "proactive_expected_response_delay_3600": "Обично одговори за мање од 1(једног) сата",
    "proactive_expected_response_delay_43200": "Obično odgovori u roku od 12 sati",
    "proactive_expected_response_delay_432000": "Obično odgovori u roku od 5 dana",
    "proactive_expected_response_delay_518400": "Obično odgovori u roku od 6 dana",
    "proactive_expected_response_delay_60": "Оbično odgovori za manje od 1 min.",
    "proactive_expected_response_delay_600": "Обично одговори за мање од 10 мин.",
    "proactive_expected_response_delay_604800": "Обично одговори за мање од 1(једне) седмице",
    "proactive_expected_response_delay_7200": "Обично одговори за мање од 2(два) сата",
    "proactive_expected_response_delay_86400": "Обично одговори за 1(један) дан",
    "proactive_expected_response_delay_900": "Оbično odgovori za manje od 15 min.",
    "proactive_expected_response_delay_unknown": "Ће одговорити, што је прије буду у могућности",
    "profile_location": "{time} u {location}",
    "recent_ticket": "Nedavni tiket",
    "recent_tickets": "Nedavni tiketi",
    "search_browse_empty_state_text": "Žao nam je, nismo uspjeli naći ni jedan rezultat za to. Pokušajte preformulisati pretragu.",
    "search_for_help": "Potražite pomoć",
    "see_all": "Pregledaj sve",
    "see_all_conversations": "Pogledajte sve vaše razgovore",
    "see_previous_conversations": "Pogledajte svoje prethodne razgovore",
    "send": "Pošalji",
    "send_a_message": "Pošalji poruku…",
    "send_a_message_reply_time": "Uobičajeno vrijeme za odgovor je",
    "send_us_a_message": "Pošaljite nam poruku",
    "set_expectations_bot_email_header": "Odgovore ćete dobiti ovdje i na vašu e-poštu:",
    "set_expectations_bot_office_hours_header": "Kada će se tim vratiti:",
    "set_expectations_bot_sms_header": "Odgovore ćete dobiti ovdje i SMS-om:",
    "set_expectations_replies_to_email": "Dobićete odgovore ovdje i na <b>{user_email}</b> .",
    "set_expectations_replies_to_email_without_bold_text": "Dobićete odgovore ovdje i na {user_email}",
    "set_expectations_team_reply_asap": "Tim će odgovoriti što prije bude mogao.",
    "shared_by": "Podijelio",
    "show_less": "Prikaži manje",
    "single_article": "1 članak",
    "single_collection": "1 kolekcija",
    "source": "Izvor",
    "sources": "Izvori",
    "start_a_conversation": "Započnite razgovor",
    "start_conversation": "Započni razgovor",
    "still_generating_answer": "Još uvijek generišem odgovor...",
    "still_thinking": "Još uvijek razmišljam...",
    "still_working_on_it": "Još uvijek radim na tome...",
    "tasks_space_title": "Zadaci",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} će odgovoriti, što prije bude u mogućnosti",
    "teammate_changes_team_assignment_of_a_conversation": "Timski kolega je promijenio zadatak razgovora...",
    "tell_us_what_you_need": "Recite nam oko čega vam je potrebna pomoć i naš bot će se potruditi da odgovori",
    "the_team_can_help_if_needed": "Tim može pomoći, ako je potrebno",
    "the_team_typically_replies_in_10800": "Tim obično odgovori za manje od 3 sata",
    "the_team_typically_replies_in_120": "Tim obično odgovori za manje od 2 min.",
    "the_team_typically_replies_in_1200": "Tim obično odgovori za manje od 20 min.",
    "the_team_typically_replies_in_14400": "Tim obično odgovori za manje od 4 sata",
    "the_team_typically_replies_in_172800": "Tim obično odgovori za 2 dana",
    "the_team_typically_replies_in_180": "Tim obično odgovori za manje od 3 min.",
    "the_team_typically_replies_in_1800": "Tim obično odgovori za manje od 30 min.",
    "the_team_typically_replies_in_18000": "Tim obično odgovori za manje od 5 sati",
    "the_team_typically_replies_in_21600": "Tim obično odgovori za manje od 6 sati",
    "the_team_typically_replies_in_240": "Tim obično odgovori za manje od 4 min.",
    "the_team_typically_replies_in_259200": "Tim obično odgovori u roku od 3 dana",
    "the_team_typically_replies_in_300": "Tim obično odgovori za manje od 5 min.",
    "the_team_typically_replies_in_345600": "Tim obično odgovori u roku od 4 dana",
    "the_team_typically_replies_in_3600": "Tim obično odgovori za manje od 1 sata",
    "the_team_typically_replies_in_43200": "Tim obično odgovori za manje od 12 sati",
    "the_team_typically_replies_in_432000": "Tim obično odgovori u roku od 5 dana",
    "the_team_typically_replies_in_518400": "Tim obično odgovori u roku od 6 dana",
    "the_team_typically_replies_in_60": "Tim obično odgovori za manje od 1 min.",
    "the_team_typically_replies_in_600": "Tim obično odgovori za manje od 10 min.",
    "the_team_typically_replies_in_604800": "Tim obično odgovori za manje od 1 sedmice",
    "the_team_typically_replies_in_7200": "Tim obično odgovori za manje od 2 sata",
    "the_team_typically_replies_in_86400": "Tim obično odgovori za 1 dan",
    "the_team_typically_replies_in_900": "Tim obično odgovori za manje od 15 min.",
    "the_team_typically_replies_in_day": "Тим обично одговори за један дан.",
    "the_team_typically_replies_in_hours": "Тим обично одговори за неколико сати.",
    "the_team_typically_replies_in_minutes": "Тим обично одговори за неколико минута.",
    "the_team_typically_replies_in_under_n_hours": "Тим обично одговори за манје од {hours} h.",
    "the_team_typically_replies_in_under_n_min": "Тим обично одговори за манје од {minutes} мин.",
    "the_team_typically_replies_in_unknown": "Tim će odgovoriti, što prije bude u mogućnosti",
    "the_team_typically_replies_in_week": "Tim obično odgovori tokom jedne sedmice.",
    "thinking": "Razmišljam...",
    "tickets_cta_text": "Imate neko pitanje o ovom tiketu?",
    "tickets_have_a_question": "Imate neko pitanje o ovome?",
    "tickets_space_title": "Tiketi",
    "time_future_1h": "1 sat",
    "time_future_2h": "2 sata",
    "time_future_30m": "30 minuta",
    "time_future_3h": "3 sata",
    "time_future_day_0": "Ponedjeljak",
    "time_future_day_1": "Utorak",
    "time_future_day_2": "Srijeda",
    "time_future_day_3": "Četvrtak",
    "time_future_day_4": "Petak",
    "time_future_day_5": "Subota",
    "time_future_day_6": "Nedjelja",
    "time_future_later_today": "Danas kasnije",
    "time_future_next_week": "Sljedeće sedmice",
    "time_future_tomorrow": "Sutra",
    "try_use_different_keywords": "Pokušajte s drugim ključnim riječima ili upišite cijelo pitanje",
    "upfront_collection_form_close_out": "Ovdje se možete vratiti kad god želite kako biste vidjeli odgovore i poslali novosti.",
    "upfront_collection_form_introduction": "Podijelite neki kontekst kako biste pomogli timu da bolje i brže reaguje.",
    "upfront_collection_form_submission_confirmation": "Hvala što ste poslali taj kontekst. Kako biste kasnije uštedjeli na vremenu, slobodno sada dodajte još pojedinosti.",
    "upfront_collection_form_subtitle": "Pošaljite timu neki kontekst",
    "upfront_collection_form_subtitle_submitted": "Oni će taj kontekst držati na umu",
    "upfront_collection_form_title": "Dobijte pomoć još brže",
    "upfront_collection_form_title_submitted": "Poslano timu!",
    "view_all": "Prikaži sve",
    "view_conversation": "Prikaži razgovor",
    "we_are_here_to_help": "Ovde smo da pomognemo",
    "we_run_on_intercom": "Radimo na Intercomu",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kada kolega iz tima promijeni status razgovora...",
    "working_on_it": "Radim na tome...",
    "you_are_all_up_to_date": "Svi ste uhvaćeni!",
    "your_checklists": "Vaše kontrolne liste",
    "your_conversations": "Vaš razgovor",
    "your_recent_conversations": "Nedavni razgovori",
    "your_recent_messages": "Nedavne poruke"
  },
  "ca": {
    "a_day": "Un dia",
    "a_few_hours": "Unes hores",
    "a_few_minutes": "Uns minuts",
    "a_week": "Una setmana",
    "active_state": "Actiu",
    "ai_answer": "Resposta d'IA",
    "ai_answer_information": "Resposta d'IA, informació",
    "ai_bot": "Robot IA",
    "almost_there": "Ja gairebé està...",
    "answer": "Resposta",
    "answer_bot_acknowledge_duplicate_content": "Ja jo heu vist, però és la millor resposta que tenim.",
    "answer_bot_all_done": "Molt útil👍",
    "answer_bot_ask_another_question": "Feu una altra pregunta 🔁",
    "answer_bot_ask_something_else": "Vull fer una altra pregunta 🔁",
    "answer_bot_connect_to_an_agent": "Vull contactar amb un agent 👤",
    "answer_bot_did_that_help": "T'ha ajudat la resposta o busques una altra cosa?",
    "answer_bot_followup_expectations_prefix": "El nostre equip et respondrà ben aviat.",
    "answer_bot_get_more_help": "Aconsegueix més ajuda 🌐",
    "answer_bot_get_more_help_followup": "Aquest enllaç et podria ajudar:",
    "answer_bot_i_have_another_question": "Tinc una altra pregunta 🔁",
    "answer_bot_im_not_done_yet": "Encara no he acabat 🔁",
    "answer_bot_keep_waiting": "No, encara necessito ajuda 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Podeu reformular la pregunta o obtenir ajuda addicional",
    "answer_bot_looping_first_no_answer": "Ho sento, com que soc un robot, no he pogut trobar-hi resposta.",
    "answer_bot_looping_first_no_answer_followup": "Podeu reformular la pregunta o parlar amb el nostre equip",
    "answer_bot_looping_no_answer": "Ho sento, com que soc un robot, no he pogut trobar-hi resposta. Això és el que podeu fer",
    "answer_bot_more_answers": "Mostra-me'n més👀",
    "answer_bot_no_answer_found": "Ho sentim, no tenim cap resposta ràpida a això. Aquí tens unes altres opcions.",
    "answer_bot_resolve_followup": "Si sorgeix alguna cosa més, sempre pots escriure aquí.",
    "answer_bot_talk_to_person": "Parla amb una persona 👤",
    "answer_bot_that_answered_my_question": "Això va respondre la meva pregunta 👍",
    "answer_bot_thats_it": "Això mateix 👍",
    "answer_bot_wait_for_the_team": "Espero l'equip💬",
    "answerbot_ask_for_more_context": "I tant. Mentre esperem, podríeu compartir més detalls sobre la vostra situació o pregunta? Ens permetrà respondre-us més ràpidament i potser us podré oferir més informació.",
    "answerbot_introduce_sneaky_answer": "Gràcies per aquest context addicional. He trobat informació nova que us pot ajudar.",
    "article_search_hint_text": "Cerca articles...",
    "ask_a_question": "Fes una pregunta",
    "ask_a_question_placeholder": "Fes una pregunta...",
    "ask_a_question_with_ellipsis": "Feu una pregunta...",
    "ask_another_question_with_ellipsis": "Feu una altra pregunta...",
    "ask_for_the_team_if_needed": "Demaneu l'equip si cal",
    "ask_me_a_question": "Feu-me una pregunta",
    "ask_our_bot_a_question": "Feu una pregunta al nostre robot",
    "avatar": "Avatar",
    "avatars": "Avatars",
    "bot": "Robot",
    "bot_answers_instantly": "El robot respon a l'instant",
    "bot_expectation_text": "El nostre robot pot respondre moltes preguntes habituals",
    "bot_intro_default_part_1": "Hola! Et parla un robot. Soc aquí per respondre les teves preguntes, però sempre tindràs l'opció de parlar amb el nostre equip.",
    "bot_intro_default_part_2": "Quin vent et porta avui per aquí?",
    "bot_is_still_thinking": "El robot encara està pensant...",
    "bot_is_thinking": "El robot està pensant...",
    "browse_collections": "Explora les col·leccions",
    "chat_with_us": "Parleu amb nosaltres",
    "check_later_for_updates": "Torneu a comprovar-ho més tard per veure les actualitzacions.",
    "checklist_card_first_step": "Primer pas",
    "checklist_card_next_step": "Pas següent",
    "checklist_completed": "Completat",
    "checklist_in_progress_time_remaining_plural": "Falten aproximadament {minutes} minuts",
    "checklist_in_progress_time_remaining_singular": "Falta aproximadament 1 minut",
    "checklist_mark_step_as_complete": "Marca com a completat",
    "checklist_not_started_time_remaining_plural": "Aproximadament {minutes} minuts",
    "checklist_not_started_time_remaining_singular": "Aproximadament 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} passos",
    "checklist_number_of_steps_progress_plural": "{steps_completed} de {total_steps} completats",
    "checklist_number_of_steps_progress_singular": "1 de {total_steps} completat(s)",
    "checklist_number_of_steps_singular": "1 pas",
    "checklist_sender_name": "Compartit per {name}",
    "checklist_step_action_button": "Botó d'acció del pas",
    "checklist_step_completed": "Completat",
    "contact_support": "Poseu-vos en contacte amb el suport",
    "contact_us": "Poseu-vos en contacte amb nosaltres",
    "continue_the_conversation": "Continua la conversa",
    "conversation_card_office_hours_header": "Quan tornarem a estar en línia",
    "conversation_card_reply_time_header": "El nostre temps habitual per respondre",
    "conversation_card_reply_time_header_m5": "Temps de resposta habitual",
    "conversation_card_unknown_response_time": "Respondrem tan aviat com puguem",
    "conversation_part_sample_text": "Hola, com us puc ajudar? Sempre tindreu l'opció de parlar amb el nostre equip si cal.",
    "conversations_space_title": "Converses",
    "create_a_ticket": "Creació d'un tiquet",
    "currently_no_help_articles": "Actualment no hi ha cap article d'ajuda disponible.",
    "customer_sends_their_first_message": "El client envia el seu primer missatge...",
    "expected_response_delay_10800": "menys de 3 hores",
    "expected_response_delay_120": "menys de 2 minuts",
    "expected_response_delay_1200": "menys de 20 minuts",
    "expected_response_delay_14400": "menys de 4 hores",
    "expected_response_delay_172800": "2 dies",
    "expected_response_delay_180": "menys de 3 minuts",
    "expected_response_delay_1800": "menys de 30 minuts",
    "expected_response_delay_18000": "menys de 5 hores",
    "expected_response_delay_21600": "menys de 6 hores",
    "expected_response_delay_240": "menys de 4 minuts",
    "expected_response_delay_259200": "3 dies",
    "expected_response_delay_300": "menys de 5 minuts",
    "expected_response_delay_345600": "4 dies",
    "expected_response_delay_3600": "menys d'1 hora",
    "expected_response_delay_43200": "menys de 12 hores",
    "expected_response_delay_432000": "5 dies",
    "expected_response_delay_518400": "6 dies",
    "expected_response_delay_60": "menys d'1 minut",
    "expected_response_delay_600": "menys de 10 minuts",
    "expected_response_delay_604800": "menys d'1 setmana",
    "expected_response_delay_7200": "menys de 2 hores",
    "expected_response_delay_86400": "1 dia",
    "expected_response_delay_900": "menys de 15 minuts",
    "expected_response_delay_unknown": "Tan aviat com puguem",
    "extra_labels_count": "+ {labels_count} etiquetes",
    "for_best_results_provide_detail": "Per obtenir els millors resultats, proporcioneu tants detalls com sigui possible",
    "generating_answer": "Generant resposta...",
    "generic_triage_default": "Només necessitem una mica més d'informació per connectar-te amb la persona adequada",
    "get_an_instant_answer": "Rebeu una resposta instantània",
    "get_help": "Rep ajuda",
    "go_to": "Ves a {app_name}",
    "help_space_title": "Ajuda",
    "home_header_mobile_hello_firstname": "Hola, {firstname}.",
    "home_header_mobile_hello_there": "Hola!",
    "home_space_title": "Inici",
    "how_can_we_help": "Com et podem ajudar?",
    "how_would_you_rate_this": "Quina valoració hi donaries?",
    "learn_more": "Més informació",
    "message_placeholder": "Missatge...",
    "messages_space_title": "Missatges",
    "messenger_bot_reply_time": "El nostre robot respondrà a l'instant",
    "messenger_settings_introduction": "Com et podem ajudar?",
    "multiple_articles": "{total_articles} Articles",
    "multiple_collections": "{total_collection} colleccions",
    "new_conversation": "Nova conversa",
    "news": "Notícies",
    "news_empty_title": "Encara no n'hi ha de nous",
    "news_latest_subtitle": "De l'equip {team_name}",
    "news_latest_title": "Novetats",
    "news_older_title": "Més antics",
    "news_space_title": "Notícies",
    "newsfeed": "Notícies",
    "no_articles_to_display": "Encara no hi ha articles",
    "no_help_articles_to_display": "No hi ha cap article d'ajuda",
    "no_results_for_searchterm": "No s'han trobat resultats per a {searchTerm}",
    "no_tasks": "No hi cap tasca",
    "no_tasks_available_currently": "Actualment no hi ha cap tasca disponible.",
    "ok_the_team_typically_replies_in_day": "D'acord. L’equip sol respondre en un dia.",
    "ok_the_team_typically_replies_in_hours": "D'acord. L’equip sol respondre en qüestió d’hores.",
    "ok_the_team_typically_replies_in_minutes": "D'acord. L’equip sol respondre en qüestió de minuts.",
    "ok_the_team_typically_replies_in_under_n_hours": "D'acord. L’equip sol respondre en menys de {hours} hores.",
    "ok_the_team_typically_replies_in_under_n_min": "D'acord. L’equip sol respondre en menys de {minutes} minuts.",
    "ok_the_team_typically_replies_in_week": "D'acord. L’equip acostuma a respondre en una setmana.",
    "our_bot_and_team_can_help": "El nostre bot i l'equip us poden ajudar",
    "our_bot_answers_instantly": "El nostre robot respon a l'instant",
    "powered_by_intercom": "Amb la tecnologia d’Intercom",
    "previous_bot_hands_over": "Contingut del bot anterior...",
    "proactive_expected_response_delay_10800": "Sol respondre en menys de 3 hores",
    "proactive_expected_response_delay_120": "Acostuma a respondre en menys de 2 minuts",
    "proactive_expected_response_delay_1200": "Sol respondre en menys de 20 minuts",
    "proactive_expected_response_delay_14400": "Sol respondre en menys de 4 hores",
    "proactive_expected_response_delay_172800": "Sol respondre en 2 dies",
    "proactive_expected_response_delay_180": "Acostuma a respondre en menys de 3 minuts",
    "proactive_expected_response_delay_1800": "Sol respondre en menys de 30 minuts",
    "proactive_expected_response_delay_18000": "Sol respondre en menys de 5 hores",
    "proactive_expected_response_delay_21600": "Sol respondre en menys de 6 hores",
    "proactive_expected_response_delay_240": "Acostuma a respondre en menys de 4 minuts",
    "proactive_expected_response_delay_259200": "Sol respondre en 3 dies",
    "proactive_expected_response_delay_300": "Sol respondre en menys de 5 minuts",
    "proactive_expected_response_delay_345600": "Sol respondre en 4 dies",
    "proactive_expected_response_delay_3600": "Sol respondre en menys d’una hora",
    "proactive_expected_response_delay_43200": "Sol respondre en 12 hores",
    "proactive_expected_response_delay_432000": "Sol respondre en 5 dies",
    "proactive_expected_response_delay_518400": "Sol respondre en 6 dies",
    "proactive_expected_response_delay_60": "Acostuma a respondre en menys d'un minut",
    "proactive_expected_response_delay_600": "Sol respondre en menys de 10 minuts",
    "proactive_expected_response_delay_604800": "Sol respondre en menys d’una setmana",
    "proactive_expected_response_delay_7200": "Sol respondre en menys de 2 hores",
    "proactive_expected_response_delay_86400": "Sol respondre en 1 dia",
    "proactive_expected_response_delay_900": "Acostuma a respondre en menys de 15 minuts",
    "proactive_expected_response_delay_unknown": "Rebrà una resposta el més aviat possible",
    "profile_location": "{time} a {location}",
    "recent_ticket": "Tiquet recent",
    "recent_tickets": "Tiquets recents",
    "search_browse_empty_state_text": "Ho sentim, no hem trobat cap resultat per a la vostra cerca. Proveu de reformular-la.",
    "search_for_help": "Cerqueu ajuda",
    "see_all": "Veu totes",
    "see_all_conversations": "Mostra totes les teves converses",
    "see_previous_conversations": "Mostra les teves converses anteriors",
    "send": "Envia",
    "send_a_message": "Envia un missatge…",
    "send_a_message_reply_time": "El temps de resposta habitual és",
    "send_us_a_message": "Envieu-nos un missatge",
    "set_expectations_bot_email_header": "Rebreu les respostes per aquí i al vostre correu electrònic:",
    "set_expectations_bot_office_hours_header": "Quan tornarà l'equip:",
    "set_expectations_bot_sms_header": "Rebreu les respostes per aquí i per SMS:",
    "set_expectations_replies_to_email": "Rebreu les respostes aquí i a <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Rebreu les respostes aquí i a {user_email}.",
    "set_expectations_team_reply_asap": "L’equip respondrà el més aviat possible.",
    "shared_by": "Compartit per",
    "show_less": "Mostra'n menys",
    "single_article": "1 Article",
    "single_collection": "1 collecció",
    "source": "Font",
    "sources": "Fonts",
    "start_a_conversation": "Inicia una conversa",
    "start_conversation": "Inicia una conversa",
    "still_generating_answer": "Encara s'està generant la resposta...",
    "still_thinking": "Encara pensant...",
    "still_working_on_it": "Encara s'hi està treballant...",
    "tasks_space_title": "Tasques",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} respondrà al més aviat possible",
    "teammate_changes_team_assignment_of_a_conversation": "Un company d'equip ha canviat l'assignació de la conversa...",
    "tell_us_what_you_need": "Explica'ns en què necessites ajuda i el nostre bot farà tot el possible per donar-te una resposta",
    "the_team_can_help_if_needed": "L’equip pot ajudar si cal",
    "the_team_typically_replies_in_10800": "L’equip sol respondre en menys de 3 hores",
    "the_team_typically_replies_in_120": "L’equip acostuma a respondre en menys de 2 minuts",
    "the_team_typically_replies_in_1200": "L’equip sol respondre en menys de 20 minuts",
    "the_team_typically_replies_in_14400": "L’equip sol respondre en menys de 4 hores",
    "the_team_typically_replies_in_172800": "L’equip sol respondre en 2 dies",
    "the_team_typically_replies_in_180": "L’equip acostuma a respondre en menys de 3 minuts",
    "the_team_typically_replies_in_1800": "L’equip sol respondre en menys de 30 minuts",
    "the_team_typically_replies_in_18000": "L’equip sol respondre en menys de 5 hores",
    "the_team_typically_replies_in_21600": "L’equip sol respondre en menys de 6 hores",
    "the_team_typically_replies_in_240": "L’equip acostuma a respondre en menys de 4 minuts",
    "the_team_typically_replies_in_259200": "L’equip sol respondre en 3 dies",
    "the_team_typically_replies_in_300": "L’equip sol respondre en menys de 5 minuts",
    "the_team_typically_replies_in_345600": "L’equip sol respondre en 4 dies",
    "the_team_typically_replies_in_3600": "L’equip sol respondre en menys d’una hora",
    "the_team_typically_replies_in_43200": "L’equip sol respondre en menys de 12 hores",
    "the_team_typically_replies_in_432000": "L’equip sol respondre en 5 dies",
    "the_team_typically_replies_in_518400": "L’equip sol respondre en 6 dies",
    "the_team_typically_replies_in_60": "L’equip acostuma a respondre en menys d'un minut",
    "the_team_typically_replies_in_600": "L’equip sol respondre en menys de 10 minuts",
    "the_team_typically_replies_in_604800": "L’equip sol respondre en menys d’una setmana",
    "the_team_typically_replies_in_7200": "L’equip sol respondre en menys de 2 hores",
    "the_team_typically_replies_in_86400": "L’equip sol respondre en 1 dia",
    "the_team_typically_replies_in_900": "L’equip acostuma a respondre en menys de 15 minuts",
    "the_team_typically_replies_in_day": "L’equip sol respondre en 24 hores.",
    "the_team_typically_replies_in_hours": "L’equip sol respondre en qüestió d’hores.",
    "the_team_typically_replies_in_minutes": "L’equip sol respondre en qüestió de minuts.",
    "the_team_typically_replies_in_under_n_hours": "L’equip sol respondre en menys de {hours} hores.",
    "the_team_typically_replies_in_under_n_min": "L’equip sol respondre en menys de {minutes} minuts.",
    "the_team_typically_replies_in_unknown": "L’equip respondrà al més aviat possible",
    "the_team_typically_replies_in_week": "L’equip acostuma a respondre en una setmana.",
    "thinking": "Pensant...",
    "tickets_cta_text": "Teniu alguna pregunta sobre aquest tiquet?",
    "tickets_have_a_question": "Teniu alguna pregunta sobre aquest problema?",
    "tickets_space_title": "Tiquets",
    "time_future_1h": "1 hora",
    "time_future_2h": "2 hores",
    "time_future_30m": "30 minuts",
    "time_future_3h": "3 hores",
    "time_future_day_0": "Dilluns",
    "time_future_day_1": "Dimarts",
    "time_future_day_2": "Dimecres",
    "time_future_day_3": "Dijous",
    "time_future_day_4": "Divendres",
    "time_future_day_5": "Dissabte",
    "time_future_day_6": "Diumenge",
    "time_future_later_today": "Avui més endavant",
    "time_future_next_week": "La setmana vinent",
    "time_future_tomorrow": "Demà",
    "try_use_different_keywords": "Proveu de fer servir paraules clau diferents o escriviu la vostra pregunta completa",
    "upfront_collection_form_close_out": "Pots tornar aquí sempre que vulguis per veure les respostes i enviar actualitzacions.",
    "upfront_collection_form_introduction": "Comparteix una mica de context per ajudar l'equip a respondre amb més rapidesa i precisió.",
    "upfront_collection_form_submission_confirmation": "Gràcies per enviar el context. Per estalviar temps més endavant, no dubtis a afegir més detalls ara mateix.",
    "upfront_collection_form_subtitle": "Envia una mica de context a l'equip",
    "upfront_collection_form_subtitle_submitted": "El tindran en compte",
    "upfront_collection_form_title": "Rep ajuda més ràpid",
    "upfront_collection_form_title_submitted": "S'ha enviat a l'equip!",
    "view_all": "Veure-les totes",
    "view_conversation": "Mostra la conversa",
    "we_are_here_to_help": "Estem aquí per ajudar-vos",
    "we_run_on_intercom": "Utilitzem Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Quan un company d'equip canvia l'estat d'una conversa...",
    "working_on_it": "Treballant-hi...",
    "you_are_all_up_to_date": "Estàs al dia!",
    "your_checklists": "Les vostres llistes de comprovació",
    "your_conversations": "Les teves converses",
    "your_recent_conversations": "Converses recents",
    "your_recent_messages": "Missatges recents"
  },
  "cs": {
    "a_day": "Den",
    "a_few_hours": "Několik hodin",
    "a_few_minutes": "Několik minut",
    "a_week": "Týden",
    "active_state": "Aktivní",
    "ai_answer": "Odpověď AI",
    "ai_answer_information": "Odpověď AI, informace",
    "ai_bot": "AI robot",
    "almost_there": "Už to skoro je…",
    "answer": "Odpověď",
    "answer_bot_acknowledge_duplicate_content": "Už jste to viděli, ale tohle je nejlepší dostupná odpověď.",
    "answer_bot_all_done": "To bylo užitečné 👍",
    "answer_bot_ask_another_question": "Položit další otázku 🔁",
    "answer_bot_ask_something_else": "Zeptejte se na něco jiného 🔁",
    "answer_bot_connect_to_an_agent": "Spojte se s naším zástupcem 👤",
    "answer_bot_did_that_help": "Pomohla vám tato odpověď, nebo hledáte něco jiného?",
    "answer_bot_followup_expectations_prefix": "Tým se na vás ohledně toho obrátí.",
    "answer_bot_get_more_help": "Získejte další pomoc 🌐",
    "answer_bot_get_more_help_followup": "Tento odkaz by mohl pomoci:",
    "answer_bot_i_have_another_question": "Mám další otázku 🔁",
    "answer_bot_im_not_done_yet": "Ještě jsem neskončil/a 🔁",
    "answer_bot_keep_waiting": "Ne, stále potřebuji pomoc 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Svou otázku můžete přeformulovat nebo získat další pomoc",
    "answer_bot_looping_first_no_answer": "Promiňte, jako robot jsem na to nedokázal nalézt odpověď.",
    "answer_bot_looping_first_no_answer_followup": "Svou otázku můžete přeformulovat nebo si promluvit s někým z našeho týmu",
    "answer_bot_looping_no_answer": "Promiňte, jako robot jsem na to nedokázal nalézt odpověď. Můžete udělat to, co je uvedeno zde",
    "answer_bot_more_answers": "Zobrazit více 👀",
    "answer_bot_no_answer_found": "Promiňte, ale na to nemáme rychlou odpověď. Zde jsou některé další možnosti.",
    "answer_bot_resolve_followup": "Vždy můžete odpovědět zde, pokud se objeví něco dalšího.",
    "answer_bot_talk_to_person": "Promluvte si s někým osobně 👤",
    "answer_bot_that_answered_my_question": "To zodpovědělo mou otázku 👍",
    "answer_bot_thats_it": "To je ono 👍",
    "answer_bot_wait_for_the_team": "Počkej na tým 💬",
    "answerbot_ask_for_more_context": "Samozřejmě. Zatímco čekáme, můžete nám o své situaci nebo otázce říct víc podrobností? Pomůže nám to rychleji najít odpověď a možná vám budu moct najít další informace.",
    "answerbot_introduce_sneaky_answer": "Děkuji za doplňující kontext. Našel jsem pár nových informací, které by vám mohly pomoct.",
    "article_search_hint_text": "Prohledat články...",
    "ask_a_question": "Položit otázku",
    "ask_a_question_placeholder": "Položit otázku...",
    "ask_a_question_with_ellipsis": "Položte otázku…",
    "ask_another_question_with_ellipsis": "Položte další otázku…",
    "ask_for_the_team_if_needed": "V případě potřeby se zeptejte týmu",
    "ask_me_a_question": "Položte mi otázku",
    "ask_our_bot_a_question": "Zeptejte se našeho robota",
    "avatar": "Avatar",
    "avatars": "Avatary",
    "bot": "Bot",
    "bot_answers_instantly": "Robot odpovídá okamžitě",
    "bot_expectation_text": "Náš robot dokáže odpovědět na mnoho běžných otázek",
    "bot_intro_default_part_1": "Ahoj! Hovoří k vám robot. Jsem tady, abych vám odpověděl na vaše otázky, ale vždy budete mít možnost promluvit si s naším týmem.",
    "bot_intro_default_part_2": "Tak co vás sem dnes přivádí?",
    "bot_is_still_thinking": "Robot stále přemýšlí…",
    "bot_is_thinking": "Robot přemýšlí…",
    "browse_collections": "Procházet sbírky",
    "chat_with_us": "Chatujte s námi",
    "check_later_for_updates": "Podívejte se později na aktualizace.",
    "checklist_card_first_step": "První krok",
    "checklist_card_next_step": "Další krok",
    "checklist_completed": "Dokončeno",
    "checklist_in_progress_time_remaining_plural": "Zbývá asi {minutes} minut(y)",
    "checklist_in_progress_time_remaining_singular": "Zbývá asi 1 minuta",
    "checklist_mark_step_as_complete": "Označit jako dokončeno",
    "checklist_not_started_time_remaining_plural": "Asi {minutes} minut(y)",
    "checklist_not_started_time_remaining_singular": "Asi 1 minuta",
    "checklist_number_of_steps_plural": "{number_of_steps} kroků",
    "checklist_number_of_steps_progress_plural": "{steps_completed} z {total_steps} dokončeno",
    "checklist_number_of_steps_progress_singular": "1 z {total_steps} hotovo",
    "checklist_number_of_steps_singular": "1 krok",
    "checklist_sender_name": "Sdíleno uživatelem {name}",
    "checklist_step_action_button": "Tlačítko kroku akce",
    "checklist_step_completed": "Dokončeno",
    "contact_support": "Kontaktovat podporu",
    "contact_us": "Kontaktujte nás",
    "continue_the_conversation": "Pokračovat v konverzaci",
    "conversation_card_office_hours_header": "Kdy budeme opět online",
    "conversation_card_reply_time_header": "Naše obvyklá doba odpovědi",
    "conversation_card_reply_time_header_m5": "Obvyklá doba odpovědi",
    "conversation_card_unknown_response_time": "Odpovíme, jakmile to půjde",
    "conversation_part_sample_text": "Ahoj, jak můžu pomoct? V případě potřeby budete mít vždy možnost promluvit si s naším týmem.",
    "conversations_space_title": "Konverzace",
    "create_a_ticket": "Vytvořit lístek",
    "currently_no_help_articles": "Momentálně nejsou k dispozici žádné články nápovědy.",
    "customer_sends_their_first_message": "Zákazník odesílá svou první zprávu...",
    "expected_response_delay_10800": "do 3 hodin",
    "expected_response_delay_120": "do 2 minut",
    "expected_response_delay_1200": "do 20 minut",
    "expected_response_delay_14400": "do 4 hodin",
    "expected_response_delay_172800": "2 dny",
    "expected_response_delay_180": "do 3 minut",
    "expected_response_delay_1800": "do 30 minut",
    "expected_response_delay_18000": "do 5 hodin",
    "expected_response_delay_21600": "do 6 hodin",
    "expected_response_delay_240": "do 4 minut",
    "expected_response_delay_259200": "3 dny",
    "expected_response_delay_300": "do 5 minut",
    "expected_response_delay_345600": "4 dny",
    "expected_response_delay_3600": "do 1 hodiny",
    "expected_response_delay_43200": "do 12 hodin",
    "expected_response_delay_432000": "5 dní",
    "expected_response_delay_518400": "6 dní",
    "expected_response_delay_60": "do 1 minuty",
    "expected_response_delay_600": "do 10 minut",
    "expected_response_delay_604800": "do 1 týdne",
    "expected_response_delay_7200": "do 2 hodin",
    "expected_response_delay_86400": "1 den",
    "expected_response_delay_900": "do 15 minut",
    "expected_response_delay_unknown": "Jakmile to bude možné",
    "extra_labels_count": "+ {labels_count} štítky/štítků",
    "for_best_results_provide_detail": "Nejlepších výsledků dosáhnete, když uvedete co nejvíce podrobností",
    "generating_answer": "Generování odpovědi…",
    "generic_triage_default": "Potřebujeme jen o něco více informací, abychom vás spojili se správnou osobou",
    "get_an_instant_answer": "Získejte okamžitou odpověď",
    "get_help": "Získat pomoc",
    "go_to": "Přejít na {app_name}",
    "help_space_title": "Nápověda",
    "home_header_mobile_hello_firstname": "Ahoj, {firstname}.",
    "home_header_mobile_hello_there": "Ahoj",
    "home_space_title": "Domov",
    "how_can_we_help": "Jak vám můžeme pomoci?",
    "how_would_you_rate_this": "Jak byste to ohodnotili?",
    "learn_more": "Zjistěte více",
    "message_placeholder": "Zpráva…",
    "messages_space_title": "Zprávy",
    "messenger_bot_reply_time": "Náš bot odpoví okamžitě",
    "messenger_settings_introduction": "Jak vám můžeme pomoci?",
    "multiple_articles": "{total_articles} články",
    "multiple_collections": "{total_collection} sbírek",
    "new_conversation": "Nová konverzace",
    "news": "Novinky",
    "news_empty_title": "Zatím žádné novinky",
    "news_latest_subtitle": "Od týmu {team_name}",
    "news_latest_title": "Nejnovější",
    "news_older_title": "Starší",
    "news_space_title": "Novinky",
    "newsfeed": "Vlákno s novinkami",
    "no_articles_to_display": "Zatím žádné články",
    "no_help_articles_to_display": "Žádné články nápovědy",
    "no_results_for_searchterm": "Žádné výsledky pro {searchTerm}",
    "no_tasks": "Žádné úkoly",
    "no_tasks_available_currently": "Momentálně nejsou k dispozici žádné úkoly.",
    "ok_the_team_typically_replies_in_day": "OK, tým obvykle odpoví do dne.",
    "ok_the_team_typically_replies_in_hours": "OK, tým obvykle odpoví do několika hodin.",
    "ok_the_team_typically_replies_in_minutes": "OK, tým obvykle odpoví do několika minut.",
    "ok_the_team_typically_replies_in_under_n_hours": "OK, tým obvykle odpoví do necelých {hours} hod.",
    "ok_the_team_typically_replies_in_under_n_min": "OK, tým obvykle odpoví do {minutes} min.",
    "ok_the_team_typically_replies_in_week": "OK, tým obvykle odpoví do týdne.",
    "our_bot_and_team_can_help": "Náš bot a tým vám mohou pomoct",
    "our_bot_answers_instantly": "Náš robot odpovídá okamžitě",
    "powered_by_intercom": "Využívá technologii Intercom",
    "previous_bot_hands_over": "Obsah z předchozího robota...",
    "proactive_expected_response_delay_10800": "Obvykle dostanete odpověď do 3 h",
    "proactive_expected_response_delay_120": "Obvykle dostanete odpověď do 2 min.",
    "proactive_expected_response_delay_1200": "Obvykle dostanete odpověď do 20 min.",
    "proactive_expected_response_delay_14400": "Obvykle dostanete odpověď do 4 h",
    "proactive_expected_response_delay_172800": "Obvykle dostanete odpověď do 2 dní",
    "proactive_expected_response_delay_180": "Obvykle dostanete odpověď do 3 min.",
    "proactive_expected_response_delay_1800": "Obvykle dostanete odpověď do 30 min.",
    "proactive_expected_response_delay_18000": "Obvykle dostanete odpověď do 5 h",
    "proactive_expected_response_delay_21600": "Obvykle dostanete odpověď do 6 h",
    "proactive_expected_response_delay_240": "Obvykle dostanete odpověď do 4 min.",
    "proactive_expected_response_delay_259200": "Obvykle dostanete odpověď do 3 dnů",
    "proactive_expected_response_delay_300": "Obvykle dostanete odpověď do 5 min.",
    "proactive_expected_response_delay_345600": "Obvykle dostanete odpověď do 4 dnů",
    "proactive_expected_response_delay_3600": "Obvykle dostanete odpověď do 1 h",
    "proactive_expected_response_delay_43200": "Obvykle dostanete odpověď do 12 hodin",
    "proactive_expected_response_delay_432000": "Obvykle dostanete odpověď do 5 dnů",
    "proactive_expected_response_delay_518400": "Obvykle dostanete odpověď do 6 dnů",
    "proactive_expected_response_delay_60": "Obvykle dostanete odpověď do 1 min.",
    "proactive_expected_response_delay_600": "Obvykle dostanete odpověď do 10 min.",
    "proactive_expected_response_delay_604800": "Obvykle dostanete odpověď do 1 týd.",
    "proactive_expected_response_delay_7200": "Obvykle dostanete odpověď do 2 h",
    "proactive_expected_response_delay_86400": "Obvykle dostanete odpověď do 1 dne",
    "proactive_expected_response_delay_900": "Obvykle dostanete odpověď do 15 minut.",
    "proactive_expected_response_delay_unknown": "Odpověď dostanete co nejdříve",
    "profile_location": "{time} v {location}",
    "recent_ticket": "Nedávný lístek",
    "recent_tickets": "Nedávné lístky",
    "search_browse_empty_state_text": "Je nám líto, ale na to jsme nedokázali najít žádné výsledky. Zkuste přeformulovat vyhledávání.",
    "search_for_help": "Vyhledat pomoc",
    "see_all": "Zobrazit vše",
    "see_all_conversations": "Podívejte se na všechny vaše konverzace",
    "see_previous_conversations": "Podívejte se na předchozí konverzace",
    "send": "Odeslat",
    "send_a_message": "Odeslat zprávu…",
    "send_a_message_reply_time": "Obvyklá doba odpovědi je",
    "send_us_a_message": "Pošlete nám zprávu",
    "set_expectations_bot_email_header": "Odpovědi obdržíte zde a ve svém e-mailu:",
    "set_expectations_bot_office_hours_header": "Kdy bude tým zpět:",
    "set_expectations_bot_sms_header": "Odpovědi obdržíte zde a prostřednictvím SMS:",
    "set_expectations_replies_to_email": "Odpovědi dostanete zde a na adresu <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Odpovědi dostanete zde a na adresu {user_email}",
    "set_expectations_team_reply_asap": "Tým odpoví co nejdříve.",
    "shared_by": "Sdíleno",
    "show_less": "Zobrazit méně",
    "single_article": "1 článek",
    "single_collection": "1 sbírka",
    "source": "Zdroj",
    "sources": "Zdroje",
    "start_a_conversation": "Zahájit konverzaci",
    "start_conversation": "Zahájit konverzaci",
    "still_generating_answer": "Stále se generuje odpověď…",
    "still_thinking": "Stále přemýšlím…",
    "still_working_on_it": "Stále se na tom pracuje…",
    "tasks_space_title": "Úkoly",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} odpoví co nejdříve to bude možné.",
    "teammate_changes_team_assignment_of_a_conversation": "Kolega z týmu změnil přidělení konverzace...",
    "tell_us_what_you_need": "Řekněte nám, s čím potřebujete pomoci a náš robot udělá vše pro to, aby vám odpověděl",
    "the_team_can_help_if_needed": "V případě potřeby může pomoci tým",
    "the_team_typically_replies_in_10800": "Tým obvykle odpoví během 3 hodin.",
    "the_team_typically_replies_in_120": "Tým obvykle odpoví do 2 min.",
    "the_team_typically_replies_in_1200": "Tým obvykle odpoví během 20 minut.",
    "the_team_typically_replies_in_14400": "Tým obvykle odpoví během 4 hodin.",
    "the_team_typically_replies_in_172800": "Tým obvykle odpoví během 2 dnů.",
    "the_team_typically_replies_in_180": "Tým obvykle odpoví do 3 min.",
    "the_team_typically_replies_in_1800": "Tým obvykle odpoví během 30 minut.",
    "the_team_typically_replies_in_18000": "Tým obvykle odpoví během 5 hodin.",
    "the_team_typically_replies_in_21600": "Tým obvykle odpoví během 6 hodin.",
    "the_team_typically_replies_in_240": "Tým obvykle odpoví do 4 min.",
    "the_team_typically_replies_in_259200": "Tým obvykle odpoví během 3 dnů",
    "the_team_typically_replies_in_300": "Tým obvykle odpoví během 5 minut.",
    "the_team_typically_replies_in_345600": "Tým obvykle odpoví během 4 dnů",
    "the_team_typically_replies_in_3600": "Tým obvykle odpoví během 1 hodiny.",
    "the_team_typically_replies_in_43200": "Tým obvykle odpoví během 12 hodin",
    "the_team_typically_replies_in_432000": "Tým obvykle odpoví během 5 dnů",
    "the_team_typically_replies_in_518400": "Tým obvykle odpoví během 6 dnů",
    "the_team_typically_replies_in_60": "Tým obvykle odpoví do 1 min.",
    "the_team_typically_replies_in_600": "Tým obvykle odpoví během 10 minut.",
    "the_team_typically_replies_in_604800": "Tým obvykle odpoví za méně než 1 týden.",
    "the_team_typically_replies_in_7200": "Tým obvykle odpoví během 2 hodin.",
    "the_team_typically_replies_in_86400": "Tým obvykle odpoví během 1 dne.",
    "the_team_typically_replies_in_900": "Tým obvykle odpoví do 15 minut.",
    "the_team_typically_replies_in_day": "Tým obvykle odpoví do 1 dne.",
    "the_team_typically_replies_in_hours": "Tým obvykle odpoví do několika hodin.",
    "the_team_typically_replies_in_minutes": "Tým obvykle odpoví do několika minut.",
    "the_team_typically_replies_in_under_n_hours": "Tým obvykle odpoví do necelých {hours} hod.",
    "the_team_typically_replies_in_under_n_min": "Tým obvykle odpoví do necelých {minutes} min.",
    "the_team_typically_replies_in_unknown": "Tým odpoví co nejdříve to bude možné.",
    "the_team_typically_replies_in_week": "Tým obvykle odpoví do týdne.",
    "thinking": "Přemýšlím…",
    "tickets_cta_text": "Máte k tomuto lístku dotaz?",
    "tickets_have_a_question": "Máte k tomuto problému dotaz?",
    "tickets_space_title": "Lístky",
    "time_future_1h": "1 hodina",
    "time_future_2h": "2 hodiny",
    "time_future_30m": "30 minut",
    "time_future_3h": "3 hodiny",
    "time_future_day_0": "Pondělí",
    "time_future_day_1": "Úterý",
    "time_future_day_2": "Středa",
    "time_future_day_3": "Čtvrtek",
    "time_future_day_4": "Pátek",
    "time_future_day_5": "Sobota",
    "time_future_day_6": "Neděle",
    "time_future_later_today": "Později dnes",
    "time_future_next_week": "Příští týden",
    "time_future_tomorrow": "Zítra",
    "try_use_different_keywords": "Zkuste použít jiná klíčová slova nebo napište celou otázku",
    "upfront_collection_form_close_out": "Můžete se sem kdykoli vrátit, zobrazit si odpovědi a odeslat aktualizace.",
    "upfront_collection_form_introduction": "Sdílejte nějaký kontext, který pomůže týmu reagovat lépe a rychleji.",
    "upfront_collection_form_submission_confirmation": "Díky, že jste nám poslali tento kontext. Chcete-li později ušetřit čas, neváhejte a již nyní přidejte další podrobnosti.",
    "upfront_collection_form_subtitle": "Pošlete týmu nějaký kontext",
    "upfront_collection_form_subtitle_submitted": "Budou na tento kontext pamatovat",
    "upfront_collection_form_title": "Získejte pomoc rychleji",
    "upfront_collection_form_title_submitted": "Odesláno týmu!",
    "view_all": "Zobrazit vše",
    "view_conversation": "Zobrazit konverzaci",
    "we_are_here_to_help": "Rádi vám pomůžeme",
    "we_run_on_intercom": "Pracujeme s platformou Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Když spoluhráč změní stav konverzace...",
    "working_on_it": "Pracuje se na tom…",
    "you_are_all_up_to_date": "Jste v obraze!",
    "your_checklists": "Vaše kontrolní seznamy",
    "your_conversations": "Vaše konverzace",
    "your_recent_conversations": "Nedávné konverzace",
    "your_recent_messages": "Poslední zprávy"
  },
  "da": {
    "a_day": "En dag",
    "a_few_hours": "Nogle få timer",
    "a_few_minutes": "Nogle få minutter",
    "a_week": "En uge",
    "active_state": "Aktiv",
    "ai_answer": "AI-svar",
    "ai_answer_information": "AI-svar, oplysninger",
    "ai_bot": "AI-bot",
    "almost_there": "Næsten klar ...",
    "answer": "Svar",
    "answer_bot_acknowledge_duplicate_content": "Du har allerede set dette, men dette er det bedste svar, der findes.",
    "answer_bot_all_done": "Det hjalp 👍",
    "answer_bot_ask_another_question": "Stil et andet spørgsmål 🔁",
    "answer_bot_ask_something_else": "Spørg noget andet 🔁",
    "answer_bot_connect_to_an_agent": "Opret forbindelse til en agent 👤",
    "answer_bot_did_that_help": "Er dette svar nyttigt, eller leder du efter noget andet?",
    "answer_bot_followup_expectations_prefix": "Teamet vil vende tilbage til dig om dette.",
    "answer_bot_get_more_help": "Få mere hjælp🌐",
    "answer_bot_get_more_help_followup": "Dette link kan muligvis hjælpe:",
    "answer_bot_i_have_another_question": "Jeg har et andet spørgsmål 🔁",
    "answer_bot_im_not_done_yet": "Jeg er ikke færdig endnu 🔁",
    "answer_bot_keep_waiting": "Nej, jeg har stadig brug for hjælp 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Du kan omformulere dit spørgsmål eller få yderligere hjælp",
    "answer_bot_looping_first_no_answer": "Beklager, men som bot kunne jeg ikke finde et svar på det.",
    "answer_bot_looping_first_no_answer_followup": "Du kan omformulere dit spørgsmål eller tale med vores team",
    "answer_bot_looping_no_answer": "Beklager, men jeg er en bot og kunne ikke finde et svar på det spørgsmål. Her er et forslag til, hvad du kan gøre",
    "answer_bot_more_answers": "Vis mig mere 👀",
    "answer_bot_no_answer_found": "Det har vi desværre ikke noget hurtigt svar på. Her er nogle andre muligheder.",
    "answer_bot_resolve_followup": "Du kan altid svare her, hvis noget andet dukker op.",
    "answer_bot_talk_to_person": "Tale med en person 👤",
    "answer_bot_that_answered_my_question": "Det besvarede mit spørgsmål 👍",
    "answer_bot_thats_it": "Det var det 👍",
    "answer_bot_wait_for_the_team": "Vent på holdet 💬",
    "answerbot_ask_for_more_context": "Selvfølgelig. Mens vi venter, kan du måske fortælle lidt mere om din situation eller dit spørgsmål? Det hjælper os med at finde et svar hurtigere, og jeg kan måske finde flere oplysninger til dig.",
    "answerbot_introduce_sneaky_answer": "Tak for de ydeligere oplysninger. Jeg har fundet nogle nye oplysninger, der måske kan hjælpe dig.",
    "article_search_hint_text": "Søg i artikler...",
    "ask_a_question": "Stil et spørgsmål",
    "ask_a_question_placeholder": "Stil et spørgsmål ...",
    "ask_a_question_with_ellipsis": "Stil et spørgsmål ...",
    "ask_another_question_with_ellipsis": "Stil et andet spørgsmål ...",
    "ask_for_the_team_if_needed": "Spørg om nødvendigt efter vores supportteam",
    "ask_me_a_question": "Stil mig et spørgsmål",
    "ask_our_bot_a_question": "Stil et spørgsmål til vores bot",
    "avatar": "Avatar",
    "avatars": "Avatarer",
    "bot": "Bot",
    "bot_answers_instantly": "Bot svarer med det samme",
    "bot_expectation_text": "Vores bot kan besvare mange almindelige spørgsmål",
    "bot_intro_default_part_1": "Hej! Dette er en bots, der taler. Jeg er her for at besvare dine spørgsmål, men du kan altid vælge at tale med vores team.",
    "bot_intro_default_part_2": "Så hvad bringer dig her i dag?",
    "bot_is_still_thinking": "Bot tænker stadig ...",
    "bot_is_thinking": "Bot tænker ...",
    "browse_collections": "Gennemse samlinger",
    "chat_with_us": "Chat med os",
    "check_later_for_updates": "Kontroller igen senere for opdateringer.",
    "checklist_card_first_step": "Første trin",
    "checklist_card_next_step": "Næste trin",
    "checklist_completed": "Fuldført",
    "checklist_in_progress_time_remaining_plural": "Ca. {minutes} minutter tilbage",
    "checklist_in_progress_time_remaining_singular": "Ca. 1 minut tilbage",
    "checklist_mark_step_as_complete": "Markér som fuldført",
    "checklist_not_started_time_remaining_plural": "Ca. {minutes} minutter",
    "checklist_not_started_time_remaining_singular": "Ca. 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} trin",
    "checklist_number_of_steps_progress_plural": "{steps_completed} af {total_steps} udført",
    "checklist_number_of_steps_progress_singular": "1 af {total_steps} udført",
    "checklist_number_of_steps_singular": "1 trin",
    "checklist_sender_name": "Deles af {name}",
    "checklist_step_action_button": "Knappen Handlingstrin",
    "checklist_step_completed": "Fuldført",
    "contact_support": "Kontakt support",
    "contact_us": "Kontakt os",
    "continue_the_conversation": "Fortsæt samtalen",
    "conversation_card_office_hours_header": "Hvornår vi er online igen",
    "conversation_card_reply_time_header": "Vores normale svartid",
    "conversation_card_reply_time_header_m5": "Normal svartid",
    "conversation_card_unknown_response_time": "Vi svarer så hurtigt, vi kan",
    "conversation_part_sample_text": "Hej, hvad kan jeg hjælpe med? Du kan altid tale med vores team, hvis det er nødvendigt.",
    "conversations_space_title": "Samtaler",
    "create_a_ticket": "Opret en supportanmodning",
    "currently_no_help_articles": "Der er i øjeblikket ingen tilgængelige hjælpeartikler.",
    "customer_sends_their_first_message": "Kunden sender sin første besked...",
    "expected_response_delay_10800": "under 3 timer",
    "expected_response_delay_120": "i løbet af 2 minutter",
    "expected_response_delay_1200": "under 20 minutter",
    "expected_response_delay_14400": "under 4 timer",
    "expected_response_delay_172800": "2 dage",
    "expected_response_delay_180": "i løbet af 3 minutter",
    "expected_response_delay_1800": "under 30 minutter",
    "expected_response_delay_18000": "under 5 timer",
    "expected_response_delay_21600": "under 6 timer",
    "expected_response_delay_240": "i løbet af 4 minutter",
    "expected_response_delay_259200": "3 dage",
    "expected_response_delay_300": "under 5 minutter",
    "expected_response_delay_345600": "4 dage",
    "expected_response_delay_3600": "under 1 time",
    "expected_response_delay_43200": "under 12 timer",
    "expected_response_delay_432000": "5 dage",
    "expected_response_delay_518400": "6 dage",
    "expected_response_delay_60": "i løbet af 1 minut",
    "expected_response_delay_600": "under 10 minutter",
    "expected_response_delay_604800": "under 1 uge",
    "expected_response_delay_7200": "under 2 timer",
    "expected_response_delay_86400": "1 dag",
    "expected_response_delay_900": "under 15 minutter",
    "expected_response_delay_unknown": "Så hurtigt vi kan",
    "extra_labels_count": "+ {labels_count} etiketter",
    "for_best_results_provide_detail": "For at få de bedste resultater skal du angive så mange oplysninger som muligt",
    "generating_answer": "Genererer svar ...",
    "generic_triage_default": "Vi skal bare bruge lidt flere oplysninger for at forbinde dig med den rette person",
    "get_an_instant_answer": "Få et hurtigt svar",
    "get_help": "Få hjælp",
    "go_to": "Gå til {app_name}",
    "help_space_title": "Hjælp",
    "home_header_mobile_hello_firstname": "Hej {firstname}.",
    "home_header_mobile_hello_there": "Hej",
    "home_space_title": "Hjem",
    "how_can_we_help": "Hvad kan vi gøre for dig?",
    "how_would_you_rate_this": "Hvordan vil du bedømme denne nyhed?",
    "learn_more": "Få mere at vide",
    "message_placeholder": "Skriv din besked her ...",
    "messages_space_title": "Meddelelser",
    "messenger_bot_reply_time": "Vores bot vil svare med det samme",
    "messenger_settings_introduction": "Hvad kan vi gøre for dig?",
    "multiple_articles": "{total_articles} artikler",
    "multiple_collections": "{total_collection} samlinger",
    "new_conversation": "Ny samtale",
    "news": "Nyheder",
    "news_empty_title": "Ingen nyheder endnu",
    "news_latest_subtitle": "Fra Team {team_name}",
    "news_latest_title": "Seneste",
    "news_older_title": "Ældre",
    "news_space_title": "Nyheder",
    "newsfeed": "Nyhedsstrøm",
    "no_articles_to_display": "Ingen artikler endnu",
    "no_help_articles_to_display": "Ingen hjælpeartikler",
    "no_results_for_searchterm": "Ingen resultater for {searchTerm}",
    "no_tasks": "Ingen opgaver",
    "no_tasks_available_currently": "Der er i øjeblikket ingen tilgængelige opgaver.",
    "ok_the_team_typically_replies_in_day": "OK, teamet svarer normalt i løbet af en dag.",
    "ok_the_team_typically_replies_in_hours": "OK, teamet svarer normalt i løbet af få timer.",
    "ok_the_team_typically_replies_in_minutes": "OK, teamet svarer normalt i løbet af få minutter.",
    "ok_the_team_typically_replies_in_under_n_hours": "OK, teamet svarer normalt i løbet af {hours} t.",
    "ok_the_team_typically_replies_in_under_n_min": "OK, teamet svarer normalt i løbet af {minutes} m.",
    "ok_the_team_typically_replies_in_week": "OK, teamet svarer normalt i løbet af en uge.",
    "our_bot_and_team_can_help": "Vores bot og team kan hjælpe",
    "our_bot_answers_instantly": "Vores bot svarer med det samme",
    "powered_by_intercom": "Drevet af Intercom",
    "previous_bot_hands_over": "Indhold fra den forrige bot...",
    "proactive_expected_response_delay_10800": "Svarer normalt på under 3 timer",
    "proactive_expected_response_delay_120": "Svarer normalt i løbet af 2 minutter",
    "proactive_expected_response_delay_1200": "svarer normalt på under 20 min.",
    "proactive_expected_response_delay_14400": "Svarer normalt på under 4 timer",
    "proactive_expected_response_delay_172800": "Svarer normalt inden for 2 dage",
    "proactive_expected_response_delay_180": "Svarer normalt i løbet af 3 minutter",
    "proactive_expected_response_delay_1800": "Svarer normalt på under 30 min.",
    "proactive_expected_response_delay_18000": "Svarer normalt på under 5 timer",
    "proactive_expected_response_delay_21600": "Svarer normalt på under 6 timer",
    "proactive_expected_response_delay_240": "Svarer normalt i løbet af 4 minutter",
    "proactive_expected_response_delay_259200": "Svarer normalt inden for 3 dage",
    "proactive_expected_response_delay_300": "Svarer normalt på under 5 min.",
    "proactive_expected_response_delay_345600": "Svarer normalt inden for 4 dage",
    "proactive_expected_response_delay_3600": "Svarer normalt på under 1 t.",
    "proactive_expected_response_delay_43200": "Svarer normalt inden for 12 timer",
    "proactive_expected_response_delay_432000": "Svarer normalt inden for 5 dage",
    "proactive_expected_response_delay_518400": "Svarer normalt inden for 6 dage",
    "proactive_expected_response_delay_60": "Svarer normalt i løbet af 1 minut",
    "proactive_expected_response_delay_600": "Svarer normalt på under 10 min.",
    "proactive_expected_response_delay_604800": "Svarer normalt inden for 1 uge",
    "proactive_expected_response_delay_7200": "Svarer normalt på under 2 timer",
    "proactive_expected_response_delay_86400": "Svarer normalt inden for 1 dag",
    "proactive_expected_response_delay_900": "Svarer normalt på under 15 minutter",
    "proactive_expected_response_delay_unknown": "Svarer så hurtigt, de kan",
    "profile_location": "{time} i {location}",
    "recent_ticket": "Seneste billet",
    "recent_tickets": "Seneste billetter",
    "search_browse_empty_state_text": "Beklager, men vi kunne ikke finde nogen resultater til det. Prøv at omformulere søgningen.",
    "search_for_help": "Søg efter hjælp",
    "see_all": "Se alle",
    "see_all_conversations": "Se alle dine samtaler",
    "see_previous_conversations": "Se dine tidligere samtaler",
    "send": "Send",
    "send_a_message": "Send en meddelelse…",
    "send_a_message_reply_time": "Normal svartid er",
    "send_us_a_message": "Send os en besked",
    "set_expectations_bot_email_header": "Du får svar her og i din e-mail:",
    "set_expectations_bot_office_hours_header": "Hvornår teamet vil være tilbage:",
    "set_expectations_bot_sms_header": "Du får svar her og via SMS:",
    "set_expectations_replies_to_email": "Du får svar her og til <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Du får svar her og på {user_email}",
    "set_expectations_team_reply_asap": "Teamet svarer så hurtigt, de kan.",
    "shared_by": "Delt af",
    "show_less": "Vis mindre",
    "single_article": "1 artikel",
    "single_collection": "1 samling",
    "source": "Kilde",
    "sources": "Kilder",
    "start_a_conversation": "Start en samtale",
    "start_conversation": "Start en samtale",
    "still_generating_answer": "Genererer stadig svar ...",
    "still_thinking": "Tænker stadig ...",
    "still_working_on_it": "Arbejder stadig på det ...",
    "tasks_space_title": "Opgaver",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} svarer så hurtigt, de kan",
    "teammate_changes_team_assignment_of_a_conversation": "Teammedlem har ændret samtaletildelingen...",
    "tell_us_what_you_need": "Fortæl os, hvilken hjælp du har brug for, og vores robot vil gøre sit bedste for at svare",
    "the_team_can_help_if_needed": "Teamet kan hjælpe, hvis det er nødvendigt",
    "the_team_typically_replies_in_10800": "Teamet svarer normalt påi under 3 timer",
    "the_team_typically_replies_in_120": "Teamet svarer normalt i løbet af 2 minutter",
    "the_team_typically_replies_in_1200": "Teamet svarer normalt påi under 20 min.",
    "the_team_typically_replies_in_14400": "Teamet svarer normalt påi under 4 timer",
    "the_team_typically_replies_in_172800": "Teamet svarer normalt efter 2 dage.",
    "the_team_typically_replies_in_180": "Teamet svarer normalt i løbet af 3 minutter",
    "the_team_typically_replies_in_1800": "Teamet svarer normalt påi under 30 min.",
    "the_team_typically_replies_in_18000": "Teamet svarer normalt påi under 5 timer",
    "the_team_typically_replies_in_21600": "Teamet svarer normalt påi under 6 timer",
    "the_team_typically_replies_in_240": "Teamet svarer normalt i løbet af 4 minutter",
    "the_team_typically_replies_in_259200": "Teamet svarer normalt indenfor 3 dage",
    "the_team_typically_replies_in_300": "Teamet svarer normalt påi under 5 min.",
    "the_team_typically_replies_in_345600": "Teamet svarer normalt indenfor 4 dage",
    "the_team_typically_replies_in_3600": "Teamet svarer normalt påi under 1 time",
    "the_team_typically_replies_in_43200": "Teamet svarer normalt i løbet af 12 timer",
    "the_team_typically_replies_in_432000": "Teamet svarer normalt indenfor 5 dage",
    "the_team_typically_replies_in_518400": "Teamet svarer normalt indenfor 6 dage",
    "the_team_typically_replies_in_60": "Teamet svarer normalt i løbet af 1 minut",
    "the_team_typically_replies_in_600": "Teamet svarer normalt påi under 10 min.",
    "the_team_typically_replies_in_604800": "Teamet svarer normalt påi under 1 uge.",
    "the_team_typically_replies_in_7200": "Teamet svarer normalt påi under 2 timer",
    "the_team_typically_replies_in_86400": "Teamet svarer normalt efter 1 dag.",
    "the_team_typically_replies_in_900": "Teamet svarer normalt på under 15 minutter",
    "the_team_typically_replies_in_day": "Teamet svarer normalt i løbet af en dag.",
    "the_team_typically_replies_in_hours": "Teamet svarer normalt i løbet af få timer.",
    "the_team_typically_replies_in_minutes": "Teamet svarer normalt i løbet af få minutter.",
    "the_team_typically_replies_in_under_n_hours": "Teamet svarer normalt i løbet af {hours} t.",
    "the_team_typically_replies_in_under_n_min": "Teamet svarer normalt i løbet af {minutes} m.",
    "the_team_typically_replies_in_unknown": "Teamet svarer så hurtigt, de kan",
    "the_team_typically_replies_in_week": "Teamet svarer normalt i løbet af en uge.",
    "thinking": "Tænker ...",
    "tickets_cta_text": "Har du spørgsmål til denne billet?",
    "tickets_have_a_question": "Har du et spørgsmål om dette problem?",
    "tickets_space_title": "Billetter",
    "time_future_1h": "1 time",
    "time_future_2h": "2 timer",
    "time_future_30m": "30 minutter",
    "time_future_3h": "3 timer",
    "time_future_day_0": "mandag",
    "time_future_day_1": "tirsdag",
    "time_future_day_2": "onsdag",
    "time_future_day_3": "torsdag",
    "time_future_day_4": "fredag",
    "time_future_day_5": "lørdag",
    "time_future_day_6": "søndag",
    "time_future_later_today": "senere i dag",
    "time_future_next_week": "næste uge",
    "time_future_tomorrow": "i morgen",
    "try_use_different_keywords": "Prøv at bruge forskellige søgeord, eller skriv dit fulde spørgsmål",
    "upfront_collection_form_close_out": "Du kan når som helst vende tilbage hertil for at se svar og indsende opdateringer.",
    "upfront_collection_form_introduction": "Del noget kontekst for at hjælpe teamet med at kunne reagere bedre og hurtigere.",
    "upfront_collection_form_submission_confirmation": "Tak for at sende den kontekst. For at spare tid senere, kan du tilføje flere oplysninger nu.",
    "upfront_collection_form_subtitle": "Send teamet noget kontekst",
    "upfront_collection_form_subtitle_submitted": "De vil overveje denne kontekst",
    "upfront_collection_form_title": "Få hurtigere hjælp",
    "upfront_collection_form_title_submitted": "Sendt til teamet!",
    "view_all": "Se alle",
    "view_conversation": "Se samtale",
    "we_are_here_to_help": "Vi hjælper gerne",
    "we_run_on_intercom": "Vi bruger Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Når en holdkammerat ændrer tilstanden for en samtale...",
    "working_on_it": "Arbejder på det ...",
    "you_are_all_up_to_date": "Du er helt opdateret!",
    "your_checklists": "Dine tjeklister",
    "your_conversations": "Dine samtaler",
    "your_recent_conversations": "Seneste samtaler",
    "your_recent_messages": "Seneste meddelelser"
  },
  "de": {
    "a_day": "Ein Tag",
    "a_few_hours": "Ein paar Stunden",
    "a_few_minutes": "Ein paar Minuten",
    "a_week": "Eine Woche",
    "active_state": "Aktiv",
    "ai_answer": "KI-Antwort",
    "ai_answer_information": "KI-Antwort, Informationen",
    "ai_bot": "KI-Bot",
    "almost_there": "Fast geschafft …",
    "answer": "Antwort",
    "answer_bot_acknowledge_duplicate_content": "Du hast das bereits gesehen, aber das ist die beste verfügbare Antwort.",
    "answer_bot_all_done": "Das hat geholfen 👍",
    "answer_bot_ask_another_question": "Eine andere Frage stellen 🔁",
    "answer_bot_ask_something_else": "Eine andere Frage stellen 🔁",
    "answer_bot_connect_to_an_agent": "Mit einem Mitarbeiter verbinden 👤",
    "answer_bot_did_that_help": "Hat dir diese Antwort geholfen, oder suchst du nach etwas anderem?",
    "answer_bot_followup_expectations_prefix": "Das Team wird sich diesbezüglich melden.",
    "answer_bot_get_more_help": "Mehr Hilfe erhalten 🌐",
    "answer_bot_get_more_help_followup": "Dieser Link könnte hilfreich sein:",
    "answer_bot_i_have_another_question": "Ich habe eine andere Frage 🔁",
    "answer_bot_im_not_done_yet": "Ich bin noch nicht fertig 🔁",
    "answer_bot_keep_waiting": "Nein, ich brauche immer noch Hilfe. 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Sie können Ihre Frage umformulieren oder sich weitere Hilfe holen",
    "answer_bot_looping_first_no_answer": "Es tut mir leid, aber als Bot konnte ich darauf leider keine Antwort finden.",
    "answer_bot_looping_first_no_answer_followup": "Du kannst deine Frage umformulieren oder mit unserem Team sprechen",
    "answer_bot_looping_no_answer": "Ich bin ein Bot und konnte leider keine Antwort hierfür finden. Sie haben folgende Möglichkeiten:",
    "answer_bot_more_answers": "Zeig mir mehr 👀",
    "answer_bot_no_answer_found": "Es tut uns leid, aber darauf haben wir leider keine schnelle Antwort. Hier sind einige andere Optionen.",
    "answer_bot_resolve_followup": "Du kannst jederzeit hier antworten, wenn es sonst noch etwas gibt.",
    "answer_bot_talk_to_person": "Mit einer Person sprechen 👤",
    "answer_bot_that_answered_my_question": "Das hat meine Frage beantwortet 👍",
    "answer_bot_thats_it": "Das ist es 👍",
    "answer_bot_wait_for_the_team": "Auf das Team warten 💬",
    "answerbot_ask_for_more_context": "Sicher. Während wir warten, könnten Sie uns weitere Details zu Ihrer Situation oder Frage mitteilen? So können wir schneller eine Antwort finden und ich kann möglicherweise weitere Informationen für Sie bereitstellen.",
    "answerbot_introduce_sneaky_answer": "Vielen Dank für diesen zusätzlichen Kontext. Ich habe einige neue Informationen gefunden, die Ihnen helfen könnten.",
    "article_search_hint_text": "Artikel durchsuchen ...",
    "ask_a_question": "Eine Frage stellen",
    "ask_a_question_placeholder": "Eine Frage stellen ...",
    "ask_a_question_with_ellipsis": "Stelle eine Frage …",
    "ask_another_question_with_ellipsis": "Stelle eine andere Frage …",
    "ask_for_the_team_if_needed": "Fragen Sie bei Bedarf nach dem Team",
    "ask_me_a_question": "Stellen Sie mir eine Frage",
    "ask_our_bot_a_question": "Stelle unserem Bot eine Frage",
    "avatar": "Avatar",
    "avatars": "Avatare",
    "bot": "Bot",
    "bot_answers_instantly": "Bot antwortet sofort",
    "bot_expectation_text": "Unser Bot kann viele gängige Fragen beantworten",
    "bot_intro_default_part_1": "Hallo! Hier spricht ein Bot. Ich bin hier, um deine Fragen zu beantworten, aber du hast immer die Möglichkeit, mit unserem Team zu sprechen.",
    "bot_intro_default_part_2": "Also, was führt dich heute hierher?",
    "bot_is_still_thinking": "Bot denkt immer noch nach …",
    "bot_is_thinking": "Bot denkt nach …",
    "browse_collections": "Sammlungen durchsuchen",
    "chat_with_us": "Chatten Sie mit uns",
    "check_later_for_updates": "Schaue später wieder vorbei, um zu sehen, ob Aktualisierungen vorliegen.",
    "checklist_card_first_step": "Erster Schritt",
    "checklist_card_next_step": "Nächster Schritt",
    "checklist_completed": "Abgeschlossen",
    "checklist_in_progress_time_remaining_plural": "Noch etwa {minutes} Minuten",
    "checklist_in_progress_time_remaining_singular": "Noch etwa eine Minute",
    "checklist_mark_step_as_complete": "Als abgeschlossen markieren",
    "checklist_not_started_time_remaining_plural": "Etwa {minutes} Minuten",
    "checklist_not_started_time_remaining_singular": "Etwa ein Minute",
    "checklist_number_of_steps_plural": "{number_of_steps} Schritte",
    "checklist_number_of_steps_progress_plural": "{steps_completed} von {total_steps} abgeschlossen",
    "checklist_number_of_steps_progress_singular": "Einer von {total_steps} erledigt",
    "checklist_number_of_steps_singular": "Ein Schritt",
    "checklist_sender_name": "Geteilt von {name}",
    "checklist_step_action_button": "Schaltfläche „Schrittaktion“",
    "checklist_step_completed": "Abgeschlossen",
    "contact_support": "Support kontaktieren",
    "contact_us": "Kontakt aufnehmen",
    "continue_the_conversation": "Die Unterhaltung fortsetzen",
    "conversation_card_office_hours_header": "Wann wir wieder online sind",
    "conversation_card_reply_time_header": "Unsere übliche Reaktionszeit",
    "conversation_card_reply_time_header_m5": "Übliche Antwortzeit",
    "conversation_card_unknown_response_time": "Wir antworten so bald wie möglich",
    "conversation_part_sample_text": "Wie kann ich Ihnen helfen? Sie haben bei Bedarf jederzeit die Möglichkeit, mit unserem Team zu sprechen.",
    "conversations_space_title": "Gespräche",
    "create_a_ticket": "Ticket erstellen",
    "currently_no_help_articles": "Derzeit sind keine Hilfeartikel verfügbar.",
    "customer_sends_their_first_message": "Kunde sendet seine erste Nachricht ...",
    "expected_response_delay_10800": "unter 3 Stunden",
    "expected_response_delay_120": "unter 2 Minuten",
    "expected_response_delay_1200": "unter 20 Minuten",
    "expected_response_delay_14400": "unter 4 Stunden",
    "expected_response_delay_172800": "2 Tage",
    "expected_response_delay_180": "unter 3 Minuten",
    "expected_response_delay_1800": "unter 30 Minuten",
    "expected_response_delay_18000": "unter 5 Stunden",
    "expected_response_delay_21600": "unter 6 Stunden",
    "expected_response_delay_240": "unter 4 Minuten",
    "expected_response_delay_259200": "3 Tage",
    "expected_response_delay_300": "unter 5 Minuten",
    "expected_response_delay_345600": "4 Tage",
    "expected_response_delay_3600": "unter 1 Stunde",
    "expected_response_delay_43200": "unter 12 Stunden",
    "expected_response_delay_432000": "5 Tage",
    "expected_response_delay_518400": "Sechs Tage",
    "expected_response_delay_60": "unter 1 Minute",
    "expected_response_delay_600": "unter 10 Minuten",
    "expected_response_delay_604800": "unter 1 Woche",
    "expected_response_delay_7200": "unter 2 Stunden",
    "expected_response_delay_86400": "1 Tag",
    "expected_response_delay_900": "unter 15 Minuten",
    "expected_response_delay_unknown": "So bald wie möglich",
    "extra_labels_count": "+ {labels_count} Labels",
    "for_best_results_provide_detail": "Um optimale Ergebnisse zu erzielen, geben Sie so viele Details wie möglich an",
    "generating_answer": "Antwort wird generiert …",
    "generic_triage_default": "Wir brauchen nur ein paar zusätzliche Informationen, um dich mit der richtigen Person zu verbinden",
    "get_an_instant_answer": "Erhalte sofort eine Antwort",
    "get_help": "Hilfe erhalten",
    "go_to": "Zu {app_name} gehen",
    "help_space_title": "Hilfe",
    "home_header_mobile_hello_firstname": "Hallo {firstname}.",
    "home_header_mobile_hello_there": "Hallo",
    "home_space_title": "Home",
    "how_can_we_help": "Wie können wir helfen?",
    "how_would_you_rate_this": "Wie würdest du das bewerten?",
    "learn_more": "Mehr erfahren",
    "message_placeholder": "Nachricht senden...",
    "messages_space_title": "Nachrichten",
    "messenger_bot_reply_time": "Unser Bot antwortet sofort",
    "messenger_settings_introduction": "Wie können wir helfen?",
    "multiple_articles": "{total_articles} Artikel",
    "multiple_collections": "{total_collection} Sammlungen",
    "new_conversation": "Neue Unterhaltung",
    "news": "News",
    "news_empty_title": "Noch keine Neuigkeiten",
    "news_latest_subtitle": "Von Team {team_name}",
    "news_latest_title": "Aktuelles",
    "news_older_title": "Älter",
    "news_space_title": "News",
    "newsfeed": "Newsfeed",
    "no_articles_to_display": "Noch keine Artikel",
    "no_help_articles_to_display": "Keine Hilfeartikel",
    "no_results_for_searchterm": "Keine Ergebnisse für {searchTerm}",
    "no_tasks": "Keine Aufgaben",
    "no_tasks_available_currently": "Derzeit sind keine Aufgaben verfügbar.",
    "ok_the_team_typically_replies_in_day": "Okay. Das Team antwortet normalerweise innerhalb eines Tages.",
    "ok_the_team_typically_replies_in_hours": "Okay. Das Team antwortet normalerweise innerhalb weniger Stunden.",
    "ok_the_team_typically_replies_in_minutes": "Okay. Das Team antwortet normalerweise innerhalb weniger Minuten.",
    "ok_the_team_typically_replies_in_under_n_hours": "Okay. Das Team antwortet normalerweise in unter {hours} Std.",
    "ok_the_team_typically_replies_in_under_n_min": "Okay. Das Team antwortet normalerweise in unter {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Okay. Das Team antwortet normalerweise innerhalb einer Woche.",
    "our_bot_and_team_can_help": "Unser Bot und unser Team können helfen",
    "our_bot_answers_instantly": "Unser Bot antwortet sofort",
    "powered_by_intercom": "Unterstützt von Intercom",
    "previous_bot_hands_over": "Inhalt aus dem vorherigen Bot ...",
    "proactive_expected_response_delay_10800": "Antwortet normalerweise in unter 3 Std",
    "proactive_expected_response_delay_120": "Antwortet normalerweise in unter 2 Min.",
    "proactive_expected_response_delay_1200": "Antwortet normalerweise in unter 20m",
    "proactive_expected_response_delay_14400": "Antwortet normalerweise in unter 4 Std",
    "proactive_expected_response_delay_172800": "Antwortet normalerweise innerhalb von 2T",
    "proactive_expected_response_delay_180": "Antwortet normalerweise in unter 3 Min.",
    "proactive_expected_response_delay_1800": "Antwortet normalerweise in unter 30m",
    "proactive_expected_response_delay_18000": "Antwortet normalerweise in unter 5 Std",
    "proactive_expected_response_delay_21600": "Antwortet normalerweise in unter 6 Std",
    "proactive_expected_response_delay_240": "Antwortet normalerweise in unter 4 Min.",
    "proactive_expected_response_delay_259200": "Antwortet normalerweise innerhalb von 3 T.",
    "proactive_expected_response_delay_300": "Antwortet normalerweise in unter 5m",
    "proactive_expected_response_delay_345600": "Antwortet normalerweise innerhalb von 4 T.",
    "proactive_expected_response_delay_3600": "Antwortet normalerweise in unter 1 Std",
    "proactive_expected_response_delay_43200": "Antwortet normalerweise innerhalb von 12 h",
    "proactive_expected_response_delay_432000": "Antwortet normalerweise innerhalb von 5 T.",
    "proactive_expected_response_delay_518400": "Antwortet normalerweise innerhalb von 6 T.",
    "proactive_expected_response_delay_60": "Antwortet normalerweise in unter 1 Min.",
    "proactive_expected_response_delay_600": "Antwortet normalerweise in unter 10m",
    "proactive_expected_response_delay_604800": "Antwortet normalerweise in unter 1W",
    "proactive_expected_response_delay_7200": "Antwortet normalerweise in unter 2 Std",
    "proactive_expected_response_delay_86400": "Antwortet normalerweise innerhalb von 1T",
    "proactive_expected_response_delay_900": "Antwortet normalerweise in unter 15 Min.",
    "proactive_expected_response_delay_unknown": "Wird so bald wie möglich antworten",
    "profile_location": "{time} in {location}",
    "recent_ticket": "Aktuelles Ticket",
    "recent_tickets": "Aktuelle Tickets",
    "search_browse_empty_state_text": "Leider konnten wir dazu keine Ergebnisse finden. Versuche, deine Suche umzuformulieren.",
    "search_for_help": "Nach Hilfe suchen",
    "see_all": "Alle anzeigen",
    "see_all_conversations": "Alle deine Unterhaltungen anzeigen",
    "see_previous_conversations": "Deine vorherigen Gespräche anzeigen",
    "send": "Senden",
    "send_a_message": "Sende eine Nachricht…",
    "send_a_message_reply_time": "Die übliche Antwortzeit beträgt",
    "send_us_a_message": "Sende uns eine Nachricht",
    "set_expectations_bot_email_header": "Antworten erhältst du hier und per E-Mail:",
    "set_expectations_bot_office_hours_header": "Wann das Team wieder da ist:",
    "set_expectations_bot_sms_header": "Antworten erhältst du hier und per SMS:",
    "set_expectations_replies_to_email": "Du erhältst Antworten hier und an <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Die Antwort wird hier angezeigt und an {user_email} gesendet",
    "set_expectations_team_reply_asap": "Das Team wird so bald wie möglich antworten.",
    "shared_by": "Geteilt von",
    "show_less": "Weniger anzeigen",
    "single_article": "1 Artikel",
    "single_collection": "Eine Sammlung",
    "source": "Quelle",
    "sources": "Quellen",
    "start_a_conversation": "Beginne eine Unterhaltung",
    "start_conversation": "Starte eine Unterhaltung",
    "still_generating_answer": "Antwort wird immer noch generiert …",
    "still_thinking": "Ich denke immer noch nach …",
    "still_working_on_it": "Ich arbeite immer noch daran …",
    "tasks_space_title": "Aufgaben",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} wird so bald wie möglich antworten",
    "teammate_changes_team_assignment_of_a_conversation": "Kollege hat die Gesprächszuweisung geändert …",
    "tell_us_what_you_need": "Sag uns, wo du Hilfe benötigst – unser Bot beantwortet Deine Fragen gerne",
    "the_team_can_help_if_needed": "Das Team kann bei Bedarf helfen",
    "the_team_typically_replies_in_10800": "Das Team antwortet normalerweise in unter 3 Std.",
    "the_team_typically_replies_in_120": "Das Team antwortet normalerweise in unter 2 Min.",
    "the_team_typically_replies_in_1200": "Das Team antwortet normalerweise in unter 20 Min.",
    "the_team_typically_replies_in_14400": "Das Team antwortet normalerweise in unter 4 Std.",
    "the_team_typically_replies_in_172800": "Das Team antwortet normalerweise innerhalb von 2 T.",
    "the_team_typically_replies_in_180": "Das Team antwortet normalerweise in unter 3 Min.",
    "the_team_typically_replies_in_1800": "Das Team antwortet normalerweise in unter 30 Min.",
    "the_team_typically_replies_in_18000": "Das Team antwortet normalerweise in unter 5 Std.",
    "the_team_typically_replies_in_21600": "Das Team antwortet normalerweise in unter 6 Std.",
    "the_team_typically_replies_in_240": "Das Team antwortet normalerweise in unter 4 Min.",
    "the_team_typically_replies_in_259200": "Das Team antwortet normalerweise innerhalb von 3 T.",
    "the_team_typically_replies_in_300": "Das Team antwortet normalerweise in unter 5 Min.",
    "the_team_typically_replies_in_345600": "Das Team antwortet normalerweise innerhalb von 4 T.",
    "the_team_typically_replies_in_3600": "Das Team antwortet normalerweise in unter 1 Std.",
    "the_team_typically_replies_in_43200": "Das Team antwortet normalerweise in unter 12 h",
    "the_team_typically_replies_in_432000": "Das Team antwortet normalerweise innerhalb von 5 T.",
    "the_team_typically_replies_in_518400": "Das Team antwortet normalerweise innerhalb von 6 T.",
    "the_team_typically_replies_in_60": "Das Team antwortet normalerweise in unter 1 Min.",
    "the_team_typically_replies_in_600": "Das Team antwortet normalerweise in unter 10 Min.",
    "the_team_typically_replies_in_604800": "Das Team antwortet normalerweise in unter 1 W.",
    "the_team_typically_replies_in_7200": "Das Team antwortet normalerweise in unter 2 Std.",
    "the_team_typically_replies_in_86400": "Das Team antwortet normalerweise innerhalb von 1 T.",
    "the_team_typically_replies_in_900": "Das Team antwortet normalerweise in unter 15 Min.",
    "the_team_typically_replies_in_day": "Das Team antwortet normalerweise innerhalb eines Tages.",
    "the_team_typically_replies_in_hours": "Das Team antwortet normalerweise innerhalb weniger Stunden.",
    "the_team_typically_replies_in_minutes": "Das Team antwortet normalerweise innerhalb weniger Minuten.",
    "the_team_typically_replies_in_under_n_hours": "Das Team antwortet normalerweise in unter {hours} Std.",
    "the_team_typically_replies_in_under_n_min": "Das Team antwortet normalerweise in unter {minutes}m.",
    "the_team_typically_replies_in_unknown": "Das Team wird so bald wie möglich antworten",
    "the_team_typically_replies_in_week": "Das Team antwortet normalerweise innerhalb einer Woche.",
    "thinking": "Ich denke nach …",
    "tickets_cta_text": "Haben Sie eine Frage zu diesem Ticket?",
    "tickets_have_a_question": "Haben Sie eine Frage zu diesem Problem?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "1 Stunde",
    "time_future_2h": "2 Stunden",
    "time_future_30m": "30 Minuten",
    "time_future_3h": "3 Stunden",
    "time_future_day_0": "Montag",
    "time_future_day_1": "Dienstag",
    "time_future_day_2": "Mittwoch",
    "time_future_day_3": "Donnerstag",
    "time_future_day_4": "Freitag",
    "time_future_day_5": "Samstag",
    "time_future_day_6": "Sonntag",
    "time_future_later_today": "Heute noch",
    "time_future_next_week": "Nächste Woche",
    "time_future_tomorrow": "Morgen",
    "try_use_different_keywords": "Probiere es mit anderen Stichwörtern oder gib deine komplette Frage ein",
    "upfront_collection_form_close_out": "Du kannst jederzeit hierher zurückkehren, um Antworten einzusehen und Updates zu senden.",
    "upfront_collection_form_introduction": "Gib etwas Kontext an, damit das Team besser und schneller reagieren kann.",
    "upfront_collection_form_submission_confirmation": "Danke, dass du uns diesen Kontext mitgeteilt hast. Um später Zeit zu sparen, kannst du jetzt weitere Details hinzufügen.",
    "upfront_collection_form_subtitle": "Sende dem Team etwas Kontext",
    "upfront_collection_form_subtitle_submitted": "Das Team wird diesen Kontext berücksichtigen",
    "upfront_collection_form_title": "Schneller Hilfe erhalten",
    "upfront_collection_form_title_submitted": "Ans Team gesendet!",
    "view_all": "Alle anzeigen",
    "view_conversation": "Gespräch anzeigen",
    "we_are_here_to_help": "Wir helfen Ihnen gerne weiter.",
    "we_run_on_intercom": "Wir nutzen Intercom.",
    "when_a_teammate_changes_the_state_of_a_conversation": "Wenn ein Kollege den Status eines Gesprächs ändert …",
    "working_on_it": "Ich arbeite daran …",
    "you_are_all_up_to_date": "Du bist auf dem neuesten Stand!",
    "your_checklists": "Deine Checklisten",
    "your_conversations": "Deine Unterhaltungen",
    "your_recent_conversations": "Aktuelle Gespräche",
    "your_recent_messages": "Aktuelle Nachrichten"
  },
  "de-form": {
    "a_day": "Ein Tag",
    "a_few_hours": "Ein paar Stunden",
    "a_few_minutes": "Ein paar Minuten",
    "a_week": "Eine Woche",
    "active_state": "Aktiv",
    "ai_answer": "KI-Antwort",
    "ai_answer_information": "KI-Antwort, Informationen",
    "ai_bot": "KI-Bot",
    "almost_there": "Fast geschafft …",
    "answer": "Antwort",
    "answer_bot_acknowledge_duplicate_content": "Sie haben dies zwar bereits gesehen, doch dies ist die beste verfügbare Antwort.",
    "answer_bot_all_done": "Das hat mir weitergeholfen 👍",
    "answer_bot_ask_another_question": "Eine andere Frage stellen 🔁",
    "answer_bot_ask_something_else": "Eine andere Frage stellen 🔁",
    "answer_bot_connect_to_an_agent": "Mit einem Mitarbeiter sprechen 👤",
    "answer_bot_did_that_help": "Hat Ihnen diese Antwort weitergeholfen oder suchen Sie nach etwas anderem?",
    "answer_bot_followup_expectations_prefix": "Das Team wird sich hierzu bei Ihnen melden.",
    "answer_bot_get_more_help": "Weitere Hilfe erhalten 🌐",
    "answer_bot_get_more_help_followup": "Dieser Link könnte hilfreich sein:",
    "answer_bot_i_have_another_question": "Ich habe eine weitere Frage 🔁",
    "answer_bot_im_not_done_yet": "Ich bin noch nicht fertig 🔁",
    "answer_bot_keep_waiting": "Nein, ich brauche immer noch Hilfe. 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Sie können Ihre Frage umformulieren oder sich weitere Hilfe holen",
    "answer_bot_looping_first_no_answer": "Ich bin ein Bot und konnte leider keine Antwort hierfür finden.",
    "answer_bot_looping_first_no_answer_followup": "Sie können Ihre Frage umformulieren oder mit unserem Team sprechen",
    "answer_bot_looping_no_answer": "Ich bin ein Bot und konnte leider keine Antwort hierfür finden. Sie haben folgende Möglichkeiten:",
    "answer_bot_more_answers": "Ich möchte mehr sehen 👀",
    "answer_bot_no_answer_found": "Leider haben wir keine schnelle Antwort auf diese Frage. Hier sind einige weitere Optionen.",
    "answer_bot_resolve_followup": "Sie können auch hier antworten, wenn Ihnen sonst noch etwas einfällt.",
    "answer_bot_talk_to_person": "Mit einem Mitarbeiter sprechen 👤",
    "answer_bot_that_answered_my_question": "Das hat meine Frage beantwortet 👍",
    "answer_bot_thats_it": "Super, danke 👍",
    "answer_bot_wait_for_the_team": "Auf das Team warten 💬",
    "answerbot_ask_for_more_context": "Sicher. Während wir warten, könnten Sie uns weitere Details zu Ihrer Situation oder Frage mitteilen? So können wir schneller eine Antwort finden und ich kann möglicherweise weitere Informationen für Sie bereitstellen.",
    "answerbot_introduce_sneaky_answer": "Vielen Dank für diesen zusätzlichen Kontext. Ich habe einige neue Informationen gefunden, die Ihnen helfen könnten.",
    "article_search_hint_text": "Artikel durchsuchen …",
    "ask_a_question": "Eine Frage stellen",
    "ask_a_question_placeholder": "Eine Frage stellen …",
    "ask_a_question_with_ellipsis": "Stellen Sie eine Frage …",
    "ask_another_question_with_ellipsis": "Stellen Sie eine andere Frage …",
    "ask_for_the_team_if_needed": "Fragen Sie bei Bedarf nach dem Team",
    "ask_me_a_question": "Stellen Sie mir eine Frage",
    "ask_our_bot_a_question": "Stellen Sie unserem Bot eine Frage",
    "avatar": "Avatar",
    "avatars": "Avatare",
    "bot": "Bot",
    "bot_answers_instantly": "Bot antwortet sofort",
    "bot_expectation_text": "Unser Bot kann viele häufig gestellten Fragen beantworten.",
    "bot_intro_default_part_1": "Hi! Ich bin ein Bot. Zunächst werde ich versuchen, Ihre Fragen zu beantworten. Sie können aber jederzeit mit unserem Team sprechen.",
    "bot_intro_default_part_2": "Wie kann ich Ihnen weiterhelfen?",
    "bot_is_still_thinking": "Bot denkt immer noch nach …",
    "bot_is_thinking": "Bot denkt nach …",
    "browse_collections": "Sammlungen durchsuchen",
    "chat_with_us": "Chatten Sie mit uns",
    "check_later_for_updates": "Schauen Sie zu einem späteren Zeitpunkt wieder vorbei, um zu sehen, ob Aktualisierungen vorliegen.",
    "checklist_card_first_step": "Erster Schritt",
    "checklist_card_next_step": "Nächster Schritt",
    "checklist_completed": "Abgeschlossen",
    "checklist_in_progress_time_remaining_plural": "Noch etwa {minutes} Minuten",
    "checklist_in_progress_time_remaining_singular": "Noch etwa eine Minute",
    "checklist_mark_step_as_complete": "Als abgeschlossen markieren",
    "checklist_not_started_time_remaining_plural": "Etwa {minutes} Minuten",
    "checklist_not_started_time_remaining_singular": "Etwa eine Minute",
    "checklist_number_of_steps_plural": "{number_of_steps} Schritte",
    "checklist_number_of_steps_progress_plural": "{steps_completed} von {total_steps} abgeschlossen",
    "checklist_number_of_steps_progress_singular": "Einer von {total_steps} erledigt",
    "checklist_number_of_steps_singular": "Ein Schritt",
    "checklist_sender_name": "Geteilt von {name}",
    "checklist_step_action_button": "Schaltfläche „Schrittaktion“",
    "checklist_step_completed": "Abgeschlossen",
    "contact_support": "Wenden Sie sich an den Support",
    "contact_us": "Kontaktieren Sie uns",
    "continue_the_conversation": "Unterhaltung fortsetzen",
    "conversation_card_office_hours_header": "Wir sind wieder online",
    "conversation_card_reply_time_header": "Unsere übliche Antwortzeit",
    "conversation_card_reply_time_header_m5": "Übliche Antwortzeit",
    "conversation_card_unknown_response_time": "Wir antworten so bald wie möglich",
    "conversation_part_sample_text": "Hallo, wie kann ich helfen? Sie können bei Bedarf jederzeit mit unserem Team sprechen.",
    "conversations_space_title": "Gespräche",
    "create_a_ticket": "Ticket erstellen",
    "currently_no_help_articles": "Derzeit sind keine Hilfeartikel verfügbar.",
    "customer_sends_their_first_message": "Kunde sendet seine erste Nachricht ...",
    "expected_response_delay_10800": "weniger als 3 Stunden",
    "expected_response_delay_120": "weniger als 2 Minuten",
    "expected_response_delay_1200": "weniger als 20 Minuten",
    "expected_response_delay_14400": "weniger als 4 Stunden",
    "expected_response_delay_172800": "2 Tage",
    "expected_response_delay_180": "weniger als 3 Minuten",
    "expected_response_delay_1800": "weniger als 30 Minuten",
    "expected_response_delay_18000": "weniger als 5 Stunden",
    "expected_response_delay_21600": "weniger als 6 Stunden",
    "expected_response_delay_240": "weniger als 5 Minuten",
    "expected_response_delay_259200": "3 Tage",
    "expected_response_delay_300": "weniger als 5 Minuten",
    "expected_response_delay_345600": "4 Tage",
    "expected_response_delay_3600": "weniger als 1 Stunde",
    "expected_response_delay_43200": "weniger als 12 Stunden",
    "expected_response_delay_432000": "5 Tage",
    "expected_response_delay_518400": "Sechs Tage",
    "expected_response_delay_60": "weniger als 1 Minute",
    "expected_response_delay_600": "weniger als 10 Minuten",
    "expected_response_delay_604800": "weniger als 1 Woche",
    "expected_response_delay_7200": "weniger als 2 Stunden",
    "expected_response_delay_86400": "1 Tag",
    "expected_response_delay_900": "weniger als 15 Minuten",
    "expected_response_delay_unknown": "Sobald wie möglich",
    "extra_labels_count": "+ {labels_count} Labels",
    "for_best_results_provide_detail": "Um optimale Ergebnisse zu erzielen, geben Sie so viele Details wie möglich an",
    "generating_answer": "Antwort wird generiert …",
    "generic_triage_default": "Wir brauchen lediglich ein wenig mehr Informationen, um Sie mit der richtigen Person in Verbindung zu bringen",
    "get_an_instant_answer": "Sofort eine Antwort erhalten",
    "get_help": "Hilfe erhalten",
    "go_to": "Zu {app_name} gehen",
    "help_space_title": "Hilfe",
    "home_header_mobile_hello_firstname": "Hallo {firstname}.",
    "home_header_mobile_hello_there": "Hallo",
    "home_space_title": "Home",
    "how_can_we_help": "Wie können wir helfen?",
    "how_would_you_rate_this": "Wie würden Sie dies bewerten?",
    "learn_more": "Mehr erfahren",
    "message_placeholder": "Nachricht senden....",
    "messages_space_title": "Nachrichten",
    "messenger_bot_reply_time": "Unser Bot antwortet sofort",
    "messenger_settings_introduction": "Wie können wir helfen?",
    "multiple_articles": "{total_articles} Artikel",
    "multiple_collections": "{total_collection} Sammlungen",
    "new_conversation": "Neue Unterhaltung",
    "news": "News",
    "news_empty_title": "Noch keine Neuigkeiten",
    "news_latest_subtitle": "Von Team {team_name}",
    "news_latest_title": "Aktuelles",
    "news_older_title": "Älter",
    "news_space_title": "Neuigkeiten",
    "newsfeed": "Newsfeed",
    "no_articles_to_display": "Noch keine Artikel",
    "no_help_articles_to_display": "Keine Hilfeartikel",
    "no_results_for_searchterm": "Keine Ergebnisse für {searchTerm}",
    "no_tasks": "Keine Aufgaben",
    "no_tasks_available_currently": "Derzeit sind keine Aufgaben verfügbar.",
    "ok_the_team_typically_replies_in_day": "Okay. Das Team antwortet normalerweise innerhalb eines Tages.",
    "ok_the_team_typically_replies_in_hours": "Okay. Das Team antwortet normalerweise innerhalb weniger Stunden.",
    "ok_the_team_typically_replies_in_minutes": "Okay. Das Team antwortet normalerweise innerhalb weniger Minuten.",
    "ok_the_team_typically_replies_in_under_n_hours": "Okay. Das Team antwortet normalerweise in unter {hours} Std.",
    "ok_the_team_typically_replies_in_under_n_min": "Okay. Das Team antwortet normalerweise in unter {minutes} min.",
    "ok_the_team_typically_replies_in_week": "OK. Das Team antwortet normalerweise innerhalb einer Woche.",
    "our_bot_and_team_can_help": "Unser Bot und unser Team können helfen",
    "our_bot_answers_instantly": "Unser Bot antwortet sofort",
    "powered_by_intercom": "Unterstützt von Intercom",
    "previous_bot_hands_over": "Inhalte von dem vorherigen Bot ...",
    "proactive_expected_response_delay_10800": "Antwortet normalerweise in unter 3 Std",
    "proactive_expected_response_delay_120": "Antwortet in der Regel in weniger als 2 Min.",
    "proactive_expected_response_delay_1200": "Antwortet normalerweise in unter 20m",
    "proactive_expected_response_delay_14400": "Antwortet normalerweise in unter 4 Std",
    "proactive_expected_response_delay_172800": "Antwortet normalerweise innerhalb von 2T",
    "proactive_expected_response_delay_180": "Antwortet in der Regel in weniger als 3 Min.",
    "proactive_expected_response_delay_1800": "Antwortet normalerweise in unter 30m",
    "proactive_expected_response_delay_18000": "Antwortet normalerweise in unter 5 Std",
    "proactive_expected_response_delay_21600": "Antwortet normalerweise in unter 6 Std",
    "proactive_expected_response_delay_240": "Antwortet in der Regel in weniger als 5 Min.",
    "proactive_expected_response_delay_259200": "Antwortet normalerweise innerhalb von 3 Tagen",
    "proactive_expected_response_delay_300": "Antwortet normalerweise in unter 5m",
    "proactive_expected_response_delay_345600": "Antwortet normalerweise innerhalb von 4 Tagen",
    "proactive_expected_response_delay_3600": "Antwortet normalerweise in unter 1 Std",
    "proactive_expected_response_delay_43200": "Antwortet normalerweise innerhalb von 12 Stunden",
    "proactive_expected_response_delay_432000": "Antwortet normalerweise innerhalb von 5 Tagen",
    "proactive_expected_response_delay_518400": "Antwortet normalerweise innerhalb von 6 Tagen",
    "proactive_expected_response_delay_60": "Antwortet in der Regel in weniger als 1 Min.",
    "proactive_expected_response_delay_600": "Antwortet normalerweise in unter 10m",
    "proactive_expected_response_delay_604800": "Antwortet normalerweise in unter 1W",
    "proactive_expected_response_delay_7200": "Antwortet normalerweise in unter 2 Std",
    "proactive_expected_response_delay_86400": "Antwortet normalerweise innerhalb von 1T",
    "proactive_expected_response_delay_900": "Antwortet in der Regel in weniger als 15 Min.",
    "proactive_expected_response_delay_unknown": "Wird so bald wie möglich antworten",
    "profile_location": "{time} in {location}",
    "recent_ticket": "Aktuelles Ticket",
    "recent_tickets": "Aktuelle Tickets",
    "search_browse_empty_state_text": "Leider konnten wir hierfür keine Ergebnisse finden. Bitte versuchen Sie, Ihre Suche anders zu formulieren.",
    "search_for_help": "Nach Hilfe suchen",
    "see_all": "Alle anzeigen",
    "see_all_conversations": "Alle Ihre Unterhaltungen anzeigen",
    "see_previous_conversations": "Vorherige Unterhaltungen anzeigen",
    "send": "Senden",
    "send_a_message": "Senden Sie eine Nachricht…",
    "send_a_message_reply_time": "Die übliche Antwortzeit beträgt",
    "send_us_a_message": "Senden Sie uns eine Nachricht",
    "set_expectations_bot_email_header": "Ihre Antworten werden hier angezeigt und per E-Mail gesendet:",
    "set_expectations_bot_office_hours_header": "Das Team ist wieder für Sie da:",
    "set_expectations_bot_sms_header": "Ihre Antworten werden hier angezeigt und per SMS gesendet:",
    "set_expectations_replies_to_email": "Sie erhalten Antworten hier und an <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Sie erhalten Antworten hier und an {user_email}.",
    "set_expectations_team_reply_asap": "Das Team wird so bald wie möglich antworten.",
    "shared_by": "Geteilt von",
    "show_less": "Weniger anzeigen",
    "single_article": "1 Artikel",
    "single_collection": "Eine Sammlung",
    "source": "Quelle",
    "sources": "Quellen",
    "start_a_conversation": "Beginnen Sie eine Unterhaltung",
    "start_conversation": "Starten Sie eine Unterhaltung",
    "still_generating_answer": "Antwort wird immer noch generiert …",
    "still_thinking": "Ich denke immer noch nach …",
    "still_working_on_it": "Ich arbeite immer noch daran …",
    "tasks_space_title": "Aufgaben",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} wird so bald wie möglich antworten",
    "teammate_changes_team_assignment_of_a_conversation": "Kollege hat die Gesprächszuweisung geändert …",
    "tell_us_what_you_need": "Sagen Sie uns, wo Sie Hilfe benötigen – unser Bot beantwortet Ihre Fragen gerne",
    "the_team_can_help_if_needed": "Das Team kann Ihnen bei Bedarf weiterhelfen.",
    "the_team_typically_replies_in_10800": "Das Team antwortet normalerweise in unter 3 Std.",
    "the_team_typically_replies_in_120": "Das Team antwortet in der Regel in weniger als 2 Min.",
    "the_team_typically_replies_in_1200": "Das Team antwortet normalerweise in unter 20 Min.",
    "the_team_typically_replies_in_14400": "Das Team antwortet normalerweise in unter 4 Std.",
    "the_team_typically_replies_in_172800": "Das Team antwortet normalerweise innerhalb von 2 T.",
    "the_team_typically_replies_in_180": "Das Team antwortet in der Regel in weniger als 3 Min.",
    "the_team_typically_replies_in_1800": "Das Team antwortet normalerweise in unter 30 Min.",
    "the_team_typically_replies_in_18000": "Das Team antwortet normalerweise in unter 5 Std.",
    "the_team_typically_replies_in_21600": "Das Team antwortet normalerweise in unter 6 Std.",
    "the_team_typically_replies_in_240": "Das Team antwortet in der Regel in weniger als 4 Min.",
    "the_team_typically_replies_in_259200": "Das Team antwortet normalerweise innerhalb von 3 Tagen",
    "the_team_typically_replies_in_300": "Das Team antwortet normalerweise in unter 5 Min.",
    "the_team_typically_replies_in_345600": "Das Team antwortet normalerweise innerhalb von 4 Tagen",
    "the_team_typically_replies_in_3600": "Das Team antwortet normalerweise in unter 1 Std.",
    "the_team_typically_replies_in_43200": "Das Team antwortet normalerweise innerhalb von 12 Stunden",
    "the_team_typically_replies_in_432000": "Das Team antwortet normalerweise innerhalb von 5 Tagen",
    "the_team_typically_replies_in_518400": "Das Team antwortet normalerweise innerhalb von 6 Tagen",
    "the_team_typically_replies_in_60": "Das Team antwortet in der Regel in weniger als 1 Min.",
    "the_team_typically_replies_in_600": "Das Team antwortet normalerweise in unter 10 Min.",
    "the_team_typically_replies_in_604800": "Das Team antwortet normalerweise in unter 1 W.",
    "the_team_typically_replies_in_7200": "Das Team antwortet normalerweise in unter 2 Std.",
    "the_team_typically_replies_in_86400": "Das Team antwortet normalerweise innerhalb von 1 T.",
    "the_team_typically_replies_in_900": "Das Team antwortet normalerweise in weniger als 15 Min.",
    "the_team_typically_replies_in_day": "Das Team antwortet normalerweise innerhalb eines Tages.",
    "the_team_typically_replies_in_hours": "Das Team antwortet normalerweise innerhalb weniger Stunden.",
    "the_team_typically_replies_in_minutes": "Das Team antwortet normalerweise innerhalb weniger Minuten.",
    "the_team_typically_replies_in_under_n_hours": "Das Team antwortet normalerweise in unter {hours} Std.",
    "the_team_typically_replies_in_under_n_min": "Das Team antwortet normalerweise in unter {minutes}m.",
    "the_team_typically_replies_in_unknown": "Das Team wird so bald wie möglich antworten",
    "the_team_typically_replies_in_week": "Das Team antwortet normalerweise innerhalb einer Woche.",
    "thinking": "Ich denke nach …",
    "tickets_cta_text": "Haben Sie eine Frage zu diesem Ticket?",
    "tickets_have_a_question": "Haben Sie eine Frage zu diesem Problem?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "1 Stunde",
    "time_future_2h": "2 Stunden",
    "time_future_30m": "30 Minuten",
    "time_future_3h": "3 Stunden",
    "time_future_day_0": "Montag",
    "time_future_day_1": "Dienstag",
    "time_future_day_2": "Mittwoch",
    "time_future_day_3": "Donnerstag",
    "time_future_day_4": "Freitag",
    "time_future_day_5": "Samstag",
    "time_future_day_6": "Sonntag",
    "time_future_later_today": "Noch heute",
    "time_future_next_week": "Nächste Woche",
    "time_future_tomorrow": "Morgen",
    "try_use_different_keywords": "Versuchen Sie es mit anderen Stichwörtern oder geben Sie eine vollständige Frage ein",
    "upfront_collection_form_close_out": "Sie können sich hier jederzeit Antworten ansehen und neue Informationen senden.",
    "upfront_collection_form_introduction": "Geben Sie etwas Kontext an, damit das Team Ihnen rascher und besser antworten kann.",
    "upfront_collection_form_submission_confirmation": "Vielen Dank für diesen Kontext. Machen Sie gerne jetzt weitere Angaben, um später Zeit zu sparen",
    "upfront_collection_form_subtitle": "Senden Sie dem Team etwas Kontext",
    "upfront_collection_form_subtitle_submitted": "Dieser Kontext wird berücksichtigt",
    "upfront_collection_form_title": "Schneller Hilfe erhalten",
    "upfront_collection_form_title_submitted": "Ans Team gesendet!",
    "view_all": "Alles anzeigen",
    "view_conversation": "Gespräch anzeigen",
    "we_are_here_to_help": "Wir helfen Ihnen gerne weiter.",
    "we_run_on_intercom": "Wir nutzen Intercom.",
    "when_a_teammate_changes_the_state_of_a_conversation": "Wenn ein Kollege den Status eines Gesprächs ändert …",
    "working_on_it": "Ich arbeite daran …",
    "you_are_all_up_to_date": "Sie sind auf dem neuesten Stand!",
    "your_checklists": "Ihre Checklisten",
    "your_conversations": "Ihre Unterhaltungen",
    "your_recent_conversations": "Aktuelle Gespräche",
    "your_recent_messages": "Aktuelle Nachrichten"
  },
  "el": {
    "a_day": "Μία ημέρα",
    "a_few_hours": "Λίγες ώρες",
    "a_few_minutes": "Λίγα λεπτά",
    "a_week": "Μία εβδομάδα",
    "active_state": "Ενεργός",
    "ai_answer": "Απάντηση ΤΝ",
    "ai_answer_information": "Απάντηση ΤΝ, πληροφορίες",
    "ai_bot": "Bot ΤΝ",
    "almost_there": "Τελειώνει σχεδόν...",
    "answer": "Απάντηση",
    "answer_bot_acknowledge_duplicate_content": "Αυτό το περιεχόμενο έχει εμφανιστεί ήδη, αλλά είναι η καλύτερη διαθέσιμη απάντηση.",
    "answer_bot_all_done": "Ήταν χρήσιμο 👍",
    "answer_bot_ask_another_question": "Κάντε μια άλλη ερώτηση 🔁",
    "answer_bot_ask_something_else": "Θέλετε να ρωτήσετε κάτι άλλο; 🔁",
    "answer_bot_connect_to_an_agent": "Επικοινωνία με αντιπρόσωπο 👤",
    "answer_bot_did_that_help": "Βοήθησε αυτή η απάντηση ή αναζητάτε κάτι άλλο;",
    "answer_bot_followup_expectations_prefix": "Η ομάδα θα επικοινωνήσει μαζί σας σχετικά με αυτό.",
    "answer_bot_get_more_help": "Λήψη περισσότερης βοήθειας 🌐",
    "answer_bot_get_more_help_followup": "Αυτός ο σύνδεσμος μπορεί να σας φανεί χρήσιμος:",
    "answer_bot_i_have_another_question": "Έχω άλλη μία ερώτηση 🔁",
    "answer_bot_im_not_done_yet": "Έχω κι άλλες ερωτήσεις 🔁",
    "answer_bot_keep_waiting": "Όχι, εξακολουθώ να χρειάζομαι βοήθεια 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Μπορείτε να αναδιατυπώσετε την ερώτησή σας ή να λάβετε πρόσθετη βοήθεια",
    "answer_bot_looping_first_no_answer": "Δυστυχώς, δεν βρέθηκε απάντηση για αυτό το θέμα.",
    "answer_bot_looping_first_no_answer_followup": "Μπορείτε να αναδιατυπώσετε την ερώτησή σας ή να μιλήσετε με την ομάδα μας",
    "answer_bot_looping_no_answer": "Δυστυχώς, δεν βρέθηκε απάντηση για αυτό το θέμα. Οι ενέργειες που μπορείτε να κάνετε",
    "answer_bot_more_answers": "Εμφάνιση περισσότερων 👀",
    "answer_bot_no_answer_found": "Δυστυχώς, δεν έχουμε γρήγορη απάντηση για αυτό. Ακολουθούν κάποιες άλλες επιλογές.",
    "answer_bot_resolve_followup": "Μπορείτε να απαντήσετε εδώ ανά πάσα στιγμή αν χρειαστείτε κάτι άλλο.",
    "answer_bot_talk_to_person": "Συνομιλία με ένα άτομο 👤",
    "answer_bot_that_answered_my_question": "Απαντήθηκε η ερώτησή μου 👍",
    "answer_bot_thats_it": "Πολύ χρήσιμο 👍",
    "answer_bot_wait_for_the_team": "Αναμονή για την ομάδα 💬",
    "answerbot_ask_for_more_context": "Φυσικά. Ενώ περιμένουμε, μπορείτε να μοιραστείτε περισσότερες λεπτομέρειες σχετικά με την κατάσταση ή την ερώτησή σας; Θα μας βοηθήσει να βρούμε μια απάντηση πιο γρήγορα και ίσως βρω περισσότερες πληροφορίες για εσάς.",
    "answerbot_introduce_sneaky_answer": "Ευχαριστώ για τα πρόσθετα στοιχεία. Βρήκα μερικές νέες πληροφορίες που ίσως σας βοηθήσουν.",
    "article_search_hint_text": "Αναζήτηση άρθρων...",
    "ask_a_question": "Κάντε μια ερώτηση",
    "ask_a_question_placeholder": "Κάντε μια ερώτηση…",
    "ask_a_question_with_ellipsis": "Κάντε μια ερώτηση...",
    "ask_another_question_with_ellipsis": "Κάντε μια άλλη ερώτηση...",
    "ask_for_the_team_if_needed": "Ζητήστε τη βοήθεια της ομάδας αν χρειαστεί",
    "ask_me_a_question": "Κάνε μου μια ερώτηση",
    "ask_our_bot_a_question": "Κάντε μια ερώτηση στο bot μας",
    "avatar": "Avatar",
    "avatars": "Avatar",
    "bot": "Bot",
    "bot_answers_instantly": "Το bot απαντά άμεσα",
    "bot_expectation_text": "Το bot μας μπορεί να απαντήσει σε πολλές συνήθεις ερωτήσεις",
    "bot_intro_default_part_1": "Χαίρετε! Είμαι ένα bot. Θα προσπαθήσω να απαντήσω στις ερωτήσεις σας, αλλά, αν προτιμάτε, μπορείτε να μιλήσετε με έναν εκπρόσωπο της ομάδας μας.",
    "bot_intro_default_part_2": "Πώς μπορώ να σας βοηθήσω σήμερα;",
    "bot_is_still_thinking": "Το bot εξακολουθεί να σκέφτεται...",
    "bot_is_thinking": "Το bot σκέφτεται...",
    "browse_collections": "Περιήγηση σε συλλογές",
    "chat_with_us": "Συνομιλήστε μαζί μας",
    "check_later_for_updates": "Ελέγξτε ξανά αργότερα για ενημερώσεις.",
    "checklist_card_first_step": "Πρώτο βήμα",
    "checklist_card_next_step": "Επόμενο βήμα",
    "checklist_completed": "Ολοκληρώθηκε",
    "checklist_in_progress_time_remaining_plural": "Απομένουν περίπου {minutes} λεπτά",
    "checklist_in_progress_time_remaining_singular": "Απομένει περίπου 1 λεπτό",
    "checklist_mark_step_as_complete": "Σήμανση ως ολοκληρωμένου",
    "checklist_not_started_time_remaining_plural": "Περίπου {minutes} λεπτά",
    "checklist_not_started_time_remaining_singular": "Περίπου 1 λεπτό",
    "checklist_number_of_steps_plural": "{number_of_steps} βήματα",
    "checklist_number_of_steps_progress_plural": "Ολοκληρώθηκαν: {steps_completed} από {total_steps}",
    "checklist_number_of_steps_progress_singular": "Ολοκληρώθηκε 1 από {total_steps}",
    "checklist_number_of_steps_singular": "1 βήμα",
    "checklist_sender_name": "Κοινοποιήθηκε από: {name}",
    "checklist_step_action_button": "Κουμπί ενέργειας βήματος",
    "checklist_step_completed": "Ολοκληρώθηκε",
    "contact_support": "Επικοινωνήστε με την υποστήριξη",
    "contact_us": "Επικοινωνήστε μαζί μας",
    "continue_the_conversation": "Συνέχιση συζήτησης",
    "conversation_card_office_hours_header": "Επόμενη διαθεσιμότητα",
    "conversation_card_reply_time_header": "Τυπικός χρόνος απάντησης",
    "conversation_card_reply_time_header_m5": "Συνήθης χρόνος απάντησης",
    "conversation_card_unknown_response_time": "Θα απαντήσουμε το συντομότερο δυνατό",
    "conversation_part_sample_text": "Γεια σας! Πώς μπορώ να βοηθήσω; Θα έχετε πάντα την επιλογή να συνομιλήσετε με την ομάδα μας, εάν χρειαστεί.",
    "conversations_space_title": "Συζητήσεις",
    "create_a_ticket": "Δημιουργία δελτίου",
    "currently_no_help_articles": "Επί του παρόντος, δεν υπάρχουν διαθέσιμα άρθρα βοήθειας.",
    "customer_sends_their_first_message": "Ο πελάτης στέλνει το πρώτο μήνυμα...",
    "expected_response_delay_10800": "λιγότερο από 3 ώρες",
    "expected_response_delay_120": "λιγότερο από 2 λεπτά",
    "expected_response_delay_1200": "λιγότερο από 20 λεπτά",
    "expected_response_delay_14400": "λιγότερο από 4 ώρες",
    "expected_response_delay_172800": "2 ημέρες",
    "expected_response_delay_180": "λιγότερο από 3 λεπτά",
    "expected_response_delay_1800": "λιγότερο από 30 λεπτά",
    "expected_response_delay_18000": "λιγότερο από 5 ώρες",
    "expected_response_delay_21600": "λιγότερο από 6 ώρες",
    "expected_response_delay_240": "λιγότερο από 4 λεπτά",
    "expected_response_delay_259200": "3 ημέρες",
    "expected_response_delay_300": "λιγότερο από 5 λεπτά",
    "expected_response_delay_345600": "4 ημέρες",
    "expected_response_delay_3600": "λιγότερο από 1 ώρα",
    "expected_response_delay_43200": "λιγότερο από 12 ώρες",
    "expected_response_delay_432000": "5 ημέρες",
    "expected_response_delay_518400": "6 ημέρες",
    "expected_response_delay_60": "λιγότερο από 1 λεπτό",
    "expected_response_delay_600": "λιγότερο από 10 λεπτά",
    "expected_response_delay_604800": "λιγότερο από 1 εβδομάδα",
    "expected_response_delay_7200": "λιγότερο από 2 ώρες",
    "expected_response_delay_86400": "1 ημέρα",
    "expected_response_delay_900": "λιγότερο από 15 λεπτά",
    "expected_response_delay_unknown": "Το συντομότερο δυνατό",
    "extra_labels_count": "+ {labels_count} ετικέτες",
    "for_best_results_provide_detail": "Για καλύτερα αποτελέσματα, παρέχετε όσο το δυνατόν περισσότερες λεπτομέρειες",
    "generating_answer": "Δημιουργεί απάντηση...",
    "generic_triage_default": "Χρειαζόμαστε λίγες ακόμη πληροφορίες για να σας φέρουμε σε επαφή με το κατάλληλο άτομο",
    "get_an_instant_answer": "Λήψη άμεσης απάντησης",
    "get_help": "Λήψη βοήθειας",
    "go_to": "Μετάβαση στο {app_name}",
    "help_space_title": "Βοήθεια",
    "home_header_mobile_hello_firstname": "Χαίρετε, {firstname}.",
    "home_header_mobile_hello_there": "Χαίρετε",
    "home_space_title": "Αρχική",
    "how_can_we_help": "Πώς μπορούμε να σας βοηθήσουμε;",
    "how_would_you_rate_this": "Πώς θα το βαθμολογούσατε;",
    "learn_more": "Μάθετε περισσότερα",
    "message_placeholder": "Μήνυμα...",
    "messages_space_title": "Μηνύματα",
    "messenger_bot_reply_time": "Το bot μας θα απαντήσει αμέσως",
    "messenger_settings_introduction": "Πώς μπορούμε να σας βοηθήσουμε;",
    "multiple_articles": "{total_articles} άρθρα",
    "multiple_collections": "{total_collection} συλλογές",
    "new_conversation": "Νέα συζήτηση",
    "news": "Νέα",
    "news_empty_title": "Δεν υπάρχουν νέα",
    "news_latest_subtitle": "Από την ομάδα {team_name}",
    "news_latest_title": "Πιο πρόσφατα",
    "news_older_title": "Παλαιότερα",
    "news_space_title": "Νέα",
    "newsfeed": "Ροή νέων",
    "no_articles_to_display": "Δεν υπάρχουν άρθρα",
    "no_help_articles_to_display": "Δεν υπάρχουν άρθρα βοήθειας",
    "no_results_for_searchterm": "Δεν βρέθηκαν αποτελέσματα για τον όρο «{searchTerm}».",
    "no_tasks": "Δεν υπάρχουν εργασίες",
    "no_tasks_available_currently": "Δεν διατίθενται εργασίες.",
    "ok_the_team_typically_replies_in_day": "Ok, η ομάδα συνήθως απαντάει σε μία ημέρα.",
    "ok_the_team_typically_replies_in_hours": "Ok, η ομάδα συνήθως απαντάει σε λίγες ώρες.",
    "ok_the_team_typically_replies_in_minutes": "Ok, η ομάδα συνήθως απαντάει σε λίγα λεπτά.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, η ομάδα συνήθως απαντάει σε λιγότερο από {hours} ώρ.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, η ομάδα συνήθως απαντάει σε λιγότερο από {minutes} λεπ.",
    "ok_the_team_typically_replies_in_week": "Οκ, η ομάδα συνήθως απαντά σε μία εβδομάδα.",
    "our_bot_and_team_can_help": "Το bot και η ομάδα μας μπορούν να βοηθήσουν",
    "our_bot_answers_instantly": "Το bot μας απαντά άμεσα",
    "powered_by_intercom": "Με την υποστήριξη της Intercom",
    "previous_bot_hands_over": "Περιεχόμενο από το προηγούμενο bot...",
    "proactive_expected_response_delay_10800": "Συνήθως απαντά σε λιγότερο από 3 ώρες",
    "proactive_expected_response_delay_120": "Απάντηση συνήθως σε λιγότερο από 2 λεπτά",
    "proactive_expected_response_delay_1200": "Συνήθως απαντά σε λιγότερο από 20 λεπτά",
    "proactive_expected_response_delay_14400": "Συνήθως απαντά σε λιγότερο από 4 ώρες",
    "proactive_expected_response_delay_172800": "Συνήθως απαντά σε 2 ημέρες",
    "proactive_expected_response_delay_180": "Απάντηση συνήθως σε λιγότερο από 3 λεπτά",
    "proactive_expected_response_delay_1800": "Συνήθως απαντά σε λιγότερο από 30 λεπτά",
    "proactive_expected_response_delay_18000": "Συνήθως απαντά σε λιγότερο από 5 ώρες",
    "proactive_expected_response_delay_21600": "Συνήθως απαντά σε λιγότερο από 6 ώρες",
    "proactive_expected_response_delay_240": "Απάντηση συνήθως σε λιγότερο από 4 λεπτά",
    "proactive_expected_response_delay_259200": "Απάντηση συνήθως σε 3 ημέρες",
    "proactive_expected_response_delay_300": "Συνήθως απαντά σε λιγότερο από 5 λεπτά",
    "proactive_expected_response_delay_345600": "Απάντηση συνήθως σε 4 ημέρες",
    "proactive_expected_response_delay_3600": "Συνήθως απαντά σε λιγότερο από 1 ώρα",
    "proactive_expected_response_delay_43200": "Απάντηση συνήθως σε 12 ώρες",
    "proactive_expected_response_delay_432000": "Απάντηση συνήθως σε 5 ημέρες",
    "proactive_expected_response_delay_518400": "Απάντηση συνήθως σε 6 ημέρες",
    "proactive_expected_response_delay_60": "Απάντηση συνήθως σε λιγότερο από 1 λεπτό",
    "proactive_expected_response_delay_600": "Συνήθως απαντά σε λιγότερο από 10 λεπτά",
    "proactive_expected_response_delay_604800": "Συνήθως απαντά σε λιγότερο από 1 εβδομάδα",
    "proactive_expected_response_delay_7200": "Συνήθως απαντά σε λιγότερο από 2 ώρες",
    "proactive_expected_response_delay_86400": "Συνήθως απαντά σε 1 ημέρα",
    "proactive_expected_response_delay_900": "Απάντηση συνήθως σε λιγότερο από 15 λεπτά",
    "proactive_expected_response_delay_unknown": "Θα απαντήσει μόλις μπορέσει",
    "profile_location": "{time} σε {location}",
    "recent_ticket": "Πρόσφατο δελτίο υποστήριξης",
    "recent_tickets": "Πρόσφατα δελτία υποστήριξης",
    "search_browse_empty_state_text": "Δυστυχώς, δεν βρέθηκαν αποτελέσματα για αυτό το θέμα. Αλλάξτε τον όρο αναζήτησης.",
    "search_for_help": "Αναζήτηση για βοήθεια",
    "see_all": "Εμφάνιση όλων",
    "see_all_conversations": "Εμφάνιση όλων των συζητήσεών σας",
    "see_previous_conversations": "Εμφάνιση των προηγούμενων συζητήσεών σας",
    "send": "Αποστολή",
    "send_a_message": "Αποστολή μηνύματος…",
    "send_a_message_reply_time": "Ο συνήθης χρόνος απάντησης είναι",
    "send_us_a_message": "Αποστολή μηνύματος",
    "set_expectations_bot_email_header": "Θα λάβετε απάντηση εδώ και μέσω email:",
    "set_expectations_bot_office_hours_header": "Η ομάδα θα επιστρέψει:",
    "set_expectations_bot_sms_header": "Θα λάβετε απάντηση εδώ και μέσω SMS:",
    "set_expectations_replies_to_email": "Θα λάβετε απαντήσεις εδώ και στη διεύθυνση <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Θα λαμβάνετε απαντήσεις εδώ και στο {user_email}",
    "set_expectations_team_reply_asap": "Η ομάδα θα σας απαντήσει το συντομότερο δυνατό.",
    "shared_by": "Κοινοποιήθηκε από",
    "show_less": "Εμφάνιση λιγότερων",
    "single_article": "1 άρθρο",
    "single_collection": "1 συλλογή",
    "source": "Πηγή",
    "sources": "Πηγές",
    "start_a_conversation": "Ξεκινήστε μια συζήτηση",
    "start_conversation": "Ξεκινήστε μια συζήτηση",
    "still_generating_answer": "Εξακολουθεί να δημιουργεί απάντηση...",
    "still_thinking": "Εξακολουθεί να σκέφτεται...",
    "still_working_on_it": "Εξακολουθεί να το ψάχνει...",
    "tasks_space_title": "Εργασίες",
    "team_name_proactive_expected_response_delay_unknown": "Η εφαρμογή {app_name} θα απαντήσει όσο το δυνατό συντομότερα",
    "teammate_changes_team_assignment_of_a_conversation": "Ο συνεργάτης άλλαξε την ανάθεση συνομιλίας...",
    "tell_us_what_you_need": "Πείτε μας σε τι χρειάζεστε βοήθεια και το bot μας θα κάνει ό,τι καλύτερο μπορεί για να σας απαντήσει",
    "the_team_can_help_if_needed": "Η ομάδα μπορεί να βοηθήσει, αν χρειαστεί",
    "the_team_typically_replies_in_10800": "Η ομάδα συνήθως απαντάει σε λιγότερο από 3 ώρες",
    "the_team_typically_replies_in_120": "Η ομάδα απαντά συνήθως σε λιγότερο από 2 λεπτά",
    "the_team_typically_replies_in_1200": "Η ομάδα συνήθως απαντάει σε λιγότερο από 20 λεπτά",
    "the_team_typically_replies_in_14400": "Η ομάδα συνήθως απαντάει σε λιγότερο από 4 ώρες",
    "the_team_typically_replies_in_172800": "Η ομάδα συνήθως απαντάει σε 2 ημέρες",
    "the_team_typically_replies_in_180": "Η ομάδα απαντά συνήθως σε λιγότερο από 3 λεπτά",
    "the_team_typically_replies_in_1800": "Η ομάδα συνήθως απαντάει σε λιγότερο από 30 λεπτά",
    "the_team_typically_replies_in_18000": "Η ομάδα συνήθως απαντάει σε λιγότερο από 5 ώρες",
    "the_team_typically_replies_in_21600": "Η ομάδα συνήθως απαντάει σε λιγότερο από 6 ώρες",
    "the_team_typically_replies_in_240": "Η ομάδα απαντά συνήθως σε λιγότερο από 4 λεπτά",
    "the_team_typically_replies_in_259200": "Η ομάδα απαντά συνήθως σε 3 ημέρες",
    "the_team_typically_replies_in_300": "Η ομάδα συνήθως απαντάει σε λιγότερο από 5 λεπτά",
    "the_team_typically_replies_in_345600": "Η ομάδα απαντά συνήθως σε 4 ημέρες",
    "the_team_typically_replies_in_3600": "Η ομάδα συνήθως απαντάει σε λιγότερο από 1 ώρα",
    "the_team_typically_replies_in_43200": "Η ομάδα απαντά συνήθως σε λιγότερο από 12 ώρες",
    "the_team_typically_replies_in_432000": "Η ομάδα απαντά συνήθως σε 5 ημέρες",
    "the_team_typically_replies_in_518400": "Η ομάδα απαντά συνήθως σε 6 ημέρες",
    "the_team_typically_replies_in_60": "Η ομάδα απαντά συνήθως σε λιγότερο από 1 λεπτό",
    "the_team_typically_replies_in_600": "Η ομάδα συνήθως απαντάει σε λιγότερο από 10 λεπτά",
    "the_team_typically_replies_in_604800": "Η ομάδα συνήθως απαντάει σε λιγότερο από 1 εβδομάδα",
    "the_team_typically_replies_in_7200": "Η ομάδα συνήθως απαντάει σε λιγότερο από 2 ώρες",
    "the_team_typically_replies_in_86400": "Η ομάδα συνήθως απαντάει σε 1 ημέρα",
    "the_team_typically_replies_in_900": "Η ομάδα απαντά συνήθως σε λιγότερο από 15 λεπτά",
    "the_team_typically_replies_in_day": "Η ομάδα συνήθως απαντάει σε μία ημέρα.",
    "the_team_typically_replies_in_hours": "Η ομάδα συνήθως απαντάει σε λίγες ώρες.",
    "the_team_typically_replies_in_minutes": "Η ομάδα συνήθως απαντάει σε λίγα λεπτά.",
    "the_team_typically_replies_in_under_n_hours": "Η ομάδα συνήθως απαντάει σε λιγότερο από {hours} ώρ.",
    "the_team_typically_replies_in_under_n_min": "Η ομάδα συνήθως απαντάει σε λιγότερο από {minutes} λεπ.",
    "the_team_typically_replies_in_unknown": "Η ομάδα θα απαντήσει όσο το δυνατό συντομότερα",
    "the_team_typically_replies_in_week": "Η ομάδα συνήθως απαντά σε μία εβδομάδα.",
    "thinking": "Σκέφτεται...",
    "tickets_cta_text": "Έχετε κάποια ερώτηση σχετικά με το δελτίο αυτό;",
    "tickets_have_a_question": "Έχετε κάποια ερώτηση σχετικά με το θέμα αυτό;",
    "tickets_space_title": "Δελτία",
    "time_future_1h": "1 ώρα",
    "time_future_2h": "2 ώρες",
    "time_future_30m": "30 λεπτά",
    "time_future_3h": "3 ώρες",
    "time_future_day_0": "Δευτέρα",
    "time_future_day_1": "Τρίτη",
    "time_future_day_2": "Τετάρτη",
    "time_future_day_3": "Πέμπτη",
    "time_future_day_4": "Παρασκευή",
    "time_future_day_5": "Σάββατο",
    "time_future_day_6": "Κυριακή",
    "time_future_later_today": "Αργότερα σήμερα",
    "time_future_next_week": "Επόμενη εβδομάδα",
    "time_future_tomorrow": "Αύριο",
    "try_use_different_keywords": "Χρησιμοποιήστε διαφορετικές λέξεις-κλειδιά ή πληκτρολογήστε την πλήρη ερώτηση.",
    "upfront_collection_form_close_out": "Μπορείτε να επιστρέψετε εδώ ανά πάσα στιγμή για να δείτε απαντήσεις και να στείλετε ενημερώσεις.",
    "upfront_collection_form_introduction": "Μοιραστείτε λεπτομέρειες για να βοηθήσετε την ομάδα να ανταποκριθεί καλύτερα και πιο γρήγορα.",
    "upfront_collection_form_submission_confirmation": "Σας ευχαριστούμε. Για να εξοικονομήσετε χρόνο αργότερα, προσθέστε περισσότερες λεπτομέρειες τώρα.",
    "upfront_collection_form_subtitle": "Στείλτε στην ομάδα κάποιες λεπτομέρειες",
    "upfront_collection_form_subtitle_submitted": "Θα τις λάβουν υπόψη τους",
    "upfront_collection_form_title": "Ταχύτερη λήψη βοήθειας",
    "upfront_collection_form_title_submitted": "Στάλθηκε στην ομάδα!",
    "view_all": "Προβολή όλων",
    "view_conversation": "Προβολή συνομιλίας",
    "we_are_here_to_help": "Είμαστε εδώ για να βοηθήσουμε",
    "we_run_on_intercom": "Χρησιμοποιούμε τεχνολογία Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Όταν ένας συνεργάτης αλλάξει την κατάσταση μιας συζήτησης...",
    "working_on_it": "Το ψάχνει..",
    "you_are_all_up_to_date": "Δεν υπάρχουν άλλες ενημερώσεις",
    "your_checklists": "Οι λίστες ελέγχου σας",
    "your_conversations": "Οι συζητήσεις σας",
    "your_recent_conversations": "Πρόσφατες συνομιλίες",
    "your_recent_messages": "Πρόσφατα μηνύματα"
  },
  "en": {
    "a_day": "A day",
    "a_few_hours": "A few hours",
    "a_few_minutes": "A few minutes",
    "a_week": "A week",
    "active_state": "Active",
    "ai_answer": "AI Answer",
    "ai_answer_information": "AI Answer, information",
    "ai_bot": "AI Bot",
    "almost_there": "Almost there...",
    "answer": "Answer",
    "answer_bot_acknowledge_duplicate_content": "You’ve already seen this, but this is the best answer available.",
    "answer_bot_all_done": "That helped 👍",
    "answer_bot_ask_another_question": "Ask another question 🔁",
    "answer_bot_ask_something_else": "Ask something else 🔁",
    "answer_bot_connect_to_an_agent": "Connect to an agent 👤",
    "answer_bot_did_that_help": "Did that answer help, or are you looking for something else?",
    "answer_bot_followup_expectations_prefix": "The team will get back to you on this.",
    "answer_bot_get_more_help": "Get more help 🌐",
    "answer_bot_get_more_help_followup": "This link might help:",
    "answer_bot_i_have_another_question": "I have another question 🔁",
    "answer_bot_im_not_done_yet": "I'm not done yet 🔁",
    "answer_bot_keep_waiting": "No, I still need help 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "You can rephrase your question, or get additional help",
    "answer_bot_looping_first_no_answer": "Sorry, as a bot I couldn’t find an answer for that.",
    "answer_bot_looping_first_no_answer_followup": "You can rephrase your question, or talk to our team",
    "answer_bot_looping_no_answer": "Sorry, as a bot I couldn’t find an answer for that. Here’s what you can do",
    "answer_bot_more_answers": "Show me more 👀",
    "answer_bot_no_answer_found": "Sorry, we don’t have a quick answer for that. Here are some other options.",
    "answer_bot_resolve_followup": "You can always reply here, if anything else comes up.",
    "answer_bot_talk_to_person": "Talk to a person 👤",
    "answer_bot_that_answered_my_question": "That answered my question 👍",
    "answer_bot_thats_it": "That's it 👍",
    "answer_bot_wait_for_the_team": "Wait for the team 💬",
    "answerbot_ask_for_more_context": "Sure. While we wait, could you share more details about your situation or question? It’ll help us find an answer faster and I may find more information for you.",
    "answerbot_introduce_sneaky_answer": "Thanks for that additional context. I found some new information that might help you.",
    "article_search_hint_text": "Search articles...",
    "ask_a_question": "Ask a question",
    "ask_a_question_placeholder": "Ask a question…",
    "ask_a_question_with_ellipsis": "Ask a question...",
    "ask_another_question_with_ellipsis": "Ask another question...",
    "ask_for_the_team_if_needed": "Ask for the team if needed",
    "ask_me_a_question": "Ask me a question",
    "ask_our_bot_a_question": "Ask our bot a question",
    "avatar": "Avatar",
    "avatars": "Avatars",
    "bot": "Bot",
    "bot_answers_instantly": "Bot answers instantly",
    "bot_expectation_text": "Our bot can answer many common questions",
    "bot_intro_default_part_1": "Hi there! This is a bot speaking. I’m here to answer your questions, but you’ll always have the option to talk to our team.",
    "bot_intro_default_part_2": "So what brings you here today?",
    "bot_is_still_thinking": "Bot is still thinking...",
    "bot_is_thinking": "Bot is thinking...",
    "browse_collections": "Browse collections",
    "chat_with_us": "Chat with us",
    "check_later_for_updates": "Check back later for updates.",
    "checklist_card_first_step": "First step",
    "checklist_card_next_step": "Next step",
    "checklist_completed": "Completed",
    "checklist_in_progress_time_remaining_plural": "About {minutes} minutes left",
    "checklist_in_progress_time_remaining_singular": "About 1 minute left",
    "checklist_mark_step_as_complete": "Mark as completed",
    "checklist_not_started_time_remaining_plural": "About {minutes} minutes",
    "checklist_not_started_time_remaining_singular": "About 1 minute",
    "checklist_number_of_steps_plural": "{number_of_steps} steps",
    "checklist_number_of_steps_progress_plural": "{steps_completed} of {total_steps} done",
    "checklist_number_of_steps_progress_singular": "1 of {total_steps} done",
    "checklist_number_of_steps_singular": "1 step",
    "checklist_sender_name": "Shared by {name}",
    "checklist_step_action_button": "Step action button",
    "checklist_step_completed": "Completed",
    "contact_support": "Contact support",
    "contact_us": "Contact us",
    "continue_the_conversation": "Continue the conversation",
    "conversation_card_office_hours_header": "We'll be back online",
    "conversation_card_reply_time_header": "Our usual reply time",
    "conversation_card_reply_time_header_m5": "Usual reply time",
    "conversation_card_unknown_response_time": "We’ll reply as soon as we can",
    "conversation_part_sample_text": "Hi, how can I help? You’ll always have the option to talk to our team if needed.",
    "conversations_space_title": "Conversations",
    "create_a_ticket": "Create a ticket",
    "currently_no_help_articles": "There’s currently no help articles available.",
    "customer_sends_their_first_message": "Customer sends their first message...",
    "expected_response_delay_10800": "under 3 hours",
    "expected_response_delay_120": "under 2 minutes",
    "expected_response_delay_1200": "under 20 minutes",
    "expected_response_delay_14400": "under 4 hours",
    "expected_response_delay_172800": "2 days",
    "expected_response_delay_180": "under 3 minutes",
    "expected_response_delay_1800": "under 30 minutes",
    "expected_response_delay_18000": "under 5 hours",
    "expected_response_delay_21600": "under 6 hours",
    "expected_response_delay_240": "under 4 minutes",
    "expected_response_delay_259200": "3 days",
    "expected_response_delay_300": "under 5 minutes",
    "expected_response_delay_345600": "4 days",
    "expected_response_delay_3600": "under 1 hour",
    "expected_response_delay_43200": "under 12 hours",
    "expected_response_delay_432000": "5 days",
    "expected_response_delay_518400": "6 days",
    "expected_response_delay_60": "under 1 minute",
    "expected_response_delay_600": "under 10 minutes",
    "expected_response_delay_604800": "under 1 week",
    "expected_response_delay_7200": "under 2 hours",
    "expected_response_delay_86400": "1 day",
    "expected_response_delay_900": "under 15 minutes",
    "expected_response_delay_unknown": "As soon as we can",
    "extra_labels_count": "+ {labels_count} labels",
    "for_best_results_provide_detail": "For best results, provide as much detail as possible",
    "generating_answer": "Generating answer...",
    "generic_triage_default": "We just need a bit more information to connect you with the right person",
    "get_an_instant_answer": "Get an instant answer",
    "get_help": "Get help",
    "go_to": "Go to {app_name}",
    "help_space_title": "Help",
    "home_header_mobile_hello_firstname": "Hello, {firstname}.",
    "home_header_mobile_hello_there": "Hello there",
    "home_space_title": "Home",
    "how_can_we_help": "How can we help?",
    "how_would_you_rate_this": "How would you rate this?",
    "learn_more": "Learn more",
    "message_placeholder": "Message…",
    "messages_space_title": "Messages",
    "messenger_bot_reply_time": "Our bot will reply instantly",
    "messenger_settings_introduction": "How can we help?",
    "multiple_articles": "{total_articles} articles",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "New conversation",
    "news": "News",
    "news_empty_title": "No news yet",
    "news_latest_subtitle": "From Team {team_name}",
    "news_latest_title": "Latest",
    "news_older_title": "Older",
    "news_space_title": "News",
    "newsfeed": "Newsfeed",
    "no_articles_to_display": "No articles yet",
    "no_help_articles_to_display": "No help articles",
    "no_results_for_searchterm": "No results for {searchTerm}",
    "no_tasks": "No tasks",
    "no_tasks_available_currently": "There are currently no tasks available.",
    "ok_the_team_typically_replies_in_day": "Ok, the team typically replies in a day.",
    "ok_the_team_typically_replies_in_hours": "Ok, the team typically replies in a few hours.",
    "ok_the_team_typically_replies_in_minutes": "Ok, the team typically replies in a few minutes.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, the team typically replies in under {hours}h.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, the team typically replies in under {minutes}m.",
    "ok_the_team_typically_replies_in_week": "Ok, the team typically replies in a week.",
    "our_bot_and_team_can_help": "Our bot and team can help",
    "our_bot_answers_instantly": "Our bot answers instantly",
    "powered_by_intercom": "Powered by Intercom",
    "previous_bot_hands_over": "Content from the previous bot...",
    "proactive_expected_response_delay_10800": "Typically replies in under 3h",
    "proactive_expected_response_delay_120": "Typically replies in under 2m",
    "proactive_expected_response_delay_1200": "Typically replies in under 20m",
    "proactive_expected_response_delay_14400": "Typically replies in under 4h",
    "proactive_expected_response_delay_172800": "Typically replies in 2d",
    "proactive_expected_response_delay_180": "Typically replies in under 3m",
    "proactive_expected_response_delay_1800": "Typically replies in under 30m",
    "proactive_expected_response_delay_18000": "Typically replies in under 5h",
    "proactive_expected_response_delay_21600": "Typically replies in under 6h",
    "proactive_expected_response_delay_240": "Typically replies in under 4m",
    "proactive_expected_response_delay_259200": "Typically replies in 3d",
    "proactive_expected_response_delay_300": "Typically replies in under 5m",
    "proactive_expected_response_delay_345600": "Typically replies in 4d",
    "proactive_expected_response_delay_3600": "Typically replies in under 1h",
    "proactive_expected_response_delay_43200": "Typically replies in 12h",
    "proactive_expected_response_delay_432000": "Typically replies in 5d",
    "proactive_expected_response_delay_518400": "Typically replies in 6d",
    "proactive_expected_response_delay_60": "Typically replies in under 1m",
    "proactive_expected_response_delay_600": "Typically replies in under 10m",
    "proactive_expected_response_delay_604800": "Typically replies in under 1w",
    "proactive_expected_response_delay_7200": "Typically replies in under 2h",
    "proactive_expected_response_delay_86400": "Typically replies in 1d",
    "proactive_expected_response_delay_900": "Typically replies in under 15m",
    "proactive_expected_response_delay_unknown": "Will reply as soon as they can",
    "profile_location": "{time} in {location}",
    "recent_ticket": "Recent ticket",
    "recent_tickets": "Recent tickets",
    "search_browse_empty_state_text": "Sorry, we couldn’t find any results for that. Try rewording your search.",
    "search_for_help": "Search for help",
    "see_all": "See all",
    "see_all_conversations": "See all your conversations",
    "see_previous_conversations": "See your previous conversations",
    "send": "Send",
    "send_a_message": "Send a message…",
    "send_a_message_reply_time": "Usual reply time is",
    "send_us_a_message": "Send us a message",
    "set_expectations_bot_email_header": "You’ll get replies here and in your email:",
    "set_expectations_bot_office_hours_header": "The team will be back",
    "set_expectations_bot_sms_header": "You’ll get replies here and by SMS:",
    "set_expectations_replies_to_email": "You'll get replies here and to <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "You'll get replies here and to {user_email}",
    "set_expectations_team_reply_asap": "The team will reply as soon as they can.",
    "shared_by": "Shared by",
    "show_less": "Show less",
    "single_article": "1 article",
    "single_collection": "1 collection",
    "source": "Source",
    "sources": "Sources",
    "start_a_conversation": "Start a conversation",
    "start_conversation": "Start a conversation…",
    "still_generating_answer": "Still generating answer...",
    "still_thinking": "Still thinking...",
    "still_working_on_it": "Still working on it...",
    "tasks_space_title": "Tasks",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} will reply as soon as they can",
    "teammate_changes_team_assignment_of_a_conversation": "Teammate has changed the conversation assignment...",
    "tell_us_what_you_need": "Tell us what you need help with and our bot will do its best to answer",
    "the_team_can_help_if_needed": "The team can help if needed",
    "the_team_typically_replies_in_10800": "The team typically replies in under 3h",
    "the_team_typically_replies_in_120": "The team typically replies in under 2m",
    "the_team_typically_replies_in_1200": "The team typically replies in under 20m",
    "the_team_typically_replies_in_14400": "The team typically replies in under 4h",
    "the_team_typically_replies_in_172800": "The team typically replies in 2d",
    "the_team_typically_replies_in_180": "The team typically replies in under 3m",
    "the_team_typically_replies_in_1800": "The team typically replies in under 30m",
    "the_team_typically_replies_in_18000": "The team typically replies in under 5h",
    "the_team_typically_replies_in_21600": "The team typically replies in under 6h",
    "the_team_typically_replies_in_240": "The team typically replies in under 4m",
    "the_team_typically_replies_in_259200": "The team typically replies in 3d",
    "the_team_typically_replies_in_300": "The team typically replies in under 5m",
    "the_team_typically_replies_in_345600": "The team typically replies in 4d",
    "the_team_typically_replies_in_3600": "The team typically replies in under 1h",
    "the_team_typically_replies_in_43200": "The team typically replies in under 12h",
    "the_team_typically_replies_in_432000": "The team typically replies in 5d",
    "the_team_typically_replies_in_518400": "The team typically replies in 6d",
    "the_team_typically_replies_in_60": "The team typically replies in under 1m",
    "the_team_typically_replies_in_600": "The team typically replies in under 10m",
    "the_team_typically_replies_in_604800": "The team typically replies in under 1w",
    "the_team_typically_replies_in_7200": "The team typically replies in under 2h",
    "the_team_typically_replies_in_86400": "The team typically replies in 1d",
    "the_team_typically_replies_in_900": "The team typically replies in under 15m",
    "the_team_typically_replies_in_day": "The team typically replies in a day.",
    "the_team_typically_replies_in_hours": "The team typically replies in a few hours.",
    "the_team_typically_replies_in_minutes": "The team typically replies in a few minutes.",
    "the_team_typically_replies_in_under_n_hours": "The team typically replies in under {hours}h.",
    "the_team_typically_replies_in_under_n_min": "The team typically replies in under {minutes}m.",
    "the_team_typically_replies_in_unknown": "The team will reply as soon as they can",
    "the_team_typically_replies_in_week": "The team typically replies in a week.",
    "thinking": "Thinking...",
    "tickets_cta_text": "Have a question about this ticket?",
    "tickets_have_a_question": "Have a question about this issue?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "In 1 hour",
    "time_future_2h": "In 2 hours",
    "time_future_30m": "In 30 minutes",
    "time_future_3h": "In 3 hours",
    "time_future_day_0": "Monday",
    "time_future_day_1": "Tuesday",
    "time_future_day_2": "Wednesday",
    "time_future_day_3": "Thursday",
    "time_future_day_4": "Friday",
    "time_future_day_5": "Saturday",
    "time_future_day_6": "Sunday",
    "time_future_later_today": "Later today",
    "time_future_next_week": "Next week",
    "time_future_tomorrow": "Tomorrow",
    "try_use_different_keywords": "Try different keywords or type your full question",
    "upfront_collection_form_close_out": "You can return here anytime to see responses and send updates.",
    "upfront_collection_form_introduction": "Share some context to help the team respond better and faster.",
    "upfront_collection_form_submission_confirmation": "Thanks for sending that context. To save time later, feel free to add more details now.",
    "upfront_collection_form_subtitle": "Send the team some context",
    "upfront_collection_form_subtitle_submitted": "They'll keep this context in mind",
    "upfront_collection_form_title": "Get help faster",
    "upfront_collection_form_title_submitted": "Sent to the team!",
    "view_all": "View all",
    "view_conversation": "View conversation",
    "we_are_here_to_help": "We're here to help",
    "we_run_on_intercom": "We run on Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "When a teammate changes the state of a conversation...",
    "working_on_it": "Working on it...",
    "you_are_all_up_to_date": "You're all caught up!",
    "your_checklists": "Your checklists",
    "your_conversations": "Your conversations",
    "your_recent_conversations": "Recent conversations",
    "your_recent_messages": "Recent messages"
  },
  "es": {
    "a_day": "Un día",
    "a_few_hours": "Unas horas",
    "a_few_minutes": "Unos minutos",
    "a_week": "Una semana",
    "active_state": "Activo",
    "ai_answer": "Respuesta de IA",
    "ai_answer_information": "Respuesta de IA, información",
    "ai_bot": "Bot de IA",
    "almost_there": "Ya casi...",
    "answer": "Respuesta",
    "answer_bot_acknowledge_duplicate_content": "Aunque ya la hayas visto, esta es la mejor respuesta disponible.",
    "answer_bot_all_done": "Ha sido útil 👍",
    "answer_bot_ask_another_question": "Hacer otra pregunta 🔁",
    "answer_bot_ask_something_else": "Preguntar otra cosa 🔁",
    "answer_bot_connect_to_an_agent": "Hablar con un agente 👤",
    "answer_bot_did_that_help": "¿Te sirvió esa respuesta o buscas otra cosa?",
    "answer_bot_followup_expectations_prefix": "El equipo se pondrá en contacto contigo.",
    "answer_bot_get_more_help": "Obtener más ayuda 🌐",
    "answer_bot_get_more_help_followup": "Este enlace te podría ayudar:",
    "answer_bot_i_have_another_question": "Tengo otra pregunta 🔁",
    "answer_bot_im_not_done_yet": "Aún no he terminado 🔁",
    "answer_bot_keep_waiting": "No, todavía necesito ayuda 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Puedes reformular tu pregunta u obtener más ayuda",
    "answer_bot_looping_first_no_answer": "Lo siento, soy un simple robot y no he encontrado ninguna respuesta a tu pregunta.",
    "answer_bot_looping_first_no_answer_followup": "Puedes reformular tu pregunta o hablar con nuestro equipo",
    "answer_bot_looping_no_answer": "Lo siento, soy un simple robot y no he encontrado ninguna respuesta a tu pregunta. Puedes hacer lo siguiente",
    "answer_bot_more_answers": "Muéstrame más 👀",
    "answer_bot_no_answer_found": "Lo sentimos, no tenemos una respuesta rápida para esa pregunta. ¿Qué tal alguna de estas opciones?",
    "answer_bot_resolve_followup": "Si te surge cualquier otra duda, puedes escribirla por aquí.",
    "answer_bot_talk_to_person": "Hablar con una persona 👤",
    "answer_bot_that_answered_my_question": "Ha contestado a mi pregunta 👍",
    "answer_bot_thats_it": "Eso es todo 👍",
    "answer_bot_wait_for_the_team": "Espero al equipo 💬",
    "answerbot_ask_for_more_context": "Claro. Mientras esperamos, ¿puedes darnos más detalles sobre tu situación o pregunta? Nos permitirá encontrar una respuesta más rápido y puede que encuentre más información para ti.",
    "answerbot_introduce_sneaky_answer": "Gracias por facilitar más contexto. He encontrado información nueva que podría ayudarte.",
    "article_search_hint_text": "Buscar artículos...",
    "ask_a_question": "Hacer una pregunta",
    "ask_a_question_placeholder": "Haz una pregunta…",
    "ask_a_question_with_ellipsis": "Haz una pregunta...",
    "ask_another_question_with_ellipsis": "Haz otra pregunta...",
    "ask_for_the_team_if_needed": "Pregunta por el equipo si es necesario",
    "ask_me_a_question": "Hazme una pregunta",
    "ask_our_bot_a_question": "Hazle una pregunta a nuestro bot",
    "avatar": "Avatar",
    "avatars": "Avatares",
    "bot": "Bot",
    "bot_answers_instantly": "El bot responde al instante",
    "bot_expectation_text": "Nuestro bot puede responder a muchas preguntas frecuentes",
    "bot_intro_default_part_1": "Hola, ¿qué tal? Soy un bot. Me tienes a tu disposición para responder a tus preguntas, pero siempre tendrás la opción de hablar con nuestro equipo.",
    "bot_intro_default_part_2": "¿Qué te trae hoy por aquí?",
    "bot_is_still_thinking": "El bot sigue pensando...",
    "bot_is_thinking": "El bot está pensando...",
    "browse_collections": "Explorar colecciones",
    "chat_with_us": "Hablar con nosotros",
    "check_later_for_updates": "Comprueba más tarde si hay actualizaciones.",
    "checklist_card_first_step": "Primer paso",
    "checklist_card_next_step": "Siguiente paso",
    "checklist_completed": "Completado",
    "checklist_in_progress_time_remaining_plural": "Quedan alrededor de {minutes} minutos",
    "checklist_in_progress_time_remaining_singular": "Queda alrededor de 1 minuto",
    "checklist_mark_step_as_complete": "Marcar como completado",
    "checklist_not_started_time_remaining_plural": "Alrededor de {minutes} minutos",
    "checklist_not_started_time_remaining_singular": "Alrededor de 1 minuto",
    "checklist_number_of_steps_plural": "{number_of_steps} pasos",
    "checklist_number_of_steps_progress_plural": "{steps_completed} de {total_steps} hecho",
    "checklist_number_of_steps_progress_singular": "1 de {total_steps} hecho",
    "checklist_number_of_steps_singular": "1 paso",
    "checklist_sender_name": "Compartido por {name}",
    "checklist_step_action_button": "Botón de acción de paso",
    "checklist_step_completed": "Completado",
    "contact_support": "Contactar con asistencia",
    "contact_us": "Contactar con nosotros",
    "continue_the_conversation": "Continúa la conversación",
    "conversation_card_office_hours_header": "Cuándo estaremos de vuelta",
    "conversation_card_reply_time_header": "Nuestro tiempo de respuesta habitual",
    "conversation_card_reply_time_header_m5": "Tiempo de respuesta habitual",
    "conversation_card_unknown_response_time": "Te responderemos lo antes posible",
    "conversation_part_sample_text": "Hola, ¿en qué te puedo ayudar? Siempre tendrás la opción de hablar con nuestro equipo, si fuera necesario.",
    "conversations_space_title": "Conversaciones",
    "create_a_ticket": "Crear una incidencia",
    "currently_no_help_articles": "Actualmente no hay artículos de ayuda disponibles.",
    "customer_sends_their_first_message": "El cliente envía su primer mensaje...",
    "expected_response_delay_10800": "menos de 3 horas",
    "expected_response_delay_120": "menos de 2 minutos",
    "expected_response_delay_1200": "menos de 20 minutos",
    "expected_response_delay_14400": "menos de 4 horas",
    "expected_response_delay_172800": "2 días",
    "expected_response_delay_180": "menos de 3 minutos",
    "expected_response_delay_1800": "menos de 30 minutos",
    "expected_response_delay_18000": "menos de 5 horas",
    "expected_response_delay_21600": "menos de 6 horas",
    "expected_response_delay_240": "menos de 4 minutos",
    "expected_response_delay_259200": "3 días",
    "expected_response_delay_300": "menos de 5 minutos",
    "expected_response_delay_345600": "4 días",
    "expected_response_delay_3600": "menos de 1 hora",
    "expected_response_delay_43200": "menos de 12 horas",
    "expected_response_delay_432000": "5 días",
    "expected_response_delay_518400": "6 días",
    "expected_response_delay_60": "menos de 1 minuto",
    "expected_response_delay_600": "menos de 10 minutos",
    "expected_response_delay_604800": "menos de 1 semana",
    "expected_response_delay_7200": "menos de 2 horas",
    "expected_response_delay_86400": "1 día",
    "expected_response_delay_900": "menos de 15 minutos",
    "expected_response_delay_unknown": "Lo antes posible",
    "extra_labels_count": "+ {labels_count} etiquetas",
    "for_best_results_provide_detail": "Para obtener los mejores resultados, facilita tantos detalles como sea posible",
    "generating_answer": "Generando respuesta...",
    "generic_triage_default": "Solo necesitamos un poco más de información para ponerte en contacto con la persona adecuada",
    "get_an_instant_answer": "Obtener una respuesta al instante",
    "get_help": "Obtener ayuda",
    "go_to": "Ir a {app_name}",
    "help_space_title": "Ayuda",
    "home_header_mobile_hello_firstname": "Hola, {firstname}.",
    "home_header_mobile_hello_there": "¡Hola!",
    "home_space_title": "Inicio",
    "how_can_we_help": "¿Cómo podemos ayudarte?",
    "how_would_you_rate_this": "¿Cómo valorarías esto?",
    "learn_more": "Más información",
    "message_placeholder": "Escribe un mensaje...",
    "messages_space_title": "Mensajes",
    "messenger_bot_reply_time": "Nuestro bot responderá al instante",
    "messenger_settings_introduction": "¿Cómo podemos ayudarte?",
    "multiple_articles": "{total_articles} artículos",
    "multiple_collections": "{total_collection} colecciones",
    "new_conversation": "Nueva conversación",
    "news": "Noticias",
    "news_empty_title": "Aún no hay noticias",
    "news_latest_subtitle": "Del equipo de {team_name}",
    "news_latest_title": "Novedades",
    "news_older_title": "Anteriores",
    "news_space_title": "Noticias",
    "newsfeed": "Noticias",
    "no_articles_to_display": "Aún no hay artículos",
    "no_help_articles_to_display": "No hay artículos de ayuda",
    "no_results_for_searchterm": "No hay resultados para {searchTerm}",
    "no_tasks": "No hay tareas",
    "no_tasks_available_currently": "Actualmente no hay tareas disponibles.",
    "ok_the_team_typically_replies_in_day": "De acuerdo, el equipo suele responder en un día.",
    "ok_the_team_typically_replies_in_hours": "De acuerdo, el equipo suele responder en cuestión de horas.",
    "ok_the_team_typically_replies_in_minutes": "De acuerdo, el equipo suele responder en cuestión de minutos.",
    "ok_the_team_typically_replies_in_under_n_hours": "De acuerdo, el equipo suele responder en menos de {hours} h.",
    "ok_the_team_typically_replies_in_under_n_min": "De acuerdo, el equipo suele responder en menos de {minutes} m.",
    "ok_the_team_typically_replies_in_week": "De acuerdo, el equipo suele responder en una semana.",
    "our_bot_and_team_can_help": "Nuestro bot y nuestro equipo te ayudarán",
    "our_bot_answers_instantly": "Nuestro bot responde al instante",
    "powered_by_intercom": "Con la tecnología de Intercom",
    "previous_bot_hands_over": "Contenido del bot anterior...",
    "proactive_expected_response_delay_10800": "Suele responder en menos de 3 horas",
    "proactive_expected_response_delay_120": "Suele responder en menos de 2 minutos",
    "proactive_expected_response_delay_1200": "Suele responder en menos de 20 minutos",
    "proactive_expected_response_delay_14400": "Suele responder en menos de 4 horas",
    "proactive_expected_response_delay_172800": "Suele responder en 2 días",
    "proactive_expected_response_delay_180": "Suele responder en menos de 3 minutos",
    "proactive_expected_response_delay_1800": "Suele responder en menos de 30 minutos",
    "proactive_expected_response_delay_18000": "Suele responder en menos de 5 horas",
    "proactive_expected_response_delay_21600": "Suele responder en menos de 6 horas",
    "proactive_expected_response_delay_240": "Suele responder en menos de 4 minutos",
    "proactive_expected_response_delay_259200": "Suele responder en 3 días",
    "proactive_expected_response_delay_300": "Suele responder en menos de 5 minutos",
    "proactive_expected_response_delay_345600": "Suele responder en 4 días",
    "proactive_expected_response_delay_3600": "Suele responder en menos de 1 hora",
    "proactive_expected_response_delay_43200": "Suele responder en 12 horas",
    "proactive_expected_response_delay_432000": "Suele responder en 5 días",
    "proactive_expected_response_delay_518400": "Suele responder en 6 días",
    "proactive_expected_response_delay_60": "Suele responder en menos de 1 minuto",
    "proactive_expected_response_delay_600": "Suele responder en menos de 10 minutos",
    "proactive_expected_response_delay_604800": "Suele responder en menos de 1 semana",
    "proactive_expected_response_delay_7200": "Suele responder en menos de 2 horas",
    "proactive_expected_response_delay_86400": "Suele responder en 1 día",
    "proactive_expected_response_delay_900": "Suele responder en menos de 15 minutos",
    "proactive_expected_response_delay_unknown": "Responderá lo antes posible",
    "profile_location": "{time} en {location}",
    "recent_ticket": "Ticket reciente",
    "recent_tickets": "Tickets recientes",
    "search_browse_empty_state_text": "Lo sentimos, no hemos encontrado ningún resultado para esa búsqueda. Prueba a reformularla.",
    "search_for_help": "Buscar ayuda",
    "see_all": "Ver todas",
    "see_all_conversations": "Ver todas las conversaciones",
    "see_previous_conversations": "Ver conversaciones anteriores",
    "send": "Enviar",
    "send_a_message": "Enviar un mensaje…",
    "send_a_message_reply_time": "El tiempo de respuesta habitual es de",
    "send_us_a_message": "Envíanos un mensaje",
    "set_expectations_bot_email_header": "Las respuestas te llegarán aquí y por correo electrónico:",
    "set_expectations_bot_office_hours_header": "El equipo volverá:",
    "set_expectations_bot_sms_header": "Las respuestas te llegarán aquí y por SMS:",
    "set_expectations_replies_to_email": "Las respuestas te llegarán aquí y a <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Las respuestas te llegarán aquí y a {user_email}",
    "set_expectations_team_reply_asap": "El equipo responderá lo antes posible.",
    "shared_by": "Compartido por",
    "show_less": "Mostrar menos",
    "single_article": "1 artículo",
    "single_collection": "1 colección",
    "source": "Fuente",
    "sources": "Fuentes",
    "start_a_conversation": "Iniciar una conversación",
    "start_conversation": "Iniciar una conversación",
    "still_generating_answer": "Sigo generando respuesta...",
    "still_thinking": "Sigo pensando...",
    "still_working_on_it": "Sigo trabajando en ello...",
    "tasks_space_title": "Tareas",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} responderá lo antes posible",
    "teammate_changes_team_assignment_of_a_conversation": "Un miembro de equipo ha cambiado la asignación de la conversación...",
    "tell_us_what_you_need": "Cuéntanos qué necesitas y nuestro bot hará todo lo posible por ayudarte",
    "the_team_can_help_if_needed": "Nuestro equipo puede ayudarte si es necesario",
    "the_team_typically_replies_in_10800": "El equipo suele responder en menos de 3 horas.",
    "the_team_typically_replies_in_120": "El equipo suele responder en menos de 2 minutos",
    "the_team_typically_replies_in_1200": "El equipo suele responder en menos de 20 minutos.",
    "the_team_typically_replies_in_14400": "El equipo suele responder en menos de 4 horas.",
    "the_team_typically_replies_in_172800": "El equipo suele responder en menos de 2 días.",
    "the_team_typically_replies_in_180": "El equipo suele responder en menos de 3 minutos",
    "the_team_typically_replies_in_1800": "El equipo suele responder en menos de 30 minutos.",
    "the_team_typically_replies_in_18000": "El equipo suele responder en menos de 5 horas.",
    "the_team_typically_replies_in_21600": "El equipo suele responder en menos de 6 horas.",
    "the_team_typically_replies_in_240": "El equipo suele responder en menos de 4 minutos",
    "the_team_typically_replies_in_259200": "El equipo suele responder en 3 días",
    "the_team_typically_replies_in_300": "El equipo suele responder en menos de 5 minutos.",
    "the_team_typically_replies_in_345600": "El equipo suele responder en 4 días",
    "the_team_typically_replies_in_3600": "El equipo suele responder en menos de 1 hora.",
    "the_team_typically_replies_in_43200": "El equipo suele responder en menos de 12 horas",
    "the_team_typically_replies_in_432000": "El equipo suele responder en 5 días",
    "the_team_typically_replies_in_518400": "El equipo suele responder en 6 días",
    "the_team_typically_replies_in_60": "El equipo suele responder en menos de 1 minuto",
    "the_team_typically_replies_in_600": "El equipo suele responder en menos de 10 minutos.",
    "the_team_typically_replies_in_604800": "El equipo suele responder en menos de 1 semana.",
    "the_team_typically_replies_in_7200": "El equipo suele responder en menos de 2 horas.",
    "the_team_typically_replies_in_86400": "El equipo suele responder en menos de 1 día",
    "the_team_typically_replies_in_900": "El equipo suele responder en menos de 15 minutos",
    "the_team_typically_replies_in_day": "El equipo suele responder en un día.",
    "the_team_typically_replies_in_hours": "El equipo suele responder en cuestión de horas.",
    "the_team_typically_replies_in_minutes": "El equipo suele responder en cuestión de minutos.",
    "the_team_typically_replies_in_under_n_hours": "El equipo suele responder en menos de {hours} h.",
    "the_team_typically_replies_in_under_n_min": "El equipo suele responder en menos de {minutes} m.",
    "the_team_typically_replies_in_unknown": "El equipo responderá lo antes posible",
    "the_team_typically_replies_in_week": "El equipo suele responder en una semana.",
    "thinking": "Pensando...",
    "tickets_cta_text": "¿Tienes alguna pregunta sobre este ticket?",
    "tickets_have_a_question": "¿Tienes alguna pregunta sobre esta incidencia?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "En 1 hora",
    "time_future_2h": "En 2 horas",
    "time_future_30m": "En 30 minutos",
    "time_future_3h": "En 3 horas",
    "time_future_day_0": "Lunes",
    "time_future_day_1": "Martes",
    "time_future_day_2": "Miércoles",
    "time_future_day_3": "Jueves",
    "time_future_day_4": "Viernes",
    "time_future_day_5": "Sábado",
    "time_future_day_6": "Domingo",
    "time_future_later_today": "Hoy, más tarde",
    "time_future_next_week": "La próxima semana",
    "time_future_tomorrow": "Mañana",
    "try_use_different_keywords": "Prueba a usar otras palabras clave o escribe tu pregunta completa",
    "upfront_collection_form_close_out": "Puedes volver aquí en cualquier momento para ver las respuestas y enviar más información.",
    "upfront_collection_form_introduction": "Proporciona más detalles para ayudar al equipo a responder mejor y más rápido.",
    "upfront_collection_form_submission_confirmation": "Gracias por la información. No dudes en añadir más detalles para ahorrar tiempo más adelante.",
    "upfront_collection_form_subtitle": "Envía algunos detalles al equipo",
    "upfront_collection_form_subtitle_submitted": "Tendrán en cuenta estos detalles",
    "upfront_collection_form_title": "Recibe ayuda más rápido",
    "upfront_collection_form_title_submitted": "¡Enviado al equipo!",
    "view_all": "Ver todo",
    "view_conversation": "Ver conversación",
    "we_are_here_to_help": "Estamos aquí para ayudarte",
    "we_run_on_intercom": "Usamos Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Cuando un miembro de equipo cambie el estado de una conversación...",
    "working_on_it": "Trabajando en ello...",
    "you_are_all_up_to_date": "Ya te has puesto al día.",
    "your_checklists": "Tus listas de verificación",
    "your_conversations": "Sus conversaciones",
    "your_recent_conversations": "Conversaciones recientes",
    "your_recent_messages": "Mensajes recientes"
  },
  "et": {
    "a_day": "Üks päev",
    "a_few_hours": "Mõned tunnid",
    "a_few_minutes": "Mõned minutid",
    "a_week": "Nädal",
    "active_state": "Aktiivne",
    "ai_answer": "TI vastus",
    "ai_answer_information": "TI vastuse teave",
    "ai_bot": "TI-bot",
    "almost_there": "Peaaegu valmis ...",
    "answer": "Vastus",
    "answer_bot_acknowledge_duplicate_content": "Olete seda juba näinud, kuid see on parim võimalik vastus.",
    "answer_bot_all_done": "Sellest oli abi 👍",
    "answer_bot_ask_another_question": "Esita mõni muu küsimus 🔁",
    "answer_bot_ask_something_else": "Esita mõni muu küsimus 🔁",
    "answer_bot_connect_to_an_agent": "Räägi agendiga 👤",
    "answer_bot_did_that_help": "Kas see vastus aitas teid või te otsite midagi muud?",
    "answer_bot_followup_expectations_prefix": "Meeskond võtab teiega sel teemal ühendust.",
    "answer_bot_get_more_help": "Rohkem abi saate 🌐",
    "answer_bot_get_more_help_followup": "Sellest lingist võib olla abi:",
    "answer_bot_i_have_another_question": "Mul on veel üks küsimus 🔁",
    "answer_bot_im_not_done_yet": "Ma ei ole veel lõpetanud 🔁",
    "answer_bot_keep_waiting": "Ei, ma vajan ikka veel abi 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Võite oma küsimuse ümber sõnastada või otsida täiendavat abi",
    "answer_bot_looping_first_no_answer": "Vabandust, botina ei suutnud ma sellele vastust leida.",
    "answer_bot_looping_first_no_answer_followup": "Võite oma küsimuse ümber sõnastada või rääkida meie tiimiga",
    "answer_bot_looping_no_answer": "Vabandust, kuna olen kõigest robot, ei suutnud ma sellele vastust leida. Te saate teha järgmist",
    "answer_bot_more_answers": "Näita rohkem 👀",
    "answer_bot_no_answer_found": "Vabandust, meil pole sellele kiiret vastust. Siin on mõned muud võimalused.",
    "answer_bot_resolve_followup": "Võite alati siin vastata, kui olukord muutub.",
    "answer_bot_talk_to_person": "Rääkige inimesega 👤",
    "answer_bot_that_answered_my_question": "See vastas minu küsimusele 👍",
    "answer_bot_thats_it": "Sellest oli abi 👍",
    "answer_bot_wait_for_the_team": "Oota meeskonda 💬",
    "answerbot_ask_for_more_context": "Muidugi. Kuni me ootame, ehk saaksite oma olukorrast või küsimusest lähemalt rääkida? See aitab meil kiiremini vastuse leida ja ma võin leida teie jaoks rohkem teavet.",
    "answerbot_introduce_sneaky_answer": "Täname selle täiendava konteksti eest. Leidsin uut teavet, mis võib teid aidata.",
    "article_search_hint_text": "Artiklite otsimine...",
    "ask_a_question": "Esitage küsimus",
    "ask_a_question_placeholder": "Esitage küsimus...",
    "ask_a_question_with_ellipsis": "Esitage küsimus ...",
    "ask_another_question_with_ellipsis": "Küsige veel üks küsimus ...",
    "ask_for_the_team_if_needed": "Küsige vajadusel abi meeskonnalt",
    "ask_me_a_question": "Esitage mulle küsimus",
    "ask_our_bot_a_question": "Küsige meie robotilt küsimus",
    "avatar": "Avatar",
    "avatars": "Avatarid",
    "bot": "Bot",
    "bot_answers_instantly": "Robot vastab kohe",
    "bot_expectation_text": "Meie bot oskab vastata paljudele levinud küsimustele",
    "bot_intro_default_part_1": "Tere! Teiega räägib robot. Olen siin, et teie küsimustele vastata, kuid teil on alati võimalus rääkida meie tiimiga.",
    "bot_intro_default_part_2": "Niisiis, mis teid täna siia toob?",
    "bot_is_still_thinking": "Robot mõtleb ikka veel ...",
    "bot_is_thinking": "Robot mõtleb ...",
    "browse_collections": "Sirvi kollektsioone",
    "chat_with_us": "Vestelge meiega",
    "check_later_for_updates": "Vaadake hiljem uuesti, juhuks kui siia midagi ilmub.",
    "checklist_card_first_step": "Esimene samm",
    "checklist_card_next_step": "Järgmine samm",
    "checklist_completed": "Lõpule viidud",
    "checklist_in_progress_time_remaining_plural": "Umbes {minutes} minutit jäänud",
    "checklist_in_progress_time_remaining_singular": "Umbes 1 minut jäänud",
    "checklist_mark_step_as_complete": "Märgi lõpuleviiduks",
    "checklist_not_started_time_remaining_plural": "Umbes {minutes} minutit",
    "checklist_not_started_time_remaining_singular": "Umbes 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} sammu",
    "checklist_number_of_steps_progress_plural": "{steps_completed}/{total_steps} tehtud",
    "checklist_number_of_steps_progress_singular": "1 samm {total_steps}-st on tehtud",
    "checklist_number_of_steps_singular": "1 samm",
    "checklist_sender_name": "Jagas {name}",
    "checklist_step_action_button": "Sammu toimingunupp",
    "checklist_step_completed": "Lõpule viidud",
    "contact_support": "Võtke ühendust tugiteenusega",
    "contact_us": "Võtke meiega ühendust",
    "continue_the_conversation": "Jätka vestlust",
    "conversation_card_office_hours_header": "Kui me oleme võrgus tagasi",
    "conversation_card_reply_time_header": "Meie tavaline vastamisaeg",
    "conversation_card_reply_time_header_m5": "Tavaline vastamisaeg",
    "conversation_card_unknown_response_time": "Vastame esimesel võimalusel",
    "conversation_part_sample_text": "Tere, kuidas ma saan aidata? Teil on alati võimalus vajaduse korral meie tiimiga rääkida.",
    "conversations_space_title": "Vestlused",
    "create_a_ticket": "Pileti loomine",
    "currently_no_help_articles": "Praegu pole ühtegi abiartiklit saadaval.",
    "customer_sends_their_first_message": "Klient saadab oma esimese sõnumi ...",
    "expected_response_delay_10800": "alla 3 tunni",
    "expected_response_delay_120": "alla 2 minuti",
    "expected_response_delay_1200": "alla 20 minuti",
    "expected_response_delay_14400": "alla 4 tunni",
    "expected_response_delay_172800": "2 päeva",
    "expected_response_delay_180": "alla 3 minuti",
    "expected_response_delay_1800": "alla 30 minuti",
    "expected_response_delay_18000": "alla 5 tunni",
    "expected_response_delay_21600": "alla 6 tunni",
    "expected_response_delay_240": "alla 4 minuti",
    "expected_response_delay_259200": "3 päeva",
    "expected_response_delay_300": "alla 5 minuti",
    "expected_response_delay_345600": "4 päeva",
    "expected_response_delay_3600": "alla 1 tunni",
    "expected_response_delay_43200": "alla 12 tunni",
    "expected_response_delay_432000": "5 päeva",
    "expected_response_delay_518400": "6 päeva",
    "expected_response_delay_60": "alla 1 minuti",
    "expected_response_delay_600": "alla 10 minuti",
    "expected_response_delay_604800": "alla 1 nädala",
    "expected_response_delay_7200": "alla 2 tunni",
    "expected_response_delay_86400": "1 päev",
    "expected_response_delay_900": "15 minuti jooksul",
    "expected_response_delay_unknown": "Esimesel võimalusel",
    "extra_labels_count": "+ {labels_count} silti",
    "for_best_results_provide_detail": "Parimate tulemuste saavutamiseks esitage võimalikult palju üksikasju",
    "generating_answer": "Koostatakse vastust ...",
    "generic_triage_default": "Vajame veidi rohkem teavet, et ühendada teid õige inimesega",
    "get_an_instant_answer": "Saa kohe vastus",
    "get_help": "Küsi abi",
    "go_to": "Ava {app_name}",
    "help_space_title": "Abi",
    "home_header_mobile_hello_firstname": "Tere, {firstname}!",
    "home_header_mobile_hello_there": "Tere",
    "home_space_title": "Avakuva",
    "how_can_we_help": "Kuidas me saame aidata?",
    "how_would_you_rate_this": "Kuidas te seda hindaksite?",
    "learn_more": "Lisateave",
    "message_placeholder": "Sõnum ...",
    "messages_space_title": "Sõnumid",
    "messenger_bot_reply_time": "Meie bott vastab kohe",
    "messenger_settings_introduction": "Kuidas me saame aidata?",
    "multiple_articles": "{total_articles} artiklit",
    "multiple_collections": "{total_collection} kollektsiooni",
    "new_conversation": "Uus vestlus",
    "news": "Uudised",
    "news_empty_title": "Uudiseid veel pole",
    "news_latest_subtitle": "Tiimilt {team_name}",
    "news_latest_title": "Uusimad",
    "news_older_title": "Vanemad",
    "news_space_title": "Uudised",
    "newsfeed": "Uudisesööde",
    "no_articles_to_display": "Artikleid veel pole",
    "no_help_articles_to_display": "Abiartikleid pole",
    "no_results_for_searchterm": "{searchTerm} kohta pole tulemusi",
    "no_tasks": "Ülesandeid pole",
    "no_tasks_available_currently": "Praegu pole ühtegi ülesannet saadaval.",
    "ok_the_team_typically_replies_in_day": "Korras, meeskond vastab tavaliselt päeva jooksul.",
    "ok_the_team_typically_replies_in_hours": "Korras, meeskond vastab tavaliselt mõne tunni jooksul.",
    "ok_the_team_typically_replies_in_minutes": "Korras, meeskond vastab tavaliselt mõne minuti jooksul.",
    "ok_the_team_typically_replies_in_under_n_hours": "Korras, meeskond vastab tavaliselt {hours} h jooksul.",
    "ok_the_team_typically_replies_in_under_n_min": "Korras, meeskond vastab tavaliselt {minutes} m jooksul.",
    "ok_the_team_typically_replies_in_week": "Korras, tiim vastab tavaliselt nädala jooksul.",
    "our_bot_and_team_can_help": "Meie robot ja meeskond saavad aidata",
    "our_bot_answers_instantly": "Meie bott vastab kohe",
    "powered_by_intercom": "Teenusepakkuja Intercom",
    "previous_bot_hands_over": "Eelmise boti sisu...",
    "proactive_expected_response_delay_10800": "Vastab tavaliselt 3 tunni jooksul",
    "proactive_expected_response_delay_120": "Vastab tavaliselt 2 minuti jooksul",
    "proactive_expected_response_delay_1200": "Vastab tavaliselt 20 minuti jooksul",
    "proactive_expected_response_delay_14400": "Vastab tavaliselt 4 tunni jooksul",
    "proactive_expected_response_delay_172800": "Vastab tavaliselt 2 päeva jooksul",
    "proactive_expected_response_delay_180": "Vastab tavaliselt 3 minuti jooksul",
    "proactive_expected_response_delay_1800": "Vastab tavaliselt 30 minuti jooksul",
    "proactive_expected_response_delay_18000": "Vastab tavaliselt 5 tunni jooksul",
    "proactive_expected_response_delay_21600": "Vastab tavaliselt 6 tunni jooksul",
    "proactive_expected_response_delay_240": "Vastab tavaliselt 4 minuti jooksul",
    "proactive_expected_response_delay_259200": "Vastab tavaliselt 3 päeva jooksul",
    "proactive_expected_response_delay_300": "Vastab tavaliselt 5 minuti jooksul",
    "proactive_expected_response_delay_345600": "Vastab tavaliselt 4 päeva jooksul",
    "proactive_expected_response_delay_3600": "Vastab tavaliselt 1 tunni jooksul",
    "proactive_expected_response_delay_43200": "Vastab tavaliselt 12 tunni jooksul",
    "proactive_expected_response_delay_432000": "Vastab tavaliselt 5 päeva jooksul",
    "proactive_expected_response_delay_518400": "Vastab tavaliselt 6 päeva jooksul",
    "proactive_expected_response_delay_60": "Vastab tavaliselt 1 minuti jooksul",
    "proactive_expected_response_delay_600": "Vastab tavaliselt 10 minuti jooksul",
    "proactive_expected_response_delay_604800": "Vastab tavaliselt 1 nädala jooksul",
    "proactive_expected_response_delay_7200": "Vastab tavaliselt 2 tunni jooksul",
    "proactive_expected_response_delay_86400": "Vastab tavaliselt 1 päeva jooksul",
    "proactive_expected_response_delay_900": "Vastab tavaliselt 15 minuti jooksul",
    "proactive_expected_response_delay_unknown": "Vastab esimesel võimalusel",
    "profile_location": "{time} kohas {location}",
    "recent_ticket": "Hiljutine pilet",
    "recent_tickets": "Hiljutised töökäsud",
    "search_browse_empty_state_text": "Vabandust, me ei leidnud selle kohta tulemusi. Proovige oma otsing ümber sõnastada.",
    "search_for_help": "Otsi abi",
    "see_all": "Vaata kõiki",
    "see_all_conversations": "Vaata oma kõiki vestluseid",
    "see_previous_conversations": "Vaadake oma eelmiseid vestluseid",
    "send": "Saada",
    "send_a_message": "Saada sõnum…",
    "send_a_message_reply_time": "Tavaline vastamisaeg on",
    "send_us_a_message": "Saatke meile sõnum",
    "set_expectations_bot_email_header": "Vastused saate siit ja e-kirja teel:",
    "set_expectations_bot_office_hours_header": "Kui meeskond tuleb tagasi:",
    "set_expectations_bot_sms_header": "Vastused saate siit ja SMS-i teel:",
    "set_expectations_replies_to_email": "Saate vastused siin ja aadressile <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Saate vastuseid siin ja aadressile {user_email}",
    "set_expectations_team_reply_asap": "Tiim vastab esimesel võimalusel.",
    "shared_by": "Jagas",
    "show_less": "Kuva vähem",
    "single_article": "1 artikkel",
    "single_collection": "1 kollektsioon",
    "source": "Allikas",
    "sources": "Allikad",
    "start_a_conversation": "Alusta vestlust",
    "start_conversation": "Alusta vestlust",
    "still_generating_answer": "Vastuse koostamine on veel pooleli ...",
    "still_thinking": "Ikka veel mõtleb ...",
    "still_working_on_it": "Töö käib veel ...",
    "tasks_space_title": "Ülesanded",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} vastab esimesel võimalusel",
    "teammate_changes_team_assignment_of_a_conversation": "Tiimikaaslane muutis vestluse määramist ...",
    "tell_us_what_you_need": "Öelge meile, millega te abi vajate ja meie bot annab endast parima, et vastata",
    "the_team_can_help_if_needed": "Vajadusel saab tiim aidata",
    "the_team_typically_replies_in_10800": "Meeskond vastab tavaliselt 3 tunni jooksul",
    "the_team_typically_replies_in_120": "Meeskond vastab tavaliselt 2 minuti jooksul",
    "the_team_typically_replies_in_1200": "Meeskond vastab tavaliselt 20 min. jooksul",
    "the_team_typically_replies_in_14400": "Meeskond vastab tavaliselt 4 tunni jooksul",
    "the_team_typically_replies_in_172800": "Meeskond vastab tavaliselt 2 päeva jooksul",
    "the_team_typically_replies_in_180": "Meeskond vastab tavaliselt 3 minuti jooksul",
    "the_team_typically_replies_in_1800": "Meeskond vastab tavaliselt 30 min. jooksul",
    "the_team_typically_replies_in_18000": "Meeskond vastab tavaliselt 5 tunni jooksul",
    "the_team_typically_replies_in_21600": "Meeskond vastab tavaliselt 6 tunni jooksul",
    "the_team_typically_replies_in_240": "Meeskond vastab tavaliselt 4 minuti jooksul",
    "the_team_typically_replies_in_259200": "Tiim vastab tavaliselt 3 päeva jooksul",
    "the_team_typically_replies_in_300": "Meeskond vastab tavaliselt 5 min. jooksul",
    "the_team_typically_replies_in_345600": "Tiim vastab tavaliselt 4 päeva jooksul",
    "the_team_typically_replies_in_3600": "Meeskond vastab tavaliselt 1 tunni jooksul",
    "the_team_typically_replies_in_43200": "Tiim vastab tavaliselt vähem kui 12 tunni jooksul",
    "the_team_typically_replies_in_432000": "Tiim vastab tavaliselt 5 päeva jooksul",
    "the_team_typically_replies_in_518400": "Tiim vastab tavaliselt 6 päeva jooksul",
    "the_team_typically_replies_in_60": "Meeskond vastab tavaliselt 1 minuti jooksul",
    "the_team_typically_replies_in_600": "Meeskond vastab tavaliselt 10 min. jooksul",
    "the_team_typically_replies_in_604800": "Meeskond vastab tavaliselt 1 nädala jooksul",
    "the_team_typically_replies_in_7200": "Meeskond vastab tavaliselt 2 tunni jooksul",
    "the_team_typically_replies_in_86400": "Meeskond vastab tavaliselt 1 päeva jooksul",
    "the_team_typically_replies_in_900": "Tiim vastab tavaliselt 15 minuti jooksul",
    "the_team_typically_replies_in_day": "Meeskond vastab tavaliselt päeva jooksul.",
    "the_team_typically_replies_in_hours": "Meeskond vastab tavaliselt mõne tunni jooksul.",
    "the_team_typically_replies_in_minutes": "Meeskond vastab tavaliselt mõne minuti jooksul.",
    "the_team_typically_replies_in_under_n_hours": "Meeskond vastab tavaliselt {hours} h jooksul.",
    "the_team_typically_replies_in_under_n_min": "Meeskond vastab tavaliselt {minutes} m jooksul.",
    "the_team_typically_replies_in_unknown": "Meeskond vastab esimesel võimalusel",
    "the_team_typically_replies_in_week": "Tiim vastab tavaliselt nädala jooksul.",
    "thinking": "Mõtleb ...",
    "tickets_cta_text": "Kas teil on selle töökäsu kohta küsimusi?",
    "tickets_have_a_question": "Kas teil on selle probleemi kohta küsimusi?",
    "tickets_space_title": "Piletid",
    "time_future_1h": "1 tund",
    "time_future_2h": "2 tundi",
    "time_future_30m": "30 minutit",
    "time_future_3h": "3 tundi",
    "time_future_day_0": "Esmaspäev",
    "time_future_day_1": "Teisipäev",
    "time_future_day_2": "Kolmapäev",
    "time_future_day_3": "Neljapäev",
    "time_future_day_4": "Reede",
    "time_future_day_5": "Laupäev",
    "time_future_day_6": "Pühapäeval",
    "time_future_later_today": "Täna hiljem",
    "time_future_next_week": "Järgmisel nädalal",
    "time_future_tomorrow": "Homme",
    "try_use_different_keywords": "Proovige kasutada erinevaid märksõnu või sisestage oma küsimus",
    "upfront_collection_form_close_out": "Saad siia igal ajal tagasi tulla, et vaadata vastuseid ja saata uuendusi.",
    "upfront_collection_form_introduction": "Jaga veidi konteksti, et aidata tiimil paremini ja kiiremini vastata.",
    "upfront_collection_form_submission_confirmation": "Täname, et saatsid selle konteksti. Lisa juba täna rohkem üksikasju, et hiljem aega säästa.",
    "upfront_collection_form_subtitle": "Saada tiimile veidi konteksti",
    "upfront_collection_form_subtitle_submitted": "Nad peavad seda kontkesti meeles",
    "upfront_collection_form_title": "Kiirem abi",
    "upfront_collection_form_title_submitted": "Tiimile saadetud saadetud!",
    "view_all": "Kuva kõik",
    "view_conversation": "Kuva vestlus",
    "we_are_here_to_help": "Oleme siin, et aidata",
    "we_run_on_intercom": "Töötame platvormil Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kui tiimikaaslane muudab vestluse olekut ...",
    "working_on_it": "Töö käib ...",
    "you_are_all_up_to_date": "Kõik on loetud!",
    "your_checklists": "Teie kontrollnimekirjad",
    "your_conversations": "Sinu vestlused",
    "your_recent_conversations": "Hiljutised vestlused",
    "your_recent_messages": "Hiljutised sõnumid"
  },
  "fa-IR": {
    "a_day": "یک روز",
    "a_few_hours": "چند ساعت",
    "a_few_minutes": "چند دقیقه",
    "a_week": "یک هفته",
    "active_state": "فعال",
    "ai_answer": "پاسخ هوش مصنوعی",
    "ai_answer_information": "پاسخ هوش مصنوعی، اطلاعات",
    "ai_bot": "ربات هوش مصنوعی",
    "almost_there": "تقریباً تمام است...",
    "answer": "پاسخ",
    "answer_bot_acknowledge_duplicate_content": "شما قبلاً این را دیده‌اید، اما این بهترین پاسخ موجود است.",
    "answer_bot_all_done": "کارساز بود👍",
    "answer_bot_ask_another_question": "یک پرسش دیگر بپرسید 🔁",
    "answer_bot_ask_something_else": "چیز دیگری بپرسید🔁",
    "answer_bot_connect_to_an_agent": "اتصال به یک عامل👤",
    "answer_bot_did_that_help": "آیا این پاسخ کمکی کرد یا به دنبال چیز دیگری هستید؟",
    "answer_bot_followup_expectations_prefix": "تیم درباره این مورد با شما تماس خواهد گرفت.",
    "answer_bot_get_more_help": "کمک بیشتری دریافت کنید 🌐",
    "answer_bot_get_more_help_followup": "این پیوند ممکن است کمک کند تا:",
    "answer_bot_i_have_another_question": "من یک پرسش دیگر دارم🔁",
    "answer_bot_im_not_done_yet": "هنوز کارم به اتمام نرسیده است🔁",
    "answer_bot_keep_waiting": "نه، هنوز به کمک نیاز دارم 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "می‌توانید سؤالتان را دوباره بیان کنید، یا راهنمایی بیشتری دریافت کنید",
    "answer_bot_looping_first_no_answer": "با عرض پوزش، من به‌عنوان یک ربات نتوانستم پاسخی برای آن پیدا کنم.",
    "answer_bot_looping_first_no_answer_followup": "می‌توانید سؤالتان را دوباره بیان کنید، یا با تیم ما صحبت کنید",
    "answer_bot_looping_no_answer": "با عرض پوزش، من به‌عنوان یک ربات، نتوانستم پاسخی برای آن پیدا کنم. آنچه می‌توانید انجام دهید این است",
    "answer_bot_more_answers": "موارد بیشتر را به من نشان بده👀",
    "answer_bot_no_answer_found": "با عرض پوزش، ما پاسخ سریعی برای آن نداریم. در اینجا چند گزینه دیگر وجود دارد.",
    "answer_bot_resolve_followup": "اگر مورد دیگری پیش آمد، همیشه می‌توانید در اینجا پاسخ دهید.",
    "answer_bot_talk_to_person": "با یک نفر صحبت کنید 👤",
    "answer_bot_that_answered_my_question": "که به پرسش من پاسخ داد👍",
    "answer_bot_thats_it": "همین است👍",
    "answer_bot_wait_for_the_team": "منتظر تیم باشید💬",
    "answerbot_ask_for_more_context": "حتماً. در حالی که منتظر هستیم، لطف می‌کنید جزئیات بیشتری را درباره وضعیت یا پرسش خود در میان بگذارید؟ به این ترتیب، می‌توانیم سریع‌تر پاسخی را بیابیم، و ممکن است اطلاعات بیشتری را برای شما پیدا کنم.",
    "answerbot_introduce_sneaky_answer": "از شما به‌خاطر ارائه جزئیات بیشتر، سپاسگزارم. من اطلاعات جدیدی را پیدا کردم که ممکن است به شما کمک کند.",
    "article_search_hint_text": "جستجوی مقالات...",
    "ask_a_question": "یک پرسش بپرسید",
    "ask_a_question_placeholder": "پرسشی بپرسید…",
    "ask_a_question_with_ellipsis": "سؤالی بپرسید...",
    "ask_another_question_with_ellipsis": "سؤال دیگری بپرسید...",
    "ask_for_the_team_if_needed": "در صورت لزوم، درخواست گفتگو با تیم کنید",
    "ask_me_a_question": "یک پرسش از من بپرسید",
    "ask_our_bot_a_question": "از ربات ما سؤالی بپرسید",
    "avatar": "آواتار",
    "avatars": "آواتارها",
    "bot": "ربات",
    "bot_answers_instantly": "ربات بلافاصله پاسخ می‌دهد",
    "bot_expectation_text": "ربات ما می‌تواند به بسیاری از پرسش‌های رایج پاسخ دهد",
    "bot_intro_default_part_1": "سلام! این یک ربات است که صحبت می‌کند. من اینجا هستم تا به پرسش‌ها شما پاسخ دهم، اما همیشه این امکان برای شما وجود دارد که با تیم ما صحبت کنید.",
    "bot_intro_default_part_2": "خوب، بفرمایید چه چیزی شما را امروز به اینجا کشاند؟",
    "bot_is_still_thinking": "ربات همچنان درحال فکر کردن...",
    "bot_is_thinking": "ربات درحال فکر کردن...",
    "browse_collections": "مجموعه‌ها را مرور کنید",
    "chat_with_us": "با ما گپ بزنید",
    "check_later_for_updates": "برای به‌روزرسانی بعداً مراجعه کنید.",
    "checklist_card_first_step": "مرحله اول",
    "checklist_card_next_step": "مرحله بعدی",
    "checklist_completed": "تکمیل شد",
    "checklist_in_progress_time_remaining_plural": "حدود {minutes} دقیقه باقی مانده است",
    "checklist_in_progress_time_remaining_singular": "حدود 1 دقیقه باقی مانده است",
    "checklist_mark_step_as_complete": "علامت‌گذاری به‌عنوان تکمیل‌شده",
    "checklist_not_started_time_remaining_plural": "حدود {minutes} دقیقه",
    "checklist_not_started_time_remaining_singular": "حدود 1 دقیقه",
    "checklist_number_of_steps_plural": "{number_of_steps} مرحله",
    "checklist_number_of_steps_progress_plural": "{steps_completed} از {total_steps} مرحله انجام شد",
    "checklist_number_of_steps_progress_singular": "1 از {total_steps} مرحله انجام شد",
    "checklist_number_of_steps_singular": "1 مرحله",
    "checklist_sender_name": "اشتراک‌گذاری‌شده توسط {name}",
    "checklist_step_action_button": "دکمه اقدام مرحله",
    "checklist_step_completed": "تکمیل شد",
    "contact_support": "با پشتیبانی تماس بگیرید",
    "contact_us": "با ما تماس بگیرید",
    "continue_the_conversation": "به گفتگو ادامه دهید",
    "conversation_card_office_hours_header": "دوباره آنلاین خواهیم شد",
    "conversation_card_reply_time_header": "زمان پاسخگویی معمول ما",
    "conversation_card_reply_time_header_m5": "زمان معمول پاسخگویی",
    "conversation_card_unknown_response_time": "در اسرع وقت پاسخ خواهیم داد",
    "conversation_part_sample_text": "سلام، چگونه می‌توانم کمک کنم؟ همیشه می‌توانید در صورت لزوم، با تیم ما صحبت کنید.",
    "conversations_space_title": "گفتگوها",
    "create_a_ticket": "ایجاد یک درخواست پشتیبانی",
    "currently_no_help_articles": "در حال حاضر، هیچ مقاله کمکی در دسترس نیست.",
    "customer_sends_their_first_message": "مشتری اولین پیام خود را ارسال می‌کند...",
    "expected_response_delay_10800": "کمتر از 3 ساعت",
    "expected_response_delay_120": "کمتر از 2 دقیقه",
    "expected_response_delay_1200": "کمتر از 20 دقیقه",
    "expected_response_delay_14400": "کمتر از 4 ساعت",
    "expected_response_delay_172800": "2 روز",
    "expected_response_delay_180": "کمتر از 3 دقیقه",
    "expected_response_delay_1800": "کمتر از 30 دقیقه",
    "expected_response_delay_18000": "کمتر از 5 ساعت",
    "expected_response_delay_21600": "کمتر از 6 ساعت",
    "expected_response_delay_240": "کمتر از 4 دقیقه",
    "expected_response_delay_259200": "3 روز",
    "expected_response_delay_300": "کمتر از 5 دقیقه",
    "expected_response_delay_345600": "4 روز",
    "expected_response_delay_3600": "کمتر از 1 ساعت",
    "expected_response_delay_43200": "کمتر از 12 ساعت",
    "expected_response_delay_432000": "5 روز",
    "expected_response_delay_518400": "6 روز",
    "expected_response_delay_60": "کمتر از 1 دقیقه",
    "expected_response_delay_600": "کمتر از 10 دقیقه",
    "expected_response_delay_604800": "کمتر از 1 هفته",
    "expected_response_delay_7200": "کمتر از 2 ساعت",
    "expected_response_delay_86400": "1 روز",
    "expected_response_delay_900": "کمتر از 15 دقیقه",
    "expected_response_delay_unknown": "به محض اینکه بتوانیم",
    "extra_labels_count": "+ {labels_count} برچسب",
    "for_best_results_provide_detail": "برای بهترین نتایج، تا حد امکان جزئیات کامل را ارائه دهید",
    "generating_answer": "درحال ایجاد پاسخ...",
    "generic_triage_default": "ما فقط به اطلاعات بیشتری نیاز داریم تا شما را با فرد مناسب ارتباط دهیم",
    "get_an_instant_answer": "یک پاسخ فوری دریافت کنید",
    "get_help": "کمک بگیرید",
    "go_to": "به {app_name} بروید",
    "help_space_title": "کمک",
    "home_header_mobile_hello_firstname": "سلام، {firstname}.",
    "home_header_mobile_hello_there": "سلام",
    "home_space_title": "صفحه اصلی",
    "how_can_we_help": "چطور می‌توانیم کمک کنیم؟",
    "how_would_you_rate_this": "شما به این چه امتیازی می‌دهید؟",
    "learn_more": "بیشتر بدانید",
    "message_placeholder": "پیام...",
    "messages_space_title": "پیام‌ها",
    "messenger_bot_reply_time": "ربات ما فوراً پاسخ خواهد داد",
    "messenger_settings_introduction": "چطور می‌توانیم کمک کنیم؟",
    "multiple_articles": "{total_articles} مقاله",
    "multiple_collections": "{total_collection} مجموعه",
    "new_conversation": "گفتگوی جدید",
    "news": "اخبار",
    "news_empty_title": "هنوز خبری نیست",
    "news_latest_subtitle": "از تیم {team_name}",
    "news_latest_title": "آخرین",
    "news_older_title": "قدیمی‌تر",
    "news_space_title": "اخبار",
    "newsfeed": "اخبار",
    "no_articles_to_display": "هنوز مقاله‌ای وجود ندارد",
    "no_help_articles_to_display": "هیچ مقاله کمکی وجود ندارد",
    "no_results_for_searchterm": "هیچ نتیجه‌ای برای {searchTerm} وجود ندارد",
    "no_tasks": "وظیفه‌ای موجود نیست",
    "no_tasks_available_currently": "در حال حاضر، هیچ وظیفه‌ای در دسترس نیست.",
    "ok_the_team_typically_replies_in_day": "بسیار خب، تیم معمولاً ظرف یک روز، پاسخ می‌دهد.",
    "ok_the_team_typically_replies_in_hours": "بسیار خب، تیم معمولاً ظرف چند ساعت، پاسخ می‌دهد.",
    "ok_the_team_typically_replies_in_minutes": "بسیار خب، تیم معمولاً ظرف چند دقیقه، پاسخ می‌دهد.",
    "ok_the_team_typically_replies_in_under_n_hours": "بسیار خب، تیم معمولاً ظرف کمتر از {hours} ساعت، پاسخ می‌دهد.",
    "ok_the_team_typically_replies_in_under_n_min": "بسیار خب، تیم معمولاً ظرف کمتر از {minutes} دقیقه، پاسخ می‌دهد.",
    "ok_the_team_typically_replies_in_week": "بسیار خب، تیم معمولاً ظرف یک هفته، پاسخ می‌دهد.",
    "our_bot_and_team_can_help": "ربات و تیم ما می‌توانند کمک کنند",
    "our_bot_answers_instantly": "ربات ما فوراً پاسخ می‌دهد",
    "powered_by_intercom": "تحت پشتیبانی توسط Intercom",
    "previous_bot_hands_over": "محتوای ربات قبلی...",
    "proactive_expected_response_delay_10800": "معمولاً ظرف کمتر از 3 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_120": "معمولاً ظرف کمتر از 2 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_1200": "معمولاً ظرف کمتر از 20 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_14400": "معمولاً ظرف کمتر از 4 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_172800": "معمولاً ظرف 2 روز، پاسخ می‌دهد",
    "proactive_expected_response_delay_180": "معمولاً ظرف کمتر از 3 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_1800": "معمولاً ظرف کمتر از 30 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_18000": "معمولاً ظرف کمتر از 5 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_21600": "معمولاً ظرف کمتر از 6 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_240": "معمولاً ظرف کمتر از 4 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_259200": "معمولاً ظرف 3 روز، پاسخ می‌دهد",
    "proactive_expected_response_delay_300": "معمولاً ظرف کمتر از 5 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_345600": "معمولاً ظرف 4 روز، پاسخ می‌دهد",
    "proactive_expected_response_delay_3600": "معمولاً ظرف کمتر از 1 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_43200": "معمولاً ظرف 12 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_432000": "معمولاً ظرف 5 روز، پاسخ می‌دهد",
    "proactive_expected_response_delay_518400": "معمولاً ظرف 6 روز، پاسخ می‌دهد",
    "proactive_expected_response_delay_60": "معمولاً ظرف کمتر از 1 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_600": "معمولاً ظرف کمتر از 10 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_604800": "معمولاً ظرف کمتر از 1 هفته، پاسخ می‌دهد",
    "proactive_expected_response_delay_7200": "معمولاً ظرف کمتر از 2 ساعت، پاسخ می‌دهد",
    "proactive_expected_response_delay_86400": "معمولاً ظرف 1 روز، پاسخ می‌دهد",
    "proactive_expected_response_delay_900": "معمولاً ظرف کمتر از 15 دقیقه، پاسخ می‌دهد",
    "proactive_expected_response_delay_unknown": "در اسرع وقت پاسخ خواهند داد",
    "profile_location": "ساعت {time} در {location}",
    "recent_ticket": "درخواست پشتیبانی اخیر",
    "recent_tickets": "درخواست‌های پشتیبانی اخیر",
    "search_browse_empty_state_text": "با عرض پوزش، ما نتوانستیم نتیجه‌ای برای آن پیدا کنیم. سعی کنید جستجوی خود را مجدداً بنویسید.",
    "search_for_help": "در جستجوی راهنما",
    "see_all": "مشاهده همه",
    "see_all_conversations": "همه گفتگوهای خود را ببینید",
    "see_previous_conversations": "گفتگوهای قبلی خود را مشاهده کنید",
    "send": "ارسال",
    "send_a_message": "پیامی را ارسال کنید…",
    "send_a_message_reply_time": "زمان معمول پاسخگویی عبارت است از",
    "send_us_a_message": "برای ما یک پیام بفرستید",
    "set_expectations_bot_email_header": "پاسخ‌ها را در اینجا و در ایمیل خود دریافت خواهید کرد:",
    "set_expectations_bot_office_hours_header": "تیم برمی‌گردد",
    "set_expectations_bot_sms_header": "در اینجا و از طریق پیامک پاسخ‌ها را دریافت خواهید کرد:",
    "set_expectations_replies_to_email": "شما در اینجا و از طریق <b>{user_email}</b> پاسخ دریافت خواهید کرد.",
    "set_expectations_replies_to_email_without_bold_text": "در اینجا و از طریق {user_email}، پاسخ را دریافت خواهید کرد.",
    "set_expectations_team_reply_asap": "تیم در اسرع وقت پاسخ خواهد داد.",
    "shared_by": "اشتراک‌گذاری‌شده توسط",
    "show_less": "نمایش کمتر",
    "single_article": "1 مقاله",
    "single_collection": "1 مجموعه",
    "source": "منبع",
    "sources": "منابع",
    "start_a_conversation": "شروع یک گفتگو",
    "start_conversation": "گفتگویی را شروع کنید…",
    "still_generating_answer": "همچنان درحال ایجاد پاسخ...",
    "still_thinking": "همچنان درحال تفکر...",
    "still_working_on_it": "همچنان درحال کار کردن روی آن...",
    "tasks_space_title": "وظایف",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} در اسرع وقت پاسخ خواهند داد",
    "teammate_changes_team_assignment_of_a_conversation": "هم‌تیمی تکلیف گفتگو را تغییر داده است...",
    "tell_us_what_you_need": "به ما بگویید در چه زمینه‌ای به کمک نیاز دارید و ربات ما تمام تلاش خود را برای پاسخگویی به کار خواهد گرفت",
    "the_team_can_help_if_needed": "تیم می‌تواند در صورت نیاز کمک کند",
    "the_team_typically_replies_in_10800": "تیم معمولاً ظرف کمتر از 3 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_120": "تیم معمولاً ظرف کمتر از 2 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_1200": "تیم معمولاً ظرف کمتر از 20 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_14400": "تیم معمولاً ظرف کمتر از 4 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_172800": "تیم معمولاً ظرف 2 روز، پاسخ می‌دهد",
    "the_team_typically_replies_in_180": "تیم معمولاً ظرف کمتر از 3 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_1800": "تیم معمولاً ظرف کمتر از 30 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_18000": "تیم معمولاً ظرف کمتر از 5 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_21600": "تیم معمولاً ظرف کمتر از 6 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_240": "تیم معمولاً ظرف کمتر از 4 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_259200": "تیم معمولاً ظرف 3 روز، پاسخ می‌دهد",
    "the_team_typically_replies_in_300": "تیم معمولاً ظرف کمتر از 5 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_345600": "تیم معمولاً ظرف 4 روز، پاسخ می‌دهد",
    "the_team_typically_replies_in_3600": "تیم معمولاً ظرف کمتر از 1 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_43200": "تیم معمولاً ظرف کمتر از 12 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_432000": "تیم معمولاً ظرف 5 روز، پاسخ می‌دهد",
    "the_team_typically_replies_in_518400": "تیم معمولاً ظرف 6 روز، پاسخ می‌دهد",
    "the_team_typically_replies_in_60": "تیم معمولاً ظرف کمتر از 1 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_600": "تیم معمولاً ظرف کمتر از 10 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_604800": "تیم معمولاً ظرف کمتر از 1 هفته، پاسخ می‌دهد",
    "the_team_typically_replies_in_7200": "تیم معمولاً ظرف کمتر از 2 ساعت، پاسخ می‌دهد",
    "the_team_typically_replies_in_86400": "تیم معمولاً ظرف 1 روز، پاسخ می‌دهد",
    "the_team_typically_replies_in_900": "تیم معمولاً ظرف کمتر از 15 دقیقه، پاسخ می‌دهد",
    "the_team_typically_replies_in_day": "تیم معمولاً ظرف یک روز، پاسخ می‌دهد.",
    "the_team_typically_replies_in_hours": "تیم معمولاً ظرف چند ساعت، پاسخ می‌دهد.",
    "the_team_typically_replies_in_minutes": "تیم معمولاً ظرف چند دقیقه، پاسخ می‌دهد.",
    "the_team_typically_replies_in_under_n_hours": "تیم معمولاً ظرف کمتر از {hours} ساعت، پاسخ می‌دهد.",
    "the_team_typically_replies_in_under_n_min": "تیم معمولاً ظرف کمتر از {minutes} دقیقه، پاسخ می‌دهد.",
    "the_team_typically_replies_in_unknown": "تیم در اسرع وقت پاسخ خواهد داد",
    "the_team_typically_replies_in_week": "تیم معمولاً ظرف یک هفته، پاسخ می‌دهد.",
    "thinking": "درحال تفکر...",
    "tickets_cta_text": "درباره این درخواست پشتیبانی پرسشی دارید؟",
    "tickets_have_a_question": "درمورد این موضوع سؤالی دارید؟",
    "tickets_space_title": "تیکت‌ها",
    "time_future_1h": "ظرف 1 ساعت",
    "time_future_2h": "ظرف 2 ساعت",
    "time_future_30m": "ظرف 30 دقیقه",
    "time_future_3h": "ظرف 3 ساعت",
    "time_future_day_0": "دوشنبه",
    "time_future_day_1": "سه‌شنبه",
    "time_future_day_2": "چهارشنبه",
    "time_future_day_3": "پنج‌شنبه",
    "time_future_day_4": "جمعه",
    "time_future_day_5": "شنبه",
    "time_future_day_6": "یکشنبه",
    "time_future_later_today": "امروز در فرصتی دیگر",
    "time_future_next_week": "هفته بعد",
    "time_future_tomorrow": "فردا",
    "try_use_different_keywords": "کلیدواژه مختلفی را امتحان کنید یا پرسش خود را کامل تایپ کنید",
    "upfront_collection_form_close_out": "برای دیدن پاسخ‌ها و ارسال به‌روزرسانی‌ها، می‌توانید در هر زمان به اینجا بازگردید.",
    "upfront_collection_form_introduction": "مقداری اطلاعات جانبی به اشتراک بگذارید تا به تیم کمک کند بهتر و سریعتر پاسخ دهد.",
    "upfront_collection_form_submission_confirmation": "از ارسال آن متن سپاسگزاریم. برای صرفه‌جویی در زمان بعداً، همین حالا به راحتی جزئیات بیشتری را اضافه کنید.",
    "upfront_collection_form_subtitle": "مقداری اطلاعات جانبی برای تیم بفرستید",
    "upfront_collection_form_subtitle_submitted": "آنها این زمینه را در ذهن خواهند داشت",
    "upfront_collection_form_title": "سریعتر کمک بگیرید",
    "upfront_collection_form_title_submitted": "برای تیم ارسال شد!",
    "view_all": "مشاهده همه",
    "view_conversation": "مشاهده گفتگو",
    "we_are_here_to_help": "ما آماده کمک‌رسانی هستیم",
    "we_run_on_intercom": "ما با Intercom کار می‌کنیم",
    "when_a_teammate_changes_the_state_of_a_conversation": "وقتی یک هم‌تیمی حالت گفتگو را تغییر می‌دهد...",
    "working_on_it": "درحال کار کردن روی آن...",
    "you_are_all_up_to_date": "همه موارد را بررسی کردید!",
    "your_checklists": "فهرست‌های بررسی شما",
    "your_conversations": "گفتگوهای شما",
    "your_recent_conversations": "گفتگوهای اخیر",
    "your_recent_messages": "پیام‌های اخیر"
  },
  "fi": {
    "a_day": "Päivä",
    "a_few_hours": "Muutama tunti",
    "a_few_minutes": "Muutama minuutti",
    "a_week": "Viikko",
    "active_state": "Aktiivinen",
    "ai_answer": "Tekoälyvastaus",
    "ai_answer_information": "Tekoälyvastaus, tietoa",
    "ai_bot": "Tekoälybotti",
    "almost_there": "Melkein valmista...",
    "answer": "Vastaus",
    "answer_bot_acknowledge_duplicate_content": "Olet jo nähnyt tämän, mutta tämä on paras saatavilla oleva vastaus.",
    "answer_bot_all_done": "Tämä auttoi 👍",
    "answer_bot_ask_another_question": "Esitä toinen kysymys 🔁",
    "answer_bot_ask_something_else": "Kysy jotain muuta 🔁",
    "answer_bot_connect_to_an_agent": "Ota yhteyttä edustajaan 👤",
    "answer_bot_did_that_help": "Oliko tästä vastauksesta apua vai etsitkö jotain muuta?",
    "answer_bot_followup_expectations_prefix": "Tiimimme ottaa sinuun pian yhteyttä.",
    "answer_bot_get_more_help": "Saa lisää apua 🌐",
    "answer_bot_get_more_help_followup": "Tästä linkistä voi olla apua:",
    "answer_bot_i_have_another_question": "Minulla on toinen kysymys 🔁",
    "answer_bot_im_not_done_yet": "En ole vielä valmis 🔁",
    "answer_bot_keep_waiting": "Ei, tarvitsen vielä apua 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Voit muotoilla kysymyksesi uudelleen tai pyytää lisäapua",
    "answer_bot_looping_first_no_answer": "Valitettavasti en bottina löytänyt kysymykseen vastausta.",
    "answer_bot_looping_first_no_answer_followup": "Voit muotoilla kysymyksen uudelleen tai keskustella tiimimme kanssa",
    "answer_bot_looping_no_answer": "Valitettavasti en bottina löytänyt kysymykseen vastausta. Voit toimia näin",
    "answer_bot_more_answers": "Näytä lisää 👀",
    "answer_bot_no_answer_found": "Valitettavasti meillä ei ole siihen nopeaa vastausta. Tässä on muita vaihtoehtoja.",
    "answer_bot_resolve_followup": "Voit aina vastata tähän, jos tarvitset vielä apua.",
    "answer_bot_talk_to_person": "Keskustele henkilön kanssa 👤",
    "answer_bot_that_answered_my_question": "Tämä vastasi kysymykseeni 👍",
    "answer_bot_thats_it": "Asia on selvä 👍",
    "answer_bot_wait_for_the_team": "Odota tiimin jäsentä 💬",
    "answerbot_ask_for_more_context": "Totta kai. Sillä välin kun odotamme, voitko kertoa lisää tilanteestasi tai kysymyksestäsi? Näin voimme löytää vastauksen nopeammin, ja voin antaa sinulle lisätietoja.",
    "answerbot_introduce_sneaky_answer": "Kiitos tästä lisäselvennyksestä. Löysin uutta tietoa, josta voi olla sinulle hyötyä.",
    "article_search_hint_text": "Hae artikkeleita...",
    "ask_a_question": "Kysy kysymys",
    "ask_a_question_placeholder": "Kysy kysymys...",
    "ask_a_question_with_ellipsis": "Kysy jotain...",
    "ask_another_question_with_ellipsis": "Kysy vielä jotain...",
    "ask_for_the_team_if_needed": "Kysy tiimiltä tarvittaessa",
    "ask_me_a_question": "Kysy minulta jotain",
    "ask_our_bot_a_question": "Kysy botiltamme",
    "avatar": "Avatar",
    "avatars": "Avatarit",
    "bot": "Botti",
    "bot_answers_instantly": "Botti vastaa heti",
    "bot_expectation_text": "Bottimme osaa vastata moniin yleisiin kysymyksiin",
    "bot_intro_default_part_1": "Hei, täällä puhuu botti. Olen valmis vastaamaan kysymyksiisi, mutta voit halutessasi myös puhua tiimimme kanssa.",
    "bot_intro_default_part_2": "Miten voin auttaa?",
    "bot_is_still_thinking": "Botti miettii vielä...",
    "bot_is_thinking": "Botti miettii...",
    "browse_collections": "Selaa kokoelmia",
    "chat_with_us": "Chattaa kanssamme",
    "check_later_for_updates": "Katso myöhemmin uudelleen.",
    "checklist_card_first_step": "Ensimmäinen vaihe",
    "checklist_card_next_step": "Seuraava vaihe",
    "checklist_completed": "Valmis",
    "checklist_in_progress_time_remaining_plural": "Noin {minutes} minuuttia jäljellä",
    "checklist_in_progress_time_remaining_singular": "Noin 1 minuutti jäljellä",
    "checklist_mark_step_as_complete": "Merkitse valmiiksi",
    "checklist_not_started_time_remaining_plural": "Noin {minutes} minuuttia",
    "checklist_not_started_time_remaining_singular": "Noin 1 minuutti",
    "checklist_number_of_steps_plural": "{number_of_steps} vaihetta",
    "checklist_number_of_steps_progress_plural": "{steps_completed} / {total_steps} valmis",
    "checklist_number_of_steps_progress_singular": "1/{total_steps} valmiina",
    "checklist_number_of_steps_singular": "1 vaihe",
    "checklist_sender_name": "Jakaja {name}",
    "checklist_step_action_button": "Vaiheen toimintopainike",
    "checklist_step_completed": "Valmis",
    "contact_support": "Ota yhteyttä tukeen",
    "contact_us": "Ota meihin yhteyttä",
    "continue_the_conversation": "Jatka keskustelua",
    "conversation_card_office_hours_header": "Milloin olemme taas paikalla",
    "conversation_card_reply_time_header": "Normaali vastausaikamme",
    "conversation_card_reply_time_header_m5": "Tavallinen vastausaika",
    "conversation_card_unknown_response_time": "Vastaamme mahdollisimman pian",
    "conversation_part_sample_text": "Miten voin auttaa? Voit aina tarvittaessa keskustella tiimimme kanssa.",
    "conversations_space_title": "Keskustelut",
    "create_a_ticket": "Luo tukipyyntö",
    "currently_no_help_articles": "Ohjeartikkeleita ei ole tällä hetkellä saatavilla.",
    "customer_sends_their_first_message": "Asiakas lähettää ensimmäisen viestinsä...",
    "expected_response_delay_10800": "alle 3 tuntia",
    "expected_response_delay_120": "alle 2 minuuttia",
    "expected_response_delay_1200": "alle 20 minuuttia",
    "expected_response_delay_14400": "alle 4 tuntia",
    "expected_response_delay_172800": "2 päivää",
    "expected_response_delay_180": "alle 3 minuuttia",
    "expected_response_delay_1800": "alle 30 minuutin",
    "expected_response_delay_18000": "alle 5 tuntia",
    "expected_response_delay_21600": "alle 6 tuntia",
    "expected_response_delay_240": "alle 4 minuuttia",
    "expected_response_delay_259200": "3 päivässä",
    "expected_response_delay_300": "alle 5 minuuttia",
    "expected_response_delay_345600": "4 päivässä",
    "expected_response_delay_3600": "alle tunti",
    "expected_response_delay_43200": "alle 2 tunnissa",
    "expected_response_delay_432000": "5 päivässä",
    "expected_response_delay_518400": "6 päivää",
    "expected_response_delay_60": "alle 1 minuuttia",
    "expected_response_delay_600": "alle 10 minuuttia",
    "expected_response_delay_604800": "alle viikko",
    "expected_response_delay_7200": "alle 2 tuntia",
    "expected_response_delay_86400": "1 päivä",
    "expected_response_delay_900": "alle 15 minuuttia",
    "expected_response_delay_unknown": "Mahdollisimman pian",
    "extra_labels_count": "+ {labels_count} tarraa",
    "for_best_results_provide_detail": "Anna parhaan tuloksen saamiseksi mahdollisimman paljon yksityiskohtia",
    "generating_answer": "Vastausta luodaan...",
    "generic_triage_default": "Tarvitsemme vain hieman lisätietoja yhdistääksemme sinut oikeaan henkilöön",
    "get_an_instant_answer": "Välitön vastaus",
    "get_help": "Hanki apua",
    "go_to": "Siirry sovellukseen {app_name}",
    "help_space_title": "Ohje",
    "home_header_mobile_hello_firstname": "Hei, {firstname}.",
    "home_header_mobile_hello_there": "Moikka",
    "home_space_title": "Koti",
    "how_can_we_help": "Miten voimme auttaa?",
    "how_would_you_rate_this": "Miten arvioisit tämän?",
    "learn_more": "Lue lisää",
    "message_placeholder": "Viesti…",
    "messages_space_title": "Viestit",
    "messenger_bot_reply_time": "Bottimme vastaa välittömästi",
    "messenger_settings_introduction": "Miten voimme auttaa?",
    "multiple_articles": "{total_articles} artikkelia",
    "multiple_collections": "{total_collection} kokoelmaa",
    "new_conversation": "Uusi keskustelu",
    "news": "Uutiset",
    "news_empty_title": "Ei vielä uutisia",
    "news_latest_subtitle": "Tiimiltä {team_name}",
    "news_latest_title": "Uusin",
    "news_older_title": "Vanhemmat",
    "news_space_title": "Uutiset",
    "newsfeed": "Uutissyöte",
    "no_articles_to_display": "Ei vielä artikkeleita",
    "no_help_articles_to_display": "Ei ohjeartikkeleita",
    "no_results_for_searchterm": "Ei tuloksia haulle {searchTerm}",
    "no_tasks": "Ei tehtäviä",
    "no_tasks_available_currently": "Tehtäviä ei ole tällä hetkellä saatavilla.",
    "ok_the_team_typically_replies_in_day": "Ok, tiimi vastaa tavallisesti vuorokauden kuluessa.",
    "ok_the_team_typically_replies_in_hours": "Ok, tiimi vastaa tavallisesti muutamassa tunnissa.",
    "ok_the_team_typically_replies_in_minutes": "Ok, tiimi vastaa tavallisesti muutamassa minuutissa.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, tiimi vastaa tavallisesti alle {hours} tunnissa.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, tiimi vastaa tavallisesti alle {minutes} minuutissa.",
    "ok_the_team_typically_replies_in_week": "Ok, tiimi vastaa tavallisesti viikon kuluessa.",
    "our_bot_and_team_can_help": "Bottimme ja tiimimme voivat auttaa",
    "our_bot_answers_instantly": "Bottimme vastaa välittömästi",
    "powered_by_intercom": "Palvelun tarjoaa Intercom",
    "previous_bot_hands_over": "Sisältöä aiemmasta botista...",
    "proactive_expected_response_delay_10800": "Vastaa tavallisesti alle 3 tunnissa",
    "proactive_expected_response_delay_120": "Vastaa tavallisesti alle 2 minuutissa",
    "proactive_expected_response_delay_1200": "Vastaa tavallisesti alle 20 minuutissa",
    "proactive_expected_response_delay_14400": "Vastaa tavallisesti alle 4 tunnissa",
    "proactive_expected_response_delay_172800": "Vastaa tavallisesti 2 päivässä",
    "proactive_expected_response_delay_180": "Vastaa tavallisesti alle 3 minuutissa",
    "proactive_expected_response_delay_1800": "Vastaa tavallisesti alle 30 minuutissa",
    "proactive_expected_response_delay_18000": "Vastaa tavallisesti alle 5 tunnissa",
    "proactive_expected_response_delay_21600": "Vastaa tavallisesti alle 6 tunnissa",
    "proactive_expected_response_delay_240": "Vastaa tavallisesti alle 4 minuutissa",
    "proactive_expected_response_delay_259200": "Vastaa tavallisesti 3 päivässä",
    "proactive_expected_response_delay_300": "Vastaa tavallisesti alle 5 minuutissa",
    "proactive_expected_response_delay_345600": "Vastaa tavallisesti 4 päivässä",
    "proactive_expected_response_delay_3600": "Vastaa tavallisesti alle 1 tunnissa",
    "proactive_expected_response_delay_43200": "Vastaa tavallisesti 12 tunnissa",
    "proactive_expected_response_delay_432000": "Vastaa tavallisesti 5 päivässä",
    "proactive_expected_response_delay_518400": "Vastaa tavallisesti 6 päivässä",
    "proactive_expected_response_delay_60": "Vastaa tavallisesti alle 1 minuutissa",
    "proactive_expected_response_delay_600": "Vastaa tavallisesti alle 10 minuutissa",
    "proactive_expected_response_delay_604800": "Vastaa tavallisesti alle 1 viikossa",
    "proactive_expected_response_delay_7200": "Vastaa tavallisesti alle 2 tunnissa",
    "proactive_expected_response_delay_86400": "Vastaa tavallisesti 1 päivässä",
    "proactive_expected_response_delay_900": "Vastaa tavallisesti alle 15 minuutissa",
    "proactive_expected_response_delay_unknown": "Vastaamme mahdollisimman pian",
    "profile_location": "{time} – {location}",
    "recent_ticket": "Uusin tukipyyntö",
    "recent_tickets": "Uusimmat tukipyynnöt",
    "search_browse_empty_state_text": "Valitettavasti hakusi ei tuottanut tuloksia. Muotoile haku uudelleen.",
    "search_for_help": "Hae apua",
    "see_all": "Katso kaikki",
    "see_all_conversations": "Näytä kaikki keskustelut",
    "see_previous_conversations": "Näytä edelliset keskustelut",
    "send": "Lähetä",
    "send_a_message": "Lähetä viesti…",
    "send_a_message_reply_time": "Tavallinen vastausaika on",
    "send_us_a_message": "Lähetä meille viesti",
    "set_expectations_bot_email_header": "Saat vastaukset tänne ja sähköpostiisi:",
    "set_expectations_bot_office_hours_header": "Tiimi on taas tavattavissa:",
    "set_expectations_bot_sms_header": "Saat vastaukset tänne ja tekstiviestillä:",
    "set_expectations_replies_to_email": "Saat vastauksia tänne ja osoitteeseen <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Saat vastauksia tänne ja osoitteeseen {user_email}",
    "set_expectations_team_reply_asap": "Tiimi vastaa mahdollisimman pian.",
    "shared_by": "Jakaja",
    "show_less": "Näytä vähemmän",
    "single_article": "1 artikkeli",
    "single_collection": "1 kokoelma",
    "source": "Lähde",
    "sources": "Lähteet",
    "start_a_conversation": "Aloita keskustelu",
    "start_conversation": "Aloita keskustelu",
    "still_generating_answer": "Vastausta luodaan edelleen...",
    "still_thinking": "Botti miettii vielä...",
    "still_working_on_it": "Edelleen työn alla...",
    "tasks_space_title": "Tehtävät",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} vastaa mahdollisimman pian.",
    "teammate_changes_team_assignment_of_a_conversation": "Tiimin jäsen on muuttanut keskustelun tehtävää...",
    "tell_us_what_you_need": "Kerro, mihin tarvitset apua, niin bottimme vastaa parhaansa mukaan",
    "the_team_can_help_if_needed": "Tiimi tulee tarvittaessa avuksi",
    "the_team_typically_replies_in_10800": "Tiimi vastaa tavallisesti alle 3 tunnissa.",
    "the_team_typically_replies_in_120": "Tiimi vastaa tavallisesti alle 2 minuutissa",
    "the_team_typically_replies_in_1200": "Tiimi vastaa tavallisesti alle 20 minuutissa.",
    "the_team_typically_replies_in_14400": "Tiimi vastaa tavallisesti alle 4 tunnissa.",
    "the_team_typically_replies_in_172800": "Tiimi vastaa tavallisesti 2 vuorokauden kuluessa.",
    "the_team_typically_replies_in_180": "Tiimi vastaa tavallisesti alle 3 minuutissa",
    "the_team_typically_replies_in_1800": "Tiimi vastaa tavallisesti alle 30 minuutissa.",
    "the_team_typically_replies_in_18000": "Tiimi vastaa tavallisesti alle 5 tunnissa.",
    "the_team_typically_replies_in_21600": "Tiimi vastaa tavallisesti alle 6 tunnissa.",
    "the_team_typically_replies_in_240": "Tiimi vastaa tavallisesti alle 4 minuutissa",
    "the_team_typically_replies_in_259200": "Tiimi vastaa tavallisesti 3 päivässä",
    "the_team_typically_replies_in_300": "Tiimi vastaa tavallisesti alle 5 minuutissa.",
    "the_team_typically_replies_in_345600": "Tiimi vastaa tavallisesti 4 päivässä",
    "the_team_typically_replies_in_3600": "Tiimi vastaa tavallisesti alle tunnissa.",
    "the_team_typically_replies_in_43200": "Tiimi vastaa tavallisesti alle 12 tunnissa",
    "the_team_typically_replies_in_432000": "Tiimi vastaa tavallisesti 5 päivässä",
    "the_team_typically_replies_in_518400": "Tiimi vastaa tavallisesti 6 päivässä",
    "the_team_typically_replies_in_60": "Tiimi vastaa tavallisesti alle 1 minuutissa",
    "the_team_typically_replies_in_600": "Tiimi vastaa tavallisesti alle 10 minuutissa.",
    "the_team_typically_replies_in_604800": "Tiimi vastaa tavallisesti viikon kuluessa.",
    "the_team_typically_replies_in_7200": "Tiimi vastaa tavallisesti alle 2 tunnissa.",
    "the_team_typically_replies_in_86400": "Tiimi vastaa tavallisesti vuorokauden kuluessa.",
    "the_team_typically_replies_in_900": "Tiimi vastaa tavallisesti alle 15 minuutissa",
    "the_team_typically_replies_in_day": "Tiimi vastaa tavallisesti vuorokauden kuluessa.",
    "the_team_typically_replies_in_hours": "Tiimi vastaa tavallisesti muutamassa tunnissa.",
    "the_team_typically_replies_in_minutes": "Tiimi vastaa tavallisesti muutamassa minuutissa.",
    "the_team_typically_replies_in_under_n_hours": "Tiimi vastaa tavallisesti alle {hours} tunnissa.",
    "the_team_typically_replies_in_under_n_min": "Tiimi vastaa tavallisesti alle {minutes} minuutissa.",
    "the_team_typically_replies_in_unknown": "Tiimi vastaa mahdollisimman pian.",
    "the_team_typically_replies_in_week": "Tiimi vastaa tavallisesti viikon kuluessa.",
    "thinking": "Botti miettii...",
    "tickets_cta_text": "Onko sinulla kysyttävää tästä tukipyynnöstä?",
    "tickets_have_a_question": "Onko sinulla tätä asiaa koskevaa kysyttävää?",
    "tickets_space_title": "Tukipyynnöt",
    "time_future_1h": "1 tunti",
    "time_future_2h": "2 tuntia",
    "time_future_30m": "30 minuutin",
    "time_future_3h": "3 tuntia",
    "time_future_day_0": "Maanantai",
    "time_future_day_1": "Tiistai",
    "time_future_day_2": "Keskiviikko",
    "time_future_day_3": "Torstai",
    "time_future_day_4": "Perjantai",
    "time_future_day_5": "Lauantai",
    "time_future_day_6": "Sunnuntai",
    "time_future_later_today": "Myöhemmin tänään",
    "time_future_next_week": "Ensi viikolla",
    "time_future_tomorrow": "Huomenna",
    "try_use_different_keywords": "Kokeile käyttää muita avainsanoja tai kirjoita kysymyksesi kokonaan",
    "upfront_collection_form_close_out": "Voit palata tänne milloin vain nähdäksesi vastauksia ja lähettääksesi päivityksiä.",
    "upfront_collection_form_introduction": "Määrittele asiayhteys, jotta tiimi voi vastata paremmin ja nopeammin.",
    "upfront_collection_form_submission_confirmation": "Kiitos, kun lähetit tietoa asiayhteydestä. Nyt voit antaa lisätietoja säästääksesi aikaa myöhemmin.",
    "upfront_collection_form_subtitle": "Lähetä tiimille tietoa asiayhteydestä",
    "upfront_collection_form_subtitle_submitted": "He ottavat tämän asiayhteyden huomioon",
    "upfront_collection_form_title": "Saa apua nopeammin",
    "upfront_collection_form_title_submitted": "Lähetetty tiimille!",
    "view_all": "Näytä kaikki",
    "view_conversation": "Näytä keskustelu",
    "we_are_here_to_help": "Autamme mielellämme",
    "we_run_on_intercom": "Toimimme Intercom-käyttöympäristössä",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kun tiimiläinen muuttaa keskustelun tilaa...",
    "working_on_it": "Työn alla...",
    "you_are_all_up_to_date": "Olet ajan tasalla!",
    "your_checklists": "Tarkistuslistat",
    "your_conversations": "Keskustelusi",
    "your_recent_conversations": "Viimeaikaiset keskustelut",
    "your_recent_messages": "Uusimmat viestit"
  },
  "fr": {
    "a_day": "Un jour",
    "a_few_hours": "Quelques heures",
    "a_few_minutes": "Quelques minutes",
    "a_week": "Une semaine",
    "active_state": "Actif",
    "ai_answer": "Réponse IA",
    "ai_answer_information": "Réponse IA, informations",
    "ai_bot": "Bot d’IA",
    "almost_there": "J’y suis presque...",
    "answer": "Réponse",
    "answer_bot_acknowledge_duplicate_content": "Vous avez déjà vu ce contenu, mais c'est la meilleure réponse disponible.",
    "answer_bot_all_done": "C'est ce que je recherche 👍",
    "answer_bot_ask_another_question": "Poser une autre question 🔁",
    "answer_bot_ask_something_else": "Poser une autre question 🔁",
    "answer_bot_connect_to_an_agent": "Contacter un agent 👤",
    "answer_bot_did_that_help": "Cette réponse vous convient-elle ou avez-vous d'autres questions ?",
    "answer_bot_followup_expectations_prefix": "Notre équipe va vous répondre.",
    "answer_bot_get_more_help": "Obtenir de l'aide 🌐",
    "answer_bot_get_more_help_followup": "Ceci pourrait vous aider :",
    "answer_bot_i_have_another_question": "J'ai une autre question 🔁",
    "answer_bot_im_not_done_yet": "Je n'ai pas terminé 🔁",
    "answer_bot_keep_waiting": "Non, j’ai encore besoin d’aide 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Vous pouvez reformuler votre question ou en poser une autre",
    "answer_bot_looping_first_no_answer": "Désolé, en tant que bot, je ne trouve pas de réponse à cette question.",
    "answer_bot_looping_first_no_answer_followup": "Vous pouvez reformuler votre question ou échanger avec notre équipe",
    "answer_bot_looping_no_answer": "Désolé, le bot n’a pas trouvé de réponse à cette question. Voici vos options :",
    "answer_bot_more_answers": "En voir plus 👀",
    "answer_bot_no_answer_found": "Nous n'avons pas trouvé de réponse pour cette question. Voici différentes options.",
    "answer_bot_resolve_followup": "N'hésitez pas à me contacter ici si vous avez encore besoin d'aide.",
    "answer_bot_talk_to_person": "Parler à un humain 👤",
    "answer_bot_that_answered_my_question": "Ça répond à ma question 👍",
    "answer_bot_thats_it": "C'est ce que je voulais savoir 👍",
    "answer_bot_wait_for_the_team": "Contacter l'équipe 💬",
    "answerbot_ask_for_more_context": "Bien sûr. En attendant, pouvez-vous donner plus de détails sur votre situation ou préciser votre question ? Cela nous aidera à répondre plus rapidement et je trouverai peut-être plus d’informations pour vous.",
    "answerbot_introduce_sneaky_answer": "Merci pour ce complément. J’ai trouvé de nouvelles informations qui pourraient vous aider.",
    "article_search_hint_text": "Recherche parmi les articles...",
    "ask_a_question": "Poser une question",
    "ask_a_question_placeholder": "Posez une question...",
    "ask_a_question_with_ellipsis": "Posez une question...",
    "ask_another_question_with_ellipsis": "Posez une autre question...",
    "ask_for_the_team_if_needed": "Sollicitez l’équipe si nécessaire",
    "ask_me_a_question": "Posez-moi une question",
    "ask_our_bot_a_question": "Posez une question à notre bot",
    "avatar": "Avatar",
    "avatars": "Avatars",
    "bot": "Robot",
    "bot_answers_instantly": "Le bot répond instantanément",
    "bot_expectation_text": "Notre bot peut répondre à beaucoup de questions fréquentes",
    "bot_intro_default_part_1": "Bonjour ! Je suis un robot d'assistance. Je suis là pour répondre à vos questions, mais vous pouvez à tout moment demander à ce qu'un membre d'équipe prenne le relais.",
    "bot_intro_default_part_2": "Comment puis-je vous aider aujourd'hui ?",
    "bot_is_still_thinking": "Le bot est en train de réfléchir...",
    "bot_is_thinking": "Le bot réfléchit...",
    "browse_collections": "Consulter toutes les collections",
    "chat_with_us": "Nous contacter par chat",
    "check_later_for_updates": "Revenez plus tard pour vérifier les mises à jour.",
    "checklist_card_first_step": "Première étape",
    "checklist_card_next_step": "Prochaine étape",
    "checklist_completed": "Terminé",
    "checklist_in_progress_time_remaining_plural": "Il reste environ {minutes} minutes",
    "checklist_in_progress_time_remaining_singular": "Il reste environ 1 minute",
    "checklist_mark_step_as_complete": "Marquer comme terminée",
    "checklist_not_started_time_remaining_plural": "Environ {minutes} minutes",
    "checklist_not_started_time_remaining_singular": "Environ 1 minute",
    "checklist_number_of_steps_plural": "{number_of_steps} étapes",
    "checklist_number_of_steps_progress_plural": "{steps_completed} étapes terminées sur {total_steps}",
    "checklist_number_of_steps_progress_singular": "1 étape terminée sur {total_steps}",
    "checklist_number_of_steps_singular": "1 étape",
    "checklist_sender_name": "Partagée par {name}",
    "checklist_step_action_button": "Bouton d’action de l’étape",
    "checklist_step_completed": "Terminé",
    "contact_support": "Contacter l’assistance",
    "contact_us": "Nous contacter",
    "continue_the_conversation": "Poursuivre la conversation",
    "conversation_card_office_hours_header": "Nous serons de retour en ligne",
    "conversation_card_reply_time_header": "Notre délai de réponse habituel",
    "conversation_card_reply_time_header_m5": "Délai de réponse habituel",
    "conversation_card_unknown_response_time": "Nous vous répondrons dès que possible",
    "conversation_part_sample_text": "Bonjour, en quoi puis-je vous aider ? Vous aurez toujours la possibilité de parler à notre équipe si nécessaire.",
    "conversations_space_title": "Conversations",
    "create_a_ticket": "Créer un ticket",
    "currently_no_help_articles": "Il n'y a actuellement aucun article d'aide disponible.",
    "customer_sends_their_first_message": "Le client envoie son premier message...",
    "expected_response_delay_10800": "moins de 3 heures",
    "expected_response_delay_120": "moins de 2 minutes",
    "expected_response_delay_1200": "moins de 20 minutes",
    "expected_response_delay_14400": "moins de 4 heures",
    "expected_response_delay_172800": "2 jours",
    "expected_response_delay_180": "moins de 3 minutes",
    "expected_response_delay_1800": "moins de 30 minutes",
    "expected_response_delay_18000": "moins de 5 heures",
    "expected_response_delay_21600": "moins de 6 heures",
    "expected_response_delay_240": "moins de 4 minutes",
    "expected_response_delay_259200": "3 jours",
    "expected_response_delay_300": "moins de 5 minutes",
    "expected_response_delay_345600": "4 jours",
    "expected_response_delay_3600": "moins de 1 heure",
    "expected_response_delay_43200": "moins de 12 heures",
    "expected_response_delay_432000": "5 jours",
    "expected_response_delay_518400": "6 jours",
    "expected_response_delay_60": "moins d'une minute",
    "expected_response_delay_600": "moins de 10 minutes",
    "expected_response_delay_604800": "moins de 1 semaine",
    "expected_response_delay_7200": "moins de 2 heures",
    "expected_response_delay_86400": "1 jour",
    "expected_response_delay_900": "moins de 15 minutes",
    "expected_response_delay_unknown": "Dès que possible",
    "extra_labels_count": "+ {labels_count} étiquettes",
    "for_best_results_provide_detail": "Pour de meilleurs résultats, fournissez autant de détails que possible",
    "generating_answer": "Création de la réponse...",
    "generic_triage_default": "Nous avons besoin d'un peu plus d'informations pour vous mettre en relation avec la bonne personne",
    "get_an_instant_answer": "Obtenir une réponse immédiate",
    "get_help": "Obtenir de l'aide",
    "go_to": "Accéder à {app_name}",
    "help_space_title": "Aide",
    "home_header_mobile_hello_firstname": "Bonjour, {firstname}.",
    "home_header_mobile_hello_there": "Bonjour",
    "home_space_title": "Accueil",
    "how_can_we_help": "Comment pouvons-nous vous aider ?",
    "how_would_you_rate_this": "Comment évaluez-vous cette actualité ?",
    "learn_more": "En savoir plus",
    "message_placeholder": "Envoyer un message...",
    "messages_space_title": "Conversations",
    "messenger_bot_reply_time": "Notre bot vous répondra instantanément",
    "messenger_settings_introduction": "Comment pouvons-nous vous aider ?",
    "multiple_articles": "{total_articles} articles",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "Nouvelle conversation",
    "news": "Actualités",
    "news_empty_title": "Aucune actualité à afficher",
    "news_latest_subtitle": "De l’équipe {team_name}",
    "news_latest_title": "Les plus récentes",
    "news_older_title": "Antérieures",
    "news_space_title": "Actualités",
    "newsfeed": "Fil d'actualité",
    "no_articles_to_display": "Aucun article pour l'instant",
    "no_help_articles_to_display": "Aucun article d'aide",
    "no_results_for_searchterm": "Aucun résultat pour {searchTerm}",
    "no_tasks": "Aucune tâche",
    "no_tasks_available_currently": "Aucune tâche n'est actuellement disponible.",
    "ok_the_team_typically_replies_in_day": "Ok, l’équipe répond généralement en 24h.",
    "ok_the_team_typically_replies_in_hours": "Ok, l’équipe répond généralement en quelques heures.",
    "ok_the_team_typically_replies_in_minutes": "Ok, l’équipe répond généralement en quelques minutes.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, l’équipe répond généralement en moins de {hours}h.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, l’équipe répond généralement en moins de {minutes} mins.",
    "ok_the_team_typically_replies_in_week": "Ok, l’équipe répond généralement en une semaine.",
    "our_bot_and_team_can_help": "Notre bot et notre équipe peuvent vous aider",
    "our_bot_answers_instantly": "Notre bot répond instantanément",
    "powered_by_intercom": "Propulsé par Intercom",
    "previous_bot_hands_over": "Contenu du bot précédent...",
    "proactive_expected_response_delay_10800": "Répond généralement dans un délai inférieur à 3 h.",
    "proactive_expected_response_delay_120": "Répond généralement dans un délai inférieur à 2 min",
    "proactive_expected_response_delay_1200": "Répond généralement dans un délai inférieur à 20 min.",
    "proactive_expected_response_delay_14400": "Répond généralement dans un délai inférieur à 4 h.",
    "proactive_expected_response_delay_172800": "Répond généralement dans un délai de 2 jours",
    "proactive_expected_response_delay_180": "Répond généralement dans un délai inférieur à 3 min",
    "proactive_expected_response_delay_1800": "Répond généralement dans un délai inférieur à 30 min.",
    "proactive_expected_response_delay_18000": "Répond généralement dans un délai inférieur à 5 h.",
    "proactive_expected_response_delay_21600": "Répond généralement dans un délai inférieur à 6 h.",
    "proactive_expected_response_delay_240": "Répond généralement dans un délai inférieur à 5 min",
    "proactive_expected_response_delay_259200": "Répond généralement dans les 3 jours",
    "proactive_expected_response_delay_300": "Répond généralement dans un délai inférieur à 5 min.",
    "proactive_expected_response_delay_345600": "Répond généralement dans les 4 jours",
    "proactive_expected_response_delay_3600": "Répond généralement dans un délai inférieur à 1 h.",
    "proactive_expected_response_delay_43200": "Répond généralement dans les 12 heures",
    "proactive_expected_response_delay_432000": "Répond généralement dans les 5 jours",
    "proactive_expected_response_delay_518400": "Répond généralement dans les 6 jours",
    "proactive_expected_response_delay_60": "Répond généralement dans un délai inférieur à 1 min",
    "proactive_expected_response_delay_600": "Répond généralement dans un délai inférieur à 10 min.",
    "proactive_expected_response_delay_604800": "Répond généralement dans un délai inférieur à 1 semaine",
    "proactive_expected_response_delay_7200": "Répond généralement dans un délai inférieur à 2 h.",
    "proactive_expected_response_delay_86400": "Répond généralement dans un délai d’1 jour",
    "proactive_expected_response_delay_900": "Répond généralement en moins de 15 minutes",
    "proactive_expected_response_delay_unknown": "Répondra dès que possible",
    "profile_location": "{time} à {location}",
    "recent_ticket": "Ticket récent",
    "recent_tickets": "Tickets récents",
    "search_browse_empty_state_text": "Désolé, cette recherche ne donne rien. Veuillez reformuler votre requête.",
    "search_for_help": "Trouver une réponse",
    "see_all": "Tout voir",
    "see_all_conversations": "Voir toutes vos conversations",
    "see_previous_conversations": "Voir vos conversations précédentes",
    "send": "Envoyer",
    "send_a_message": "Envoyer un message…",
    "send_a_message_reply_time": "Le délai de réponse habituel est de",
    "send_us_a_message": "Envoyez-nous un message",
    "set_expectations_bot_email_header": "Vous recevrez une réponse ici et par e-mail :",
    "set_expectations_bot_office_hours_header": "L'équipe sera de retour :",
    "set_expectations_bot_sms_header": "Vous recevrez une réponse ici et par SMS :",
    "set_expectations_replies_to_email": "Vous recevrez les réponses ici et à l’adresse <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Vous recevrez les réponses ici et à l'adresse {user_email}",
    "set_expectations_team_reply_asap": "Notre équipe vous répondra dans les meilleurs délais.",
    "shared_by": "Partagé par",
    "show_less": "Afficher moins",
    "single_article": "1 article",
    "single_collection": "1 collection",
    "source": "Source",
    "sources": "Sources",
    "start_a_conversation": "Démarrer une conversation",
    "start_conversation": "Démarrer une conversation",
    "still_generating_answer": "Réponse en cours de création...",
    "still_thinking": "Je suis en train de réfléchir",
    "still_working_on_it": "J’y travaille encore...",
    "tasks_space_title": "Tâches",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} répondra dès que possible.",
    "teammate_changes_team_assignment_of_a_conversation": "Le collègue a modifié l’attribution de la conversation…",
    "tell_us_what_you_need": "Dites-nous ce qui vous amène : notre bot mettra tout en œuvre pour vous aider",
    "the_team_can_help_if_needed": "Notre équipe peut vous aider au besoin",
    "the_team_typically_replies_in_10800": "L’équipe répond généralement en moins de trois heures.",
    "the_team_typically_replies_in_120": "L'équipe répond généralement en moins de 2 min",
    "the_team_typically_replies_in_1200": "L’équipe répond généralement en moins de 20 minutes.",
    "the_team_typically_replies_in_14400": "L’équipe répond généralement en moins de quatre heures.",
    "the_team_typically_replies_in_172800": "L’équipe répond généralement en moins de deux jours.",
    "the_team_typically_replies_in_180": "L'équipe répond généralement en moins de 3 min",
    "the_team_typically_replies_in_1800": "L’équipe répond généralement en moins de 30 minutes.",
    "the_team_typically_replies_in_18000": "L’équipe répond généralement en moins de cinq heures.",
    "the_team_typically_replies_in_21600": "L’équipe répond généralement en moins de six heures.",
    "the_team_typically_replies_in_240": "L'équipe répond généralement en moins de 4 min",
    "the_team_typically_replies_in_259200": "L'équipe répond généralement dans les 3 jours",
    "the_team_typically_replies_in_300": "L’équipe répond généralement en moins de 5 minutes.",
    "the_team_typically_replies_in_345600": "L'équipe répond généralement dans les 4 jours",
    "the_team_typically_replies_in_3600": "L’équipe répond généralement en moins d'une heure.",
    "the_team_typically_replies_in_43200": "L'équipe répond généralement en moins de 12 heures",
    "the_team_typically_replies_in_432000": "L'équipe répond généralement dans les 5 jours",
    "the_team_typically_replies_in_518400": "L'équipe répond généralement dans les 6 jours",
    "the_team_typically_replies_in_60": "L’équipe répond généralement en moins de 1 min",
    "the_team_typically_replies_in_600": "L’équipe répond généralement en moins de 10 minutes.",
    "the_team_typically_replies_in_604800": "L’équipe répond généralement en moins d'une semaine.",
    "the_team_typically_replies_in_7200": "L’équipe répond généralement en moins de deux heures.",
    "the_team_typically_replies_in_86400": "L’équipe répond généralement en moins de 24 heures.",
    "the_team_typically_replies_in_900": "L'équipe répond généralement en moins de 15 min",
    "the_team_typically_replies_in_day": "L’équipe répond généralement dans un délai d’un jour.",
    "the_team_typically_replies_in_hours": "L’équipe répond généralement dans un délai de quelques heures.",
    "the_team_typically_replies_in_minutes": "L’équipe répond généralement dans un délai de quelques minutes.",
    "the_team_typically_replies_in_under_n_hours": "L’équipe répond généralement en moins de {hours}h.",
    "the_team_typically_replies_in_under_n_min": "L’équipe répond généralement en moins de {minutes}m.",
    "the_team_typically_replies_in_unknown": "L'équipe répondra dès que possible.",
    "the_team_typically_replies_in_week": "L’équipe répond généralement en une semaine.",
    "thinking": "Je réfléchis...",
    "tickets_cta_text": "Vous avez une question à propos de ce ticket ?",
    "tickets_have_a_question": "Vous avez une question à ce sujet ?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "dans 1 heure",
    "time_future_2h": "dans 2 heures",
    "time_future_30m": "dans 30 minutes",
    "time_future_3h": "dans 3 heures",
    "time_future_day_0": "Lundi",
    "time_future_day_1": "Mardi",
    "time_future_day_2": "Mercredi",
    "time_future_day_3": "Jeudi",
    "time_future_day_4": "Vendredi",
    "time_future_day_5": "Samedi",
    "time_future_day_6": "Dimanche",
    "time_future_later_today": "Dans la journée",
    "time_future_next_week": "La semaine prochaine",
    "time_future_tomorrow": "Demain",
    "try_use_different_keywords": "Veuillez utiliser d'autres mots-clés ou saisir votre question complète",
    "upfront_collection_form_close_out": "Revenez ici à tout moment pour consulter les réponses et faire part des progrès.",
    "upfront_collection_form_introduction": "Donnez du contexte à l'équipe pour une réponse plus juste et plus rapide.",
    "upfront_collection_form_submission_confirmation": "Merci pour ce complément. Pour gagner du temps, n'hésitez pas à ajouter un maximum de détails.",
    "upfront_collection_form_subtitle": "Envoyez du contexte à l'équipe",
    "upfront_collection_form_subtitle_submitted": "Ils en tiendront compte",
    "upfront_collection_form_title": "Recevez de l'aide plus rapidement",
    "upfront_collection_form_title_submitted": "Bien reçu par l'équipe !",
    "view_all": "Voir tout",
    "view_conversation": "Afficher la conversation",
    "we_are_here_to_help": "Nous sommes là pour vous aider",
    "we_run_on_intercom": "Avec Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Lorsqu'un collègue modifie l'état d'une conversation.",
    "working_on_it": "J’y travaille...",
    "you_are_all_up_to_date": "Vous êtes à jour !",
    "your_checklists": "Votre check-list",
    "your_conversations": "Vos conversations",
    "your_recent_conversations": "Conversations récentes",
    "your_recent_messages": "Messages récents"
  },
  "he": {
    "a_day": "יום אחד",
    "a_few_hours": "מספר שעות",
    "a_few_minutes": "מספר דקות",
    "a_week": "שבוע",
    "active_state": "פעיל",
    "ai_answer": "תשובת בינה מלאכותית",
    "ai_answer_information": "תשובת בינה מלאכותית, מידע",
    "ai_bot": "בוט בינה מלאכותית",
    "almost_there": "כמעט סיימנו...",
    "answer": "תשובה",
    "answer_bot_acknowledge_duplicate_content": "כבר ראית את זה, אבל זו התשובה הטובה ביותר שיש.",
    "answer_bot_all_done": "זה עזר לי 👍",
    "answer_bot_ask_another_question": "רוצה לשאול שאלה אחרת 🔁",
    "answer_bot_ask_something_else": "לשאול משהו אחר 🔁",
    "answer_bot_connect_to_an_agent": "לשוחח עם נציג 👤",
    "answer_bot_did_that_help": "האם התשובה עזרה לך או שדרוש לך משהו אחר?",
    "answer_bot_followup_expectations_prefix": "מישהו מהצוות ייצור איתך קשר מחר לגבי השאלה שלך.",
    "answer_bot_get_more_help": "לקבלת עזרה נוספת 🌐",
    "answer_bot_get_more_help_followup": "ייתכן שהקישור הזה יסייע:",
    "answer_bot_i_have_another_question": "יש לי עוד שאלה 🔁",
    "answer_bot_im_not_done_yet": "עוד לא סיימתי 🔁",
    "answer_bot_keep_waiting": "לא, אני עדיין זקוק/ה לעזרה 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "אפשר לשנות את ניסוח השאלה או לקבל עזרה נוספת",
    "answer_bot_looping_first_no_answer": "צר לי, אני בוט ולא מצאתי תשובה לשאלה שלך.",
    "answer_bot_looping_first_no_answer_followup": "אפשר לשנות את ניסוח השאלה או לשוחח עם הצוות שלנו",
    "answer_bot_looping_no_answer": "צר לי, אני בוט ולא מצאתי תשובה לשאלה שלך. אפשרויות זמינות נוספות",
    "answer_bot_more_answers": "הראה לי עוד 👀",
    "answer_bot_no_answer_found": "מצטערים, אין לנו תשובה מהירה לשאלה שלך, אבל יש כמה אפשרויות אחרות.",
    "answer_bot_resolve_followup": "אתה תמיד מוזמן לכתוב כאן אם יש לך שאלות נוספות.",
    "answer_bot_talk_to_person": "לשוחח עם נציג אנושי 👤",
    "answer_bot_that_answered_my_question": "זה ענה לי על השאלה 👍",
    "answer_bot_thats_it": "זהו 👍",
    "answer_bot_wait_for_the_team": "המתן לנציג מהצוות 💬",
    "answerbot_ask_for_more_context": "בטח. בזמן שאנחנו מחכים, האם תוכל/י לשתף פרטים נוספים על הסיטואציה או על השאלה שלך? זה יעזור לנו למצוא תשובה מהר יותר, ואולי אמצא מידע נוסף עבורך.",
    "answerbot_introduce_sneaky_answer": "תודה על ההקשר הנוסף הזה. מצאתי מידע חדש שעשוי לעזור לך.",
    "article_search_hint_text": "חיפוש מאמרים...",
    "ask_a_question": "שאל שאלה",
    "ask_a_question_placeholder": "שאל שאלה..",
    "ask_a_question_with_ellipsis": "שאל/י שאלה...",
    "ask_another_question_with_ellipsis": "שאל/י עוד שאלה...",
    "ask_for_the_team_if_needed": "במידת הצורך, אפשר לבקש את הצוות",
    "ask_me_a_question": "שאל/י אותי שאלה",
    "ask_our_bot_a_question": "שאל/י את הבוט שלנו שאלה",
    "avatar": "אווטאר",
    "avatars": "אווטארים",
    "bot": "בוט",
    "bot_answers_instantly": "הבוט עונה מיד",
    "bot_expectation_text": "ה-Bot שלנו יכול לענות על שאלות נפוצות רבות",
    "bot_intro_default_part_1": "היי! כאן Bot, לשירותך. אנסה להשיב לך על שאלות, אך תמיד תהיה לך אפשרות לשוחח עם איש צוות.",
    "bot_intro_default_part_2": "איך אוכל לעזור לך?",
    "bot_is_still_thinking": "הבוט עדיין חושב...",
    "bot_is_thinking": "הבוט חושב...",
    "browse_collections": "דפדוף באוספים",
    "chat_with_us": "אפשר לדבר איתנו בצ'אט",
    "check_later_for_updates": "אפשר לבדוק שוב מאוחר יותר אם יש עדכונים.",
    "checklist_card_first_step": "השלב הראשון",
    "checklist_card_next_step": "השלב הבא",
    "checklist_completed": "רשימת המשימות הושלמה",
    "checklist_in_progress_time_remaining_plural": "נותרו כ-{minutes} דקות",
    "checklist_in_progress_time_remaining_singular": "נותרה כדקה",
    "checklist_mark_step_as_complete": "סמן את השלב כשלב שהושלם",
    "checklist_not_started_time_remaining_plural": "כ-{minutes} דקות",
    "checklist_not_started_time_remaining_singular": "כדקה",
    "checklist_number_of_steps_plural": "{number_of_steps} שלבים",
    "checklist_number_of_steps_progress_plural": "{steps_completed} מתוך {total_steps} בוצעו",
    "checklist_number_of_steps_progress_singular": "1 מתוך {total_steps} בוצע",
    "checklist_number_of_steps_singular": "שלב אחד",
    "checklist_sender_name": "הרשימה שותפה על ידי {name}",
    "checklist_step_action_button": "כפתור פעולה לביצוע שלב זה",
    "checklist_step_completed": "השלב הושלם",
    "contact_support": "יצירת קשר עם התמיכה",
    "contact_us": "יצירת קשר",
    "continue_the_conversation": "המשיכו בשיחה",
    "conversation_card_office_hours_header": "אחד מאנשי הצוות שלנו יהיה זמין אונליין",
    "conversation_card_reply_time_header": "זמן התגובה הרגיל שלנו",
    "conversation_card_reply_time_header_m5": "זמן התגובה הרגיל",
    "conversation_card_unknown_response_time": "נשיב בהקדם האפשרי",
    "conversation_part_sample_text": "היי, איך אוכל לעזור? אם יהיה צורך, תמיד תהיה לך אפשרות לדבר עם הצוות שלנו.",
    "conversations_space_title": "שיחות",
    "create_a_ticket": "יצירת פנייה",
    "currently_no_help_articles": "אין בשלב זה מאמרי עזרה זמינים.",
    "customer_sends_their_first_message": "הלקוח/ה שולח/ת את ההודעה הראשונה שלו/ה...",
    "expected_response_delay_10800": "תוך פחות מ-3 שעות",
    "expected_response_delay_120": "תוך פחות מ-2 דקות",
    "expected_response_delay_1200": "תוך פחות מ-20 דקות",
    "expected_response_delay_14400": "תוך פחות מ-4 שעות",
    "expected_response_delay_172800": "יומיים",
    "expected_response_delay_180": "תוך פחות מ-3 דקות",
    "expected_response_delay_1800": "פחות מחצי שעה",
    "expected_response_delay_18000": "פחות מ-5 שעות",
    "expected_response_delay_21600": "פחות מ-6 שעות",
    "expected_response_delay_240": "תוך פחות מ-4 דקות",
    "expected_response_delay_259200": "3 ימים",
    "expected_response_delay_300": "פחות מ-5 דקות",
    "expected_response_delay_345600": "4 ימים",
    "expected_response_delay_3600": "פחות משעה",
    "expected_response_delay_43200": "פחות מ-12 שעות",
    "expected_response_delay_432000": "5 ימים",
    "expected_response_delay_518400": "6 ימים",
    "expected_response_delay_60": "תוך פחות מדקה",
    "expected_response_delay_600": "פחות מ-10 דקות",
    "expected_response_delay_604800": "פחות משבוע",
    "expected_response_delay_7200": "פחות משעתיים",
    "expected_response_delay_86400": "יום אחד",
    "expected_response_delay_900": "פחות מ-15 דקות",
    "expected_response_delay_unknown": "בהקדם האפשרי",
    "extra_labels_count": "+ {labels_count} תוויות",
    "for_best_results_provide_detail": "לקבלת התוצאות הטובות ביותר, יש לספק כמה שיותר פרטים",
    "generating_answer": "מייצר תשובה...",
    "generic_triage_default": "אנחנו צריכים רק עוד כמה פרטים כדי לקשר אותך אל האדם הנכון",
    "get_an_instant_answer": "קבלת תשובה מיידית",
    "get_help": "קבלת עזרה",
    "go_to": "עבור/י אל {app_name}",
    "help_space_title": "עזרה",
    "home_header_mobile_hello_firstname": "שלום, {firstname}.",
    "home_header_mobile_hello_there": "שלום לך",
    "home_space_title": "בית",
    "how_can_we_help": "איך אפשר לעזור?",
    "how_would_you_rate_this": "איזה דירוג היית נותן/ת לזה?",
    "learn_more": "למידע נוסף",
    "message_placeholder": "כאן המקום להקליד",
    "messages_space_title": "הודעות",
    "messenger_bot_reply_time": "הבוט שלנו יענה באופן מיידי",
    "messenger_settings_introduction": "איך אפשר לעזור?",
    "multiple_articles": "{total_articles} מאמרים",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "שיחה חדשה",
    "news": "חדשות",
    "news_empty_title": "עדיין אין חדשות",
    "news_latest_subtitle": "מהצוות {team_name}",
    "news_latest_title": "העדכניים ביותר",
    "news_older_title": "ישנים יותר",
    "news_space_title": "חדשות",
    "newsfeed": "עדכון חדשות",
    "no_articles_to_display": "עדיין אין מאמרים",
    "no_help_articles_to_display": "אין מאמרי עזרה",
    "no_results_for_searchterm": "אין תוצאות עבור {searchTerm}",
    "no_tasks": "לא קיימות משימות",
    "no_tasks_available_currently": "כרגע לא קיימות משימות זמינות.",
    "ok_the_team_typically_replies_in_day": "אוקיי, הצוות משיב בדרך כלל תוך יום.",
    "ok_the_team_typically_replies_in_hours": "אוקיי, הצוות משיב בדרך כלל תוך מספר שעות.",
    "ok_the_team_typically_replies_in_minutes": "אוקיי, הצוות משיב בדרך כלל תוך מספר דקות.",
    "ok_the_team_typically_replies_in_under_n_hours": "אוקיי, הצוות משיב בדרך כלל תוך פחות מ-{hours} שעות.",
    "ok_the_team_typically_replies_in_under_n_min": "אוקיי, הצוות משיב בדרך כלל תוך פחות מ-{minutes} דקות.",
    "ok_the_team_typically_replies_in_week": "אוקיי, הצוות משיב בדרך כלל תוך שבוע.",
    "our_bot_and_team_can_help": "הבוט והצוות שלנו יכולים לעזור",
    "our_bot_answers_instantly": "הבוט שלנו עונה מיד",
    "powered_by_intercom": "מופעל על-ידי Intercom",
    "previous_bot_hands_over": "תוכן מהבוט הקודם...",
    "proactive_expected_response_delay_10800": "לרוב משיב תוך פחות מ-3 שעות",
    "proactive_expected_response_delay_120": "לרוב משיב תוך פחות מ-2 דקות",
    "proactive_expected_response_delay_1200": "לרוב משיב תוך פחות מ-20 דקות",
    "proactive_expected_response_delay_14400": "לרוב משיב תוך פחות מ-4 שעות",
    "proactive_expected_response_delay_172800": "לרוב משיב תוך יומיים",
    "proactive_expected_response_delay_180": "לרוב משיב תוך פחות מ-3 דקות",
    "proactive_expected_response_delay_1800": "לרוב משיב תוך פחות מ-30 דקות",
    "proactive_expected_response_delay_18000": "לרוב משיב תוך פחות מ-5 שעות",
    "proactive_expected_response_delay_21600": "לרוב משיב תוך פחות מ-6 שעות",
    "proactive_expected_response_delay_240": "לרוב משיב תוך פחות מ-4 דקות",
    "proactive_expected_response_delay_259200": "לרוב עונה תוך 3 ימים",
    "proactive_expected_response_delay_300": "לרוב משיב תוך פחות מ-5 דקות",
    "proactive_expected_response_delay_345600": "לרוב עונה תוך 4 ימים",
    "proactive_expected_response_delay_3600": "לרוב משיב תוך פחות משעה",
    "proactive_expected_response_delay_43200": "לרוב עונה תוך 12 שעות",
    "proactive_expected_response_delay_432000": "לרוב עונה תוך 5 ימים",
    "proactive_expected_response_delay_518400": "לרוב עונה תוך 6 ימים",
    "proactive_expected_response_delay_60": "לרוב משיב תוך פחות מדקה",
    "proactive_expected_response_delay_600": "לרוב משיב תוך פחות מ-10 דקות",
    "proactive_expected_response_delay_604800": "לרוב משיב תוך פחות משבוע",
    "proactive_expected_response_delay_7200": "לרוב משיב תוך פחות משעתיים",
    "proactive_expected_response_delay_86400": "לרוב משיב תוך יום",
    "proactive_expected_response_delay_900": "לרוב עונה תוך פחות מ-15 דקות",
    "proactive_expected_response_delay_unknown": "ישיבו בהקדם האפשרי",
    "profile_location": "{time} ב- {location}",
    "recent_ticket": "פנייה אחרונה",
    "recent_tickets": "פניות אחרונות",
    "search_browse_empty_state_text": "מצטערים, לא מצאנו תוצאות לחיפוש שלך. אפשר לנסות לנסח מחדש את טקסט החיפוש.",
    "search_for_help": "חיפוש עזרה",
    "see_all": "ראה הכל",
    "see_all_conversations": "ראה את כל השיחות שלך",
    "see_previous_conversations": "ראה את השיחות הקודמות שלך",
    "send": "שלח",
    "send_a_message": "…שלח הודעה",
    "send_a_message_reply_time": "זמן התגובה הרגיל הוא",
    "send_us_a_message": "כתבו לנו",
    "set_expectations_bot_email_header": "תקבל תשובות כאן ובדוא\"ל:",
    "set_expectations_bot_office_hours_header": "המועד שבו הצוות יחזור לפעילות:",
    "set_expectations_bot_sms_header": "תקבל תשובות כאן וב-SMS:",
    "set_expectations_replies_to_email": "תקבל תשובות כאן ובכתובת הדוא&quot;ל <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "תקבל תשובות כאן ובכתובת הדוא\"ל {user_email}",
    "set_expectations_team_reply_asap": "הצוות ישיב בהקדם האפשרי.",
    "shared_by": "שותף על ידי",
    "show_less": "הצג פחות",
    "single_article": "מאמר אחד",
    "single_collection": "1 collection",
    "source": "מקור",
    "sources": "מקורות",
    "start_a_conversation": "התחילו שיחה",
    "start_conversation": "התחילו שיחה",
    "still_generating_answer": "עדיין מייצר תשובה...",
    "still_thinking": "עדיין חושב...",
    "still_working_on_it": "עדיין עובד על זה...",
    "tasks_space_title": "משימות",
    "team_name_proactive_expected_response_delay_unknown": ".{app_name} ישיבו בהקדם האפשרי",
    "teammate_changes_team_assignment_of_a_conversation": "חבר צוות שינה את הקצאת השיחה...",
    "tell_us_what_you_need": "ספר לנו במה דרושה לך עזרה והבוט שלנו יעשה כמיטב יכולתו לענות",
    "the_team_can_help_if_needed": "חברי הצוות יכולים לעזור במידת הצורך",
    "the_team_typically_replies_in_10800": "הצוות משיב בדרך כלל תוך פחות מ-3 שעות",
    "the_team_typically_replies_in_120": "הצוות משיב בדרך כלל תוך פחות מ-2 דקות",
    "the_team_typically_replies_in_1200": "הצוות משיב בדרך כלל תוך פחות מ-20 דקות",
    "the_team_typically_replies_in_14400": "הצוות משיב בדרך כלל תוך פחות מ-4 שעות",
    "the_team_typically_replies_in_172800": "הצוות משיב בדרך כלל תוך יומיים",
    "the_team_typically_replies_in_180": "הצוות משיב בדרך כלל תוך פחות מ-3 דקות",
    "the_team_typically_replies_in_1800": "הצוות משיב בדרך כלל תוך פחות מ-30 דקות",
    "the_team_typically_replies_in_18000": "הצוות משיב בדרך כלל תוך פחות מ-5 שעות",
    "the_team_typically_replies_in_21600": "הצוות משיב בדרך כלל תוך פחות מ-6 שעות",
    "the_team_typically_replies_in_240": "הצוות משיב בדרך כלל תוך פחות מ-4 דקות",
    "the_team_typically_replies_in_259200": "הצוות עונה לרוב תוך 3 ימים",
    "the_team_typically_replies_in_300": "הצוות משיב בדרך כלל תוך פחות מ-5 דקות",
    "the_team_typically_replies_in_345600": "הצוות עונה לרוב תוך 4 ימים",
    "the_team_typically_replies_in_3600": "הצוות משיב בדרך כלל תוך פחות משעה",
    "the_team_typically_replies_in_43200": "הצוות עונה לרוב תוך פחות מ-12 שעות",
    "the_team_typically_replies_in_432000": "הצוות עונה לרוב תוך 5 ימים",
    "the_team_typically_replies_in_518400": "הצוות עונה לרוב תוך 6 ימים",
    "the_team_typically_replies_in_60": "הצוות משיב בדרך כלל תוך פחות מדקה",
    "the_team_typically_replies_in_600": "הצוות משיב בדרך כלל תוך פחות מ-10 דקות",
    "the_team_typically_replies_in_604800": "הצוות משיב בדרך כלל תוך פחות משבוע",
    "the_team_typically_replies_in_7200": "הצוות משיב בדרך כלל תוך פחות משעתיים",
    "the_team_typically_replies_in_86400": "הצוות משיב בדרך כלל תוך יום",
    "the_team_typically_replies_in_900": "הצוות משיב בדרך כלל תוך פחות מ-15 דקות",
    "the_team_typically_replies_in_day": "הצוות משיב בדרך כלל תוך יום.",
    "the_team_typically_replies_in_hours": "הצוות משיב בדרך כלל תוך מספר שעות.",
    "the_team_typically_replies_in_minutes": "הצוות משיב בדרך כלל תוך מספר דקות.",
    "the_team_typically_replies_in_under_n_hours": "הצוות משיב בדרך כלל תוך פחות מ-{hours} שעות.",
    "the_team_typically_replies_in_under_n_min": "הצוות משיב בדרך כלל תוך פחות מ-{minutes} דקות.",
    "the_team_typically_replies_in_unknown": "הצוות ישיב בהקדם האפשרי",
    "the_team_typically_replies_in_week": "הצוות משיב בדרך כלל תוך שבוע.",
    "thinking": "חושב...",
    "tickets_cta_text": "יש לך שאלה לגבי פנייה זו?",
    "tickets_have_a_question": "יש לך שאלה לגבי נושא זה?",
    "tickets_space_title": "פניות",
    "time_future_1h": "תוך שעה",
    "time_future_2h": "תוך שעתיים",
    "time_future_30m": "תוך חצי שעה",
    "time_future_3h": "בעוד 3 שעות",
    "time_future_day_0": "ביום שני",
    "time_future_day_1": "ביום שלישי",
    "time_future_day_2": "ביום רביעי",
    "time_future_day_3": "ביום חמישי",
    "time_future_day_4": "ביום שישי",
    "time_future_day_5": "ביום שבת",
    "time_future_day_6": "ביום ראשון",
    "time_future_later_today": "בהמשך היום",
    "time_future_next_week": "בשבוע הבא",
    "time_future_tomorrow": "מחר",
    "try_use_different_keywords": "נסה להשתמש במילות מפתח שונות או להקליד את השאלה במלואה",
    "upfront_collection_form_close_out": "תוכל לחזור לכאן בכל שלב כדי לראות תגובות ולשלוח עדכונים.",
    "upfront_collection_form_introduction": "שתף את ההקשר כדי לעזור לצוות להגיב טוב יותר ומהר יותר.",
    "upfront_collection_form_submission_confirmation": "תודה ששלחת את ההקשר. כדי לחסוך זמן מאוחר יותר, אל תהסס להוסיף עוד פרטים עכשיו.",
    "upfront_collection_form_subtitle": "שלח לצוות את ההקשר",
    "upfront_collection_form_subtitle_submitted": "כך חברי הצוות יוכלו לקחת את ההקשר בחשבון",
    "upfront_collection_form_title": "קבל עזרה מהר יותר",
    "upfront_collection_form_title_submitted": "נשלח לצוות!",
    "view_all": "הצג הכל",
    "view_conversation": "הצג שיחה",
    "we_are_here_to_help": "אנחנו כאן כדי לעזור",
    "we_run_on_intercom": "מופעל באמצעות Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "כשחבר/ת צוות משנה סטטוס של שיחה...",
    "working_on_it": "עובד על זה...",
    "you_are_all_up_to_date": "זהו, סיימת להתעדכן!",
    "your_checklists": "הרשימות שלך לביצוע",
    "your_conversations": "השיחות שלך",
    "your_recent_conversations": "שיחות אחרונות",
    "your_recent_messages": "הודעות שהתקבלו לאחרונה"
  },
  "hi": {
    "a_day": "एक दिन",
    "a_few_hours": "कुछ घंटे",
    "a_few_minutes": "कुछ मिनट",
    "a_week": "एक हफ़्ता",
    "active_state": "एक्टिव",
    "ai_answer": "AI का जवाब",
    "ai_answer_information": "AI से मिले जवाब की जानकारी",
    "ai_bot": "AI बॉट",
    "almost_there": "लगभग होने वाला है...",
    "answer": "जवाब",
    "answer_bot_acknowledge_duplicate_content": "आपने इसे पहले ही देख लिया है, लेकिन यह सबसे अच्छा जवाब है.",
    "answer_bot_all_done": "इससे मदद मिली 👍",
    "answer_bot_ask_another_question": "कोई और सवाल पूछें 🔁",
    "answer_bot_ask_something_else": "कुछ और पूछें 🔁",
    "answer_bot_connect_to_an_agent": "किसी एजेंट से कनेक्ट करें 👤",
    "answer_bot_did_that_help": "क्या उस जवाब से मदद मिली या आपको कुछ और चाहिए?",
    "answer_bot_followup_expectations_prefix": "इस मामले पर टीम आपसे संपर्क करेगी.",
    "answer_bot_get_more_help": "और मदद पाएं 🌐",
    "answer_bot_get_more_help_followup": "इस लिंक से मदद मिल सकती है:",
    "answer_bot_i_have_another_question": "मेरा एक और सवाल है 🔁",
    "answer_bot_im_not_done_yet": "अभी तक मेरा काम पूरा नहीं हुआ है 🔁",
    "answer_bot_keep_waiting": "नहीं, मुझे अभी भी मदद की ज़रूरत है 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "आप अपने सवाल को किसी दूसरी तरह पूछ सकते हैं या अतिरिक्त सहायता ले सकते हैं",
    "answer_bot_looping_first_no_answer": "माफ़ करें, बॉट के तौर पर मुझे इसके लिए कोई जवाब नहीं मिला.",
    "answer_bot_looping_first_no_answer_followup": "आप अपने सवाल को किसी दूसरी तरह पूछ सकते हैं या हमारी टीम से बात कर सकते हैं",
    "answer_bot_looping_no_answer": "माफ़ करें, बॉट के तौर पर मुझे इसके लिए कोई जवाब नहीं मिला. आप ऐसा कर सकते हैं",
    "answer_bot_more_answers": "मुझे और दिखाएं 👀",
    "answer_bot_no_answer_found": "माफ़ करें, हमारे पास इसके लिए कोई पहले से दिया गया जवाब नहीं है. यहां कुछ और ऑप्शन दिए गए हैं.",
    "answer_bot_resolve_followup": "अगर कुछ और होता है, तो आप हमेशा यहां जवाब दे सकते हैं.",
    "answer_bot_talk_to_person": "किसी व्यक्ति से बात करें 👤",
    "answer_bot_that_answered_my_question": "इससे मेरे सवाल का जवाब मिल गया 👍",
    "answer_bot_thats_it": "बस इतना ही 👍",
    "answer_bot_wait_for_the_team": "टीम का इंतज़ार करें 💬",
    "answerbot_ask_for_more_context": "पक्का. जब तक हम इंतज़ार कर रहे हैं, क्या आप अपनी स्थिति के बारे में और जानकारी शेयर कर सकते हैं या आपका कोई सवाल है? इससे हम जल्दी से कोई जवाब खोज पाएंगे और शायद मैं आपके लिए ज़्यादा जानकारी ढूंढ सकूं.",
    "answerbot_introduce_sneaky_answer": "वह अतिरिक्त जानकारी देने के लिए धन्यवाद। मुझे कुछ नई जानकारी मिली, जो आपकी मदद कर सकती है।",
    "article_search_hint_text": "आर्टिकल्स खोजें...",
    "ask_a_question": "कोई सवाल पूछें",
    "ask_a_question_placeholder": "कोई सवाल पूछें…",
    "ask_a_question_with_ellipsis": "कोई सवाल पूछें...",
    "ask_another_question_with_ellipsis": "दूसरा सवाल पूछें...",
    "ask_for_the_team_if_needed": "अगर ज़रूरत पड़े तो टीम से बात करवाने को कहें",
    "ask_me_a_question": "मुझसे कोई सवाल पूछें",
    "ask_our_bot_a_question": "हमारे बॉट से कोई सवाल पूछें",
    "avatar": "अवतार",
    "avatars": "अवतार",
    "bot": "बॉट",
    "bot_answers_instantly": "बॉट तुरंत जवाब देता है",
    "bot_expectation_text": "हमारा बॉट कई सामान्य सवालों का जवाब दे सकता है",
    "bot_intro_default_part_1": "नमस्ते! मैं एक बॉट हूं. मैं यहां आपके सवालों का जवाब देने के लिए हूं, लेकिन आपके पास हमेशा हमारी टीम से बात करने का ऑप्शन होगा.",
    "bot_intro_default_part_2": "तो आज आपका क्या सवाल है?",
    "bot_is_still_thinking": "बॉट अभी भी सोच रहा है...",
    "bot_is_thinking": "बॉट सोच रहा है...",
    "browse_collections": "कलेक्शंस ब्राउज़ करें",
    "chat_with_us": "हमारे साथ चैट करें",
    "check_later_for_updates": "अपडेट के लिए बाद में देखें.",
    "checklist_card_first_step": "पहला स्टेप",
    "checklist_card_next_step": "अगला स्टेप",
    "checklist_completed": "पूरी हुई",
    "checklist_in_progress_time_remaining_plural": "लगभग {minutes} मिनट बचे हैं",
    "checklist_in_progress_time_remaining_singular": "लगभग 1 मिनट बचा है",
    "checklist_mark_step_as_complete": "'पूरा हो गया' के तौर पर मार्क करें",
    "checklist_not_started_time_remaining_plural": "लगभग {minutes} मिनट",
    "checklist_not_started_time_remaining_singular": "लगभग 1 मिनट",
    "checklist_number_of_steps_plural": "{number_of_steps} स्टेप",
    "checklist_number_of_steps_progress_plural": "{total_steps} में से {steps_completed} पूरे हो गए",
    "checklist_number_of_steps_progress_singular": "{total_steps} में से 1 पूरा हो गया",
    "checklist_number_of_steps_singular": "1 स्टेप",
    "checklist_sender_name": "{name} द्वारा शेयर किया गया",
    "checklist_step_action_button": "स्टेप ऐक्शन बटन",
    "checklist_step_completed": "पूरी हुई",
    "contact_support": "सपोर्ट टीम से संपर्क करें",
    "contact_us": "हमसे संपर्क करें",
    "continue_the_conversation": "बातचीत जारी रखें",
    "conversation_card_office_hours_header": "हम वापस ऑनलाइन आएंगे",
    "conversation_card_reply_time_header": "आम तौर पर, हम इतने समय में जवाब देते हैं",
    "conversation_card_reply_time_header_m5": "आम तौर पर, जवाब देने में लगने वाला समय",
    "conversation_card_unknown_response_time": "हम जल्द से जल्द जवाब देने की कोशिश करेंगे",
    "conversation_part_sample_text": "नमस्ते, मैं आपकी कैसे मदद कर सकता हूँ? अगर आपको ज़रूरी लगे, तो आपके पास हमेशा हमारी टीम से बात करने का विकल्प होगा.",
    "conversations_space_title": "बातचीत",
    "create_a_ticket": "टिकट बनाएं",
    "currently_no_help_articles": "फ़िलहाल कोई मदद से जुड़े आर्टिकल्स उपलब्ध नहीं हैं.",
    "customer_sends_their_first_message": "कस्टमर अपना पहला मेसेज भेजते हैं...",
    "expected_response_delay_10800": "3 घंटे के अंदर",
    "expected_response_delay_120": "2 मिनट के अंदर",
    "expected_response_delay_1200": "20 मिनट के अंदर",
    "expected_response_delay_14400": "4 घंटे के अंदर",
    "expected_response_delay_172800": "2 दिन",
    "expected_response_delay_180": "3 मिनट के अंदर",
    "expected_response_delay_1800": "30 मिनट के अंदर",
    "expected_response_delay_18000": "5 घंटे के अंदर",
    "expected_response_delay_21600": "6 घंटे के अंदर",
    "expected_response_delay_240": "4 मिनट के अंदर",
    "expected_response_delay_259200": "3 दिन",
    "expected_response_delay_300": "5 मिनट के अंदर",
    "expected_response_delay_345600": "4 दिन",
    "expected_response_delay_3600": "1 घंटे के अंदर",
    "expected_response_delay_43200": "12 घंटे के अंदर",
    "expected_response_delay_432000": "5 दिन",
    "expected_response_delay_518400": "6 दिन",
    "expected_response_delay_60": "1 मिनट के अंदर",
    "expected_response_delay_600": "10 मिनट के अंदर",
    "expected_response_delay_604800": "1 हफ़्ते के अंदर",
    "expected_response_delay_7200": "2 घंटे के अंदर",
    "expected_response_delay_86400": "1 दिन",
    "expected_response_delay_900": "15 मिनट के अंदर",
    "expected_response_delay_unknown": "जितना जल्दी हमसे हो सकेगा",
    "extra_labels_count": "+ {labels_count} लेबल",
    "for_best_results_provide_detail": "बेहतरीन नतीजे पाने के लिए, जितनी हो सके उतनी जानकारी दें",
    "generating_answer": "जवाब जेनरेट किया जा रहा है...",
    "generic_triage_default": "आपको सही व्यक्ति से कनेक्ट करने के लिए हमें बस थोड़ी और जानकारी चाहिए",
    "get_an_instant_answer": "तुरंत जवाब पाएं",
    "get_help": "मदद लें",
    "go_to": "{app_name} पर जाएं",
    "help_space_title": "मदद",
    "home_header_mobile_hello_firstname": "नमस्ते, {firstname}.",
    "home_header_mobile_hello_there": "नमस्ते",
    "home_space_title": "होम",
    "how_can_we_help": "हम कैसे मदद कर सकते हैं?",
    "how_would_you_rate_this": "आप इसे क्या रेटिंग देंगे?",
    "learn_more": "ज़्यादा जानें",
    "message_placeholder": "संदेश भेजें...",
    "messages_space_title": "मेसेज",
    "messenger_bot_reply_time": "हमारा बॉट तुरंत जवाब देगा",
    "messenger_settings_introduction": "हम कैसे मदद कर सकते हैं?",
    "multiple_articles": "{total_articles} आर्टिकल्स",
    "multiple_collections": "{total_collection} कलेक्शन",
    "new_conversation": "नई बातचीत",
    "news": "न्यूज़",
    "news_empty_title": "अभी तक कोई ख़बर नहीं",
    "news_latest_subtitle": "{team_name} टीम की ओर से",
    "news_latest_title": "लेटेस्ट",
    "news_older_title": "पुराना",
    "news_space_title": "न्यूज़",
    "newsfeed": "न्यूज़फ़ीड",
    "no_articles_to_display": "अभी तक कोई आर्टिकल नहीं",
    "no_help_articles_to_display": "कोई मदद से जुड़े आर्टिकल्स मौजूद नहीं है",
    "no_results_for_searchterm": "{searchTerm} के लिए कोई नतीजा नहीं मिला",
    "no_tasks": "कोई टास्क नहीं हैं",
    "no_tasks_available_currently": "फ़िलहाल, कोई टास्क उपलब्ध नहीं हैं.",
    "ok_the_team_typically_replies_in_day": "ठीक है, आम तौर पर, टीम एक दिन में जवाब देती है.",
    "ok_the_team_typically_replies_in_hours": "ठीक है, आम तौर पर, टीम कुछ घंटों में जवाब देती है.",
    "ok_the_team_typically_replies_in_minutes": "ठीक है, आम तौर पर, टीम कुछ मिनट में जवाब देती है.",
    "ok_the_team_typically_replies_in_under_n_hours": "ठीक है, आम तौर पर, टीम {hours} घंटे के अंदर जवाब देती है.",
    "ok_the_team_typically_replies_in_under_n_min": "ठीक है, आम तौर पर, टीम {minutes} मिनट के अंदर जवाब देती है.",
    "ok_the_team_typically_replies_in_week": "ठीक है, आम तौर पर, टीम एक हफ़्ते में जवाब देती है.",
    "our_bot_and_team_can_help": "हमारे बॉट और टीम आपकी मदद कर सकते हैं",
    "our_bot_answers_instantly": "हमारा बॉट तुरंत जवाब देता है",
    "powered_by_intercom": "Intercom द्वारा संचालित",
    "previous_bot_hands_over": "पिछले बॉट का कंटेंट...",
    "proactive_expected_response_delay_10800": "आम तौर पर, 3 घंटे के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_120": "आम तौर पर, 2 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_1200": "आम तौर पर, 20 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_14400": "आम तौर पर, 4 घंटे के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_172800": "आम तौर पर, 2 दिन में जवाब देते हैं",
    "proactive_expected_response_delay_180": "आम तौर पर, 3 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_1800": "आम तौर पर, 30 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_18000": "आम तौर पर, 5 घंटे के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_21600": "आमतौर पर, 6 घंटे के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_240": "आम तौर पर, 4 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_259200": "आम तौर पर, 3 दिन में जवाब देते हैं",
    "proactive_expected_response_delay_300": "आम तौर पर, 5 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_345600": "आम तौर पर, 4 दिन में जवाब देते हैं",
    "proactive_expected_response_delay_3600": "आम तौर पर, 1 घंटे के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_43200": "आम तौर पर 12 घंटे में जवाब देते हैं",
    "proactive_expected_response_delay_432000": "आम तौर पर, 5 दिन में जवाब देते हैं",
    "proactive_expected_response_delay_518400": "आम तौर पर, 6 दिन में जवाब देते हैं",
    "proactive_expected_response_delay_60": "आम तौर पर, 1 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_600": "आम तौर पर, 10 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_604800": "आम तौर पर, 1 हफ़्ते के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_7200": "आम तौर पर, 2 घंटे के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_86400": "आम तौर पर, 1 दिन में जवाब देते हैं",
    "proactive_expected_response_delay_900": "आम तौर पर, 15 मिनट के अंदर जवाब देते हैं",
    "proactive_expected_response_delay_unknown": "वह जल्द से जल्द जवाब देंगे",
    "profile_location": "{location} में {time}",
    "recent_ticket": "हाल ही का टिकट",
    "recent_tickets": "हाल ही के टिकट",
    "search_browse_empty_state_text": "माफ़ करें, हमें उससे जुड़ा कोई नतीजा नहीं मिला. दूसरे शब्दों से खोजें.",
    "search_for_help": "मदद के लिए खोजें",
    "see_all": "सब कुछ देखें",
    "see_all_conversations": "अपनी सभी बातचीत देखें",
    "see_previous_conversations": "अपनी पिछली बातचीत देखें",
    "send": "भेजें",
    "send_a_message": "कोई मेसेज भेजें…",
    "send_a_message_reply_time": "आम तौर पर, जवाब देने में लगने वाला समय है",
    "send_us_a_message": "हमें एक मेसेज भेजें",
    "set_expectations_bot_email_header": "आपको यहां और आपके ईमेल में जवाब मिलेंगे:",
    "set_expectations_bot_office_hours_header": "टीम वापस संपर्क करेगी",
    "set_expectations_bot_sms_header": "आपको यहां और SMS के ज़रिए जवाब मिलेंगे:",
    "set_expectations_replies_to_email": "आपको यहां और <b>{user_email}</b> पर जवाब मिलेंगे.",
    "set_expectations_replies_to_email_without_bold_text": "आपको यहां और {user_email} पर जवाब मिलेंगे.",
    "set_expectations_team_reply_asap": "टीम जल्द से जल्द जवाब देने की कोशिश करेगी.",
    "shared_by": "द्वारा शेयर किया गया",
    "show_less": "कम दिखाएं",
    "single_article": "1 आर्टिकल",
    "single_collection": "1 कलेक्शन",
    "source": "सोर्स",
    "sources": "सोर्स",
    "start_a_conversation": "बातचीत शुरू करें",
    "start_conversation": "बातचीत शुरू करें…",
    "still_generating_answer": "अभी भी जवाब जेनरेट किया जा रहा है...",
    "still_thinking": "अभी भी सोच रहा है...",
    "still_working_on_it": "अभी भी इस पर काम चल रहा है...",
    "tasks_space_title": "टास्क",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} जल्द से जल्द जवाब देगा",
    "teammate_changes_team_assignment_of_a_conversation": "टीम के सदस्य ने बातचीत का असाइनमेंट बदल दिया है...",
    "tell_us_what_you_need": "हमें बताएं कि आपको क्या मदद चाहिए और हमारा बॉट जवाब देने की पूरी कोशिश करेगा",
    "the_team_can_help_if_needed": "ज़रूरत पड़ने पर टीम मदद कर सकती है",
    "the_team_typically_replies_in_10800": "आम तौर पर, टीम 3 घंटे के अंदर जवाब देती है",
    "the_team_typically_replies_in_120": "आम तौर पर, टीम 2 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_1200": "आम तौर पर, टीम 20 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_14400": "आम तौर पर, टीम 4 घंटे के अंदर जवाब देती है",
    "the_team_typically_replies_in_172800": "आम तौर पर, टीम 2 दिन में जवाब देती है",
    "the_team_typically_replies_in_180": "आम तौर पर, टीम 3 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_1800": "आम तौर पर, टीम 30 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_18000": "आम तौर पर, टीम 5 घंटे के अंदर जवाब देती है",
    "the_team_typically_replies_in_21600": "आम तौर पर, टीम 6 घंटे के अंदर जवाब देती है",
    "the_team_typically_replies_in_240": "आम तौर पर, टीम 4 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_259200": "आम तौर पर, टीम 3 दिन में जवाब देती है",
    "the_team_typically_replies_in_300": "आम तौर पर, टीम 5 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_345600": "आम तौर पर, टीम 4 दिन में जवाब देती है",
    "the_team_typically_replies_in_3600": "आम तौर पर, टीम 1 घंटे के अंदर जवाब देती है",
    "the_team_typically_replies_in_43200": "आम तौर पर, टीम 12 घंटों के अंदर जवाब देती है",
    "the_team_typically_replies_in_432000": "आम तौर पर, टीम 5 दिन में जवाब देती है",
    "the_team_typically_replies_in_518400": "आम तौर पर, टीम 6 दिन में जवाब देती है",
    "the_team_typically_replies_in_60": "आम तौर पर, टीम 1 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_600": "आम तौर पर, टीम 10 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_604800": "आम तौर पर, टीम 1 हफ़्ते के अंदर जवाब देती है",
    "the_team_typically_replies_in_7200": "आम तौर पर, टीम 2 घंटे के अंदर जवाब देती है",
    "the_team_typically_replies_in_86400": "आम तौर पर, टीम 1 दिन में जवाब देती है",
    "the_team_typically_replies_in_900": "आम तौर पर, टीम 15 मिनट के अंदर जवाब देती है",
    "the_team_typically_replies_in_day": "आम तौर पर, टीम एक दिन में जवाब देती है.",
    "the_team_typically_replies_in_hours": "आम तौर पर, टीम कुछ घंटों में जवाब देती है.",
    "the_team_typically_replies_in_minutes": "आम तौर पर, टीम कुछ मिनट में जवाब देती है.",
    "the_team_typically_replies_in_under_n_hours": "आम तौर पर, टीम {hours} घंटे के अंदर जवाब देती है.",
    "the_team_typically_replies_in_under_n_min": "आम तौर पर, टीम {minutes} मिनट के अंदर जवाब देती है.",
    "the_team_typically_replies_in_unknown": "टीम जल्द से जल्द जवाब देने की कोशिश करेगी",
    "the_team_typically_replies_in_week": "आम तौर पर, टीम एक हफ़्ते में जवाब देती है.",
    "thinking": "सोच रहा है...",
    "tickets_cta_text": "इस टिकट के बारे में कोई सवाल है?",
    "tickets_have_a_question": "इस मामले के बारे में कोई सवाल है?",
    "tickets_space_title": "टिकट",
    "time_future_1h": "1 घंटे में",
    "time_future_2h": "2 घंटे में",
    "time_future_30m": "30 मिनट में",
    "time_future_3h": "3 घंटे में",
    "time_future_day_0": "सोमवार",
    "time_future_day_1": "मंगलवार",
    "time_future_day_2": "बुधवार",
    "time_future_day_3": "गुरूवार",
    "time_future_day_4": "शुक्रवार",
    "time_future_day_5": "शानिवार",
    "time_future_day_6": "रविवार",
    "time_future_later_today": "आज कुछ वक़्त बाद",
    "time_future_next_week": "अगले हफ़्ते",
    "time_future_tomorrow": "आने वाला कल",
    "try_use_different_keywords": "अलग-अलग कीवर्ड आज़माएं या अपना पूरा सवाल लिखें",
    "upfront_collection_form_close_out": "जवाब देखने और अपडेट भेजने के लिए, आप यहां कभी भी वापस आ सकते हैं.",
    "upfront_collection_form_introduction": "हमारी टीम की ओर से बेहतर और तेज़ी से जवाब पाने के लिए, अपने विषय को थोड़ा और विस्तार से बताइये.",
    "upfront_collection_form_submission_confirmation": "वह संदर्भ भेजने के लिए शुक्रिया. बाद में समय बचाने के लिए, बेझिझक अभी और जानकारी दें.",
    "upfront_collection_form_subtitle": "टीम को कुछ संदर्भ भेजें",
    "upfront_collection_form_subtitle_submitted": "वे इस संदर्भ को ध्यान में रखेंगे",
    "upfront_collection_form_title": "तेज़ी से मदद पाएं",
    "upfront_collection_form_title_submitted": "टीम को भेजा गया!",
    "view_all": "सब कुछ देखें",
    "view_conversation": "बातचीत देखें",
    "we_are_here_to_help": "हम मदद करने के लिए यहाँ मौजूद हैं",
    "we_run_on_intercom": "हम Intercom का इस्तेमाल करते हैं",
    "when_a_teammate_changes_the_state_of_a_conversation": "जब कोई टीम का साथी बातचीत की स्थिति बदलता है...",
    "working_on_it": "इस पर काम चल रहा है...",
    "you_are_all_up_to_date": "आपने सारी जानकारी पढ़ ली है!",
    "your_checklists": "आपकी चेकलिस्ट",
    "your_conversations": "आपकी बातचीत",
    "your_recent_conversations": "हालिया बातचीत",
    "your_recent_messages": "हाल ही के मेसेज"
  },
  "hr": {
    "a_day": "Jedan dan",
    "a_few_hours": "Nekoliko sati",
    "a_few_minutes": "Nekoliko minuta",
    "a_week": "Tjedan dana",
    "active_state": "Aktivan/na",
    "ai_answer": "Odgovor umjetne inteligencije",
    "ai_answer_information": "Odgovor umjetne inteligencije, informacije",
    "ai_bot": "AI bot",
    "almost_there": "Još malo...",
    "answer": "Odgovor",
    "answer_bot_acknowledge_duplicate_content": "Ovo ste već vidjeli, ali je to najbolji mogući odgovor.",
    "answer_bot_all_done": "To je pomoglo 👍",
    "answer_bot_ask_another_question": "Postavite drugo pitanje 🔁",
    "answer_bot_ask_something_else": "Postavite drugo pitanje 🔁",
    "answer_bot_connect_to_an_agent": "Povežite se s agentom 👤",
    "answer_bot_did_that_help": "Je li taj odgovor pomogao ili tražite nešto drugo?",
    "answer_bot_followup_expectations_prefix": "Tim će vam se javiti oko ovoga.",
    "answer_bot_get_more_help": "Potražite dodatnu pomoć 🌐",
    "answer_bot_get_more_help_followup": "Ova bi veza mogla pomoći:",
    "answer_bot_i_have_another_question": "Imam još jedno pitanje 🔁",
    "answer_bot_im_not_done_yet": "Još nisam završio/la 🔁",
    "answer_bot_keep_waiting": "Ne, još uvijek trebam pomoć 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Preoblikujte pitanje ili potražite dodatnu pomoć",
    "answer_bot_looping_first_no_answer": "Nažalost, kao robot nisam u mogućnosti pronaći odgovor na to.",
    "answer_bot_looping_first_no_answer_followup": "Preoblikujte pitanje ili porazgovarajte s našim timom",
    "answer_bot_looping_no_answer": "Nažalost, kao bot ne mogu pronaći odgovor na to pitanje. Možete učiniti sljedeće",
    "answer_bot_more_answers": "Pokaži mi više 👀",
    "answer_bot_no_answer_found": "Nažalost, nemamo brzi odgovor za to. Evo nekoliko drugih mogućnosti.",
    "answer_bot_resolve_followup": "Ovdje možete uvijek odgovoriti ako se pojavi još nešto.",
    "answer_bot_talk_to_person": "Razgovarati s osobom 👤",
    "answer_bot_that_answered_my_question": "To je bio odgovor na moje pitanje 👍",
    "answer_bot_thats_it": "To je sve 👍",
    "answer_bot_wait_for_the_team": "Pričekaj ekipu 💬",
    "answerbot_ask_for_more_context": "Naravno. Dok čekamo, možete li podijeliti više pojedinosti o svojoj situaciji ili pitanju? Tako ćemo brže pronaći odgovor, a možda pronađemo i više informacija za vas.",
    "answerbot_introduce_sneaky_answer": "Hvala na kontekstu. Našao sam informacije koje bi vam mogle pomoći.",
    "article_search_hint_text": "Pretražite članke...",
    "ask_a_question": "Postavite pitanje",
    "ask_a_question_placeholder": "Postavite pitanje...",
    "ask_a_question_with_ellipsis": "Postavite pitanje...",
    "ask_another_question_with_ellipsis": "Postavite drugo pitanje...",
    "ask_for_the_team_if_needed": "Zatražite člana tima ako je potrebno",
    "ask_me_a_question": "Postavite mi pitanje",
    "ask_our_bot_a_question": "Postavite pitanje našem botu",
    "avatar": "Avatar",
    "avatars": "Avatari",
    "bot": "Bot",
    "bot_answers_instantly": "Bot odgovara odmah",
    "bot_expectation_text": "Naš bot može odgovoriti na mnoga uobičajena pitanja",
    "bot_intro_default_part_1": "Pozdrav! Razgovarate s robotom. Ovdje sam kako bih odgovorio na vaša pitanja, ali uvijek vam je dostupna i mogućnost razgovora s našim timom.",
    "bot_intro_default_part_2": "Što je razlog vašeg današnjeg posjeta?",
    "bot_is_still_thinking": "Bot još uvijek razmišlja...",
    "bot_is_thinking": "Bot razmišlja...",
    "browse_collections": "Pregledajte zbirke",
    "chat_with_us": "Razgovarajte s nama",
    "check_later_for_updates": "Provjerite kasnije ima li novih ažuriranja.",
    "checklist_card_first_step": "Prvi korak",
    "checklist_card_next_step": "Sljedeći korak",
    "checklist_completed": "Dovršeno",
    "checklist_in_progress_time_remaining_plural": "Ostalo je još {minutes} min.",
    "checklist_in_progress_time_remaining_singular": "Još oko 1 minute do završetka",
    "checklist_mark_step_as_complete": "Označi kao dovršeno",
    "checklist_not_started_time_remaining_plural": "Oko {minutes} min",
    "checklist_not_started_time_remaining_singular": "Oko 1 minute",
    "checklist_number_of_steps_plural": "{number_of_steps} koraka",
    "checklist_number_of_steps_progress_plural": "{steps_completed} od {total_steps} koraka je dovršeno",
    "checklist_number_of_steps_progress_singular": "Dovršeno je 1 od {total_steps} koraka",
    "checklist_number_of_steps_singular": "1 korak",
    "checklist_sender_name": "Podijelio/la {name}",
    "checklist_step_action_button": "Akcijski gumb za korak",
    "checklist_step_completed": "Dovršeno",
    "contact_support": "Obratite se podršci",
    "contact_us": "Obratite nam se",
    "continue_the_conversation": "Nastavite razgovor",
    "conversation_card_office_hours_header": "Ponovno smo online",
    "conversation_card_reply_time_header": "Naše uobičajeno vrijeme odgovora",
    "conversation_card_reply_time_header_m5": "Uobičajeno vrijeme za odgovor",
    "conversation_card_unknown_response_time": "Odgovorit ćemo što prije",
    "conversation_part_sample_text": "Pozdrav, kako vam mogu pomoći? Uvijek ćete imati mogućnost razgovarati s našim timom ako je potrebno.",
    "conversations_space_title": "Razgovori",
    "create_a_ticket": "Izradite zahtjev za podršku",
    "currently_no_help_articles": "Trenutačno nema dostupnih članaka pomoći.",
    "customer_sends_their_first_message": "Kupac šalje svoju prvu poruku...",
    "expected_response_delay_10800": "manje od 3 sata",
    "expected_response_delay_120": "manje od 2 minute",
    "expected_response_delay_1200": "manje od 20 minuta",
    "expected_response_delay_14400": "manje od 4 sata",
    "expected_response_delay_172800": "2 dana",
    "expected_response_delay_180": "manje od 3 minute",
    "expected_response_delay_1800": "manje od 30 minuta",
    "expected_response_delay_18000": "manje od 5 sati",
    "expected_response_delay_21600": "manje od 6 sati",
    "expected_response_delay_240": "manje od 4 minuta",
    "expected_response_delay_259200": "3 dana",
    "expected_response_delay_300": "manje od 5 minuta",
    "expected_response_delay_345600": "4 dana",
    "expected_response_delay_3600": "manje od 1 sata",
    "expected_response_delay_43200": "manje od 12 sati",
    "expected_response_delay_432000": "5 dana",
    "expected_response_delay_518400": "6 dana",
    "expected_response_delay_60": "manje od 1 minute",
    "expected_response_delay_600": "manje od 10 minuta",
    "expected_response_delay_604800": "manje od 1 tjedna",
    "expected_response_delay_7200": "manje od 2 sata",
    "expected_response_delay_86400": "1 dan",
    "expected_response_delay_900": "manje od 15 minuta",
    "expected_response_delay_unknown": "što je prije moguće",
    "extra_labels_count": "+ {labels_count} oznake/a",
    "for_best_results_provide_detail": "Za najbolje rezultate navedite što više detalja",
    "generating_answer": "Generiram odgovor...",
    "generic_triage_default": "Potrebno nam je samo još malo informacija kako bismo vas povezali s pravom osobom",
    "get_an_instant_answer": "Dobijte trenutačni odgovor",
    "get_help": "Potražite pomoć",
    "go_to": "Otvori {app_name}",
    "help_space_title": "Pomoć",
    "home_header_mobile_hello_firstname": "Pozdrav, {firstname}.",
    "home_header_mobile_hello_there": "Pozdrav",
    "home_space_title": "Početna",
    "how_can_we_help": "Kako vam možemo po moći",
    "how_would_you_rate_this": "Kako biste ovo ocijenili?",
    "learn_more": "Saznajte više",
    "message_placeholder": "Poruka…",
    "messages_space_title": "Poruke",
    "messenger_bot_reply_time": "Naš bot odmah će odgovoriti",
    "messenger_settings_introduction": "Kako vam možemo po moći",
    "multiple_articles": "{total_articles} članaka",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "Novi razgovor",
    "news": "Vijesti",
    "news_empty_title": "Još nema novosti",
    "news_latest_subtitle": "Od tima {team_name}",
    "news_latest_title": "Najnovije",
    "news_older_title": "Starije",
    "news_space_title": "Vijesti",
    "newsfeed": "Sažetak sadržaja vijesti",
    "no_articles_to_display": "Još nema članaka",
    "no_help_articles_to_display": "Nema članaka pomoći",
    "no_results_for_searchterm": "Nema rezultata za {searchTerm}",
    "no_tasks": "Nema zadataka",
    "no_tasks_available_currently": "Trenutačno nema dostupnih zadataka.",
    "ok_the_team_typically_replies_in_day": "U redu, tim obično odgovori u roku od jednog dana.",
    "ok_the_team_typically_replies_in_hours": "U redu, tim obično odgovori za nekoliko sati.",
    "ok_the_team_typically_replies_in_minutes": "U redu, tim obično odgovori za nekoliko minuta.",
    "ok_the_team_typically_replies_in_under_n_hours": "U redu, tim obično odgovori za manje od {hours} sata/i.",
    "ok_the_team_typically_replies_in_under_n_min": "U redu, tim obično odgovori za manje od {minutes} minute/a.",
    "ok_the_team_typically_replies_in_week": "U redu, naš tim obično odgovara u roku od tjedan dana.",
    "our_bot_and_team_can_help": "Naš bot i tim mogu vam pomoći",
    "our_bot_answers_instantly": "Naš bot odgovara odmah",
    "powered_by_intercom": "Omogućuje Intercom",
    "previous_bot_hands_over": "Sadržaj od prethodnog bota...",
    "proactive_expected_response_delay_10800": "Obično odgovori za manje od 3 h",
    "proactive_expected_response_delay_120": "Obično odgovori za manje od 2 min",
    "proactive_expected_response_delay_1200": "Obično odgovori za manje od 20 min",
    "proactive_expected_response_delay_14400": "Obično odgovori za manje od 4 h",
    "proactive_expected_response_delay_172800": "Obično odgovori za 2 dana",
    "proactive_expected_response_delay_180": "Obično odgovori za manje od 3 min",
    "proactive_expected_response_delay_1800": "Obično odgovori za manje od 30 min",
    "proactive_expected_response_delay_18000": "Obično odgovori za manje od 5 h",
    "proactive_expected_response_delay_21600": "Obično odgovori za manje od 6 h",
    "proactive_expected_response_delay_240": "Obično odgovori za manje od 4 min",
    "proactive_expected_response_delay_259200": "Odgovor obično stiže za 3 dana",
    "proactive_expected_response_delay_300": "Obično odgovori za manje od 5 min",
    "proactive_expected_response_delay_345600": "Odgovor obično stiže za 4 dana",
    "proactive_expected_response_delay_3600": "Obično odgovori za manje od 1 h",
    "proactive_expected_response_delay_43200": "Odgovor obično stiže za 12 sati",
    "proactive_expected_response_delay_432000": "Odgovor obično stiže za 5 dana",
    "proactive_expected_response_delay_518400": "Odgovor obično stiže za 6 dana",
    "proactive_expected_response_delay_60": "Obično odgovori za manje od 1 min",
    "proactive_expected_response_delay_600": "Obično odgovori za manje od 10 min",
    "proactive_expected_response_delay_604800": "Obično odgovori za manje od tjedan dana",
    "proactive_expected_response_delay_7200": "Obično odgovori za manje od 2 h",
    "proactive_expected_response_delay_86400": "Obično odgovori za 1 dan",
    "proactive_expected_response_delay_900": "Odgovor obično stiže za manje od 15 min",
    "proactive_expected_response_delay_unknown": "Odgovorit će čim bude u mogućnosti",
    "profile_location": "{time} u {location}",
    "recent_ticket": "Nedavni zahtjev",
    "recent_tickets": "Nedavni zahtjevi",
    "search_browse_empty_state_text": "Za to, nažalost, nismo uspjeli pronaći rezultate. Pokušajte preoblikovati svoj upit za pretraživanje.",
    "search_for_help": "Pretražite za pomoć",
    "see_all": "Pogledaj sve",
    "see_all_conversations": "Pogledajte sve svoje razgovore",
    "see_previous_conversations": "Pogledajte svoje prethodne razgovore",
    "send": "Pošalji",
    "send_a_message": "Pošalji poruku…",
    "send_a_message_reply_time": "Uobičajeno vrijeme za odgovor je",
    "send_us_a_message": "Pošaljite nam poruku",
    "set_expectations_bot_email_header": "Odgovore ćete dobiti ovdje i na vašu adresu e-pošte:",
    "set_expectations_bot_office_hours_header": "Kada će se tim vratiti:",
    "set_expectations_bot_sms_header": "Odgovore ćete dobiti ovdje i putem SMS poruke:",
    "set_expectations_replies_to_email": "Odgovore ćete dobiti ovdje i na <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Odgovore ćete dobiti ovdje i na {user_email}",
    "set_expectations_team_reply_asap": "Tim će odgovoriti čim bude u mogućnosti.",
    "shared_by": "Podijelio/la",
    "show_less": "Prikaži manje",
    "single_article": "1 članak",
    "single_collection": "1 collection",
    "source": "Izvor",
    "sources": "Izvori",
    "start_a_conversation": "Započni razgovor",
    "start_conversation": "Započni razgovor",
    "still_generating_answer": "Još uvijek generiram odgovor...",
    "still_thinking": "Još uvijek razmišljam...",
    "still_working_on_it": "Još uvijek radim na tome...",
    "tasks_space_title": "Zadaci",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} će odgovoriti čim bude u mogućnosti",
    "teammate_changes_team_assignment_of_a_conversation": "Član tima promijenio je zadatak razgovora…",
    "tell_us_what_you_need": "Recite nam u čemu vam je potrebna pomoć i naš će se bot potruditi dati odgovor",
    "the_team_can_help_if_needed": "Ako je potrebno, pomoći može i naš tim",
    "the_team_typically_replies_in_10800": "Tim obično odgovora za manje od 3 h.",
    "the_team_typically_replies_in_120": "Tim obično odgovori za manje od 2 min.",
    "the_team_typically_replies_in_1200": "Tim obično odgovora za manje od 20 min.",
    "the_team_typically_replies_in_14400": "Tim obično odgovora za manje od 4 h.",
    "the_team_typically_replies_in_172800": "Tim obično odgovora za 2 dana.",
    "the_team_typically_replies_in_180": "Tim obično odgovori za manje od 3 min.",
    "the_team_typically_replies_in_1800": "Tim obično odgovora za manje od 30 min.",
    "the_team_typically_replies_in_18000": "Tim obično odgovora za manje od 5 h.",
    "the_team_typically_replies_in_21600": "Tim obično odgovora za manje od 6 h.",
    "the_team_typically_replies_in_240": "Tim obično odgovori za manje od 4 min.",
    "the_team_typically_replies_in_259200": "Tim obično odgovori za 3 dana.",
    "the_team_typically_replies_in_300": "Tim obično odgovora za manje od 5 min.",
    "the_team_typically_replies_in_345600": "Tim obično odgovori za 4 dana.",
    "the_team_typically_replies_in_3600": "Tim obično odgovora za manje od 1 h.",
    "the_team_typically_replies_in_43200": "Tim obično odgovori za manje od 12 h.",
    "the_team_typically_replies_in_432000": "Tim obično odgovori za 5 dana.",
    "the_team_typically_replies_in_518400": "Tim obično odgovori za 6 dana.",
    "the_team_typically_replies_in_60": "Tim obično odgovori za manje od 1 min.",
    "the_team_typically_replies_in_600": "Tim obično odgovora za manje od 10 min.",
    "the_team_typically_replies_in_604800": "Tim obično odgovora za manje od 1 tjedna.",
    "the_team_typically_replies_in_7200": "Tim obično odgovora za manje od 2 h.",
    "the_team_typically_replies_in_86400": "Tim obično odgovora za 1 dan.",
    "the_team_typically_replies_in_900": "Tim obično odgovori za manje od 15 min",
    "the_team_typically_replies_in_day": "Tim obično odgovori za jedan dan.",
    "the_team_typically_replies_in_hours": "Tim obično odgovori za nekoliko sati.",
    "the_team_typically_replies_in_minutes": "Tim obično odgovori za nekoliko minuta.",
    "the_team_typically_replies_in_under_n_hours": "Tim obično odgovori za manje od {hours} sata/i.",
    "the_team_typically_replies_in_under_n_min": "Tim obično odgovori za manje od {minutes} minute/a.",
    "the_team_typically_replies_in_unknown": "Tim će odgovoriti čim bude u mogućnosti",
    "the_team_typically_replies_in_week": "Naš tim obično odgovara u roku od tjedan dana.",
    "thinking": "Razmišljam...",
    "tickets_cta_text": "Imate li pitanja o ovom zahtjevu?",
    "tickets_have_a_question": "Imate li pitanja o ovom problemu?",
    "tickets_space_title": "Zahtjevi",
    "time_future_1h": "1 sat",
    "time_future_2h": "2 sata",
    "time_future_30m": "30 minuta",
    "time_future_3h": "3 sata",
    "time_future_day_0": "Ponedjeljak",
    "time_future_day_1": "Utorak",
    "time_future_day_2": "Srijeda",
    "time_future_day_3": "Četvrtak",
    "time_future_day_4": "Petak",
    "time_future_day_5": "Subota",
    "time_future_day_6": "Nedjelja",
    "time_future_later_today": "Kasnije danas",
    "time_future_next_week": "Sljedeći tjedan",
    "time_future_tomorrow": "Sutra",
    "try_use_different_keywords": "Pokušajte upotrijebiti drugačije ključne riječi ili unesite cijelo pitanje",
    "upfront_collection_form_close_out": "Ovdje se možete vratiti u bilo koje vrijeme da biste vidjeli odgovore i poslali ažuriranja.",
    "upfront_collection_form_introduction": "Dodajte kontekst kako biste pomogli timu da bolje i brže reagira.",
    "upfront_collection_form_submission_confirmation": "Hvala što ste dodali kontekst. Da biste kasnije uštedjeli vrijeme, slobodno dodajte više detalja.",
    "upfront_collection_form_subtitle": "Pošaljite timu kontekst",
    "upfront_collection_form_subtitle_submitted": "Oni će imati u vidu taj kontekst",
    "upfront_collection_form_title": "Brže dobijte pomoć",
    "upfront_collection_form_title_submitted": "Poslano timu!",
    "view_all": "Prikaži sve",
    "view_conversation": "Prikaži razgovor",
    "we_are_here_to_help": "Tu smo da pomognemo",
    "we_run_on_intercom": "Mi koristimo Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kad član tima promijeni status razgovora...",
    "working_on_it": "Radim na tome...",
    "you_are_all_up_to_date": "U tijeku ste sa svime!",
    "your_checklists": "Vaši kontrolni popisi",
    "your_conversations": "Vaši razgovori",
    "your_recent_conversations": "Nedavni razgovori",
    "your_recent_messages": "Nedavne poruke"
  },
  "hu": {
    "a_day": "1 nap",
    "a_few_hours": "Néhány óra",
    "a_few_minutes": "Néhány perc",
    "a_week": "Egy hét",
    "active_state": "Aktív",
    "ai_answer": "MI-válasz",
    "ai_answer_information": "MI-válasz, információ",
    "ai_bot": "MI-robot",
    "almost_there": "Mindjárt kész vagyok…",
    "answer": "Válasz",
    "answer_bot_acknowledge_duplicate_content": "Ezt már látta, de ez az legjobb rendelkezésre álló válasz.",
    "answer_bot_all_done": "Ez segített 👍",
    "answer_bot_ask_another_question": "Tegyél fel egy másik kérdést 🔁",
    "answer_bot_ask_something_else": "Kérdezzen valami mást 🔁",
    "answer_bot_connect_to_an_agent": "Kapcsolódás egy ügynökhöz 👤",
    "answer_bot_did_that_help": "Segített ez a válasz, vagy valami mást keres?",
    "answer_bot_followup_expectations_prefix": "Munkatársaink jelentkezni fognak ezzel kapcsolatban.",
    "answer_bot_get_more_help": "További segítség 🌐",
    "answer_bot_get_more_help_followup": "Ez a hivatkozás segíthet:",
    "answer_bot_i_have_another_question": "Lenne még egy kérdésem 🔁",
    "answer_bot_im_not_done_yet": "Még nem végeztem 🔁",
    "answer_bot_keep_waiting": "Nem, továbbra is szükségem van segítségre 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Átfogalmazhatja kérdését, vagy további segítséget kérhet",
    "answer_bot_looping_first_no_answer": "Sajnálom, robotként nem találtam erre választ.",
    "answer_bot_looping_first_no_answer_followup": "Átfogalmazhatja kérdését, vagy beszélhet a munkatársainkkal",
    "answer_bot_looping_no_answer": "Sajnálom, robotként nem találtam erre választ. Íme, a lehetőségei",
    "answer_bot_more_answers": "Mutasson többet 👀",
    "answer_bot_no_answer_found": "Sajnos erre nincs gyors válaszunk. Íme néhány további lehetőség.",
    "answer_bot_resolve_followup": "Itt mindig válaszolhat, ha bármi más felmerül.",
    "answer_bot_talk_to_person": "Beszéljen egy emberrel 👤",
    "answer_bot_that_answered_my_question": "Ez választ adott a kérdésemre 👍",
    "answer_bot_thats_it": "Ez az 👍",
    "answer_bot_wait_for_the_team": "Várjon csapatunkra 💬",
    "answerbot_ask_for_more_context": "Rendben! Amíg várakozunk, megosztana velünk további részleteket a helyzetéről vagy a kérdéséről? Így gyorsabban találunk választ, és talán több információt is gyűjthetek Önnek.",
    "answerbot_introduce_sneaky_answer": "Köszönöm a kiegészítő magyarázatot. Találtam néhány új információt, ami segíthet Önnek.",
    "article_search_hint_text": "Articles keresése...",
    "ask_a_question": "Tegyen fel kérdést",
    "ask_a_question_placeholder": "Tegyen fel kérdést...",
    "ask_a_question_with_ellipsis": "Kérdezzen…",
    "ask_another_question_with_ellipsis": "Tegyen fel egy másik kérdést…",
    "ask_for_the_team_if_needed": "Szükség esetén kérje a csapat egyik munkatársát",
    "ask_me_a_question": "Tegyen fel nekem egy kérdést",
    "ask_our_bot_a_question": "Kérdezzen a robotunktól",
    "avatar": "Avatar",
    "avatars": "Avatarok",
    "bot": "Robot",
    "bot_answers_instantly": "A robot azonnal válaszol",
    "bot_expectation_text": "Robotunk számos gyakori kérdésre tud választ adni",
    "bot_intro_default_part_1": "Üdv! A robot vagyok. Megpróbálok válaszolni a kérdéseire, de mindig megvan a lehetősége arra is, hogy beszéljen a munkatársainkkal.",
    "bot_intro_default_part_2": "Miben segíthetek ma?",
    "bot_is_still_thinking": "A robot még mindig gondolkodik…",
    "bot_is_thinking": "A robot gondolkodik…",
    "browse_collections": "Gyűjtemények böngészése",
    "chat_with_us": "Csevegjen velünk",
    "check_later_for_updates": "Nézzen vissza később a frissítésekért.",
    "checklist_card_first_step": "Első lépés",
    "checklist_card_next_step": "Következő lépés",
    "checklist_completed": "Befejezett",
    "checklist_in_progress_time_remaining_plural": "Körülbelül {minutes} perc van hátra",
    "checklist_in_progress_time_remaining_singular": "Körülbelül 1 perc maradt",
    "checklist_mark_step_as_complete": "Megjelölés befejezettként",
    "checklist_not_started_time_remaining_plural": "Körülbelül {minutes} perc",
    "checklist_not_started_time_remaining_singular": "Körülbelül 1 perc",
    "checklist_number_of_steps_plural": "{number_of_steps} lépés",
    "checklist_number_of_steps_progress_plural": "{steps_completed}/{total_steps} végrehajtva",
    "checklist_number_of_steps_progress_singular": "{total_steps}/1 végrehajtva",
    "checklist_number_of_steps_singular": "1 lépés",
    "checklist_sender_name": "Megosztotta: {name}",
    "checklist_step_action_button": "Lépés akciógomb",
    "checklist_step_completed": "Befejezett",
    "contact_support": "Kapcsolat az ügyfélszolgálattal",
    "contact_us": "Kapcsolatfelvétel",
    "continue_the_conversation": "Beszélgetés folytatása",
    "conversation_card_office_hours_header": "Ekkor leszünk újra online",
    "conversation_card_reply_time_header": "Szokásos válaszidőnk",
    "conversation_card_reply_time_header_m5": "Szokásos válaszadási idő",
    "conversation_card_unknown_response_time": "Válaszolunk, amint tudunk.",
    "conversation_part_sample_text": "Üdv! Miben segíthetünk? Szükség esetén mindig megvan a lehetősége arra is, hogy beszéljen a munkatársainkkal.",
    "conversations_space_title": "Beszélgetések",
    "create_a_ticket": "Jegy létrehozása",
    "currently_no_help_articles": "Jelenleg nem áll rendelkezésre súgócikk.",
    "customer_sends_their_first_message": "Az ügyfél elküldi első üzenetét...",
    "expected_response_delay_10800": "3 órán belül",
    "expected_response_delay_120": "2 percen belül",
    "expected_response_delay_1200": "20 percen belül",
    "expected_response_delay_14400": "4 órán belül",
    "expected_response_delay_172800": "2 nap",
    "expected_response_delay_180": "3 percen belül",
    "expected_response_delay_1800": "30 percen belül",
    "expected_response_delay_18000": "5 órán belül",
    "expected_response_delay_21600": "6 órán belül",
    "expected_response_delay_240": "4 percen belül",
    "expected_response_delay_259200": "3 nap",
    "expected_response_delay_300": "5 percen belül",
    "expected_response_delay_345600": "4 nap",
    "expected_response_delay_3600": "1 órán belül",
    "expected_response_delay_43200": "12 órán belül",
    "expected_response_delay_432000": "5 nap",
    "expected_response_delay_518400": "6 nap",
    "expected_response_delay_60": "1 percen belül",
    "expected_response_delay_600": "10 percen belül",
    "expected_response_delay_604800": "1 héten belül",
    "expected_response_delay_7200": "2 órán belül",
    "expected_response_delay_86400": "1 nap",
    "expected_response_delay_900": "15 percen belül",
    "expected_response_delay_unknown": "Amint tudunk",
    "extra_labels_count": "+ {labels_count} címke",
    "for_best_results_provide_detail": "A legjobb eredmény érdekében a lehető legtöbb információt adja meg",
    "generating_answer": "Válasz generálása folyamatban...",
    "generic_triage_default": "Még egy kis információra lenne szükségünk ahhoz, hogy kapcsolatba léptethessük a megfelelő személlyel.",
    "get_an_instant_answer": "Kérjen azonnali választ",
    "get_help": "Súgó kérése",
    "go_to": "Tovább ide: ({app_name})",
    "help_space_title": "Súgó",
    "home_header_mobile_hello_firstname": "Üdv, {firstname}!",
    "home_header_mobile_hello_there": "Üdv!",
    "home_space_title": "Főoldal",
    "how_can_we_help": "Miben segíthetünk?",
    "how_would_you_rate_this": "Hogyan értékelnéd?",
    "learn_more": "Tudjon meg többet",
    "message_placeholder": "Üzenet…",
    "messages_space_title": "Üzenetek",
    "messenger_bot_reply_time": "Robotunk azonnal válaszol",
    "messenger_settings_introduction": "Miben segíthetünk?",
    "multiple_articles": "{total_articles} cikk",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "Új beszélgetés",
    "news": "Hírek",
    "news_empty_title": "Még nincs hír",
    "news_latest_subtitle": "A(z) {team_name} csapattól",
    "news_latest_title": "Legfrissebbek",
    "news_older_title": "Régebbi",
    "news_space_title": "Hírek",
    "newsfeed": "Hírcsatorna",
    "no_articles_to_display": "Még nincsenek cikkek",
    "no_help_articles_to_display": "Nincs súgócikk",
    "no_results_for_searchterm": "Nincs eredmény a következőre: {searchTerm}",
    "no_tasks": "Nincsenek feladatok",
    "no_tasks_available_currently": "Jelenleg nincsenek elérhető feladatok.",
    "ok_the_team_typically_replies_in_day": "Rendben, a csapat általában egy napon belül válaszol.",
    "ok_the_team_typically_replies_in_hours": "Rendben, a csapat általában néhány órán belül válaszol.",
    "ok_the_team_typically_replies_in_minutes": "Rendben, a csapat általában néhány percen belül válaszol.",
    "ok_the_team_typically_replies_in_under_n_hours": "Rendben, a csapat általában {hours} órán belül válaszol.",
    "ok_the_team_typically_replies_in_under_n_min": "Rendben, a csapat általában {minutes} percen belül válaszol.",
    "ok_the_team_typically_replies_in_week": "Rendben, a csapat általában egy héten belül válaszol.",
    "our_bot_and_team_can_help": "Robotunk és csapatunk segíteni tud",
    "our_bot_answers_instantly": "Robotunk azonnal válaszol",
    "powered_by_intercom": "A funkciókat az Intercom biztosítja",
    "previous_bot_hands_over": "Tartalom az előző bottól...",
    "proactive_expected_response_delay_10800": "Általában 3 órán belül válaszol",
    "proactive_expected_response_delay_120": "Általában 2 percen belül válaszol",
    "proactive_expected_response_delay_1200": "Általában 20 percen belül válaszol",
    "proactive_expected_response_delay_14400": "Általában 4 órán belül válaszol",
    "proactive_expected_response_delay_172800": "Általában 2 napon belül válaszol",
    "proactive_expected_response_delay_180": "Általában 3 percen belül válaszol",
    "proactive_expected_response_delay_1800": "Általában 30 percen belül válaszol",
    "proactive_expected_response_delay_18000": "Általában 5 órán belül válaszol",
    "proactive_expected_response_delay_21600": "Általában 6 órán belül válaszol",
    "proactive_expected_response_delay_240": "Általában 4 percen belül válaszol",
    "proactive_expected_response_delay_259200": "Általában 3 napon belül válaszol",
    "proactive_expected_response_delay_300": "Általában 5 percen belül válaszol",
    "proactive_expected_response_delay_345600": "Általában 4 napon belül válaszol",
    "proactive_expected_response_delay_3600": "Általában 1 órán belül válaszol",
    "proactive_expected_response_delay_43200": "Általában 12 órán belül válaszol",
    "proactive_expected_response_delay_432000": "Általában 5 napon belül válaszol",
    "proactive_expected_response_delay_518400": "Általában 6 napon belül válaszol",
    "proactive_expected_response_delay_60": "Általában 1 percen belül válaszol",
    "proactive_expected_response_delay_600": "Általában 10 percen belül válaszol",
    "proactive_expected_response_delay_604800": "Általában 1 héten belül válaszol",
    "proactive_expected_response_delay_7200": "Általában 2 órán belül válaszol",
    "proactive_expected_response_delay_86400": "Általában 1 napon belül válaszol",
    "proactive_expected_response_delay_900": "Általában 15 percen belül válaszol.",
    "proactive_expected_response_delay_unknown": "Válaszol, amint tud",
    "profile_location": "{time} – {location}",
    "recent_ticket": "Legutóbbi jegy",
    "recent_tickets": "Legutóbbi jegyek",
    "search_browse_empty_state_text": "Sajnáljuk, erre nincs találatunk. Fogalmazza át a keresését.",
    "search_for_help": "Keresés a súgóban",
    "see_all": "Összes megtekintése",
    "see_all_conversations": "Összes beszélgetés megtekintése",
    "see_previous_conversations": "Előző beszélgetések megtekintése",
    "send": "Küldés",
    "send_a_message": "Üzenet küldése…",
    "send_a_message_reply_time": "A szokásos válaszadási idő",
    "send_us_a_message": "Küldjön üzenetet nekünk!",
    "set_expectations_bot_email_header": "Választ itt és az e-mail címére fog kapni:",
    "set_expectations_bot_office_hours_header": "Csapatunk ekkor lesz újra elérhető:",
    "set_expectations_bot_sms_header": "Választ itt és SMS-ben fog kapni:",
    "set_expectations_replies_to_email": "Választ itt és a <b>{user_email}</b> címre fog kapni.",
    "set_expectations_replies_to_email_without_bold_text": "Választ itt és a(z) {user_email} címre fog kapni.",
    "set_expectations_team_reply_asap": "Csapatunk válaszol, amint tud.",
    "shared_by": "Megosztotta:",
    "show_less": "Kevesebb mutatása",
    "single_article": "1 cikk",
    "single_collection": "1 collection",
    "source": "Forrás",
    "sources": "Források",
    "start_a_conversation": "Beszélgetés indítása",
    "start_conversation": "Beszélgetés indítása",
    "still_generating_answer": "Még mindig tart a válasz generálása…",
    "still_thinking": "Még mindig gondolkodom…",
    "still_working_on_it": "Még mindig dolgozom rajta…",
    "tasks_space_title": "Feladatok",
    "team_name_proactive_expected_response_delay_unknown": "A(z) {app_name} válaszol, amint tud.",
    "teammate_changes_team_assignment_of_a_conversation": "Egy csapattag megváltoztatta a beszélgetés hozzárendelését...",
    "tell_us_what_you_need": "Mondja el, miben segíthetünk, és robotunk mindent megtesz annak érdekében, hogy válaszoljon a kérdésére.",
    "the_team_can_help_if_needed": "Munkatársaink szükség esetén segítenek",
    "the_team_typically_replies_in_10800": "Csapatunk általában 3 órán belül válaszol.",
    "the_team_typically_replies_in_120": "A munkatársak általában 2 percen belül válaszolnak.",
    "the_team_typically_replies_in_1200": "Csapatunk általában 20 percen belül válaszol.",
    "the_team_typically_replies_in_14400": "Csapatunk általában 4 órán belül válaszol.",
    "the_team_typically_replies_in_172800": "Csapatunk általában 2 napon belül válaszol.",
    "the_team_typically_replies_in_180": "A munkatársak általában 3 percen belül válaszolnak.",
    "the_team_typically_replies_in_1800": "Csapatunk általában 30 percen belül válaszol.",
    "the_team_typically_replies_in_18000": "Csapatunk általában 5 órán belül válaszol.",
    "the_team_typically_replies_in_21600": "Csapatunk általában 6 órán belül válaszol.",
    "the_team_typically_replies_in_240": "A munkatársak általában 4 percen belül válaszolnak.",
    "the_team_typically_replies_in_259200": "A munkatársak általában 3 napon belül válaszolnak.",
    "the_team_typically_replies_in_300": "Csapatunk általában 5 percen belül válaszol.",
    "the_team_typically_replies_in_345600": "A munkatársak általában 4 napon belül válaszolnak.",
    "the_team_typically_replies_in_3600": "Csapatunk általában 1 órán belül válaszol.",
    "the_team_typically_replies_in_43200": "A munkatársak általában 12 órán belül válaszolnak.",
    "the_team_typically_replies_in_432000": "A munkatársak általában 5 napon belül válaszolnak.",
    "the_team_typically_replies_in_518400": "A munkatársak általában 6 napon belül válaszolnak.",
    "the_team_typically_replies_in_60": "A munkatársak általában 1 percen belül válaszolnak.",
    "the_team_typically_replies_in_600": "Csapatunk általában 10 percen belül válaszol.",
    "the_team_typically_replies_in_604800": "Csapatunk általában 1 héten belül válaszol.",
    "the_team_typically_replies_in_7200": "Csapatunk általában 2 órán belül válaszol.",
    "the_team_typically_replies_in_86400": "Csapatunk általában 1 napon belül válaszol.",
    "the_team_typically_replies_in_900": "Csapatunk általában 15 percen belül válaszol.",
    "the_team_typically_replies_in_day": "Csapatunk általában egy napon belül válaszol.",
    "the_team_typically_replies_in_hours": "Csapatunk általában néhány órán belül válaszol.",
    "the_team_typically_replies_in_minutes": "Csapatunk általában néhány percen belül válaszol.",
    "the_team_typically_replies_in_under_n_hours": "Csapatunk általában {hours} órán belül válaszol.",
    "the_team_typically_replies_in_under_n_min": "Csapatunk általában {minutes} percen belül válaszol.",
    "the_team_typically_replies_in_unknown": "Csapatunk válaszol, amint tud.",
    "the_team_typically_replies_in_week": "A csapat általában egy héten belül válaszol.",
    "thinking": "Gondolkodom…",
    "tickets_cta_text": "Kérdése van ezzel a jeggyel kapcsolatban?",
    "tickets_have_a_question": "Kérdése van ezzel a hibával kapcsolatban?",
    "tickets_space_title": "Jegyek",
    "time_future_1h": "1 óra",
    "time_future_2h": "2 óra",
    "time_future_30m": "30 perc",
    "time_future_3h": "3 óra",
    "time_future_day_0": "hétfő",
    "time_future_day_1": "kedd",
    "time_future_day_2": "szerda",
    "time_future_day_3": "csütörtök",
    "time_future_day_4": "péntek",
    "time_future_day_5": "szombat",
    "time_future_day_6": "vasárnap",
    "time_future_later_today": "később ma",
    "time_future_next_week": "jövő héten",
    "time_future_tomorrow": "holnap",
    "try_use_different_keywords": "Próbáljon meg más kulcsszavakat használni, vagy írja be a teljes kérdését.",
    "upfront_collection_form_close_out": "Bármikor visszatérhet ide, hogy megnézze a válaszokat, és újabb híreket küldjön.",
    "upfront_collection_form_introduction": "Osszon meg néhány háttérinformációt, hogy munkatársaink hatékonyabban és gyorsabban tudjanak reagálni.",
    "upfront_collection_form_submission_confirmation": "Köszönjük, hogy elküldte ezt a háttérinformációt. Hogy később időt spórolhasson, adjon meg most további részleteket.",
    "upfront_collection_form_subtitle": "Küldjön a csapatnak néhány háttérinformációt.",
    "upfront_collection_form_subtitle_submitted": "Szem előtt fogják tartani ezeket a háttérinformációkat.",
    "upfront_collection_form_title": "Gyorsabb segítség",
    "upfront_collection_form_title_submitted": "Elküldve a csapatnak!",
    "view_all": "Összes megtekintése",
    "view_conversation": "Beszélgetés megtekintése",
    "we_are_here_to_help": "Azért vagyunk itt, hogy segítsünk",
    "we_run_on_intercom": "Fejlesztette az Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Amikor egy csapattárs megváltoztatja a beszélgetés állapotát...",
    "working_on_it": "Dolgozom rajta…",
    "you_are_all_up_to_date": "Egyelőre mindent megnéztél!",
    "your_checklists": "Az Ön ellenőrzőlistái",
    "your_conversations": "Korábbi beszélgetések",
    "your_recent_conversations": "Legutóbbi beszélgetések",
    "your_recent_messages": "Legutóbbi üzenetek"
  },
  "id": {
    "a_day": "Satu hari",
    "a_few_hours": "Beberapa jam",
    "a_few_minutes": "Beberapa menit",
    "a_week": "Seminggu",
    "active_state": "Aktif",
    "ai_answer": "Jawaban AI",
    "ai_answer_information": "Jawaban AI, informasi",
    "ai_bot": "Bot AI",
    "almost_there": "Hampir selesai...",
    "answer": "Jawaban",
    "answer_bot_acknowledge_duplicate_content": "Anda telah melihat ini, tetapi ini adalah jawaban terbaik yang tersedia.",
    "answer_bot_all_done": "Yang membantu 👍",
    "answer_bot_ask_another_question": "Ajukan pertanyaan lain 🔁",
    "answer_bot_ask_something_else": "Ajukan pertanyaan lain 🔁",
    "answer_bot_connect_to_an_agent": "Terhubung ke agen 👤",
    "answer_bot_did_that_help": "Apakah jawaban itu membantu, atau Anda mencari sesuatu yang lain?",
    "answer_bot_followup_expectations_prefix": "Tim akan menghubungi Anda mengenai hal ini.",
    "answer_bot_get_more_help": "Dapatkan bantuan lain 🌐",
    "answer_bot_get_more_help_followup": "Tautan ini mungkin membantu:",
    "answer_bot_i_have_another_question": "Saya punya pertanyaan lain 🔁",
    "answer_bot_im_not_done_yet": "Saya belum selesai 🔁",
    "answer_bot_keep_waiting": "Tidak, saya masih butuh bantuan 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Anda dapat menyusun ulang pertanyaan Anda, atau mendapatkan bantuan tambahan",
    "answer_bot_looping_first_no_answer": "Maaf, sebagai bot saya tidak bisa menemukan jawaban untuk itu.",
    "answer_bot_looping_first_no_answer_followup": "Anda dapat menyusun ulang pertanyaan Anda, atau menghubungi tim kami",
    "answer_bot_looping_no_answer": "Maaf, sebagai bot saya tidak bisa menemukan jawaban untuk itu. Inil yang dapat Anda lakukan ⬊",
    "answer_bot_more_answers": "Tunjukkan kepada saya lebih banyak 👀",
    "answer_bot_no_answer_found": "Maaf, kami tidak punya jawaban cepat untuk itu. Berikut beberapa opsi lainnya.",
    "answer_bot_resolve_followup": "Anda selalu dapat membalas di sini, jika ada hal lain yang muncul.",
    "answer_bot_talk_to_person": "Bicara dengan seseorang 👤",
    "answer_bot_that_answered_my_question": "Menjawab pertanyaan saya 👍",
    "answer_bot_thats_it": "Cukup 👍",
    "answer_bot_wait_for_the_team": "Tunggu tim 💬",
    "answerbot_ask_for_more_context": "Tentu saja. Sementara kita menunggu, dapatkah Anda membagikan lebih banyak detail tentang situasi atau pertanyaan Anda? Itu akan membantu kami menemukan jawaban dengan lebih cepat dan saya mungkin akan menemukan lebih banyak informasi untuk Anda.",
    "answerbot_introduce_sneaky_answer": "Terima kasih atas konteks tambahannya. Saya menemukan beberapa informasi baru yang mungkin dapat membantu Anda.",
    "article_search_hint_text": "Cari artikel...",
    "ask_a_question": "Ajukan pertanyaan",
    "ask_a_question_placeholder": "Ajukan pertanyaan…",
    "ask_a_question_with_ellipsis": "Ajukan pertanyaan...",
    "ask_another_question_with_ellipsis": "Ajukan pertanyaan lain...",
    "ask_for_the_team_if_needed": "Mintalah komunikasi dengan tim jika diperlukan",
    "ask_me_a_question": "Tanyakan kepada saya",
    "ask_our_bot_a_question": "Ajukan pertanyaan kepada bot kami",
    "avatar": "Avatar",
    "avatars": "Avatar",
    "bot": "Bot",
    "bot_answers_instantly": "Bot menjawab secara instan",
    "bot_expectation_text": "Bot kami dapat menjawab berbagai pertanyaan umum",
    "bot_intro_default_part_1": "Hai! Ini bot yang sedang berbicara. Saya hadir untuk menjawab pertanyaan Anda, tetapi Anda akan selalu memiliki pilihan untuk berbincang dengan tim kami.",
    "bot_intro_default_part_2": "Jadi, apa yang sekarang membawa Anda ke sini?",
    "bot_is_still_thinking": "Bot masih berpikir...",
    "bot_is_thinking": "Bot sedang berpikir...",
    "browse_collections": "Jelajahi koleksi",
    "chat_with_us": "Ngobrol dengan kami",
    "check_later_for_updates": "Periksa kembali nanti untuk pembaruan.",
    "checklist_card_first_step": "Langkah pertama",
    "checklist_card_next_step": "Langkah berikutnya",
    "checklist_completed": "Selesai",
    "checklist_in_progress_time_remaining_plural": "Sekitar {minutes} menit lagi",
    "checklist_in_progress_time_remaining_singular": "Sekitar 1 menit lagi",
    "checklist_mark_step_as_complete": "Tandai sebagai selesai",
    "checklist_not_started_time_remaining_plural": "Sekitar {minutes} menit",
    "checklist_not_started_time_remaining_singular": "Sekitar 1 menit",
    "checklist_number_of_steps_plural": "{number_of_steps} langkah",
    "checklist_number_of_steps_progress_plural": "{steps_completed} dari {total_steps} selesai",
    "checklist_number_of_steps_progress_singular": "1 dari {total_steps} selesai",
    "checklist_number_of_steps_singular": "1 langkah",
    "checklist_sender_name": "Dibagikan oleh {name}",
    "checklist_step_action_button": "Tombol tindakan langkah",
    "checklist_step_completed": "Selesai",
    "contact_support": "Hubungi dukungan",
    "contact_us": "Hubungi kami",
    "continue_the_conversation": "Lanjutkan percakapan",
    "conversation_card_office_hours_header": "Waktu kami kembali online",
    "conversation_card_reply_time_header": "Waktu balasan kami biasanya",
    "conversation_card_reply_time_header_m5": "Waktu balasan biasanya",
    "conversation_card_unknown_response_time": "Kami akan membalas secepatnya",
    "conversation_part_sample_text": "Hai, ada yang bisa saya bantu? Anda akan selalu memiliki opsi untuk berbicara dengan tim kami jika perlu.",
    "conversations_space_title": "Percakapan",
    "create_a_ticket": "Buat tiket",
    "currently_no_help_articles": "Saat ini tidak ada artikel bantuan yang tersedia.",
    "customer_sends_their_first_message": "Pelanggan mengirim pesan pertama mereka...",
    "expected_response_delay_10800": "kurang dari 3 jam",
    "expected_response_delay_120": "kurang dari 2 menit",
    "expected_response_delay_1200": "kurang dari 20 menit",
    "expected_response_delay_14400": "kurang dari 4 jam",
    "expected_response_delay_172800": "2 hari",
    "expected_response_delay_180": "kurang dari 3 menit",
    "expected_response_delay_1800": "kurang dari 30 menit",
    "expected_response_delay_18000": "kurang dari 5 jam",
    "expected_response_delay_21600": "kurang dari 6 jam",
    "expected_response_delay_240": "kurang dari 4 menit",
    "expected_response_delay_259200": "3 hari",
    "expected_response_delay_300": "kurang dari 5 menit",
    "expected_response_delay_345600": "4 hari",
    "expected_response_delay_3600": "kurang dari 1 jam",
    "expected_response_delay_43200": "kurang dari 12 jam",
    "expected_response_delay_432000": "5 hari",
    "expected_response_delay_518400": "6 hari",
    "expected_response_delay_60": "kurang dari 1 menit",
    "expected_response_delay_600": "kurang dari 10 menit",
    "expected_response_delay_604800": "kurang dari 1 minggu",
    "expected_response_delay_7200": "kurang dari 2 jam",
    "expected_response_delay_86400": "1 hari",
    "expected_response_delay_900": "kurang dari 15 menit",
    "expected_response_delay_unknown": "Secepat yang kami bisa",
    "extra_labels_count": "+ {labels_count} label",
    "for_best_results_provide_detail": "Untuk hasil terbaik, berikan detail sebanyak mungkin",
    "generating_answer": "Membuat jawaban...",
    "generic_triage_default": "Kami hanya perlu sedikit lebih banyak informasi untuk menghubungkan Anda dengan orang yang tepat",
    "get_an_instant_answer": "Dapatkan jawaban cepat",
    "get_help": "Dapatkan bantuan",
    "go_to": "Buka {app_name}",
    "help_space_title": "Bantuan",
    "home_header_mobile_hello_firstname": "Halo, {firstname}.",
    "home_header_mobile_hello_there": "Halo yang di sana",
    "home_space_title": "Layar utama",
    "how_can_we_help": "Bagaimana kami dapat membantu?",
    "how_would_you_rate_this": "Bagaimana Anda menilai ini?",
    "learn_more": "Pelajari lebih lanjut",
    "message_placeholder": "Pesan...",
    "messages_space_title": "Pesan",
    "messenger_bot_reply_time": "Bot kami akan langsung membalas",
    "messenger_settings_introduction": "Bagaimana kami dapat membantu?",
    "multiple_articles": "{total_articles} Artikel",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "Percakapan Baru",
    "news": "Berita",
    "news_empty_title": "Belum ada berita",
    "news_latest_subtitle": "Dari Tim {team_name}",
    "news_latest_title": "Terbaru",
    "news_older_title": "Lebih lama",
    "news_space_title": "Berita",
    "newsfeed": "Berita",
    "no_articles_to_display": "Belum ada artikel",
    "no_help_articles_to_display": "Tidak ada artikel bantuan",
    "no_results_for_searchterm": "Tidak ditemukan hasil pencarian untuk {searchTerm}",
    "no_tasks": "Tidak ada tugas",
    "no_tasks_available_currently": "Saat ini tidak ada tugas yang tersedia.",
    "ok_the_team_typically_replies_in_day": "Ok, tim biasanya menjawab dalam satu hari.",
    "ok_the_team_typically_replies_in_hours": "Ok, tim biasanya menjawab dalam beberapa jam.",
    "ok_the_team_typically_replies_in_minutes": "Ok, tim biasanya menjawab dalam beberapa menit.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, tim biasanya menjawab kurang dari {hours} jam.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, tim biasanya menjawab kurang dari {minutes} menit.",
    "ok_the_team_typically_replies_in_week": "Ok, tim biasanya menjawab dalam seminggu.",
    "our_bot_and_team_can_help": "Bot dan tim kami dapat membantu",
    "our_bot_answers_instantly": "Bot kami akan langsung menjawab",
    "powered_by_intercom": "Diprakarsai oleh Intercom",
    "previous_bot_hands_over": "Konten dari bot sebelumnya...",
    "proactive_expected_response_delay_10800": "Biasanya menjawab kurang dari 3 jam",
    "proactive_expected_response_delay_120": "Biasanya menjawab kurang dari 2 menit",
    "proactive_expected_response_delay_1200": "Biasanya menjawab kurang dari 20 menit",
    "proactive_expected_response_delay_14400": "Biasanya menjawab kurang dari 4 jam",
    "proactive_expected_response_delay_172800": "Biasanya menjawab dalam 2 hari",
    "proactive_expected_response_delay_180": "Biasanya menjawab kurang dari 3 menit",
    "proactive_expected_response_delay_1800": "Biasanya menjawab kurang dari 30 menit",
    "proactive_expected_response_delay_18000": "Biasanya menjawab kurang dari 5 jam",
    "proactive_expected_response_delay_21600": "Biasanya menjawab kurang dari 6 jam",
    "proactive_expected_response_delay_240": "Biasanya menjawab kurang dari 4 menit",
    "proactive_expected_response_delay_259200": "Biasanya menjawab dalam 3 hari",
    "proactive_expected_response_delay_300": "Biasanya menjawab kurang dari 5 menit",
    "proactive_expected_response_delay_345600": "Biasanya menjawab dalam 4 hari",
    "proactive_expected_response_delay_3600": "Biasanya menjawab kurang dari 1 jam",
    "proactive_expected_response_delay_43200": "Biasanya menjawab dalam 1 jam",
    "proactive_expected_response_delay_432000": "Biasanya menjawab dalam 5 hari",
    "proactive_expected_response_delay_518400": "Biasanya menjawab dalam 6 hari",
    "proactive_expected_response_delay_60": "Biasanya menjawab kurang dari 1 menit",
    "proactive_expected_response_delay_600": "Biasanya menjawab kurang dari 10 menit",
    "proactive_expected_response_delay_604800": "Biasanya menjawab kurang dari 1 minggu",
    "proactive_expected_response_delay_7200": "Biasanya menjawab kurang dari 2 jam",
    "proactive_expected_response_delay_86400": "Biasanya menjawab dalam 1 hari",
    "proactive_expected_response_delay_900": "Biasanya menjawab kurang dari 15 menit",
    "proactive_expected_response_delay_unknown": "Akan menjawab secepat mereka bisa",
    "profile_location": "{time} dalam {location}",
    "recent_ticket": "Tiket terbaru",
    "recent_tickets": "Tiket terbaru",
    "search_browse_empty_state_text": "Maaf, kami tidak dapat menemukan hasil apa pun untuk itu. Coba pakai kata lain untuk pencarian Anda.",
    "search_for_help": "Cari bantuan",
    "see_all": "Lihat semua",
    "see_all_conversations": "Lihat semua percakapan Anda",
    "see_previous_conversations": "Lihat percakapan Anda sebelumnya",
    "send": "Kirim",
    "send_a_message": "Kirim pesan…",
    "send_a_message_reply_time": "Waktu balasan biasanya adalah",
    "send_us_a_message": "Kirimi kami pesan",
    "set_expectations_bot_email_header": "Anda akan mendapatkan balasan di sini dan di email Anda:",
    "set_expectations_bot_office_hours_header": "Waktu tim kembali online:",
    "set_expectations_bot_sms_header": "Anda akan mendapatkan balasan di sini dan melalui SMS:",
    "set_expectations_replies_to_email": "Anda akan mendapatkan balasan di sini dan ke <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Anda akan mendapatkan balasan di sini dan ke {user_email}",
    "set_expectations_team_reply_asap": "Tim akan menjawab secepat yang mereka bisa",
    "shared_by": "Dibagikan oleh",
    "show_less": "Tampilkan lebih sedikit",
    "single_article": "1 Artikel",
    "single_collection": "1 collection",
    "source": "Sumber",
    "sources": "Sumber",
    "start_a_conversation": "Mulai percakapan",
    "start_conversation": "Mulai percakapan",
    "still_generating_answer": "Masih membuat jawaban...",
    "still_thinking": "Masih berpikir...",
    "still_working_on_it": "Masih mengerjakan...",
    "tasks_space_title": "Tugas",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} akan menjawab secepat yang mereka bisa",
    "teammate_changes_team_assignment_of_a_conversation": "Rekan satu tim telah mengubah tugas percakapan...",
    "tell_us_what_you_need": "Beri tahu kami bantuan apa yang Anda butuhkan dan bot kami akan melakukan yang terbaik untuk menjawabnya",
    "the_team_can_help_if_needed": "Tim dapat membantu bila diperlukan",
    "the_team_typically_replies_in_10800": "Tim biasanya menjawab kurang dari 3 jam",
    "the_team_typically_replies_in_120": "Tim biasanya menjawab kurang dari 2 menit",
    "the_team_typically_replies_in_1200": "Tim biasanya menjawab kurang dari 20 menit",
    "the_team_typically_replies_in_14400": "Tim biasanya menjawab kurang dari 4 jam",
    "the_team_typically_replies_in_172800": "Tim biasanya menjawab dalam 2 hari",
    "the_team_typically_replies_in_180": "Tim biasanya menjawab kurang dari 3 menit",
    "the_team_typically_replies_in_1800": "Tim biasanya menjawab kurang dari 30 menit",
    "the_team_typically_replies_in_18000": "Tim biasanya menjawab kurang dari 5 jam",
    "the_team_typically_replies_in_21600": "Tim biasanya menjawab kurang dari 6 jam",
    "the_team_typically_replies_in_240": "Tim biasanya menjawab kurang dari 4 menit",
    "the_team_typically_replies_in_259200": "Tim biasanya menjawab dalam 3 hari",
    "the_team_typically_replies_in_300": "Tim biasanya menjawab kurang dari 5 menit",
    "the_team_typically_replies_in_345600": "Tim biasanya menjawab dalam 4 hari",
    "the_team_typically_replies_in_3600": "Tim biasanya menjawab kurang dari 1 jam",
    "the_team_typically_replies_in_43200": "Tim biasanya menjawab kurang dari 12 jam",
    "the_team_typically_replies_in_432000": "Tim biasanya menjawab dalam 5 hari",
    "the_team_typically_replies_in_518400": "Tim biasanya menjawab dalam 6 hari",
    "the_team_typically_replies_in_60": "Tim biasanya menjawab kurang dari 1 menit",
    "the_team_typically_replies_in_600": "Tim biasanya menjawab kurang dari 10 menit",
    "the_team_typically_replies_in_604800": "Tim biasanya menjawab kurang dari 1 minggu",
    "the_team_typically_replies_in_7200": "Tim biasanya menjawab kurang dari 2 jam",
    "the_team_typically_replies_in_86400": "Tim biasanya menjawab dalam 1 hari",
    "the_team_typically_replies_in_900": "Tim biasanya menjawab kurang dari 15 menit",
    "the_team_typically_replies_in_day": "Tim biasanya menjawab dalam satu hari.",
    "the_team_typically_replies_in_hours": "Tim biasanya menjawab dalam beberapa jam.",
    "the_team_typically_replies_in_minutes": "Tim biasanya menjawab dalam beberapa menit.",
    "the_team_typically_replies_in_under_n_hours": "Tim biasanya menjawab kurang dari {hours} jam.",
    "the_team_typically_replies_in_under_n_min": "Tim biasanya menjawab kurang dari {minutes} menit.",
    "the_team_typically_replies_in_unknown": "Tim akan menjawab secepat yang mereka bisa",
    "the_team_typically_replies_in_week": "Tim biasanya menjawab dalam seminggu.",
    "thinking": "Sedang berpikir...",
    "tickets_cta_text": "Ada pertanyaan tentang tiket ini?",
    "tickets_have_a_question": "Ada pertanyaan tentang masalah ini?",
    "tickets_space_title": "Tiket",
    "time_future_1h": "1 jam",
    "time_future_2h": "2 jam",
    "time_future_30m": "30 menit",
    "time_future_3h": "3 jam",
    "time_future_day_0": "Senin",
    "time_future_day_1": "Selasa",
    "time_future_day_2": "Rabu",
    "time_future_day_3": "Kamis",
    "time_future_day_4": "Jumat",
    "time_future_day_5": "Sabtu",
    "time_future_day_6": "Minggu",
    "time_future_later_today": "Nanti hari ini",
    "time_future_next_week": "Minggu depan",
    "time_future_tomorrow": "Besok",
    "try_use_different_keywords": "Coba gunakan kata kunci yang berbeda atau ketik pertanyaan lengkap Anda",
    "upfront_collection_form_close_out": "Anda dapat kembali ke sini kapan saja untuk melihat respons dan mengirimkan pembaruan.",
    "upfront_collection_form_introduction": "Bagikan sedikit konteks untuk membantu tim merespons lebih baik dan lebih cepat.",
    "upfront_collection_form_submission_confirmation": "Terima kasih sudah membagikan konteksnya. Untuk menghemat waktu nanti, silahkan menambahkan detail lebih lanjut sekarang.",
    "upfront_collection_form_subtitle": "Kirim sedikit konteks kepada tim",
    "upfront_collection_form_subtitle_submitted": "Mereka akan mengingat konteks ini",
    "upfront_collection_form_title": "Dapatkan bantuan lebih cepat",
    "upfront_collection_form_title_submitted": "Dikirim ke tim!",
    "view_all": "Lihat semua",
    "view_conversation": "Lihat percakapan",
    "we_are_here_to_help": "Kami siap membantu",
    "we_run_on_intercom": "Kita menggunakan Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Saat rekan setim mengubah keadaan percakapan...",
    "working_on_it": "Sedang mengerjakan...",
    "you_are_all_up_to_date": "Anda sudah mengejar ketertinggalan!",
    "your_checklists": "Daftar periksa Anda",
    "your_conversations": "Percakapan Anda",
    "your_recent_conversations": "Percakapan terbaru",
    "your_recent_messages": "Pesan terbaru"
  },
  "it": {
    "a_day": "Un giorno",
    "a_few_hours": "Poche ore",
    "a_few_minutes": "Pochi minuti",
    "a_week": "Una settimana",
    "active_state": "Attivo",
    "ai_answer": "Risposta IA",
    "ai_answer_information": "Risposta dell'IA, informazioni",
    "ai_bot": "Bot IA",
    "almost_there": "Ci siamo quasi...",
    "answer": "Risposta",
    "answer_bot_acknowledge_duplicate_content": "Potresti averla già letta, ma questa è la risposta disponibile più pertinente.",
    "answer_bot_all_done": "La risposta è stata utile 👍",
    "answer_bot_ask_another_question": "Fai un'altra domanda 🔁",
    "answer_bot_ask_something_else": "Chiedi qualcos'altro 🔁",
    "answer_bot_connect_to_an_agent": "Mettiti in contatto con un agente 👤",
    "answer_bot_did_that_help": "Questa risposta è stata esauriente o ti serviva altro?",
    "answer_bot_followup_expectations_prefix": "Il team ti contatterà in merito alla tua richiesta.",
    "answer_bot_get_more_help": "Ottieni ulteriore assistenza 🌐",
    "answer_bot_get_more_help_followup": "Questo link potrebbe essere utile:",
    "answer_bot_i_have_another_question": "Ho un'altra domanda 🔁",
    "answer_bot_im_not_done_yet": "Non ho ancora finito 🔁",
    "answer_bot_keep_waiting": "No, ho ancora bisogno di aiuto 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Puoi riformulare la domanda o ottenere ulteriore assistenza",
    "answer_bot_looping_first_no_answer": "Mi dispiace, come bot non riesco a fornire alcuna risposta in merito.",
    "answer_bot_looping_first_no_answer_followup": "Puoi riformulare la domanda o rivolgerti al nostro team",
    "answer_bot_looping_no_answer": "Mi dispiace, come bot non riesco a fornire alcuna risposta in merito. Ecco cosa puoi fare",
    "answer_bot_more_answers": "Mostrami altre risposte 👀",
    "answer_bot_no_answer_found": "Siamo spiacenti, non abbiamo una risposta rapida a questo proposito. Ecco alcune opzioni alternative.",
    "answer_bot_resolve_followup": "Puoi sempre inviare un messaggio qui, nel caso avessi altre domande.",
    "answer_bot_talk_to_person": "Parla con una persona 👤",
    "answer_bot_that_answered_my_question": "Ha risposto alla mia domanda 👍",
    "answer_bot_thats_it": "Era quello che mi serviva 👍",
    "answer_bot_wait_for_the_team": "Attendi l'intervento del team 💬",
    "answerbot_ask_for_more_context": "Certo. Nell'attesa, potresti condividere maggiori dettagli sulla tua situazione o questione? Ci aiuterà a trovare una risposta più velocemente e potrei trovare più informazioni per te.",
    "answerbot_introduce_sneaky_answer": "Grazie per averci fornito più contesto. Ho trovato alcune informazioni che potrebbero esserti utili.",
    "article_search_hint_text": "Cerca articoli…",
    "ask_a_question": "Fai una domanda",
    "ask_a_question_placeholder": "Poni una domanda…",
    "ask_a_question_with_ellipsis": "Fai una domanda...",
    "ask_another_question_with_ellipsis": "Fai un'altra domanda...",
    "ask_for_the_team_if_needed": "Chiedi di ricevere assistenza dal team se necessario",
    "ask_me_a_question": "Fate una domanda",
    "ask_our_bot_a_question": "Fai una domanda al nostro bot",
    "avatar": "Avatar",
    "avatars": "Avatar",
    "bot": "Bot",
    "bot_answers_instantly": "Il bot risponde istantaneamente",
    "bot_expectation_text": "Il nostro bot può rispondere a molte domande comuni",
    "bot_intro_default_part_1": "Ciao! Stai parlando con un bot. Sono qui per rispondere alle tue domande, ma avrai sempre la possibilità di parlare con il nostro team.",
    "bot_intro_default_part_2": "Quindi, come posso aiutarti?",
    "bot_is_still_thinking": "Il bot sta ancora pensando...",
    "bot_is_thinking": "Il bot sta pensando...",
    "browse_collections": "Sfoglia le collezioni",
    "chat_with_us": "Chatta con noi",
    "check_later_for_updates": "Controlla in seguito per eventuali aggiornamenti.",
    "checklist_card_first_step": "Primo passaggio",
    "checklist_card_next_step": "Passaggio successivo",
    "checklist_completed": "Completata",
    "checklist_in_progress_time_remaining_plural": "Restano circa {minutes} minuti",
    "checklist_in_progress_time_remaining_singular": "Resta circa 1 minuto",
    "checklist_mark_step_as_complete": "Contrassegna come completato",
    "checklist_not_started_time_remaining_plural": "Circa {minutes} minuti",
    "checklist_not_started_time_remaining_singular": "Circa 1 minuto",
    "checklist_number_of_steps_plural": "{number_of_steps} passaggi",
    "checklist_number_of_steps_progress_plural": "{steps_completed} su {total_steps} completati",
    "checklist_number_of_steps_progress_singular": "1 su {total_steps} completato",
    "checklist_number_of_steps_singular": "1 passaggio",
    "checklist_sender_name": "Condivisa da {name}",
    "checklist_step_action_button": "Pulsante di azione del passaggio",
    "checklist_step_completed": "Completato",
    "contact_support": "Contatta l'assistenza",
    "contact_us": "Contattaci",
    "continue_the_conversation": "Continua la conversazione",
    "conversation_card_office_hours_header": "Quando torneremo online",
    "conversation_card_reply_time_header": "Il nostro tempo di risposta abituale",
    "conversation_card_reply_time_header_m5": "Tempo di risposta abituale",
    "conversation_card_unknown_response_time": "Risponderemo non appena possibile",
    "conversation_part_sample_text": "Ciao, come posso aiutarti? Avrai sempre la possibilità di parlare con il nostro team, se necessario.",
    "conversations_space_title": "Conversazioni",
    "create_a_ticket": "Crea un ticket",
    "currently_no_help_articles": "Al momento non sono disponibili articoli dell'assistenza.",
    "customer_sends_their_first_message": "Il cliente invia il suo primo messaggio…",
    "expected_response_delay_10800": "meno di 3 ore",
    "expected_response_delay_120": "meno di 2 minuti",
    "expected_response_delay_1200": "meno di 20 minuti",
    "expected_response_delay_14400": "meno di 4 ore",
    "expected_response_delay_172800": "2 giorni",
    "expected_response_delay_180": "meno di 3 minuti",
    "expected_response_delay_1800": "meno di 30 minuti",
    "expected_response_delay_18000": "meno di 5 ore",
    "expected_response_delay_21600": "meno di 6 ore",
    "expected_response_delay_240": "meno di 4 minuti",
    "expected_response_delay_259200": "3 giorni",
    "expected_response_delay_300": "meno di 5 minuti",
    "expected_response_delay_345600": "4 giorni",
    "expected_response_delay_3600": "meno di 1 ora",
    "expected_response_delay_43200": "meno di 12 ore",
    "expected_response_delay_432000": "5 giorni",
    "expected_response_delay_518400": "6 giorni",
    "expected_response_delay_60": "meno di 1 minuto",
    "expected_response_delay_600": "meno di 10 minuti",
    "expected_response_delay_604800": "meno di 1 settimana",
    "expected_response_delay_7200": "meno di 2 ore",
    "expected_response_delay_86400": "1 giorno",
    "expected_response_delay_900": "meno di 15 minuti",
    "expected_response_delay_unknown": "Non appena possibile",
    "extra_labels_count": "+ {labels_count} etichette",
    "for_best_results_provide_detail": "Per ottenere i migliori risultati, fornisci il maggior numero di dettagli possibile",
    "generating_answer": "Sto generando una risposta...",
    "generic_triage_default": "Ci occorre solo qualche informazione in più per metterti in contatto con la persona giusta",
    "get_an_instant_answer": "Ottieni una risposta immediata",
    "get_help": "Ricevi aiuto",
    "go_to": "Vai a {app_name}",
    "help_space_title": "Assistenza",
    "home_header_mobile_hello_firstname": "Ciao, {firstname}.",
    "home_header_mobile_hello_there": "Ciao",
    "home_space_title": "Home",
    "how_can_we_help": "Come possiamo aiutarti?",
    "how_would_you_rate_this": "Che valutazione daresti?",
    "learn_more": "Maggiori informazioni",
    "message_placeholder": "Scrivi un messaggio...",
    "messages_space_title": "Messaggi",
    "messenger_bot_reply_time": "Il nostro bot risponderà immediatamente",
    "messenger_settings_introduction": "Come possiamo aiutarti?",
    "multiple_articles": "{total_articles} Articles",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "Nuova conversazione",
    "news": "Novità",
    "news_empty_title": "No news yet",
    "news_latest_subtitle": "From Team {team_name}",
    "news_latest_title": "Ultime notizie",
    "news_older_title": "Older",
    "news_space_title": "Novità",
    "newsfeed": "Notizie",
    "no_articles_to_display": "Nessun articolo da mostrare",
    "no_help_articles_to_display": "Nessun articolo dell'assistenza",
    "no_results_for_searchterm": "Nessun risultato per {searchTerm}",
    "no_tasks": "Nessuna attività",
    "no_tasks_available_currently": "Al momento, non esistono attività disponibili.",
    "ok_the_team_typically_replies_in_day": "OK, il team solitamente risponde in un giorno.",
    "ok_the_team_typically_replies_in_hours": "OK, il team solitamente risponde entro qualche ora.",
    "ok_the_team_typically_replies_in_minutes": "OK, il team solitamente risponde dopo qualche minuto.",
    "ok_the_team_typically_replies_in_under_n_hours": "OK, il team solitamente risponde in meno di {hours} h.",
    "ok_the_team_typically_replies_in_under_n_min": "OK, il team solitamente risponde in meno di {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Ok, il team di solito risponde entro una settimana.",
    "our_bot_and_team_can_help": "Il nostro bot e il nostro team possono aiutarti",
    "our_bot_answers_instantly": "Il nostro bot risponde immediatamente",
    "powered_by_intercom": "Con tecnologia Intercom",
    "previous_bot_hands_over": "Contenuto dal bot precedente…",
    "proactive_expected_response_delay_10800": "Solitamente risponde in meno di 3 h",
    "proactive_expected_response_delay_120": "Solitamente risponde in meno di 2 minuti",
    "proactive_expected_response_delay_1200": "Solitamente risponde in meno di 20 min",
    "proactive_expected_response_delay_14400": "Solitamente risponde in meno di 4 h",
    "proactive_expected_response_delay_172800": "Solitamente risponde in 2 gg",
    "proactive_expected_response_delay_180": "Solitamente risponde in meno di 3 minuti",
    "proactive_expected_response_delay_1800": "Solitamente risponde in meno di 30 min",
    "proactive_expected_response_delay_18000": "Solitamente risponde in meno di 5 h",
    "proactive_expected_response_delay_21600": "Solitamente risponde in meno di 6 h",
    "proactive_expected_response_delay_240": "Solitamente risponde in meno di 4 minuti",
    "proactive_expected_response_delay_259200": "Solitamente risponde entro 3 giorni",
    "proactive_expected_response_delay_300": "Solitamente risponde in meno di 5 min",
    "proactive_expected_response_delay_345600": "Solitamente risponde entro 4 giorni",
    "proactive_expected_response_delay_3600": "Solitamente risponde in meno di 1 h",
    "proactive_expected_response_delay_43200": "Solitamente risponde entro 12 ore",
    "proactive_expected_response_delay_432000": "Solitamente risponde entro 5 giorni",
    "proactive_expected_response_delay_518400": "Solitamente risponde entro 6 giorni",
    "proactive_expected_response_delay_60": "Solitamente risponde in meno di 1 minuto",
    "proactive_expected_response_delay_600": "Solitamente risponde in meno di 10 min",
    "proactive_expected_response_delay_604800": "Solitamente risponde in meno di 1 sett",
    "proactive_expected_response_delay_7200": "Solitamente risponde in meno di 2 h",
    "proactive_expected_response_delay_86400": "Solitamente risponde in 1 g",
    "proactive_expected_response_delay_900": "Solitamente risponde in meno di 15 minuti",
    "proactive_expected_response_delay_unknown": "Risponderà non appena possibile",
    "profile_location": "{time} a {location}",
    "recent_ticket": "Ticket recente",
    "recent_tickets": "Ticket recenti",
    "search_browse_empty_state_text": "Spiacenti, non siamo riusciti a trovare risultati pertinenti. Prova a riformulare la ricerca.",
    "search_for_help": "Trova risposte",
    "see_all": "Vedi tutte",
    "see_all_conversations": "Visualizza tutte le tue conversazioni",
    "see_previous_conversations": "Visualizza le tue conversazioni precedenti",
    "send": "Invia",
    "send_a_message": "Invia un messaggio…",
    "send_a_message_reply_time": "Il tempo di risposta abituale è di",
    "send_us_a_message": "Inviaci un messaggio",
    "set_expectations_bot_email_header": "Riceverai le risposte qui e via e-mail:",
    "set_expectations_bot_office_hours_header": "Quando il team tornerà:",
    "set_expectations_bot_sms_header": "Riceverai le risposte qui e via SMS:",
    "set_expectations_replies_to_email": "Riceverai le risposte qui e all'indirizzo <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Riceverai le risposte qui e all'indirizzo {user_email}",
    "set_expectations_team_reply_asap": "Il team risponderà nel più breve tempo possibile.",
    "shared_by": "Condiviso da",
    "show_less": "Mostra meno",
    "single_article": "1 Article",
    "single_collection": "1 collection",
    "source": "Origine",
    "sources": "Fonti",
    "start_a_conversation": "Inizia una conversazione",
    "start_conversation": "Inizia conversazione",
    "still_generating_answer": "Sto ancora generando una risposta...",
    "still_thinking": "Sto ancora pensando...",
    "still_working_on_it": "Ci sto ancora lavorando...",
    "tasks_space_title": "Tasks",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} risponderà non appena possibile",
    "teammate_changes_team_assignment_of_a_conversation": "Un membro del team ha modificato l'assegnazione della conversazione...",
    "tell_us_what_you_need": "Spiegaci di cosa hai bisogno e il nostro bot farà il possibile per aiutarti",
    "the_team_can_help_if_needed": "Il team può aiutarti in caso di necessità",
    "the_team_typically_replies_in_10800": "Il team solitamente risponde in meno di 3 h",
    "the_team_typically_replies_in_120": "Il team solitamente risponde in meno di 2 minuti.",
    "the_team_typically_replies_in_1200": "Il team solitamente risponde in meno di 20 min.",
    "the_team_typically_replies_in_14400": "Il team solitamente risponde in meno di 4 h",
    "the_team_typically_replies_in_172800": "Il team solitamente risponde in 2 gg",
    "the_team_typically_replies_in_180": "Il team solitamente risponde in meno di 3 minuti",
    "the_team_typically_replies_in_1800": "Il team solitamente risponde in meno di 30 min.",
    "the_team_typically_replies_in_18000": "Il team solitamente risponde in meno di 5 h",
    "the_team_typically_replies_in_21600": "Il team solitamente risponde in meno di 6 h",
    "the_team_typically_replies_in_240": "Il team solitamente risponde in meno di 4 minuti.",
    "the_team_typically_replies_in_259200": "Il team solitamente risponde entro 3 giorni",
    "the_team_typically_replies_in_300": "Il team solitamente risponde in meno di 5 min.",
    "the_team_typically_replies_in_345600": "Il team solitamente risponde entro 4 giorni",
    "the_team_typically_replies_in_3600": "Il team solitamente risponde in meno di 1 h",
    "the_team_typically_replies_in_43200": "Il team solitamente risponde in meno di 12 ore",
    "the_team_typically_replies_in_432000": "Il team solitamente risponde entro 5 giorni",
    "the_team_typically_replies_in_518400": "Il team solitamente risponde entro 6 giorni",
    "the_team_typically_replies_in_60": "Il team solitamente risponde in meno di 1 minuto.",
    "the_team_typically_replies_in_600": "Il team solitamente risponde in meno di 10 min.",
    "the_team_typically_replies_in_604800": "Il team solitamente risponde in meno di 1 sett",
    "the_team_typically_replies_in_7200": "Il team solitamente risponde in meno di 2 h",
    "the_team_typically_replies_in_86400": "Il team solitamente risponde in 1 gg",
    "the_team_typically_replies_in_900": "Il team solitamente risponde in meno di 15 minuti",
    "the_team_typically_replies_in_day": "Il team solitamente risponde in un giorno.",
    "the_team_typically_replies_in_hours": "Il team solitamente risponde entro qualche ora.",
    "the_team_typically_replies_in_minutes": "Il team solitamente risponde dopo qualche minuto.",
    "the_team_typically_replies_in_under_n_hours": "Il team solitamente risponde in meno di {hours} h.",
    "the_team_typically_replies_in_under_n_min": "Il team solitamente risponde in meno di {minutes} min.",
    "the_team_typically_replies_in_unknown": "Il team risponderà non appena possibile",
    "the_team_typically_replies_in_week": "Il team di solito risponde entro una settimana.",
    "thinking": "Sto pensando...",
    "tickets_cta_text": "Hai una domanda relativa a questo ticket?",
    "tickets_have_a_question": "Hai una domanda relativa a questo problema?",
    "tickets_space_title": "Ticket",
    "time_future_1h": "1 ora",
    "time_future_2h": "2 ore",
    "time_future_30m": "30 minuti",
    "time_future_3h": "3 ore",
    "time_future_day_0": "Lunedì",
    "time_future_day_1": "Martedì",
    "time_future_day_2": "Mercoledì",
    "time_future_day_3": "Giovedì",
    "time_future_day_4": "Venerdì",
    "time_future_day_5": "Sabato",
    "time_future_day_6": "Domenica",
    "time_future_later_today": "Entro oggi",
    "time_future_next_week": "La prossima settimana",
    "time_future_tomorrow": "Domani",
    "try_use_different_keywords": "Prova a utilizzare parole chiave diverse o digita la domanda completa",
    "upfront_collection_form_close_out": "Puoi tornare qui in qualsiasi momento per leggere le risposte e inviare aggiornamenti.",
    "upfront_collection_form_introduction": "Condividi una descrizione del contesto per aiutare il team a rispondere meglio e più velocemente.",
    "upfront_collection_form_submission_confirmation": "Grazie per aver inviato una descrizione del contesto. Se in futuro vuoi risparmiare un po' di tempo, puoi aggiungere subito altri dettagli.",
    "upfront_collection_form_subtitle": "Invia al team una descrizione del contesto",
    "upfront_collection_form_subtitle_submitted": "Il team terrà in considerazione questa descrizione del contesto",
    "upfront_collection_form_title": "Ricevi aiuto più velocemente",
    "upfront_collection_form_title_submitted": "Inviato al team!",
    "view_all": "Visualizza tutto",
    "view_conversation": "Visualizza conversazione",
    "we_are_here_to_help": "Siamo a tua disposizione",
    "we_run_on_intercom": "Disponibile su Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Quando un membro del team cambia lo stato di una conversazione…",
    "working_on_it": "Ci sto lavorando...",
    "you_are_all_up_to_date": "Hai letto tutti gli aggiornamenti.",
    "your_checklists": "Le tue checklist",
    "your_conversations": "Le tue conversazioni",
    "your_recent_conversations": "Conversazioni recenti",
    "your_recent_messages": "Messaggi recenti"
  },
  "ja": {
    "a_day": "1日",
    "a_few_hours": "数時間",
    "a_few_minutes": "数分",
    "a_week": "1週間",
    "active_state": "アクティブ",
    "ai_answer": "AIによる回答",
    "ai_answer_information": "AIによる回答と情報",
    "ai_bot": "AIボット",
    "almost_there": "まもなく回答します...",
    "answer": "回答",
    "answer_bot_acknowledge_duplicate_content": "すでにご覧になっているかと思いますが、これがベストアンサーになります。",
    "answer_bot_all_done": "役に立った 👍",
    "answer_bot_ask_another_question": "別の質問をする 🔁",
    "answer_bot_ask_something_else": "他の質問をする 🔁",
    "answer_bot_connect_to_an_agent": "エージェントに接続する 👤",
    "answer_bot_did_that_help": "この回答は役に立ちましたでしょうか。引き続き別の回答をお探しですか。",
    "answer_bot_followup_expectations_prefix": "この件について、チームからご連絡させていただきます。",
    "answer_bot_get_more_help": "さらにサポートを受ける 🌐",
    "answer_bot_get_more_help_followup": "このリンクがお役に立つかもしれません:",
    "answer_bot_i_have_another_question": "他にも質問があります 🔁",
    "answer_bot_im_not_done_yet": "まだ疑問は解決していません 🔁",
    "answer_bot_keep_waiting": "追加のサポートが必要です👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "質問を言い換えるか、追加のヘルプをご利用ください",
    "answer_bot_looping_first_no_answer": "申し訳ございません。Bot は回答することができません。",
    "answer_bot_looping_first_no_answer_followup": "質問を言い換えるか、当社のチームとお話しください",
    "answer_bot_looping_no_answer": "申し訳ございません。Bot は回答することができません。こちらの方法が可能です",
    "answer_bot_more_answers": "他の回答を見る 👀",
    "answer_bot_no_answer_found": "申し訳ございません。今すぐ回答することができません。こちらにあるその他のオプションをお試しください。",
    "answer_bot_resolve_followup": "何かあれば、いつでもここにご回答いただけます。",
    "answer_bot_talk_to_person": "人間と話す👤",
    "answer_bot_that_answered_my_question": "疑問が解決しました 👍",
    "answer_bot_thats_it": "以上です 👍",
    "answer_bot_wait_for_the_team": "チームを待つ 💬",
    "answerbot_ask_for_more_context": "わかりました。待っている間、あなたの状況や質問について詳しく教えていただけませんか？教えていただければ、より迅速に対応できるほか、さらに詳しい情報が見つかる可能性がございます。",
    "answerbot_introduce_sneaky_answer": "詳しい情報をいただきありがとうございます。お役に立ちそうな新しい情報が見つかりました。",
    "article_search_hint_text": "記事を検索...",
    "ask_a_question": "質問する",
    "ask_a_question_placeholder": "質問する...",
    "ask_a_question_with_ellipsis": "質問してください...",
    "ask_another_question_with_ellipsis": "別の質問をしてください...",
    "ask_for_the_team_if_needed": "必要であればチームにお問い合わせください",
    "ask_me_a_question": "質問してください",
    "ask_our_bot_a_question": "ボットに質問する",
    "avatar": "アバター",
    "avatars": "アバター",
    "bot": "Bot",
    "bot_answers_instantly": "ボットがすぐに回答します",
    "bot_expectation_text": "よくある一般的な質問にボットがお答えします",
    "bot_intro_default_part_1": "こんにちは！Bot が対応いたします。私がご質問にお答えできるよう努めますが、弊社のチームと話すオプションも常にご利用になれます。",
    "bot_intro_default_part_2": "今日はどうされましたか？",
    "bot_is_still_thinking": "ボットはまだ考えています...",
    "bot_is_thinking": "ボットは考えています...",
    "browse_collections": "コレクションを見る",
    "chat_with_us": "チャットを開始",
    "check_later_for_updates": "後ほど更新をご確認ください。",
    "checklist_card_first_step": "最初のステップ",
    "checklist_card_next_step": "次のステップ",
    "checklist_completed": "完了",
    "checklist_in_progress_time_remaining_plural": "残り約{minutes}分",
    "checklist_in_progress_time_remaining_singular": "残り約1分",
    "checklist_mark_step_as_complete": "完了としてマーク",
    "checklist_not_started_time_remaining_plural": "約{minutes}分",
    "checklist_not_started_time_remaining_singular": "約1分",
    "checklist_number_of_steps_plural": "{number_of_steps}ステップ",
    "checklist_number_of_steps_progress_plural": "{steps_completed}/{total_steps}完了",
    "checklist_number_of_steps_progress_singular": "1/{total_steps}完了",
    "checklist_number_of_steps_singular": "1ステップ",
    "checklist_sender_name": "{name}さんが共有",
    "checklist_step_action_button": "ステップアクションボタン",
    "checklist_step_completed": "完了",
    "contact_support": "サポートに連絡",
    "contact_us": "お問い合わせ",
    "continue_the_conversation": "会話を続行",
    "conversation_card_office_hours_header": "営業再開日",
    "conversation_card_reply_time_header": "通常の返信までの時間",
    "conversation_card_reply_time_header_m5": "通常の返信までの時間",
    "conversation_card_unknown_response_time": "できるだけ早く返信いたします",
    "conversation_part_sample_text": "こんにちは。お困りですか？必要に応じて、いつでも当社のチームにご相談いただけます。",
    "conversations_space_title": "会話",
    "create_a_ticket": "チケットを作成",
    "currently_no_help_articles": "現在ヘルプ記事はありません。",
    "customer_sends_their_first_message": "顧客が最初のメッセージを送信します...",
    "expected_response_delay_10800": "3時間以内",
    "expected_response_delay_120": "2分以内",
    "expected_response_delay_1200": "20分以内",
    "expected_response_delay_14400": "4時間以内",
    "expected_response_delay_172800": "2日",
    "expected_response_delay_180": "3分以内",
    "expected_response_delay_1800": "30分以内",
    "expected_response_delay_18000": "5時間以内",
    "expected_response_delay_21600": "6時間以内",
    "expected_response_delay_240": "4分以内",
    "expected_response_delay_259200": "3日",
    "expected_response_delay_300": "5分以内",
    "expected_response_delay_345600": "4日",
    "expected_response_delay_3600": "1時間以内",
    "expected_response_delay_43200": "12時間以内",
    "expected_response_delay_432000": "5日",
    "expected_response_delay_518400": "6日",
    "expected_response_delay_60": "1分以内",
    "expected_response_delay_600": "10分以内",
    "expected_response_delay_604800": "1週間以内",
    "expected_response_delay_7200": "2時間以内",
    "expected_response_delay_86400": "1日",
    "expected_response_delay_900": "15分以内",
    "expected_response_delay_unknown": "出来る限り早く",
    "extra_labels_count": "+ {labels_count} 件のラベル",
    "for_best_results_provide_detail": "できるだけ多くの情報を提供することで、AIを最大限に活かすことができます",
    "generating_answer": "回答を生成しています...",
    "generic_triage_default": "適切な担当者におつなぎするために、もう少し情報が必要です",
    "get_an_instant_answer": "今すぐ回答を見つけましょう",
    "get_help": "ヘルプを探す",
    "go_to": "{app_name}に移動",
    "help_space_title": "ヘルプ",
    "home_header_mobile_hello_firstname": "こんにちは、{firstname}さん。",
    "home_header_mobile_hello_there": "こんにちは",
    "home_space_title": "ホーム",
    "how_can_we_help": "お困りですか？",
    "how_would_you_rate_this": "こちらのニュースはいかがでしたか？",
    "learn_more": "詳細を見る",
    "message_placeholder": "メッセージ...",
    "messages_space_title": "メッセージ",
    "messenger_bot_reply_time": "ボットが即座に返信します",
    "messenger_settings_introduction": "お困りですか？",
    "multiple_articles": "{total_articles}件の記事",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "新しい会話",
    "news": "ニュース",
    "news_empty_title": "ニュースはまだありません",
    "news_latest_subtitle": "チーム{team_name}より",
    "news_latest_title": "最新",
    "news_older_title": "以前のニュース",
    "news_space_title": "ニュース",
    "newsfeed": "ニュースフィード",
    "no_articles_to_display": "記事はまだありません",
    "no_help_articles_to_display": "ヘルプ記事はありません",
    "no_results_for_searchterm": "{searchTerm}の結果はありません",
    "no_tasks": "タスクなし",
    "no_tasks_available_currently": "現在、利用可能なタスクはありません。",
    "ok_the_team_typically_replies_in_day": "承知しました。サポートチームが通常24時間以内に返信いたします。",
    "ok_the_team_typically_replies_in_hours": "承知しました。チームより通常数時間以内に返信いたします。",
    "ok_the_team_typically_replies_in_minutes": "承知しました。チームより通常数分以内に返信いたします。",
    "ok_the_team_typically_replies_in_under_n_hours": "承知しました。チームより通常{hours}時間以内に返信いたします。",
    "ok_the_team_typically_replies_in_under_n_min": "承知しました。チームより通常{minutes}分以内に返信いたします。",
    "ok_the_team_typically_replies_in_week": "承知しました。サポートチームが通常1週間以内に返信いたします。",
    "our_bot_and_team_can_help": "弊社のボットとチームにご相談ください",
    "our_bot_answers_instantly": "ボットが即座に回答します",
    "powered_by_intercom": "私たちはIntercomを使っています",
    "previous_bot_hands_over": "前の Bot からのコンテンツ...",
    "proactive_expected_response_delay_10800": "通常3時間以内に返信いたします",
    "proactive_expected_response_delay_120": "通常2分以内に返信いたします",
    "proactive_expected_response_delay_1200": "通常20分以内に返信いたします",
    "proactive_expected_response_delay_14400": "通常4時間以内に返信いたします",
    "proactive_expected_response_delay_172800": "通常2日以内に返信いたします",
    "proactive_expected_response_delay_180": "通常3分以内に返信いたします",
    "proactive_expected_response_delay_1800": "通常30分以内に返信いたします",
    "proactive_expected_response_delay_18000": "通常5時間以内に返信いたします",
    "proactive_expected_response_delay_21600": "通常6時間以内に返信いたします",
    "proactive_expected_response_delay_240": "通常4分以内に返信いたします",
    "proactive_expected_response_delay_259200": "通常3日以内に返信いたします",
    "proactive_expected_response_delay_300": "通常5分以内に返信いたします",
    "proactive_expected_response_delay_345600": "通常4日以内に返信いたします",
    "proactive_expected_response_delay_3600": "通常1時間以内に返信いたします",
    "proactive_expected_response_delay_43200": "通常12時間以内に返信いたします",
    "proactive_expected_response_delay_432000": "通常5日以内に返信いたします",
    "proactive_expected_response_delay_518400": "通常6日以内に返信いたします",
    "proactive_expected_response_delay_60": "通常1分以内に返信いたします",
    "proactive_expected_response_delay_600": "が通常10分以内にご返信いたしますので、少々お待ち下さい。",
    "proactive_expected_response_delay_604800": "通常1週間以内に返信いたします",
    "proactive_expected_response_delay_7200": "通常2時間以内に返信いたします",
    "proactive_expected_response_delay_86400": "通常1日以内に返信いたします",
    "proactive_expected_response_delay_900": "通常15分以内に返信いたします",
    "proactive_expected_response_delay_unknown": "出来る限り早く返信いたします",
    "profile_location": "{time} 、 {location}",
    "recent_ticket": "最近のチケット",
    "recent_tickets": "最近のチケット",
    "search_browse_empty_state_text": "申し訳ありません。該当する結果は見つかりませんでした。検索条件を変更してみてください。",
    "search_for_help": "ヘルプの検索",
    "see_all": "すべてを見る",
    "see_all_conversations": "すべての会話を表示",
    "see_previous_conversations": "以前の会話を表示",
    "send": "送信",
    "send_a_message": "メッセージを送信…",
    "send_a_message_reply_time": "通常の返信までの時間は",
    "send_us_a_message": "会話を始める",
    "set_expectations_bot_email_header": "返信はこちらの他に、メールでも届きます：",
    "set_expectations_bot_office_hours_header": "チームが次のスケジュールでご用件を伺います：",
    "set_expectations_bot_sms_header": "返信はこちらの他に、SMSでも届きます：",
    "set_expectations_replies_to_email": "返信は、こちらと<b>{user_email}</b>に届きます。",
    "set_expectations_replies_to_email_without_bold_text": "返信は、こちらと {user_email} に届きます。",
    "set_expectations_team_reply_asap": "出来る限り早くご返信いたします。",
    "shared_by": "投稿者：",
    "show_less": "表示を少なくする",
    "single_article": "1件の記事",
    "single_collection": "1 collection",
    "source": "ソース",
    "sources": "ソース",
    "start_a_conversation": "会話を開始",
    "start_conversation": "会話を開始",
    "still_generating_answer": "まだ回答を生成しています...",
    "still_thinking": "まだ考えています...",
    "still_working_on_it": "まだ考えをまとめています...",
    "tasks_space_title": "タスク",
    "team_name_proactive_expected_response_delay_unknown": "{app_name}が出来る限り早く返信いたします",
    "teammate_changes_team_assignment_of_a_conversation": "チームメイトが会話の割り当てを変更しました...",
    "tell_us_what_you_need": "サポートをご希望の内容をお教えください。当社の Bot が回答します",
    "the_team_can_help_if_needed": "必要に応じてチームがお手伝いします",
    "the_team_typically_replies_in_10800": "チームより通常3時間以内に返信いたします",
    "the_team_typically_replies_in_120": "チームより通常2分以内に返信いたします",
    "the_team_typically_replies_in_1200": "チームより通常20分以内に返信いたします",
    "the_team_typically_replies_in_14400": "チームより通常4時間以内に返信いたします",
    "the_team_typically_replies_in_172800": "チームより通常2日以内に返信いたします",
    "the_team_typically_replies_in_180": "チームより通常3分以内に返信いたします",
    "the_team_typically_replies_in_1800": "チームより通常30分以内に返信いたします",
    "the_team_typically_replies_in_18000": "チームより通常5時間以内に返信いたします",
    "the_team_typically_replies_in_21600": "チームより通常6時間以内に返信いたします",
    "the_team_typically_replies_in_240": "チームより通常4分以内に返信いたします",
    "the_team_typically_replies_in_259200": "チームより通常3日以内に返信いたします",
    "the_team_typically_replies_in_300": "チームより通常5分以内に返信いたします",
    "the_team_typically_replies_in_345600": "チームより通常4日以内に返信いたします",
    "the_team_typically_replies_in_3600": "チームより通常1時間以内に返信いたします",
    "the_team_typically_replies_in_43200": "チームより通常12時間以内に返信いたします",
    "the_team_typically_replies_in_432000": "チームより通常5日以内に返信いたします",
    "the_team_typically_replies_in_518400": "チームより通常6日以内に返信いたします",
    "the_team_typically_replies_in_60": "チームより通常1分以内に返信いたします",
    "the_team_typically_replies_in_600": "チームより通常10分以内に返信いたします",
    "the_team_typically_replies_in_604800": "チームより通常1週間以内に返信いたします",
    "the_team_typically_replies_in_7200": "チームより通常2時間以内に返信いたします",
    "the_team_typically_replies_in_86400": "サポートチームが通常24時間以内に返信いたします",
    "the_team_typically_replies_in_900": "チームより通常15分以内に返信いたします",
    "the_team_typically_replies_in_day": "サポートチームが通常24時間以内に返信いたします",
    "the_team_typically_replies_in_hours": "サポートチームが通常数時間以内に返信いたしますので、しばらくの間お待ちください。",
    "the_team_typically_replies_in_minutes": "チームより通常数分以内にご返信いたしますので、少々お待ちください。",
    "the_team_typically_replies_in_under_n_hours": "チームより通常{hours}時間以内に返信いたします。",
    "the_team_typically_replies_in_under_n_min": "チームより通常{minutes}分以内に返信いたします。",
    "the_team_typically_replies_in_unknown": "出来る限り早く返信いたします",
    "the_team_typically_replies_in_week": "サポートチームが通常1週間以内に返信いたします。",
    "thinking": "考えています...",
    "tickets_cta_text": "このチケットについてご質問がありますか？",
    "tickets_have_a_question": "この問題についてご質問がありますか？",
    "tickets_space_title": "チケット",
    "time_future_1h": "1時間",
    "time_future_2h": "2時間",
    "time_future_30m": "30分",
    "time_future_3h": "3時間",
    "time_future_day_0": "月曜日",
    "time_future_day_1": "火曜日",
    "time_future_day_2": "水曜日",
    "time_future_day_3": "木曜日",
    "time_future_day_4": "金曜日",
    "time_future_day_5": "土曜日",
    "time_future_day_6": "日曜日",
    "time_future_later_today": "本日中",
    "time_future_next_week": "来週",
    "time_future_tomorrow": "明日",
    "try_use_different_keywords": "別のキーワードを使用するか、質問の全文を入力してみてください",
    "upfront_collection_form_close_out": "回答の確認や最新情報の送信は、いつでもこちらからおこなえます。",
    "upfront_collection_form_introduction": "チームがより効果的かつ迅速に対応できるように、コンテキストを共有しましょう。",
    "upfront_collection_form_submission_confirmation": "コンテキストを送信いただきありがとうございます。今詳細を追加いただくと、後ほど時間の節約つながります。",
    "upfront_collection_form_subtitle": "チームにコンテキストを送信する",
    "upfront_collection_form_subtitle_submitted": "コンテキストを参考にさせていただきます",
    "upfront_collection_form_title": "より早くヘルプを得ましょう",
    "upfront_collection_form_title_submitted": "チームに送信しました！",
    "view_all": "すべて表示",
    "view_conversation": "会話を表示",
    "we_are_here_to_help": "遠慮なくお問い合わせください",
    "we_run_on_intercom": "私たちはIntercomを使っています",
    "when_a_teammate_changes_the_state_of_a_conversation": "チームメイトが会話の状態を変更した場合...",
    "working_on_it": "考えをまとめています...",
    "you_are_all_up_to_date": "すべてのニュースを確認しました！",
    "your_checklists": "チェックリスト",
    "your_conversations": "あなたの会話",
    "your_recent_conversations": "最近の会話",
    "your_recent_messages": "最近のメッセージ"
  },
  "ko": {
    "a_day": "1일",
    "a_few_hours": "몇 시간",
    "a_few_minutes": "몇 분",
    "a_week": "주간",
    "active_state": "연결 가능",
    "ai_answer": "AI 답변",
    "ai_answer_information": "AI 답변, 정보",
    "ai_bot": "AI 봇",
    "almost_there": "거의 다 됐어요...",
    "answer": "답변",
    "answer_bot_acknowledge_duplicate_content": "이미 보셨겠지만 이것이 가장 좋은 답변입니다.",
    "answer_bot_all_done": "도움이 되었습니다 👍",
    "answer_bot_ask_another_question": "다른 질문 하기 🔁",
    "answer_bot_ask_something_else": "다른 질문하기 🔁",
    "answer_bot_connect_to_an_agent": "담당자에게 연결 👤",
    "answer_bot_did_that_help": "답변이 도움이 되셨나요? 아니면 다른 것을 찾고 계십니까?",
    "answer_bot_followup_expectations_prefix": "이와 관련하여 팀에서 곧 연락을 드릴 것입니다.",
    "answer_bot_get_more_help": "추가 지원 받기 🌐",
    "answer_bot_get_more_help_followup": "다음 링크가 도움이 될 수 있습니다:",
    "answer_bot_i_have_another_question": "다른 질문이 있습니다 🔁",
    "answer_bot_im_not_done_yet": "아직 하지 않았습니다 🔁",
    "answer_bot_keep_waiting": "아니요, 여전히 도움이 필요합니다 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "질문을 다시 작성하거나 추가로 도움을 받으실 수 있습니다",
    "answer_bot_looping_first_no_answer": "봇이 답변을 찾을 수 없습니다.",
    "answer_bot_looping_first_no_answer_followup": "질문을 다시 작성하거나 팀과 대화할 수 있습니다",
    "answer_bot_looping_no_answer": "죄송합니다. 봇이 답변을 찾을 수 없습니다. 가능한 작업은 다음과 같습니다",
    "answer_bot_more_answers": "자세히 보여주세요 👀",
    "answer_bot_no_answer_found": "질문에 대한 간략한 답변이 없습니다. 몇 가지 다른 옵션을 사용해 보십시오.",
    "answer_bot_resolve_followup": "다른 요구 사항이 있는 경우 언제든 여기에 회신하실 수 있습니다.",
    "answer_bot_talk_to_person": "사람과 대화하기 👤",
    "answer_bot_that_answered_my_question": "내 질문에 대한 답변이 되었습니다 👍",
    "answer_bot_thats_it": "바로 그겁니다 👍",
    "answer_bot_wait_for_the_team": "팀을 기다리세요 💬",
    "answerbot_ask_for_more_context": "물론이죠. 기다리는 동안 귀하의 상황이나 질문에 대해 더 자세한 내용을 공유해 주실 수 있을까요? 이는 더 빠르게 답변을 찾는 데 도움이 되며 제가 더 많은 정보를 찾아 드릴 수도 있습니다.",
    "answerbot_introduce_sneaky_answer": "추가 정보를 제공해 주셔서 감사합니다. 도움이 될 만한 새로운 정보를 발견했습니다.",
    "article_search_hint_text": "기사 검색...",
    "ask_a_question": "질문하기",
    "ask_a_question_placeholder": "질문하기...",
    "ask_a_question_with_ellipsis": "질문하기...",
    "ask_another_question_with_ellipsis": "다른 질문하기...",
    "ask_for_the_team_if_needed": "필요한 경우 팀에 요청",
    "ask_me_a_question": "질문하기",
    "ask_our_bot_a_question": "봇에게 질문하기",
    "avatar": "아바타",
    "avatars": "아바타",
    "bot": "봇",
    "bot_answers_instantly": "즉각적인 봇 응답",
    "bot_expectation_text": "봇이 많은 공통된 질문에 답변해 드릴 수 있습니다",
    "bot_intro_default_part_1": "안녕하세요! 저는 봇이예요. 제가 질문에 답변을 해드리려고 하는데, 언제든 저희 팀과 얘기하실 수 있습니다.",
    "bot_intro_default_part_2": "필요한 게 무엇인가요?",
    "bot_is_still_thinking": "봇이 아직 생각 중입니다...",
    "bot_is_thinking": "봇이 생각 중입니다...",
    "browse_collections": "콜렉션 찾아보기",
    "chat_with_us": "채팅하기",
    "check_later_for_updates": "나중에 업데이트를 다시 확인하십시오.",
    "checklist_card_first_step": "첫 번째 단계",
    "checklist_card_next_step": "다음 단계",
    "checklist_completed": "완료됨",
    "checklist_in_progress_time_remaining_plural": "약 {minutes}분 남음",
    "checklist_in_progress_time_remaining_singular": "약 1분 남음",
    "checklist_mark_step_as_complete": "완료됨으로 표시",
    "checklist_not_started_time_remaining_plural": "약 {minutes}분",
    "checklist_not_started_time_remaining_singular": "약 1분",
    "checklist_number_of_steps_plural": "{number_of_steps}단계",
    "checklist_number_of_steps_progress_plural": "{steps_completed}/{total_steps} 완료",
    "checklist_number_of_steps_progress_singular": "1/{total_steps}단계 완료",
    "checklist_number_of_steps_singular": "1단계",
    "checklist_sender_name": "공유자: {name}",
    "checklist_step_action_button": "단계 작업 버튼",
    "checklist_step_completed": "완료됨",
    "contact_support": "지원팀에 문의하기",
    "contact_us": "문의하기",
    "continue_the_conversation": "대화 계속",
    "conversation_card_office_hours_header": "다시 온라인 상태가 될 때",
    "conversation_card_reply_time_header": "보통 회신 시간",
    "conversation_card_reply_time_header_m5": "일반적인 회신 시간",
    "conversation_card_unknown_response_time": "가능한 한 신속히 답변드립니다",
    "conversation_part_sample_text": "안녕하세요, 무엇을 도와드릴까요? 필요한 경우 언제든지 저희 팀과 얘기하실 수 있습니다.",
    "conversations_space_title": "대화",
    "create_a_ticket": "티켓 생성",
    "currently_no_help_articles": "현재 사용할 수 있는 도움말 자료가 없습니다.",
    "customer_sends_their_first_message": "고객이 첫 메시지를 보냅니다...",
    "expected_response_delay_10800": "3시간 미만",
    "expected_response_delay_120": "2분 미만",
    "expected_response_delay_1200": "20분 미만",
    "expected_response_delay_14400": "4시간 미만",
    "expected_response_delay_172800": "2일",
    "expected_response_delay_180": "3분 미만",
    "expected_response_delay_1800": "30분 미만",
    "expected_response_delay_18000": "5시간 미만",
    "expected_response_delay_21600": "6시간 미만",
    "expected_response_delay_240": "4분 미만",
    "expected_response_delay_259200": "3일",
    "expected_response_delay_300": "5분 미만",
    "expected_response_delay_345600": "4일",
    "expected_response_delay_3600": "1시간 미만",
    "expected_response_delay_43200": "12시간 미만",
    "expected_response_delay_432000": "5일",
    "expected_response_delay_518400": "6일",
    "expected_response_delay_60": "1분 미만",
    "expected_response_delay_600": "10분 미만",
    "expected_response_delay_604800": "1주일 미만",
    "expected_response_delay_7200": "2시간 미만",
    "expected_response_delay_86400": "1일",
    "expected_response_delay_900": "15분 미만",
    "expected_response_delay_unknown": "가능한 한 빨리",
    "extra_labels_count": "+ {labels_count}개 라벨",
    "for_best_results_provide_detail": "최상의 결과를 얻으려면 세부 정보를 가능한 한 많이 제공해 주세요",
    "generating_answer": "답변 생성 중...",
    "generic_triage_default": "적합한 담당자와 연결해 드리기 위해 약간의 추가 정보가 필요합니다.",
    "get_an_instant_answer": "즉각적인 답변 받기",
    "get_help": "지원 받기",
    "go_to": "{app_name}(으)로 이동",
    "help_space_title": "도움말",
    "home_header_mobile_hello_firstname": "안녕하세요 {firstname}님.",
    "home_header_mobile_hello_there": "안녕하세요",
    "home_space_title": "홈",
    "how_can_we_help": "도움이 필요하신가요?",
    "how_would_you_rate_this": "이것을 어떻게 평가하시겠습니까?",
    "learn_more": "자세히 알아보기",
    "message_placeholder": "메시지...",
    "messages_space_title": "메시지",
    "messenger_bot_reply_time": "봇이 즉시 응답해 드릴 것입니다",
    "messenger_settings_introduction": "도움이 필요하신가요?",
    "multiple_articles": "{total_articles}개 자료",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "새로운 대화",
    "news": "뉴스",
    "news_empty_title": "아직 소식이 없습니다",
    "news_latest_subtitle": "팀 {team_name}에서",
    "news_latest_title": "최근",
    "news_older_title": "이전",
    "news_space_title": "뉴스",
    "newsfeed": "뉴스피드",
    "no_articles_to_display": "아직 자료가 없습니다",
    "no_help_articles_to_display": "도움말 자료 없음",
    "no_results_for_searchterm": "\"{searchTerm}\"에 대한 결과 없음",
    "no_tasks": "작업 없음",
    "no_tasks_available_currently": "현재 사용할 수 있는 작업이 없습니다.",
    "ok_the_team_typically_replies_in_day": "확인 완료, 보통 하루 내에 팀의 답변을 받게 됩니다.",
    "ok_the_team_typically_replies_in_hours": "확인 완료, 보통 몇 시간 내에 팀의 답변을 받게 됩니다.",
    "ok_the_team_typically_replies_in_minutes": "확인 완료, 보통 몇 분 내에 팀의 답변을 받게 됩니다.",
    "ok_the_team_typically_replies_in_under_n_hours": "확인 완료, 보통 {hours}시간 내에 팀의 답변을 받게 됩니다.",
    "ok_the_team_typically_replies_in_under_n_min": "확인 완료, 보통 {minutes}분 내에 팀의 답변을 받게 됩니다.",
    "ok_the_team_typically_replies_in_week": "확인 완료, 팀은 보통 일주일 내에 답변드립니다.",
    "our_bot_and_team_can_help": "봇과 팀이 도와드릴 수 있습니다",
    "our_bot_answers_instantly": "봇이 즉시 응답합니다",
    "powered_by_intercom": "Intercom 제공",
    "previous_bot_hands_over": "이전 봇의 콘텐츠...",
    "proactive_expected_response_delay_10800": "보통 3시간 이내 답변",
    "proactive_expected_response_delay_120": "보통 2분 이내 답변",
    "proactive_expected_response_delay_1200": "보통 20분 이내 답변",
    "proactive_expected_response_delay_14400": "보통 4시간 이내 답변",
    "proactive_expected_response_delay_172800": "보통 2일 후 답변",
    "proactive_expected_response_delay_180": "보통 3분 이내 답변",
    "proactive_expected_response_delay_1800": "보통 30분 이내 답변",
    "proactive_expected_response_delay_18000": "보통 5시간 이내 답변",
    "proactive_expected_response_delay_21600": "보통 6시간 이내 답변",
    "proactive_expected_response_delay_240": "보통 4분 이내 답변",
    "proactive_expected_response_delay_259200": "보통 3일 후 답변",
    "proactive_expected_response_delay_300": "보통 5분 이내 답변",
    "proactive_expected_response_delay_345600": "보통 4일 후 답변",
    "proactive_expected_response_delay_3600": "보통 1시간 이내 답변",
    "proactive_expected_response_delay_43200": "보통 12시간 후 답변",
    "proactive_expected_response_delay_432000": "보통 5일 후 답변",
    "proactive_expected_response_delay_518400": "보통 6일 후 답변",
    "proactive_expected_response_delay_60": "보통 1분 이내 답변",
    "proactive_expected_response_delay_600": "보통 10분 이내 답변",
    "proactive_expected_response_delay_604800": "보통 1주 이내 답변",
    "proactive_expected_response_delay_7200": "보통 2시간 이내 답변",
    "proactive_expected_response_delay_86400": "보통 1일 후 답변",
    "proactive_expected_response_delay_900": "보통 15분 이내 답변",
    "proactive_expected_response_delay_unknown": "가능한 한 신속히 답변드립니다",
    "profile_location": "{location}의 현재시각: {time}",
    "recent_ticket": "최근 티켓",
    "recent_tickets": "최근 티켓",
    "search_browse_empty_state_text": "죄송합니다. 결과를 찾을 수 없습니다. 다른 검색어로 다시 시도해 보십시오.",
    "search_for_help": "도움말 검색",
    "see_all": "전체 보기",
    "see_all_conversations": "모든 대화 보기",
    "see_previous_conversations": "이전 대화 보기",
    "send": "전송하기",
    "send_a_message": "메시지 전송…",
    "send_a_message_reply_time": "일반적인 회신 시간:",
    "send_us_a_message": "메시지를 보내주세요",
    "set_expectations_bot_email_header": "채팅 메세지와 이메일을 통해 답변을 받게 됩니다:",
    "set_expectations_bot_office_hours_header": "팀이 복귀하는 시기:",
    "set_expectations_bot_sms_header": "여기에서 문자메시지로 회신을 받게 됩니다.",
    "set_expectations_replies_to_email": "이곳과 <b>{user_email}</b>으로 회신을 받으시게 됩니다.",
    "set_expectations_replies_to_email_without_bold_text": "이곳과 {user_email}(으)로 회신을 받으시게 됩니다",
    "set_expectations_team_reply_asap": "가능한 신속히 답변드리겠습니다.",
    "shared_by": "공유자:",
    "show_less": "간단히",
    "single_article": "자료 1개",
    "single_collection": "1 collection",
    "source": "출처",
    "sources": "출처",
    "start_a_conversation": "대화 시작하기",
    "start_conversation": "대화 시작하기",
    "still_generating_answer": "아직 답변 생성 중...",
    "still_thinking": "아직 생각 중...",
    "still_working_on_it": "아직 작업 중...",
    "tasks_space_title": "작업",
    "team_name_proactive_expected_response_delay_unknown": "{app_name}에서 가능한 한 신속히 답변드립니다",
    "teammate_changes_team_assignment_of_a_conversation": "팀원이 대화 배정을 변경했습니다...",
    "tell_us_what_you_need": "어떤 도움이 필요한지 알려주시면 bot이 최선을 다해 답해드릴 것입니다",
    "the_team_can_help_if_needed": "필요하면 팀에서 도움을 드릴 수 있습니다",
    "the_team_typically_replies_in_10800": "보통 3시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_120": "보통 2분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_1200": "보통 20분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_14400": "보통 4시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_172800": "보통 2일 후에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_180": "보통 3분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_1800": "보통 30분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_18000": "보통 5시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_21600": "보통 6시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_240": "보통 4분 이내에 팀에서 답변 드립니다.",
    "the_team_typically_replies_in_259200": "보통 3일 후에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_300": "보통 5분 이내에 팀에서 답변 드립니다.",
    "the_team_typically_replies_in_345600": "보통 4일 후에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_3600": "보통 1시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_43200": "보통 12시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_432000": "보통 5일 후에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_518400": "보통 6일 후에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_60": "보통 1분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_600": "보통 10분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_604800": "보통 일주일 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_7200": "보통 2시간 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_86400": "보통 1일 후에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_900": "보통 15분 이내에 팀의 답변을 받게 됩니다.",
    "the_team_typically_replies_in_day": "팀: 보통 하루 이후 답변",
    "the_team_typically_replies_in_hours": "팀: 보통 몇 시간 이후 답변",
    "the_team_typically_replies_in_minutes": "팀: 보통 몇 분 이후 답변",
    "the_team_typically_replies_in_under_n_hours": "팀: 보통 {hours}시간 이내 답변",
    "the_team_typically_replies_in_under_n_min": "팀: 보통 {minutes}분 이내 답변",
    "the_team_typically_replies_in_unknown": "가능한 한 신속히 답변드립니다.",
    "the_team_typically_replies_in_week": "팀은 보통 일주일 내에 답변드립니다.",
    "thinking": "생각 중...",
    "tickets_cta_text": "이 티켓에 대해 질문이 있으신가요?",
    "tickets_have_a_question": "이 문제에 대한 질문이 있으신가요?",
    "tickets_space_title": "티켓",
    "time_future_1h": "1시간",
    "time_future_2h": "2시간",
    "time_future_30m": "30분",
    "time_future_3h": "3시간",
    "time_future_day_0": "월요일",
    "time_future_day_1": "화요일",
    "time_future_day_2": "수요일",
    "time_future_day_3": "목요일",
    "time_future_day_4": "금요일",
    "time_future_day_5": "토요일",
    "time_future_day_6": "일요일",
    "time_future_later_today": "오늘 늦게",
    "time_future_next_week": "다음주",
    "time_future_tomorrow": "내일",
    "try_use_different_keywords": "다른 키워드를 사용하거나 전체 질문을 입력하십시오",
    "upfront_collection_form_close_out": "언제든지 여기로 돌아와서 응답을 보고 업데이트를 전송할 수 있습니다.",
    "upfront_collection_form_introduction": "팀이 보다 빠르고 우수하게 응답할 수 있도록 몇 가지 컨텍스트를 공유하십시오.",
    "upfront_collection_form_submission_confirmation": "해당 컨텍스트를 보내주셔서 감사합니다. 나중에 시간을 절약하기 위해 지금 더 많은 세부 정보를 추가해 보십시오.",
    "upfront_collection_form_subtitle": "팀에게 몇 가지 컨텍스트를 보내십시오",
    "upfront_collection_form_subtitle_submitted": "팀원들이 이 컨텍스트를 염두에 둘 것입니다",
    "upfront_collection_form_title": "보다 신속하게 도움을 받으십시오",
    "upfront_collection_form_title_submitted": "팀에게 전송했습니다!",
    "view_all": "전체 보기",
    "view_conversation": "대화 보기",
    "we_are_here_to_help": "저희가 도와드리겠습니다",
    "we_run_on_intercom": "우리는 Intercom으로 구동합니다",
    "when_a_teammate_changes_the_state_of_a_conversation": "팀원이 대화 상태를 변경하는 경우...",
    "working_on_it": "작업 중...",
    "you_are_all_up_to_date": "완료되었습니다!",
    "your_checklists": "체크리스트",
    "your_conversations": "대화",
    "your_recent_conversations": "최근 대화",
    "your_recent_messages": "최근 메시지"
  },
  "lt": {
    "a_day": "viena diena",
    "a_few_hours": "kelios valandos",
    "a_few_minutes": "kelios minutės",
    "a_week": "Savaitė",
    "active_state": "Aktyvus",
    "ai_answer": "DI atsakymas",
    "ai_answer_information": "DI atsakymas, informacija",
    "ai_bot": "DI robotas",
    "almost_there": "Beveik baigta...",
    "answer": "Atsakymas",
    "answer_bot_acknowledge_duplicate_content": "Jūs jau matėte šį atsakymą, tačiau jis geriausias.",
    "answer_bot_all_done": "Tai padėjo 👍",
    "answer_bot_ask_another_question": "Užduoti kitą klausimą 🔁",
    "answer_bot_ask_something_else": "Užduoti kitą klausimą 🔁",
    "answer_bot_connect_to_an_agent": "Sujungti su agentu 👤",
    "answer_bot_did_that_help": "Ar atsakymas padėjo, ar ieškote ko nors kito?",
    "answer_bot_followup_expectations_prefix": "Komanda dėl to su jumis susisieks.",
    "answer_bot_get_more_help": "Gaukite daugiau pagalbos 🌐",
    "answer_bot_get_more_help_followup": "Ši nuoroda gali būti naudinga:",
    "answer_bot_i_have_another_question": "Turiu dar vieną klausimą 🔁",
    "answer_bot_im_not_done_yet": "Dar ne viskas 🔁",
    "answer_bot_keep_waiting": "Ne, man vis tiek reikia pagalbos 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Galite perfrazuoti savo klausimą arba gauti papildomos pagalbos",
    "answer_bot_looping_first_no_answer": "Atsiprašau, atsakyti negaliu.",
    "answer_bot_looping_first_no_answer_followup": "Galite perfrazuoti savo klausimą arba pasikalbėti su mūsų komanda",
    "answer_bot_looping_no_answer": "Atsiprašau, atsakyti negaliu. Štai ko galite imtis",
    "answer_bot_more_answers": "Rodyti daugiau 👀",
    "answer_bot_no_answer_found": "Atsiprašome, neturime greito atsakymo į šį klausimą. Štai keli kiti variantai.",
    "answer_bot_resolve_followup": "Visada galite atsakyti čia, jei iškiltų kitas klausimas.",
    "answer_bot_talk_to_person": "Pasikalbėkite su žmogumi 👤",
    "answer_bot_that_answered_my_question": "Gavau atsakymą į savo klausimą 👍",
    "answer_bot_thats_it": "Štai ir viskas 👍",
    "answer_bot_wait_for_the_team": "Palaukite komandos 💬",
    "answerbot_ask_for_more_context": "Žinoma. Kol laukiame, ar galite papasakoti daugiau apie savo situaciją arba užduoti klausimų? Tai padės mums greičiau rasti atsakymą, o aš galbūt rasiu daugiau informacijos.",
    "answerbot_introduce_sneaky_answer": "Ačiū už papildomą kontekstą. Radau naujos informacijos, kuri gali jums padėti.",
    "article_search_hint_text": "Ieškoti straipsnių...",
    "ask_a_question": "Užduoti klausimą",
    "ask_a_question_placeholder": "Užduoti klausimą...",
    "ask_a_question_with_ellipsis": "Užduokite klausimą...",
    "ask_another_question_with_ellipsis": "Užduokite kitą klausimą...",
    "ask_for_the_team_if_needed": "Jei reikia, kreipkitės į komandą",
    "ask_me_a_question": "Užduokite man klausimą",
    "ask_our_bot_a_question": "Užduokite mūsų robotui klausimą",
    "avatar": "Avataras",
    "avatars": "Avatarai",
    "bot": "Autoatsakiklis",
    "bot_answers_instantly": "Robotas atsako akimirksniu",
    "bot_expectation_text": "Mūsų autoatsakiklis gali atsakyti įdaugelį dažniausiai užduodamų klausimų",
    "bot_intro_default_part_1": "Sveiki! Kalbate su robotu. Esu čia, kad atsakyčiau į jūsų klausimus, tačiau visada galite kreiptis į mūsų komandą.",
    "bot_intro_default_part_2": "Kas jus čia atvedė?",
    "bot_is_still_thinking": "Robotas vis dar galvoja...",
    "bot_is_thinking": "Robotas galvoja...",
    "browse_collections": "Naršyti kolekcijas",
    "chat_with_us": "Pasikalbėkite su mumis",
    "check_later_for_updates": "Patikrinkite vėliau, ar nėra naujinių.",
    "checklist_card_first_step": "Pirmas žingsnis",
    "checklist_card_next_step": "Kitas žingsnis",
    "checklist_completed": "Užbaigta",
    "checklist_in_progress_time_remaining_plural": "Liko maždaug {minutes} min.",
    "checklist_in_progress_time_remaining_singular": "Liko maždaug 1 minutė",
    "checklist_mark_step_as_complete": "Pažymėti kaip užbaigtą",
    "checklist_not_started_time_remaining_plural": "Maždaug {minutes} min.",
    "checklist_not_started_time_remaining_singular": "Maždaug 1 minutė",
    "checklist_number_of_steps_plural": "{number_of_steps} žingsnių",
    "checklist_number_of_steps_progress_plural": "{steps_completed} iš {total_steps} atlikta",
    "checklist_number_of_steps_progress_singular": "1 iš {total_steps} atliktas",
    "checklist_number_of_steps_singular": "1 žingsnis",
    "checklist_sender_name": "Pasidalijo {name}",
    "checklist_step_action_button": "Žingsnio veiksmo mygtukas",
    "checklist_step_completed": "Užbaigta",
    "contact_support": "Susisiekite su pagalbos tarnyba",
    "contact_us": "Susisiekite su mumis",
    "continue_the_conversation": "Tęsti pokalbį",
    "conversation_card_office_hours_header": "Kada būsime pasiekiami",
    "conversation_card_reply_time_header": "Mūsų įprastas atsakymo laikas",
    "conversation_card_reply_time_header_m5": "Įprastas atsakymo laikas",
    "conversation_card_unknown_response_time": "Atsakysime kuo greičiau",
    "conversation_part_sample_text": "Sveiki, kuo galiu padėti? Jei reikės, visada turėsite galimybę pasikalbėti su mūsų komanda.",
    "conversations_space_title": "Pokalbiai",
    "create_a_ticket": "Sukurti bilietą",
    "currently_no_help_articles": "Šiuo metu pagalbos straipsnių nėra.",
    "customer_sends_their_first_message": "Klientas išsiunčia savo pirmąją žinutę...",
    "expected_response_delay_10800": "mažiau nei 3 val.",
    "expected_response_delay_120": "greičiau nei per 2 min.",
    "expected_response_delay_1200": "mažiau nei 20 min.",
    "expected_response_delay_14400": "mažiau nei 4 val.",
    "expected_response_delay_172800": "2 dienos",
    "expected_response_delay_180": "greičiau nei per 3 min.",
    "expected_response_delay_1800": "mažiau nei 30 min.",
    "expected_response_delay_18000": "mažiau nei 5 val.",
    "expected_response_delay_21600": "mažiau nei 6 val.",
    "expected_response_delay_240": "greičiau nei per 4 min.",
    "expected_response_delay_259200": "3 dienos",
    "expected_response_delay_300": "mažiau nei 5 min.",
    "expected_response_delay_345600": "4 dienos",
    "expected_response_delay_3600": "mažiau nei 1 val.",
    "expected_response_delay_43200": "greičiau nei per 12 valandų",
    "expected_response_delay_432000": "5 dienos",
    "expected_response_delay_518400": "6 dienos",
    "expected_response_delay_60": "greičiau nei per 1 min.",
    "expected_response_delay_600": "mažiau nei 10 min.",
    "expected_response_delay_604800": "mažiau nei 1 sav.",
    "expected_response_delay_7200": "mažiau nei 2 val.",
    "expected_response_delay_86400": "1 d.",
    "expected_response_delay_900": "mažiau nei 5 min.",
    "expected_response_delay_unknown": "kuo greičiau",
    "extra_labels_count": "+ {labels_count} etiketės",
    "for_best_results_provide_detail": "Norėdami gauti geriausius rezultatus, pateikite kuo daugiau informacijos",
    "generating_answer": "Generuoju atsakymą...",
    "generic_triage_default": "Mums dar reikia šiek tiek daugiau informacijos, kad galėtume jus sujungti su tinkamu asmeniu",
    "get_an_instant_answer": "Gaukite greitą atsakymą",
    "get_help": "Pagalba",
    "go_to": "Eiti į {app_name}",
    "help_space_title": "Pagalba",
    "home_header_mobile_hello_firstname": "Sveiki, {firstname}.",
    "home_header_mobile_hello_there": "Sveiki",
    "home_space_title": "Namai",
    "how_can_we_help": "Kaip galėtume padėti?",
    "how_would_you_rate_this": "Kaip įvertintumėte?",
    "learn_more": "Sužinokite daugiau",
    "message_placeholder": "Pranešimas...",
    "messages_space_title": "Pranešimai",
    "messenger_bot_reply_time": "Mūsų robotas atsakys akimirksniu",
    "messenger_settings_introduction": "Kuo galime padėti?",
    "multiple_articles": "{total_articles} straipsnių",
    "multiple_collections": "{total_collection} kolekcijos",
    "new_conversation": "Naujas pokalbis",
    "news": "Naujienos",
    "news_empty_title": "Naujienų dar nėra",
    "news_latest_subtitle": "Nuo komandos {team_name}",
    "news_latest_title": "Paskutinės",
    "news_older_title": "Senesnės",
    "news_space_title": "Naujienos",
    "newsfeed": "Naujienų srautas",
    "no_articles_to_display": "Dar nėra straipsnių",
    "no_help_articles_to_display": "Nėra pagalbos straipsnių",
    "no_results_for_searchterm": "Nerasta {searchTerm} rezultatų",
    "no_tasks": "Užduočių nėra",
    "no_tasks_available_currently": "Šiuo metu užduočių nėra.",
    "ok_the_team_typically_replies_in_day": "Gerai, komanda įprastai atsako per dieną.",
    "ok_the_team_typically_replies_in_hours": "Gerai, komanda įprastai atsako per keletą valandų.",
    "ok_the_team_typically_replies_in_minutes": "Gerai, komanda įprastai atsako per keletą minučių.",
    "ok_the_team_typically_replies_in_under_n_hours": "Gerai, komanda įprastai atsako per mažiau nei {hours} h.",
    "ok_the_team_typically_replies_in_under_n_min": "Gerai, komanda įprastai atsako per mažiau nei {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Gerai, komanda įprastai atsako per savaitę.",
    "our_bot_and_team_can_help": "Mūsų robotas ir komanda gali padėti",
    "our_bot_answers_instantly": "Mūsų robotas atsako akimirksniu",
    "powered_by_intercom": "Palaikoma „Intercom“",
    "previous_bot_hands_over": "Turinys iš ankstesnio roboto...",
    "proactive_expected_response_delay_10800": "Įprastai atsako per mažiau nei 3 val.",
    "proactive_expected_response_delay_120": "Įprastai atsako greičiau nei per 2 min.",
    "proactive_expected_response_delay_1200": "Įprastai atsako per mažiau nei 20 min.",
    "proactive_expected_response_delay_14400": "Įprastai atsako per mažiau nei 4 val.",
    "proactive_expected_response_delay_172800": "Įprastai atsako per 2 d.",
    "proactive_expected_response_delay_180": "Įprastai atsako greičiau nei per 3 min.",
    "proactive_expected_response_delay_1800": "Įprastai atsako per mažiau nei 30 min.",
    "proactive_expected_response_delay_18000": "Įprastai atsako per mažiau nei 5 val.",
    "proactive_expected_response_delay_21600": "Įprastai atsako per mažiau nei 6 val.",
    "proactive_expected_response_delay_240": "Paprastai atsako greičiau nei per 4 min.",
    "proactive_expected_response_delay_259200": "Paprastai atsako per 3 dienas",
    "proactive_expected_response_delay_300": "Įprastai atsako per mažiau nei 5 min.",
    "proactive_expected_response_delay_345600": "Paprastai atsako per 4 dienas",
    "proactive_expected_response_delay_3600": "Įprastai atsako per mažiau nei 1 val.",
    "proactive_expected_response_delay_43200": "Paprastai atsako per 12 valandų",
    "proactive_expected_response_delay_432000": "Paprastai atsako per 5 dienas",
    "proactive_expected_response_delay_518400": "Paprastai atsako per 6 dienas",
    "proactive_expected_response_delay_60": "Įprastai atsako greičiau nei per 1 min.",
    "proactive_expected_response_delay_600": "Įprastai atsako per mažiau nei 10 min.",
    "proactive_expected_response_delay_604800": "Įprastai atsako per mažiau nei 1 sav.",
    "proactive_expected_response_delay_7200": "Įprastai atsako per mažiau nei 2 val.",
    "proactive_expected_response_delay_86400": "Įprastai atsako per 1 d.",
    "proactive_expected_response_delay_900": "Įprastai atsako per mažiau nei 15 min.",
    "proactive_expected_response_delay_unknown": "Atsakys taip greitai, kaip gali",
    "profile_location": "{time}, {location}",
    "recent_ticket": "Naujausias bilietas",
    "recent_tickets": "Naujausi bilietai",
    "search_browse_empty_state_text": "Apgailestaujame, mums nepavyko rasti jokių rezultatų. Pabandykite perrašyti savo paieškos tekstą.",
    "search_for_help": "Ieškoti pagalbos",
    "see_all": "Žiūrėti visus",
    "see_all_conversations": "Peržiūrėkite visus savo pokalbius",
    "see_previous_conversations": "Peržiūrėkite savo ankstesnius pokalbius",
    "send": "Siųsti",
    "send_a_message": "Siųsti žinutę…",
    "send_a_message_reply_time": "Įprastas atsakymo laikas yra",
    "send_us_a_message": "Atsiųskite mums pranešimą",
    "set_expectations_bot_email_header": "Gausite atsakymus čia ir el. paštu:",
    "set_expectations_bot_office_hours_header": "Komanda pratęs darbą:",
    "set_expectations_bot_sms_header": "Gausite atsakymus čia ir SMS:",
    "set_expectations_replies_to_email": "Atsakymus gausite čia ir adresu <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Atsakymus gausite čia ir adresu {user_email}",
    "set_expectations_team_reply_asap": "Komanda atsakys kiek įmanoma greičiau.",
    "shared_by": "Pasidalino",
    "show_less": "Rodyti mažiau",
    "single_article": "1 straipsnis",
    "single_collection": "1 kolekcija",
    "source": "Šaltinis",
    "sources": "Šaltiniai",
    "start_a_conversation": "Pradėti pokalbį",
    "start_conversation": "Pradėti pokalbį",
    "still_generating_answer": "Vis dar generuoju atsakymą...",
    "still_thinking": "Vis dar galvoju...",
    "still_working_on_it": "Vis dar dirbu šiuo klausimu...",
    "tasks_space_title": "Užduotys",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} atsakys kiek įmanoma greičiau",
    "teammate_changes_team_assignment_of_a_conversation": "Komandos draugas pakeitė pokalbio užduotį...",
    "tell_us_what_you_need": "Papasakokite, kuo galime padėti, ir mūsų „Bot“ kaip galėdamas geriau atsakys",
    "the_team_can_help_if_needed": "Prireikus komanda gali padėti",
    "the_team_typically_replies_in_10800": "Komanda įprastai atsako greičiau nei per 3 val.",
    "the_team_typically_replies_in_120": "Komanda įprastai atsako greičiau nei per 2 min.",
    "the_team_typically_replies_in_1200": "Komanda įprastai atsako greičiau nei per 20 min.",
    "the_team_typically_replies_in_14400": "Komanda įprastai atsako greičiau nei per 4 val.",
    "the_team_typically_replies_in_172800": "Komanda įprastai atsako per 2 d.",
    "the_team_typically_replies_in_180": "Komanda įprastai atsako greičiau nei per 3 min.",
    "the_team_typically_replies_in_1800": "Komanda įprastai atsako greičiau nei per 30 min.",
    "the_team_typically_replies_in_18000": "Komanda įprastai atsako greičiau nei per 5 val.",
    "the_team_typically_replies_in_21600": "Komanda įprastai atsako greičiau nei per 6 val.",
    "the_team_typically_replies_in_240": "Komanda įprastai atsako greičiau nei per 4 min.",
    "the_team_typically_replies_in_259200": "Komanda paprastai atsako per 3 dienas",
    "the_team_typically_replies_in_300": "Komanda įprastai atsako greičiau nei per 5 min.",
    "the_team_typically_replies_in_345600": "Komanda paprastai atsako per 4 dienas",
    "the_team_typically_replies_in_3600": "Komanda įprastai atsako greičiau nei per 1 val.",
    "the_team_typically_replies_in_43200": "Komanda paprastai atsako per 12 valandų",
    "the_team_typically_replies_in_432000": "Komanda paprastai atsako per 5 dienas",
    "the_team_typically_replies_in_518400": "Komanda paprastai atsako per 6 dienas",
    "the_team_typically_replies_in_60": "Komanda įprastai atsako greičiau nei per 1 min.",
    "the_team_typically_replies_in_600": "Komanda įprastai atsako greičiau nei per 10 min.",
    "the_team_typically_replies_in_604800": "Komanda įprastai atsako greičiau nei per 1 sav.",
    "the_team_typically_replies_in_7200": "Komanda įprastai atsako greičiau nei per 2 val.",
    "the_team_typically_replies_in_86400": "Komanda įprastai atsako per 1 d.",
    "the_team_typically_replies_in_900": "Komanda įprastai atsako greičiau nei per 15 min.",
    "the_team_typically_replies_in_day": "Komanda įprastai atsako per dieną.",
    "the_team_typically_replies_in_hours": "Komanda įprastai atsako per keletą valandų.",
    "the_team_typically_replies_in_minutes": "Komanda įprastai atsako per keletą minučių.",
    "the_team_typically_replies_in_under_n_hours": "Komanda įprastai atsako per mažiau nei {hours} h.",
    "the_team_typically_replies_in_under_n_min": "Komanda įprastai atsako per mažiau nei {minutes} min.",
    "the_team_typically_replies_in_unknown": "Komanda atsakys kiek įmanoma greičiau",
    "the_team_typically_replies_in_week": "Komanda įprastai atsako per savaitę.",
    "thinking": "Galvoju...",
    "tickets_cta_text": "Turite klausimų apie šį bilietą?",
    "tickets_have_a_question": "Turite klausimų apie šią problemą?",
    "tickets_space_title": "Bilietai",
    "time_future_1h": "po 1 val.",
    "time_future_2h": "po 2 val.",
    "time_future_30m": "po 30 min.",
    "time_future_3h": "po 3 val.",
    "time_future_day_0": "pirmadienį",
    "time_future_day_1": "antradienį",
    "time_future_day_2": "trečiadienį",
    "time_future_day_3": "ketvirtadienį",
    "time_future_day_4": "penktadienį",
    "time_future_day_5": "šeštadienį",
    "time_future_day_6": "sekmadienį",
    "time_future_later_today": "vėliau šiandien",
    "time_future_next_week": "kitą savaitę",
    "time_future_tomorrow": "rytoj",
    "try_use_different_keywords": "Naudokite kitus raktinius žodžius arba įveskite visą klausimą",
    "upfront_collection_form_close_out": "Galite bet kada čia grįžti norėdami pamatyti atsakymų ir atsiųsti atnaujinimų.",
    "upfront_collection_form_introduction": "Pasidalinkite kontekstu, kad padėtumėte komandai geriau ir greičiau atsakyti.",
    "upfront_collection_form_submission_confirmation": "Ačiū už atsiųstą kontekstą. Kad negaištumėte laiko vėliau, pridėkite daugiau informacijos.",
    "upfront_collection_form_subtitle": "Išsiųskite komandai šiek tiek konteksto",
    "upfront_collection_form_subtitle_submitted": "Ji atsižvelgs į šį kontekstą",
    "upfront_collection_form_title": "Gaukite pagalbos greičiau",
    "upfront_collection_form_title_submitted": "Išsiųsta komandai!",
    "view_all": "Rodyti viską",
    "view_conversation": "Rodyti pokalbį",
    "we_are_here_to_help": "Esame čia, kad padėtume",
    "we_run_on_intercom": "Mes veikiame „Intercom“ platformoje",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kai komandos draugas pakeičia pokalbio būseną...",
    "working_on_it": "Dirbu šiuo klausimu...",
    "you_are_all_up_to_date": "Viskas atlikta!",
    "your_checklists": "Jūsų kontroliniai sąrašai",
    "your_conversations": "Jūsų pokalbiai",
    "your_recent_conversations": "Naujausi pokalbiai",
    "your_recent_messages": "Naujausi pranešimai"
  },
  "lv": {
    "a_day": "Viena diena",
    "a_few_hours": "Dažas stundas",
    "a_few_minutes": "Dažas minūtes",
    "a_week": "Nedēļa",
    "active_state": "Aktīvs",
    "ai_answer": "MI atbilde",
    "ai_answer_information": "MI atbilde, informācija",
    "ai_bot": "MI robots",
    "almost_there": "Gandrīz gatavs...",
    "answer": "Atbilde",
    "answer_bot_acknowledge_duplicate_content": "Jūs to jau lasījāt, bet tā ir labākā pieejamā atbilde.",
    "answer_bot_all_done": "Tas palīdzēja 👍",
    "answer_bot_ask_another_question": "Uzdodiet citu jautājumu 🔁",
    "answer_bot_ask_something_else": "Uzdot vēl kādu jautājumu 🔁",
    "answer_bot_connect_to_an_agent": "Sazināties ar aģentu 👤",
    "answer_bot_did_that_help": "Šī atbilde Jūs apmierina vai arī ir nepieciešama cita informācija?",
    "answer_bot_followup_expectations_prefix": "Mūsu komanda sazināsies ar jums par šo jautājumu.",
    "answer_bot_get_more_help": "Saņemt papildu palīdzību 🌐",
    "answer_bot_get_more_help_followup": "Šī saite varētu palīdzēt:",
    "answer_bot_i_have_another_question": "Man ir cits jautājums 🔁",
    "answer_bot_im_not_done_yet": "Man ir vēl jautājumi 🔁",
    "answer_bot_keep_waiting": "Nē, man joprojām ir vajadzīga palīdzība 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Jūs varat pārfrāzēt savu jautājumu vai saņemt papildu palīdzību",
    "answer_bot_looping_first_no_answer": "Atvainojiet, es kā bots nevarēju atrast atbildi uz šo jautājumu.",
    "answer_bot_looping_first_no_answer_followup": "Jūs varat pārfrāzēt savu jautājumu vai uzrunāt mūsu komandu",
    "answer_bot_looping_no_answer": "Atvainojiet, es kā robots nevarēju atrast atbildi uz šo jautājumu. Lūk, ko jūs varat darīt",
    "answer_bot_more_answers": "Parādiet man vairāk 👀",
    "answer_bot_no_answer_found": "Atvainojiet, mums nav ātras atbildes uz šo jautājumu. Šeit ir dažas citas iespējas.",
    "answer_bot_resolve_followup": "Ja rodas vēl kādi jautājumi, vienmēr varat atbildēt šeit.",
    "answer_bot_talk_to_person": "Parunājiet ar cilvēku 👤",
    "answer_bot_that_answered_my_question": "Es saņēmu atbildi uz savu jautājumu 👍",
    "answer_bot_thats_it": "Tas palīdzēja 👍",
    "answer_bot_wait_for_the_team": "Gaidīt komandu 💬",
    "answerbot_ask_for_more_context": "Protams. Kamēr mēs gaidām, vai varat sniegt sīkāku informāciju par savu situāciju vai jautājumu? Tas mums ļaus ātrāk atbildēt, un es, iespējams, varēšu atrast vairāk informācijas.",
    "answerbot_introduce_sneaky_answer": "Paldies par šo papildu kontekstu. Es atradu jaunu informāciju, kas varētu jums palīdzēt.",
    "article_search_hint_text": "Meklēt rakstus...",
    "ask_a_question": "Uzdot jautājumu",
    "ask_a_question_placeholder": "Uzdodiet jautājumu...",
    "ask_a_question_with_ellipsis": "Uzdodiet jautājumu...",
    "ask_another_question_with_ellipsis": "Uzdodiet vēl kādu jautājumu...",
    "ask_for_the_team_if_needed": "Ja nepieciešams, vaicājiet komandai",
    "ask_me_a_question": "Uzdodiet man jautājumu",
    "ask_our_bot_a_question": "Uzdodiet mūsu robotam jautājumu",
    "avatar": "Avatārs",
    "avatars": "Avatāri",
    "bot": "Bots",
    "bot_answers_instantly": "Bots atbild nekavējoties",
    "bot_expectation_text": "Mūsu bots var atbildēt uz daudziem bieži uzdotiem jautājumiem",
    "bot_intro_default_part_1": "Sveiki! Ar jums runā bots. Es atbildēšu uz jūsu jautājumiem, taču jums vienmēr ir iespēja runāt arī ar mūsu komandu.",
    "bot_intro_default_part_2": "Tātad, ko šodien vēlaties uzzināt?",
    "bot_is_still_thinking": "Bots joprojām domā...",
    "bot_is_thinking": "Bots domā...",
    "browse_collections": "Pārlūkot kolekcijas",
    "chat_with_us": "Tērzējiet ar mums",
    "check_later_for_updates": "Ienāciet vēlāk, lai saņemtu informāciju par atjauninājumiem.",
    "checklist_card_first_step": "Pirmais solis",
    "checklist_card_next_step": "Nākamais solis",
    "checklist_completed": "Pabeigts",
    "checklist_in_progress_time_remaining_plural": "Atlikušas aptuveni {minutes} minūtes",
    "checklist_in_progress_time_remaining_singular": "Atlikusi aptuveni 1 minūte",
    "checklist_mark_step_as_complete": "Atzīmēt kā pabeigtu",
    "checklist_not_started_time_remaining_plural": "Aptuveni {minutes} minūtes",
    "checklist_not_started_time_remaining_singular": "Apmēram 1 minūte",
    "checklist_number_of_steps_plural": "{number_of_steps} soļi",
    "checklist_number_of_steps_progress_plural": "Pabeigti {steps_completed} no {total_steps}",
    "checklist_number_of_steps_progress_singular": "1 solis no {total_steps} pabeigts",
    "checklist_number_of_steps_singular": "1 solis",
    "checklist_sender_name": "Kopīgoja {name}",
    "checklist_step_action_button": "Soļu darbības poga",
    "checklist_step_completed": "Pabeigts",
    "contact_support": "Sazinieties ar atbalsta dienestu",
    "contact_us": "Sazinieties ar mums",
    "continue_the_conversation": "Turpināt sarunu",
    "conversation_card_office_hours_header": "Kad mēs atkal būsim tiešsaistē",
    "conversation_card_reply_time_header": "Mūsu parastais atbildēšanas laiks",
    "conversation_card_reply_time_header_m5": "Parastais atbildes laiks",
    "conversation_card_unknown_response_time": "Mēs atbildēsim, cik drīz vien varēsim",
    "conversation_part_sample_text": "Sveiki, kā varu Jums palīdzēt? Ja vēlaties, vienmēr varat sazināties arī ar mūsu komandu.",
    "conversations_space_title": "Sarunas",
    "create_a_ticket": "Izveidot biļeti",
    "currently_no_help_articles": "Palīdzības raksti pašlaik nav pieejami.",
    "customer_sends_their_first_message": "Klients nosūta savu pirmo ziņojumu...",
    "expected_response_delay_10800": "mazāk nekā 3 stundas",
    "expected_response_delay_120": "mazāk nekā 2 minūtes",
    "expected_response_delay_1200": "mazāk nekā 20 minūtes",
    "expected_response_delay_14400": "līdz 4 stundām",
    "expected_response_delay_172800": "2 dienas",
    "expected_response_delay_180": "mazāk nekā 3 minūtes",
    "expected_response_delay_1800": "mazāk nekā 30 minūtes",
    "expected_response_delay_18000": "līdz 5 stundām",
    "expected_response_delay_21600": "līdz 6 stundām",
    "expected_response_delay_240": "mazāk nekā 4 minūtes",
    "expected_response_delay_259200": "3 dienas",
    "expected_response_delay_300": "mazāk nekā 5 minūtes",
    "expected_response_delay_345600": "4 dienas",
    "expected_response_delay_3600": "mazāk par 1 stundu",
    "expected_response_delay_43200": "ne ilgāk par 12 stundām",
    "expected_response_delay_432000": "5 dienas",
    "expected_response_delay_518400": "6 dienas",
    "expected_response_delay_60": "mazāk nekā 1 minūte",
    "expected_response_delay_600": "mazāk nekā 10 minūtes",
    "expected_response_delay_604800": "līdz 1 nedēļai",
    "expected_response_delay_7200": "mazāk nekā 2 stundas",
    "expected_response_delay_86400": "1 diena",
    "expected_response_delay_900": "ātrāk nekā 15 minūtes",
    "expected_response_delay_unknown": "Cik drīz vien varēsim",
    "extra_labels_count": "+ {labels_count} etiķetes",
    "for_best_results_provide_detail": "Lai iegūtu vislabākos rezultātus, sniedziet pēc iespējas vairāk informācijas",
    "generating_answer": "Notiek atbildes ģenerēšana...",
    "generic_triage_default": "Mums nepieciešams vēl mazliet informācijas, lai jūs varētu sazināties ar īsto personu",
    "get_an_instant_answer": "Saņemt tūlītēju atbildi",
    "get_help": "Saņemt palīdzību",
    "go_to": "Doties uz {app_name}",
    "help_space_title": "Palīdzība",
    "home_header_mobile_hello_firstname": "Labdien, {firstname}.",
    "home_header_mobile_hello_there": "Sveiki",
    "home_space_title": "Mājas",
    "how_can_we_help": "Kā mēs varam palīdzēt?",
    "how_would_you_rate_this": "Kā jūs to vērtētu?",
    "learn_more": "Uzzināt vairāk",
    "message_placeholder": "Ziņa…",
    "messages_space_title": "Ziņojumi",
    "messenger_bot_reply_time": "Mūsu robots atbildēs nekavējoties",
    "messenger_settings_introduction": "Kā mēs varam palīdzēt?",
    "multiple_articles": "{total_articles} raksti",
    "multiple_collections": "{total_collection} kolekcijas",
    "new_conversation": "Jauna saruna",
    "news": "Jaunumi",
    "news_empty_title": "Vēl nav ziņu",
    "news_latest_subtitle": "No komandas {team_name}",
    "news_latest_title": "Jaunākās ziņas",
    "news_older_title": "Vecākas ziņas",
    "news_space_title": "Jaunumi",
    "newsfeed": "Jaunumu plūsma",
    "no_articles_to_display": "Vēl nav neviena raksta",
    "no_help_articles_to_display": "Nav palīdzības rakstu",
    "no_results_for_searchterm": "{searchTerm} nav rezultātu",
    "no_tasks": "Nav uzdevumu",
    "no_tasks_available_currently": "Pašlaik nav pieejamu uzdevumu.",
    "ok_the_team_typically_replies_in_day": "Labi, komanda parasti atbild dienas laikā.",
    "ok_the_team_typically_replies_in_hours": "Labi, komanda parasti atbild pāris stundu laikā.",
    "ok_the_team_typically_replies_in_minutes": "Labi, komanda parasti atbild pāris minūšu laikā.",
    "ok_the_team_typically_replies_in_under_n_hours": "Labi, komanda parasti atbild ātrāk par {hours} stundām.",
    "ok_the_team_typically_replies_in_under_n_min": "Labi, komanda parasti atbild ātrāk par {minutes} minūtēm.",
    "ok_the_team_typically_replies_in_week": "Labi, komanda parasti atbild nedēļas laikā.",
    "our_bot_and_team_can_help": "Mūsu bots un komanda var palīdzēt",
    "our_bot_answers_instantly": "Mūsu robots atbild nekavējoties",
    "powered_by_intercom": "Nodrošina Intercom",
    "previous_bot_hands_over": "Iepriekšējā bota saturs...",
    "proactive_expected_response_delay_10800": "Parasti atbild ātrāk par 3 st.",
    "proactive_expected_response_delay_120": "Parasti atbild ātrāk par 2 min.",
    "proactive_expected_response_delay_1200": "Parasti atbild ātrāk par 20 min.",
    "proactive_expected_response_delay_14400": "Parasti atbild ātrāk par 4 st.",
    "proactive_expected_response_delay_172800": "Parasti atbild 2 dienu laikā",
    "proactive_expected_response_delay_180": "Parasti atbild ātrāk par 3 min.",
    "proactive_expected_response_delay_1800": "Parasti atbild ātrāk par 30 min.",
    "proactive_expected_response_delay_18000": "Parasti atbild ātrāk par 5 st.",
    "proactive_expected_response_delay_21600": "Parasti atbild ātrāk par 6 st.",
    "proactive_expected_response_delay_240": "Parasti atbild ātrāk par 4 min.",
    "proactive_expected_response_delay_259200": "Parasti atbild 3 dienu laikā",
    "proactive_expected_response_delay_300": "Parasti atbild ātrāk par 5 min.",
    "proactive_expected_response_delay_345600": "Parasti atbild 4 dienu laikā",
    "proactive_expected_response_delay_3600": "Parasti atbild ātrāk par 1 st.",
    "proactive_expected_response_delay_43200": "Parasti atbild 12 stundu laikā",
    "proactive_expected_response_delay_432000": "Parasti atbild 5 dienu laikā",
    "proactive_expected_response_delay_518400": "Parasti atbild 6 dienu laikā",
    "proactive_expected_response_delay_60": "Parasti atbild ātrāk par 1 min.",
    "proactive_expected_response_delay_600": "Parasti atbild ātrāk par 10 min.",
    "proactive_expected_response_delay_604800": "Parasti atbild ātrāk par 1 ned.",
    "proactive_expected_response_delay_7200": "Parasti atbild ātrāk par 2 st.",
    "proactive_expected_response_delay_86400": "Parasti atbild 1 dienas laikā",
    "proactive_expected_response_delay_900": "Parasti atbild ātrāk nekā 15 min. laikā.",
    "proactive_expected_response_delay_unknown": "Atbildēs, cik vien ātri varēs",
    "profile_location": "{time}, {location}",
    "recent_ticket": "Pēdējā biļete",
    "recent_tickets": "Pēdējās biļetes",
    "search_browse_empty_state_text": "Atvainojiet, mēs nevarējām atrast nekādus rezultātus. Mēģiniet pārformulēt meklēšanas tekstu.",
    "search_for_help": "Meklēt palīdzību",
    "see_all": "Skatīt visas",
    "see_all_conversations": "Skatīt visas jūsu sarunas",
    "see_previous_conversations": "Skatiet savas iepriekšējās sarunas",
    "send": "Sūtīt",
    "send_a_message": "Sūtīt ziņojumu…",
    "send_a_message_reply_time": "Parastais atbildes laiks ir",
    "send_us_a_message": "Nosūtiet mums ziņojumu",
    "set_expectations_bot_email_header": "Jūs saņemsiet atbildes šeit un savā e-pastā:",
    "set_expectations_bot_office_hours_header": "Kad komanda atgriezīsies:",
    "set_expectations_bot_sms_header": "Jūs saņemsiet atbildes šeit un īsziņā:",
    "set_expectations_replies_to_email": "Jūs saņemsiet atbildes šeit un uz e-pasta adresi <b>{user_email}</b> .",
    "set_expectations_replies_to_email_without_bold_text": "Jūs saņemsiet atbildes šeit un uz e-pasta adresi {user_email}",
    "set_expectations_team_reply_asap": "Komanda atbildēs, tiklīdz varēs",
    "shared_by": "Kopīgoja",
    "show_less": "Rādīt mazāk",
    "single_article": "1 raksts",
    "single_collection": "1 kolekcija",
    "source": "Avots",
    "sources": "Avoti",
    "start_a_conversation": "Sāciet sarunu",
    "start_conversation": "Sākt sarunu",
    "still_generating_answer": "Joprojām ģenerē atbildi...",
    "still_thinking": "Vēl domā...",
    "still_working_on_it": "Joprojām sagatavo atbildi...",
    "tasks_space_title": "Uzdevumi",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} atbildēs, cik vien ātri varēs",
    "teammate_changes_team_assignment_of_a_conversation": "Komandas biedrs ir mainījis sarunas uzdevumu...",
    "tell_us_what_you_need": "Pastāstiet mums, attiecībā uz ko jums nepieciešama palīdzība, un mūsu bots darīs visu iespējamo, lai sniegtu atbildi",
    "the_team_can_help_if_needed": "Mūsu komanda varēs palīdzēt, ja nepieciešams",
    "the_team_typically_replies_in_10800": "Komanda parasti atbild ātrāk par 3 st.",
    "the_team_typically_replies_in_120": "Komanda parasti atbild ātrāk nekā 2 minūšu laikā",
    "the_team_typically_replies_in_1200": "Komanda parasti atbild ātrāk par 20 min.",
    "the_team_typically_replies_in_14400": "Komanda parasti atbild ātrāk par 4 st.",
    "the_team_typically_replies_in_172800": "Komanda parasti atbild 2 dienu laikā",
    "the_team_typically_replies_in_180": "Komanda parasti atbild ātrāk nekā 3 minūšu laikā",
    "the_team_typically_replies_in_1800": "Komanda parasti atbild ātrāk par 30 min.",
    "the_team_typically_replies_in_18000": "Komanda parasti atbild ātrāk par 5 st.",
    "the_team_typically_replies_in_21600": "Komanda parasti atbild ātrāk par 6 st.",
    "the_team_typically_replies_in_240": "Komanda parasti atbild ātrāk nekā 4 minūšu laikā",
    "the_team_typically_replies_in_259200": "Komanda parasti atbild 3 dienu laikā",
    "the_team_typically_replies_in_300": "Komanda parasti atbild ātrāk par 5 min.",
    "the_team_typically_replies_in_345600": "Komanda parasti atbild 4 dienu laikā",
    "the_team_typically_replies_in_3600": "Komanda parasti atbild ātrāk par 1 st.",
    "the_team_typically_replies_in_43200": "Komanda parasti atbild mazāk nekā 12 stundu laikā",
    "the_team_typically_replies_in_432000": "Komanda parasti atbild 5 dienu laikā",
    "the_team_typically_replies_in_518400": "Komanda parasti atbild 6 dienu laikā",
    "the_team_typically_replies_in_60": "Komanda parasti atbild ātrāk nekā 1 minūtes laikā",
    "the_team_typically_replies_in_600": "Komanda parasti atbild ātrāk par 10 min.",
    "the_team_typically_replies_in_604800": "Komanda parasti atbild ātrāk par 1 ned.",
    "the_team_typically_replies_in_7200": "Komanda parasti atbild ātrāk par 2 st.",
    "the_team_typically_replies_in_86400": "Komanda parasti atbild 1 dienas laikā",
    "the_team_typically_replies_in_900": "Komandas atbilde parasti ir ātrāk nekā 15 min. laikā",
    "the_team_typically_replies_in_day": "Komanda parasti atbild dienas laikā.",
    "the_team_typically_replies_in_hours": "Komanda parasti atbild pāris stundu laikā.",
    "the_team_typically_replies_in_minutes": "Komanda parasti atbild pāris minūšu laikā.",
    "the_team_typically_replies_in_under_n_hours": "Komanda parasti atbild ātrāk par {hours} stundām.",
    "the_team_typically_replies_in_under_n_min": "Komanda parasti atbild ātrāk par {minutes} minūtēm.",
    "the_team_typically_replies_in_unknown": "Komanda atbildēs, cik vien ātri varēs",
    "the_team_typically_replies_in_week": "Komanda parasti atbild nedēļas laikā.",
    "thinking": "Domā...",
    "tickets_cta_text": "Vai jums ir jautājums par šo biļeti?",
    "tickets_have_a_question": "Vai jums ir jautājums par šo tēmu?",
    "tickets_space_title": "Biļetes",
    "time_future_1h": "1 stunda",
    "time_future_2h": "2 stundas",
    "time_future_30m": "30 minūtes",
    "time_future_3h": "3 stundas",
    "time_future_day_0": "Pirmdien",
    "time_future_day_1": "Otrdien",
    "time_future_day_2": "Trešdien",
    "time_future_day_3": "Ceturtdien",
    "time_future_day_4": "Piektdien",
    "time_future_day_5": "Sestdien",
    "time_future_day_6": "Svētdien",
    "time_future_later_today": "Vēlāk šodien",
    "time_future_next_week": "Nākamnedēļ",
    "time_future_tomorrow": "Rīt",
    "try_use_different_keywords": "Mēģiniet izmantot citus atslēgvārdus vai ierakstiet visu jautājumu",
    "upfront_collection_form_close_out": "Jūs varat šeit atgriezties jebkurā laikā, lai redzētu atbildes un nosūtītu atjauninājumus.",
    "upfront_collection_form_introduction": "Norādiet mazliet informācijas, lai mūsu komanda varētu jums labāk un ātrāk atbildēt.",
    "upfront_collection_form_submission_confirmation": "Paldies, ka nosūtījāt šos datus. Lai vēlāk ietaupītu laiku, pievienojiet plašāku informāciju jau tagad.",
    "upfront_collection_form_subtitle": "Nosūtiet komandai mazliet informācijas",
    "upfront_collection_form_subtitle_submitted": "Viņi atcerēsies šo informāciju",
    "upfront_collection_form_title": "Saņemiet palīdzību ātrāk",
    "upfront_collection_form_title_submitted": "Nosūtīts komandai!",
    "view_all": "Skatīt visu",
    "view_conversation": "Skatīt sarunu",
    "we_are_here_to_help": "Mēs esam šeit, lai jums palīdzētu",
    "we_run_on_intercom": "Programma darbojas, izmantojot Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kad komandas biedrs maina sarunas statusu...",
    "working_on_it": "Sagatavo atbildi...",
    "you_are_all_up_to_date": "Tagad jums ir visa vajadzīgā informācija!",
    "your_checklists": "Jūsu kontrolsaraksti",
    "your_conversations": "Jūsu sarunas",
    "your_recent_conversations": "Nesenās sarunas",
    "your_recent_messages": "Jaunākie ziņojumi"
  },
  "mn": {
    "a_day": "Өдөр",
    "a_few_hours": "Хэдэн цаг",
    "a_few_minutes": "Хэдэн минут",
    "a_week": "Долоо хоног",
    "active_state": "Идэвхтэй",
    "ai_answer": "AI хариулт",
    "ai_answer_information": "AI Хариулт, мэдээлэл",
    "ai_bot": "AI робот",
    "almost_there": "Бараг боллоо...",
    "answer": "Хариулт",
    "answer_bot_acknowledge_duplicate_content": "Та үүнийг аль хэдийн харсан боловч энэ бол хамгийн сайн хариулт юм.",
    "answer_bot_all_done": "Энэ нь тус болсон 👍",
    "answer_bot_ask_another_question": "Өөр асуулт асуу 🔁",
    "answer_bot_ask_something_else": "Өөр юм асуу 🔁",
    "answer_bot_connect_to_an_agent": "Агент руу холбогдоно уу 👤",
    "answer_bot_did_that_help": "Энэ хариулт тус болсон уу, танд өөр зүйл хэрэгтэй юу?",
    "answer_bot_followup_expectations_prefix": "Манай баг энэ талаар тантай эргэн холбогдох болно.",
    "answer_bot_get_more_help": "Илүү тусламж авах 🌐",
    "answer_bot_get_more_help_followup": "Энэ холбоос тусалж магадгүй:",
    "answer_bot_i_have_another_question": "Надад өөр асуулт байна 🔁",
    "answer_bot_im_not_done_yet": "Би хараахан дуусаагүй байна 🔁",
    "answer_bot_keep_waiting": "Үгүй ээ, надад тусламж хэрэгтэй хэвээр байна 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Та асуултаа дахин хэлэх эсвэл нэмэлт тусламж авах боломжтой",
    "answer_bot_looping_first_no_answer": "Уучлаарай, ботын хувьд би үүнд хариулт олсонгүй.",
    "answer_bot_looping_first_no_answer_followup": "Та асуултаа дахин хэлэх эсвэл манай багтай ярилцаж болно",
    "answer_bot_looping_no_answer": "Уучлаарай, ботын хувьд би үүнд хариулт олсонгүй. Таны хийж чадах зүйл энд байна",
    "answer_bot_more_answers": "Надад илүү ихийг харуулна уу 👀",
    "answer_bot_no_answer_found": "Уучлаарай, бидэнд үүнд өгөх товч хариу байхгүй байна. Энд зарим өөр сонголтууд бий.",
    "answer_bot_resolve_followup": "Хэрэв танд ямар нэг асуудал байвал энэ ямагт хариулж бичих боломжтой.",
    "answer_bot_talk_to_person": "Хүнтэй ярих 👤",
    "answer_bot_that_answered_my_question": "Энэ миний асуултанд хариулсан 👍",
    "answer_bot_thats_it": "Ингээд л боллоо 👍",
    "answer_bot_wait_for_the_team": "Багийг хүлээнэ үү 💬",
    "answerbot_ask_for_more_context": "Мэдээж. Биднийг хүлээж байх зуур та нөхцөл байдлынхаа талаар ямар нэг дэлгэрэнгүй мэдээлэл эсвэл асуултаа хуваалцах боломжтой юу? Энэ нь бидэнд хурдан хариулт олоход туслах бөгөөд би танд илүү их мэдээлэл олж өгч чадах болно.",
    "answerbot_introduce_sneaky_answer": "Энэ нэмэлт хам сэдвийг өгсөнд баярлалаа. Би танд туслах зарим нэг шинэ мэдээлэл олсон.",
    "article_search_hint_text": "Нийтлэл хайх ...",
    "ask_a_question": "Асуулт асуугаарай",
    "ask_a_question_placeholder": "Асуулт асуу...",
    "ask_a_question_with_ellipsis": "Асуулт асуугаарай...",
    "ask_another_question_with_ellipsis": "Өөр асуулт асуугаарай...",
    "ask_for_the_team_if_needed": "Шаардлагатай бол багаас асуугаарай",
    "ask_me_a_question": "Надаас асуулт асуугаарай",
    "ask_our_bot_a_question": "Манай роботоос асуулт асуугаарай",
    "avatar": "Аватар",
    "avatars": "Аватарууд",
    "bot": "Bot",
    "bot_answers_instantly": "Робот шууд хариулдаг",
    "bot_expectation_text": "Манай бот олон нийтлэг асуултад хариулж чадна",
    "bot_intro_default_part_1": "Сайн уу! Та боттой ярьж байна. Би энд таны асуултанд хариулах гэж байна, гэхдээ манай багтай ярих сонголт үргэлж танд бий шүү.",
    "bot_intro_default_part_2": "За тэгээд, таныг өнөөдөр юу энд авчирав?",
    "bot_is_still_thinking": "Робот бодсоор л байна ...",
    "bot_is_thinking": "Робот бодож байна ...",
    "browse_collections": "Цуглуулгыг үзэх",
    "chat_with_us": "Бидэнтэй чатлаарай",
    "check_later_for_updates": "Шинэчлэлтүүдийг дараа дахин шалгана уу.",
    "checklist_card_first_step": "Эхний алхам",
    "checklist_card_next_step": "Дараагийн алхам",
    "checklist_completed": "Дууссан",
    "checklist_in_progress_time_remaining_plural": "Ойролцоогоор {minutes} минут үлдсэн",
    "checklist_in_progress_time_remaining_singular": "1 минут орчим үлдлээ",
    "checklist_mark_step_as_complete": "Дууссан гэж тэмдэглэ",
    "checklist_not_started_time_remaining_plural": "Ойролцоогоор {minutes} минут",
    "checklist_not_started_time_remaining_singular": "1 минут орчим",
    "checklist_number_of_steps_plural": "{number_of_steps} алхам",
    "checklist_number_of_steps_progress_plural": "{total_steps} {steps_completed} нь дууссан",
    "checklist_number_of_steps_progress_singular": "{total_steps} -н 1 нь хийгдсэн",
    "checklist_number_of_steps_singular": "1 алхам",
    "checklist_sender_name": "{name} хуваалцсан",
    "checklist_step_action_button": "Алхам үйлдлийн товчлуур",
    "checklist_step_completed": "Дууссан",
    "contact_support": "Дэмжлэгтэй холбоо барина уу",
    "contact_us": "Бидэнтэй холбоо барина уу",
    "continue_the_conversation": "Яриаг үргэлжлүүлэх",
    "conversation_card_office_hours_header": "Бид буцаж онлайн болоход",
    "conversation_card_reply_time_header": "Бидний хариу өгөх энгийн хугацаа",
    "conversation_card_reply_time_header_m5": "Ердийн хариу өгөх хугацаа",
    "conversation_card_unknown_response_time": "Бид аль болох хурдан хариулна",
    "conversation_part_sample_text": "Сайн уу, би яаж туслах вэ? Шаардлагатай бол манай багтай ярилцах сонголт танд үргэлж байх болно.",
    "conversations_space_title": "Харилцан яриа",
    "create_a_ticket": "Тасалбар үүсгэх",
    "currently_no_help_articles": "Одоогоор туслах нийтлэл байхгүй байна.",
    "customer_sends_their_first_message": "Үйлчлүүлэгч анхны мессежээ илгээж байна...",
    "expected_response_delay_10800": "3 цагийн дотор",
    "expected_response_delay_120": "2 минутын дотор",
    "expected_response_delay_1200": "20 минутын дотор",
    "expected_response_delay_14400": "4 цагийн дотор",
    "expected_response_delay_172800": "2 өдөр",
    "expected_response_delay_180": "3 минутын дотор",
    "expected_response_delay_1800": "30 минутын дотор",
    "expected_response_delay_18000": "5 цагийн дотор",
    "expected_response_delay_21600": "6 цагийн дотор",
    "expected_response_delay_240": "4 минутын дотор",
    "expected_response_delay_259200": "3 өдөр",
    "expected_response_delay_300": "5 минутын дотор",
    "expected_response_delay_345600": "4 өдөр",
    "expected_response_delay_3600": "1 цагийн дотор",
    "expected_response_delay_43200": "12 цагийн дотор",
    "expected_response_delay_432000": "5 өдөр",
    "expected_response_delay_518400": "6 хоног",
    "expected_response_delay_60": "1 минутын дотор",
    "expected_response_delay_600": "10 минутын дотор",
    "expected_response_delay_604800": "1 долоо хоногийн дотор",
    "expected_response_delay_7200": "2 цагийн дотор",
    "expected_response_delay_86400": "1 өдөр",
    "expected_response_delay_900": "15 минутын дотор",
    "expected_response_delay_unknown": "Бид аль болох хурдан",
    "extra_labels_count": "+ {labels_count} шошгууд",
    "for_best_results_provide_detail": "Хамгийн сайн үр дүнд хүрэхийн тулд аль болох дэлгэрэнгүй мэдээлэл өгнө үү",
    "generating_answer": "Хариулт үүсгэж байна...",
    "generic_triage_default": "Таныг зөв хүнтэй холбоход бидэнд бага зэрэг мэдээлэл хэрэгтэй байна",
    "get_an_instant_answer": "Шууд хариу авах",
    "get_help": "Тусламж авах",
    "go_to": "руу очих {app_name}",
    "help_space_title": "Тусламж",
    "home_header_mobile_hello_firstname": "Сайн байна уу, {firstname} .",
    "home_header_mobile_hello_there": "Сайн уу",
    "home_space_title": "Гэр",
    "how_can_we_help": "Бид танд хэрхэн туслах вэ?",
    "how_would_you_rate_this": "Та үүнийг хэрхэн үнэлэх вэ?",
    "learn_more": "Дэлгэрэнгүй",
    "message_placeholder": "Мессеж...",
    "messages_space_title": "Мессежүүд",
    "messenger_bot_reply_time": "Манай робот шууд хариулах болно",
    "messenger_settings_introduction": "Бид танд хэрхэн туслах вэ?",
    "multiple_articles": "{total_articles} Нийтлэл",
    "multiple_collections": "{total_collection} collections",
    "new_conversation": "Шинэ харилцан яриа",
    "news": "Мэдээ",
    "news_empty_title": "Одоогоор мэдээ алга",
    "news_latest_subtitle": "{team_name} багаас",
    "news_latest_title": "Хамгийн сүүлийн үеийн",
    "news_older_title": "Хуучин",
    "news_space_title": "Мэдээ",
    "newsfeed": "Мэдээний тойм",
    "no_articles_to_display": "Одоогоор нийтлэл алга",
    "no_help_articles_to_display": "Туслах нийтлэл байхгүй",
    "no_results_for_searchterm": "{searchTerm}-ийн хайлт байхгүй",
    "no_tasks": "Ажил алга",
    "no_tasks_available_currently": "Одоогоор хийх боломжтой ажил алга.",
    "ok_the_team_typically_replies_in_day": "За, баг ерөнхийдөө нэг өдрийн дараа хариулдаг.",
    "ok_the_team_typically_replies_in_hours": "За, баг ерөнхийдөө хэдэн цагийн дараа хариулдаг.",
    "ok_the_team_typically_replies_in_minutes": "За, баг ерөнхийдөө хэдэн минутын дараа хариулдаг.",
    "ok_the_team_typically_replies_in_under_n_hours": "За, баг ерөнхийдөө {hours} цагийн дотор хариулдаг.",
    "ok_the_team_typically_replies_in_under_n_min": "За, баг ерөнхийдөө {minutes} минутын дотор хариулдаг.",
    "ok_the_team_typically_replies_in_week": "За, баг ерөнхийдөө нэг долоо хоногийн дотор хариулдаг.",
    "our_bot_and_team_can_help": "Манай бот болон баг тусалж чадна",
    "our_bot_answers_instantly": "Манай бот шууд хариулдаг",
    "powered_by_intercom": "Intercom-н ажиллуулдаг",
    "previous_bot_hands_over": "Өмнөх ботын контент...",
    "proactive_expected_response_delay_10800": "Ерөнхийдөө 3 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_120": "Ерөнхийдөө 2 минутын дотор хариулдаг",
    "proactive_expected_response_delay_1200": "Ерөнхийдөө 20 минутын дотор хариулдаг",
    "proactive_expected_response_delay_14400": "Ерөнхийдөө 4 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_172800": "Ерөнхийдөө 2 өдрийн дараа хариулдаг",
    "proactive_expected_response_delay_180": "Ерөнхийдөө 3 минутын дотор хариулдаг",
    "proactive_expected_response_delay_1800": "Ерөнхийдөө 30 минутын дотор хариулдаг",
    "proactive_expected_response_delay_18000": "Ерөнхийдөө 5 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_21600": "Ерөнхийдөө 6 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_240": "Ерөнхийдөө 4 минутын дотор хариулдаг",
    "proactive_expected_response_delay_259200": "Ихэвчлэн 3 өдрийн дотор хариулдаг",
    "proactive_expected_response_delay_300": "Ерөнхийдөө 5 минутын дотор хариулдаг",
    "proactive_expected_response_delay_345600": "Ихэвчлэн 4 өдрийн дотор хариулдаг",
    "proactive_expected_response_delay_3600": "Ерөнхийдөө 1 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_43200": "Ихэвчлэн 12 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_432000": "Ихэвчлэн 5 өдрийн дотор хариулдаг",
    "proactive_expected_response_delay_518400": "Ихэвчлэн 6 өдрийн дотор хариулдаг",
    "proactive_expected_response_delay_60": "Ерөнхийдөө 1 минутын дотор хариулдаг",
    "proactive_expected_response_delay_600": "Ерөнхийдөө 10 минутын дотор хариулдаг",
    "proactive_expected_response_delay_604800": "Ерөнхийдөө 1 долоо хоногийн дотор хариулдаг",
    "proactive_expected_response_delay_7200": "Ерөнхийдөө 2 цагийн дотор хариулдаг",
    "proactive_expected_response_delay_86400": "Ерөнхийдөө 1 өдрийн дараа хариулдаг",
    "proactive_expected_response_delay_900": "Ихэвчлэн 15 минутаас бага хугацаанд хариулдаг",
    "proactive_expected_response_delay_unknown": "Аль болох хурдан хариулна",
    "profile_location": "{time}-д {location}-д",
    "recent_ticket": "Саяхны тасалбар",
    "recent_tickets": "Саяхны тасалбарууд",
    "search_browse_empty_state_text": "Уучлаарай, бид үүнд ямар нэгэн үр дүн олсонгүй. Хайх үгээ өөрчлөөд оролдоно уу.",
    "search_for_help": "Тусламж хайх",
    "see_all": "Бүгдийг харах",
    "see_all_conversations": "Бүх яриагаа харах",
    "see_previous_conversations": "Өмнөх харилцан яриагаа харах",
    "send": "Илгээх",
    "send_a_message": "Мессеж илгээх...",
    "send_a_message_reply_time": "Ердийн хариу өгөх хугацаа нь",
    "send_us_a_message": "Бидэнд зурвас илгээнэ үү",
    "set_expectations_bot_email_header": "Та хариуг энд болон имэйлээр авна:",
    "set_expectations_bot_office_hours_header": "Баг эргэж ирэхэд:",
    "set_expectations_bot_sms_header": "Та хариуг энд болон мессежээр авна:",
    "set_expectations_replies_to_email": "Та энд болон <b>{user_email}</b>-д хариулт авах болно.",
    "set_expectations_replies_to_email_without_bold_text": "Та энд болон {user_email}-д хариулт авах болно",
    "set_expectations_team_reply_asap": "Баг аль болох хурдан хариулна.",
    "shared_by": "Хуваалцсан",
    "show_less": "Бага харуулах",
    "single_article": "1 нийтлэл",
    "single_collection": "1 collection",
    "source": "Эх сурвалж",
    "sources": "Эх сурвалж",
    "start_a_conversation": "Харилцан яриа эхлүүлэх...",
    "start_conversation": "Харилцан яриа эхлүүлэх...",
    "still_generating_answer": "Хариултыг үүсгэсээр байна...",
    "still_thinking": "Бодсоор байна...",
    "still_working_on_it": "Үүн дээр ажилласаар л байна...",
    "tasks_space_title": "Даалгаврууд",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} аль болох хурдан хариулна",
    "teammate_changes_team_assignment_of_a_conversation": "Багийн найз ярианы даалгаврыг өөрчилсөн...",
    "tell_us_what_you_need": "Танд ямар тусламж хэрэгтэй байгаагаа бидэнд хэлбэл манай бот таны хариултыг өгөх бүхнийг хийх болно",
    "the_team_can_help_if_needed": "Шаардлагатай бол манай баг танд тусална",
    "the_team_typically_replies_in_10800": "Баг ерөнхийдөө 3 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_120": "Баг ерөнхийдөө 2 минутын дотор хариулдаг",
    "the_team_typically_replies_in_1200": "Баг ерөнхийдөө 20 минутын дотор хариулдаг",
    "the_team_typically_replies_in_14400": "Баг ерөнхийдөө 4 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_172800": "Баг ерөнхийдөө 2 өдрийн дараа хариулдаг",
    "the_team_typically_replies_in_180": "Баг ерөнхийдөө 3 минутын дотор хариулдаг",
    "the_team_typically_replies_in_1800": "Баг ерөнхийдөө 30 минутын дотор хариулдаг",
    "the_team_typically_replies_in_18000": "Баг ерөнхийдөө 5 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_21600": "Баг ерөнхийдөө 6 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_240": "Баг ерөнхийдөө 4 минутын дотор хариулдаг",
    "the_team_typically_replies_in_259200": "Баг ихэвчлэн 3 өдрийн дотор хариулдаг",
    "the_team_typically_replies_in_300": "Баг ерөнхийдөө 5 минутын дотор хариулдаг",
    "the_team_typically_replies_in_345600": "Баг ихэвчлэн 4 өдрийн дотор хариулдаг",
    "the_team_typically_replies_in_3600": "Баг ерөнхийдөө 1 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_43200": "Баг ихэвчлэн 12 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_432000": "Баг ихэвчлэн 5 өдрийн дотор хариулдаг",
    "the_team_typically_replies_in_518400": "Баг ихэвчлэн 6 өдрийн дотор хариулдаг",
    "the_team_typically_replies_in_60": "Баг ерөнхийдөө 1 минутын дотор хариулдаг",
    "the_team_typically_replies_in_600": "Баг ерөнхийдөө 10 минутын дотор хариулдаг",
    "the_team_typically_replies_in_604800": "Баг ерөнхийдөө 1 долоо хоногийн дотор хариулдаг",
    "the_team_typically_replies_in_7200": "Баг ерөнхийдөө 2 цагийн дотор хариулдаг",
    "the_team_typically_replies_in_86400": "Баг ерөнхийдөө 1 өдрийн дараа хариулдаг",
    "the_team_typically_replies_in_900": "Баг ерөнхийдөө 15 минутын дотор хариулдаг",
    "the_team_typically_replies_in_day": "Баг ерөнхийдөө нэг өдрийн дараа хариулдаг.",
    "the_team_typically_replies_in_hours": "Баг ерөнхийдөө хэдэн цагийн дараа хариулдаг.",
    "the_team_typically_replies_in_minutes": "Баг ерөнхийдөө хэдэн минутын дараа хариулдаг.",
    "the_team_typically_replies_in_under_n_hours": "Баг ерөнхийдөө {hours} цагийн дотор хариулдаг.",
    "the_team_typically_replies_in_under_n_min": "Баг ерөнхийдөө {minutes} минутын дотор хариулдаг.",
    "the_team_typically_replies_in_unknown": "Баг аль болох хурдан хариулна",
    "the_team_typically_replies_in_week": "Баг ерөнхийдөө нэг долоо хоногийн дотор хариулдаг.",
    "thinking": "Бодож байна...",
    "tickets_cta_text": "Энэ тасалбарын талаар асуух зүйл байна уу?",
    "tickets_have_a_question": "Энэ асуудлын талаар асуух зүйл байна уу?",
    "tickets_space_title": "Тасалбар",
    "time_future_1h": "1 цаг",
    "time_future_2h": "2 цаг",
    "time_future_30m": "30 минут",
    "time_future_3h": "3 цаг",
    "time_future_day_0": "Даваа",
    "time_future_day_1": "Мягмар",
    "time_future_day_2": "Лхагва",
    "time_future_day_3": "Пүрэв",
    "time_future_day_4": "Баасан",
    "time_future_day_5": "Бямба",
    "time_future_day_6": "Ням",
    "time_future_later_today": "Өнөө орой",
    "time_future_next_week": "Дараа долоо хоногт",
    "time_future_tomorrow": "Маргааш",
    "try_use_different_keywords": "Өөр гар ашиглах эсвэл бүтэн асуулт шивэхийг хичээ",
    "upfront_collection_form_close_out": "Та хариу харах болон шинэчлэлт илгээхийн тулд энд эргэн ирж болно.",
    "upfront_collection_form_introduction": "Баг илүү сайн, илүү хурдан хариу өгөхөд туслахын тулд зарим нөхцөлийг хуваалцаарай.",
    "upfront_collection_form_submission_confirmation": "Тэрхүү нөхцөлийг илгээсэнд баярлалаа. Дараа цаг хэмнэхийн тулд одоо илүү дэлгэрэнгүйг чөлөөтэй нэмээрэй.",
    "upfront_collection_form_subtitle": "Багт зарим нөхцөлийг илгээх",
    "upfront_collection_form_subtitle_submitted": "Тэд энэ нөхцөлийг санаж байх болно",
    "upfront_collection_form_title": "Илүү хурдан тусламж авах",
    "upfront_collection_form_title_submitted": "Багт илгээсэн!",
    "view_all": "Бүгдийг үзэх",
    "view_conversation": "Харилцан яриаг үзэх",
    "we_are_here_to_help": "Бид туслахаар энд байна",
    "we_run_on_intercom": "Бид Intercom-г ажиллуулдаг",
    "when_a_teammate_changes_the_state_of_a_conversation": "Багийн найз ярианы төлөвийг өөрчлөхөд...",
    "working_on_it": "Үүндээр ажиллаж байна...",
    "you_are_all_up_to_date": "Та бүгдийг жин тан хийсэн!",
    "your_checklists": "Таны шалгах хуудас",
    "your_conversations": "Таны харилцан яриа",
    "your_recent_conversations": "Сүүлийн үеийн харилцан ярианууд",
    "your_recent_messages": "Сүүлийн мессежүүд"
  },
  "ms": {
    "a_day": "Sehari",
    "a_few_hours": "Beberapa jam",
    "a_few_minutes": "Beberapa minit",
    "a_week": "Seminggu",
    "active_state": "Aktif",
    "ai_answer": "Jawapan AI",
    "ai_answer_information": "Jawapan AI, maklumat",
    "ai_bot": "Bot AI",
    "almost_there": "Hampir selesai...",
    "answer": "Jawapan",
    "answer_bot_acknowledge_duplicate_content": "Anda telah melihat ini, tetapi ini jawapan terbaik yang tersedia.",
    "answer_bot_all_done": "Itu membantu 👍",
    "answer_bot_ask_another_question": "Tanya soalan lain 🔁",
    "answer_bot_ask_something_else": "Tanya sesuatu yang lain 🔁",
    "answer_bot_connect_to_an_agent": "Sambung kepada ejen 👤",
    "answer_bot_did_that_help": "Adakah jawapan itu membantu, atau adakah anda sedang mencari sesuatu yang lain?",
    "answer_bot_followup_expectations_prefix": "Pasukan akan menghubungi anda mengenai perkara ini.",
    "answer_bot_get_more_help": "Dapatkan lebih banyak bantuan 🌐",
    "answer_bot_get_more_help_followup": "Pautan ini mungkin membantu:",
    "answer_bot_i_have_another_question": "Saya mempunyai soalan lain 🔁",
    "answer_bot_im_not_done_yet": "Saya belum selesai 🔁",
    "answer_bot_keep_waiting": "Tidak, saya masih memerlukan bantuan 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Anda boleh mengungkapkan semula soalan anda atau mendapatkan bantuan tambahan",
    "answer_bot_looping_first_no_answer": "Maaf, sebagai bot saya tidak dapat mencari jawapan untuk itu.",
    "answer_bot_looping_first_no_answer_followup": "Anda boleh mengungkapkan semula soalan anda atau bercakap dengan pasukan kami",
    "answer_bot_looping_no_answer": "Maaf, sebagai bot saya tidak dapat mencari jawapan untuk itu. Ini apa yang boleh anda lakukan",
    "answer_bot_more_answers": "Tunjukkan lebih banyak lagi👀",
    "answer_bot_no_answer_found": "Maaf, kami tidak mempunyai jawapan pantas untuk itu. Berikut adalah beberapa pilihan lain.",
    "answer_bot_resolve_followup": "Anda sentiasa boleh membalas di sini, jika ada perkara lain yang timbul.",
    "answer_bot_talk_to_person": "Bercakap dengan seseorang 👤",
    "answer_bot_that_answered_my_question": "Itu menjawab soalan saya 👍",
    "answer_bot_thats_it": "Itu sahaja 👍",
    "answer_bot_wait_for_the_team": "Menunggu pasukan 💬",
    "answerbot_ask_for_more_context": "Sudah tentu. Sementara kita menunggu, bolehkah anda berkongsi butiran lanjut tentang situasi atau soalan anda? Ia akan membantu kami untuk mendapatkan jawapan dengan lebih pantas dan saya boleh mendapatkan lebih banyak maklumat untuk anda.",
    "answerbot_introduce_sneaky_answer": "Terima kasih untuk konteks tambahan itu. Saya menemui beberapa maklumat baharu yang mungkin membantu anda.",
    "article_search_hint_text": "Cari artikel...",
    "ask_a_question": "Tanya soalan",
    "ask_a_question_placeholder": "Tanya soalan...",
    "ask_a_question_with_ellipsis": "Tanya soalan...",
    "ask_another_question_with_ellipsis": "Tanya soalan lain...",
    "ask_for_the_team_if_needed": "Minta bantuan pasukan jika perlu",
    "ask_me_a_question": "Tanya soalan kepada saya",
    "ask_our_bot_a_question": "Tanya soalan kepada bot kami",
    "avatar": "Avatar",
    "avatars": "Avatar",
    "bot": "Bot",
    "bot_answers_instantly": "Bot menjawab serta-merta",
    "bot_expectation_text": "Bot kami boleh menjawab banyak soalan biasa",
    "bot_intro_default_part_1": "Hai! Ini bot sedang bercakap. Saya di sini untuk menjawab soalan anda, tetapi anda akan sentiasa mempunyai pilihan untuk bercakap dengan pasukan kami.",
    "bot_intro_default_part_2": "Jadi apa yang membawa anda ke sini hari ini?",
    "bot_is_still_thinking": "Bot masih berfikir...",
    "bot_is_thinking": "Bot sedang berfikir...",
    "browse_collections": "Semak imbas koleksi",
    "chat_with_us": "Bual dengan kami",
    "check_later_for_updates": "Semak semula pada waktu lain untuk kemas kini.",
    "checklist_card_first_step": "Langkah pertama",
    "checklist_card_next_step": "Langkah seterusnya",
    "checklist_completed": "Selesai",
    "checklist_in_progress_time_remaining_plural": "Tinggal kira-kira {minutes} minit",
    "checklist_in_progress_time_remaining_singular": "Tinggal kira-kira 1 minit",
    "checklist_mark_step_as_complete": "Tandakan sebagai selesai",
    "checklist_not_started_time_remaining_plural": "Kira-kira {minutes} minit",
    "checklist_not_started_time_remaining_singular": "Kira-kira 1 minit",
    "checklist_number_of_steps_plural": "{number_of_steps} langkah",
    "checklist_number_of_steps_progress_plural": "{steps_completed} {total_steps} selesai",
    "checklist_number_of_steps_progress_singular": "1 daripada {total_steps} selesai",
    "checklist_number_of_steps_singular": "1 langkah",
    "checklist_sender_name": "Dikongsi oleh {name}",
    "checklist_step_action_button": "Butang tindakan langkah",
    "checklist_step_completed": "Selesai",
    "contact_support": "Hubungi sokongan",
    "contact_us": "Hubungi kami",
    "continue_the_conversation": "Teruskan perbualan",
    "conversation_card_office_hours_header": "Kami akan kembali dalam talian",
    "conversation_card_reply_time_header": "Masa biasa kami membalas",
    "conversation_card_reply_time_header_m5": "Masa biasa membalas",
    "conversation_card_unknown_response_time": "Kami akan membalas secepat mungkin",
    "conversation_part_sample_text": "Hai, bagaimana saya boleh membantu? Anda sentiasa mempunyai pilihan untuk bercakap dengan pasukan kami jika perlu.",
    "conversations_space_title": "Perbualan",
    "create_a_ticket": "Cipta tiket",
    "currently_no_help_articles": "Tiada artikel bantuan tersedia buat masa ini.",
    "customer_sends_their_first_message": "Pelanggan menghantar mesej pertama mereka...",
    "expected_response_delay_10800": "kurang dari 3 jam",
    "expected_response_delay_120": "kurang dari 2 minit",
    "expected_response_delay_1200": "kurang dari 20 minit",
    "expected_response_delay_14400": "kurang dari 4 jam",
    "expected_response_delay_172800": "2 hari",
    "expected_response_delay_180": "kurang dari 3 minit",
    "expected_response_delay_1800": "kurang dari 30 minit",
    "expected_response_delay_18000": "kurang dari 5 jam",
    "expected_response_delay_21600": "kurang dari 6 jam",
    "expected_response_delay_240": "kurang dari 4 minit",
    "expected_response_delay_259200": "3 hari",
    "expected_response_delay_300": "kurang dari 5 minit",
    "expected_response_delay_345600": "4 hari",
    "expected_response_delay_3600": "kurang dari 1 jam",
    "expected_response_delay_43200": "Kurang dari 12 jam",
    "expected_response_delay_432000": "5 hari",
    "expected_response_delay_518400": "6 hari",
    "expected_response_delay_60": "kurang dari 1 minit",
    "expected_response_delay_600": "kurang dari 10 minit",
    "expected_response_delay_604800": "kurang dari 1 minggu",
    "expected_response_delay_7200": "kurang dari 2 jam",
    "expected_response_delay_86400": "1 hari",
    "expected_response_delay_900": "kurang dari 15 minit",
    "expected_response_delay_unknown": "Sebaik sahaja kami boleh",
    "extra_labels_count": "+ {labels_count} label",
    "for_best_results_provide_detail": "Untuk hasil terbaik, berikan sebanyak mungkin perincian",
    "generating_answer": "Menjana jawapan...",
    "generic_triage_default": "Kami hanya memerlukan sedikit lagi maklumat untuk menghubungkan anda dengan orang yang betul",
    "get_an_instant_answer": "Dapatkan jawapan segera",
    "get_help": "Dapatkan bantuan",
    "go_to": "Pergi ke {app_name}",
    "help_space_title": "Bantuan",
    "home_header_mobile_hello_firstname": "Helo, {firstname}.",
    "home_header_mobile_hello_there": "Helo",
    "home_space_title": "Utama",
    "how_can_we_help": "Bagaimanakah kami boleh membantu?",
    "how_would_you_rate_this": "Bagaimanakah anda menilai ini?",
    "learn_more": "Ketahui lebih lanjut",
    "message_placeholder": "Mesej…",
    "messages_space_title": "Mesej",
    "messenger_bot_reply_time": "Bot kami akan membalas dengan serta-merta",
    "messenger_settings_introduction": "Bagaimanakah kami boleh membantu?",
    "multiple_articles": "{total_articles} Artikel",
    "multiple_collections": "Koleksi {total_collection}",
    "new_conversation": "Perbualan baharu",
    "news": "Berita",
    "news_empty_title": "Belum ada berita",
    "news_latest_subtitle": "Daripada Pasukan {team_name}",
    "news_latest_title": "Terkini",
    "news_older_title": "Lebih lama",
    "news_space_title": "Berita",
    "newsfeed": "Suapan Berita",
    "no_articles_to_display": "Tiada artikel lagi",
    "no_help_articles_to_display": "Tiada artikel bantuan",
    "no_results_for_searchterm": "Tiada hasil untuk {searchTerm}",
    "no_tasks": "Tiada tugas",
    "no_tasks_available_currently": "Tiada tugasan tersedia buat masa ini.",
    "ok_the_team_typically_replies_in_day": "Ok, pasukan biasanya membalas dalam masa sehari.",
    "ok_the_team_typically_replies_in_hours": "Ok, pasukan biasanya membalas dalam masa beberapa jam.",
    "ok_the_team_typically_replies_in_minutes": "Ok, pasukan biasanya membalas dalam masa beberapa minit.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, pasukan biasanya membalas dalam masa kurang dari {hours} jam.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, pasukan biasanya membalas dalam masa kurang dari {minutes}m.",
    "ok_the_team_typically_replies_in_week": "Ok, pasukan biasanya membalas dalam masa seminggu.",
    "our_bot_and_team_can_help": "Bot dan pasukan kami boleh membantu",
    "our_bot_answers_instantly": "Bot kami menjawab dengan serta-merta",
    "powered_by_intercom": "Dikuasakan oleh Intercom",
    "previous_bot_hands_over": "Kandungan daripada bot sebelumnya...",
    "proactive_expected_response_delay_10800": "Biasanya membalas dalam masa kurang dari 3j",
    "proactive_expected_response_delay_120": "Biasanya membalas dalam masa kurang dari 2m",
    "proactive_expected_response_delay_1200": "Biasanya membalas dalam masa kurang dari 20m",
    "proactive_expected_response_delay_14400": "Biasanya membalas dalam masa kurang dari 4j",
    "proactive_expected_response_delay_172800": "Biasanya membalas dalam masa 2h",
    "proactive_expected_response_delay_180": "Biasanya membalas dalam masa kurang dari 3m",
    "proactive_expected_response_delay_1800": "Biasanya membalas dalam masa kurang dari 30m",
    "proactive_expected_response_delay_18000": "Biasanya membalas dalam masa kurang dari 5j",
    "proactive_expected_response_delay_21600": "Biasanya membalas dalam masa kurang dari 6j",
    "proactive_expected_response_delay_240": "Biasanya membalas dalam masa kurang dari 4m",
    "proactive_expected_response_delay_259200": "Biasanya membalas dalam masa 3h",
    "proactive_expected_response_delay_300": "Biasanya membalas dalam masa kurang dari 5m",
    "proactive_expected_response_delay_345600": "Biasanya membalas dalam masa 4h",
    "proactive_expected_response_delay_3600": "Biasanya membalas dalam masa kurang dari 1j",
    "proactive_expected_response_delay_43200": "Biasanya membalas dalam masa 12j",
    "proactive_expected_response_delay_432000": "Biasanya membalas dalam masa 5h",
    "proactive_expected_response_delay_518400": "Biasanya membalas dalam masa 6h",
    "proactive_expected_response_delay_60": "Biasanya membalas dalam masa kurang dari 1m",
    "proactive_expected_response_delay_600": "Biasanya membalas dalam masa kurang dari 10m",
    "proactive_expected_response_delay_604800": "Biasanya membalas dalam masa kurang dari 1m",
    "proactive_expected_response_delay_7200": "Biasanya membalas dalam masa kurang dari 2j",
    "proactive_expected_response_delay_86400": "Biasanya membalas dalam masa 1d",
    "proactive_expected_response_delay_900": "Biasanya membalas dalam masa kurang dari 15m",
    "proactive_expected_response_delay_unknown": "Akan membalas secepat mungkin",
    "profile_location": "{time} di {location}",
    "recent_ticket": "Tiket terkini",
    "recent_tickets": "Tiket terkini",
    "search_browse_empty_state_text": "Maaf, kami tidak menemui sebarang hasil untuk itu. Cuba susun semula perkataan dalam carian anda.",
    "search_for_help": "Cari bantuan",
    "see_all": "Lihat semua",
    "see_all_conversations": "Lihat semua perbualan anda",
    "see_previous_conversations": "Lihat perbualan anda sebelum ini",
    "send": "Hantar",
    "send_a_message": "Hantar mesej...",
    "send_a_message_reply_time": "Masa biasa membalas ialah",
    "send_us_a_message": "Hantarkan mesej kepada kami",
    "set_expectations_bot_email_header": "Anda akan mendapat balasan di sini dan dalam e-mel anda:",
    "set_expectations_bot_office_hours_header": "Pasukan akan kembali",
    "set_expectations_bot_sms_header": "Anda akan mendapat balasan di sini dan melalui SMS:",
    "set_expectations_replies_to_email": "Anda akan mendapat balasan di sini dan di <b>{user_email}</b> .",
    "set_expectations_replies_to_email_without_bold_text": "Anda akan mendapat balasan di sini dan ke {user_email}",
    "set_expectations_team_reply_asap": "Pasukan akan membalas secepat mungkin.",
    "shared_by": "Dikongsi oleh",
    "show_less": "Tunjukkan kurang",
    "single_article": "1 Artikel",
    "single_collection": "1 koleksi",
    "source": "Sumber",
    "sources": "Sumber",
    "start_a_conversation": "Mulakan perbualan",
    "start_conversation": "Mulakan perbualan...",
    "still_generating_answer": "Masih menjana jawapan...",
    "still_thinking": "Masih berfikir...",
    "still_working_on_it": "Masih mengusahakannya...",
    "tasks_space_title": "Tugas",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} akan membalas secepat mungkin",
    "teammate_changes_team_assignment_of_a_conversation": "Rakan sepasukan telah menukar tugasan perbualan...",
    "tell_us_what_you_need": "Beritahu kami perkara yang anda perlukan bantuan dan bot kami akan melakukan yang terbaik untuk menjawab",
    "the_team_can_help_if_needed": "Pasukan boleh membantu jika perlu",
    "the_team_typically_replies_in_10800": "Pasukan biasanya membalas dalam masa kurang dari 3j",
    "the_team_typically_replies_in_120": "Pasukan biasanya membalas dalam masa kurang dari 2m",
    "the_team_typically_replies_in_1200": "Pasukan biasanya membalas dalam masa kurang dari 20m",
    "the_team_typically_replies_in_14400": "Pasukan biasanya membalas dalam masa kurang dari 4j",
    "the_team_typically_replies_in_172800": "Pasukan biasanya membalas dalam masa 2h",
    "the_team_typically_replies_in_180": "Pasukan biasanya membalas dalam masa kurang dari 3m",
    "the_team_typically_replies_in_1800": "Pasukan biasanya membalas dalam masa kurang dari 30m",
    "the_team_typically_replies_in_18000": "Pasukan biasanya membalas dalam masa kurang dari 5j",
    "the_team_typically_replies_in_21600": "Pasukan biasanya membalas dalam masa kurang dari 6j",
    "the_team_typically_replies_in_240": "Pasukan biasanya membalas dalam masa kurang dari 4m",
    "the_team_typically_replies_in_259200": "Pasukan biasanya membalas dalam masa 3h",
    "the_team_typically_replies_in_300": "Pasukan biasanya membalas dalam masa kurang dari 5m",
    "the_team_typically_replies_in_345600": "Pasukan biasanya membalas dalam masa 4h",
    "the_team_typically_replies_in_3600": "Pasukan biasanya membalas dalam masa kurang dari 1j",
    "the_team_typically_replies_in_43200": "Pasukan biasanya membalas dalam masa kurang dari 12j",
    "the_team_typically_replies_in_432000": "Pasukan biasanya membalas dalam masa 5h",
    "the_team_typically_replies_in_518400": "Pasukan biasanya membalas dalam masa 6h",
    "the_team_typically_replies_in_60": "Pasukan biasanya membalas dalam masa kurang dari 1m",
    "the_team_typically_replies_in_600": "Pasukan biasanya membalas dalam masa kurang dari 10m",
    "the_team_typically_replies_in_604800": "Pasukan biasanya membalas dalam masa kurang dari 1ming",
    "the_team_typically_replies_in_7200": "Pasukan biasanya membalas dalam masa kurang dari 2j",
    "the_team_typically_replies_in_86400": "Pasukan biasanya membalas dalam masa 1h",
    "the_team_typically_replies_in_900": "Pasukan biasanya membalas dalam masa kurang dari 15m",
    "the_team_typically_replies_in_day": "Pasukan biasanya membalas dalam masa sehari.",
    "the_team_typically_replies_in_hours": "Pasukan biasanya membalas dalam masa beberapa jam.",
    "the_team_typically_replies_in_minutes": "Pasukan biasanya membalas dalam masa beberapa minit.",
    "the_team_typically_replies_in_under_n_hours": "Pasukan biasanya membalas dalam masa kurang dari {hours}j.",
    "the_team_typically_replies_in_under_n_min": "Pasukan biasanya membalas dalam masa kurang dari {minutes}m.",
    "the_team_typically_replies_in_unknown": "Pasukan akan membalas secepat mungkin",
    "the_team_typically_replies_in_week": "Pasukan biasanya membalas dalam masa seminggu.",
    "thinking": "Berfikir...",
    "tickets_cta_text": "Ada soalan mengenai tiket ini?",
    "tickets_have_a_question": "Ada soalan mengenai isu ini?",
    "tickets_space_title": "Tiket",
    "time_future_1h": "Dalam masa 1 jam",
    "time_future_2h": "Dalam masa 2 jam",
    "time_future_30m": "Dalam masa 30 minit",
    "time_future_3h": "Dalam masa 3 jam",
    "time_future_day_0": "Isnin",
    "time_future_day_1": "Selasa",
    "time_future_day_2": "Rabu",
    "time_future_day_3": "Khamis",
    "time_future_day_4": "Jumaat",
    "time_future_day_5": "Sabtu",
    "time_future_day_6": "Ahad",
    "time_future_later_today": "Pada waktu lain hari ini",
    "time_future_next_week": "Minggu depan",
    "time_future_tomorrow": "Esok",
    "try_use_different_keywords": "Cuba kata kunci yang berbeza atau taipkan soalan penuh anda",
    "upfront_collection_form_close_out": "Anda boleh kembali ke sini pada bila-bila masa untuk melihat respons dan menghantar kemas kini.",
    "upfront_collection_form_introduction": "Kongsi beberapa konteks untuk membantu pasukan memberi respons dengan lebih baik dan lebih pantas.",
    "upfront_collection_form_submission_confirmation": "Terima kasih kerana menghantar konteks itu. Untuk menjimatkan masa pada waktu lain, sila tambah butiran lanjut sekarang.",
    "upfront_collection_form_subtitle": "Hantar beberapa konteks kepada pasukan",
    "upfront_collection_form_subtitle_submitted": "Mereka akan mengingati konteks ini",
    "upfront_collection_form_title": "Dapatkan bantuan dengan lebih pantas",
    "upfront_collection_form_title_submitted": "Dihantar kepada pasukan!",
    "view_all": "Lihat semua",
    "view_conversation": "Lihat perbualan",
    "we_are_here_to_help": "Kami sedia membantu",
    "we_run_on_intercom": "Kami jalankan pada Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Apabila teman sepasukan mengubah keadaan perbualan...",
    "working_on_it": "Mengusahakannya...",
    "you_are_all_up_to_date": "Anda semua terperangkap!",
    "your_checklists": "Senarai semak anda",
    "your_conversations": "Perbualan anda",
    "your_recent_conversations": "Perbualan terkini",
    "your_recent_messages": "Mesej terkini"
  },
  "nl": {
    "a_day": "Een dag",
    "a_few_hours": "Een paar uur",
    "a_few_minutes": "Een paar minuten",
    "a_week": "Een week",
    "active_state": "Actief",
    "ai_answer": "AI-antwoord",
    "ai_answer_information": "AI-antwoord, informatie",
    "ai_bot": "AI-bot",
    "almost_there": "Bijna klaar ...",
    "answer": "Antwoord",
    "answer_bot_acknowledge_duplicate_content": "Je hebt dit al gezien, maar dit is het beste antwoord dat beschikbaar is.",
    "answer_bot_all_done": "Dat hielp 👍",
    "answer_bot_ask_another_question": "Een andere vraag stellen 🔁",
    "answer_bot_ask_something_else": "Nog iets anders vragen 🔁",
    "answer_bot_connect_to_an_agent": "Verbinding maken met een medewerker 👤",
    "answer_bot_did_that_help": "Heeft dit antwoord geholpen, of zoek je iets anders?",
    "answer_bot_followup_expectations_prefix": "Het team komt hier nog op terug.",
    "answer_bot_get_more_help": "Meer hulp krijgen 🌐",
    "answer_bot_get_more_help_followup": "Deze koppeling kan helpen:",
    "answer_bot_i_have_another_question": "Ik heb nog een vraag 🔁",
    "answer_bot_im_not_done_yet": "Ik ben nog niet klaar 🔁",
    "answer_bot_keep_waiting": "Nee, ik heb nog steeds hulp nodig 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Je kunt jouw vraag anders formuleren of extra hulp vragen",
    "answer_bot_looping_first_no_answer": "Het spijt me, maar als bot heb ik geen antwoord op die vraag.",
    "answer_bot_looping_first_no_answer_followup": "Je kunt jouw vraag anders formuleren of met ons team spreken",
    "answer_bot_looping_no_answer": "Het spijt me, maar als bot kan ik die vraag niet beantwoorden. Dit zijn uw opties",
    "answer_bot_more_answers": "Ik wil meer zien 👀",
    "answer_bot_no_answer_found": "Sorry, daar hebben we geen snel antwoord op. Hier zijn enkele andere opties.",
    "answer_bot_resolve_followup": "U kunt hier altijd reageren als u weer hulp nodig hebt.",
    "answer_bot_talk_to_person": "Praten met een persoon 👤",
    "answer_bot_that_answered_my_question": "Daarmee is mijn vraag beantwoord 👍",
    "answer_bot_thats_it": "Dat was alles 👍",
    "answer_bot_wait_for_the_team": "Wacht op het team 💬",
    "answerbot_ask_for_more_context": "Natuurlijk. Kunt u, terwijl we wachten, meer details geven over uw situatie of vraag? Zo kunnen we sneller een antwoord vinden en kan ik meer informatie voor u verzamelen.",
    "answerbot_introduce_sneaky_answer": "Bedankt voor de aanvullende informatie. Ik heb nieuwe informatie gevonden die u misschien kan helpen.",
    "article_search_hint_text": "Artikelen zoeken ...",
    "ask_a_question": "Stel een vraag",
    "ask_a_question_placeholder": "Stel een vraag ...",
    "ask_a_question_with_ellipsis": "Stel een vraag ...",
    "ask_another_question_with_ellipsis": "Stel nog een vraag ...",
    "ask_for_the_team_if_needed": "Vraag naar het team indien nodig",
    "ask_me_a_question": "Stel me een vraag",
    "ask_our_bot_a_question": "Stel onze bot een vraag",
    "avatar": "Avatar",
    "avatars": "Avatars",
    "bot": "Bot",
    "bot_answers_instantly": "De bot antwoordt onmiddellijk",
    "bot_expectation_text": "Onze bot kan tal van veelgestelde vragen beantwoorden",
    "bot_intro_default_part_1": "Hallo! Ik ben een bot. Ik geef graag antwoord op uw vragen, maar u kunt ook altijd met ons team spreken.",
    "bot_intro_default_part_2": "Hoe kan ik u helpen?",
    "bot_is_still_thinking": "De bot denkt nog steeds na ...",
    "bot_is_thinking": "De bot denkt na ...",
    "browse_collections": "Door collecties bladeren",
    "chat_with_us": "Chat met ons",
    "check_later_for_updates": "Kom later terug voor updates.",
    "checklist_card_first_step": "Eerste stap",
    "checklist_card_next_step": "Volgende stap",
    "checklist_completed": "Voltooid",
    "checklist_in_progress_time_remaining_plural": "Nog ongeveer {minutes} minuten",
    "checklist_in_progress_time_remaining_singular": "Nog ongeveer 1 minuut",
    "checklist_mark_step_as_complete": "Markeren als voltooid",
    "checklist_not_started_time_remaining_plural": "Ongeveer {minutes} minuten",
    "checklist_not_started_time_remaining_singular": "Ongeveer 1 minuut",
    "checklist_number_of_steps_plural": "{number_of_steps} stappen",
    "checklist_number_of_steps_progress_plural": "{steps_completed} van {total_steps} voltooid",
    "checklist_number_of_steps_progress_singular": "1 van {total_steps} voltooid",
    "checklist_number_of_steps_singular": "1 stap",
    "checklist_sender_name": "Gedeeld door {name}",
    "checklist_step_action_button": "Actieknop voor stap",
    "checklist_step_completed": "Voltooid",
    "contact_support": "Neem contact op met de klantenservice",
    "contact_us": "Neem contact met ons op",
    "continue_the_conversation": "Gesprek voortzetten",
    "conversation_card_office_hours_header": "We zijn weer online",
    "conversation_card_reply_time_header": "Onze gebruikelijke reactietijd",
    "conversation_card_reply_time_header_m5": "Gebruikelijke antwoordtijd",
    "conversation_card_unknown_response_time": "We zullen zo snel mogelijk antwoorden",
    "conversation_part_sample_text": "Hallo, hoe kan ik helpen? U kunt altijd met ons team praten als dat nodig is.",
    "conversations_space_title": "Gesprekken",
    "create_a_ticket": "Maak een ticket",
    "currently_no_help_articles": "Er zijn momenteel geen Help-artikelen beschikbaar.",
    "customer_sends_their_first_message": "Klant stuurt het eerste bericht ...",
    "expected_response_delay_10800": "minder dan 3 uur",
    "expected_response_delay_120": "minder dan 2 minuten",
    "expected_response_delay_1200": "minder dan 20 minuten",
    "expected_response_delay_14400": "minder dan 4 uur",
    "expected_response_delay_172800": "2 dagen",
    "expected_response_delay_180": "minder dan 3 minuten",
    "expected_response_delay_1800": "minder dan 30 minuten",
    "expected_response_delay_18000": "minder dan 5 uur",
    "expected_response_delay_21600": "minder dan 6 uur",
    "expected_response_delay_240": "minder dan 4 minuten",
    "expected_response_delay_259200": "3 dagen",
    "expected_response_delay_300": "minder dan 5 minuten",
    "expected_response_delay_345600": "4 dagen",
    "expected_response_delay_3600": "minder dan 1 uur",
    "expected_response_delay_43200": "binnen 12 uur",
    "expected_response_delay_432000": "5 dagen",
    "expected_response_delay_518400": "6 dagen",
    "expected_response_delay_60": "minder dan 1 minuut",
    "expected_response_delay_600": "minder dan 10 minuten",
    "expected_response_delay_604800": "minder dan 1 week",
    "expected_response_delay_7200": "minder dan 2 uur",
    "expected_response_delay_86400": "1 dag",
    "expected_response_delay_900": "binnen 15 minuten",
    "expected_response_delay_unknown": "Zo spoedig mogelijk",
    "extra_labels_count": "+ {labels_count} labels",
    "for_best_results_provide_detail": "Geef voor het beste resultaat zoveel mogelijk details op",
    "generating_answer": "Bezig met het genereren van een antwoord ...",
    "generic_triage_default": "We hebben alleen wat meer informatie nodig om u in contact te brengen met de juiste persoon",
    "get_an_instant_answer": "Krijg direct antwoord",
    "get_help": "Hulp",
    "go_to": "Ga naar {app_name}",
    "help_space_title": "Help",
    "home_header_mobile_hello_firstname": "Hallo, {firstname}.",
    "home_header_mobile_hello_there": "Hallo",
    "home_space_title": "Home",
    "how_can_we_help": "Hoe kunnen we u helpen?",
    "how_would_you_rate_this": "Hoe zou u dit beoordelen?",
    "learn_more": "Meer informatie",
    "message_placeholder": "Typ een bericht …",
    "messages_space_title": "Berichten",
    "messenger_bot_reply_time": "Onze bot zal direct reageren",
    "messenger_settings_introduction": "Hoe kunnen we u helpen?",
    "multiple_articles": "{total_articles} artikelen",
    "multiple_collections": "{total_collection} collecties",
    "new_conversation": "Nieuw gesprek",
    "news": "Nieuws",
    "news_empty_title": "Nog geen nieuws",
    "news_latest_subtitle": "Van team {team_name}",
    "news_latest_title": "Laatste nieuws",
    "news_older_title": "Ouder",
    "news_space_title": "Nieuws",
    "newsfeed": "Nieuwsfeed",
    "no_articles_to_display": "Nog geen artikelen",
    "no_help_articles_to_display": "Geen Help-artikelen",
    "no_results_for_searchterm": "Geen resultaten voor {searchTerm}",
    "no_tasks": "Geen taken",
    "no_tasks_available_currently": "Er zijn momenteel geen taken beschikbaar.",
    "ok_the_team_typically_replies_in_day": "OK, meestal antwoordt het team binnen de 24 uur.",
    "ok_the_team_typically_replies_in_hours": "OK, meestal antwoordt het team binnen enkele uren.",
    "ok_the_team_typically_replies_in_minutes": "OK, meestal antwoordt het team binnen enkele minuten.",
    "ok_the_team_typically_replies_in_under_n_hours": "OK, meestal antwoordt het team binnen de {hours} uren.",
    "ok_the_team_typically_replies_in_under_n_min": "OK, meestal antwoordt het team binnen de {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Oké, het team antwoordt meestal binnen een week.",
    "our_bot_and_team_can_help": "Onze bot en ons team kunnen je helpen",
    "our_bot_answers_instantly": "Onze bot reageert direct",
    "powered_by_intercom": "Mogelijk gemaakt door Intercom",
    "previous_bot_hands_over": "Inhoud van de vorige bot ...",
    "proactive_expected_response_delay_10800": "Reageert meestal binnen 3 uur.",
    "proactive_expected_response_delay_120": "Reageert meestal binnen 2 minuten.",
    "proactive_expected_response_delay_1200": "Reageert meestal binnen 20 minuten.",
    "proactive_expected_response_delay_14400": "Reageert meestal binnen 4 uur.",
    "proactive_expected_response_delay_172800": "Reageert meestal binnen 2 dagen.",
    "proactive_expected_response_delay_180": "Reageert meestal binnen 3 minuten.",
    "proactive_expected_response_delay_1800": "Reageert meestal binnen 30 minuten.",
    "proactive_expected_response_delay_18000": "Reageert meestal binnen 5 uur.",
    "proactive_expected_response_delay_21600": "Reageert meestal binnen 6 uur.",
    "proactive_expected_response_delay_240": "Reageert meestal binnen 4 minuten.",
    "proactive_expected_response_delay_259200": "Reageert meestal binnen 3 dagen",
    "proactive_expected_response_delay_300": "Reageert meestal binnen 5 minuten.",
    "proactive_expected_response_delay_345600": "Reageert meestal binnen 4 dagen",
    "proactive_expected_response_delay_3600": "Reageert meestal binnen het uur.",
    "proactive_expected_response_delay_43200": "Reageert meestal binnen 12 uur",
    "proactive_expected_response_delay_432000": "Reageert meestal binnen 5 dagen",
    "proactive_expected_response_delay_518400": "Reageert meestal binnen 6 dagen",
    "proactive_expected_response_delay_60": "Reageert meestal binnen 1 minuten.",
    "proactive_expected_response_delay_600": "Reageert meestal binnen 10 minuten.",
    "proactive_expected_response_delay_604800": "Reageert meestal binnen week.",
    "proactive_expected_response_delay_7200": "Reageert meestal binnen 2 uur.",
    "proactive_expected_response_delay_86400": "Reageert meestal de dag zelf.",
    "proactive_expected_response_delay_900": "Reageert meestal binnen 15 minuten.",
    "proactive_expected_response_delay_unknown": "Reageert zo snel mogelijk.",
    "profile_location": "{time} in {location}",
    "recent_ticket": "Recent ticket",
    "recent_tickets": "Recente tickets",
    "search_browse_empty_state_text": "Helaas kunnen we daarvoor geen resultaten vinden. Probeer andere zoektermen te gebruiken.",
    "search_for_help": "Hulp zoeken",
    "see_all": "Zie alles",
    "see_all_conversations": "Alle gesprekken weergeven",
    "see_previous_conversations": "Eerdere gesprekken bekijken",
    "send": "Versturen",
    "send_a_message": "Een bericht versturen…",
    "send_a_message_reply_time": "Gebruikelijke antwoordtijd is",
    "send_us_a_message": "Stuur ons een bericht",
    "set_expectations_bot_email_header": "Je ontvangt hier antwoord en in je e-mail:",
    "set_expectations_bot_office_hours_header": "Wanneer het team weer beschikbaar is:",
    "set_expectations_bot_sms_header": "Je ontvangt hier antwoord en via sms:",
    "set_expectations_replies_to_email": "U krijgt hier en op <b>{user_email}</b> antwoorden.",
    "set_expectations_replies_to_email_without_bold_text": "U krijgt hier en op {user_email} antwoorden.",
    "set_expectations_team_reply_asap": "Het team zal zo snel mogelijk antwoorden.",
    "shared_by": "Gedeeld door",
    "show_less": "Minder tonen",
    "single_article": "1 artikel",
    "single_collection": "1 collectie",
    "source": "Bron",
    "sources": "Bronnen",
    "start_a_conversation": "Start een gesprek",
    "start_conversation": "Een gesprek starten",
    "still_generating_answer": "Nog steeds bezig met het genereren van een antwoord ...",
    "still_thinking": "Ik denk nog steeds na ...",
    "still_working_on_it": "Ik ben er nog steeds mee bezig ...",
    "tasks_space_title": "Taken",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} zal je zo snel mogelijk antwoorden.",
    "teammate_changes_team_assignment_of_a_conversation": "Teamgenoot heeft de gesprekstoewijzing gewijzigd ...",
    "tell_us_what_you_need": "Vertel ons waar u hulp bij nodig hebt. Onze bot doet zijn best om uw vraag te beantwoorden",
    "the_team_can_help_if_needed": "Het team kan zo nodig helpen",
    "the_team_typically_replies_in_10800": "Meestal antwoordt het team binnen de 3 uur.",
    "the_team_typically_replies_in_120": "Het team reageert meestal binnen 2 min.",
    "the_team_typically_replies_in_1200": "Het team reageert meestal binnen 20 minuten.",
    "the_team_typically_replies_in_14400": "Meestal antwoordt het team binnen de 4 uur.",
    "the_team_typically_replies_in_172800": "Meestal antwoordt het team binnen de 2 dagen.",
    "the_team_typically_replies_in_180": "Het team reageert meestal binnen 3 min.",
    "the_team_typically_replies_in_1800": "Het team reageert meestal binnen 30 minuten.",
    "the_team_typically_replies_in_18000": "Meestal antwoordt het team binnen de 5 uur.",
    "the_team_typically_replies_in_21600": "Meestal antwoordt het team binnen de 6 uur.",
    "the_team_typically_replies_in_240": "Het team reageert meestal binnen 4 min.",
    "the_team_typically_replies_in_259200": "Het team reageert meestal binnen 3 dagen",
    "the_team_typically_replies_in_300": "Het team reageert meestal binnen 5 minuten.",
    "the_team_typically_replies_in_345600": "Het team reageert meestal binnen 4 dagen",
    "the_team_typically_replies_in_3600": "Meestal antwoordt het team binnen het uur.",
    "the_team_typically_replies_in_43200": "Het team reageert meestal binnen 12 uur",
    "the_team_typically_replies_in_432000": "Het team reageert meestal binnen 5 dagen",
    "the_team_typically_replies_in_518400": "Het team reageert meestal binnen 6 dagen",
    "the_team_typically_replies_in_60": "Het team reageert meestal binnen 1 min.",
    "the_team_typically_replies_in_600": "Het team reageert meestal binnen 10 minuten.",
    "the_team_typically_replies_in_604800": "Meestal antwoordt het team binnen de week.",
    "the_team_typically_replies_in_7200": "Meestal antwoordt het team binnen de 2 uur.",
    "the_team_typically_replies_in_86400": "Meestal antwoordt het team binnen de 24 uur.",
    "the_team_typically_replies_in_900": "Het team reageert meestal binnen 15 minuten.",
    "the_team_typically_replies_in_day": "Het team antwoordt meestal binnen 24 uur.",
    "the_team_typically_replies_in_hours": "Het team antwoordt meestal binnen enkele uren.",
    "the_team_typically_replies_in_minutes": "Het team antwoordt meestal binnen enkele minuten.",
    "the_team_typically_replies_in_under_n_hours": "Meestal antwoordt het team binnen de {hours} uren.",
    "the_team_typically_replies_in_under_n_min": "Meestal antwoordt het team binnen {minutes} min.",
    "the_team_typically_replies_in_unknown": "Het team zal je zo snel mogelijk antwoorden.",
    "the_team_typically_replies_in_week": "Het team antwoordt meestal binnen een week.",
    "thinking": "Even nadenken ...",
    "tickets_cta_text": "Hebt je een vraag over dit ticket?",
    "tickets_have_a_question": "Hebt je een vraag over dit probleem?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "1 uur",
    "time_future_2h": "2 uur",
    "time_future_30m": "30 minuten",
    "time_future_3h": "3 uur",
    "time_future_day_0": "maandag",
    "time_future_day_1": "dinsdag",
    "time_future_day_2": "woensdag",
    "time_future_day_3": "donderdag",
    "time_future_day_4": "vrijdag",
    "time_future_day_5": "zaterdag",
    "time_future_day_6": "zondag",
    "time_future_later_today": "Later vandaag",
    "time_future_next_week": "Volgend week",
    "time_future_tomorrow": "Morgen",
    "try_use_different_keywords": "Probeer andere trefwoorden te gebruiken of typ uw volledige vraag",
    "upfront_collection_form_close_out": "U kunt altijd terugkomen om reacties te zien en updates te delen.",
    "upfront_collection_form_introduction": "Deel wat context zodat het team beter en sneller kan reageren",
    "upfront_collection_form_submission_confirmation": "Bedankt voor het delen van deze context. Voeg gerust wat meer details toe, zodat u later tijd bespaart.",
    "upfront_collection_form_subtitle": "Geef het team wat context",
    "upfront_collection_form_subtitle_submitted": "Men houdt deze dan in het achterhoofd",
    "upfront_collection_form_title": "Word sneller geholpen",
    "upfront_collection_form_title_submitted": "Verzonden naar het team!",
    "view_all": "Alles weergeven",
    "view_conversation": "Gesprek weergeven",
    "we_are_here_to_help": "We staan klaar om te helpen",
    "we_run_on_intercom": "Wij gebruiken Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Wanneer een teamgenoot de status van een gesprek wijzigt...",
    "working_on_it": "Ik ben er mee bezig ...",
    "you_are_all_up_to_date": "Je bent helemaal op de hoogte!",
    "your_checklists": "Uw checklists",
    "your_conversations": "Nieuw gesprek",
    "your_recent_conversations": "Recente gesprekken",
    "your_recent_messages": "Recente berichten"
  },
  "nb": {
    "a_day": "Én dag",
    "a_few_hours": "Noen timer",
    "a_few_minutes": "Noen minutter",
    "a_week": "En uke",
    "active_state": "Aktiv",
    "ai_answer": "AI-svar",
    "ai_answer_information": "AI-svar, informasjon",
    "ai_bot": "AI-robot",
    "almost_there": "Nesten ferdig...",
    "answer": "Svar",
    "answer_bot_acknowledge_duplicate_content": "Du har allerede sett dette, men det er det beste tilgjengelige svaret.",
    "answer_bot_all_done": "Det hjalp 👍",
    "answer_bot_ask_another_question": "Still et annet spørsmål 🔁",
    "answer_bot_ask_something_else": "Spør om noe annet 🔁",
    "answer_bot_connect_to_an_agent": "Få hjelp av en person 👤",
    "answer_bot_did_that_help": "Hjalp dette svaret deg, eller ser du etter noe annet?",
    "answer_bot_followup_expectations_prefix": "Teamet tar kontakt med deg angående dette.",
    "answer_bot_get_more_help": "Få mer hjelp 🌐",
    "answer_bot_get_more_help_followup": "Denne lenken er kanskje til hjelp:",
    "answer_bot_i_have_another_question": "Jeg har et annet spørsmål 🔁",
    "answer_bot_im_not_done_yet": "Jeg er ikke ferdig ennå 🔁",
    "answer_bot_keep_waiting": "Nei, jeg trenger fremdeles hjelp 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Du kan omformulere spørsmålet eller få videre hjelp",
    "answer_bot_looping_first_no_answer": "Beklager, jeg er en bot og finner ikke svar på dette spørsmålet.",
    "answer_bot_looping_first_no_answer_followup": "Du kan omformulere spørsmålet eller snakke med teamet vårt",
    "answer_bot_looping_no_answer": "Beklager, jeg er en bot og finner ikke svar på dette spørsmålet. Prøv dette",
    "answer_bot_more_answers": "Vis meg mer 👀",
    "answer_bot_no_answer_found": "Beklager, dette har vi ikke et raskt svar på. Her er noen andre alternativer.",
    "answer_bot_resolve_followup": "Du kan svare her hvis det er noe mer du lurer på.",
    "answer_bot_talk_to_person": "Snakk med en person 👤",
    "answer_bot_that_answered_my_question": "Jeg har fått svar på spørsmålet mitt 👍",
    "answer_bot_thats_it": "Det var alt 👍",
    "answer_bot_wait_for_the_team": "Vent på teamet 💬",
    "answerbot_ask_for_more_context": "Ja visst. Mens vi venter, kan du forteller mer om situasjonen eller spørsmålet? Det gjør det raskere å finne et svar og jeg kan finne mer informasjon til deg.",
    "answerbot_introduce_sneaky_answer": "Takk for den utdypelsen. Jeg fant litt ny informasjon som kan hjelpe deg.",
    "article_search_hint_text": "Søk i artikler ...",
    "ask_a_question": "Still et spørsmål",
    "ask_a_question_placeholder": "Still et spørsmål …",
    "ask_a_question_with_ellipsis": "Still et spørsmål...",
    "ask_another_question_with_ellipsis": "Still et annet spørsmål...",
    "ask_for_the_team_if_needed": "Spør etter teamet ved behov",
    "ask_me_a_question": "Still meg et spørsmål",
    "ask_our_bot_a_question": "Still roboten vår et spørsmål",
    "avatar": "Avatar",
    "avatars": "Avatarer",
    "bot": "Robot",
    "bot_answers_instantly": "Roboten svarer umiddelbart",
    "bot_expectation_text": "Chatboten kan svare på mange vanlige spørsmål",
    "bot_intro_default_part_1": "Hei der! Dette er en bot som snakker. Jeg skal prøve å svare på spørsmålene dine, men du har alltid muligheten til å snakke med noen fra teamet vårt.",
    "bot_intro_default_part_2": "Hva kan jeg hjelpe deg med i dag?",
    "bot_is_still_thinking": "Roboten tenker fortsatt...",
    "bot_is_thinking": "Roboten tenker...",
    "browse_collections": "Bla gjennom samlinger",
    "chat_with_us": "Chat med oss",
    "check_later_for_updates": "Kom tilbake senere for oppdateringer.",
    "checklist_card_first_step": "Første trinn",
    "checklist_card_next_step": "Neste trinn",
    "checklist_completed": "Fullført",
    "checklist_in_progress_time_remaining_plural": "Omtrent {minutes} minutter igjen",
    "checklist_in_progress_time_remaining_singular": "Omtrent 1 minutt igjen",
    "checklist_mark_step_as_complete": "Marker som fullført",
    "checklist_not_started_time_remaining_plural": "Omtrent {minutes} minutter",
    "checklist_not_started_time_remaining_singular": "Omtrent 1 minutt",
    "checklist_number_of_steps_plural": "{number_of_steps} trinn",
    "checklist_number_of_steps_progress_plural": "{steps_completed} av {total_steps} fullført",
    "checklist_number_of_steps_progress_singular": "1 av {total_steps} fullført",
    "checklist_number_of_steps_singular": "1 trinn",
    "checklist_sender_name": "Delt av {name}",
    "checklist_step_action_button": "Trinn-handlingsknapp",
    "checklist_step_completed": "Fullført",
    "contact_support": "Kontakt kundestøtte",
    "contact_us": "Kontakt oss",
    "continue_the_conversation": "Fortsett samtalen",
    "conversation_card_office_hours_header": "Når vi er tilbake på nett",
    "conversation_card_reply_time_header": "Normal svartid",
    "conversation_card_reply_time_header_m5": "Vanlig svartid",
    "conversation_card_unknown_response_time": "Vi svarer så snart vi kan",
    "conversation_part_sample_text": "Hei, hvordan kan jeg hjelpe deg? Du har alltid muligheten til å snakke med teamet vårt hvis du trenger det.",
    "conversations_space_title": "Samtaler",
    "create_a_ticket": "Opprett en sak",
    "currently_no_help_articles": "Ingen hjelpeartikler er tilgjengelige for øyeblikket.",
    "customer_sends_their_first_message": "Kunden sender sin første melding  ...",
    "expected_response_delay_10800": "under 3 timer",
    "expected_response_delay_120": "under 20 minutter",
    "expected_response_delay_1200": "under 20 minutter",
    "expected_response_delay_14400": "under 4 timer",
    "expected_response_delay_172800": "2 dager",
    "expected_response_delay_180": "under 30 minutter",
    "expected_response_delay_1800": "under 30 minutter",
    "expected_response_delay_18000": "under 5 timer",
    "expected_response_delay_21600": "under 4 timer",
    "expected_response_delay_240": "under 5 minutter",
    "expected_response_delay_259200": "3 dager",
    "expected_response_delay_300": "under 5 minutter",
    "expected_response_delay_345600": "4 dager",
    "expected_response_delay_3600": "under 1 time",
    "expected_response_delay_43200": "under 12 timer",
    "expected_response_delay_432000": "5 dager",
    "expected_response_delay_518400": "6 dager",
    "expected_response_delay_60": "under 10 minutter",
    "expected_response_delay_600": "under 10 minutter",
    "expected_response_delay_604800": "under 1 uke",
    "expected_response_delay_7200": "under 2 timer",
    "expected_response_delay_86400": "1 dag",
    "expected_response_delay_900": "under 15 minutter",
    "expected_response_delay_unknown": "Så snart vi kan",
    "extra_labels_count": "+ {labels_count} etiketter",
    "for_best_results_provide_detail": "For best mulig resultat, oppgi så mange detaljer som mulig",
    "generating_answer": "Genererer svar...",
    "generic_triage_default": "Vi trenger litt mer informasjon for å koble deg til den rette personen",
    "get_an_instant_answer": "Få svar på et blunk",
    "get_help": "Få hjelp",
    "go_to": "Gå til {app_name}",
    "help_space_title": "Hjelp",
    "home_header_mobile_hello_firstname": "Hei, {firstname}.",
    "home_header_mobile_hello_there": "Hallo der",
    "home_space_title": "Hjem",
    "how_can_we_help": "Hvordan kan vi hjelpe?",
    "how_would_you_rate_this": "Hva er din vurdering av dette?",
    "learn_more": "Finn ut mer",
    "message_placeholder": "Melding...",
    "messages_space_title": "Meldinger",
    "messenger_bot_reply_time": "Boten vår svarer umiddelbart",
    "messenger_settings_introduction": "Hvordan kan vi hjelpe?",
    "multiple_articles": "{total_articles} artikler",
    "multiple_collections": "{total_collection} samlinger",
    "new_conversation": "Ny samtale",
    "news": "Nyheter",
    "news_empty_title": "Ingen nyheter ennå",
    "news_latest_subtitle": "Fra team {team_name}",
    "news_latest_title": "Senest",
    "news_older_title": "Eldre",
    "news_space_title": "Nyheter",
    "newsfeed": "Nyhetsfeed",
    "no_articles_to_display": "Ingen artikler ennå",
    "no_help_articles_to_display": "Ingen hjelpeartikler",
    "no_results_for_searchterm": "Ingen resultater for {searchTerm}",
    "no_tasks": "Ingen oppgaver",
    "no_tasks_available_currently": "Ingen oppgaver er tilgjengelige for øyeblikket.",
    "ok_the_team_typically_replies_in_day": "OK, teamet svarer vanligvis i løpet av en dag.",
    "ok_the_team_typically_replies_in_hours": "OK, teamet svarer vanligvis i løpet av noen få timer.",
    "ok_the_team_typically_replies_in_minutes": "OK, teamet svarer vanligvis i løpet av noen få minutter.",
    "ok_the_team_typically_replies_in_under_n_hours": "OK, teamet svarer vanligvis innen {hours}t.",
    "ok_the_team_typically_replies_in_under_n_min": "OK, teamet svarer vanligvis innen {minutes} m.",
    "ok_the_team_typically_replies_in_week": "OK, teamet svarer vanligvis i løpet av en uke.",
    "our_bot_and_team_can_help": "Roboten og teamet vårt kan hjelpe deg",
    "our_bot_answers_instantly": "Roboten vår svarer umiddelbart",
    "powered_by_intercom": "Leveres av Intercom",
    "previous_bot_hands_over": "Innhold fra forrige bot…",
    "proactive_expected_response_delay_10800": "Svarer vanligvis i løpet av 3 timer",
    "proactive_expected_response_delay_120": "Svarer vanligvis innen 20 min",
    "proactive_expected_response_delay_1200": "Svarer vanligvis i løpet av 20 minutter",
    "proactive_expected_response_delay_14400": "Svarer vanligvis i løpet av 4 timer",
    "proactive_expected_response_delay_172800": "Svarer vanligvis i løpet av 2 dager",
    "proactive_expected_response_delay_180": "Svarer vanligvis innen 3 timer",
    "proactive_expected_response_delay_1800": "Svarer vanligvis i løpet av 30 minutter",
    "proactive_expected_response_delay_18000": "Svarer vanligvis i løpet av 5 timer",
    "proactive_expected_response_delay_21600": "Svarer vanligvis i løpet av 6 timer",
    "proactive_expected_response_delay_240": "Svarer vanligvis innen 5 min",
    "proactive_expected_response_delay_259200": "Svarer vanligvis i løpet av 3 dager",
    "proactive_expected_response_delay_300": "Svarer vanligvis i løpet av 5 minutter",
    "proactive_expected_response_delay_345600": "Svarer vanligvis i løpet av 4 dager",
    "proactive_expected_response_delay_3600": "Svarer vanligvis i løpet av 1 time",
    "proactive_expected_response_delay_43200": "Svarer vanligvis i løpet av 12 timer",
    "proactive_expected_response_delay_432000": "Svarer vanligvis i løpet av 5 dager",
    "proactive_expected_response_delay_518400": "Svarer vanligvis i løpet av 6 dager",
    "proactive_expected_response_delay_60": "Svarer vanligvis innen 10 min",
    "proactive_expected_response_delay_600": "Svarer vanligvis i løpet av 10 minutter",
    "proactive_expected_response_delay_604800": "Svarer vanligvis i løpet av 1 uke",
    "proactive_expected_response_delay_7200": "Svarer vanligvis i løpet av 2 timer",
    "proactive_expected_response_delay_86400": "Svarer vanligvis i løpet av 1 dag",
    "proactive_expected_response_delay_900": "Svarer vanligvis innen 15 min",
    "proactive_expected_response_delay_unknown": "Vil svare så snart som mulig",
    "profile_location": "{time} i {location}",
    "recent_ticket": "Nylig sak",
    "recent_tickets": "Nylige saker",
    "search_browse_empty_state_text": "Vi får dessverre ingen treff. Prøv å omformulere søket.",
    "search_for_help": "Søk etter hjelp",
    "see_all": "Se alle",
    "see_all_conversations": "Vis alle samtalene mine",
    "see_previous_conversations": "Se tidligere samtaler",
    "send": "Send",
    "send_a_message": "Send en melding…",
    "send_a_message_reply_time": "Vanlig svartid er",
    "send_us_a_message": "Send en melding til oss",
    "set_expectations_bot_email_header": "Du får svar her og på denne e-postadressen:",
    "set_expectations_bot_office_hours_header": "Teamet er tilbake igjen:",
    "set_expectations_bot_sms_header": "Du får svar her og på SMS til:",
    "set_expectations_replies_to_email": "Du får svar her og på <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Du vil få svar her og på {user_email}",
    "set_expectations_team_reply_asap": "Teamet svarer så snart som mulig.",
    "shared_by": "Delt av",
    "show_less": "Vis mindre",
    "single_article": "1 artikkel",
    "single_collection": "1 samling",
    "source": "Kilde",
    "sources": "Kilder",
    "start_a_conversation": "Start en samtale",
    "start_conversation": "Start en samtale",
    "still_generating_answer": "Genererer fortsatt svar...",
    "still_thinking": "Tenker fremdeles...",
    "still_working_on_it": "Jobber fortsatt med saken...",
    "tasks_space_title": "Oppgaver",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} vil svare så snart som mulig",
    "teammate_changes_team_assignment_of_a_conversation": "Teammedlem har endret samtaleoppgaven ...",
    "tell_us_what_you_need": "Fortell oss hva du trenger hjelp med, så vil boten vår gjøre sitt beste for å svare",
    "the_team_can_help_if_needed": "Teamet vårt hjelper deg hvis du trenger det",
    "the_team_typically_replies_in_10800": "Teamet svarer vanligvis innen 3 timer",
    "the_team_typically_replies_in_120": "Teamet svarer vanligvis innen 20 min",
    "the_team_typically_replies_in_1200": "Teamet svarer vanligvis innen 20 min",
    "the_team_typically_replies_in_14400": "Teamet svarer vanligvis innen 4 timer",
    "the_team_typically_replies_in_172800": "Teamet svarer vanligvis innen 2 dager",
    "the_team_typically_replies_in_180": "Teamet svarer vanligvis innen 30 min",
    "the_team_typically_replies_in_1800": "Teamet svarer vanligvis innen 30 min",
    "the_team_typically_replies_in_18000": "Teamet svarer vanligvis innen 5 timer",
    "the_team_typically_replies_in_21600": "Teamet svarer vanligvis innen 6 timer",
    "the_team_typically_replies_in_240": "Teamet svarer vanligvis innen 4 timer",
    "the_team_typically_replies_in_259200": "Teamet svarer vanligvis innen 3 dager",
    "the_team_typically_replies_in_300": "Teamet svarer vanligvis innen 5 min",
    "the_team_typically_replies_in_345600": "Teamet svarer vanligvis innen 4 dager",
    "the_team_typically_replies_in_3600": "Teamet svarer vanligvis innen 1 time",
    "the_team_typically_replies_in_43200": "Teamet svarer vanligvis innen 12 timer",
    "the_team_typically_replies_in_432000": "Teamet svarer vanligvis innen 5 dager",
    "the_team_typically_replies_in_518400": "Teamet svarer vanligvis innen 6 dager",
    "the_team_typically_replies_in_60": "Teamet svarer vanligvis innen 10 min",
    "the_team_typically_replies_in_600": "Teamet svarer vanligvis innen 10 min",
    "the_team_typically_replies_in_604800": "Teamet svarer vanligvis innen 1 uke",
    "the_team_typically_replies_in_7200": "Teamet svarer vanligvis innen 2 timer",
    "the_team_typically_replies_in_86400": "Teamet svarer vanligvis innen 1 dag",
    "the_team_typically_replies_in_900": "Teamet svarer vanligvis innen 15 min",
    "the_team_typically_replies_in_day": "Teamet svarer vanligvis i løpet av en dag.",
    "the_team_typically_replies_in_hours": "Teamet svarer vanligvis i løpet av noen få timer.",
    "the_team_typically_replies_in_minutes": "Teamet svarer vanligvis i løpet av noen få minutter.",
    "the_team_typically_replies_in_under_n_hours": "Teamet svarer vanligvis innen {hours}t.",
    "the_team_typically_replies_in_under_n_min": "Teamet svarer vanligvis innen {minutes}m.",
    "the_team_typically_replies_in_unknown": "Teamet vil svare så snart som mulig",
    "the_team_typically_replies_in_week": "Teamet svarer vanligvis i løpet av en uke.",
    "thinking": "Tenker...",
    "tickets_cta_text": "Har du et spørsmål om denne saken?",
    "tickets_have_a_question": "Har du et spørsmål om dette problemet?",
    "tickets_space_title": "Saker",
    "time_future_1h": "1 time",
    "time_future_2h": "2 timer",
    "time_future_30m": "30 minutter",
    "time_future_3h": "3 timer",
    "time_future_day_0": "mandag",
    "time_future_day_1": "tirsdag",
    "time_future_day_2": "onsdag",
    "time_future_day_3": "torsdag",
    "time_future_day_4": "fredag",
    "time_future_day_5": "lørdag",
    "time_future_day_6": "søndag",
    "time_future_later_today": "senere i dag",
    "time_future_next_week": "neste uke",
    "time_future_tomorrow": "i morgen",
    "try_use_different_keywords": "Prøv å bruke andre søkeord, eller skriv inn hele spørsmålet ditt",
    "upfront_collection_form_close_out": "Du kan når som helst komme tilbake hit for å se på svarene eller sende oppdateringer.",
    "upfront_collection_form_introduction": "Del litt informasjon om innhold for å hjelpe teamet med å svare raskere og bedre.",
    "upfront_collection_form_submission_confirmation": "Takk for at du sendte informasjon om innholdet. Du kan legge til mer informasjon nå, for å spare tid senere.",
    "upfront_collection_form_subtitle": "Send litt innhold til teamet",
    "upfront_collection_form_subtitle_submitted": "De vil ta hensyn til informasjonen du oppgir",
    "upfront_collection_form_title": "Få hjelp raskere",
    "upfront_collection_form_title_submitted": "Sendt til teamet!",
    "view_all": "Vis alle",
    "view_conversation": "Vis samtale",
    "we_are_here_to_help": "Vi hjelper deg gjerne",
    "we_run_on_intercom": "Vi kjører på Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Når et teammedlem endrer en samtales status ...",
    "working_on_it": "Jobber med saken...",
    "you_are_all_up_to_date": "Du er helt ajour!",
    "your_checklists": "Dine sjekklister",
    "your_conversations": "Dine samtaler",
    "your_recent_conversations": "Nylige samtaler",
    "your_recent_messages": "Nylige meldinger"
  },
  "pl": {
    "a_day": "Dzień",
    "a_few_hours": "Kilka godzin",
    "a_few_minutes": "Kilka minut",
    "a_week": "Tydzień",
    "active_state": "Aktywny",
    "ai_answer": "Odpowiedź SI",
    "ai_answer_information": "Odpowiedź SI, informacje",
    "ai_bot": "Bot SI",
    "almost_there": "Prawie...",
    "answer": "Odpowiedź",
    "answer_bot_acknowledge_duplicate_content": "To już było, ale to najlepsza dostępna odpowiedź.",
    "answer_bot_all_done": "To pomogło 👍",
    "answer_bot_ask_another_question": "Zadaj inne pytanie 🔁",
    "answer_bot_ask_something_else": "Zapytaj o coś innego 🔁",
    "answer_bot_connect_to_an_agent": "Połącz się z agentem 👤",
    "answer_bot_did_that_help": "Czy ta odpowiedź była pomocna, czy szukasz czegoś innego?",
    "answer_bot_followup_expectations_prefix": "Zespół skontaktuje się z Tobą w tej sprawie.",
    "answer_bot_get_more_help": "Uzyskaj dodatkową pomoc 🌐",
    "answer_bot_get_more_help_followup": "To łącze może pomóc:",
    "answer_bot_i_have_another_question": "Mam kolejne pytanie 🔁",
    "answer_bot_im_not_done_yet": "Mam jeszcze pytania 🔁",
    "answer_bot_keep_waiting": "Nie, nadal potrzebuję pomocy 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Sformułuj pytanie inaczej lub uzyskaj dodatkową pomoc",
    "answer_bot_looping_first_no_answer": "Niestety bot nie jest w stanie odpowiedzieć na to pytanie.",
    "answer_bot_looping_first_no_answer_followup": "Sformułuj pytanie inaczej lub porozmawiaj z naszym zespołem",
    "answer_bot_looping_no_answer": "Niestety bot nie jest w stanie odpowiedzieć na to pytanie. Są dostępne następujące opcje",
    "answer_bot_more_answers": "Pokaż więcej 👀",
    "answer_bot_no_answer_found": "Niestety możemy szybko udzielić odpowiedzi. Oto kilka innych opcji.",
    "answer_bot_resolve_followup": "Możesz zawsze napisać do nas tutaj, jeśli coś jeszcze przyjdzie Ci do głowy.",
    "answer_bot_talk_to_person": "Porozmawiaj z osobą 👤",
    "answer_bot_that_answered_my_question": "To wyczerpująca odpowiedź 👍",
    "answer_bot_thats_it": "To wszystko 👍",
    "answer_bot_wait_for_the_team": "Poczekaj na kogoś z zespołu 💬",
    "answerbot_ask_for_more_context": "Oczywiście. Czy w czasie oczekiwania możesz podać więcej szczegółów dotyczących swojej sytuacji lub pytania? To pomoże nam szybciej znaleźć odpowiedź, a być może uda mi się znaleźć więcej informacji.",
    "answerbot_introduce_sneaky_answer": "Dziękuję za dodatkowy kontekst. Udało mi się znaleźć nowe informacje, które mogą okazać się pomocne.",
    "article_search_hint_text": "Przeszukaj artykuły...",
    "ask_a_question": "Zadaj pytanie",
    "ask_a_question_placeholder": "Zadaj pytanie...",
    "ask_a_question_with_ellipsis": "Zadaj pytanie...",
    "ask_another_question_with_ellipsis": "Zadaj kolejne pytanie...",
    "ask_for_the_team_if_needed": "W razie potrzeby skontaktuj się z zespołem",
    "ask_me_a_question": "Zadaj mi pytanie",
    "ask_our_bot_a_question": "Zadaj pytanie naszemu botowi",
    "avatar": "Awatar",
    "avatars": "Awatary",
    "bot": "Robot",
    "bot_answers_instantly": "Bot odpowiada natychmiast",
    "bot_expectation_text": "Nasz bot może odpowiedzieć na wiele typowych pytań",
    "bot_intro_default_part_1": "Cześć! Jestem botem. Moim zadaniem jest odpowiedzieć na Twoje pytania, ale w każdej chwili możesz skontaktować się z naszym zespołem.",
    "bot_intro_default_part_2": "W czym zatem mogę Ci dziś pomóc?",
    "bot_is_still_thinking": "Bot wciąż przetwarza...",
    "bot_is_thinking": "Bot przetwarza...",
    "browse_collections": "Przeglądaj kolekcje",
    "chat_with_us": "Porozmawiaj z nami",
    "check_later_for_updates": "Sprawdź później, czy są dostępne nowe informacje.",
    "checklist_card_first_step": "Pierwszy krok",
    "checklist_card_next_step": "Następny krok",
    "checklist_completed": "Zakończono",
    "checklist_in_progress_time_remaining_plural": "Zostało około {minutes} min",
    "checklist_in_progress_time_remaining_singular": "Została mniej więcej 1 min",
    "checklist_mark_step_as_complete": "Oznaczenie jako zakończone",
    "checklist_not_started_time_remaining_plural": "Około {minutes} min",
    "checklist_not_started_time_remaining_singular": "Około 1 min",
    "checklist_number_of_steps_plural": "Liczba kroków: {number_of_steps}",
    "checklist_number_of_steps_progress_plural": "Wykonano {steps_completed} z {total_steps}",
    "checklist_number_of_steps_progress_singular": "Wykonano 1 z {total_steps}",
    "checklist_number_of_steps_singular": "1 krok",
    "checklist_sender_name": "Udostępnione przez użytkownika {name}",
    "checklist_step_action_button": "Przycisk działań",
    "checklist_step_completed": "Zakończono",
    "contact_support": "Skontaktuj się z pomocą techniczną",
    "contact_us": "Skontaktuj się z nami",
    "continue_the_conversation": "Kontynuuj rozmowę",
    "conversation_card_office_hours_header": "Wracamy online",
    "conversation_card_reply_time_header": "Nasz standardowy czas odpowiedzi",
    "conversation_card_reply_time_header_m5": "Standardowy czas odpowiedzi",
    "conversation_card_unknown_response_time": "Odpowiemy możliwie szybko",
    "conversation_part_sample_text": "Dzień dobry, w czym mogę pomóc? Zawsze będziesz mieć możliwość porozmawiania z naszym zespołem, jeśli zajdzie taka potrzeba.",
    "conversations_space_title": "Rozmowy",
    "create_a_ticket": "Utwórz zgłoszenie",
    "currently_no_help_articles": "Obecnie nie ma dostępnych artykułów pomocy.",
    "customer_sends_their_first_message": "Klient wysyła swoją pierwszą wiadomość...",
    "expected_response_delay_10800": "w ciągu 3 godz.",
    "expected_response_delay_120": "w ciągu 2 min",
    "expected_response_delay_1200": "w ciągu 20 min",
    "expected_response_delay_14400": "w ciągu 4 godz.",
    "expected_response_delay_172800": "w ciągu 2 dni",
    "expected_response_delay_180": "w ciągu 3 min",
    "expected_response_delay_1800": "w ciągu 30 min",
    "expected_response_delay_18000": "w ciągu 5 godz.",
    "expected_response_delay_21600": "w ciągu 6 godz.",
    "expected_response_delay_240": "w ciągu 4 min",
    "expected_response_delay_259200": "w ciągu 3 dni",
    "expected_response_delay_300": "w ciągu 5 min",
    "expected_response_delay_345600": "w ciągu 4 dni",
    "expected_response_delay_3600": "w ciągu 1 godz.",
    "expected_response_delay_43200": "w ciągu maksymalnie 12 godzin",
    "expected_response_delay_432000": "w ciągu 5 dni",
    "expected_response_delay_518400": "6 dni",
    "expected_response_delay_60": "w ciągu 1 min",
    "expected_response_delay_600": "w ciągu 10 min",
    "expected_response_delay_604800": "w ciągu 1 tygodnia",
    "expected_response_delay_7200": "w ciągu 2 godz.",
    "expected_response_delay_86400": "w ciągu 1 dnia",
    "expected_response_delay_900": "w ciągu 15 min",
    "expected_response_delay_unknown": "Możliwie szybko",
    "extra_labels_count": "+ {labels_count} etykiet(y)",
    "for_best_results_provide_detail": "Aby uzyskać najlepsze wyniki, podaj jak najwięcej szczegółów",
    "generating_answer": "Generowanie odpowiedzi...",
    "generic_triage_default": "Potrzebujemy nieco więcej informacji, aby połączyć Cię z odpowiednią osobą",
    "get_an_instant_answer": "Uzyskaj natychmiastową odpowiedź",
    "get_help": "Uzyskaj pomoc",
    "go_to": "Przejdź do aplikacji {app_name}",
    "help_space_title": "Pomoc",
    "home_header_mobile_hello_firstname": "Witaj, {firstname}!",
    "home_header_mobile_hello_there": "Witaj",
    "home_space_title": "Dom",
    "how_can_we_help": "Jak możemy pomóc?",
    "how_would_you_rate_this": "Jak oceniasz tę treść?",
    "learn_more": "Dowiedz się więcej",
    "message_placeholder": "Napisz wiadomość...",
    "messages_space_title": "Wiadomości",
    "messenger_bot_reply_time": "Nasz bot odpowie natychmiast",
    "messenger_settings_introduction": "Jak możemy pomóc?",
    "multiple_articles": "Liczba artykułów: {total_articles}",
    "multiple_collections": "Liczba kolekcji: {total_collection}",
    "new_conversation": "Nowa rozmowa",
    "news": "Aktualności",
    "news_empty_title": "Na razie brak wiadomości",
    "news_latest_subtitle": "Z zespołu {team_name}",
    "news_latest_title": "Najnowsze",
    "news_older_title": "Starsze",
    "news_space_title": "Aktualności",
    "newsfeed": "Kanał informacyjny",
    "no_articles_to_display": "Nie ma jeszcze artykułów",
    "no_help_articles_to_display": "Brak artykułów pomocy",
    "no_results_for_searchterm": "Brak wyników dla {searchTerm}",
    "no_tasks": "Brak zadań",
    "no_tasks_available_currently": "Obecnie nie ma dostępnych zadań.",
    "ok_the_team_typically_replies_in_day": "Ok, zespół odpowiada zwykle w ciągu jednego dnia.",
    "ok_the_team_typically_replies_in_hours": "Ok, zespół odpowiada zwykle w ciągu kilku godzin.",
    "ok_the_team_typically_replies_in_minutes": "Ok, zespół odpowiada zwykle w ciągu kilku minut.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, zespół odpowiada zwykle w ciągu {hours} godz.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, zespół odpowiada zwykle w ciągu {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Zespół odpowiada zwykle w ciągu tygodnia.",
    "our_bot_and_team_can_help": "Nasz bot i zespół służą pomocą",
    "our_bot_answers_instantly": "Nasz bot odpowiada natychmiast",
    "powered_by_intercom": "Obsługiwane przez Intercom",
    "previous_bot_hands_over": "Treść z poprzedniego bota...",
    "proactive_expected_response_delay_10800": "Zwykle odpowiada w ciągu 3 godz.",
    "proactive_expected_response_delay_120": "Zwykle odpowiada w ciągu 2 min.",
    "proactive_expected_response_delay_1200": "Zwykle odpowiada w ciągu 20 min.",
    "proactive_expected_response_delay_14400": "Zwykle odpowiada w ciągu 4 godz.",
    "proactive_expected_response_delay_172800": "Zwykle odpowiada w ciągu 2 d.",
    "proactive_expected_response_delay_180": "Zwykle odpowiada w ciągu 3 min.",
    "proactive_expected_response_delay_1800": "Zwykle odpowiada w ciągu 30 min.",
    "proactive_expected_response_delay_18000": "Zwykle odpowiada w ciągu 5 godz.",
    "proactive_expected_response_delay_21600": "Zwykle odpowiada w ciągu 6 godz.",
    "proactive_expected_response_delay_240": "Zwykle odpowiada w ciągu 4 min.",
    "proactive_expected_response_delay_259200": "Zwykle odpowiada w ciągu 3 dni",
    "proactive_expected_response_delay_300": "Zwykle odpowiada w ciągu 5 min.",
    "proactive_expected_response_delay_345600": "Zwykle odpowiada w ciągu 4 dni",
    "proactive_expected_response_delay_3600": "Zwykle odpowiada w ciągu 1 godz.",
    "proactive_expected_response_delay_43200": "Zwykle odpowiada w ciągu 12 godzin",
    "proactive_expected_response_delay_432000": "Zwykle odpowiada w ciągu 5 dni",
    "proactive_expected_response_delay_518400": "Zwykle odpowiada w ciągu 6 dni",
    "proactive_expected_response_delay_60": "Zwykle odpowiada w ciągu 1 min.",
    "proactive_expected_response_delay_600": "Zwykle odpowiada w ciągu 10 min.",
    "proactive_expected_response_delay_604800": "Zwykle odpowiada w ciągu 1 tyg.",
    "proactive_expected_response_delay_7200": "Zwykle odpowiada w ciągu 2 godz.",
    "proactive_expected_response_delay_86400": "Zwykle odpowiada w ciągu 1 d.",
    "proactive_expected_response_delay_900": "Zwykle odpowiada w ciągu 15 min",
    "proactive_expected_response_delay_unknown": "Odpowie tak szybko, jak to możliwe",
    "profile_location": "{time} w {location}",
    "recent_ticket": "Najnowsze zgłoszenie",
    "recent_tickets": "Najnowsze zgłoszenia",
    "search_browse_empty_state_text": "Niestety nie można znaleźć żadnych wyników. Spróbuj przeformułować wyszukiwanie.",
    "search_for_help": "Poszukaj pomocy",
    "see_all": "Wyświetl wszystkie",
    "see_all_conversations": "Wyświetl wszystkie swoje rozmowy",
    "see_previous_conversations": "Zobacz swoje poprzednie rozmowy",
    "send": "Wyślij",
    "send_a_message": "Wyślij wiadomość…",
    "send_a_message_reply_time": "Standardowy czas odpowiedzi to",
    "send_us_a_message": "Wyślij do nas wiadomość",
    "set_expectations_bot_email_header": "Otrzymasz odpowiedzi tutaj i w wiadomości e-mail:",
    "set_expectations_bot_office_hours_header": "Gdy wróci zespół:",
    "set_expectations_bot_sms_header": "Otrzymasz odpowiedzi tutaj i w wiadomości SMS:",
    "set_expectations_replies_to_email": "Otrzymasz odpowiedzi tutaj i na adres <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Otrzymasz odpowiedzi tutaj i na adres {user_email}",
    "set_expectations_team_reply_asap": "Zespół odpowie tak szybko, jak to możliwe.",
    "shared_by": "Udostępnione przez",
    "show_less": "Pokaż mniej",
    "single_article": "1 artykuł",
    "single_collection": "1 kolekcja",
    "source": "Źródło",
    "sources": "Źródła",
    "start_a_conversation": "Rozpocznij rozmowę",
    "start_conversation": "Rozpocznij rozmowę",
    "still_generating_answer": "Wciąż generuję odpowiedź...",
    "still_thinking": "Wciąż się zastanawiam...",
    "still_working_on_it": "Wciąż nad tym pracuję...",
    "tasks_space_title": "Zadania",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} odpowie tak szybko, jak to możliwe",
    "teammate_changes_team_assignment_of_a_conversation": "Członek zespołu zmienił przypisanie rozmowy...",
    "tell_us_what_you_need": "Opisz problem, a nasz bot postara się pomóc.",
    "the_team_can_help_if_needed": "Zespół może w razie potrzeby pomóc",
    "the_team_typically_replies_in_10800": "Zespół odpowiada zwykle w ciągu mniej niż 3 godz.",
    "the_team_typically_replies_in_120": "Zespół odpowiada zwykle w ciągu 2 min.",
    "the_team_typically_replies_in_1200": "Zespół odpowiada zwykle w ciągu mniej niż 20 min.",
    "the_team_typically_replies_in_14400": "Zespół odpowiada zwykle w ciągu mniej niż 4 godz.",
    "the_team_typically_replies_in_172800": "Zespół odpowiada zwykle w ciągu 2 dni",
    "the_team_typically_replies_in_180": "Zespół odpowiada zwykle w ciągu 3 min.",
    "the_team_typically_replies_in_1800": "Zespół odpowiada zwykle w ciągu mniej niż 30 min.",
    "the_team_typically_replies_in_18000": "Zespół odpowiada zwykle w ciągu mniej niż 5 godz.",
    "the_team_typically_replies_in_21600": "Zespół odpowiada zwykle w ciągu mniej niż 6 godz.",
    "the_team_typically_replies_in_240": "Zespół odpowiada zwykle w ciągu 4 min.",
    "the_team_typically_replies_in_259200": "Zespół odpowiada zwykle w ciągu 3 dni",
    "the_team_typically_replies_in_300": "Zespół odpowiada zwykle w ciągu mniej niż 5 min.",
    "the_team_typically_replies_in_345600": "Zespół odpowiada zwykle w ciągu 4 dni",
    "the_team_typically_replies_in_3600": "Zespół odpowiada zwykle w ciągu mniej niż 1 godz.",
    "the_team_typically_replies_in_43200": "Zespół odpowiada zwykle w ciągu maksymalnie 12 godzin",
    "the_team_typically_replies_in_432000": "Zespół odpowiada zwykle w ciągu 5 dni",
    "the_team_typically_replies_in_518400": "Zespół odpowiada zwykle w ciągu 6 dni",
    "the_team_typically_replies_in_60": "Zespół odpowiada zwykle w ciągu 1 min.",
    "the_team_typically_replies_in_600": "Zespół odpowiada zwykle w ciągu mniej niż 10 min.",
    "the_team_typically_replies_in_604800": "Zespół odpowiada zwykle w ciągu mniej niż 1 tydz.",
    "the_team_typically_replies_in_7200": "Zespół odpowiada zwykle w ciągu mniej niż 2 godz.",
    "the_team_typically_replies_in_86400": "Zespół odpowiada zwykle w ciągu 1 dnia",
    "the_team_typically_replies_in_900": "Zespół odpowiada zwykle w ciągu 15 min",
    "the_team_typically_replies_in_day": "Zespół odpowiada zwykle w ciągu jednego dnia.",
    "the_team_typically_replies_in_hours": "Zespół odpowiada zwykle w ciągu kilku godzin.",
    "the_team_typically_replies_in_minutes": "Zespół odpowiada zwykle w ciągu kilku minut.",
    "the_team_typically_replies_in_under_n_hours": "Zespół odpowiada zwykle w ciągu {hours} godz.",
    "the_team_typically_replies_in_under_n_min": "Zespół odpowiada zwykle w ciągu {minutes} min.",
    "the_team_typically_replies_in_unknown": "Zespół odpowie tak szybko, jak to możliwe",
    "the_team_typically_replies_in_week": "Zespół odpowiada zwykle w ciągu tygodnia.",
    "thinking": "Zastanawiam się...",
    "tickets_cta_text": "Masz pytanie dotyczące tego zgłoszenia?",
    "tickets_have_a_question": "Masz pytanie dotyczące tego problemu?",
    "tickets_space_title": "Zgłoszenia",
    "time_future_1h": "za 1 godz.",
    "time_future_2h": "za 2 godz.",
    "time_future_30m": "za 30 min",
    "time_future_3h": "za 3 godz.",
    "time_future_day_0": "w poniedziałek",
    "time_future_day_1": "we wtorek",
    "time_future_day_2": "w środę",
    "time_future_day_3": "w czwartek",
    "time_future_day_4": "w piątek",
    "time_future_day_5": "w sobotę",
    "time_future_day_6": "w niedzielę",
    "time_future_later_today": "jeszcze dzisiaj",
    "time_future_next_week": "w przyszłym tygodniu",
    "time_future_tomorrow": "jutro",
    "try_use_different_keywords": "Spróbuj użyć innych słów kluczowych lub wpisz pełne pytanie",
    "upfront_collection_form_close_out": "Możesz tu wrócić w dowolnym momencie, aby zobaczyć odpowiedzi i przesłać nowe informacje.",
    "upfront_collection_form_introduction": "Udostępnij kontekst, aby pomóc zespołowi reagować lepiej i szybciej.",
    "upfront_collection_form_submission_confirmation": "Dziękujemy za przesłanie kontekstu. Aby zaoszczędzić czas później, możesz dodać więcej szczegółów teraz.",
    "upfront_collection_form_subtitle": "Wyślij zespołowi kontekst",
    "upfront_collection_form_subtitle_submitted": "Będą go mieć na uwadze",
    "upfront_collection_form_title": "Uzyskaj pomoc szybciej",
    "upfront_collection_form_title_submitted": "Wysłano do zespołu!",
    "view_all": "Pokaż wszystkie",
    "view_conversation": "Wyświetl rozmowę",
    "we_are_here_to_help": "Jesteśmy tutaj, aby służyć pomocą",
    "we_run_on_intercom": "Używamy Intercomu",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kiedy członek zespołu zmienia status rozmowy…",
    "working_on_it": "Pracuję nad tym...",
    "you_are_all_up_to_date": "To już wszystko!",
    "your_checklists": "Twoje listy kontrolne",
    "your_conversations": "Twoje rozmowy",
    "your_recent_conversations": "Ostatnie rozmowy",
    "your_recent_messages": "Najnowsze wiadomości"
  },
  "pt": {
    "a_day": "Um dia",
    "a_few_hours": "Algumas horas",
    "a_few_minutes": "Alguns minutos",
    "a_week": "Uma semana",
    "active_state": "Ativo",
    "ai_answer": "Resposta IA",
    "ai_answer_information": "Resposta IA, informação",
    "ai_bot": "Bot AI",
    "almost_there": "Quase lá...",
    "answer": "Resposta",
    "answer_bot_acknowledge_duplicate_content": "Já viu isto antes, mas esta é a melhor resposta disponível.",
    "answer_bot_all_done": "Isso ajudou 👍",
    "answer_bot_ask_another_question": "Fazer outra pergunta 🔁",
    "answer_bot_ask_something_else": "Fazer outra pergunta 🔁",
    "answer_bot_connect_to_an_agent": "Entrar em contacto com um agente 👤",
    "answer_bot_did_that_help": "A resposta ajudou ou está à procura de outra coisa?",
    "answer_bot_followup_expectations_prefix": "A equipa irá responder-lhe.",
    "answer_bot_get_more_help": "Obter mais ajuda 🌐",
    "answer_bot_get_more_help_followup": "Este link pode ajudar:",
    "answer_bot_i_have_another_question": "Tenho outra pergunta 🔁",
    "answer_bot_im_not_done_yet": "Ainda não terminei 🔁",
    "answer_bot_keep_waiting": "Não, ainda preciso de ajuda 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Pode reformular a sua pergunta ou obter ajuda adicional",
    "answer_bot_looping_first_no_answer": "Desculpe, como bot não consegui encontrar uma resposta para isso.",
    "answer_bot_looping_first_no_answer_followup": "Pode reformular a sua pergunta ou falar com a nossa equipa",
    "answer_bot_looping_no_answer": "Desculpe, como bot não consegui encontrar uma resposta para isso. Eis o que pode fazer",
    "answer_bot_more_answers": "Mostrar-me mais 👀",
    "answer_bot_no_answer_found": "Lamentamos, mas não temos uma resposta rápida para isso. Aqui estão outras opções.",
    "answer_bot_resolve_followup": "Pode responder aqui, se surgir mais alguma coisa.",
    "answer_bot_talk_to_person": "Fale com uma pessoa 👤",
    "answer_bot_that_answered_my_question": "Isso respondeu à minha pergunta 👍",
    "answer_bot_thats_it": "Era isso 👍",
    "answer_bot_wait_for_the_team": "Aguarde pela equipa💬",
    "answerbot_ask_for_more_context": "Com certeza. Enquanto aguardamos, pode partilhar mais detalhes sobre a sua situação ou pergunta? Assim podemos encontrar uma resposta mais rapidamente e procurar informações adicionais para si.",
    "answerbot_introduce_sneaky_answer": "Agradeço esta contextualização. Encontrei mais informações que podem ajudar.",
    "article_search_hint_text": "Pesquise artigos...",
    "ask_a_question": "Faça uma pergunta",
    "ask_a_question_placeholder": "Faça uma pergunta…",
    "ask_a_question_with_ellipsis": "Faz uma pergunta...",
    "ask_another_question_with_ellipsis": "Faz outra pergunta...",
    "ask_for_the_team_if_needed": "Peça para falar com a equipa, se necessário",
    "ask_me_a_question": "Faça-me uma pergunta",
    "ask_our_bot_a_question": "Faz uma pergunta ao nosso bot",
    "avatar": "Avatar",
    "avatars": "avatares",
    "bot": "Bot",
    "bot_answers_instantly": "Bot responde instantaneamente",
    "bot_expectation_text": "O nosso bot pode responder a muitas perguntas comuns",
    "bot_intro_default_part_1": "Olá! Sou um bot e estou aqui para responder às suas perguntas. Caso necessário, terá sempre a opção de falar com a nossa equipa.",
    "bot_intro_default_part_2": "Então, o que o(a) traz aqui hoje?",
    "bot_is_still_thinking": "O bot ainda está a pensar...",
    "bot_is_thinking": "O bot está a pensar...",
    "browse_collections": "Procurar coleções",
    "chat_with_us": "Converse connosco",
    "check_later_for_updates": "Verifique novamente mais tarde se há atualizações.",
    "checklist_card_first_step": "Primeiro passo",
    "checklist_card_next_step": "Próximo passo",
    "checklist_completed": "Concluído",
    "checklist_in_progress_time_remaining_plural": "Faltam cerca de {minutes} minutos",
    "checklist_in_progress_time_remaining_singular": "Falta cerca de 1 minuto",
    "checklist_mark_step_as_complete": "Marcar como concluído",
    "checklist_not_started_time_remaining_plural": "Cerca de {minutes} minutos",
    "checklist_not_started_time_remaining_singular": "Cerca de 1 minuto",
    "checklist_number_of_steps_plural": "{number_of_steps} passos",
    "checklist_number_of_steps_progress_plural": "{steps_completed} de {total_steps} concluídos",
    "checklist_number_of_steps_progress_singular": "1 de {total_steps} concluído",
    "checklist_number_of_steps_singular": "1 passo",
    "checklist_sender_name": "Partilhadas por {name}",
    "checklist_step_action_button": "Botão de ação de passos",
    "checklist_step_completed": "Concluído",
    "contact_support": "Contactar assistência",
    "contact_us": "Contacte-nos",
    "continue_the_conversation": "Continuar a conversa",
    "conversation_card_office_hours_header": "Quando estaremos novamente online",
    "conversation_card_reply_time_header": "O nosso tempo de resposta habitual",
    "conversation_card_reply_time_header_m5": "Tempo de resposta habitual",
    "conversation_card_unknown_response_time": "Responderemos assim que possível",
    "conversation_part_sample_text": "Olá, como posso ajudar? Terá sempre a possibilidade de falar com a nossa equipa, se necessário.",
    "conversations_space_title": "Conversas",
    "create_a_ticket": "Criar um ticket",
    "currently_no_help_articles": "Não há artigos de ajuda disponíveis de momento.",
    "customer_sends_their_first_message": "O cliente envia a sua primeira mensagem...",
    "expected_response_delay_10800": "menos de 3 horas",
    "expected_response_delay_120": "menos de 2 minutos",
    "expected_response_delay_1200": "menos de 20 minutos",
    "expected_response_delay_14400": "menos de 4 horas",
    "expected_response_delay_172800": "2 dias",
    "expected_response_delay_180": "menos de 3 minutos",
    "expected_response_delay_1800": "menos de 30 minutos",
    "expected_response_delay_18000": "menos de 5 horas",
    "expected_response_delay_21600": "menos de 6 horas",
    "expected_response_delay_240": "menos de 4 minutos",
    "expected_response_delay_259200": "3 dias",
    "expected_response_delay_300": "menos de 5 minutos",
    "expected_response_delay_345600": "4 dias",
    "expected_response_delay_3600": "menos de 1 hora",
    "expected_response_delay_43200": "menos de 12 horas",
    "expected_response_delay_432000": "5 dias",
    "expected_response_delay_518400": "6 dias",
    "expected_response_delay_60": "menos de 1 minuto",
    "expected_response_delay_600": "menos de 10 minutos",
    "expected_response_delay_604800": "menos de 1 semana",
    "expected_response_delay_7200": "menos de 2 horas",
    "expected_response_delay_86400": "1 dia",
    "expected_response_delay_900": "menos de 15 minutos",
    "expected_response_delay_unknown": "Assim que possível",
    "extra_labels_count": "+ {labels_count} etiquetas",
    "for_best_results_provide_detail": "Para obter melhores resultados, forneça o máximo de pormenores possível",
    "generating_answer": "A gerar resposta...",
    "generic_triage_default": "Só precisamos de um pouco mais de informações para ligá-lo à pessoa certa.",
    "get_an_instant_answer": "Obtenha uma resposta instantânea",
    "get_help": "Obter ajuda",
    "go_to": "Ir para {app_name}",
    "help_space_title": "Ajuda",
    "home_header_mobile_hello_firstname": "Olá, {firstname}.",
    "home_header_mobile_hello_there": "Olá",
    "home_space_title": "Início",
    "how_can_we_help": "Como podemos ajudar?",
    "how_would_you_rate_this": "Como classificaria isto?",
    "learn_more": "Saiba mais",
    "message_placeholder": "Envie uma mensagem...",
    "messages_space_title": "Mensagens",
    "messenger_bot_reply_time": "O nosso bot responderá instantaneamente",
    "messenger_settings_introduction": "Como podemos ajudar?",
    "multiple_articles": "{total_articles} artigos",
    "multiple_collections": "{total_collection} coleções",
    "new_conversation": "Nova conversa",
    "news": "Notícias",
    "news_empty_title": "Nenhuma notícia ainda",
    "news_latest_subtitle": "Da equipa {team_name}",
    "news_latest_title": "Mais recentes",
    "news_older_title": "Mais antigo",
    "news_space_title": "Notícias",
    "newsfeed": "Feed de notícias",
    "no_articles_to_display": "Ainda não há artigos",
    "no_help_articles_to_display": "Sem artigos de ajuda",
    "no_results_for_searchterm": "Nenhum resultado para {searchTerm}",
    "no_tasks": "Nenhuma tarefa",
    "no_tasks_available_currently": "De momento não existem tarefas disponíveis.",
    "ok_the_team_typically_replies_in_day": "Ok. A equipa responde, normalmente, num dia.",
    "ok_the_team_typically_replies_in_hours": "Ok. A equipa responde normalmente em algumas horas.",
    "ok_the_team_typically_replies_in_minutes": "Ok. A equipa responde normalmente em alguns minutos.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok. A equipa responde normalmente em menos de {hours} h.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok. A equipa responde normalmente em menos de {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Ok. A equipa responde, normalmente, numa semana.",
    "our_bot_and_team_can_help": "O nosso bot e a nossa equipe podem ajudar",
    "our_bot_answers_instantly": "O nosso bot responde instantaneamente",
    "powered_by_intercom": "Desenvolvido pela Intercom",
    "previous_bot_hands_over": "Conteúdo do bot anterior...",
    "proactive_expected_response_delay_10800": "Responde normalmente em menos de 3h",
    "proactive_expected_response_delay_120": "Normalmente responde em menos de 2 min",
    "proactive_expected_response_delay_1200": "Responde normalmente em menos de 20 min",
    "proactive_expected_response_delay_14400": "Responde normalmente em menos de 4h",
    "proactive_expected_response_delay_172800": "Responde normalmente em 2d",
    "proactive_expected_response_delay_180": "Normalmente responde em menos de 3 min",
    "proactive_expected_response_delay_1800": "Responde normalmente em menos de 30 min",
    "proactive_expected_response_delay_18000": "Responde normalmente em menos de 5h",
    "proactive_expected_response_delay_21600": "Responde normalmente em menos de 6h",
    "proactive_expected_response_delay_240": "Normalmente responde em menos de 4 min",
    "proactive_expected_response_delay_259200": "Responde normalmente em 3 dias",
    "proactive_expected_response_delay_300": "Responde normalmente em menos de 5 min",
    "proactive_expected_response_delay_345600": "Responde normalmente em 4 dias",
    "proactive_expected_response_delay_3600": "Responde normalmente em menos de 1h",
    "proactive_expected_response_delay_43200": "Responde normalmente em 12 horas",
    "proactive_expected_response_delay_432000": "Responde normalmente em 5 dias",
    "proactive_expected_response_delay_518400": "Responde normalmente em 6 dias",
    "proactive_expected_response_delay_60": "Normalmente responde em menos de 1 min",
    "proactive_expected_response_delay_600": "Responde normalmente em menos de 10 min",
    "proactive_expected_response_delay_604800": "Responde normalmente em menos de 1s",
    "proactive_expected_response_delay_7200": "Responde normalmente em menos de 2h",
    "proactive_expected_response_delay_86400": "Responde normalmente em 1d",
    "proactive_expected_response_delay_900": "Responde normalmente em menos de 15 min",
    "proactive_expected_response_delay_unknown": "Responderá assim que puder",
    "profile_location": "{time} em {location}",
    "recent_ticket": "Ticket recente",
    "recent_tickets": "Tickets recentes",
    "search_browse_empty_state_text": "Lamentamos, mas não foi possível encontrar quaisquer resultados. Tente reformular a sua pesquisa.",
    "search_for_help": "Procure ajuda",
    "see_all": "Ver tudo",
    "see_all_conversations": "Ver todas as suas conversas",
    "see_previous_conversations": "Veja as suas conversas anteriores",
    "send": "Enviar",
    "send_a_message": "Enviar mensagem…",
    "send_a_message_reply_time": "O tempo de resposta habitual é",
    "send_us_a_message": "Envie-nos uma mensagem",
    "set_expectations_bot_email_header": "Receberá respostas aqui ou no seu e-mail:",
    "set_expectations_bot_office_hours_header": "Quando a equipa estará de volta:",
    "set_expectations_bot_sms_header": "Receberá respostas aqui ou por SMS:",
    "set_expectations_replies_to_email": "Receberá respostas aqui e em <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Receberá respostas aqui e em {user_email}",
    "set_expectations_team_reply_asap": "A equipa responderá assim que puder.",
    "shared_by": "Partilhado por",
    "show_less": "Mostrar menos",
    "single_article": "1 artigo",
    "single_collection": "1 coleção",
    "source": "Fonte",
    "sources": "Fonte",
    "start_a_conversation": "Começar uma conversa",
    "start_conversation": "Começar conversa",
    "still_generating_answer": "Ainda a gerar resposta...",
    "still_thinking": "Ainda a pensar...",
    "still_working_on_it": "Ainda a trabalhar nisso...",
    "tasks_space_title": "Tarefas",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} responderá assim que puder",
    "teammate_changes_team_assignment_of_a_conversation": "Um colega de equipa alterou a atribuição da conversa...",
    "tell_us_what_you_need": "Diga-nos com o que precisa de ajuda e o nosso bot fará o melhor para responder",
    "the_team_can_help_if_needed": "A equipa pode ajudar se necessário",
    "the_team_typically_replies_in_10800": "A equipa responde normalmente em menos de 3h.",
    "the_team_typically_replies_in_120": "A equipa normalmente responde em menos de 2 min",
    "the_team_typically_replies_in_1200": "A equipa responde normalmente em menos de 20 min.",
    "the_team_typically_replies_in_14400": "A equipa responde normalmente em menos de 4h.",
    "the_team_typically_replies_in_172800": "A equipa responde normalmente em 2 dias.",
    "the_team_typically_replies_in_180": "A equipa normalmente responde em menos de 3 min",
    "the_team_typically_replies_in_1800": "A equipa responde normalmente em menos de 30 min.",
    "the_team_typically_replies_in_18000": "A equipa responde normalmente em menos de 5h.",
    "the_team_typically_replies_in_21600": "A equipa responde normalmente em menos de 6h.",
    "the_team_typically_replies_in_240": "A equipa normalmente responde em menos de 4 min",
    "the_team_typically_replies_in_259200": "A equipa responde normalmente em 3 dias.",
    "the_team_typically_replies_in_300": "A equipa responde normalmente em menos de 5 min.",
    "the_team_typically_replies_in_345600": "A equipa responde normalmente em 4 dias.",
    "the_team_typically_replies_in_3600": "A equipa responde normalmente em menos de 1h.",
    "the_team_typically_replies_in_43200": "A equipa responde normalmente em menos de 12 horas.",
    "the_team_typically_replies_in_432000": "A equipa responde normalmente em 5 dias.",
    "the_team_typically_replies_in_518400": "A equipa responde normalmente em 6 dias.",
    "the_team_typically_replies_in_60": "A equipa normalmente responde em menos de 1 min",
    "the_team_typically_replies_in_600": "A equipa responde normalmente em menos de 10 min.",
    "the_team_typically_replies_in_604800": "A equipa responde normalmente em menos de 1 sem.",
    "the_team_typically_replies_in_7200": "A equipa responde normalmente em menos de 2h.",
    "the_team_typically_replies_in_86400": "A equipa responde normalmente em 1 dia.",
    "the_team_typically_replies_in_900": "A equipa normalmente responde em menos de 15 min",
    "the_team_typically_replies_in_day": "A equipa responde, normalmente, num dia.",
    "the_team_typically_replies_in_hours": "A equipa responde normalmente em algumas horas.",
    "the_team_typically_replies_in_minutes": "A equipa responde normalmente em alguns minutos.",
    "the_team_typically_replies_in_under_n_hours": "A equipa responde normalmente em menos de {hours}h.",
    "the_team_typically_replies_in_under_n_min": "A equipa responde normalmente em menos de {minutes}m.",
    "the_team_typically_replies_in_unknown": "A equipa responderá assim que puder",
    "the_team_typically_replies_in_week": "A equipa responde, normalmente, numa semana.",
    "thinking": "A pensar...",
    "tickets_cta_text": "Tem alguma dúvida sobre este ticket?",
    "tickets_have_a_question": "Tem alguma dúvida sobre este problema?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "Em 1 hora",
    "time_future_2h": "Em 2 horas",
    "time_future_30m": "Em 30 minutos",
    "time_future_3h": "Em 3 horas",
    "time_future_day_0": "Segunda-feira",
    "time_future_day_1": "Terça-feira",
    "time_future_day_2": "Quarta-feira",
    "time_future_day_3": "Quinta-feira",
    "time_future_day_4": "Sexta-feira",
    "time_future_day_5": "Sábado",
    "time_future_day_6": "Domingo",
    "time_future_later_today": "Hoje mais tarde",
    "time_future_next_week": "Próxima semana",
    "time_future_tomorrow": "Amanhã",
    "try_use_different_keywords": "Tente usar palavras-chave diferentes ou escreva a sua pergunta completa",
    "upfront_collection_form_close_out": "Pode voltar aqui a qualquer altura para ver as respostas e enviar atualizações.",
    "upfront_collection_form_introduction": "Partilhe algum contexto para ajudar a equipa a responder melhor e mais rapidamente.",
    "upfront_collection_form_submission_confirmation": "Obrigado por enviar mais informação. Para poupar tempo mais tarde, sinta-se à vontade para adicionar mais detalhes agora.",
    "upfront_collection_form_subtitle": "Envie mais informação à equipa",
    "upfront_collection_form_subtitle_submitted": "Terão em conta essa informação",
    "upfront_collection_form_title": "Obtenha ajuda mais rapidamente",
    "upfront_collection_form_title_submitted": "Enviado para a equipa!",
    "view_all": "Ver tudo",
    "view_conversation": "Ver conversa",
    "we_are_here_to_help": "Estamos aqui para ajudar",
    "we_run_on_intercom": "Usamos a tecnologia da Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Quando um colega de equipa altera o estado de uma conversa...",
    "working_on_it": "A trabalhar nisso...",
    "you_are_all_up_to_date": "Está tudo atualizado!",
    "your_checklists": "As suas listas de verificação",
    "your_conversations": "As suas conversas",
    "your_recent_conversations": "Conversas recentes",
    "your_recent_messages": "Mensagens recentes"
  },
  "pt-BR": {
    "a_day": "Um dia",
    "a_few_hours": "Algumas horas",
    "a_few_minutes": "Alguns minutos",
    "a_week": "Uma semana",
    "active_state": "Ativo",
    "ai_answer": "Resposta de IA",
    "ai_answer_information": "Resposta da IA, informação",
    "ai_bot": "Bot de IA",
    "almost_there": "Quase lá...",
    "answer": "Resposta",
    "answer_bot_acknowledge_duplicate_content": "Você já viu isso, porém, essa é a melhor resposta disponível.",
    "answer_bot_all_done": "Isso ajudou 👍",
    "answer_bot_ask_another_question": "Faça outra pergunta 🔁",
    "answer_bot_ask_something_else": "Pergunte algo mais 🔁",
    "answer_bot_connect_to_an_agent": "Conecte-se a um agente 👤",
    "answer_bot_did_that_help": "Essa resposta foi útil, ou você estava procurando outra coisa?",
    "answer_bot_followup_expectations_prefix": "A equipe entrará em contato com você sobre isso.",
    "answer_bot_get_more_help": "Obtenha mais ajuda 🌐",
    "answer_bot_get_more_help_followup": "Este link pode ajudar:",
    "answer_bot_i_have_another_question": "Tenho outra pergunta 🔁",
    "answer_bot_im_not_done_yet": "Ainda não terminei 🔁",
    "answer_bot_keep_waiting": "Não, ainda preciso de ajuda 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Reformule a sua pergunta ou receba mais ajuda",
    "answer_bot_looping_first_no_answer": "Desculpe, sou um bot e não consegui encontrar uma resposta para isso.",
    "answer_bot_looping_first_no_answer_followup": "Reformule a sua pergunta ou fale com a nossa equipe",
    "answer_bot_looping_no_answer": "Desculpe, sou um bot e não consegui encontrar uma resposta. Veja o que você pode fazer",
    "answer_bot_more_answers": "Mostre mais 👀",
    "answer_bot_no_answer_found": "Desculpe, não temos uma resposta rápida para isso. Aqui estão algumas outras opções.",
    "answer_bot_resolve_followup": "Você sempre pode responder aqui se algo novo surgir.",
    "answer_bot_talk_to_person": "Fale com uma pessoa 👤",
    "answer_bot_that_answered_my_question": "Isso respondeu à minha pergunta 👍",
    "answer_bot_thats_it": "Certo! 👍",
    "answer_bot_wait_for_the_team": "Aguarde a equipe 💬",
    "answerbot_ask_for_more_context": "Claro. Enquanto aguardamos, poderia dar mais detalhes sobre sua situação ou sua dúvida? Assim poderemos chegar a uma resposta mais rápido e eu poderei encontrar mais informações para você.",
    "answerbot_introduce_sneaky_answer": "Obrigado por enviar esse contexto extra. Encontrei algumas informações novas que podem ajudar você.",
    "article_search_hint_text": "Pesquisar artigos...",
    "ask_a_question": "Faça uma pergunta",
    "ask_a_question_placeholder": "Faça uma pergunta…",
    "ask_a_question_with_ellipsis": "Faça uma pergunta...",
    "ask_another_question_with_ellipsis": "Faça outra pergunta...",
    "ask_for_the_team_if_needed": "Peça para falar com a equipe, se necessário",
    "ask_me_a_question": "Faça-me uma pergunta",
    "ask_our_bot_a_question": "Faça uma pergunta ao nosso bot",
    "avatar": "Avatar",
    "avatars": "Avatares",
    "bot": "Bot",
    "bot_answers_instantly": "O bot responde imediatamente",
    "bot_expectation_text": "Nosso bot pode responder a muitas perguntas comuns",
    "bot_intro_default_part_1": "Oi! Sou um bot e estou aqui para responder às suas perguntas. Ou se precisar, você sempre terá a opção de conversar com alguém da nossa equipe.",
    "bot_intro_default_part_2": "Então, em que posso lhe ajudar hoje?",
    "bot_is_still_thinking": "O bot ainda está pensando...",
    "bot_is_thinking": "O bot está pensando...",
    "browse_collections": "Explorar coleções",
    "chat_with_us": "Fale conosco",
    "check_later_for_updates": "Volte mais tarde para saber se há atualizações.",
    "checklist_card_first_step": "Primeira etapa",
    "checklist_card_next_step": "Próxima etapa",
    "checklist_completed": "Concluído",
    "checklist_in_progress_time_remaining_plural": "Faltam cerca de {minutes} minutos",
    "checklist_in_progress_time_remaining_singular": "Falta cerca de 1 minuto",
    "checklist_mark_step_as_complete": "Marcar como concluído",
    "checklist_not_started_time_remaining_plural": "Cerca de {minutes} minutos",
    "checklist_not_started_time_remaining_singular": "Cerca de 1 minuto",
    "checklist_number_of_steps_plural": "{number_of_steps} etapas",
    "checklist_number_of_steps_progress_plural": "{steps_completed} de {total_steps} concluídas",
    "checklist_number_of_steps_progress_singular": "1 de {total_steps} concluída",
    "checklist_number_of_steps_singular": "1 etapa",
    "checklist_sender_name": "Compartilhado por {name}",
    "checklist_step_action_button": "Botão de ação de etapa",
    "checklist_step_completed": "Concluído",
    "contact_support": "Fale com o suporte",
    "contact_us": "Fale conosco",
    "continue_the_conversation": "Continuar a conversa",
    "conversation_card_office_hours_header": "Quando voltaremos online",
    "conversation_card_reply_time_header": "Nosso tempo de resposta habitual",
    "conversation_card_reply_time_header_m5": "Tempo normal de resposta",
    "conversation_card_unknown_response_time": "Responderemos assim que possível",
    "conversation_part_sample_text": "Olá, como posso ajudar? Fale com nossa equipe se necessário e quando quiser.",
    "conversations_space_title": "Conversas",
    "create_a_ticket": "Criar ticket de suporte",
    "currently_no_help_articles": "Não há artigos de ajuda disponíveis no momento.",
    "customer_sends_their_first_message": "O cliente envia sua primeira mensagem...",
    "expected_response_delay_10800": "menos de 3 horas",
    "expected_response_delay_120": "menos de 2 minutos",
    "expected_response_delay_1200": "menos de 20 minutos",
    "expected_response_delay_14400": "menos de 4 horas",
    "expected_response_delay_172800": "2 dias",
    "expected_response_delay_180": "menos de 3 minutos",
    "expected_response_delay_1800": "menos de 30 minutos",
    "expected_response_delay_18000": "menos de 5 horas",
    "expected_response_delay_21600": "menos de 6 horas",
    "expected_response_delay_240": "menos de 4 minutos",
    "expected_response_delay_259200": "3 dias",
    "expected_response_delay_300": "menos de 5 minutos",
    "expected_response_delay_345600": "4 dias",
    "expected_response_delay_3600": "menos de 1 hora",
    "expected_response_delay_43200": "menos de 12 horas",
    "expected_response_delay_432000": "5 dias",
    "expected_response_delay_518400": "6 dias",
    "expected_response_delay_60": "menos de 1 minuto",
    "expected_response_delay_600": "menos de 10 minutos",
    "expected_response_delay_604800": "menos de 1 semana",
    "expected_response_delay_7200": "menos de 2 horas",
    "expected_response_delay_86400": "1 dia",
    "expected_response_delay_900": "menos de 15 minutos",
    "expected_response_delay_unknown": "Assim que possível",
    "extra_labels_count": "+ {labels_count} rótulos",
    "for_best_results_provide_detail": "Para ter melhores resultados, informe o máximo de detalhes possível",
    "generating_answer": "Gerando resposta...",
    "generic_triage_default": "Só precisamos de um pouco mais de informações para conectar você à pessoa certa",
    "get_an_instant_answer": "Receba uma resposta instantânea",
    "get_help": "Buscar ajuda",
    "go_to": "Ir para {app_name}",
    "help_space_title": "Ajuda",
    "home_header_mobile_hello_firstname": "Olá, {firstname}.",
    "home_header_mobile_hello_there": "Olá",
    "home_space_title": "Início",
    "how_can_we_help": "Como podemos ajudar?",
    "how_would_you_rate_this": "Como você avaliaria?",
    "learn_more": "Saiba mais",
    "message_placeholder": "Envie uma mensagem...",
    "messages_space_title": "Mensagens",
    "messenger_bot_reply_time": "Nosso bot responderá imediatamente",
    "messenger_settings_introduction": "Como podemos ajudar?",
    "multiple_articles": "{total_articles} artigos",
    "multiple_collections": "{total_collection} coleções",
    "new_conversation": "Nova conversa",
    "news": "Notícias",
    "news_empty_title": "Ainda não há notícias",
    "news_latest_subtitle": "Da equipe {team_name}",
    "news_latest_title": "Mais recentes",
    "news_older_title": "Mais antigos",
    "news_space_title": "Notícias",
    "newsfeed": "Feed de notícias",
    "no_articles_to_display": "Nenhum artigo ainda",
    "no_help_articles_to_display": "Não há artigos de ajuda",
    "no_results_for_searchterm": "Nenhum resultado para {searchTerm}",
    "no_tasks": "Nenhuma tarefa",
    "no_tasks_available_currently": "Não há tarefas disponíveis no momento.",
    "ok_the_team_typically_replies_in_day": "Ok. Normalmente, a equipe responde em um dia.",
    "ok_the_team_typically_replies_in_hours": "Ok. Normalmente, a equipe responde em algumas horas.",
    "ok_the_team_typically_replies_in_minutes": "Ok. Normalmente, a equipe responde em alguns minutos.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok. Normalmente, a equipe responde em menos de {hours} h.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok. Normalmente, a equipe responde em menos de {minutes} min.",
    "ok_the_team_typically_replies_in_week": "Ok. Geralmente, a equipe responde em uma semana.",
    "our_bot_and_team_can_help": "Nosso bot e nossa equipe podem ajudar",
    "our_bot_answers_instantly": "Nosso bot responde imediatamente",
    "powered_by_intercom": "Com tecnologia Intercom",
    "previous_bot_hands_over": "Conteúdo do bot anterior...",
    "proactive_expected_response_delay_10800": "Geralmente responde em menos de 3 horas",
    "proactive_expected_response_delay_120": "Geralmente responde em menos de 2 minutos",
    "proactive_expected_response_delay_1200": "Geralmente responde em menos de 20 minutos",
    "proactive_expected_response_delay_14400": "Geralmente responde em menos de 4 horas",
    "proactive_expected_response_delay_172800": "Geralmente responde em 2 dias",
    "proactive_expected_response_delay_180": "Geralmente responde em menos de 3 minutos",
    "proactive_expected_response_delay_1800": "Geralmente responde em menos de 30 minutos",
    "proactive_expected_response_delay_18000": "Geralmente responde em menos de 5 horas",
    "proactive_expected_response_delay_21600": "Geralmente responde em menos de 6 horas",
    "proactive_expected_response_delay_240": "Geralmente responde em menos de 4 minutos",
    "proactive_expected_response_delay_259200": "Geralmente responde em 3 dias",
    "proactive_expected_response_delay_300": "Geralmente responde em menos de 5 minutos",
    "proactive_expected_response_delay_345600": "Geralmente responde em 4 dias",
    "proactive_expected_response_delay_3600": "Geralmente responde em menos de 1 hora",
    "proactive_expected_response_delay_43200": "Geralmente responde em 12 horas",
    "proactive_expected_response_delay_432000": "Geralmente responde em 5 dias",
    "proactive_expected_response_delay_518400": "Geralmente responde em 6 dias",
    "proactive_expected_response_delay_60": "Geralmente responde em menos de 1 minuto",
    "proactive_expected_response_delay_600": "Geralmente responde em menos de 10 minutos",
    "proactive_expected_response_delay_604800": "Geralmente responde em menos de 1 semana",
    "proactive_expected_response_delay_7200": "Geralmente responde em menos de 2 horas",
    "proactive_expected_response_delay_86400": "Geralmente responde em 1 dia",
    "proactive_expected_response_delay_900": "Geralmente responde em menos de 15 minutos",
    "proactive_expected_response_delay_unknown": "Responderá assim que possível",
    "profile_location": "{time} em {location}",
    "recent_ticket": "Ticket recente",
    "recent_tickets": "Tickets recentes",
    "search_browse_empty_state_text": "Desculpe, não encontramos nenhum resultado para essa busca. Tente pesquisar usando outras palavras.",
    "search_for_help": "Qual é a sua dúvida?",
    "see_all": "Ver tudo",
    "see_all_conversations": "Ver todas as suas conversas",
    "see_previous_conversations": "Ver suas conversas anteriores",
    "send": "Enviar",
    "send_a_message": "Enviar uma mensagem",
    "send_a_message_reply_time": "O tempo normal de resposta é",
    "send_us_a_message": "Envie uma mensagem",
    "set_expectations_bot_email_header": "Você receberá respostas aqui e no seu e-mail:",
    "set_expectations_bot_office_hours_header": "Quando a equipe voltar:",
    "set_expectations_bot_sms_header": "Você receberá respostas aqui e por SMS:",
    "set_expectations_replies_to_email": "Você receberá respostas aqui e no <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Você receberá as respostas aqui e em {user_email}",
    "set_expectations_team_reply_asap": "A equipe responderá assim que possível.",
    "shared_by": "Compartilhado por",
    "show_less": "Mostrar menos",
    "single_article": "1 artigo",
    "single_collection": "1 coleção",
    "source": "Origem",
    "sources": "Fontes",
    "start_a_conversation": "Iniciar uma conversa",
    "start_conversation": "Comece uma conversa",
    "still_generating_answer": "Ainda gerando resposta...",
    "still_thinking": "Ainda pensando...",
    "still_working_on_it": "Ainda trabalhando nisso...",
    "tasks_space_title": "Tarefas",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} responderá assim que possível",
    "teammate_changes_team_assignment_of_a_conversation": "O membro da equipe alterou a atribuição da conversa...",
    "tell_us_what_you_need": "Diga-nos com o que você precisa de ajuda e nosso bot fará o seu melhor para achar a resposta",
    "the_team_can_help_if_needed": "Caso seja necessário, a equipe pode ajudar",
    "the_team_typically_replies_in_10800": "A equipe geralmente responde em menos de 3 horas",
    "the_team_typically_replies_in_120": "A equipe geralmente responde em menos de 2 minutos",
    "the_team_typically_replies_in_1200": "A equipe geralmente responde em menos de 20 minutos",
    "the_team_typically_replies_in_14400": "A equipe geralmente responde em menos de 4 horas",
    "the_team_typically_replies_in_172800": "A equipe geralmente responde em menos de 2 dias",
    "the_team_typically_replies_in_180": "A equipe geralmente responde em menos de 3 minutos",
    "the_team_typically_replies_in_1800": "A equipe geralmente responde em menos de 30 minutos",
    "the_team_typically_replies_in_18000": "A equipe geralmente responde em menos de 5 horas",
    "the_team_typically_replies_in_21600": "A equipe geralmente responde em menos de 6 horas",
    "the_team_typically_replies_in_240": "A equipe geralmente responde em menos de 4 minutos",
    "the_team_typically_replies_in_259200": "A equipe geralmente responde em menos de 3 dias",
    "the_team_typically_replies_in_300": "A equipe geralmente responde em menos de 5 minutos",
    "the_team_typically_replies_in_345600": "A equipe geralmente responde em menos de 4 dias",
    "the_team_typically_replies_in_3600": "A equipe geralmente responde em menos de 1 hora",
    "the_team_typically_replies_in_43200": "A equipe geralmente responde em menos de 12 horas",
    "the_team_typically_replies_in_432000": "A equipe geralmente responde em menos de 5 dias",
    "the_team_typically_replies_in_518400": "A equipe geralmente responde em menos de 6 dias",
    "the_team_typically_replies_in_60": "A equipe geralmente responde em menos de 1 minuto",
    "the_team_typically_replies_in_600": "A equipe geralmente responde em menos de 10 minutos",
    "the_team_typically_replies_in_604800": "A equipe geralmente responde em menos de 1 semana",
    "the_team_typically_replies_in_7200": "A equipe geralmente responde em menos de 2 horas",
    "the_team_typically_replies_in_86400": "A equipe geralmente responde em menos de 1 dia",
    "the_team_typically_replies_in_900": "A equipe geralmente responde em menos de 15 minutos",
    "the_team_typically_replies_in_day": "Normalmente a equipe responde em um dia.",
    "the_team_typically_replies_in_hours": "Normalmente a equipe responde em algumas horas.",
    "the_team_typically_replies_in_minutes": "Normalmente a equipe responde em alguns minutos.",
    "the_team_typically_replies_in_under_n_hours": "Normalmente a equipe responde em menos de {hours}h.",
    "the_team_typically_replies_in_under_n_min": "Normalmente a equipe responde em menos de {minutes}m.",
    "the_team_typically_replies_in_unknown": "A equipe responderá assim que possível",
    "the_team_typically_replies_in_week": "A equipe geralmente responde em uma semana.",
    "thinking": "Pensando...",
    "tickets_cta_text": "Alguma dúvida sobre este ticket?",
    "tickets_have_a_question": "Alguma dúvida sobre esse problema?",
    "tickets_space_title": "Tickets",
    "time_future_1h": "Em 1 hora",
    "time_future_2h": "Em 2 horas",
    "time_future_30m": "Em 30 minutos",
    "time_future_3h": "Em 3 horas",
    "time_future_day_0": "Segunda-feira",
    "time_future_day_1": "Terça-feira",
    "time_future_day_2": "Quarta-feira",
    "time_future_day_3": "Quinta-feira",
    "time_future_day_4": "Sexta-feira",
    "time_future_day_5": "Sábado",
    "time_future_day_6": "Domingo",
    "time_future_later_today": "Hoje mais tarde",
    "time_future_next_week": "Na próxima semana",
    "time_future_tomorrow": "Amanhã",
    "try_use_different_keywords": "Tente usar palavras-chave diferentes ou digite sua pergunta completa",
    "upfront_collection_form_close_out": "Você pode retornar aqui a qualquer momento para ver as respostas e enviar atualizações.",
    "upfront_collection_form_introduction": "Compartilhe mais informações para ajudar a equipe a lhe responder melhor e mais rápido.",
    "upfront_collection_form_submission_confirmation": "Obrigado por enviar mais informações. Para poupar tempo mais tarde, não hesite em adicionar mais detalhes agora.",
    "upfront_collection_form_subtitle": "Envie mais informações à equipe",
    "upfront_collection_form_subtitle_submitted": "A equipe manterá em conta essa informação",
    "upfront_collection_form_title": "Receba ajuda com mais rapidez",
    "upfront_collection_form_title_submitted": "Enviado para a equipe!",
    "view_all": "Mostrar tudo",
    "view_conversation": "Exibir conversa",
    "we_are_here_to_help": "Estamos aqui para ajudar",
    "we_run_on_intercom": "Usamos a tecnologia da Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Quando um membro da equipe altera o estado da conversa...",
    "working_on_it": "Trabalhando nisso...",
    "you_are_all_up_to_date": "Você está atualizado!",
    "your_checklists": "Suas listas de verificação",
    "your_conversations": "Suas conversas",
    "your_recent_conversations": "Conversas recentes",
    "your_recent_messages": "Mensagens recentes"
  },
  "ro": {
    "a_day": "O zi",
    "a_few_hours": "Câteva ore",
    "a_few_minutes": "Câteva minute",
    "a_week": "O săptămână",
    "active_state": "Activ",
    "ai_answer": "Răspuns AI",
    "ai_answer_information": "Răspuns AI, informații",
    "ai_bot": "Robot AI",
    "almost_there": "Aproape am terminat..",
    "answer": "Răspuns",
    "answer_bot_acknowledge_duplicate_content": "Ați văzut deja acest lucru, dar acesta este cel mai bun răspuns disponibil.",
    "answer_bot_all_done": "Aceasta a ajutat 👍",
    "answer_bot_ask_another_question": "Puneți o altă întrebare 🔁",
    "answer_bot_ask_something_else": "Întrebați altceva 🔁",
    "answer_bot_connect_to_an_agent": "Conectați-vă la un agent 👤",
    "answer_bot_did_that_help": "Acest răspuns v-a ajutat sau căutați altceva?",
    "answer_bot_followup_expectations_prefix": "Echipa vă va contacta din nou în acest sens.",
    "answer_bot_get_more_help": "Obțineți mai mult ajutor 🌐",
    "answer_bot_get_more_help_followup": "Acest link ar putea fi de ajutor:",
    "answer_bot_i_have_another_question": "Am o altă întrebare 🔁",
    "answer_bot_im_not_done_yet": "Încă nu am terminat 🔁",
    "answer_bot_keep_waiting": "Nu, încă am nevoie de ajutor 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Vă puteți reformula întrebarea sau puteți solicita asistență suplimentară",
    "answer_bot_looping_first_no_answer": "Îmi pare rău, fiind un bot nu am putut găsi un răspuns pentru acesta.",
    "answer_bot_looping_first_no_answer_followup": "Vă puteți reformula întrebarea sau puteți discuta cu echipa noastră",
    "answer_bot_looping_no_answer": "Îmi pare rău, în calitate de robot, nu am putut găsi un răspuns pentru aceasta. Iată ce puteți face",
    "answer_bot_more_answers": "Mai multe detalii 👀",
    "answer_bot_no_answer_found": "Ne pare rău, nu avem un răspuns rapid pentru aceasta. Iată câteva alte opțiuni.",
    "answer_bot_resolve_followup": "Puteți răspunde întotdeauna aici, dacă apare ceva.",
    "answer_bot_talk_to_person": "Vorbiți cu o persoană 👤",
    "answer_bot_that_answered_my_question": "Aceasta a răspuns la întrebarea mea 👍",
    "answer_bot_thats_it": "Gata 👍",
    "answer_bot_wait_for_the_team": "Așteptați echipa 💬",
    "answerbot_ask_for_more_context": "Sigur. În timp ce așteptăm, ne puteți oferi mai multe detalii despre situația sau întrebarea dvs.? Ne va ajuta să găsim un răspuns mai rapid și este posibil să găsesc mai multe informații pentru dvs.",
    "answerbot_introduce_sneaky_answer": "Mulțumesc pentru acest context suplimentar. Am găsit câteva informații noi care te-ar putea ajuta.",
    "article_search_hint_text": "Căutați articole...",
    "ask_a_question": "Puneți o întrebare",
    "ask_a_question_placeholder": "Puneți o întrebare...",
    "ask_a_question_with_ellipsis": "Adresați o întrebare...",
    "ask_another_question_with_ellipsis": "Adresați o altă întrebare...",
    "ask_for_the_team_if_needed": "Solicitați echipa dacă este necesar",
    "ask_me_a_question": "Puneți-mi o întrebare",
    "ask_our_bot_a_question": "Adresați o întrebare robotului nostru",
    "avatar": "Avatar",
    "avatars": "Avataruri",
    "bot": "Bot",
    "bot_answers_instantly": "Robotul răspunde instantaneu",
    "bot_expectation_text": "Botul nostru poate răspunde la multe întrebări frecvente",
    "bot_intro_default_part_1": "Bună! Acesta este un bot care vorbește. Sunt aici pentru a vă răspunde la întrebări, dar puteți oricând să discutați cu echipa noastră.",
    "bot_intro_default_part_2": "Așadar, de ce sunteți astăzi aici?",
    "bot_is_still_thinking": "Robotul încă se gândește...",
    "bot_is_thinking": "Robotul se gândește...",
    "browse_collections": "Răsfoiți colecțiile",
    "chat_with_us": "Discutați cu noi",
    "check_later_for_updates": "Reveniți mai târziu pentru actualizări.",
    "checklist_card_first_step": "Primul pas",
    "checklist_card_next_step": "Pasul următor",
    "checklist_completed": "Finalizat",
    "checklist_in_progress_time_remaining_plural": "Au mai rămas aproximativ {minutes} minute",
    "checklist_in_progress_time_remaining_singular": "A mai rămas aproximativ 1 minut",
    "checklist_mark_step_as_complete": "Marcați ca fiind finalizat",
    "checklist_not_started_time_remaining_plural": "Aproximativ {minutes} minute",
    "checklist_not_started_time_remaining_singular": "Aproximativ 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} pași",
    "checklist_number_of_steps_progress_plural": "{steps_completed} din {total_steps} efectuați",
    "checklist_number_of_steps_progress_singular": "1 din {total_steps} efectuat",
    "checklist_number_of_steps_singular": "1 pas",
    "checklist_sender_name": "Distribuit de {name}",
    "checklist_step_action_button": "Buton de acțiune pas cu pas",
    "checklist_step_completed": "Finalizat",
    "contact_support": "Contactați asistența",
    "contact_us": "Contactați-ne",
    "continue_the_conversation": "Continuă conversația",
    "conversation_card_office_hours_header": "Vom reveni online",
    "conversation_card_reply_time_header": "Timpul nostru obișnuit de răspuns",
    "conversation_card_reply_time_header_m5": "Timp de răspuns obișnuit",
    "conversation_card_unknown_response_time": "Vom răspunde cât mai repede",
    "conversation_part_sample_text": "Bună ziua, cu ce vă pot ajuta? Veți avea întotdeauna posibilitatea de a discuta cu echipa noastră, dacă este necesar.",
    "conversations_space_title": "Conversații",
    "create_a_ticket": "Creați un tichet",
    "currently_no_help_articles": "În prezent, nu există articole de ajutor disponibile.",
    "customer_sends_their_first_message": "Clientul trimite primul său mesaj...",
    "expected_response_delay_10800": "sub 3 ore",
    "expected_response_delay_120": "sub 2 minute",
    "expected_response_delay_1200": "sub 20 de minute",
    "expected_response_delay_14400": "sub 4 ore",
    "expected_response_delay_172800": "2 zile",
    "expected_response_delay_180": "sub 3 minute",
    "expected_response_delay_1800": "sub 30 de minute",
    "expected_response_delay_18000": "sub 5 ore",
    "expected_response_delay_21600": "sub 6 ore",
    "expected_response_delay_240": "sub 4 minute",
    "expected_response_delay_259200": "3 zile",
    "expected_response_delay_300": "sub 5 minute",
    "expected_response_delay_345600": "4 zile",
    "expected_response_delay_3600": "sub 1 oră",
    "expected_response_delay_43200": "sub 12 ore",
    "expected_response_delay_432000": "5 zile",
    "expected_response_delay_518400": "6 zile",
    "expected_response_delay_60": "sub 1 minut",
    "expected_response_delay_600": "sub 10 minute",
    "expected_response_delay_604800": "sub 1 săptămână",
    "expected_response_delay_7200": "sub 2 ore",
    "expected_response_delay_86400": "1 zi",
    "expected_response_delay_900": "sub 15 minute",
    "expected_response_delay_unknown": "De îndată ce putem",
    "extra_labels_count": "+ {labels_count} etichete",
    "for_best_results_provide_detail": "Pentru cele mai bune rezultate, oferiți cât mai multe detalii posibil",
    "generating_answer": "Generez un răspuns...",
    "generic_triage_default": "Avem nevoie doar de câteva alte informații pentru a vă pune în legătură cu persoana potrivită",
    "get_an_instant_answer": "Obțineți un răspuns instantaneu",
    "get_help": "Obțineți ajutor",
    "go_to": "Accesați {app_name}",
    "help_space_title": "Ajutor",
    "home_header_mobile_hello_firstname": "Bună, {firstname}.",
    "home_header_mobile_hello_there": "Bună",
    "home_space_title": "Acasă",
    "how_can_we_help": "Cum vă putem ajuta?",
    "how_would_you_rate_this": "Ce rating ați oferi?",
    "learn_more": "Aflați mai multe",
    "message_placeholder": "Mesaj...",
    "messages_space_title": "Mesaje",
    "messenger_bot_reply_time": "Botul nostru va răspunde instantaneu",
    "messenger_settings_introduction": "Cum vă putem ajuta?",
    "multiple_articles": "{total_articles} articole",
    "multiple_collections": "{total_collection} colecții",
    "new_conversation": "Conversație nouă",
    "news": "Noutăți",
    "news_empty_title": "Nu există încă nicio veste",
    "news_latest_subtitle": "De la echipa {team_name}",
    "news_latest_title": "Cele mai recente",
    "news_older_title": "Mai vechi",
    "news_space_title": "Noutăți",
    "newsfeed": "Flux de știri",
    "no_articles_to_display": "Încă nu există articole",
    "no_help_articles_to_display": "Nu există articole de ajutor",
    "no_results_for_searchterm": "Niciun rezultat pentru {searchTerm}",
    "no_tasks": "Nu există sarcini",
    "no_tasks_available_currently": "În prezent, nu există sarcini disponibile.",
    "ok_the_team_typically_replies_in_day": "Ok, echipa răspunde de obicei în mai puţin de 24h.",
    "ok_the_team_typically_replies_in_hours": "Ok, echipa răspunde de obicei în câteva ore.",
    "ok_the_team_typically_replies_in_minutes": "Ok, echipa răspunde de obicei în câteva minute.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, echipa răspunde de obicei în mai puțin de {hours}h.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, echipa răspunde de obicei în mai puțin de {minutes}m.",
    "ok_the_team_typically_replies_in_week": "Ok, echipa răspunde de obicei în mai puţin de o săptămână.",
    "our_bot_and_team_can_help": "Botul și echipa noastră vă pot ajuta",
    "our_bot_answers_instantly": "Robotul nostru răspunde instantaneu",
    "powered_by_intercom": "Dezvoltat de Intercom",
    "previous_bot_hands_over": "Conținut de la botul anterior...",
    "proactive_expected_response_delay_10800": "În general, răspunde în mai puțin de 3 ore",
    "proactive_expected_response_delay_120": "De obicei, răspunde în mai puțin de 2 minute",
    "proactive_expected_response_delay_1200": "În general, răspunde în mai puțin de 20 de minute",
    "proactive_expected_response_delay_14400": "În general, răspunde în mai puțin de 4 ore",
    "proactive_expected_response_delay_172800": "În general, răspunde în 2 zile",
    "proactive_expected_response_delay_180": "De obicei, răspunde în mai puțin de 3 minute",
    "proactive_expected_response_delay_1800": "În general, răspunde în mai puțin de 30 de minute",
    "proactive_expected_response_delay_18000": "În general, răspunde în mai puțin de 5 ore",
    "proactive_expected_response_delay_21600": "În general, răspunde în mai puțin de 6 ore",
    "proactive_expected_response_delay_240": "De obicei, răspunde în mai puțin de 4 minute",
    "proactive_expected_response_delay_259200": "De obicei, răspunde în 3 zile",
    "proactive_expected_response_delay_300": "În general, răspunde în mai puțin de 5 minute",
    "proactive_expected_response_delay_345600": "De obicei, răspunde în 4 zile",
    "proactive_expected_response_delay_3600": "În general, răspunde în mai puțin de 1 oră",
    "proactive_expected_response_delay_43200": "De obicei, răspunde în 12 ore",
    "proactive_expected_response_delay_432000": "De obicei, răspunde în 5 zile",
    "proactive_expected_response_delay_518400": "De obicei, răspunde în 6 zile",
    "proactive_expected_response_delay_60": "De obicei, răspunde în mai puțin de 1 minut",
    "proactive_expected_response_delay_600": "În general, răspunde în mai puțin de 10 minute",
    "proactive_expected_response_delay_604800": "În general, răspunde în mai puțin de 1 săptămână",
    "proactive_expected_response_delay_7200": "În general, răspunde în mai puțin de 2 ore",
    "proactive_expected_response_delay_86400": "În general, răspunde în 1 zi",
    "proactive_expected_response_delay_900": "De obicei, răspunde în mai puțin de 15 minute",
    "proactive_expected_response_delay_unknown": "Va răspunde imediat ce va putea",
    "profile_location": "{time} în {location}",
    "recent_ticket": "Tichet recent",
    "recent_tickets": "Tichete recente",
    "search_browse_empty_state_text": "Ne pare rău, nu am putut găsi rezultate pentru aceasta. Încercați să reformulați căutarea.",
    "search_for_help": "Căutați ajutor",
    "see_all": "Vezi toate",
    "see_all_conversations": "Vezi toate conversațiile tale",
    "see_previous_conversations": "Vezi conversațiile tale anterioare",
    "send": "Trimite",
    "send_a_message": "Trimite un mesaj…",
    "send_a_message_reply_time": "Timpul obișnuit de răspuns este",
    "send_us_a_message": "Trimiteți-ne un mesaj",
    "set_expectations_bot_email_header": "Veți primi răspunsuri aici și prin e-mail:",
    "set_expectations_bot_office_hours_header": "Când va reveni echipa:",
    "set_expectations_bot_sms_header": "Veți primi răspunsuri aici și prin SMS:",
    "set_expectations_replies_to_email": "Veți primi răspunsuri aici și la <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Veți primi răspunsuri aici și la {user_email}",
    "set_expectations_team_reply_asap": "Echipa va răspunde imediat ce va putea.",
    "shared_by": "Distribuit de",
    "show_less": "Arată mai puțin",
    "single_article": "1 articol",
    "single_collection": "1 colecție",
    "source": "Sursă",
    "sources": "Surse",
    "start_a_conversation": "Începe o conversație",
    "start_conversation": "Începe o conversaţie",
    "still_generating_answer": "Încă generez un răspuns...",
    "still_thinking": "Încă mă gândesc...",
    "still_working_on_it": "Încă pregătesc un răspuns...",
    "tasks_space_title": "Sarcini",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} va răspunde imediat ce va putea",
    "teammate_changes_team_assignment_of_a_conversation": "Colegul de echipă a schimbat atribuirea conversației...",
    "tell_us_what_you_need": "Spuneți-ne pentru ce aveți nevoie de ajutor și bot-ul nostru va face tot posibilul pentru a răspunde",
    "the_team_can_help_if_needed": "Echipa poate ajuta dacă este necesar",
    "the_team_typically_replies_in_10800": "Echipa răspunde de obicei în mai puțin de 3 ore",
    "the_team_typically_replies_in_120": "Echipa răspunde de obicei în mai puțin de 2 minute",
    "the_team_typically_replies_in_1200": "Echipa răspunde de obicei în mai puțin de 20 de minute",
    "the_team_typically_replies_in_14400": "Echipa răspunde de obicei în mai puțin de 4 ore",
    "the_team_typically_replies_in_172800": "Echipa răspunde de obicei în 2 zile",
    "the_team_typically_replies_in_180": "Echipa răspunde de obicei în mai puțin de 3 minute",
    "the_team_typically_replies_in_1800": "Echipa răspunde de obicei în mai puțin de 30 de minute",
    "the_team_typically_replies_in_18000": "Echipa răspunde de obicei în mai puțin de 5 ore",
    "the_team_typically_replies_in_21600": "Echipa răspunde de obicei în mai puțin de 6 ore",
    "the_team_typically_replies_in_240": "Echipa răspunde de obicei în mai puțin de 4 minute",
    "the_team_typically_replies_in_259200": "Echipa răspunde de obicei în 3 zile",
    "the_team_typically_replies_in_300": "Echipa răspunde de obicei în mai puțin de 5 minute",
    "the_team_typically_replies_in_345600": "Echipa răspunde de obicei în 4 zile",
    "the_team_typically_replies_in_3600": "Echipa răspunde de obicei în mai puțin de 1 oră",
    "the_team_typically_replies_in_43200": "Echipa răspunde de obicei în mai puțin de 12 ore",
    "the_team_typically_replies_in_432000": "Echipa răspunde de obicei în 5 zile",
    "the_team_typically_replies_in_518400": "Echipa răspunde de obicei în 6 zile",
    "the_team_typically_replies_in_60": "Echipa răspunde de obicei în mai puțin de 1 minut",
    "the_team_typically_replies_in_600": "Echipa răspunde de obicei în mai puțin de 10 minute",
    "the_team_typically_replies_in_604800": "Echipa răspunde de obicei în mai puțin de 1 săptămână",
    "the_team_typically_replies_in_7200": "Echipa răspunde de obicei în mai puțin de 2 ore",
    "the_team_typically_replies_in_86400": "Echipa răspunde de obicei în decurs de o zi",
    "the_team_typically_replies_in_900": "Echipa răspunde de obicei în mai puțin de 15 minute",
    "the_team_typically_replies_in_day": "Echipa răspunde de obicei într-o zi.",
    "the_team_typically_replies_in_hours": "Echipa răspunde de obicei în câteva ore.",
    "the_team_typically_replies_in_minutes": "Echipa răspunde de obicei în câteva minute.",
    "the_team_typically_replies_in_under_n_hours": "Echipa răspunde de obicei în mai puțin de {hours}h.",
    "the_team_typically_replies_in_under_n_min": "Echipa răspunde de obicei în mai puțin de {minutes}m.",
    "the_team_typically_replies_in_unknown": "Echipa va răspunde imediat ce va putea",
    "the_team_typically_replies_in_week": "Echipa răspunde de obicei într-o săptămână.",
    "thinking": "Mă gândesc...",
    "tickets_cta_text": "Aveți o întrebare despre acest tichet?",
    "tickets_have_a_question": "Aveți o întrebare cu privire la acest subiect?",
    "tickets_space_title": "Tichete",
    "time_future_1h": "1 oră",
    "time_future_2h": "2 ore",
    "time_future_30m": "30 de minute",
    "time_future_3h": "3 ore",
    "time_future_day_0": "Luni",
    "time_future_day_1": "Marți",
    "time_future_day_2": "Miercuri",
    "time_future_day_3": "Joi",
    "time_future_day_4": "Vineri",
    "time_future_day_5": "Sâmbătă",
    "time_future_day_6": "Duminică",
    "time_future_later_today": "Mai târziu astăzi",
    "time_future_next_week": "Săptămâna viitoare",
    "time_future_tomorrow": "Mâine",
    "try_use_different_keywords": "Încercați alte cuvinte cheie sau introduceți întrebarea completă",
    "upfront_collection_form_close_out": "Puteți reveni aici oricând pentru a vedea răspunsurile și a trimite actualizări.",
    "upfront_collection_form_introduction": "Împărtășiți un context pentru a ajuta echipa să răspundă mai bine și mai repede.",
    "upfront_collection_form_submission_confirmation": "Vă mulțumim pentru trimiterea contextului. Pentru a economisi timp mai târziu, nu ezitați să adăugați mai multe detalii acum.",
    "upfront_collection_form_subtitle": "Trimiteți echipei un context",
    "upfront_collection_form_subtitle_submitted": "Vor ține cont de acest context",
    "upfront_collection_form_title": "Obțineți ajutor mai repede",
    "upfront_collection_form_title_submitted": "Trimis echipei!",
    "view_all": "Vizualizare toate",
    "view_conversation": "Vedeți conversația",
    "we_are_here_to_help": "Suntem aici pentru a vă ajuta",
    "we_run_on_intercom": "Rulăm pe Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Când un coleg de echipă schimbă starea unei conversații...",
    "working_on_it": "Pregătesc un răspuns...",
    "you_are_all_up_to_date": "Sunteți la zi!",
    "your_checklists": "Listele dumneavoastră de verificare",
    "your_conversations": "Conversațiile tale",
    "your_recent_conversations": "Conversații recente",
    "your_recent_messages": "Mesaje recente"
  },
  "ru": {
    "a_day": "один день",
    "a_few_hours": "несколько часов",
    "a_few_minutes": "несколько минут",
    "a_week": "неделя",
    "active_state": "Активен",
    "ai_answer": "Ответ от ИИ",
    "ai_answer_information": "Об ответах от ИИ",
    "ai_bot": "ИИ-бот",
    "almost_there": "Почти готово…",
    "answer": "Ответ",
    "answer_bot_acknowledge_duplicate_content": "Вы уже видели этот ответ, но это лучшее, что я могу предложить.",
    "answer_bot_all_done": "Полезный ответ 👍",
    "answer_bot_ask_another_question": "Задать другой вопрос 🔁",
    "answer_bot_ask_something_else": "Задать другой вопрос 🔁",
    "answer_bot_connect_to_an_agent": "Поговорить с оператором 👤",
    "answer_bot_did_that_help": "Вам помог этот ответ? Или вы искали другую информацию?",
    "answer_bot_followup_expectations_prefix": "Наш специалист с удовольствием вам поможет.",
    "answer_bot_get_more_help": "Нужна дополнительная помощь 🌐",
    "answer_bot_get_more_help_followup": "Эта ссылка может помочь:",
    "answer_bot_i_have_another_question": "У меня еще вопрос 🔁",
    "answer_bot_im_not_done_yet": "У меня остались вопросы 🔁",
    "answer_bot_keep_waiting": "Нет, нужна помощь 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Вы можете перефразировать вопрос или обратиться за дополнительной помощью",
    "answer_bot_looping_first_no_answer": "К сожалению, я, будучи ботом, не нашел для вас ответ.",
    "answer_bot_looping_first_no_answer_followup": "Вы можете перефразировать вопрос или пообщаться с оператором",
    "answer_bot_looping_no_answer": "К сожалению, я, будучи ботом, не нашел для вас ответ. Вот что вы можете сделать",
    "answer_bot_more_answers": "Хочу увидеть другие варианты 👀",
    "answer_bot_no_answer_found": "К сожалению, у нас нет быстрого ответа на этот вопрос. Выберите другой вариант.",
    "answer_bot_resolve_followup": "Если вам потребуется помощь, просто напишите ответ тут.",
    "answer_bot_talk_to_person": "Поговорить с человеком 👤",
    "answer_bot_that_answered_my_question": "Ответ пригодился 👍",
    "answer_bot_thats_it": "У меня всё 👍",
    "answer_bot_wait_for_the_team": "Подожду специалиста 💬",
    "answerbot_ask_for_more_context": "Конечно! Не могли бы вы подробнее описать свою проблему или вопрос, пока мы ждем оператора? Это позволит нам быстрее найти для вас ответ, а я также постараюсь помочь информацией.",
    "answerbot_introduce_sneaky_answer": "Спасибо за дополнительный контекст! Я нашел информацию, которая, возможно, вам пригодится.",
    "article_search_hint_text": "Поиск по статьям…",
    "ask_a_question": "Задать вопрос",
    "ask_a_question_placeholder": "Задать вопрос…",
    "ask_a_question_with_ellipsis": "Задать вопрос…",
    "ask_another_question_with_ellipsis": "Задать еще вопрос…",
    "ask_for_the_team_if_needed": "При необходимости позовите специалиста",
    "ask_me_a_question": "Задайте мне вопрос",
    "ask_our_bot_a_question": "Задайте вопрос нашему боту",
    "avatar": "Аватар",
    "avatars": "Аватары",
    "bot": "Бот",
    "bot_answers_instantly": "Бот отвечает моментально",
    "bot_expectation_text": "Наш бот может ответить на многие популярные вопросы",
    "bot_intro_default_part_1": "Здравствуйте! С вами говорит бот. Я постараюсь ответить на ваши вопросы, однако вы всегда можете связаться с нашими специалистами.",
    "bot_intro_default_part_2": "Итак, чем я могу вам помочь?",
    "bot_is_still_thinking": "Бот всё еще думает…",
    "bot_is_thinking": "Бот думает…",
    "browse_collections": "Просмотр коллекций",
    "chat_with_us": "Запустить чат",
    "check_later_for_updates": "Попробуйте заглянуть сюда позже.",
    "checklist_card_first_step": "Первый шаг",
    "checklist_card_next_step": "Следующий шаг",
    "checklist_completed": "Готово",
    "checklist_in_progress_time_remaining_plural": "Осталось около {minutes} мин.",
    "checklist_in_progress_time_remaining_singular": "Осталось около 1 минуты",
    "checklist_mark_step_as_complete": "Пометить шаг выполненным",
    "checklist_not_started_time_remaining_plural": "Около {minutes} мин.",
    "checklist_not_started_time_remaining_singular": "Около 1 минуты",
    "checklist_number_of_steps_plural": "Всего шагов: {number_of_steps}",
    "checklist_number_of_steps_progress_plural": "Выполнено: {steps_completed} из {total_steps}",
    "checklist_number_of_steps_progress_singular": "Выполнено: 1 из {total_steps}",
    "checklist_number_of_steps_singular": "1 шаг",
    "checklist_sender_name": "От кого: {name}",
    "checklist_step_action_button": "Кнопка действия",
    "checklist_step_completed": "Готово",
    "contact_support": "Служба поддержки",
    "contact_us": "Связаться с нами",
    "continue_the_conversation": "Продолжить беседу",
    "conversation_card_office_hours_header": "Когда мы вернемся на связь",
    "conversation_card_reply_time_header": "Среднее время ответа",
    "conversation_card_reply_time_header_m5": "Среднее время ответа",
    "conversation_card_unknown_response_time": "Мы ответим, как только сможем",
    "conversation_part_sample_text": "Здравствуйте, как я могу помочь? При необходимости вы всегда можете связаться с нашими специалистами.",
    "conversations_space_title": "Разговоры",
    "create_a_ticket": "Создание заявки",
    "currently_no_help_articles": "Справочных статей пока нет.",
    "customer_sends_their_first_message": "Клиент отправляет своё первое сообщение…",
    "expected_response_delay_10800": "менее 3 часов",
    "expected_response_delay_120": "менее 2 минут",
    "expected_response_delay_1200": "менее 20 минут",
    "expected_response_delay_14400": "менее 4 часов",
    "expected_response_delay_172800": "2 дня",
    "expected_response_delay_180": "менее 3 минут",
    "expected_response_delay_1800": "менее 30 минут",
    "expected_response_delay_18000": "менее 5 часов",
    "expected_response_delay_21600": "менее 6 часов",
    "expected_response_delay_240": "менее 4 минут",
    "expected_response_delay_259200": "3 дня",
    "expected_response_delay_300": "менее 5 минут",
    "expected_response_delay_345600": "4 дня",
    "expected_response_delay_3600": "менее 1 часа",
    "expected_response_delay_43200": "не более 12 часов",
    "expected_response_delay_432000": "5 дней",
    "expected_response_delay_518400": "6 дней",
    "expected_response_delay_60": "менее 1 минуты",
    "expected_response_delay_600": "менее 10 минут",
    "expected_response_delay_604800": "менее 1 недели",
    "expected_response_delay_7200": "менее 2 часов",
    "expected_response_delay_86400": "1 день",
    "expected_response_delay_900": "в течение 15 минут",
    "expected_response_delay_unknown": "при первой возможности",
    "extra_labels_count": "+ еще меток: {labels_count}",
    "for_best_results_provide_detail": "Чтобы получить оптимальный результат, укажите как можно больше информации.",
    "generating_answer": "Составляю ответ…",
    "generic_triage_default": "Чтобы связать вас с подходящим специалистом, нам потребуется дополнительная информация",
    "get_an_instant_answer": "Моментальный ответ",
    "get_help": "Поиск по справке",
    "go_to": "Перейти в {app_name}",
    "help_space_title": "Помощь",
    "home_header_mobile_hello_firstname": "Здравствуйте, {firstname}!",
    "home_header_mobile_hello_there": "Приветствуем!",
    "home_space_title": "Главная",
    "how_can_we_help": "Чем мы можем помочь?",
    "how_would_you_rate_this": "Ваша оценка",
    "learn_more": "Подробнее",
    "message_placeholder": "Ваше сообщение...",
    "messages_space_title": "Чат",
    "messenger_bot_reply_time": "Наш бот ответит мгновенно",
    "messenger_settings_introduction": "Чем мы можем помочь?",
    "multiple_articles": "{total_articles} статьи",
    "multiple_collections": "{total_collection} коллекц.",
    "new_conversation": "Новый разговор",
    "news": "Новости",
    "news_empty_title": "Пока без новостей",
    "news_latest_subtitle": "От команды {team_name}",
    "news_latest_title": "Последние новости",
    "news_older_title": "Предыдущие статьи",
    "news_space_title": "Новости",
    "newsfeed": "Лента новостей",
    "no_articles_to_display": "Статей пока нет",
    "no_help_articles_to_display": "Справочных статей нет",
    "no_results_for_searchterm": "По запросу «{searchTerm}» ничего не найдено",
    "no_tasks": "Нет задач",
    "no_tasks_available_currently": "В настоящее время нет доступных задач.",
    "ok_the_team_typically_replies_in_day": "Спасибо. Как правило, команда отвечает в течение дня.",
    "ok_the_team_typically_replies_in_hours": "Спасибо. Как правило, команда отвечает в течение нескольких часов.",
    "ok_the_team_typically_replies_in_minutes": "Спасибо. Как правило, команда отвечает в течение нескольких минут.",
    "ok_the_team_typically_replies_in_under_n_hours": "Спасибо. Как правило, команда отвечает в течение {hours} часов.",
    "ok_the_team_typically_replies_in_under_n_min": "Спасибо. Как правило, команда отвечает в течение {minutes} минут.",
    "ok_the_team_typically_replies_in_week": "Спасибо! Как правило, команда отвечает в течение недели.",
    "our_bot_and_team_can_help": "Наш бот и команда могут помочь",
    "our_bot_answers_instantly": "Наш бот отвечает моментально",
    "powered_by_intercom": "Разработано компанией Intercom",
    "previous_bot_hands_over": "Содержание предыдущего бота…",
    "proactive_expected_response_delay_10800": "Как правило, отвечает в течение 3 ч.",
    "proactive_expected_response_delay_120": "Как правило, отвечает в течение 2 минут.",
    "proactive_expected_response_delay_1200": "Как правило, отвечает в течение 20 мин.",
    "proactive_expected_response_delay_14400": "Как правило, отвечает в течение 4 ч.",
    "proactive_expected_response_delay_172800": "Как правило, отвечает в течение 2 д.",
    "proactive_expected_response_delay_180": "Как правило, отвечает в течение 3 минут.",
    "proactive_expected_response_delay_1800": "Как правило, отвечает в течение 30 мин.",
    "proactive_expected_response_delay_18000": "Как правило, отвечает в течение 5 ч.",
    "proactive_expected_response_delay_21600": "Как правило, отвечает в течение 6 ч.",
    "proactive_expected_response_delay_240": "Как правило, отвечает в течение 4 минут.",
    "proactive_expected_response_delay_259200": "Обычно отвечает в течение 3 дней",
    "proactive_expected_response_delay_300": "Как правило, отвечает в течение 5 мин.",
    "proactive_expected_response_delay_345600": "Обычно отвечает в течение 4 дней",
    "proactive_expected_response_delay_3600": "Как правило, отвечает в течение 1 ч.",
    "proactive_expected_response_delay_43200": "Обычно отвечает в течение 12 часов",
    "proactive_expected_response_delay_432000": "Обычно отвечает в течение 5 дней",
    "proactive_expected_response_delay_518400": "Обычно отвечает в течение 6 дней",
    "proactive_expected_response_delay_60": "Как правило, отвечает в течение 1 минуты.",
    "proactive_expected_response_delay_600": "Как правило, отвечает в течение 10 мин.",
    "proactive_expected_response_delay_604800": "Как правило, отвечает в течение 1 нед.",
    "proactive_expected_response_delay_7200": "Как правило, отвечает в течение 2 ч.",
    "proactive_expected_response_delay_86400": "Как правило, отвечает в течение 1 д.",
    "proactive_expected_response_delay_900": "Как правило, отвечает в течение 15 минут",
    "proactive_expected_response_delay_unknown": "Ответит, как только сможет",
    "profile_location": "{time} в {location}",
    "recent_ticket": "Недавняя заявка",
    "recent_tickets": "Последние заявки",
    "search_browse_empty_state_text": "К сожалению, мы ничего не нашли по этому запросу. Попробуйте сформулировать его по-другому.",
    "search_for_help": "Поиск по статьям",
    "see_all": "Просмотреть все",
    "see_all_conversations": "Посмотреть все беседы",
    "see_previous_conversations": "Посмотреть прошлые разговоры",
    "send": "Отправить",
    "send_a_message": "Отправить сообщение…",
    "send_a_message_reply_time": "Среднее время ответа:",
    "send_us_a_message": "Отправить сообщение",
    "set_expectations_bot_email_header": "Мы ответим здесь и по электронной почте:",
    "set_expectations_bot_office_hours_header": "Наши специалисты будут на связи:",
    "set_expectations_bot_sms_header": "Мы ответим здесь и по СМС:",
    "set_expectations_replies_to_email": "Мы ответим вам здесь и на <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Мы ответим вам здесь и по адресу {user_email}.",
    "set_expectations_team_reply_asap": "Команда ответит, как только сможет.",
    "shared_by": "Автор:",
    "show_less": "Свернуть",
    "single_article": "1 статья",
    "single_collection": "1 коллекция",
    "source": "Источник",
    "sources": "Источники",
    "start_a_conversation": "Напишите нам",
    "start_conversation": "Начать разговор",
    "still_generating_answer": "Всё еще составляю ответ…",
    "still_thinking": "Всё еще думаю…",
    "still_working_on_it": "Всё еще работаю над ответом…",
    "tasks_space_title": "Задачи",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} ответит, как только сможет",
    "teammate_changes_team_assignment_of_a_conversation": "Участник команды изменил(а) назначение разговора…",
    "tell_us_what_you_need": "Расскажите, чем мы можем помочь, и наш бот постарается найти ответ",
    "the_team_can_help_if_needed": "При необходимости вам помогут наши специалисты",
    "the_team_typically_replies_in_10800": "Как правило, команда отвечает в течение 3 часов.",
    "the_team_typically_replies_in_120": "Как правило, команда отвечает в течение 2 минут.",
    "the_team_typically_replies_in_1200": "Как правило, команда отвечает в течение 20 минут.",
    "the_team_typically_replies_in_14400": "Как правило, команда отвечает в течение 4 часов.",
    "the_team_typically_replies_in_172800": "Как правило, команда отвечает в течение 2 дней.",
    "the_team_typically_replies_in_180": "Как правило, команда отвечает в течение 3 минут.",
    "the_team_typically_replies_in_1800": "Как правило, команда отвечает в течение 30 минут.",
    "the_team_typically_replies_in_18000": "Как правило, команда отвечает в течение 5 часов.",
    "the_team_typically_replies_in_21600": "Как правило, команда отвечает в течение 6 часов.",
    "the_team_typically_replies_in_240": "Как правило, команда отвечает в течение 4 минут.",
    "the_team_typically_replies_in_259200": "Команда, как правило, отвечает в течение 3 дней.",
    "the_team_typically_replies_in_300": "Как правило, команда отвечает в течение 5 минут.",
    "the_team_typically_replies_in_345600": "Команда, как правило, отвечает в течение 4 дней.",
    "the_team_typically_replies_in_3600": "Как правило, команда отвечает в течение 1 часа.",
    "the_team_typically_replies_in_43200": "Команда, как правило, отвечает в течение 12 часов.",
    "the_team_typically_replies_in_432000": "Команда, как правило, отвечает в течение 5 дней.",
    "the_team_typically_replies_in_518400": "Команда, как правило, отвечает в течение 6 дней.",
    "the_team_typically_replies_in_60": "Как правило, команда отвечает в течение 1 минуты.",
    "the_team_typically_replies_in_600": "Как правило, команда отвечает в течение 10 минут.",
    "the_team_typically_replies_in_604800": "Как правило, команда отвечает в течение 1 недели.",
    "the_team_typically_replies_in_7200": "Как правило, команда отвечает в течение 2 часов.",
    "the_team_typically_replies_in_86400": "Как правило, команда отвечает в течение 1 дня.",
    "the_team_typically_replies_in_900": "Команда, как правило, отвечает в течение 15 минут",
    "the_team_typically_replies_in_day": "Как правило, команда отвечает в течение дня.",
    "the_team_typically_replies_in_hours": "Как правило, команда отвечает в течение нескольких часов.",
    "the_team_typically_replies_in_minutes": "Как правило, команда отвечает в течение нескольких минут.",
    "the_team_typically_replies_in_under_n_hours": "Как правило, команда отвечает в течение {hours} часов.",
    "the_team_typically_replies_in_under_n_min": "Как правило, команда отвечает в течение {minutes} минут.",
    "the_team_typically_replies_in_unknown": "Команда ответит, как только сможет",
    "the_team_typically_replies_in_week": "Как правило, команда отвечает в течение недели.",
    "thinking": "Надо подумать…",
    "tickets_cta_text": "Есть вопрос по этой заявке?",
    "tickets_have_a_question": "Есть вопрос по этой теме?",
    "tickets_space_title": "Запрос",
    "time_future_1h": "через 1 час",
    "time_future_2h": "через 2 часа",
    "time_future_30m": "через 30 минут",
    "time_future_3h": "через 3 часа",
    "time_future_day_0": "понедельник",
    "time_future_day_1": "вторник",
    "time_future_day_2": "среда",
    "time_future_day_3": "четверг",
    "time_future_day_4": "пятница",
    "time_future_day_5": "суббота",
    "time_future_day_6": "воскресенье",
    "time_future_later_today": "позднее сегодня",
    "time_future_next_week": "на следующей неделе",
    "time_future_tomorrow": "завтра",
    "try_use_different_keywords": "Попробуйте изменить ключевые слова или введите вопрос полностью",
    "upfront_collection_form_close_out": "Вы всегда можете вернуться сюда, чтобы прочесть ответ или отправить дополнительные сведения.",
    "upfront_collection_form_introduction": "Расскажите о проблеме подробнее, чтобы наши специалисты могли быстрее и качественнее вам помочь.",
    "upfront_collection_form_submission_confirmation": "Спасибо за информацию! Чтобы сэкономить время в будущем, вы также можете предоставить нам другие подробности.",
    "upfront_collection_form_subtitle": "Отправьте нам дополнительную информацию",
    "upfront_collection_form_subtitle_submitted": "Наши специалисты обязательно примут ее во внимание",
    "upfront_collection_form_title": "Помощь в ускоренном порядке",
    "upfront_collection_form_title_submitted": "Информация отправлена!",
    "view_all": "Смотреть все",
    "view_conversation": "Просмотреть беседу",
    "we_are_here_to_help": "Мы всегда готовы помочь",
    "we_run_on_intercom": "Мы используем Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Если участник команды меняет состояние разговора…",
    "working_on_it": "Работаю над ответом…",
    "you_are_all_up_to_date": "Вы ознакомились со всеми новостями!",
    "your_checklists": "Ваши контрольные списки",
    "your_conversations": "Ваши разговоры",
    "your_recent_conversations": "Недавние беседы",
    "your_recent_messages": "Недавние сообщения"
  },
  "sl": {
    "a_day": "1 dan",
    "a_few_hours": "Nekaj ur",
    "a_few_minutes": "Nekaj minut",
    "a_week": "Teden",
    "active_state": "Aktiven",
    "ai_answer": "Odgovor UI",
    "ai_answer_information": "Odgovor UI, informacije",
    "ai_bot": "Bot z UI",
    "almost_there": "Skoraj pri koncu …",
    "answer": "Odgovor",
    "answer_bot_acknowledge_duplicate_content": "To ste že videli, vendar je to najboljši možen odgovor.",
    "answer_bot_all_done": "To je pomagalo 👍",
    "answer_bot_ask_another_question": "Zastavi drugo vprašanje 🔁",
    "answer_bot_ask_something_else": "Vprašajte nekaj drugega 🔁",
    "answer_bot_connect_to_an_agent": "Poveži z agentom 👤",
    "answer_bot_did_that_help": "Vam je ta odgovor pomagal ali iščete kaj drugega?",
    "answer_bot_followup_expectations_prefix": "Ekipa bo v povezavi s tem stopila v stik z vami.",
    "answer_bot_get_more_help": "Več pomoči 🌐",
    "answer_bot_get_more_help_followup": "V pomoč vam je lahko ta povezava:",
    "answer_bot_i_have_another_question": "Imam drugo vprašanje 🔁",
    "answer_bot_im_not_done_yet": "Nisem še končal/a 🔁",
    "answer_bot_keep_waiting": "Ne, še vedno potrebujem pomoč 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Vprašanje lahko preoblikujete ali pridobite dodatno pomoč",
    "answer_bot_looping_first_no_answer": "Oprostite, kot robot nisem uspel najti odgovora na to.",
    "answer_bot_looping_first_no_answer_followup": "Vprašanje lahko preoblikujete ali pa se pogovorite z našo skupino",
    "answer_bot_looping_no_answer": "Oprostite, kot robot nisem uspel najti odgovora na to. Kaj lahko storite?",
    "answer_bot_more_answers": "Prikaži več 👀",
    "answer_bot_no_answer_found": "Oprostite, nimamo hitrega odgovora na to. Druge možnosti.",
    "answer_bot_resolve_followup": "Če se še kaj pojavi, lahko vedno odgovorite tukaj.",
    "answer_bot_talk_to_person": "Pogovorite se z osebo 👤",
    "answer_bot_that_answered_my_question": "To je odgovor na moje vprašanje 👍",
    "answer_bot_thats_it": "To je to 👍",
    "answer_bot_wait_for_the_team": "Počakajte, da se vrne nekdo iz ekipe 💬",
    "answerbot_ask_for_more_context": "Seveda. Medtem ko čakamo, nam lahko zaupate več podrobnosti o svojih okoliščinah ali vprašanju? Tako bomo hitreje našli odgovor, jaz pa bom morda našel več informacij za vas.",
    "answerbot_introduce_sneaky_answer": "Hvala za dodaten kontekst. Našel sem nekaj novih informacij, ki bi vam lahko pomagale.",
    "article_search_hint_text": "Poišči članke ...",
    "ask_a_question": "Zastavite vprašanje",
    "ask_a_question_placeholder": "Zastavite vprašanje ...",
    "ask_a_question_with_ellipsis": "Zastavite vprašanje …",
    "ask_another_question_with_ellipsis": "Zastavite še eno vprašanje …",
    "ask_for_the_team_if_needed": "Po potrebi se lahko obrnete na ekipo",
    "ask_me_a_question": "Zastavite mi vprašanje",
    "ask_our_bot_a_question": "Zastavite vprašanje našemu botu",
    "avatar": "Avatar",
    "avatars": "Avatarji",
    "bot": "Bot",
    "bot_answers_instantly": "Bot odgovarja takoj",
    "bot_expectation_text": "Naš bot lahko odgovori na številna pogosta vprašanja",
    "bot_intro_default_part_1": "Zdravo! Govori robot. Tukaj sem, da odgovorim na vaša vprašanja, vendar boste vedno imeli možnost, da se pogovorite z našo ekipo.",
    "bot_intro_default_part_2": "Torej, kako vam lahko danes pomagam?",
    "bot_is_still_thinking": "Bot še vedno razmišlja …",
    "bot_is_thinking": "Bot razmišlja …",
    "browse_collections": "Brskanje po zbirkah",
    "chat_with_us": "Klepet z nami",
    "check_later_for_updates": "Pozneje preverite posodobitve.",
    "checklist_card_first_step": "Prvi korak",
    "checklist_card_next_step": "Naslednji korak",
    "checklist_completed": "Zaključeno",
    "checklist_in_progress_time_remaining_plural": "Ostane še približno {minutes} minut",
    "checklist_in_progress_time_remaining_singular": "Približno 1 minuta do konca",
    "checklist_mark_step_as_complete": "Označi kot zaključeno",
    "checklist_not_started_time_remaining_plural": "Približno {minutes} minut",
    "checklist_not_started_time_remaining_singular": "Približno 1 minuta",
    "checklist_number_of_steps_plural": "{number_of_steps} korakov",
    "checklist_number_of_steps_progress_plural": "{steps_completed} od {total_steps} opravljenih",
    "checklist_number_of_steps_progress_singular": "Odpravljen 1 od {total_steps}",
    "checklist_number_of_steps_singular": "1 korak",
    "checklist_sender_name": "V skupni rabi s/z {name}",
    "checklist_step_action_button": "Gumb za dejanje koraka",
    "checklist_step_completed": "Zaključeno",
    "contact_support": "Stik s podporo",
    "contact_us": "Stik z nami",
    "continue_the_conversation": "Nadaljuj pogovor",
    "conversation_card_office_hours_header": "Ko bomo znova na spletu",
    "conversation_card_reply_time_header": "Naš običajni čas za odgovor",
    "conversation_card_reply_time_header_m5": "Običajni čas odgovora",
    "conversation_card_unknown_response_time": "Odgovorili bomo, takoj ko je mogoče",
    "conversation_part_sample_text": "Pozdravljeni, kako lahko pomagam? Vedno boste imeli možnost, da se po potrebi pogovorite z našo ekipo.",
    "conversations_space_title": "Pogovori",
    "create_a_ticket": "Ustvari zahtevek",
    "currently_no_help_articles": "Trenutno ni na voljo noben članek za pomoč.",
    "customer_sends_their_first_message": "Stranka pošilja svoje prvo sporočilo ...",
    "expected_response_delay_10800": "manj kot 3 ure",
    "expected_response_delay_120": "manj kot 2 minuti",
    "expected_response_delay_1200": "manj kot 20 minut",
    "expected_response_delay_14400": "manj kot 4 ure",
    "expected_response_delay_172800": "2 dni",
    "expected_response_delay_180": "manj kot 3 minute",
    "expected_response_delay_1800": "manj kot 30 minut",
    "expected_response_delay_18000": "manj kot 5 ur",
    "expected_response_delay_21600": "manj kot 6 ur",
    "expected_response_delay_240": "manj kot 4 minute",
    "expected_response_delay_259200": "3 dni",
    "expected_response_delay_300": "manj kot 5 minut",
    "expected_response_delay_345600": "4 dni",
    "expected_response_delay_3600": "manj kot 1 uro",
    "expected_response_delay_43200": "manj kot 12 ur",
    "expected_response_delay_432000": "5 dni",
    "expected_response_delay_518400": "6 dni",
    "expected_response_delay_60": "manj kot 1 minuta",
    "expected_response_delay_600": "manj kot 10 minut",
    "expected_response_delay_604800": "manj kot 1 teden",
    "expected_response_delay_7200": "manj kot 2 uri",
    "expected_response_delay_86400": "1 dan",
    "expected_response_delay_900": "manj kot 15 minut",
    "expected_response_delay_unknown": "Takoj, ko bo mogoče",
    "extra_labels_count": "+ {labels_count} oznak",
    "for_best_results_provide_detail": "Za najboljše rezultate navedite čim več podrobnosti",
    "generating_answer": "Ustvarjam odgovor …",
    "generic_triage_default": "Potrebujemo le nekaj dodatnih podatkov, da vas povežemo s pravo osebo",
    "get_an_instant_answer": "Takoj dobite odgovor",
    "get_help": "Poiščite pomoč",
    "go_to": "Odprite {app_name}",
    "help_space_title": "Pomoč",
    "home_header_mobile_hello_firstname": "Pozdravljeni, {firstname}.",
    "home_header_mobile_hello_there": "Pozdravljeni",
    "home_space_title": "Domov",
    "how_can_we_help": "Kako vam lahko pomagamo?",
    "how_would_you_rate_this": "Kakšna je vaša ocena?",
    "learn_more": "Več o tem",
    "message_placeholder": "Sporočilo …",
    "messages_space_title": "Sporočila",
    "messenger_bot_reply_time": "Naš bot vam bo takoj odgovoril.",
    "messenger_settings_introduction": "Kako vam lahko pomagamo?",
    "multiple_articles": "{total_articles} člankov",
    "multiple_collections": "{total_collection} zbirke",
    "new_conversation": "Nov pogovor",
    "news": "Novice",
    "news_empty_title": "Še ni novic",
    "news_latest_subtitle": "Od ekipe {team_name}",
    "news_latest_title": "Zadnje",
    "news_older_title": "Starejši",
    "news_space_title": "Novice",
    "newsfeed": "Novice",
    "no_articles_to_display": "Še ni člankov",
    "no_help_articles_to_display": "Ni člankov za pomoč",
    "no_results_for_searchterm": "Ni rezultatov za {searchTerm}",
    "no_tasks": "Ni opravil",
    "no_tasks_available_currently": "Trenutno ni razpoložljivih opravil.",
    "ok_the_team_typically_replies_in_day": "V redu, ekipa običajno odgovori v enem dnevu.",
    "ok_the_team_typically_replies_in_hours": "V redu, ekipa običajno odgovori v nekaj urah.",
    "ok_the_team_typically_replies_in_minutes": "V redu, ekipa običajno odgovori v nekaj minutah.",
    "ok_the_team_typically_replies_in_under_n_hours": "V redu, ekipa običajno odgovori prej kot v {hours} urah.",
    "ok_the_team_typically_replies_in_under_n_min": "V redu, ekipa običajno odgovori prej kot v {minutes} minutah.",
    "ok_the_team_typically_replies_in_week": "V redu, ekipa običajno odgovori v enem tednu.",
    "our_bot_and_team_can_help": "Naš bot in naša ekipa lahko pomagata",
    "our_bot_answers_instantly": "Naš bot odgovarja takoj",
    "powered_by_intercom": "Omogoča Intercom",
    "previous_bot_hands_over": "Vsebina prejšnjega bota ...",
    "proactive_expected_response_delay_10800": "Običajno odgovori v manj kot 3 urah",
    "proactive_expected_response_delay_120": "Običajno odgovori v manj kot 2 minutah",
    "proactive_expected_response_delay_1200": "Običajno odgovori v manj kot 20 minutah",
    "proactive_expected_response_delay_14400": "Običajno odgovori v manj kot 4 urah",
    "proactive_expected_response_delay_172800": "Običajno odgovori v 2 dneh",
    "proactive_expected_response_delay_180": "Običajno odgovori v manj kot 3 minutah",
    "proactive_expected_response_delay_1800": "Običajno odgovori v manj kot 30 minutah",
    "proactive_expected_response_delay_18000": "Običajno odgovori v manj kot 5 urah",
    "proactive_expected_response_delay_21600": "Običajno odgovori v manj kot 6 urah",
    "proactive_expected_response_delay_240": "Običajno odgovori v manj kot 4 minutah",
    "proactive_expected_response_delay_259200": "Običajno odgovori v 3 dnevih",
    "proactive_expected_response_delay_300": "Običajno odgovori v manj kot 5 minutah",
    "proactive_expected_response_delay_345600": "Običajno odgovori v 4 dnevih",
    "proactive_expected_response_delay_3600": "Običajno odgovori v manj kot 1 uri",
    "proactive_expected_response_delay_43200": "Običajno odgovori v 12 urah",
    "proactive_expected_response_delay_432000": "Običajno odgovori v 5 dnevih",
    "proactive_expected_response_delay_518400": "Običajno odgovori v 6 dnevih",
    "proactive_expected_response_delay_60": "Običajno odgovori v manj kot 1 minuti",
    "proactive_expected_response_delay_600": "Običajno odgovori v manj kot 10 minutah",
    "proactive_expected_response_delay_604800": "Običajno odgovori v manj kot 1 tednu",
    "proactive_expected_response_delay_7200": "Običajno odgovori v manj kot 2 urah",
    "proactive_expected_response_delay_86400": "Običajno odgovori v 1 dnevu",
    "proactive_expected_response_delay_900": "Običajno odgovori v manj kot 15 minutah",
    "proactive_expected_response_delay_unknown": "Odgovorili bodo, takoj ko je mogoče",
    "profile_location": "{time} na lokaciji {location}",
    "recent_ticket": "Nedavni zahtevek",
    "recent_tickets": "Nedavni zahtevki",
    "search_browse_empty_state_text": "Žal za to nismo našli nobenih rezultatov. Poskusite preoblikovati iskanje.",
    "search_for_help": "Poiščite pomoč",
    "see_all": "Oglejte si vse",
    "see_all_conversations": "Oglejte si vse svoje pogovore",
    "see_previous_conversations": "Oglejte si svoje predhodne pogovore",
    "send": "Pošlji",
    "send_a_message": "Pošlji sporočilo…",
    "send_a_message_reply_time": "Običajni čas odgovora je",
    "send_us_a_message": "Pošljite nam sporočilo",
    "set_expectations_bot_email_header": "Odgovore boste dobili tukaj in po svoji e-pošti:",
    "set_expectations_bot_office_hours_header": "Kdaj se bo ekipa vrnila:",
    "set_expectations_bot_sms_header": "Odgovore boste dobili tukaj in po SMS-u:",
    "set_expectations_replies_to_email": "Odgovore boste prejeli tukaj in na <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Odgovore boste prejeli tukaj in na {user_email}",
    "set_expectations_team_reply_asap": "Ekipa bo odgovorila takoj, ko bo mogoče.",
    "shared_by": "Delil",
    "show_less": "Prikaži manj",
    "single_article": "1 članek",
    "single_collection": "1 zbirka",
    "source": "Vir",
    "sources": "Viri",
    "start_a_conversation": "Začnite pogovor",
    "start_conversation": "Začni pogovor",
    "still_generating_answer": "Še vedno ustvarjam odgovor …",
    "still_thinking": "Še vedno razmišljam …",
    "still_working_on_it": "Še vedno delam na tem …",
    "tasks_space_title": "Opravila",
    "team_name_proactive_expected_response_delay_unknown": "Ekipa {app_name} bo odgovorila takoj, ko bo to mogoče.",
    "teammate_changes_team_assignment_of_a_conversation": "Član ekipe je spremenil dodelitev pogovora ...",
    "tell_us_what_you_need": "Povejte nam, kako vam lahko pomagamo in naš robot se bo potrudil, da vam bo odgovoril",
    "the_team_can_help_if_needed": "Če je potrebno, vam lahko pomaga ekipa",
    "the_team_typically_replies_in_10800": "Ekipa običajno odgovori v manj kot 3 urah.",
    "the_team_typically_replies_in_120": "Ekipa običajno odgovori v manj kot 2 minutah",
    "the_team_typically_replies_in_1200": "Ekipa običajno odgovori v manj kot 20 minutah.",
    "the_team_typically_replies_in_14400": "Ekipa običajno odgovori v manj kot 4 urah.",
    "the_team_typically_replies_in_172800": "Ekipa običajno odgovori v 2 dneh.",
    "the_team_typically_replies_in_180": "Ekipa običajno odgovori v manj kot 3 minutah",
    "the_team_typically_replies_in_1800": "Ekipa običajno odgovori v manj kot 30 minutah.",
    "the_team_typically_replies_in_18000": "Ekipa običajno odgovori v manj kot 5 urah.",
    "the_team_typically_replies_in_21600": "Ekipa običajno odgovori v manj kot 6 urah.",
    "the_team_typically_replies_in_240": "Ekipa običajno odgovori v manj kot 4 minutah",
    "the_team_typically_replies_in_259200": "Ekipa običajno odgovori v 3 dnevih",
    "the_team_typically_replies_in_300": "Ekipa običajno odgovori v manj kot 5 minutah.",
    "the_team_typically_replies_in_345600": "Ekipa običajno odgovori v 4 dnevih",
    "the_team_typically_replies_in_3600": "Ekipa običajno odgovori v manj kot 1 uri.",
    "the_team_typically_replies_in_43200": "Ekipa običajno odgovori v manj kot 12 urah.",
    "the_team_typically_replies_in_432000": "Ekipa običajno odgovori v 5 dnevih",
    "the_team_typically_replies_in_518400": "Ekipa običajno odgovori v 6 dnevih",
    "the_team_typically_replies_in_60": "Ekipa običajno odgovori v manj kot 1 minuti",
    "the_team_typically_replies_in_600": "Ekipa običajno odgovori v manj kot 10 minutah.",
    "the_team_typically_replies_in_604800": "Ekipa običajno odgovori v manj kot 1 tednu.",
    "the_team_typically_replies_in_7200": "Ekipa običajno odgovori v manj kot 2 urah.",
    "the_team_typically_replies_in_86400": "Ekipa običajno odgovori v 1 dnevu.",
    "the_team_typically_replies_in_900": "Ekipa običajno odgovori v manj kot 15 minutah",
    "the_team_typically_replies_in_day": "Ekipa običajno odgovori v enem dnevu.",
    "the_team_typically_replies_in_hours": "Ekipa običajno odgovori v nekaj urah.",
    "the_team_typically_replies_in_minutes": "Ekipa običajno odgovori v nekaj minutah.",
    "the_team_typically_replies_in_under_n_hours": "Ekipa običajno odgovori prej kot v {hours} urah.",
    "the_team_typically_replies_in_under_n_min": "Ekipa običajno odgovori prej kot v {minutes} minutah.",
    "the_team_typically_replies_in_unknown": "Ekipa bo odgovorila takoj, ko bo mogoče.",
    "the_team_typically_replies_in_week": "Ekipa običajno odgovori v enem tednu.",
    "thinking": "Razmišljam …",
    "tickets_cta_text": "Imate vprašanje glede tega zahtevka?",
    "tickets_have_a_question": "Imate vprašanje glede te težave?",
    "tickets_space_title": "Zahtevki",
    "time_future_1h": "1 ura",
    "time_future_2h": "2 uri",
    "time_future_30m": "30 minut",
    "time_future_3h": "3 ure",
    "time_future_day_0": "Ponedeljek",
    "time_future_day_1": "Torek",
    "time_future_day_2": "Sreda",
    "time_future_day_3": "Četrtek",
    "time_future_day_4": "Petek",
    "time_future_day_5": "Sobota",
    "time_future_day_6": "Nedelja",
    "time_future_later_today": "Kasneje danes",
    "time_future_next_week": "Naslednji teden",
    "time_future_tomorrow": "Jutri",
    "try_use_different_keywords": "Poskusite uporabiti različne ključne besede ali vnesite celotno vprašanje",
    "upfront_collection_form_close_out": "Kadarkoli se lahko vrnete na to stran, si ogledate odgovore in pošljete posodobitve.",
    "upfront_collection_form_introduction": "Delite kontekst, da se bo lahko ekipa bolje in hitreje odzvala.",
    "upfront_collection_form_submission_confirmation": "Hvala, da ste delili ta kontekst. Da boste kasneje prihraniti čas, lahko zdaj dodate več podrobnosti.",
    "upfront_collection_form_subtitle": "Pošljite ekipi kontekst",
    "upfront_collection_form_subtitle_submitted": "Upoštevali bodo ta kontekst",
    "upfront_collection_form_title": "Hitreje poiščite pomoč",
    "upfront_collection_form_title_submitted": "Poslano ekipi!",
    "view_all": "Poglej vse",
    "view_conversation": "Prikaži pogovor",
    "we_are_here_to_help": "Tukaj smo, da vam pomagamo",
    "we_run_on_intercom": "Uporabljamo platformo Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Ko član ekipe spremeni stanje pogovora ...",
    "working_on_it": "Delam na tem …",
    "you_are_all_up_to_date": "Ste na tekočem!",
    "your_checklists": "Vaši kontrolni seznami",
    "your_conversations": "Vaši pogovori",
    "your_recent_conversations": "Nedavni pogovori",
    "your_recent_messages": "Nedavna sporočila"
  },
  "sr": {
    "a_day": "Jedan dan",
    "a_few_hours": "Par sati",
    "a_few_minutes": "Par minuta",
    "a_week": "Jedna nedelja",
    "active_state": "Активан",
    "ai_answer": "AI odgovor",
    "ai_answer_information": "AI odgovor, informacije",
    "ai_bot": "AI bot",
    "almost_there": "Još malo...",
    "answer": "Odgovor",
    "answer_bot_acknowledge_duplicate_content": "Ovo ste već videli, ali je najbolji dostupan odgovor.",
    "answer_bot_all_done": "To je pomoglo 👍",
    "answer_bot_ask_another_question": "Postavite drugo pitanje 🔁",
    "answer_bot_ask_something_else": "Pitajte nešto drugo 🔁",
    "answer_bot_connect_to_an_agent": "Povežite se sa agentom 👤",
    "answer_bot_did_that_help": "Da li je taj odgovor pomogao ili tražite nešto drugo?",
    "answer_bot_followup_expectations_prefix": "Tim će vas kontaktirati u vezi ovoga.",
    "answer_bot_get_more_help": "Potražite dodatnu pomoć 🌐",
    "answer_bot_get_more_help_followup": "Ova veza bi mogla da pomogne:",
    "answer_bot_i_have_another_question": "Imam još jedno pitanje 🔁",
    "answer_bot_im_not_done_yet": "Još nisam završio 🔁",
    "answer_bot_keep_waiting": "Ne, još uvek mi treba pomoć 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Možete da preformulišete svoje pitanje ili da dobijete dodatnu pomoć",
    "answer_bot_looping_first_no_answer": "Žao mi je, kao bot nisam uspeo da nađem odgovor za to.",
    "answer_bot_looping_first_no_answer_followup": "Možete da preformulišete svoje pitanje ili da razgovarate sa našim timom",
    "answer_bot_looping_no_answer": "Žao mi je, kao robot nisam uspeo da nađem odgovor za to. Evo šta možete da uradite",
    "answer_bot_more_answers": "Pokažite mi više 👀",
    "answer_bot_no_answer_found": "Žao nam je, nemamo brz odgovor na to. Evo nekih drugih opcija.",
    "answer_bot_resolve_followup": "Uvek možete da odgovorite ovde ukoliko se pojavi još nešto.",
    "answer_bot_talk_to_person": "Razgovarajte sa osobom 👤",
    "answer_bot_that_answered_my_question": "To je odgovor na moje pitanje 👍",
    "answer_bot_thats_it": "To je to 👍",
    "answer_bot_wait_for_the_team": "Sačekajte tim 💬",
    "answerbot_ask_for_more_context": "Naravno. Dok čekamo, možete li podeliti još detalja o svojoj situaciji ili pitanju? Tako ćemo brže pronaći odgovor, a možda ću vam pronaći i više informacija.",
    "answerbot_introduce_sneaky_answer": "Hvala na tom dodatnom kontekstu. Pronađene su neke nove informacije koje bi vam mogle pomoći.",
    "article_search_hint_text": "Pretražite članke...",
    "ask_a_question": "Postavite pitanje",
    "ask_a_question_placeholder": "Postavite pitanje...",
    "ask_a_question_with_ellipsis": "Postavite pitanje...",
    "ask_another_question_with_ellipsis": "Postavite još jedno pitanje...",
    "ask_for_the_team_if_needed": "Zatražite tim ako je potrebno",
    "ask_me_a_question": "Postavite mi pitanje",
    "ask_our_bot_a_question": "Postavite pitanje našem botu",
    "avatar": "Avatar",
    "avatars": "Avatari",
    "bot": "Robot",
    "bot_answers_instantly": "Bot odmah odgovara",
    "bot_expectation_text": "Naš robot može da odgovori na veliki broj uobičajenih pitanja",
    "bot_intro_default_part_1": "Zdravo! Obraća vam se bot. Ovde sam da odgovorim na vaša pitanja, ali uvek ćete imati opciju da razgovarate sa našim timom.",
    "bot_intro_default_part_2": "Zbog čega ste danas ovde?",
    "bot_is_still_thinking": "Bot još uvek razmišlja...",
    "bot_is_thinking": "Bot razmišlja...",
    "browse_collections": "Pregledajte kolekcije",
    "chat_with_us": "Ćaskajte sa nama",
    "check_later_for_updates": "Proverite kasnije da li ima novosti.",
    "checklist_card_first_step": "Prvi korak",
    "checklist_card_next_step": "Sledeći korak",
    "checklist_completed": "Završeno",
    "checklist_in_progress_time_remaining_plural": "Preostalo je oko {minutes} minuta",
    "checklist_in_progress_time_remaining_singular": "Preostalo oko 1 minut",
    "checklist_mark_step_as_complete": "Označi kao završeno",
    "checklist_not_started_time_remaining_plural": "Oko {minutes} minuta",
    "checklist_not_started_time_remaining_singular": "Oko 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} koraka",
    "checklist_number_of_steps_progress_plural": "{steps_completed} od {total_steps} je završeno",
    "checklist_number_of_steps_progress_singular": "1 od {total_steps} je završeno",
    "checklist_number_of_steps_singular": "1 korak",
    "checklist_sender_name": "Podelio: {name}",
    "checklist_step_action_button": "Dugme za korak radnje",
    "checklist_step_completed": "Završeno",
    "contact_support": "Obratite se podršci",
    "contact_us": "Obratite nam se",
    "continue_the_conversation": "Nastavite razgovor",
    "conversation_card_office_hours_header": "Kada se vratimo na mrežu",
    "conversation_card_reply_time_header": "Naše uobičajeno vreme za davanje odgovora",
    "conversation_card_reply_time_header_m5": "Uobičajeno vreme za odgovor",
    "conversation_card_unknown_response_time": "Odgovorićemo čim budemo u mogućnosti",
    "conversation_part_sample_text": "Zdravo, kako mogu da pomognem? Uvek ćete imati mogućnost da razgovarate sa našim timom ako je potrebno.",
    "conversations_space_title": "Razgovori",
    "create_a_ticket": "Kreiraj tiket",
    "currently_no_help_articles": "Trenutno nema dostupnih članaka za pomoć.",
    "customer_sends_their_first_message": "Kupac šalje svoju prvu poruku...",
    "expected_response_delay_10800": "manje od 3 sata",
    "expected_response_delay_120": "manje od 2 minuta",
    "expected_response_delay_1200": "manje od 20 minuta",
    "expected_response_delay_14400": "manje od 4 sata",
    "expected_response_delay_172800": "2 dana",
    "expected_response_delay_180": "manje od 3 minuta",
    "expected_response_delay_1800": "manje od 30 minuta",
    "expected_response_delay_18000": "manje od 5 sati",
    "expected_response_delay_21600": "manje od 6 sati",
    "expected_response_delay_240": "manje od 4 minuta",
    "expected_response_delay_259200": "3 dana",
    "expected_response_delay_300": "manje od 5 minuta",
    "expected_response_delay_345600": "4 dana",
    "expected_response_delay_3600": "manje od 1 sat",
    "expected_response_delay_43200": "manje od 12 sati",
    "expected_response_delay_432000": "5 dana",
    "expected_response_delay_518400": "6 dana",
    "expected_response_delay_60": "manje od 1 minuta",
    "expected_response_delay_600": "manje od 10 minuta",
    "expected_response_delay_604800": "manje od 1 nedelje",
    "expected_response_delay_7200": "manje od 2 sata",
    "expected_response_delay_86400": "1 dan",
    "expected_response_delay_900": "manje od 15 minuta",
    "expected_response_delay_unknown": "Čim budemo bili u mogućnosti",
    "extra_labels_count": "+ {labels_count} oznake",
    "for_best_results_provide_detail": "Da biste dobili najbolje rezultate navedite što više detalja",
    "generating_answer": "Generisanje odgovora...",
    "generic_triage_default": "Potrebno nam je samo još malo informacija kako bismo vas povezali s pravom osobom",
    "get_an_instant_answer": "Odmah dobijte odgovor",
    "get_help": "Potražite pomoć",
    "go_to": "Idi na {app_name}",
    "help_space_title": "Pomoć",
    "home_header_mobile_hello_firstname": "Zdravo, {firstname}.",
    "home_header_mobile_hello_there": "Pozdrav",
    "home_space_title": "Početna",
    "how_can_we_help": "Kako možemo da pomognemo?",
    "how_would_you_rate_this": "Kako biste ovo ocenili?",
    "learn_more": "Saznajte više",
    "message_placeholder": "Poruka…",
    "messages_space_title": "Poruke",
    "messenger_bot_reply_time": "Naš bot će odmah odgovoriti",
    "messenger_settings_introduction": "Kako možemo da pomognemo?",
    "multiple_articles": "{total_articles} članaka",
    "multiple_collections": "{total_collection} kolekcija",
    "new_conversation": "Novi razgovor",
    "news": "Novosti",
    "news_empty_title": "Još nema novosti",
    "news_latest_subtitle": "Od tima {team_name}",
    "news_latest_title": "Najnovije",
    "news_older_title": "Starije",
    "news_space_title": "Novosti",
    "newsfeed": "Feed sa novostima",
    "no_articles_to_display": "Još nema članaka",
    "no_help_articles_to_display": "Nema članaka za pomoć",
    "no_results_for_searchterm": "Nema rezultata za {searchTerm}",
    "no_tasks": "Nema zadataka",
    "no_tasks_available_currently": "Trenutno nema dostupnih zadataka.",
    "ok_the_team_typically_replies_in_day": "U redu, tim obično odgovara za jedan dan.",
    "ok_the_team_typically_replies_in_hours": "U redu, tim obično odgovara za nekoliko sati.",
    "ok_the_team_typically_replies_in_minutes": "U redu, tim obično odgovara za nekoliko minuta.",
    "ok_the_team_typically_replies_in_under_n_hours": "U redu, tim obično odgovara za manje od {hours} h",
    "ok_the_team_typically_replies_in_under_n_min": "U redu, tim obično odgovara za manje od {minutes} min",
    "ok_the_team_typically_replies_in_week": "U redu, tim obično odgovori u roku od jedne nedelje.",
    "our_bot_and_team_can_help": "Naš bot i tim mogu da pomognu",
    "our_bot_answers_instantly": "Naš bot odmah odgovara",
    "powered_by_intercom": "Koristimo tehnologiju kompanije Intercom",
    "previous_bot_hands_over": "Sadržaj od prethodnog bota...",
    "proactive_expected_response_delay_10800": "Obično odgovara za manje od 3 č.",
    "proactive_expected_response_delay_120": "Obično odgovara za manje od 2 min.",
    "proactive_expected_response_delay_1200": "Obično odgovara za manje od 20 min.",
    "proactive_expected_response_delay_14400": "Obično odgovara za manje od 4 č.",
    "proactive_expected_response_delay_172800": "Obično odgovara za 2 dana",
    "proactive_expected_response_delay_180": "Obično odgovara za manje od 3 min.",
    "proactive_expected_response_delay_1800": "Obično odgovara za manje od 30 min.",
    "proactive_expected_response_delay_18000": "Obično odgovara za manje od 5 č.",
    "proactive_expected_response_delay_21600": "Obično odgovara za manje od 6 č.",
    "proactive_expected_response_delay_240": "Obično odgovara za manje od 4 min.",
    "proactive_expected_response_delay_259200": "Obično odgovara za 3 dana",
    "proactive_expected_response_delay_300": "Obično odgovara za manje od 5 min.",
    "proactive_expected_response_delay_345600": "Obično odgovara za 4 dana",
    "proactive_expected_response_delay_3600": "Obično odgovara za manje od 1 č.",
    "proactive_expected_response_delay_43200": "Obično odgovara za 12 č.",
    "proactive_expected_response_delay_432000": "Obično odgovara za 5 dana",
    "proactive_expected_response_delay_518400": "Obično odgovara za 6 dana",
    "proactive_expected_response_delay_60": "Obično odgovara za manje od 1 min.",
    "proactive_expected_response_delay_600": "Obično odgovara za manje od 10 min.",
    "proactive_expected_response_delay_604800": "Obično odgovara za manje od 1 nedelje",
    "proactive_expected_response_delay_7200": "Obično odgovara za manje od 2 č.",
    "proactive_expected_response_delay_86400": "Obično odgovara za 1 dan",
    "proactive_expected_response_delay_900": "Obično odgovara za manje od 15 min.",
    "proactive_expected_response_delay_unknown": "Odgovoriće čim bude u mogućnosti",
    "profile_location": "{time} u {location}",
    "recent_ticket": "Nedavni tiket",
    "recent_tickets": "Nedavni tiketi",
    "search_browse_empty_state_text": "Žao nam je, nismo pronašli nijedan rezultat za to. Pokušajte preformulisati pretragu.",
    "search_for_help": "Potražite pomoć",
    "see_all": "Vidi sve",
    "see_all_conversations": "Pogledajte sve vaše razgovore",
    "see_previous_conversations": "Pogledajte vaše prethodne razgovore",
    "send": "Pošalji",
    "send_a_message": "Pošaljite poruku…",
    "send_a_message_reply_time": "Uobičajeno vreme za odgovor je",
    "send_us_a_message": "Pošaljite nam poruku",
    "set_expectations_bot_email_header": "Odgovore ćete dobiti ovde i putem e-pošte:",
    "set_expectations_bot_office_hours_header": "Kada će tim opet biti ovde:",
    "set_expectations_bot_sms_header": "Odgovore ćete dobiti ovde i SMS-om:",
    "set_expectations_replies_to_email": "Odgovore ćete dobiti ovde i na <b>{user_email}</b> .",
    "set_expectations_replies_to_email_without_bold_text": "Odgovore ćete dobiti ovde i na adresu {user_email}",
    "set_expectations_team_reply_asap": "Tim će odgovoriti čim bude mogao.",
    "shared_by": "Podelio",
    "show_less": "Prikaži manje",
    "single_article": "1 članak",
    "single_collection": "1 kolekcija",
    "source": "Izvor",
    "sources": "Izvori",
    "start_a_conversation": "Započni razgovor",
    "start_conversation": "Započni razgovor…",
    "still_generating_answer": "Još uvek se generiše odgovor...",
    "still_thinking": "Još uvek razmišljam...",
    "still_working_on_it": "Još uvek radim na tome...",
    "tasks_space_title": "Zadaci",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} će odgovoriti čim bude u mogućnosti",
    "teammate_changes_team_assignment_of_a_conversation": "Saigrač je promenio dodelu razgovora…",
    "tell_us_what_you_need": "Recite nam u vezi sa čim vam je potrebna pomoć i naš robot će se potruditi da odgovori",
    "the_team_can_help_if_needed": "Tim može da pomogne ako je potrebno",
    "the_team_typically_replies_in_10800": "Tim obično odgovara za manje od 3 č.",
    "the_team_typically_replies_in_120": "Tim obično odgovori za manje od 20 min.",
    "the_team_typically_replies_in_1200": "Tim obično odgovara za manje od 20 min.",
    "the_team_typically_replies_in_14400": "Tim obično odgovara za manje od 4 č.",
    "the_team_typically_replies_in_172800": "Tim obično odgovara u roku od 2 dana",
    "the_team_typically_replies_in_180": "Tim obično odgovara za manje od 3 min.",
    "the_team_typically_replies_in_1800": "Tim obično odgovara za manje od 30 min.",
    "the_team_typically_replies_in_18000": "Tim obično odgovara za manje od 5 č.",
    "the_team_typically_replies_in_21600": "Tim obično odgovara za manje od 6 č.",
    "the_team_typically_replies_in_240": "Tim obično odgovara za manje od 4 min.",
    "the_team_typically_replies_in_259200": "Tim obično odgovara u roku od 3 dana",
    "the_team_typically_replies_in_300": "Tim obično odgovara za manje od 5 min.",
    "the_team_typically_replies_in_345600": "Tim obično odgovara u roku od 4 dana",
    "the_team_typically_replies_in_3600": "Tim obično odgovara za manje od 1 č.",
    "the_team_typically_replies_in_43200": "Tim obično odgovara za manje od 12h",
    "the_team_typically_replies_in_432000": "Tim obično odgovara u roku od 5 dana",
    "the_team_typically_replies_in_518400": "Tim obično odgovara u roku od 6 dana",
    "the_team_typically_replies_in_60": "Tim obično odgovara za manje od 1 min.",
    "the_team_typically_replies_in_600": "Tim obično odgovara za manje od 10 min.",
    "the_team_typically_replies_in_604800": "Tim obično odgovara za manje od nedelju dana",
    "the_team_typically_replies_in_7200": "Tim obično odgovara za manje od 2 č.",
    "the_team_typically_replies_in_86400": "Tim obično odgovara u roku od 1 dana",
    "the_team_typically_replies_in_900": "Tim obično odgovara za manje od 15 min.",
    "the_team_typically_replies_in_day": "Tim obično odgovara u roku od jednog dana.",
    "the_team_typically_replies_in_hours": "Tim obično odgovara u roku od nekoliko sati.",
    "the_team_typically_replies_in_minutes": "Tim obično odgovara u roku od nekoliko minuta.",
    "the_team_typically_replies_in_under_n_hours": "Tim obično odgovara za manje od {hours} časova.",
    "the_team_typically_replies_in_under_n_min": "Tim obično odgovara za manje od {minutes} min.",
    "the_team_typically_replies_in_unknown": "Tim će odgovoriti čim bude u mogućnosti",
    "the_team_typically_replies_in_week": "Tim obično odgovori u roku od jedne nedelje.",
    "thinking": "Razmišljam...",
    "tickets_cta_text": "Imate li pitanje u vezi sa ovim tiketom?",
    "tickets_have_a_question": "Imate li pitanje u vezi ovog problema?",
    "tickets_space_title": "Tiketi",
    "time_future_1h": "1 sat",
    "time_future_2h": "2 sata",
    "time_future_30m": "30 minuta",
    "time_future_3h": "3 sata",
    "time_future_day_0": "Ponedeljak",
    "time_future_day_1": "Utorak",
    "time_future_day_2": "Sreda",
    "time_future_day_3": "Četvrtak",
    "time_future_day_4": "Petak",
    "time_future_day_5": "Subota",
    "time_future_day_6": "Nedelja",
    "time_future_later_today": "Kasnije ovog dana",
    "time_future_next_week": "Sledeće nedelje",
    "time_future_tomorrow": "Sutra",
    "try_use_different_keywords": "Pokušajte sa drugim ključnim rečima ili upišite celo pitanje",
    "upfront_collection_form_close_out": "Uvek možete da se vratite ovde da vidite odgovore i pošaljete ažuriranja.",
    "upfront_collection_form_introduction": "Podelite kontekst da biste pomogli da tim bolje i brže reaguje.",
    "upfront_collection_form_submission_confirmation": "Hvala što ste poslali kontekst. Da biste kasnije uštedeli na vremenu, slobodno sada dodajte više pojedinosti.",
    "upfront_collection_form_subtitle": "Pošaljite timu neki kontekst",
    "upfront_collection_form_subtitle_submitted": "Oni će voditi računa o kontekstu",
    "upfront_collection_form_title": "Još brže dobijte pomoć",
    "upfront_collection_form_title_submitted": "Poslato timu!",
    "view_all": "Prikaži sve",
    "view_conversation": "Prikaži razgovor",
    "we_are_here_to_help": "Stojimo vam na raspolaganju",
    "we_run_on_intercom": "Naša platforma je Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Kada član tima promeni status razgovora...",
    "working_on_it": "Radim na tome...",
    "you_are_all_up_to_date": "Završili ste sve što je trebalo!",
    "your_checklists": "Vaše kontrolne liste",
    "your_conversations": "Vaš razgovor",
    "your_recent_conversations": "Nedavni razgovori",
    "your_recent_messages": "Nedavne poruke"
  },
  "sv": {
    "a_day": "en dag",
    "a_few_hours": "några timmar",
    "a_few_minutes": "några minuter",
    "a_week": "en vecka",
    "active_state": "Aktiv",
    "ai_answer": "AI-svar",
    "ai_answer_information": "AI-svar, information",
    "ai_bot": "AI-bot",
    "almost_there": "Nästan klart ...",
    "answer": "Svar",
    "answer_bot_acknowledge_duplicate_content": "Du har redan sett det här, men det är det bästa tillgängliga svaret.",
    "answer_bot_all_done": "Det hjälpte 👍",
    "answer_bot_ask_another_question": "Ställ en annan fråga 🔁",
    "answer_bot_ask_something_else": "Fråga något annat 🔁",
    "answer_bot_connect_to_an_agent": "Koppla mig till en representant 👤",
    "answer_bot_did_that_help": "Fick du hjälp av svaret eller söker du någonting mer?",
    "answer_bot_followup_expectations_prefix": "Teamet återkommer till dig om det här.",
    "answer_bot_get_more_help": "Få mer hjälp 🌐",
    "answer_bot_get_more_help_followup": "Den här länken kanske kan vara till hjälp:",
    "answer_bot_i_have_another_question": "Jag har en annan fråga 🔁",
    "answer_bot_im_not_done_yet": "Jag är inte klar ännu 🔁",
    "answer_bot_keep_waiting": "Nej, jag behöver fortfarande hjälp 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Du kan formulera om din fråga eller få ytterligare hjälp",
    "answer_bot_looping_first_no_answer": "Tyvärr – som bot kunde jag inte hitta något svar på det.",
    "answer_bot_looping_first_no_answer_followup": "Du kan formulera om din fråga eller prata med vårt team",
    "answer_bot_looping_no_answer": "Tyvärr – som bot kunde jag inte hitta något svar på det. Det här kan du göra",
    "answer_bot_more_answers": "Visa mig mer 👀",
    "answer_bot_no_answer_found": "Tyvärr har vi inget snabbt svar på det. Här är några andra alternativ.",
    "answer_bot_resolve_followup": "Du kan alltid svara här om det är något mer.",
    "answer_bot_talk_to_person": "Prata med en person 👤",
    "answer_bot_that_answered_my_question": "Det besvarade min fråga 👍",
    "answer_bot_thats_it": "Det var allt 👍",
    "answer_bot_wait_for_the_team": "Vänta på teamet 💬",
    "answerbot_ask_for_more_context": "Visst. Medan vi väntar, kan du berätta mer om din situation eller fråga? Då kan vi hitta ett svar snabbare, och jag kanske hittar mer information åt dig.",
    "answerbot_introduce_sneaky_answer": "Tack för att du förklarade. Jag hittade lite ny information som kanske kan hjälpa dig.",
    "article_search_hint_text": "Sök artiklar ...",
    "ask_a_question": "Ställ en fråga",
    "ask_a_question_placeholder": "Ställ en fråga …",
    "ask_a_question_with_ellipsis": "Ställ en fråga ...",
    "ask_another_question_with_ellipsis": "Ställ en ny fråga ...",
    "ask_for_the_team_if_needed": "Fråga efter teamet om det behövs",
    "ask_me_a_question": "Ställ en fråga till mig",
    "ask_our_bot_a_question": "Ställ en fråga till vår bot",
    "avatar": "Avatar",
    "avatars": "Avatarer",
    "bot": "Robot",
    "bot_answers_instantly": "Boten svarar direkt",
    "bot_expectation_text": "Vår bot kan svara på många vanliga frågor",
    "bot_intro_default_part_1": "Hej! Jag är en bot. Jag finns här för att besvara dina frågor, men du kan också alltid välja att prata med någon i vårt team.",
    "bot_intro_default_part_2": "Vad kan jag hjälpa dig med idag?",
    "bot_is_still_thinking": "Boten tänker fortfarande ...",
    "bot_is_thinking": "Boten tänker ...",
    "browse_collections": "Bläddra bland samlingar",
    "chat_with_us": "Chatta med oss",
    "check_later_for_updates": "Kom tillbaka senare för uppdateringar.",
    "checklist_card_first_step": "Första steget",
    "checklist_card_next_step": "Nästa steg",
    "checklist_completed": "Slutfört",
    "checklist_in_progress_time_remaining_plural": "Cirka {minutes} minuter kvar",
    "checklist_in_progress_time_remaining_singular": "Cirka 1 minut kvar",
    "checklist_mark_step_as_complete": "Markera som slutfört",
    "checklist_not_started_time_remaining_plural": "Cirka {minutes} minuter",
    "checklist_not_started_time_remaining_singular": "Cirka 1 minut",
    "checklist_number_of_steps_plural": "{number_of_steps} steg",
    "checklist_number_of_steps_progress_plural": "Slutfört: {steps_completed} av {total_steps}",
    "checklist_number_of_steps_progress_singular": "Slutfört: 1 av {total_steps}",
    "checklist_number_of_steps_singular": "1 steg",
    "checklist_sender_name": "Delat av {name}",
    "checklist_step_action_button": "Åtgärdsknapp för steg",
    "checklist_step_completed": "Slutfört",
    "contact_support": "Kontakta supporten",
    "contact_us": "Kontakta oss",
    "continue_the_conversation": "Fortsätt konversationen",
    "conversation_card_office_hours_header": "Vi är tillbaka online",
    "conversation_card_reply_time_header": "Vår normala svarstid",
    "conversation_card_reply_time_header_m5": "Normal svarstid",
    "conversation_card_unknown_response_time": "Vi svarar så fort som möjligt",
    "conversation_part_sample_text": "Hej, hur kan jag hjälpa till? Du har alltid möjlighet att prata med vårt team om det behövs.",
    "conversations_space_title": "Konversationer",
    "create_a_ticket": "Skapa ett ärende",
    "currently_no_help_articles": "Det finns för närvarande inga hjälpartiklar tillgängliga.",
    "customer_sends_their_first_message": "Kunden skickar sitt första meddelande ...",
    "expected_response_delay_10800": "högst 3 timmar",
    "expected_response_delay_120": "högst 2 minuter",
    "expected_response_delay_1200": "högst 20 minuter",
    "expected_response_delay_14400": "högst 4 timmar",
    "expected_response_delay_172800": "2 dagar",
    "expected_response_delay_180": "högst 3 minuter",
    "expected_response_delay_1800": "högst 30 minuter",
    "expected_response_delay_18000": "högst 5 timmar",
    "expected_response_delay_21600": "högst 6 timmar",
    "expected_response_delay_240": "högst 4 minuter",
    "expected_response_delay_259200": "3 dagar",
    "expected_response_delay_300": "högst 5 minuter",
    "expected_response_delay_345600": "4 dagar",
    "expected_response_delay_3600": "högst 1 timme",
    "expected_response_delay_43200": "inom 12 timmar",
    "expected_response_delay_432000": "5 dagar",
    "expected_response_delay_518400": "6 dagar",
    "expected_response_delay_60": "högst 1 minut",
    "expected_response_delay_600": "högst 10 minuter",
    "expected_response_delay_604800": "högst 1 vecka",
    "expected_response_delay_7200": "högst 2 timmar",
    "expected_response_delay_86400": "1 dag",
    "expected_response_delay_900": "inom 15 minuter",
    "expected_response_delay_unknown": "så snart vi kan",
    "extra_labels_count": "+ {labels_count} etiketter",
    "for_best_results_provide_detail": "För bästa resultat, ange så mycket detaljer som möjligt",
    "generating_answer": "Genererar ett svar ...",
    "generic_triage_default": "Vi behöver bara lite mer information för att koppla dig till rätt person",
    "get_an_instant_answer": "Få svar direkt",
    "get_help": "Hitta hjälp",
    "go_to": "Gå till {app_name}",
    "help_space_title": "Hjälp",
    "home_header_mobile_hello_firstname": "Hej, {firstname}!",
    "home_header_mobile_hello_there": "Hej!",
    "home_space_title": "Hem",
    "how_can_we_help": "Hur kan vi hjälpa till?",
    "how_would_you_rate_this": "Vilket betyg ger du det här?",
    "learn_more": "Läs mer",
    "message_placeholder": "Meddelande...",
    "messages_space_title": "Meddelanden",
    "messenger_bot_reply_time": "Vår bot svarar omedelbart",
    "messenger_settings_introduction": "Hur kan vi hjälpa till?",
    "multiple_articles": "{total_articles} artiklar",
    "multiple_collections": "{total_collection} samlingar",
    "new_conversation": "Ny konversation",
    "news": "Nyheter",
    "news_empty_title": "Inga nyheter ännu",
    "news_latest_subtitle": "Från teamet {team_name}",
    "news_latest_title": "Senaste",
    "news_older_title": "Äldre",
    "news_space_title": "Nyheter",
    "newsfeed": "Nyhetsflöde",
    "no_articles_to_display": "Inga artiklar ännu",
    "no_help_articles_to_display": "Inga hjälpartiklar",
    "no_results_for_searchterm": "Inga resultat för {searchTerm}",
    "no_tasks": "Inga uppgifter",
    "no_tasks_available_currently": "Det finns för närvarande inga tillgängliga uppgifter.",
    "ok_the_team_typically_replies_in_day": "Ok, teamet svarar vanligtvis inom en dag.",
    "ok_the_team_typically_replies_in_hours": "Ok, teamet svarar vanligtvis inom några få timmar.",
    "ok_the_team_typically_replies_in_minutes": "ok, teamet svarar vanligtvis inom några få minuter.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, teamet svarar vanligtvis inom {hours} tim.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, teamet svarar vanligtvis inom {minutes} m.",
    "ok_the_team_typically_replies_in_week": "Ok, teamet svarar vanligtvis inom en vecka.",
    "our_bot_and_team_can_help": "Vår bot och vårt team kan hjälpa dig",
    "our_bot_answers_instantly": "Vår bot svarar omedelbart",
    "powered_by_intercom": "Drivs av Intercom",
    "previous_bot_hands_over": "Innehåll från föregående bot ...",
    "proactive_expected_response_delay_10800": "Svarar vanligtvis inom 3 h",
    "proactive_expected_response_delay_120": "Svarar vanligtvis inom 2 min.",
    "proactive_expected_response_delay_1200": "Svarar vanligtvis inom 20 min",
    "proactive_expected_response_delay_14400": "Svarar vanligtvis inom 4 h",
    "proactive_expected_response_delay_172800": "Svarar vanligtvis inom 2 d",
    "proactive_expected_response_delay_180": "Svarar vanligtvis inom 3 min.",
    "proactive_expected_response_delay_1800": "Svarar vanligtvis inom 30 min",
    "proactive_expected_response_delay_18000": "Svarar vanligtvis inom 5 h",
    "proactive_expected_response_delay_21600": "Svarar vanligtvis inom 6 h",
    "proactive_expected_response_delay_240": "Svarar vanligtvis inom 4 min.",
    "proactive_expected_response_delay_259200": "Svarar vanligtvis inom 3 d",
    "proactive_expected_response_delay_300": "Svarar vanligtvis inom 5 min",
    "proactive_expected_response_delay_345600": "Svarar vanligtvis inom 4 d",
    "proactive_expected_response_delay_3600": "Svarar vanligtvis inom 1 h",
    "proactive_expected_response_delay_43200": "Svarar vanligtvis inom 12 t",
    "proactive_expected_response_delay_432000": "Svarar vanligtvis inom 5 d",
    "proactive_expected_response_delay_518400": "Svarar vanligtvis inom 6 d",
    "proactive_expected_response_delay_60": "Svarar vanligtvis inom 1 min.",
    "proactive_expected_response_delay_600": "Svarar vanligtvis inom 10 min",
    "proactive_expected_response_delay_604800": "Svarar vanligtvis inom 1 v",
    "proactive_expected_response_delay_7200": "Svarar vanligtvis inom 2 h",
    "proactive_expected_response_delay_86400": "Svarar vanligtvis inom 1 d",
    "proactive_expected_response_delay_900": "Svarar vanligtvis inom 15 min",
    "proactive_expected_response_delay_unknown": "Svarar så fort det är möjligt",
    "profile_location": "{time} i {location}",
    "recent_ticket": "Senaste ärende",
    "recent_tickets": "Senaste ärenden",
    "search_browse_empty_state_text": "Tyvärr kunde vi inte hitta några resultat för din sökning. Prova med att formulera om den.",
    "search_for_help": "Sök efter hjälp",
    "see_all": "Visa alla",
    "see_all_conversations": "Se alla dina konversationer",
    "see_previous_conversations": "Se dina tidigare konversationer",
    "send": "Skicka",
    "send_a_message": "Skriv ett meddelande…",
    "send_a_message_reply_time": "Normal svarstid är",
    "send_us_a_message": "Skicka ett meddelande till oss",
    "set_expectations_bot_email_header": "Du får svar här och till din e-postadress",
    "set_expectations_bot_office_hours_header": "Teamet är tillbaka:",
    "set_expectations_bot_sms_header": "Du får svar här och till SMS på nummer",
    "set_expectations_replies_to_email": "Du får svar här och på <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Du får svar här och på {user_email}",
    "set_expectations_team_reply_asap": "Teamet svarar så snart de kan.",
    "shared_by": "Delat av",
    "show_less": "Visa mindre",
    "single_article": "1 artikel",
    "single_collection": "1 samling",
    "source": "Källa",
    "sources": "Källor",
    "start_a_conversation": "Starta en konversation",
    "start_conversation": "Börja en konversation",
    "still_generating_answer": "Genererar fortfarande ett svar ...",
    "still_thinking": "Tänker fortfarande ...",
    "still_working_on_it": "Jobbar fortfarande på det ...",
    "tasks_space_title": "Uppgifter",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} svarar så fort det är möjligt",
    "teammate_changes_team_assignment_of_a_conversation": "Teammedlemmen har ändrat tilldelningen av konversationen...",
    "tell_us_what_you_need": "Berätta vad du behöver hjälp med, så gör vår bot sitt bästa för att svara",
    "the_team_can_help_if_needed": "Vårt team kan hjälpa dig om det behövs",
    "the_team_typically_replies_in_10800": "Teamet svarar vanligtvis inom 3 tim.",
    "the_team_typically_replies_in_120": "Teamet svarar vanligtvis inom 2 min.",
    "the_team_typically_replies_in_1200": "Teamet svarar vanligtvis inom 20 min.",
    "the_team_typically_replies_in_14400": "Teamet svarar vanligtvis inom 4 tim.",
    "the_team_typically_replies_in_172800": "Teamet svarar vanligtvis på 2 d.",
    "the_team_typically_replies_in_180": "Teamet svarar vanligtvis inom 3 min.",
    "the_team_typically_replies_in_1800": "Teamet svarar vanligtvis inom 30 min.",
    "the_team_typically_replies_in_18000": "Teamet svarar vanligtvis inom 5 tim.",
    "the_team_typically_replies_in_21600": "Teamet svarar vanligtvis inom 6 tim.",
    "the_team_typically_replies_in_240": "Teamet svarar vanligtvis inom 4 min.",
    "the_team_typically_replies_in_259200": "Teamet svarar vanligtvis inom 3 d",
    "the_team_typically_replies_in_300": "Teamet svarar vanligtvis inom 5 min.",
    "the_team_typically_replies_in_345600": "Teamet svarar vanligtvis inom 4 d",
    "the_team_typically_replies_in_3600": "Teamet svarar vanligtvis inom 1 tim.",
    "the_team_typically_replies_in_43200": "Teamet svarar vanligtvis inom 12 t",
    "the_team_typically_replies_in_432000": "Teamet svarar vanligtvis inom 5 d",
    "the_team_typically_replies_in_518400": "Teamet svarar vanligtvis inom 6 d",
    "the_team_typically_replies_in_60": "Teamet svarar vanligtvis inom 1 min.",
    "the_team_typically_replies_in_600": "Teamet svarar vanligtvis inom 10 min.",
    "the_team_typically_replies_in_604800": "Teamet svarar vanligtvis inom 1 v.",
    "the_team_typically_replies_in_7200": "Teamet svarar vanligtvis inom 2 tim.",
    "the_team_typically_replies_in_86400": "Teamet svarar vanligtvis på 1 d.",
    "the_team_typically_replies_in_900": "Teamet svarar vanligtvis inom 15 min",
    "the_team_typically_replies_in_day": "Teamet svarar vanligtvis inom en dag.",
    "the_team_typically_replies_in_hours": "Teamet svarar vanligtvis inom några timmar.",
    "the_team_typically_replies_in_minutes": "Teamet svarar vanligtvis inom några få minuter.",
    "the_team_typically_replies_in_under_n_hours": "Teamet svarar vanligtvis inom {hours} tim.",
    "the_team_typically_replies_in_under_n_min": "Teamet svarar vanligtvis inom {minutes} min.",
    "the_team_typically_replies_in_unknown": "Teamet svarar så snart de kan",
    "the_team_typically_replies_in_week": "Teamet svarar vanligtvis inom en vecka.",
    "thinking": "Tänker ...",
    "tickets_cta_text": "Har du en fråga om det här ärendet?",
    "tickets_have_a_question": "Har du en fråga om det här problemet?",
    "tickets_space_title": "Ärenden",
    "time_future_1h": "om 1 timme",
    "time_future_2h": "om 2 timmar",
    "time_future_30m": "om 30 minuter",
    "time_future_3h": "om 3 timmar",
    "time_future_day_0": "på måndag",
    "time_future_day_1": "på tisdag",
    "time_future_day_2": "på onsdag",
    "time_future_day_3": "på torsdag",
    "time_future_day_4": "på fredag",
    "time_future_day_5": "på lördag",
    "time_future_day_6": "på söndag",
    "time_future_later_today": "senare idag",
    "time_future_next_week": "nästa vecka",
    "time_future_tomorrow": "imorgon",
    "try_use_different_keywords": "Prova med andra nyckelord eller skriv in hela din fråga",
    "upfront_collection_form_close_out": "Du kan komma tillbaka hit när som helst för att se svar och skicka uppdateringar.",
    "upfront_collection_form_introduction": "Berätta lite mer så att teamet kan skicka ett snabbare och bättre svar.",
    "upfront_collection_form_submission_confirmation": "Tack för den informationen. Du får gärna lägga till mer information nu för att spara tid senare.",
    "upfront_collection_form_subtitle": "Skicka lite information till teamet",
    "upfront_collection_form_subtitle_submitted": "De kommer att ha den här informationen i åtanke",
    "upfront_collection_form_title": "Få hjälp snabbare",
    "upfront_collection_form_title_submitted": "Skickat till teamet!",
    "view_all": "Visa alla",
    "view_conversation": "Visa konversation",
    "we_are_here_to_help": "Vi finns här för att hjälpa till",
    "we_run_on_intercom": "Vi använder Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "När en teammedlem ändrar status för en konversation...",
    "working_on_it": "Jobbar på det ...",
    "you_are_all_up_to_date": "Du har kommit ikapp!",
    "your_checklists": "Dina checklistor",
    "your_conversations": "Dina konversationer",
    "your_recent_conversations": "Senaste konversationerna",
    "your_recent_messages": "Senaste meddelanden"
  },
  "sw": {
    "a_day": "Siku",
    "a_few_hours": "Saa chache",
    "a_few_minutes": "Dakika chache",
    "a_week": "Kwa wiki",
    "active_state": "Anatumia",
    "ai_answer": "Majibu ya AI",
    "ai_answer_information": "Jibu la AI, taarifa",
    "ai_bot": "Boti ya AI",
    "almost_there": "Karibu tufikie...",
    "answer": "Jibu",
    "answer_bot_acknowledge_duplicate_content": "Tayari umeona hili, lakini hili ndilo jibu bora zaidi linalopatikana.",
    "answer_bot_all_done": "Hiyo ilisaidia 👍",
    "answer_bot_ask_another_question": "Uliza swali lingine 🔁",
    "answer_bot_ask_something_else": "Uliza jambo lingine 🔁",
    "answer_bot_connect_to_an_agent": "Zungumza na wakala 👤",
    "answer_bot_did_that_help": "Je, jibu hilo lilisaidia, au unatafuta kitu kingine?",
    "answer_bot_followup_expectations_prefix": "Timu itarudi kwako kuhusu hili.",
    "answer_bot_get_more_help": "Pata msaada zaidi 🌐",
    "answer_bot_get_more_help_followup": "Kiungo hiki kinaweza kusaidia:",
    "answer_bot_i_have_another_question": "Nina swali lingine 🔁",
    "answer_bot_im_not_done_yet": "Bado sijamaliza 🔁",
    "answer_bot_keep_waiting": "Hapana, bado nahitaji usaidizi 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Unaweza kutaja swali lako upya, au kupata usaidizi wa ziada",
    "answer_bot_looping_first_no_answer": "Samahani, kama bot sikuweza kupata jibu la hilo.",
    "answer_bot_looping_first_no_answer_followup": "Unaweza kutaja swali lako upya, au kuzungumza na timu yetu",
    "answer_bot_looping_no_answer": "Samahani, kama bot sikuweza kupata jibu la hilo. Hivi ndivyo unavyoweza kufanya",
    "answer_bot_more_answers": "Nionyeshe zaidi 👀",
    "answer_bot_no_answer_found": "Samahani, hatuna jibu la haraka kwa hilo. Hapa ni baadhi ya chaguzi nyingine.",
    "answer_bot_resolve_followup": "Unaweza kujibu hapa kila wakati, ikiwa jambo lingine litatokea.",
    "answer_bot_talk_to_person": "Zungumza na mtu 👤",
    "answer_bot_that_answered_my_question": "Hiyo ilijibu swali langu 👍",
    "answer_bot_thats_it": "Hiyo ni 👍",
    "answer_bot_wait_for_the_team": "Subiri timu 💬",
    "answerbot_ask_for_more_context": "Bila shaka. Tunaposubiri, unaweza kushiriki maelezo zaidi kuhusu hali au swali lako? Itatusaidia kupata jibu kwa haraka zaidi, na huenda nikakutafutia maelezo zaidi.",
    "answerbot_introduce_sneaky_answer": "Asante kwa muktadha huo wa ziada. Nimepata maelezo mapya ambayo yanaweza kukusaidia.",
    "article_search_hint_text": "Makala ya utafutaji...",
    "ask_a_question": "Uliza swali",
    "ask_a_question_placeholder": "Uliza swali...",
    "ask_a_question_with_ellipsis": "Uliza swali...",
    "ask_another_question_with_ellipsis": "Uliza swali lingine...",
    "ask_for_the_team_if_needed": "Uliziaa timu ikiwa inahitajika",
    "ask_me_a_question": "Niulize swali",
    "ask_our_bot_a_question": "Uliza bot yetu swali",
    "avatar": "Avatar",
    "avatars": "Avatars",
    "bot": "Bot",
    "bot_answers_instantly": "Bot anajibu haraka",
    "bot_expectation_text": "Bot yetu inaweza kujibu maswali mengi ya kawaida",
    "bot_intro_default_part_1": "Mambo vipi! Ninayezungumza ni bot. Niko hapa kujibu maswali yako, lakini unaweza pia kuamua wakati wowote kuzungumza na timu yetu.",
    "bot_intro_default_part_2": "Ungependa kufanya nini leo?",
    "bot_is_still_thinking": "Bot bado inafikiria ...",
    "bot_is_thinking": "Bot anafikiria...",
    "browse_collections": "Vinjari makusanyo",
    "chat_with_us": "Zungumza na sisi",
    "check_later_for_updates": "Angalia tena baadaye kwa sasisho.",
    "checklist_card_first_step": "Hatua ya kwanza",
    "checklist_card_next_step": "Hatua inayofuata",
    "checklist_completed": "Kukamilisha",
    "checklist_in_progress_time_remaining_plural": "Takriban dakika {minutes} zimesalia",
    "checklist_in_progress_time_remaining_singular": "Karibu dakika 1 imebaki",
    "checklist_mark_step_as_complete": "Weka alama kama ilivyokamilika",
    "checklist_not_started_time_remaining_plural": "Kuhusu {minutes} dakika",
    "checklist_not_started_time_remaining_singular": "Kwa dakika 1",
    "checklist_number_of_steps_plural": "Hatua{number_of_steps}",
    "checklist_number_of_steps_progress_plural": "{steps_completed} ya {total_steps} kufanyika",
    "checklist_number_of_steps_progress_singular": "1 ya {total_steps} kufanyika",
    "checklist_number_of_steps_singular": "Hatua ya 1",
    "checklist_sender_name": "Imeshirikiwa na {name}",
    "checklist_step_action_button": "Kitufe cha hatua ya hatua",
    "checklist_step_completed": "Kukamilisha",
    "contact_support": "Wasiliana na usaidizi",
    "contact_us": "Wasiliana nasi",
    "continue_the_conversation": "Endelea na mazungumzo",
    "conversation_card_office_hours_header": "Tutarudi mtandaoni",
    "conversation_card_reply_time_header": "Wakati wetu wa kawaida wa kujibu",
    "conversation_card_reply_time_header_m5": "Muda wa kawaida wa kujibu",
    "conversation_card_unknown_response_time": "Tutajibu haraka iwezekanavyo",
    "conversation_part_sample_text": "Habari, ninawezaje kusaidia? Utakuwa na chaguo la kuzungumza na timu yetu kila wakati ikihitajika.",
    "conversations_space_title": "Mazungumzo",
    "create_a_ticket": "Unda tiketi",
    "currently_no_help_articles": "Kwa sasa hakuna makala ya usaidizi yanayopatikana.",
    "customer_sends_their_first_message": "Mteja anatuma ujumbe wake wa kwanza...",
    "expected_response_delay_10800": "Chini ya saa 3",
    "expected_response_delay_120": "Chini ya dakika 2",
    "expected_response_delay_1200": "Chini ya dakika 20",
    "expected_response_delay_14400": "Chini ya saa 4",
    "expected_response_delay_172800": "Siku ya 2",
    "expected_response_delay_180": "Chini ya dakika 3",
    "expected_response_delay_1800": "Chini ya dakika 30",
    "expected_response_delay_18000": "Chini ya saa 5",
    "expected_response_delay_21600": "Chini ya saa 6",
    "expected_response_delay_240": "Chini ya dakika 4",
    "expected_response_delay_259200": "Siku 3",
    "expected_response_delay_300": "Chini ya dakika 5",
    "expected_response_delay_345600": "Siku 4",
    "expected_response_delay_3600": "Chini ya saa 1",
    "expected_response_delay_43200": "Chini ya saa 12",
    "expected_response_delay_432000": "Siku 5",
    "expected_response_delay_518400": "Siku 6",
    "expected_response_delay_60": "Chini ya dakika 1",
    "expected_response_delay_600": "Chini ya dakika 10",
    "expected_response_delay_604800": "Chini ya wiki 1",
    "expected_response_delay_7200": "Chini ya saa 2",
    "expected_response_delay_86400": "Siku ya 1",
    "expected_response_delay_900": "Chini ya dakika 15",
    "expected_response_delay_unknown": "Haraka iwezekanavyo",
    "extra_labels_count": "+ {labels_count} lebo",
    "for_best_results_provide_detail": "Kwa matokeo bora, toa maelezo mengi iwezekanavyo",
    "generating_answer": "Inazalisha jibu...",
    "generic_triage_default": "Tunahitaji tu maelezo zaidi ili kukuunganisha na mtu anayefaa",
    "get_an_instant_answer": "Pata jibu la papo hapo",
    "get_help": "Pata msaada",
    "go_to": "Nenda kwenye {app_name}",
    "help_space_title": "Msaada",
    "home_header_mobile_hello_firstname": "Habari, {firstname}.",
    "home_header_mobile_hello_there": "Habari",
    "home_space_title": "Nyumbani",
    "how_can_we_help": "Tunawezaje kusaidia?",
    "how_would_you_rate_this": "Unawezaje kupima hii?",
    "learn_more": "Jifunze zaidi",
    "message_placeholder": "Ujumbe…",
    "messages_space_title": "Ujumbe",
    "messenger_bot_reply_time": "Kijibu chetu kitajibu papo hapo",
    "messenger_settings_introduction": "Tunawezaje kusaidia?",
    "multiple_articles": "makala {total_articles}",
    "multiple_collections": "makusanyiko {total_collection}",
    "new_conversation": "Mazungumzo mapya",
    "news": "Habari",
    "news_empty_title": "Hakuna habari kwa sasa",
    "news_latest_subtitle": "Kutoka Timu ya {team_name}",
    "news_latest_title": "Ya karibuni",
    "news_older_title": "Zamani",
    "news_space_title": "Habari",
    "newsfeed": "Habari",
    "no_articles_to_display": "Bado hakuna makala",
    "no_help_articles_to_display": "Hakuna nakala ya msaada",
    "no_results_for_searchterm": "Hakuna matokeo ya {searchTerm}",
    "no_tasks": "Hakuna kazi",
    "no_tasks_available_currently": "Kwa sasa hakuna kazi zinazopatikana.",
    "ok_the_team_typically_replies_in_day": "Sawa, kwa kawaida timu hujibu baada ya siku moja.",
    "ok_the_team_typically_replies_in_hours": "Sawa, kwa kawaida timu hujibu baada ya saa chache.",
    "ok_the_team_typically_replies_in_minutes": "Sawa, kwa kawaida timu hujibu baada ya dakika chache.",
    "ok_the_team_typically_replies_in_under_n_hours": "Sawa, kwa kawaida timu hujibu baada ya saa {hours}.",
    "ok_the_team_typically_replies_in_under_n_min": "Sawa, kwa kawaida timu hujibu kwa chini ya mita {minutes}.",
    "ok_the_team_typically_replies_in_week": "Sawa, kwa kawaida timu hujibu baada ya wiki moja.",
    "our_bot_and_team_can_help": "Bot na timu yetu inaweza kusaidia",
    "our_bot_answers_instantly": "Majibu yetu ya bot mara moja",
    "powered_by_intercom": "Inaendeshwa na Intercom",
    "previous_bot_hands_over": "Maudhui kutoka kwenye kijibu kilichotangulia...",
    "proactive_expected_response_delay_10800": "Kwa kawaida hujibu chini ya saa 3",
    "proactive_expected_response_delay_120": "Kwa kawaida hujibu chini ya dakika 2",
    "proactive_expected_response_delay_1200": "Kwa kawaida hujibu chini ya dakika 20",
    "proactive_expected_response_delay_14400": "Kwa kawaida hujibu chini ya saa 4",
    "proactive_expected_response_delay_172800": "Majibu ya kawaida ndani ya siku 2",
    "proactive_expected_response_delay_180": "Kwa kawaida hujibu chini ya dakika 3",
    "proactive_expected_response_delay_1800": "Kwa kawaida hujibu chini ya dakika 30",
    "proactive_expected_response_delay_18000": "Kwa kawaida hujibu chini ya saa 5",
    "proactive_expected_response_delay_21600": "Kwa kawaida hujibu chini ya saa 6",
    "proactive_expected_response_delay_240": "Kwa kawaida hujibu chini ya dakika 4",
    "proactive_expected_response_delay_259200": "Kwa kawaida hujibu ndani ya siku 3",
    "proactive_expected_response_delay_300": "Kwa kawaida hujibu chini ya dakika 5",
    "proactive_expected_response_delay_345600": "Kwa kawaida hujibu ndani ya siku 4",
    "proactive_expected_response_delay_3600": "Kwa kawaida hujibu chini ya saa 1",
    "proactive_expected_response_delay_43200": "Kwa kawaida hujibu ndani ya saa 12",
    "proactive_expected_response_delay_432000": "Kwa kawaida hujibu ndani ya siku 5",
    "proactive_expected_response_delay_518400": "Kwa kawaida hujibu ndani ya siku 6",
    "proactive_expected_response_delay_60": "Kwa kawaida hujibu chini ya dakika 1",
    "proactive_expected_response_delay_600": "Kwa kawaida hujibu chini ya dakika 10",
    "proactive_expected_response_delay_604800": "Kwa kawaida hujibu chini ya wiki 1",
    "proactive_expected_response_delay_7200": "Kwa kawaida hujibu chini ya saa 2",
    "proactive_expected_response_delay_86400": "Kwa kawaida hujibu chini ya siku 1",
    "proactive_expected_response_delay_900": "Kwa kawaida hujibu chini ya dakika 15",
    "proactive_expected_response_delay_unknown": "Watajibu haraka iwezekanavyo",
    "profile_location": "{time} katika {location}",
    "recent_ticket": "Tiketi ya hivi karibuni",
    "recent_tickets": "Tiketi za hivi karibuni",
    "search_browse_empty_state_text": "Samahani, hatukuweza kupata matokeo yoyote kwa hilo. Jaribu kubadilisha utafutaji wako.",
    "search_for_help": "Tafuta msaada",
    "see_all": "Tazama yote",
    "see_all_conversations": "Angalia mazungumzo yako yote",
    "see_previous_conversations": "Tazama mazungumzo yako ya awali",
    "send": "Tuma",
    "send_a_message": "Tuma ujumbe...",
    "send_a_message_reply_time": "Muda wa kujibu wa kawaida ni",
    "send_us_a_message": "Tutumie ujumbe",
    "set_expectations_bot_email_header": "Utapata majibu hapa na katika barua pepe yako:",
    "set_expectations_bot_office_hours_header": "Timu itarudi",
    "set_expectations_bot_sms_header": "Utapata majibu hapa na kwa SMS:",
    "set_expectations_replies_to_email": "Utapata majibu hapa na kwa <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Utapata majibu hapa na kwa {user_email}",
    "set_expectations_team_reply_asap": "Timu itajibu haraka iwezekanavyo.",
    "shared_by": "Imeshirikiwa na",
    "show_less": "Onyesha chini",
    "single_article": "makala 1",
    "single_collection": "Mkusanyiko wa 1",
    "source": "Chanzo",
    "sources": "Vyanzo",
    "start_a_conversation": "Anzisha mazungumzo",
    "start_conversation": "Anzisha mazungumzo...",
    "still_generating_answer": "Bado inaleta jibu...",
    "still_thinking": "Bado inafikiri...",
    "still_working_on_it": "Bado ninaifanyia kazi...",
    "tasks_space_title": "Kazi",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} itajibu haraka iwezekanavyo",
    "teammate_changes_team_assignment_of_a_conversation": "Mshiriki wa timu amebadilisha kazi ya mazungumzo...",
    "tell_us_what_you_need": "Tuambie unachohitaji usaidizi nacho na mfumo wetu wa bot utafanya iwezavyo kujibu",
    "the_team_can_help_if_needed": "Timu inaweza kusaidia ikiwa inahitajika",
    "the_team_typically_replies_in_10800": "Timu kwa kawaida hujibu chini ya saa 3",
    "the_team_typically_replies_in_120": "Timu kwa kawaida hujibu chini ya dakika 2",
    "the_team_typically_replies_in_1200": "Timu kwa kawaida hujibu chini ya dakika 20",
    "the_team_typically_replies_in_14400": "Timu kwa kawaida hujibu chini ya saa 4",
    "the_team_typically_replies_in_172800": "Timu kawaida hujibu ndani ya siku 2",
    "the_team_typically_replies_in_180": "Timu kwa kawaida hujibu chini ya dakika 3",
    "the_team_typically_replies_in_1800": "Timu kwa kawaida hujibu chini ya dakika 30",
    "the_team_typically_replies_in_18000": "Timu kwa kawaida hujibu chini ya saa 5",
    "the_team_typically_replies_in_21600": "Timu kwa kawaida hujibu chini ya saa 6",
    "the_team_typically_replies_in_240": "Timu kwa kawaida hujibu chini ya dakika 4",
    "the_team_typically_replies_in_259200": "Timu kwa kawaida hujibu ndani ya siku 3",
    "the_team_typically_replies_in_300": "Timu kwa kawaida hujibu chini ya dakika 5",
    "the_team_typically_replies_in_345600": "Timu kwa kawaida hujibu ndani ya siku 4",
    "the_team_typically_replies_in_3600": "Timu kwa kawaida hujibu chini ya saa 1",
    "the_team_typically_replies_in_43200": "Timu kwa kawaida hujibu chini ya saa 12",
    "the_team_typically_replies_in_432000": "Timu kwa kawaida hujibu ndani ya siku 5",
    "the_team_typically_replies_in_518400": "Timu kwa kawaida hujibu ndani ya siku 6",
    "the_team_typically_replies_in_60": "Timu kwa kawaida hujibu chini ya dakika 1",
    "the_team_typically_replies_in_600": "Timu kwa kawaida hujibu chini ya dakika 10",
    "the_team_typically_replies_in_604800": "Timu kwa kawaida hujibu chini ya wiki 1",
    "the_team_typically_replies_in_7200": "Timu kwa kawaida hujibu chini ya saa 2",
    "the_team_typically_replies_in_86400": "Timu kwa kawaida hujibu ndani ya siku 1",
    "the_team_typically_replies_in_900": "Timu kwa kawaida hujibu chini ya dakika 15",
    "the_team_typically_replies_in_day": "Timu kwa kawaida hujibu ndani ya siku moja.",
    "the_team_typically_replies_in_hours": "Timu kwa kawaida hujibu baada ya saa chache.",
    "the_team_typically_replies_in_minutes": "Timu kwa kawaida hujibu baada ya dakika chache.",
    "the_team_typically_replies_in_under_n_hours": "Timu kwa kawaida hujibu kwa chini ya saa {hours}.",
    "the_team_typically_replies_in_under_n_min": "Timu kwa kawaida hujibu kwa chini ya dakika {minutes}.",
    "the_team_typically_replies_in_unknown": "Timu itajibu haraka iwezekanavyo",
    "the_team_typically_replies_in_week": "Timu kwa kawaida hujibu baada ya wiki.",
    "thinking": "Inafikiri...",
    "tickets_cta_text": "Je, una swali kuhusu tiketi hii?",
    "tickets_have_a_question": "Je, una swali kuhusu suala hili?",
    "tickets_space_title": "Tiketi",
    "time_future_1h": "Ndani ya saa 1",
    "time_future_2h": "Ndani ya saa 2",
    "time_future_30m": "Ndani ya dakika 30",
    "time_future_3h": "Ndani ya saa 3",
    "time_future_day_0": "Jumatatu",
    "time_future_day_1": "Jumanne",
    "time_future_day_2": "Jumatano",
    "time_future_day_3": "Alhamisi",
    "time_future_day_4": "Ijumaa",
    "time_future_day_5": "Jumamosi",
    "time_future_day_6": "Jumapili",
    "time_future_later_today": "Baadaye leo",
    "time_future_next_week": "Wiki ijayo",
    "time_future_tomorrow": "Kesho",
    "try_use_different_keywords": "Jaribu maneno ya msingi tofauti au weka swali lako kamili",
    "upfront_collection_form_close_out": "Unaweza kurudi hapa wakati wowote kuona majibu na kutuma sasisho.",
    "upfront_collection_form_introduction": "Shiriki baadhi ya muktadha ili kusaidia timu kujibu vyema na haraka.",
    "upfront_collection_form_submission_confirmation": "Asante kwa kutuma muktadha huo. Ili kuokoa muda baadaye, jisikie huru kuongeza maelezo zaidi sasa.",
    "upfront_collection_form_subtitle": "Tuma timu baadhi ya muktadha",
    "upfront_collection_form_subtitle_submitted": "Wataweka muktadha huu akilini",
    "upfront_collection_form_title": "Pata msaada haraka",
    "upfront_collection_form_title_submitted": "Imetumwa kwa timu!",
    "view_all": "Tazama yote",
    "view_conversation": "Tazama mazungumzo",
    "we_are_here_to_help": "Tuko hapa kukusaidia",
    "we_run_on_intercom": "Tunatumia Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Mwenzake anapobadilisha hali ya mazungumzo...",
    "working_on_it": "Ninaifanyia kazi...",
    "you_are_all_up_to_date": "Umeona kila kitu!",
    "your_checklists": "Orodha yako ya ukaguzi",
    "your_conversations": "Mazungumzo yako",
    "your_recent_conversations": "Mazungumzo ya hivi karibuni",
    "your_recent_messages": "Ujumbe wa hivi karibuni"
  },
  "th": {
    "a_day": "หนึ่งวัน",
    "a_few_hours": "สองถึงสามชั่วโมง",
    "a_few_minutes": "สองถึงสามนาที",
    "a_week": "สัปดาห์",
    "active_state": "ใช้งานอยู่",
    "ai_answer": "คําตอบจาก AI",
    "ai_answer_information": "คำตอบจาก AI, ข้อมูล",
    "ai_bot": "บอท AI",
    "almost_there": "ใกล้จะเสร็จแล้ว...",
    "answer": "คำตอบ",
    "answer_bot_acknowledge_duplicate_content": "คุณได้เห็นสิ่งนี้แล้ว แต่นี่เป็นคำตอบที่ดีที่สุด",
    "answer_bot_all_done": "มีประโยชน์ 👍",
    "answer_bot_ask_another_question": "สอบถามเพิ่มเติม 🔁",
    "answer_bot_ask_something_else": "ถามอย่างอื่น 🔁",
    "answer_bot_connect_to_an_agent": "เชื่อมต่อกับตัวแทน 👤",
    "answer_bot_did_that_help": "คำตอบดังกล่าวมีประโยชน์หรือคุณกำลังมองหาคำตอบอื่นอยู่",
    "answer_bot_followup_expectations_prefix": "ทีมจะติดต่อกลับหาคุณในเรื่องนี้",
    "answer_bot_get_more_help": "รับความช่วยเหลือเพิ่มเติม 🌐",
    "answer_bot_get_more_help_followup": "ลิงก์นี้อาจมีประโยชน์:",
    "answer_bot_i_have_another_question": "มีคำถามอื่น 🔁",
    "answer_bot_im_not_done_yet": "ยังไม่เสร็จ 🔁",
    "answer_bot_keep_waiting": "ไม่ ฉันยังต้องการความช่วยเหลือ 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "คุณสามารถเรียบเรียงคำถามของคุณใหม่หรือรับความช่วยเหลือเพิ่มเติม",
    "answer_bot_looping_first_no_answer": "ขออภัย บ็อตไม่สามารถหาคำตอบได้",
    "answer_bot_looping_first_no_answer_followup": "คุณสามารถเรียบเรียงคำถามใหม่หรือพูดคุยกับทีมของเรา",
    "answer_bot_looping_no_answer": "ขออภัย บ็อตไม่สามารถหาคำตอบได้ ต่อไปนี้คือสิ่งที่คุณดำเนินการได้",
    "answer_bot_more_answers": "แสดงเพิ่มเติม 👀",
    "answer_bot_no_answer_found": "ขออภัย เราไม่มีคำตอบด่วนสำหรับคำถามดังกล่าว นี่คือตัวเลือกอื่น",
    "answer_bot_resolve_followup": "คุณสามารถตอบกลับที่นี่ได้ตลอดเวลา หากมีอะไรเกิดขึ้นอีก",
    "answer_bot_talk_to_person": "พูดคุยกับพนักงาน 👤",
    "answer_bot_that_answered_my_question": "ฉันได้รับคำตอบแล้ว 👍",
    "answer_bot_thats_it": "เยี่ยมเลย 👍",
    "answer_bot_wait_for_the_team": "โปรดรอทีม 💬",
    "answerbot_ask_for_more_context": "แน่นอน ในขณะที่เรารอเพื่อนร่วมทีม โปรดให้รายละเอียดเพิ่มเติมเกี่ยวกับสถานการณ์หรือคำถามของคุณ เพื่อให้เราช่วยคุณได้เร็วขึ้น และเราอาจค้นหาข้อมูลเพิ่มเติมสำหรับคุณ",
    "answerbot_introduce_sneaky_answer": "ขอขอบคุณที่ให้ข้อมูลเพิ่มเติม เราพบข้อมูลใหม่บางอย่างที่อาจช่วยคุณได้",
    "article_search_hint_text": "ค้นหาบทความ...",
    "ask_a_question": "ถามคําถาม",
    "ask_a_question_placeholder": "ถามคําถาม...",
    "ask_a_question_with_ellipsis": "ถามคำถาม...",
    "ask_another_question_with_ellipsis": "ถามคำถามอื่น...",
    "ask_for_the_team_if_needed": "สอบถามทีมงานหากจำเป็น",
    "ask_me_a_question": "ถามคําถามเลย",
    "ask_our_bot_a_question": "ถามบอทของเรา",
    "avatar": "อวาตาร์",
    "avatars": "อวาตาร์",
    "bot": "บอท",
    "bot_answers_instantly": "บอทตอบทันที",
    "bot_expectation_text": "บอทของเราสามารถตอบคำถามทั่วไปได้หลายข้อ",
    "bot_intro_default_part_1": "สวัสดี นี่คือบอท ที่จะช่วยตอบคำถามของคุณ แต่คุณจะมีตัวเลือกในการพูดคุยกับทีมของเราเสมอ",
    "bot_intro_default_part_2": "คุณมีอะไรให้ช่วยเหลือไหมวันนี้",
    "bot_is_still_thinking": "บอทยังคิดอยู่...",
    "bot_is_thinking": "บอทกำลังคิดว่า...",
    "browse_collections": "เรียกดูคอลเลกชัน",
    "chat_with_us": "แชทกับเรา",
    "check_later_for_updates": "โปรดกลับมาตรวจสอบการอัปเดตในภายหลัง",
    "checklist_card_first_step": "ขั้นตอนแรก",
    "checklist_card_next_step": "ขั้นตอนถัดไป",
    "checklist_completed": "เสร็จสมบูรณ์",
    "checklist_in_progress_time_remaining_plural": "เหลือเวลาประมาณ {minutes} นาที",
    "checklist_in_progress_time_remaining_singular": "เหลืออีกประมาณ 1 นาที",
    "checklist_mark_step_as_complete": "ทําเครื่องหมายว่าเสร็จสมบูรณ์",
    "checklist_not_started_time_remaining_plural": "ประมาณ {minutes} นาที",
    "checklist_not_started_time_remaining_singular": "ประมาณ 1 นาที",
    "checklist_number_of_steps_plural": "{number_of_steps} ขั้นตอน",
    "checklist_number_of_steps_progress_plural": "{steps_completed} จาก {total_steps} ขั้นตอนที่เสร็จสิ้น",
    "checklist_number_of_steps_progress_singular": "1 จาก {total_steps} ขั้นตอนที่เสร็จสิ้น",
    "checklist_number_of_steps_singular": "1 ขั้นตอน",
    "checklist_sender_name": "แชร์โดย {name}",
    "checklist_step_action_button": "ปุ่มการดำเนินการสำหรับขั้นตอน",
    "checklist_step_completed": "เสร็จสมบูรณ์",
    "contact_support": "ติดต่อฝ่ายสนับสนุน",
    "contact_us": "ติดต่อเรา",
    "continue_the_conversation": "สนทนาต่อ",
    "conversation_card_office_hours_header": "เราจะกลับมาออนไลน์",
    "conversation_card_reply_time_header": "เวลาตอบกลับปกติของเรา",
    "conversation_card_reply_time_header_m5": "เวลาตอบกลับปกติ",
    "conversation_card_unknown_response_time": "เราจะตอบกลับโดยเร็วที่สุด",
    "conversation_part_sample_text": "สวัสดี มีอะไรให้้เราช่วย คุณจะมีตัวเลือกในการพูดคุยกับทีมของเราเสมอหากต้องการ",
    "conversations_space_title": "การสนทนา",
    "create_a_ticket": "สร้างทิกเก็ต",
    "currently_no_help_articles": "ขณะนี้ยังไม่มีบทความช่วยเหลือ",
    "customer_sends_their_first_message": "ลูกค้าได้ส่งข้อความแรก...",
    "expected_response_delay_10800": "ไม่เกิน 3 ชั่วโมง",
    "expected_response_delay_120": "ไม่เกิน 2 นาที",
    "expected_response_delay_1200": "ไม่เกิน 20 นาที",
    "expected_response_delay_14400": "ไม่เกิน 4 ชั่วโมง",
    "expected_response_delay_172800": "2 วัน",
    "expected_response_delay_180": "ไม่เกิน 3 นาที",
    "expected_response_delay_1800": "ไม่เกิน 30 นาที",
    "expected_response_delay_18000": "ไม่เกิน 5 ชั่วโมง",
    "expected_response_delay_21600": "ไม่เกิน 6 ชั่วโมง",
    "expected_response_delay_240": "ไม่เกิน 4 นาที",
    "expected_response_delay_259200": "3 วัน",
    "expected_response_delay_300": "ไม่เกิน 5 นาที",
    "expected_response_delay_345600": "4 วัน",
    "expected_response_delay_3600": "ไม่เกิน 1 ชั่วโมง",
    "expected_response_delay_43200": "ไม่เกิน 12 ชั่วโมง",
    "expected_response_delay_432000": "5 วัน",
    "expected_response_delay_518400": "6 วัน",
    "expected_response_delay_60": "ไม่เกิน 1 นาที",
    "expected_response_delay_600": "ไม่เกิน 10 นาที",
    "expected_response_delay_604800": "ไม่เกิน 1 สัปดาห์",
    "expected_response_delay_7200": "ไม่เกิน 2 ชั่วโมง",
    "expected_response_delay_86400": "1 วัน",
    "expected_response_delay_900": "ไม่เกิน 15 นาที",
    "expected_response_delay_unknown": "โดยเร็วที่สุด",
    "extra_labels_count": "+{labels_count} ป้ายกํากับ",
    "for_best_results_provide_detail": "เพื่อให้ได้ผลลัพธ์ที่ดีที่สุด โปรดระบุรายละเอียดให้มากที่สุด",
    "generating_answer": "กำลังสร้างคำตอบ...",
    "generic_triage_default": "เราต้องการข้อมูลเพิ่มเติมเล็กน้อยเพื่อเชื่อมโยงคุณกับบุคคลที่เหมาะสม",
    "get_an_instant_answer": "รับคำตอบทันที",
    "get_help": "รับความช่วยเหลือ",
    "go_to": "ไปที่ {app_name}",
    "help_space_title": "ความช่วยเหลือ",
    "home_header_mobile_hello_firstname": "สวัสดี {firstname}",
    "home_header_mobile_hello_there": "สวัสดี",
    "home_space_title": "หน้าหลัก",
    "how_can_we_help": "เราจะช่วยคุณได้อย่างไร",
    "how_would_you_rate_this": "คุณจะให้คะแนนอย่างไร",
    "learn_more": "เรียนรู้เพิ่มเติม",
    "message_placeholder": "ข้อความ…",
    "messages_space_title": "ข้อความ",
    "messenger_bot_reply_time": "บ็อตของเราจะตอบกลับทันที",
    "messenger_settings_introduction": "เราจะช่วยคุณได้อย่างไร",
    "multiple_articles": "{total_articles} บทความ",
    "multiple_collections": "{total_collection} คอลเลกชัน",
    "new_conversation": "การสนทนาใหม่",
    "news": "ข่าวสาร",
    "news_empty_title": "ยังไม่มีข่าว",
    "news_latest_subtitle": "จากทีม {team_name}",
    "news_latest_title": "ล่าสุด",
    "news_older_title": "เก่ากว่า",
    "news_space_title": "ข่าวสาร",
    "newsfeed": "ฟีดข่าว",
    "no_articles_to_display": "ยังไม่มีบทความ",
    "no_help_articles_to_display": "ไม่มีบทความช่วยเหลือ",
    "no_results_for_searchterm": "ไม่มีผลลัพธ์สําหรับ {searchTerm}",
    "no_tasks": "ไม่มีงาน",
    "no_tasks_available_currently": "ขณะนี้ยังไม่มีงาน",
    "ok_the_team_typically_replies_in_day": "โปรดทราบว่าโดยทั่วไปแล้วทีมจะตอบกลับภายในหนึ่งวัน",
    "ok_the_team_typically_replies_in_hours": "โปรดทราบว่าโดยทั่วไปแล้วทีมจะตอบกลับภายในสองถึงสามชั่วโมง",
    "ok_the_team_typically_replies_in_minutes": "โปรดทราบว่าโดยทั่วไปแล้วทีมจะตอบกลับภายในสองถึงสามนาที",
    "ok_the_team_typically_replies_in_under_n_hours": "โปรดทราบว่าโดยทั่วไปแล้วทีมจะตอบกลับภายใน {hours} ชั่วโมง",
    "ok_the_team_typically_replies_in_under_n_min": "โปรดทราบว่าโดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน {minutes} นาที",
    "ok_the_team_typically_replies_in_week": "โปรดทราบว่าโดยทั่วไปแล้วทีมจะตอบกลับภายในหนึ่งสัปดาห์",
    "our_bot_and_team_can_help": "บอทและทีมงานของเราช่วยคุณได้",
    "our_bot_answers_instantly": "บ็อตของเราจะตอบทันที",
    "powered_by_intercom": "ขับเคลื่อนโดย Intercom",
    "previous_bot_hands_over": "เนื้อหาจากบอทก่อนหน้า...",
    "proactive_expected_response_delay_10800": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 3 ชั่วโมง",
    "proactive_expected_response_delay_120": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 2 นาที",
    "proactive_expected_response_delay_1200": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 20 นาที",
    "proactive_expected_response_delay_14400": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 4 ชั่วโมง",
    "proactive_expected_response_delay_172800": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 2 วัน",
    "proactive_expected_response_delay_180": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 3 นาที",
    "proactive_expected_response_delay_1800": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 30 นาที",
    "proactive_expected_response_delay_18000": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 5 ชั่วโมง",
    "proactive_expected_response_delay_21600": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 6 ชั่วโมง",
    "proactive_expected_response_delay_240": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 4 นาที",
    "proactive_expected_response_delay_259200": "โดยทั่วไปแล้วจะตอบกลับภายใน 3 วัน",
    "proactive_expected_response_delay_300": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 5 นาที",
    "proactive_expected_response_delay_345600": "โดยทั่วไปแล้วจะตอบกลับภายใน 4 วัน",
    "proactive_expected_response_delay_3600": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 1 ชั่วโมง",
    "proactive_expected_response_delay_43200": "โดยทั่วไปแล้วจะตอบกลับภายใน 12 ชม",
    "proactive_expected_response_delay_432000": "โดยทั่วไปแล้วจะตอบกลับภายใน 5 วัน",
    "proactive_expected_response_delay_518400": "โดยทั่วไปแล้วจะตอบกลับภายใน 6 วัน",
    "proactive_expected_response_delay_60": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 1 นาที",
    "proactive_expected_response_delay_600": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 10 นาที",
    "proactive_expected_response_delay_604800": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 1 สัปดาห์",
    "proactive_expected_response_delay_7200": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 2 ชั่วโมง",
    "proactive_expected_response_delay_86400": "โดยปกติจะตอบกลับภายในเวลาไม่เกิน 1 วัน",
    "proactive_expected_response_delay_900": "โดยทั่วไปแล้วจะตอบกลับภายในเวลาไม่เกิน 15 นาที",
    "proactive_expected_response_delay_unknown": "จะตอบโดยเร็วที่สุด",
    "profile_location": "{time} ใน {location}",
    "recent_ticket": "ทิกเก็ตล่าสุด",
    "recent_tickets": "ทิกเก็ตล่าสุด",
    "search_browse_empty_state_text": "ขออภัย เราไม่พบผลลัพธ์สำหรับสิ่งนั้น ลองเขียนคําค้นหาของคุณใหม่",
    "search_for_help": "ค้นหาความช่วยเหลือ",
    "see_all": "ดูทั้งหมด",
    "see_all_conversations": "ดูการสนทนาทั้งหมดของคุณ",
    "see_previous_conversations": "ดูการสนทนาก่อนหน้าของคุณ",
    "send": "ส่ง",
    "send_a_message": "ส่งข้อความ...",
    "send_a_message_reply_time": "เวลาตอบกลับปกติคือ",
    "send_us_a_message": "ส่งข้อความถึงเรา",
    "set_expectations_bot_email_header": "คุณจะได้รับการตอบกลับที่นี่และในอีเมลของคุณ:",
    "set_expectations_bot_office_hours_header": "ทีมงานจะกลับมา",
    "set_expectations_bot_sms_header": "คุณจะได้รับการตอบกลับที่นี่และทาง SMS:",
    "set_expectations_replies_to_email": "คุณจะได้รับการตอบกลับที่นี่และที่ <b>{user_email}</b>",
    "set_expectations_replies_to_email_without_bold_text": "คุณจะได้รับการตอบกลับที่นี่และที่ {user_email}",
    "set_expectations_team_reply_asap": "ทีมจะตอบกลับโดยเร็วที่สุด",
    "shared_by": "แชร์โดย",
    "show_less": "แสดงน้อยลง",
    "single_article": "1 บทความ",
    "single_collection": "1 คอลเลกชัน",
    "source": "แหล่งที่มา",
    "sources": "แหล่งที่มา",
    "start_a_conversation": "เริ่มการสนทนา",
    "start_conversation": "เริ่มการสนทนา…",
    "still_generating_answer": "ยังคงสร้างคำตอบ...",
    "still_thinking": "ยังคิดอยู่ว่า...",
    "still_working_on_it": "ยังคงดำเนินการอยู่...",
    "tasks_space_title": "งาน",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} จะตอบกลับโดยเร็วที่สุด",
    "teammate_changes_team_assignment_of_a_conversation": "เพื่อนร่วมทีมได้เปลี่ยนการมอบหมายการสนทนา...",
    "tell_us_what_you_need": "บอกเราว่าคุณต้องการให้ช่วยเหลืออะไร แล้วบ็อตของเราจะให้คำตอบที่ดีที่สุด",
    "the_team_can_help_if_needed": "ทีมสามารถช่วยได้หากจําเป็น",
    "the_team_typically_replies_in_10800": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 3 ชั่วโมง",
    "the_team_typically_replies_in_120": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 2 นาที",
    "the_team_typically_replies_in_1200": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 20 นาที",
    "the_team_typically_replies_in_14400": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่ถึง 4 ชั่วโมง",
    "the_team_typically_replies_in_172800": "โดยทั่วไปทีมจะตอบกลับภายใน 2 วัน",
    "the_team_typically_replies_in_180": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 3 นาที",
    "the_team_typically_replies_in_1800": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 30 นาที",
    "the_team_typically_replies_in_18000": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 5 ชั่วโมง",
    "the_team_typically_replies_in_21600": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 6 ชั่วโมง",
    "the_team_typically_replies_in_240": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 4 นาที",
    "the_team_typically_replies_in_259200": "โดยทั่วไปแล้วทีมจะตอบกลับภายใน 3 วัน",
    "the_team_typically_replies_in_300": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 5 นาที",
    "the_team_typically_replies_in_345600": "โดยทั่วไปแล้วทีมจะตอบกลับภายใน 4 วัน",
    "the_team_typically_replies_in_3600": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 1 ชั่วโมง",
    "the_team_typically_replies_in_43200": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 12 ชั่วโมง",
    "the_team_typically_replies_in_432000": "โดยทั่วไปแล้วทีมจะตอบกลับภายใน 5 วัน",
    "the_team_typically_replies_in_518400": "โดยทั่วไปแล้วทีมจะตอบกลับภายใน 6 วัน",
    "the_team_typically_replies_in_60": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 1 นาที",
    "the_team_typically_replies_in_600": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 10 นาที",
    "the_team_typically_replies_in_604800": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 1 สัปดาห์",
    "the_team_typically_replies_in_7200": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 2 ชั่วโมง",
    "the_team_typically_replies_in_86400": "โดยทั่วไปทีมจะตอบกลับภายใน 1 วัน",
    "the_team_typically_replies_in_900": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน 15 นาที",
    "the_team_typically_replies_in_day": "โดยทั่วไปทีมจะตอบกลับภายในหนึ่งวัน",
    "the_team_typically_replies_in_hours": "โดยทั่วไปแล้วทีมจะตอบกลับภายในสองถึงสามชั่วโมง",
    "the_team_typically_replies_in_minutes": "โดยทั่วไปแล้วทีมจะตอบกลับภายในสองถึงสามนาที",
    "the_team_typically_replies_in_under_n_hours": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน {hours} ชั่วโมง",
    "the_team_typically_replies_in_under_n_min": "โดยทั่วไปแล้วทีมจะตอบกลับภายในเวลาไม่เกิน {minutes} นาที",
    "the_team_typically_replies_in_unknown": "ทีมจะตอบกลับโดยเร็วที่สุด",
    "the_team_typically_replies_in_week": "โดยทั่วไปแล้วทีมจะตอบกลับภายในหนึ่งสัปดาห์",
    "thinking": "กำลังคิด...",
    "tickets_cta_text": "มีคำถามเกี่ยวกับคำขอแจ้งปัญหานี้หรือไม่",
    "tickets_have_a_question": "มีคำถามเกี่ยวกับปัญหานี้หรือไม่",
    "tickets_space_title": "ทิกเก็ต",
    "time_future_1h": "ใน 1 ชั่วโมง",
    "time_future_2h": "ใน 2 ชั่วโมง",
    "time_future_30m": "ใน 30 นาที",
    "time_future_3h": "ใน 3 ชั่วโมง",
    "time_future_day_0": "วันจันทร์",
    "time_future_day_1": "วันอังคาร",
    "time_future_day_2": "วันพุธ",
    "time_future_day_3": "วันพฤหัสบดี",
    "time_future_day_4": "วันศุกร์",
    "time_future_day_5": "วันเสาร์",
    "time_future_day_6": "วันอาทิตย์",
    "time_future_later_today": "ภายหลังในวันนี้",
    "time_future_next_week": "สัปดาห์หน้า",
    "time_future_tomorrow": "พรุ่งนี้",
    "try_use_different_keywords": "ลองใช้คำสำคัญอื่นหรือพิมพ์คำถามที่ครบสมบูรณ์ของคุณ",
    "upfront_collection_form_close_out": "คุณสามารถกลับมาที่นี่ได้ทุกเมื่อเพื่อดูคำตอบและส่งการอัปเดต",
    "upfront_collection_form_introduction": "แชร์บริบทเพื่อช่วยให้ทีมตอบคำถามได้ดีขึ้นและเร็วขึ้น",
    "upfront_collection_form_submission_confirmation": "ขอขอบคุณที่ส่งบริบทดังกล่าว หากต้องการประหยัดเวลาในภายหลัง คุณสามารถเพิ่มรายละเอียดได้ทันที",
    "upfront_collection_form_subtitle": "ส่งบริบทให้กับทีม",
    "upfront_collection_form_subtitle_submitted": "ทีมจะพิจารณาบริบทนี้",
    "upfront_collection_form_title": "รับความช่วยเหลือได้เร็วขึ้น",
    "upfront_collection_form_title_submitted": "ส่งให้ทีม!",
    "view_all": "ดูทั้งหมด",
    "view_conversation": "ดูการสนทนา",
    "we_are_here_to_help": "เราพร้อมให้ความช่วยเหลือ",
    "we_run_on_intercom": "เราทำงานผ่าน Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "เมื่อเพื่อนร่วมทีมเปลี่ยนสถานะการสนทนา...",
    "working_on_it": "กําลังดําเนินการอยู่...",
    "you_are_all_up_to_date": "เรียบร้อยหมดแล้ว!",
    "your_checklists": "รายการตรวจสอบของคุณ",
    "your_conversations": "การสนทนาของคุณ",
    "your_recent_conversations": "การสนทนาล่าสุด",
    "your_recent_messages": "ข้อความล่าสุด"
  },
  "tr": {
    "a_day": "Bir gün",
    "a_few_hours": "Birkaç saat",
    "a_few_minutes": "Birkaç dakika",
    "a_week": "Bir hafta",
    "active_state": "Aktif",
    "ai_answer": "Yapay Zekâ Yanıtı",
    "ai_answer_information": "Yapay Zekâ Yanıtı, bilgi",
    "ai_bot": "Yapay Zekâ Botu",
    "almost_there": "Neredeyse bitti...",
    "answer": "Yanıt",
    "answer_bot_acknowledge_duplicate_content": "Bunu zaten gördünüz ancak verebileceğim en iyi yanıt bu.",
    "answer_bot_all_done": "Bu yardımcı oldu 👍",
    "answer_bot_ask_another_question": "Başka bir soru sorun 🔁",
    "answer_bot_ask_something_else": "Başka bir soru sor 🔁",
    "answer_bot_connect_to_an_agent": "Bir temsilciye bağlan 👤",
    "answer_bot_did_that_help": "Bu yanıt size yardımcı oldu mu yoksa başka bir şey mi arıyorsunuz?",
    "answer_bot_followup_expectations_prefix": "Ekibimiz size bu konuyla ilgili dönüş yapacak.",
    "answer_bot_get_more_help": "Daha fazla yardım alın 🌐",
    "answer_bot_get_more_help_followup": "Bu bağlantı yardımcı olabilir:",
    "answer_bot_i_have_another_question": "Başka bir sorum var 🔁",
    "answer_bot_im_not_done_yet": "Soracaklarım henüz bitmedi 🔁",
    "answer_bot_keep_waiting": "Hayır, hâlâ yardıma ihtiyacım var 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Sorunuzu yeniden ifade edebilir veya ek yardım alabilirsiniz",
    "answer_bot_looping_first_no_answer": "Üzgünüm, bir bot olarak bunun cevabını bulamadım.",
    "answer_bot_looping_first_no_answer_followup": "Sorunuzu yeniden ifade edebilir veya ekibimizle konuşabilirsiniz",
    "answer_bot_looping_no_answer": "Üzgünüm, bir bot olarak bunun yanıtını bulamadım. İşte yapabilecekleriniz",
    "answer_bot_more_answers": "Daha fazlasını göster 👀",
    "answer_bot_no_answer_found": "Üzgünüm. Bu sorunun yanıtı bende yok. İşte diğer bazı seçenekler.",
    "answer_bot_resolve_followup": "Başka bir konuda yardıma ihtiyacınız olursa her zaman buradan yanıtlayabilirsiniz.",
    "answer_bot_talk_to_person": "Bir kişiyle konuşun 👤",
    "answer_bot_that_answered_my_question": "Aradığım yanıt buydu 👍",
    "answer_bot_thats_it": "İşte bu 👍",
    "answer_bot_wait_for_the_team": "Ekibi bekleyin 💬",
    "answerbot_ask_for_more_context": "Elbette. Beklerken, durumunuz veya sorunuz hakkında daha fazla ayrıntı paylaşabilir misiniz? Bu sayede daha hızlı bir yanıt bulabilir ve bu konuda daha fazla bilgi verebilirim.",
    "answerbot_introduce_sneaky_answer": "Bu ek bilgi için teşekkürler. Size yardımcı olabilecek bazı yeni bilgiler buldum.",
    "article_search_hint_text": "Makalelerde ara...",
    "ask_a_question": "Soru sor",
    "ask_a_question_placeholder": "Bir soru sorun...",
    "ask_a_question_with_ellipsis": "Bir soru sorun...",
    "ask_another_question_with_ellipsis": "Başka bir soru sorun...",
    "ask_for_the_team_if_needed": "Gerekirse ekip isteyin",
    "ask_me_a_question": "Bana bir soru sorun",
    "ask_our_bot_a_question": "Botumuza bir soru sorun",
    "avatar": "Avatar",
    "avatars": "Avatarlar",
    "bot": "Bot",
    "bot_answers_instantly": "Bot anında yanıt verir",
    "bot_expectation_text": "Bot'umuz sıkça sorulan birçok soruyu yanıtlayabilir",
    "bot_intro_default_part_1": "Merhaba! Ben bir robotum. Sorularınızı ben yanıtlayacağım. Ancak istediğiniz zaman ekibimizle görüşme seçeneğiniz de olacak.",
    "bot_intro_default_part_2": "Bugün neden buradasınız?",
    "bot_is_still_thinking": "Bot hâlâ düşünüyor...",
    "bot_is_thinking": "Bot düşünüyor...",
    "browse_collections": "Koleksiyonlara göz atın",
    "chat_with_us": "Bizimle sohbet edin",
    "check_later_for_updates": "Güncellemeler için daha sonra tekrar kontrol edin.",
    "checklist_card_first_step": "İlk adım",
    "checklist_card_next_step": "Sonraki adım",
    "checklist_completed": "Tamamlandı",
    "checklist_in_progress_time_remaining_plural": "Yaklaşık {minutes} dakika kaldı",
    "checklist_in_progress_time_remaining_singular": "Yaklaşık 1 dakika kaldı",
    "checklist_mark_step_as_complete": "Tamamlandı olarak işaretle",
    "checklist_not_started_time_remaining_plural": "Yaklaşık {minutes} dakika",
    "checklist_not_started_time_remaining_singular": "Yaklaşık 1 dakika",
    "checklist_number_of_steps_plural": "{number_of_steps} adım",
    "checklist_number_of_steps_progress_plural": "{total_steps} / {steps_completed} tamamlandı",
    "checklist_number_of_steps_progress_singular": "1 / {total_steps} tamamlandı",
    "checklist_number_of_steps_singular": "1 adım",
    "checklist_sender_name": "{name} tarafından paylaşıldı",
    "checklist_step_action_button": "Adım işlem düğmesi",
    "checklist_step_completed": "Tamamlandı",
    "contact_support": "İletişim desteği",
    "contact_us": "Bize ulaşın",
    "continue_the_conversation": "Sohbete devam et",
    "conversation_card_office_hours_header": "Tekrar çevrimiçi olacağımız zaman",
    "conversation_card_reply_time_header": "Ortalama yanıt süremiz",
    "conversation_card_reply_time_header_m5": "Ortalama yanıt süresi",
    "conversation_card_unknown_response_time": "Mümkün olan en kısa sürede yanıtlayacağız",
    "conversation_part_sample_text": "Merhaba, nasıl yardımcı olabilirim? Gerektiğinde her zaman ekibimizle konuşma seçeneğiniz olacaktır.",
    "conversations_space_title": "Sohbetler",
    "create_a_ticket": "Destek talebi oluştur",
    "currently_no_help_articles": "Şu anda hiçbir yardım makalesi yok.",
    "customer_sends_their_first_message": "Müşteri ilk mesajını gönderdi...",
    "expected_response_delay_10800": "3 saatten az",
    "expected_response_delay_120": "2 dakika içinde",
    "expected_response_delay_1200": "20 dakikadan az",
    "expected_response_delay_14400": "4 saatten az",
    "expected_response_delay_172800": "2 gün",
    "expected_response_delay_180": "3 dakika içinde",
    "expected_response_delay_1800": "30 dakikadan az",
    "expected_response_delay_18000": "5 saatten az",
    "expected_response_delay_21600": "6 saatten az",
    "expected_response_delay_240": "4 dakika içinde",
    "expected_response_delay_259200": "3 gün",
    "expected_response_delay_300": "5 dakikadan az",
    "expected_response_delay_345600": "4 gün",
    "expected_response_delay_3600": "1 saatten az",
    "expected_response_delay_43200": "en geç 12 saat",
    "expected_response_delay_432000": "5 gün",
    "expected_response_delay_518400": "6 gün",
    "expected_response_delay_60": "1 dakika içinde",
    "expected_response_delay_600": "10 dakikadan az",
    "expected_response_delay_604800": "1 haftadan az",
    "expected_response_delay_7200": "2 saatten az",
    "expected_response_delay_86400": "1 gün",
    "expected_response_delay_900": "15 dakika içinde",
    "expected_response_delay_unknown": "En kısa süre içinde",
    "extra_labels_count": "+ {labels_count} etiket",
    "for_best_results_provide_detail": "En iyi sonuçlar için mümkün olduğunca fazla ayrıntı verin",
    "generating_answer": "Yanıt üretiliyor...",
    "generic_triage_default": "Sizi doğru kişiye bağlayabilmek için biraz daha bilgiye ihtiyacımız var",
    "get_an_instant_answer": "Anında yanıt alın",
    "get_help": "Yardım alın",
    "go_to": "{app_name} uygulamasına git",
    "help_space_title": "Yardım",
    "home_header_mobile_hello_firstname": "Merhaba {firstname}.",
    "home_header_mobile_hello_there": "Merhaba",
    "home_space_title": "Ana Sayfa",
    "how_can_we_help": "Nasıl yardımcı olabiliriz?",
    "how_would_you_rate_this": "Bu makaleye hangi puanı verirsiniz?",
    "learn_more": "Daha fazla bilgi",
    "message_placeholder": "Mesaj…",
    "messages_space_title": "Mesajlar",
    "messenger_bot_reply_time": "Botumuz anında yanıt verecektir",
    "messenger_settings_introduction": "Nasıl yardımcı olabiliriz?",
    "multiple_articles": "{total_articles} Makale",
    "multiple_collections": "{total_collection} koleksiyon",
    "new_conversation": "Yeni Sohbet",
    "news": "Haberler",
    "news_empty_title": "Henüz haber yok",
    "news_latest_subtitle": "{team_name} ekibinden",
    "news_latest_title": "En yeni",
    "news_older_title": "Daha eski",
    "news_space_title": "Haberler",
    "newsfeed": "Haber akışı",
    "no_articles_to_display": "Henüz makale yok",
    "no_help_articles_to_display": "Yardım makalesi yok",
    "no_results_for_searchterm": "{searchTerm} için sonuç bulunamadı",
    "no_tasks": "Görev yok",
    "no_tasks_available_currently": "Şu anda görev yok.",
    "ok_the_team_typically_replies_in_day": "Tamam. Ekip genellikle bir gün içinde yanıt verir.",
    "ok_the_team_typically_replies_in_hours": "Tamam. Ekip genellikle birkaç saat içinde yanıt verir.",
    "ok_the_team_typically_replies_in_minutes": "Tamam. Ekip genellikle birkaç dakika içinde yanıt verir.",
    "ok_the_team_typically_replies_in_under_n_hours": "Tamam. Ekip genellikle en geç {hours} sa. içinde yanıt verir.",
    "ok_the_team_typically_replies_in_under_n_min": "Tamam. Ekip genellikle en geç {minutes} dk. içinde yanıt verir.",
    "ok_the_team_typically_replies_in_week": "Tamam. Ekip genellikle bir hafta içinde yanıt verir.",
    "our_bot_and_team_can_help": "Botumuz ve ekibimiz yardımcı olabilir",
    "our_bot_answers_instantly": "Botumuz anında yanıt verir",
    "powered_by_intercom": "Intercom ile güçlendirilmiştir",
    "previous_bot_hands_over": "Önceki bottan devralınan içerik...",
    "proactive_expected_response_delay_10800": "Genellikle en geç 3 sa. içinde yanıt verir",
    "proactive_expected_response_delay_120": "Genellikle 2 dk. içinde yanıt verir",
    "proactive_expected_response_delay_1200": "Genellikle en geç 20 dk. içinde yanıt verir",
    "proactive_expected_response_delay_14400": "Genellikle en geç 4 sa. içinde yanıt verir",
    "proactive_expected_response_delay_172800": "Genellikle 2 gün içinde yanıt verir",
    "proactive_expected_response_delay_180": "Genellikle 3 dk. içinde yanıt verir",
    "proactive_expected_response_delay_1800": "Genellikle en geç 30 dk. içinde yanıt verir",
    "proactive_expected_response_delay_18000": "Genellikle en geç 5 sa. içinde yanıt verir",
    "proactive_expected_response_delay_21600": "Genellikle en geç 6 sa. içinde yanıt verir",
    "proactive_expected_response_delay_240": "Genellikle 4 dk. içinde yanıt verir",
    "proactive_expected_response_delay_259200": "Genellikle 3 gün içinde yanıt verir",
    "proactive_expected_response_delay_300": "Genellikle en geç 5 dk. içinde yanıt verir",
    "proactive_expected_response_delay_345600": "Genellikle 4 gün içinde yanıt verir",
    "proactive_expected_response_delay_3600": "Genellikle en geç 1 sa. içinde yanıt verir",
    "proactive_expected_response_delay_43200": "Genellikle 12 saat içinde yanıt verir",
    "proactive_expected_response_delay_432000": "Genellikle 5 gün içinde yanıt verir",
    "proactive_expected_response_delay_518400": "Genellikle 6 gün içinde yanıt verir",
    "proactive_expected_response_delay_60": "Genellikle 1 dk. içinde yanıt verir",
    "proactive_expected_response_delay_600": "Genellikle en geç 10 dk. içinde yanıt verir",
    "proactive_expected_response_delay_604800": "Genellikle en geç 1 hafta içinde yanıt verir",
    "proactive_expected_response_delay_7200": "Genellikle en geç 2 sa. içinde yanıt verir",
    "proactive_expected_response_delay_86400": "Genellikle 1 gün içinde yanıt verir",
    "proactive_expected_response_delay_900": "Genellikle 15 dk. içinde yanıt verir",
    "proactive_expected_response_delay_unknown": "En kısa sürede yanıt verecektir",
    "profile_location": "{time} in {location}",
    "recent_ticket": "Son destek talebi",
    "recent_tickets": "Son destek talepleri",
    "search_browse_empty_state_text": "Üzgünüz. Bu aramayla ilgili hiçbir sonuç bulunamadı. Aramanızı başka sözcüklerle yapmayı deneyin.",
    "search_for_help": "Yardım makalesi arayın",
    "see_all": "Tümünü gör",
    "see_all_conversations": "Tüm sohbetlerinizi görün",
    "see_previous_conversations": "Önceki sohbetlerinizi görün",
    "send": "Gönder",
    "send_a_message": "Bir mesaj gönder…",
    "send_a_message_reply_time": "Ortalama yanıt süremiz:",
    "send_us_a_message": "Bize mesaj gönderin",
    "set_expectations_bot_email_header": "Yanıtları buradan ve e-posta yoluyla alacaksınız:",
    "set_expectations_bot_office_hours_header": "Ekip tekrar ne zaman çevrimiçi olacak:",
    "set_expectations_bot_sms_header": "Yanıtları buradan ve SMS yoluyla alacaksınız:",
    "set_expectations_replies_to_email": "Yanıtlar burada gösterilecek ve <b>{user_email}</b> adresine gönderilecektir.",
    "set_expectations_replies_to_email_without_bold_text": "Yanıtlar burada gösterilecek ve <b>{user_email}</b> adresine gönderilecektir",
    "set_expectations_team_reply_asap": "Ekibimiz size en kısa sürede yanıt verecektir.",
    "shared_by": "Paylaşan:",
    "show_less": "Daha az göster",
    "single_article": "1 Makale",
    "single_collection": "1 koleksiyon",
    "source": "Kaynak",
    "sources": "Kaynaklar",
    "start_a_conversation": "Bir sohbet başlat",
    "start_conversation": "Bir sohbet başlat",
    "still_generating_answer": "Hâlâ yanıt üretiliyor...",
    "still_thinking": "Hâlâ düşünüyor...",
    "still_working_on_it": "Hâlâ üzerinde çalışıyor...",
    "tasks_space_title": "Görevler",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} en kısa sürede yanıt verecektir.",
    "teammate_changes_team_assignment_of_a_conversation": "Takım arkadaşı konuşma atamasını değiştirdi...",
    "tell_us_what_you_need": "Hangi konuda yardıma ihtiyacınız olduğunu belirtirseniz bot sizi yanıtlamaya çalışacaktır",
    "the_team_can_help_if_needed": "Gerekirse ekibimiz size yardımcı olabilir",
    "the_team_typically_replies_in_10800": "Ekip genellikle en geç 3 sa. içinde yanıt verir.",
    "the_team_typically_replies_in_120": "Ekip genellikle 2 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_1200": "Ekip genellikle en geç 20 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_14400": "Ekip genellikle en geç 4 sa. içinde yanıt verir.",
    "the_team_typically_replies_in_172800": "Ekip genellikle 2 gün içinde yanıt verir.",
    "the_team_typically_replies_in_180": "Ekip genellikle 3 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_1800": "Ekip genellikle en geç 30 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_18000": "Ekip genellikle en geç 5 sa. içinde yanıt verir.",
    "the_team_typically_replies_in_21600": "Ekip genellikle en geç 6 sa. içinde yanıt verir.",
    "the_team_typically_replies_in_240": "Ekip genellikle 4 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_259200": "Ekip genellikle 3 gün içinde yanıt verir",
    "the_team_typically_replies_in_300": "Ekip genellikle en geç 5 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_345600": "Ekip genellikle 4 gün içinde yanıt verir",
    "the_team_typically_replies_in_3600": "Ekip genellikle en geç 1 sa. içinde yanıt verir.",
    "the_team_typically_replies_in_43200": "Ekip genellikle en geç 12 saat içinde yanıt verir",
    "the_team_typically_replies_in_432000": "Ekip genellikle 5 gün içinde yanıt verir",
    "the_team_typically_replies_in_518400": "Ekip genellikle 6 gün içinde yanıt verir",
    "the_team_typically_replies_in_60": "Ekip genellikle 1 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_600": "Ekip genellikle en geç 10 dk. içinde yanıt verir.",
    "the_team_typically_replies_in_604800": "Ekip genellikle en geç 1 hafta içinde yanıt verir.",
    "the_team_typically_replies_in_7200": "Ekip genellikle en geç 2 sa. içinde yanıt verir.",
    "the_team_typically_replies_in_86400": "Ekip genellikle 1 gün içinde yanıt verir.",
    "the_team_typically_replies_in_900": "Ekip genellikle 15 dk. içinde yanıt verir",
    "the_team_typically_replies_in_day": "Ekip genellikle bir gün içinde yanıt verir.",
    "the_team_typically_replies_in_hours": "Ekip genellikle birkaç saat içinde yanıt verir.",
    "the_team_typically_replies_in_minutes": "Ekip genellikle birkaç dakika içinde yanıt verir.",
    "the_team_typically_replies_in_under_n_hours": "Ekip genellikle en geç {hours} sa. içinde yanıt verir.",
    "the_team_typically_replies_in_under_n_min": "Ekip genellikle en geç {minutes} dk. içinde yanıt verir.",
    "the_team_typically_replies_in_unknown": "Ekip en kısa sürede yanıt verecektir.",
    "the_team_typically_replies_in_week": "Ekip genellikle bir hafta içinde yanıt verir.",
    "thinking": "Düşünüyor...",
    "tickets_cta_text": "Bu destek talebi hakkında bir sorunuz mu var?",
    "tickets_have_a_question": "Bu konuyla ilgili bir sorunuz mu var?",
    "tickets_space_title": "Destek Talepleri",
    "time_future_1h": "1 saat",
    "time_future_2h": "2 saat",
    "time_future_30m": "30 dakika",
    "time_future_3h": "3 saat",
    "time_future_day_0": "Pazartesi",
    "time_future_day_1": "Salı",
    "time_future_day_2": "Çarşamba",
    "time_future_day_3": "Perşembe",
    "time_future_day_4": "Cuma",
    "time_future_day_5": "Cumartesi",
    "time_future_day_6": "Pazar",
    "time_future_later_today": "Günün ilerleyen saatlerinde",
    "time_future_next_week": "Gelecek hafta",
    "time_future_tomorrow": "Yarın",
    "try_use_different_keywords": "Farklı anahtar sözcükler kullanmayı deneyin veya sorunuzun tamamını girin",
    "upfront_collection_form_close_out": "Yanıtları görmek ve güncelleme göndermek için her zaman buraya geri dönebilirsiniz.",
    "upfront_collection_form_introduction": "Ekibin daha iyi ve daha hızlı yanıt vermesine yardımcı olmak için biraz bağlam paylaşın.",
    "upfront_collection_form_submission_confirmation": "Bu bağlamı gönderdiğiniz için teşekkür ederiz. Daha sonra zamandan kazanmak için şimdi daha fazla ayrıntı vermekten çekinmeyin.",
    "upfront_collection_form_subtitle": "Ekibe biraz bağlam gönderin",
    "upfront_collection_form_subtitle_submitted": "Bu bağlamı dikkate alacaklar",
    "upfront_collection_form_title": "Daha hızlı yardım alın",
    "upfront_collection_form_title_submitted": "Ekibe gönderildi!",
    "view_all": "Tümünü görüntüle",
    "view_conversation": "Sohbeti görüntüle",
    "we_are_here_to_help": "Size yardım etmeye hazırız",
    "we_run_on_intercom": "Intercom üzerinden faaliyet gösteriyoruz",
    "when_a_teammate_changes_the_state_of_a_conversation": "Bir ekip arkadaşı konuşmanın durumunu değiştirdiğinde...",
    "working_on_it": "Üzerinde çalışıyor...",
    "you_are_all_up_to_date": "En yeni haberler bu kadar!",
    "your_checklists": "Kontrol listeleriniz",
    "your_conversations": "Sohbetleriniz",
    "your_recent_conversations": "Son sohbetler",
    "your_recent_messages": "Son mesajlar"
  },
  "uk": {
    "a_day": "один день",
    "a_few_hours": "кілька годин",
    "a_few_minutes": "кілька хвилин",
    "a_week": "Тиждень",
    "active_state": "У мережі",
    "ai_answer": "Відповідь ШІ",
    "ai_answer_information": "Відповідь ШІ, інформація",
    "ai_bot": "Бот із ШІ",
    "almost_there": "Майже закінчив...",
    "answer": "Відповідь",
    "answer_bot_acknowledge_duplicate_content": "Ви вже бачили цю відповідь, але це найкращий варіант, який ми можемо запропонувати.",
    "answer_bot_all_done": "Це мені допомогло 👍",
    "answer_bot_ask_another_question": "Поставити інше запитання 🔁",
    "answer_bot_ask_something_else": "Поставити інше запитання 🔁",
    "answer_bot_connect_to_an_agent": "Зв’язатися з фахівцем 👤",
    "answer_bot_did_that_help": "Вам допомогла ця відповідь, чи потрібно щось іще?",
    "answer_bot_followup_expectations_prefix": "Наші спеціалісти допоможуть у вирішенні питання.",
    "answer_bot_get_more_help": "Додаткова допомога 🌐",
    "answer_bot_get_more_help_followup": "Це посилання може допомогти:",
    "answer_bot_i_have_another_question": "У мене є інше запитання 🔁",
    "answer_bot_im_not_done_yet": "Я хочу запитати ще 🔁",
    "answer_bot_keep_waiting": "Мені все ще потрібна допомога 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Ви можете перефразувати своє запитання або отримати додаткову допомогу",
    "answer_bot_looping_first_no_answer": "Вибачте, як бот, я не можу знайти відповідь на це запитання.",
    "answer_bot_looping_first_no_answer_followup": "Ви можете перефразувати своє запитання або поспілкуватися з нашою командою",
    "answer_bot_looping_no_answer": "Вибачте, я бот і не можу знайти відповідь на це запитання. Що можна зробити",
    "answer_bot_more_answers": "Покажіть мені інші варіанти 👀",
    "answer_bot_no_answer_found": "На жаль, у нас немає швидкої відповіді на це запитання. Але ось що ще можна зробити.",
    "answer_bot_resolve_followup": "Ви завжди можете написати мені, якщо ще знадобиться допомога.",
    "answer_bot_talk_to_person": "Поговоріть із нашим фахівцем 👤",
    "answer_bot_that_answered_my_question": "Відповідь на запитання знайдено 👍",
    "answer_bot_thats_it": "Те, що треба 👍",
    "answer_bot_wait_for_the_team": "Очікую на допомогу фахівця 💬",
    "answerbot_ask_for_more_context": "Гаразд. Поки ми чекаємо, опишіть свою ситуацію чи запитання докладніше. Це допоможе нам відповісти швидше, і я зможу знайти для вас більше інформації.",
    "answerbot_introduce_sneaky_answer": "Дякую за пояснення. Пропонуємо переглянути додаткову інформацію, яка може вам допомогти.",
    "article_search_hint_text": "Пошук статей...",
    "ask_a_question": "Поставити запитання",
    "ask_a_question_placeholder": "Поставте запитання…",
    "ask_a_question_with_ellipsis": "Поставити запитання...",
    "ask_another_question_with_ellipsis": "Поставте інше запитання...",
    "ask_for_the_team_if_needed": "За потреби зверніться до нашої команди",
    "ask_me_a_question": "Поставте мені запитання",
    "ask_our_bot_a_question": "Поставити боту запитання",
    "avatar": "Аватар",
    "avatars": "Аватари",
    "bot": "Бот",
    "bot_answers_instantly": "Бот відповідає миттєво",
    "bot_expectation_text": "Наш бот зможе відповісти на багато поширених запитань",
    "bot_intro_default_part_1": "Вітаємо! Я — бот-помічник. Я готовий відповісти на ваші запитання, але ви завжди можете поспілкуватися також із нашою командою.",
    "bot_intro_default_part_2": "То що ж вас до мене привело?",
    "bot_is_still_thinking": "Бот усе ще думає...",
    "bot_is_thinking": "Бот думає...",
    "browse_collections": "Перегляд колекцій",
    "chat_with_us": "Чат",
    "check_later_for_updates": "Перевірте наявність оновлень пізніше.",
    "checklist_card_first_step": "Перший крок",
    "checklist_card_next_step": "Наступний крок",
    "checklist_completed": "Завершено",
    "checklist_in_progress_time_remaining_plural": "Залишилося близько {minutes} хв.",
    "checklist_in_progress_time_remaining_singular": "Залишилося близько 1 хвилини",
    "checklist_mark_step_as_complete": "Позначити як виконане",
    "checklist_not_started_time_remaining_plural": "Близько {minutes} хв.",
    "checklist_not_started_time_remaining_singular": "Близько 1 хвилини",
    "checklist_number_of_steps_plural": "{number_of_steps} кроки(ів)",
    "checklist_number_of_steps_progress_plural": "{steps_completed} з {total_steps} виконано",
    "checklist_number_of_steps_progress_singular": "1 із {total_steps} виконано",
    "checklist_number_of_steps_singular": "1 крок",
    "checklist_sender_name": "Поділився {name}",
    "checklist_step_action_button": "Кнопка покрокової дії",
    "checklist_step_completed": "Завершено",
    "contact_support": "Служба підтримки",
    "contact_us": "Зв'язок",
    "continue_the_conversation": "Продовжити бесіду",
    "conversation_card_office_hours_header": "Коли ми будемо онлайн",
    "conversation_card_reply_time_header": "Звичайний час відповіді",
    "conversation_card_reply_time_header_m5": "Звичайний час відповіді",
    "conversation_card_unknown_response_time": "Ми відповімо за першої нагоди",
    "conversation_part_sample_text": "Вітаю! Чим я можу допомогти? За потреби ви завжди зможете зв’язатися з нашими фахівцями.",
    "conversations_space_title": "Бесіди",
    "create_a_ticket": "Створити запит",
    "currently_no_help_articles": "Наразі немає довідкових статей.",
    "customer_sends_their_first_message": "Клієнт надсилає перше повідомлення...",
    "expected_response_delay_10800": "до 3 годин",
    "expected_response_delay_120": "до 2 хвилин",
    "expected_response_delay_1200": "до 20 хвилин",
    "expected_response_delay_14400": "до 4 годин",
    "expected_response_delay_172800": "2 дні",
    "expected_response_delay_180": "до 3 хвилин",
    "expected_response_delay_1800": "до 30 хвилин",
    "expected_response_delay_18000": "до 5 годин",
    "expected_response_delay_21600": "до 6 годин",
    "expected_response_delay_240": "до 4 хвилин",
    "expected_response_delay_259200": "3 дн.",
    "expected_response_delay_300": "до 5 хвилин",
    "expected_response_delay_345600": "4 дн.",
    "expected_response_delay_3600": "до 1 години",
    "expected_response_delay_43200": "протягом 12 годин",
    "expected_response_delay_432000": "5 дн.",
    "expected_response_delay_518400": "6 днів",
    "expected_response_delay_60": "до 1 хвилини",
    "expected_response_delay_600": "до 10 хвилин",
    "expected_response_delay_604800": "до 1 тижня",
    "expected_response_delay_7200": "до 2 годин",
    "expected_response_delay_86400": "1 день",
    "expected_response_delay_900": "до 15 хвилин",
    "expected_response_delay_unknown": "за першої нагоди",
    "extra_labels_count": "міток: + {labels_count}",
    "for_best_results_provide_detail": "Для кращого результату надайте якомога більше деталей",
    "generating_answer": "Генерую відповідь...",
    "generic_triage_default": "Нам потрібно ще трошки інформації, щоб з’єднати вас із відповідним фахівцем",
    "get_an_instant_answer": "Миттєва відповідь",
    "get_help": "Отримати допомогу",
    "go_to": "Перейти в додаток {app_name}",
    "help_space_title": "Допомога",
    "home_header_mobile_hello_firstname": "Вітаємо, {firstname}!",
    "home_header_mobile_hello_there": "Вітаємо!",
    "home_space_title": "Головна",
    "how_can_we_help": "Чим вам допомогти?",
    "how_would_you_rate_this": "Як би ви оцінили цей матеріал?",
    "learn_more": "Докладніше",
    "message_placeholder": "Повідомлення...",
    "messages_space_title": "Повідомлення",
    "messenger_bot_reply_time": "Наш бот миттєво відповість",
    "messenger_settings_introduction": "Чим вам допомогти?",
    "multiple_articles": "Статей: {total_articles}",
    "multiple_collections": "{total_collection} колекцій(-її)",
    "new_conversation": "Нова бесіда",
    "news": "Новини",
    "news_empty_title": "Поки новин немає",
    "news_latest_subtitle": "Від команди {team_name}",
    "news_latest_title": "Останні новини",
    "news_older_title": "Попередні",
    "news_space_title": "Новини",
    "newsfeed": "Стрічка новин",
    "no_articles_to_display": "Статей ще немає",
    "no_help_articles_to_display": "Немає довідкових статей",
    "no_results_for_searchterm": "Немає результатів для запиту: {searchTerm}",
    "no_tasks": "Немає завдань",
    "no_tasks_available_currently": "Наразі завдань немає.",
    "ok_the_team_typically_replies_in_day": "Дякуємо. Зазвичай команда відповідає протягом дня.",
    "ok_the_team_typically_replies_in_hours": "Дякуємо. Зазвичай команда відповідає протягом кількох годин.",
    "ok_the_team_typically_replies_in_minutes": "Дякуємо. Зазвичай команда відповідає протягом кількох хвилин.",
    "ok_the_team_typically_replies_in_under_n_hours": "Дякуємо. Зазвичай команда відповідає менше ніж за {hours} год.",
    "ok_the_team_typically_replies_in_under_n_min": "Дякуємо. Зазвичай команда відповідає менше ніж за {minutes} хв.",
    "ok_the_team_typically_replies_in_week": "Зазвичай команда відповідає через тиждень.",
    "our_bot_and_team_can_help": "Вам допоможуть наш бот і наші фахівці",
    "our_bot_answers_instantly": "Наш бот відповідає миттєво",
    "powered_by_intercom": "Ми користуємось Intercom",
    "previous_bot_hands_over": "Вміст від попереднього бота...",
    "proactive_expected_response_delay_10800": "Зазвичай відповідає менше ніж за 3 год.",
    "proactive_expected_response_delay_120": "Зазвичай відповідає менше ніж за 2 хв.",
    "proactive_expected_response_delay_1200": "Зазвичай відповідає менше ніж за 20 хв.",
    "proactive_expected_response_delay_14400": "Зазвичай відповідає менше ніж за 4 год.",
    "proactive_expected_response_delay_172800": "Зазвичай відповідає менше ніж за 2 д.",
    "proactive_expected_response_delay_180": "Зазвичай відповідає менше ніж за 3 хв.",
    "proactive_expected_response_delay_1800": "Зазвичай відповідає менше ніж за 30 хв.",
    "proactive_expected_response_delay_18000": "Зазвичай відповідає менше ніж за 5 год.",
    "proactive_expected_response_delay_21600": "Зазвичай відповідає менше ніж за 6 год.",
    "proactive_expected_response_delay_240": "Зазвичай відповідає менше ніж за 4 хв.",
    "proactive_expected_response_delay_259200": "Зазвичай відповідає через 3 дн.",
    "proactive_expected_response_delay_300": "Зазвичай відповідає протягом менше ніж 5 хв.",
    "proactive_expected_response_delay_345600": "Зазвичай відповідає через 4 дн.",
    "proactive_expected_response_delay_3600": "Зазвичай відповідає менше ніж за 1 год.",
    "proactive_expected_response_delay_43200": "Зазвичай відповідає через 12 год.",
    "proactive_expected_response_delay_432000": "Зазвичай відповідає через 5 дн.",
    "proactive_expected_response_delay_518400": "Зазвичай відповідає через 6 дн.",
    "proactive_expected_response_delay_60": "Зазвичай відповідає менше ніж за 1 хв.",
    "proactive_expected_response_delay_600": "Зазвичай відповідає менше ніж за 10 хв.",
    "proactive_expected_response_delay_604800": "Зазвичай відповідає менше ніж за 1 тижд.",
    "proactive_expected_response_delay_7200": "Зазвичай відповідає менше ніж за 2 год.",
    "proactive_expected_response_delay_86400": "Зазвичай відповідає протягом 1 дн.",
    "proactive_expected_response_delay_900": "Час очікування відповіді — зазвичай до 15 хв.",
    "proactive_expected_response_delay_unknown": "Дасть відповідь за першої нагоди",
    "profile_location": "{time} в {location}",
    "recent_ticket": "Останній запит",
    "recent_tickets": "Останні запити",
    "search_browse_empty_state_text": "На жаль, ми не знайшли результатів за цим запитом. Спробуйте перефразувати його.",
    "search_for_help": "Пошук в статтях",
    "see_all": "Показати всі",
    "see_all_conversations": "Перегляньте всі ваші бесіди",
    "see_previous_conversations": "Перегляньте свої попередні бесіди",
    "send": "Надіслати",
    "send_a_message": "Надіслати повідомлення…",
    "send_a_message_reply_time": "Звичайний час відповіді становить",
    "send_us_a_message": "Напишіть нам повідомлення",
    "set_expectations_bot_email_header": "Ви отримаєте відповідь тут і на свою ел. пошту:",
    "set_expectations_bot_office_hours_header": "Команда повернеться",
    "set_expectations_bot_sms_header": "Ви отримаєте відповідь тут і в SMS-повідомленні:",
    "set_expectations_replies_to_email": "Ви отримаєте відповіді тут і на ел. пошту <b>{user_email}</b>",
    "set_expectations_replies_to_email_without_bold_text": "Ви отримаєте відповіді тут і на ел. пошту: {user_email}",
    "set_expectations_team_reply_asap": "Команда дасть відповідь за першої нагоди.",
    "shared_by": "Поділився користувач",
    "show_less": "Показати менше",
    "single_article": "1 стаття",
    "single_collection": "1 колекція",
    "source": "Джерело",
    "sources": "Джерела",
    "start_a_conversation": "Почати бесіду",
    "start_conversation": "Почати бесіду…",
    "still_generating_answer": "Усе ще генерую...",
    "still_thinking": "Усе ще думаю...",
    "still_working_on_it": "Усе ще працюю...",
    "tasks_space_title": "Завдання",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} дасть відповідь за першої нагоди",
    "teammate_changes_team_assignment_of_a_conversation": "Учасник команди змінив призначення розмови...",
    "tell_us_what_you_need": "Розкажіть, яка допомога вам потрібна, і наш бот зробить усе можливе, щоб надати відповідь",
    "the_team_can_help_if_needed": "У разі потреби наші фахівці вам допоможуть",
    "the_team_typically_replies_in_10800": "Зазвичай команда відповідає менше ніж за 3 год.",
    "the_team_typically_replies_in_120": "Зазвичай команда відповідає менше ніж за 2 хв.",
    "the_team_typically_replies_in_1200": "Зазвичай команда відповідає менше ніж за 20 хв.",
    "the_team_typically_replies_in_14400": "Зазвичай команда відповідає менше ніж за 4 год.",
    "the_team_typically_replies_in_172800": "Зазвичай команда відповідає менше ніж за 2 дні.",
    "the_team_typically_replies_in_180": "Зазвичай команда відповідає менше ніж за 3 хв.",
    "the_team_typically_replies_in_1800": "Зазвичай команда відповідає менше ніж за 30 хв.",
    "the_team_typically_replies_in_18000": "Зазвичай команда відповідає менше ніж за 5 год.",
    "the_team_typically_replies_in_21600": "Зазвичай команда відповідає менше ніж за 6 год.",
    "the_team_typically_replies_in_240": "Зазвичай команда відповідає менше ніж за 4 хв.",
    "the_team_typically_replies_in_259200": "Зазвичай команда відповідає через 3 дн.",
    "the_team_typically_replies_in_300": "Зазвичай команда відповідає менше ніж за 5 хв.",
    "the_team_typically_replies_in_345600": "Зазвичай команда відповідає через 4 дн.",
    "the_team_typically_replies_in_3600": "Зазвичай команда відповідає менше ніж за 1 год.",
    "the_team_typically_replies_in_43200": "Зазвичай команда відповідає протягом 12 год.",
    "the_team_typically_replies_in_432000": "Зазвичай команда відповідає через 5 дн.",
    "the_team_typically_replies_in_518400": "Зазвичай команда відповідає через 6 дн.",
    "the_team_typically_replies_in_60": "Зазвичай команда відповідає менше ніж за 1 хв.",
    "the_team_typically_replies_in_600": "Зазвичай команда відповідає менше ніж за 10 хв.",
    "the_team_typically_replies_in_604800": "Зазвичай команда відповідає менше ніж за 1 тижд.",
    "the_team_typically_replies_in_7200": "Зазвичай команда відповідаєменше ніж за 2 год.",
    "the_team_typically_replies_in_86400": "Зазвичай команда відповідає протягом 1 дня",
    "the_team_typically_replies_in_900": "Час очікування відповіді від команди — зазвичай до 15 хв.",
    "the_team_typically_replies_in_day": "Зазвичай команда відповідає протягом дня.",
    "the_team_typically_replies_in_hours": "Зазвичай команда відповідає протягом кількох годин.",
    "the_team_typically_replies_in_minutes": "Зазвичай команда відповідає протягом кількох хвилин.",
    "the_team_typically_replies_in_under_n_hours": "Зазвичай команда відповідає менше ніж за {hours} год.",
    "the_team_typically_replies_in_under_n_min": "Зазвичай команда відповідає менше ніж за {minutes} хв.",
    "the_team_typically_replies_in_unknown": "Команда дасть відповідь за першої нагоди",
    "the_team_typically_replies_in_week": "Зазвичай команда відповідає через тиждень.",
    "thinking": "Думаю...",
    "tickets_cta_text": "Маєте запитання щодо цього запиту?",
    "tickets_have_a_question": "Маєте запитання щодо цього?",
    "tickets_space_title": "Запити",
    "time_future_1h": "через 1 годину",
    "time_future_2h": "через 2 години",
    "time_future_30m": "через 30 хвилин",
    "time_future_3h": "через 3 години",
    "time_future_day_0": "понеділок",
    "time_future_day_1": "вівторок",
    "time_future_day_2": "середа",
    "time_future_day_3": "четвер",
    "time_future_day_4": "п’ятниця",
    "time_future_day_5": "субота",
    "time_future_day_6": "неділя",
    "time_future_later_today": "пізніше сьогодні",
    "time_future_next_week": "наступного тижня",
    "time_future_tomorrow": "завтра",
    "try_use_different_keywords": "Спробуйте інші ключові слова або введіть запитання повністю",
    "upfront_collection_form_close_out": "Ви будь-коли можете повернутися сюди для перегляду відповідей та оновлень.",
    "upfront_collection_form_introduction": "Докладніше розкажіть про проблему, щоб наші фахівці відповіли точніше та швидше.",
    "upfront_collection_form_submission_confirmation": "Дякуємо за надання докладнішої інформації. Щоб зберегти час потім, ви можете доповнити інформацію зараз.",
    "upfront_collection_form_subtitle": "Надайте команді докладнішу інформацію",
    "upfront_collection_form_subtitle_submitted": "Наші фахівці врахують ці дані",
    "upfront_collection_form_title": "Отримайте допомогу швидше",
    "upfront_collection_form_title_submitted": "Відправлено нашим фахівцям!",
    "view_all": "Показати усі",
    "view_conversation": "Переглянути бесіду",
    "we_are_here_to_help": "Ми готові допомогти",
    "we_run_on_intercom": "Ми використовуємо Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Коли учасник команди змінює стан бесіди...",
    "working_on_it": "Працюю над відповіддю...",
    "you_are_all_up_to_date": "Попалися!",
    "your_checklists": "Ваші контрольні списки",
    "your_conversations": "Ваші бесіди",
    "your_recent_conversations": "Останні бесіди",
    "your_recent_messages": "Нещодавні повідомлення"
  },
  "vi": {
    "a_day": "Một ngày",
    "a_few_hours": "Một vài giờ",
    "a_few_minutes": "Một vài phút",
    "a_week": "Một tuần",
    "active_state": "Đang hoạt động",
    "ai_answer": "Câu trả lời AI",
    "ai_answer_information": "Câu trả lời AI, thông tin",
    "ai_bot": "AI Bot",
    "almost_there": "Gần xong...",
    "answer": "Trả lời",
    "answer_bot_acknowledge_duplicate_content": "Bạn đã thấy điều này, nhưng đây là câu trả lời tốt nhất hiện có.",
    "answer_bot_all_done": "Điều đó hữu ích 👍",
    "answer_bot_ask_another_question": "Hỏi một câu hỏi khác 🔁",
    "answer_bot_ask_something_else": "Hỏi một điều khác 🔁",
    "answer_bot_connect_to_an_agent": "Kết nối với đại lý 👤",
    "answer_bot_did_that_help": "Câu trả lời đó có giúp ích không hay bạn đang tìm kiếm điều khác?",
    "answer_bot_followup_expectations_prefix": "Nhóm sẽ trả lời bạn về vấn đề này.",
    "answer_bot_get_more_help": "Được trợ giúp thêm 🌐",
    "answer_bot_get_more_help_followup": "Đường liên kết này có thể giúp:",
    "answer_bot_i_have_another_question": "Tôi có câu hỏi khác 🔁",
    "answer_bot_im_not_done_yet": "Tôi vẫn chưa xong 🔁",
    "answer_bot_keep_waiting": "Không, tôi vẫn cần giúp đỡ 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "Bạn có thể viết lại câu hỏi của mình hoặc được trợ giúp thêm",
    "answer_bot_looping_first_no_answer": "Rất tiếc, do là người máy nên tôi không thể tìm thấy câu trả lời cho điều đó.",
    "answer_bot_looping_first_no_answer_followup": "Bạn có thể viết lại câu hỏi của mình hoặc nói chuyện với nhóm của chúng tôi",
    "answer_bot_looping_no_answer": "Rất tiếc, do là người máy nên tôi không thể tìm thấy câu trả lời cho điều đó. Sau đây là những việc bạn có thể làm",
    "answer_bot_more_answers": "Hiển thị thêm cho tôi 👀",
    "answer_bot_no_answer_found": "Rất tiếc, chúng tôi không có câu trả lời nhanh cho điều đó. Sau đây là một số lựa chọn khác.",
    "answer_bot_resolve_followup": "Bạn luôn có thể trả lời ở đây nếu có bất kỳ điều gì khác xảy ra.",
    "answer_bot_talk_to_person": "Nói chuyện với một người 👤",
    "answer_bot_that_answered_my_question": "Điều đó đã trả lời câu hỏi của tôi 👍",
    "answer_bot_thats_it": "Vậy là xong 👍",
    "answer_bot_wait_for_the_team": "Đợi nhóm 💬",
    "answerbot_ask_for_more_context": "Chắc chắn. Trong khi chờ đợi, bạn có thể chia sẻ thêm thông tin chi tiết về tình huống hoặc câu hỏi của mình không? Nó sẽ giúp chúng tôi tìm câu trả lời nhanh hơn và tôi có thể tìm thêm thông tin cho bạn.",
    "answerbot_introduce_sneaky_answer": "Cảm ơn vì bối cảnh bổ sung đó. Tôi tìm thấy một số thông tin mới có thể giúp ích cho bạn.",
    "article_search_hint_text": "Tìm kiếm các bài viết...",
    "ask_a_question": "Đặt câu hỏi",
    "ask_a_question_placeholder": "Đặt câu hỏi...",
    "ask_a_question_with_ellipsis": "Đặt câu hỏi...",
    "ask_another_question_with_ellipsis": "Đặt một câu hỏi khác...",
    "ask_for_the_team_if_needed": "Yêu cầu nhóm nếu cần",
    "ask_me_a_question": "Đặt câu hỏi cho tôi",
    "ask_our_bot_a_question": "Đặt câu hỏi cho bot của chúng tôi",
    "avatar": "Ảnh đại diện",
    "avatars": "Ảnh đại diện",
    "bot": "Bot",
    "bot_answers_instantly": "Bot trả lời ngay lập tức",
    "bot_expectation_text": "Bot của chúng tôi có thể trả lời nhiều câu hỏi phổ biến",
    "bot_intro_default_part_1": "Chào bạn! Đây là bot đang nói. Tôi ở đây để trả lời câu hỏi của bạn, nhưng bạn sẽ luôn có thể chọn nói chuyện với nhóm của chúng tôi.",
    "bot_intro_default_part_2": "Vì sao bạn lại ở đây hôm nay?",
    "bot_is_still_thinking": "Bot vẫn đang suy nghĩ...",
    "bot_is_thinking": "Bot đang suy nghĩ...",
    "browse_collections": "Xem qua các bộ sưu tập",
    "chat_with_us": "Trò chuyện với chúng tôi",
    "check_later_for_updates": "Hãy kiểm tra lại sau để biết các bản cập nhật.",
    "checklist_card_first_step": "Bước đầu tiên",
    "checklist_card_next_step": "Bước tiếp theo",
    "checklist_completed": "Đã hoàn thành",
    "checklist_in_progress_time_remaining_plural": "Còn khoảng {minutes} phút",
    "checklist_in_progress_time_remaining_singular": "Còn khoảng 1 phút",
    "checklist_mark_step_as_complete": "Đánh dấu là đã hoàn thành",
    "checklist_not_started_time_remaining_plural": "Khoảng {minutes} phút",
    "checklist_not_started_time_remaining_singular": "Khoảng 1 phút",
    "checklist_number_of_steps_plural": "{number_of_steps} bước",
    "checklist_number_of_steps_progress_plural": "{steps_completed} trong {total_steps} đã xong",
    "checklist_number_of_steps_progress_singular": "1 trong số {total_steps} đã hoàn thành",
    "checklist_number_of_steps_singular": "1 bước",
    "checklist_sender_name": "Được chia sẻ bởi {name}",
    "checklist_step_action_button": "Nút hành động bước",
    "checklist_step_completed": "Đã hoàn thành",
    "contact_support": "Liên hệ với bộ phận hỗ trợ",
    "contact_us": "Liên hệ với chúng tôi",
    "continue_the_conversation": "Tiếp tục cuộc trò chuyện",
    "conversation_card_office_hours_header": "Thời gian chúng tôi sẽ trực tuyến trở lại",
    "conversation_card_reply_time_header": "Thời gian hồi âm thông thường của chúng tôi",
    "conversation_card_reply_time_header_m5": "Thời gian trả lời thông thường",
    "conversation_card_unknown_response_time": "Chúng tôi sẽ hồi âm sớm nhất có thể",
    "conversation_part_sample_text": "Xin chào, tôi có thể giúp gì? Bạn sẽ luôn có tùy chọn để nói chuyện với nhóm của chúng tôi nếu cần.",
    "conversations_space_title": "Hội thoại",
    "create_a_ticket": "Tạo vé",
    "currently_no_help_articles": "Hiện tại không có bài viết trợ giúp nào.",
    "customer_sends_their_first_message": "Khách hàng gửi tin nhắn đầu tiên của họ...",
    "expected_response_delay_10800": "chưa đầy 3 giờ",
    "expected_response_delay_120": "chưa đầy 2 phút",
    "expected_response_delay_1200": "chưa đầy 20 phút",
    "expected_response_delay_14400": "chưa đầy 4 giờ",
    "expected_response_delay_172800": "2 ngày",
    "expected_response_delay_180": "chưa đầy 3 phút",
    "expected_response_delay_1800": "chưa đầy 30 phút",
    "expected_response_delay_18000": "chưa đầy 5 giờ",
    "expected_response_delay_21600": "chưa đầy 6 giờ",
    "expected_response_delay_240": "chưa đầy 4 phút",
    "expected_response_delay_259200": "3 ngày",
    "expected_response_delay_300": "chưa đầy 5 phút",
    "expected_response_delay_345600": "4 ngày",
    "expected_response_delay_3600": "chưa đầy 1 giờ",
    "expected_response_delay_43200": "chưa đầy 12 giờ",
    "expected_response_delay_432000": "5 ngày",
    "expected_response_delay_518400": "6 ngày",
    "expected_response_delay_60": "chưa đầy 1 phút",
    "expected_response_delay_600": "chưa đầy 10 phút",
    "expected_response_delay_604800": "chưa đầy 1 tuần",
    "expected_response_delay_7200": "chưa đầy 2 giờ",
    "expected_response_delay_86400": "1 ngày",
    "expected_response_delay_900": "chưa đầy 15 phút",
    "expected_response_delay_unknown": "Ngay khi có thể",
    "extra_labels_count": "+ {labels_count} nhãn",
    "for_best_results_provide_detail": "Để có kết quả tốt nhất, hãy cung cấp càng nhiều chi tiết càng tốt",
    "generating_answer": "Đang tạo câu trả lời...",
    "generic_triage_default": "Chúng tôi chỉ cần thêm một chút thông tin để kết nối bạn với người phù hợp",
    "get_an_instant_answer": "Nhận câu trả lời ngay lập tức",
    "get_help": "Được trợ giúp",
    "go_to": "Vào {app_name}",
    "help_space_title": "Trợ giúp",
    "home_header_mobile_hello_firstname": "Xin chào, {firstname}.",
    "home_header_mobile_hello_there": "Xin chào",
    "home_space_title": "Nhà",
    "how_can_we_help": "Chúng tôi có thể giúp được gì cho bạn?",
    "how_would_you_rate_this": "Bạn đánh giá thế nào về điều này?",
    "learn_more": "Tìm hiểu thêm",
    "message_placeholder": "Tin nhắn...",
    "messages_space_title": "Tin nhắn",
    "messenger_bot_reply_time": "Bot của chúng tôi sẽ trả lời ngay lập tức",
    "messenger_settings_introduction": "Chúng tôi có thể giúp được gì cho bạn?",
    "multiple_articles": "{total_articles} Bài viết",
    "multiple_collections": "{total_collection} bộ sưu tập",
    "new_conversation": "Hội thoại mới",
    "news": "Tin tức",
    "news_empty_title": "Chưa có tin tức",
    "news_latest_subtitle": "Từ nhóm {team_name}",
    "news_latest_title": "Mới nhất",
    "news_older_title": "Cũ hơn",
    "news_space_title": "Tin tức",
    "newsfeed": "Nguồn cấp tin tức",
    "no_articles_to_display": "Chưa có bài viết nào",
    "no_help_articles_to_display": "Không có bài viết trợ giúp",
    "no_results_for_searchterm": "Không có kết quả cho {searchTerm}",
    "no_tasks": "Không có nhiệm vụ",
    "no_tasks_available_currently": "Hiện tại không có nhiệm vụ nào khả dụng.",
    "ok_the_team_typically_replies_in_day": "Ok, nhóm thường trả lời trong một ngày.",
    "ok_the_team_typically_replies_in_hours": "Ok, nhóm thường trả lời trong vài giờ.",
    "ok_the_team_typically_replies_in_minutes": "Ok, nhóm thường trả lời trong vài phút.",
    "ok_the_team_typically_replies_in_under_n_hours": "Ok, nhóm thường trả lời trong dưới {hours} giờ.",
    "ok_the_team_typically_replies_in_under_n_min": "Ok, nhóm thường trả lời trong dưới {minutes} phút.",
    "ok_the_team_typically_replies_in_week": "Ok, nhóm thường trả lời trong một tuần.",
    "our_bot_and_team_can_help": "Bot và nhóm của chúng tôi có thể trợ giúp",
    "our_bot_answers_instantly": "Bot của chúng tôi trả lời ngay lập tức",
    "powered_by_intercom": "Được cung cấp bởi Intercom",
    "previous_bot_hands_over": "Nội dung từ bot trước...",
    "proactive_expected_response_delay_10800": "Thường trả lời trong vòng 3 giờ",
    "proactive_expected_response_delay_120": "Thường trả lời trong vòng 2 phút",
    "proactive_expected_response_delay_1200": "Thường trả lời trong vòng 20 phút",
    "proactive_expected_response_delay_14400": "Thường trả lời trong vòng 4 giờ",
    "proactive_expected_response_delay_172800": "Thường trả lời trong 2 ngày",
    "proactive_expected_response_delay_180": "Thường trả lời trong vòng 3 phút",
    "proactive_expected_response_delay_1800": "Thường trả lời trong vòng 30 phút",
    "proactive_expected_response_delay_18000": "Thường trả lời trong vòng 5 giờ",
    "proactive_expected_response_delay_21600": "Thường trả lời trong vòng 6 giờ",
    "proactive_expected_response_delay_240": "Thường trả lời trong vòng 4 phút",
    "proactive_expected_response_delay_259200": "Thường trả lời trong 3 ngày",
    "proactive_expected_response_delay_300": "Thường trả lời trong vòng 5 phút",
    "proactive_expected_response_delay_345600": "Thường trả lời trong 4 ngày",
    "proactive_expected_response_delay_3600": "Thường trả lời trong vòng 1 giờ",
    "proactive_expected_response_delay_43200": "Thường trả lời trong 12 giờ",
    "proactive_expected_response_delay_432000": "Thường trả lời trong 5 ngày",
    "proactive_expected_response_delay_518400": "Thường trả lời trong 6 ngày",
    "proactive_expected_response_delay_60": "Thường trả lời trong vòng 1 phút",
    "proactive_expected_response_delay_600": "Thường trả lời trong vòng 10 phút",
    "proactive_expected_response_delay_604800": "Thường trả lời trong vòng 1 tuần",
    "proactive_expected_response_delay_7200": "Thường trả lời trong vòng 2 giờ",
    "proactive_expected_response_delay_86400": "Thường trả lời trong 1 ngày",
    "proactive_expected_response_delay_900": "Thường trả lời trong chưa đầy 15 phút",
    "proactive_expected_response_delay_unknown": "Sẽ trả lời sớm nhất có thể",
    "profile_location": "{time} ở {location}",
    "recent_ticket": "Vé gần đây",
    "recent_tickets": "Vé gần đây",
    "search_browse_empty_state_text": "Rất tiếc, chúng tôi không thể tìm thấy bất cứ kết quả nào cho điều đó. Thử viết lại nội dung tìm kiếm của bạn.",
    "search_for_help": "Tìm kiếm sự trợ giúp",
    "see_all": "Xem tất cả",
    "see_all_conversations": "Xem tất cả các cuộc trò chuyện của bạn",
    "see_previous_conversations": "Xem các cuộc trò chuyện trước đây của bạn",
    "send": "Gửi",
    "send_a_message": "Gửi tin nhắn…",
    "send_a_message_reply_time": "Thời gian trả lời thông thường là",
    "send_us_a_message": "Gửi tin nhắn cho chúng tôi",
    "set_expectations_bot_email_header": "Bạn sẽ nhận được hồi âm ở đây và trong email của bạn:",
    "set_expectations_bot_office_hours_header": "Thời gian nhóm sẽ trở lại:",
    "set_expectations_bot_sms_header": "Bạn sẽ nhận được hồi âm ở đây và bằng tin nhắn SMS:",
    "set_expectations_replies_to_email": "Bạn sẽ nhận được câu trả lời tại đây và tới <b>{user_email}</b>.",
    "set_expectations_replies_to_email_without_bold_text": "Bạn sẽ nhận được trả lời ở đây và tới {user_email}",
    "set_expectations_team_reply_asap": "Nhóm sẽ trả lời sớm nhất có thể",
    "shared_by": "Được chia sẻ bởi",
    "show_less": "Hiển thị ít hơn",
    "single_article": "1 Bài viết",
    "single_collection": "1 bộ sưu tập",
    "source": "Nguồn",
    "sources": "Nguồn",
    "start_a_conversation": "Bắt đầu trò chuyện...",
    "start_conversation": "Bắt đầu một hội thoại",
    "still_generating_answer": "Vẫn đang tạo câu trả lời...",
    "still_thinking": "Vẫn đang suy nghĩ...",
    "still_working_on_it": "Vẫn đang tìm hiểu...",
    "tasks_space_title": "Nhiệm vụ",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} sẽ trả lời sớm nhất có thể",
    "teammate_changes_team_assignment_of_a_conversation": "Đồng đội đã thay đổi nhiệm vụ trò chuyện...",
    "tell_us_what_you_need": "Hãy cho chúng tôi biết bạn cần giúp gì và bot của chúng tôi sẽ cố gắng đáp ứng",
    "the_team_can_help_if_needed": "Nhóm có thể trợ giúp nếu cần",
    "the_team_typically_replies_in_10800": "Nhóm thường trả lời trong vòng 3 giờ.",
    "the_team_typically_replies_in_120": "Nhóm thường trả lời trong vòng 2 phút",
    "the_team_typically_replies_in_1200": "Nhóm thường trả lời trong vòng 20 phút.",
    "the_team_typically_replies_in_14400": "Nhóm thường trả lời trong vòng 4 giờ.",
    "the_team_typically_replies_in_172800": "Nhóm thường trả lời trong 2 ngày.",
    "the_team_typically_replies_in_180": "Nhóm thường trả lời trong vòng 3 phút",
    "the_team_typically_replies_in_1800": "Nhóm thường trả lời trong vòng 30 phút.",
    "the_team_typically_replies_in_18000": "Nhóm thường trả lời trong vòng 5 giờ.",
    "the_team_typically_replies_in_21600": "Nhóm thường trả lời trong vòng 6 giờ.",
    "the_team_typically_replies_in_240": "Nhóm thường trả lời trong vòng 4 phút",
    "the_team_typically_replies_in_259200": "Nhóm thường trả lời trong 3 ngày",
    "the_team_typically_replies_in_300": "Nhóm thường trả lời trong vòng 5 phút.",
    "the_team_typically_replies_in_345600": "Nhóm thường trả lời trong 4 ngày",
    "the_team_typically_replies_in_3600": "Nhóm thường trả lời trong vòng 1 giờ.",
    "the_team_typically_replies_in_43200": "Nhóm thường trả lời trong vòng 12 giờ",
    "the_team_typically_replies_in_432000": "Nhóm thường trả lời trong 5 ngày",
    "the_team_typically_replies_in_518400": "Nhóm thường trả lời trong 6 ngày",
    "the_team_typically_replies_in_60": "Nhóm thường trả lời trong vòng 1 phút",
    "the_team_typically_replies_in_600": "Nhóm thường trả lời trong vòng 10 phút.",
    "the_team_typically_replies_in_604800": "Nhóm thường trả lời trong vòng 1 tuần.",
    "the_team_typically_replies_in_7200": "Nhóm thường trả lời trong vòng 2 giờ.",
    "the_team_typically_replies_in_86400": "Nhóm thường trả lời trong 1 ngày.",
    "the_team_typically_replies_in_900": "Nhóm thường trả lời trong chưa đầy 15 phút",
    "the_team_typically_replies_in_day": "Nhóm thường trả lời trong một ngày.",
    "the_team_typically_replies_in_hours": "Nhóm thường trả lời trong vài giờ.",
    "the_team_typically_replies_in_minutes": "Nhóm thường trả lời trong vài phút.",
    "the_team_typically_replies_in_under_n_hours": "Nhóm thường trả lời trong dưới {hours} giờ.",
    "the_team_typically_replies_in_under_n_min": "Nhóm thường trả lời trong dưới {minutes} phút.",
    "the_team_typically_replies_in_unknown": "Nhóm sẽ trả lời sớm nhất có thể",
    "the_team_typically_replies_in_week": "Nhóm thường trả lời trong một tuần.",
    "thinking": "Đang suy nghĩ...",
    "tickets_cta_text": "Bạn có câu hỏi về vé này?",
    "tickets_have_a_question": "Bạn có câu hỏi về vấn đề này?",
    "tickets_space_title": "Vé",
    "time_future_1h": "1 giờ",
    "time_future_2h": "2 giờ",
    "time_future_30m": "30 phút",
    "time_future_3h": "3 giờ",
    "time_future_day_0": "Thứ Hai",
    "time_future_day_1": "Thứ Ba",
    "time_future_day_2": "Thứ Tư",
    "time_future_day_3": "Thứ Năm",
    "time_future_day_4": "Thứ Sáu",
    "time_future_day_5": "Thứ Bảy",
    "time_future_day_6": "Chủ Nhật",
    "time_future_later_today": "Trong hôm nay",
    "time_future_next_week": "Tuần tới",
    "time_future_tomorrow": "Ngày mai",
    "try_use_different_keywords": "Hãy thử sử dụng các từ khóa khác nhau hoặc nhập câu hỏi đầy đủ của bạn",
    "upfront_collection_form_close_out": "Bạn có thể trở lại đây bất kỳ lúc nào để xem hồi âm và gửi nội dung cập nhật.",
    "upfront_collection_form_introduction": "Chia sẻ một số ngữ cảnh để giúp nhóm phản hồi tốt hơn và nhanh hơn.",
    "upfront_collection_form_submission_confirmation": "Cám ơn bạn đã gửi ngữ cảnh đó. Để tiết kiệm thời gian sau này, bạn có thể bổ sung thêm thông tin bây giờ.",
    "upfront_collection_form_subtitle": "Gửi cho nhóm một số ngữ cảnh",
    "upfront_collection_form_subtitle_submitted": "Họ sẽ luôn ghi nhớ ngữ cảnh này",
    "upfront_collection_form_title": "Được trợ giúp nhanh hơn",
    "upfront_collection_form_title_submitted": "Đã gửi đến nhóm!",
    "view_all": "Xem tất cả",
    "view_conversation": "Xem cuộc trò chuyện",
    "we_are_here_to_help": "Chúng tôi có mặt để trợ giúp",
    "we_run_on_intercom": "Phát triển bởi Intercom",
    "when_a_teammate_changes_the_state_of_a_conversation": "Khi một đồng đội thay đổi trạng thái của cuộc trò chuyện...",
    "working_on_it": "Đang tìm hiểu...",
    "you_are_all_up_to_date": "Bản tin nóng!",
    "your_checklists": "Danh sách kiểm tra của bạn",
    "your_conversations": "Cuộc trò chuyện của bạn",
    "your_recent_conversations": "Cuộc trò chuyện gần đây",
    "your_recent_messages": "Tin nhắn gần đây"
  },
  "zh-TW": {
    "a_day": "一天",
    "a_few_hours": "幾小時",
    "a_few_minutes": "幾分鐘",
    "a_week": "一週",
    "active_state": "在線上",
    "ai_answer": "AI 答案",
    "ai_answer_information": "AI 答案、資訊",
    "ai_bot": "AI 機器人",
    "almost_there": "快找到答案了...",
    "answer": "答案",
    "answer_bot_acknowledge_duplicate_content": "您已取得相同回覆，這已是最佳解答。",
    "answer_bot_all_done": "很有幫助 👍",
    "answer_bot_ask_another_question": "詢問其他問題 🔁",
    "answer_bot_ask_something_else": "詢問其他事情 🔁",
    "answer_bot_connect_to_an_agent": "聯絡客服專員 👤",
    "answer_bot_did_that_help": "解答是否有幫助？您還需要其他協助嗎？",
    "answer_bot_followup_expectations_prefix": "團隊成員會與您聯絡討論這件事。",
    "answer_bot_get_more_help": "尋求更多協助 🌐",
    "answer_bot_get_more_help_followup": "這個連結或許會有幫助：",
    "answer_bot_i_have_another_question": "我還有一個問題 🔁",
    "answer_bot_im_not_done_yet": "我還沒問完 🔁",
    "answer_bot_keep_waiting": "沒幫助，我仍需要真人協助 👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "您可以重新表述您的問題，或尋求更多幫助",
    "answer_bot_looping_first_no_answer": "抱歉，我是 Bot，找不到這個疑問的解答。",
    "answer_bot_looping_first_no_answer_followup": "您可以重新表述您的問題，或告訴我們的團隊",
    "answer_bot_looping_no_answer": "抱歉，我是 Bot，找不到這個疑問的解答。您可以試試以下方法",
    "answer_bot_more_answers": "顯示更多 👀",
    "answer_bot_no_answer_found": "抱歉，我們無法快速解答這個問題。以下提供幾個其他選項。",
    "answer_bot_resolve_followup": "如果想到其他疑問，您隨時可以在這裡回覆。",
    "answer_bot_talk_to_person": "與真人交談 👤",
    "answer_bot_that_answered_my_question": "這回答了我的問題 👍",
    "answer_bot_thats_it": "很有幫助 👍",
    "answer_bot_wait_for_the_team": "等候團隊 💬",
    "answerbot_ask_for_more_context": "當然。等待的同時，您能否詳述您的情況或疑問？這有助於我們更快找到答案，我也能為您找到更多資訊。",
    "answerbot_introduce_sneaky_answer": "感謝您提供額外的背景資訊。我找到了一些可能對您有幫助的新資訊。",
    "article_search_hint_text": "搜尋文章...",
    "ask_a_question": "詢問問題",
    "ask_a_question_placeholder": "詢問問題…",
    "ask_a_question_with_ellipsis": "請問一個問題...",
    "ask_another_question_with_ellipsis": "再問一個問題...",
    "ask_for_the_team_if_needed": "如有需要，請詢問團隊",
    "ask_me_a_question": "請問我一個問題",
    "ask_our_bot_a_question": "請問我們的機器人一個問題",
    "avatar": "頭像",
    "avatars": "頭像",
    "bot": "機器人",
    "bot_answers_instantly": "機器人將即時答覆",
    "bot_expectation_text": "我們的 Bot 可以回答許多常見問題",
    "bot_intro_default_part_1": "您好！我是 Bot。我可以為您解答問題，不過您隨時可以選擇與我們的團隊聯絡。",
    "bot_intro_default_part_2": "請問您今天想詢問什麼呢？",
    "bot_is_still_thinking": "機器人仍在思考...",
    "bot_is_thinking": "機器人正在思考...",
    "browse_collections": "瀏覽收藏",
    "chat_with_us": "與我們聊天",
    "check_later_for_updates": "請稍後再回來查看更新內容。",
    "checklist_card_first_step": "第一步",
    "checklist_card_next_step": "下一步",
    "checklist_completed": "已完成",
    "checklist_in_progress_time_remaining_plural": "還剩大約 {minutes} 分鐘",
    "checklist_in_progress_time_remaining_singular": "剩餘約 1 分鐘",
    "checklist_mark_step_as_complete": "標記為已完成",
    "checklist_not_started_time_remaining_plural": "大約 {minutes} 分鐘",
    "checklist_not_started_time_remaining_singular": "約 1 分鐘",
    "checklist_number_of_steps_plural": "{number_of_steps} 個步驟",
    "checklist_number_of_steps_progress_plural": "已完成 {steps_completed} 個步驟，共 {total_steps} 個",
    "checklist_number_of_steps_progress_singular": "1/{total_steps} 完成",
    "checklist_number_of_steps_singular": "1 個步驟",
    "checklist_sender_name": "分享者：{name}",
    "checklist_step_action_button": "步驟動作按鈕",
    "checklist_step_completed": "已完成",
    "contact_support": "聯絡支援部門",
    "contact_us": "聯絡我們",
    "continue_the_conversation": "繼續這一場對話",
    "conversation_card_office_hours_header": "恢復上線時",
    "conversation_card_reply_time_header": "我們通常的回覆時間",
    "conversation_card_reply_time_header_m5": "一般回覆時間",
    "conversation_card_unknown_response_time": "我們將盡快回覆",
    "conversation_part_sample_text": "嗨，我能提供什麼幫助？如果需要，您可以隨時選擇與我們的團隊聯絡。",
    "conversations_space_title": "對話",
    "create_a_ticket": "建立支援申請單",
    "currently_no_help_articles": "目前沒有可用的幫助文章。",
    "customer_sends_their_first_message": "客戶傳送他們的第一條訊息...",
    "expected_response_delay_10800": "不到 3 小時",
    "expected_response_delay_120": "2 分鐘內",
    "expected_response_delay_1200": "不到 20 分鐘",
    "expected_response_delay_14400": "不到 4 小時",
    "expected_response_delay_172800": "2 天",
    "expected_response_delay_180": "3 分鐘內",
    "expected_response_delay_1800": "不到 30 分鐘",
    "expected_response_delay_18000": "不到 5 小時",
    "expected_response_delay_21600": "不到 6 小時",
    "expected_response_delay_240": "4 分鐘內",
    "expected_response_delay_259200": "3 天",
    "expected_response_delay_300": "不到 5 分鐘",
    "expected_response_delay_345600": "4 天",
    "expected_response_delay_3600": "不到 1 小時",
    "expected_response_delay_43200": "12 小時內",
    "expected_response_delay_432000": "5 天",
    "expected_response_delay_518400": "6 天",
    "expected_response_delay_60": "1 分鐘內",
    "expected_response_delay_600": "不到 10 分鐘",
    "expected_response_delay_604800": "不到 1 星期",
    "expected_response_delay_7200": "不到 2 小時",
    "expected_response_delay_86400": "1 天",
    "expected_response_delay_900": "不到 15 分鐘",
    "expected_response_delay_unknown": "盡快",
    "extra_labels_count": "+ {labels_count} 個標籤",
    "for_best_results_provide_detail": "請提供盡可能多的詳細資訊以獲取最佳結果",
    "generating_answer": "正在產生答案...",
    "generic_triage_default": "我們需要一些資訊以為您接洽適當的人員",
    "get_an_instant_answer": "立即獲取答案",
    "get_help": "取得協助",
    "go_to": "前往{app_name}",
    "help_space_title": "說明",
    "home_header_mobile_hello_firstname": "你好，{firstname}。",
    "home_header_mobile_hello_there": "嗨，你好",
    "home_space_title": "主頁",
    "how_can_we_help": "我們能提供什麼幫助？",
    "how_would_you_rate_this": "您對此有何評價？",
    "learn_more": "瞭解更多",
    "message_placeholder": "發訊息...",
    "messages_space_title": "訊息",
    "messenger_bot_reply_time": "我們的機器人會立即回覆",
    "messenger_settings_introduction": "我們能提供什麼幫助？",
    "multiple_articles": "{total_articles} 篇文章",
    "multiple_collections": "{total_collection} 個系列",
    "new_conversation": "新對話",
    "news": "新聞",
    "news_empty_title": "暫時沒有新聞",
    "news_latest_subtitle": "來自{team_name}團隊",
    "news_latest_title": "最新消息",
    "news_older_title": "較舊消息",
    "news_space_title": "新聞",
    "newsfeed": "新聞動態",
    "no_articles_to_display": "還沒有文章",
    "no_help_articles_to_display": "沒有幫助文章",
    "no_results_for_searchterm": "「{searchTerm}」沒有結果",
    "no_tasks": "沒有任務",
    "no_tasks_available_currently": "目前沒有可進行的任務。",
    "ok_the_team_typically_replies_in_day": "好的，團隊通常會在一天內回覆。",
    "ok_the_team_typically_replies_in_hours": "好的，團隊通常會在幾小時內回覆。",
    "ok_the_team_typically_replies_in_minutes": "好的，團隊通常會在幾分鐘內回覆。",
    "ok_the_team_typically_replies_in_under_n_hours": "好的，團隊通常會在 {hours} 小時內回覆。",
    "ok_the_team_typically_replies_in_under_n_min": "好的，團隊通常會在 {minutes} 分鐘內回覆。",
    "ok_the_team_typically_replies_in_week": "好的，團隊通常會在一週內回覆。",
    "our_bot_and_team_can_help": "我們的機器人和團隊可以提供幫助",
    "our_bot_answers_instantly": "我們的機器人將即時答覆",
    "powered_by_intercom": "由 Intercom 提供技術支持",
    "previous_bot_hands_over": "來自前一個 Bot 的內容……",
    "proactive_expected_response_delay_10800": "通常在 3 小時內回覆",
    "proactive_expected_response_delay_120": "通常在 2 分鐘內回覆",
    "proactive_expected_response_delay_1200": "通常在 20 分鐘內回覆",
    "proactive_expected_response_delay_14400": "通常在 4 小時內回覆",
    "proactive_expected_response_delay_172800": "通常在 2 天內回覆",
    "proactive_expected_response_delay_180": "通常在 3 分鐘內回覆",
    "proactive_expected_response_delay_1800": "通常在 30 分鐘內回覆",
    "proactive_expected_response_delay_18000": "通常在 5 小時內回覆",
    "proactive_expected_response_delay_21600": "通常在 6 小時內回覆",
    "proactive_expected_response_delay_240": "通常在 4 分鐘內回覆",
    "proactive_expected_response_delay_259200": "通常在 3 天內回覆",
    "proactive_expected_response_delay_300": "通常在 5 分鐘內回覆",
    "proactive_expected_response_delay_345600": "通常在 4 天內回覆",
    "proactive_expected_response_delay_3600": "通常在 1 小時內回覆",
    "proactive_expected_response_delay_43200": "通常在 12 小時內回覆",
    "proactive_expected_response_delay_432000": "通常在 5 天內回覆",
    "proactive_expected_response_delay_518400": "通常在 6 天內回覆",
    "proactive_expected_response_delay_60": "通常在 1 分鐘內回覆",
    "proactive_expected_response_delay_600": "通常在 10 分鐘內回覆",
    "proactive_expected_response_delay_604800": "通常在 1 週內回覆",
    "proactive_expected_response_delay_7200": "通常在 2 小時內回覆",
    "proactive_expected_response_delay_86400": "通常在 1 天內回覆",
    "proactive_expected_response_delay_900": "通常在 15 分鐘內回覆",
    "proactive_expected_response_delay_unknown": "將盡快回覆",
    "profile_location": "{time} 於 {location}",
    "recent_ticket": "最近的支援申請單",
    "recent_tickets": "最近的支援申請單",
    "search_browse_empty_state_text": "抱歉，找不到字詞的任何結果。請修改您的搜尋關鍵字。",
    "search_for_help": "搜尋說明",
    "see_all": "檢視全部",
    "see_all_conversations": "查看您的所有對話",
    "see_previous_conversations": "查看您之前的對話",
    "send": "傳送",
    "send_a_message": "傳送訊息…",
    "send_a_message_reply_time": "一般回覆時間為",
    "send_us_a_message": "傳訊息給我們",
    "set_expectations_bot_email_header": "您將於此處以及電子郵件中收到回覆：",
    "set_expectations_bot_office_hours_header": "團隊返回時間：",
    "set_expectations_bot_sms_header": "您將於此處以及 SMS 收到回覆：",
    "set_expectations_replies_to_email": "您將在此處與 <b>{user_email}</b> 收到回覆。",
    "set_expectations_replies_to_email_without_bold_text": "您將在此處與 {user_email} 收到回覆",
    "set_expectations_team_reply_asap": "團隊將盡快回覆。",
    "shared_by": "分享者",
    "show_less": "顯示較少",
    "single_article": "1 篇文章",
    "single_collection": "1 個系列",
    "source": "來源",
    "sources": "來源",
    "start_a_conversation": "開始對話",
    "start_conversation": "開始對話",
    "still_generating_answer": "仍在產生答案...",
    "still_thinking": "仍在思考...",
    "still_working_on_it": "仍在努力解答...",
    "tasks_space_title": "任務",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} 將盡快回覆",
    "teammate_changes_team_assignment_of_a_conversation": "隊友已變更對話分配...",
    "tell_us_what_you_need": "告訴我們您需要什麼幫助，我們的 bot 將盡力為您解答",
    "the_team_can_help_if_needed": "如有需要，團隊專員也可為您提供協助",
    "the_team_typically_replies_in_10800": "團隊通常會在 3 小時內回覆",
    "the_team_typically_replies_in_120": "團隊通常會在 2 分鐘內回覆",
    "the_team_typically_replies_in_1200": "團隊通常會在 20 分鐘內回覆",
    "the_team_typically_replies_in_14400": "團隊通常會在 4 小時內回覆",
    "the_team_typically_replies_in_172800": "團隊通常會在 2 天內回覆",
    "the_team_typically_replies_in_180": "團隊通常會在 3 分鐘內回覆",
    "the_team_typically_replies_in_1800": "團隊通常會在 30 分鐘內回覆",
    "the_team_typically_replies_in_18000": "團隊通常會在 5 小時內回覆",
    "the_team_typically_replies_in_21600": "團隊通常會在 6 小時內回覆",
    "the_team_typically_replies_in_240": "團隊通常會在 4 分鐘內回覆",
    "the_team_typically_replies_in_259200": "團隊通常會在 3 天內回覆",
    "the_team_typically_replies_in_300": "團隊通常會在 5 分鐘內回覆",
    "the_team_typically_replies_in_345600": "團隊通常會在 4 天內回覆",
    "the_team_typically_replies_in_3600": "團隊通常會在 1 小時內回覆",
    "the_team_typically_replies_in_43200": "團隊通常會在 12 小時內回覆",
    "the_team_typically_replies_in_432000": "團隊通常會在 5 天內回覆",
    "the_team_typically_replies_in_518400": "團隊通常會在 6 天內回覆",
    "the_team_typically_replies_in_60": "團隊通常會在 1 分鐘內回覆",
    "the_team_typically_replies_in_600": "團隊通常會在 10 分鐘內回覆",
    "the_team_typically_replies_in_604800": "團隊通常會在 1 週內回覆",
    "the_team_typically_replies_in_7200": "團隊通常會在 2 小時內回覆",
    "the_team_typically_replies_in_86400": "團隊通常會在 1 天內回覆",
    "the_team_typically_replies_in_900": "團隊通常會在 15 分鐘內回覆",
    "the_team_typically_replies_in_day": "團隊通常會在一天內回覆。",
    "the_team_typically_replies_in_hours": "團隊通常會在幾小時內回覆。",
    "the_team_typically_replies_in_minutes": "團隊通常會在幾分鐘內回覆。",
    "the_team_typically_replies_in_under_n_hours": "團隊通常會在 {hours} 小時內回覆。",
    "the_team_typically_replies_in_under_n_min": "團隊通常會在 {minutes} 分鐘內回覆。",
    "the_team_typically_replies_in_unknown": "團隊將盡快回覆",
    "the_team_typically_replies_in_week": "團隊通常會在一週內回覆。",
    "thinking": "正在思考...",
    "tickets_cta_text": "對此支援申請單有疑問嗎？",
    "tickets_have_a_question": "對這個問題有疑問嗎？",
    "tickets_space_title": "支援申請單",
    "time_future_1h": "1 小時",
    "time_future_2h": "2 小時",
    "time_future_30m": "30 分鐘",
    "time_future_3h": "3 小時",
    "time_future_day_0": "星期一",
    "time_future_day_1": "星期二",
    "time_future_day_2": "星期三",
    "time_future_day_3": "星期四",
    "time_future_day_4": "星期五",
    "time_future_day_5": "星期六",
    "time_future_day_6": "星期日",
    "time_future_later_today": "今天稍晚",
    "time_future_next_week": "下星期",
    "time_future_tomorrow": "明天",
    "try_use_different_keywords": "請嘗試使用不同的關鍵字，或輸入完整的問題",
    "upfront_collection_form_close_out": "您隨時可以返回查看回應並傳送更新資訊。",
    "upfront_collection_form_introduction": "分享一些背景資訊，幫助團隊更快更好地回應。",
    "upfront_collection_form_submission_confirmation": "感謝您傳送相關背景資訊。為節省稍後的處理時間，歡迎您現在加入更多細節。",
    "upfront_collection_form_subtitle": "傳送一些背景資訊給團隊",
    "upfront_collection_form_subtitle_submitted": "他們會記住這些背景資訊",
    "upfront_collection_form_title": "更快得到協助",
    "upfront_collection_form_title_submitted": "已發送給團隊！",
    "view_all": "檢視全部",
    "view_conversation": "檢視對話",
    "we_are_here_to_help": "我們隨時為您提供協助",
    "we_run_on_intercom": "我們在 Intercom 上運行",
    "when_a_teammate_changes_the_state_of_a_conversation": "當組員改變對話狀態時......",
    "working_on_it": "正在努力解答...",
    "you_are_all_up_to_date": "您已瞭解所有最新消息了！",
    "your_checklists": "您的檢查清單",
    "your_conversations": "您的對話",
    "your_recent_conversations": "最近的對話",
    "your_recent_messages": "最近的訊息"
  },
  "zh-CN": {
    "a_day": "一天",
    "a_few_hours": "几小时",
    "a_few_minutes": "几分钟",
    "a_week": "一周",
    "active_state": "在线",
    "ai_answer": "人工智能答案",
    "ai_answer_information": "人工智能答案，信息",
    "ai_bot": "人工智能机器人",
    "almost_there": "快找到答案了……",
    "answer": "回答",
    "answer_bot_acknowledge_duplicate_content": "您已看到此内容，但这是可用的最佳答案。",
    "answer_bot_all_done": "这很有用 👍",
    "answer_bot_ask_another_question": "提问其他问题 🔁",
    "answer_bot_ask_something_else": "问点别的内容 🔁",
    "answer_bot_connect_to_an_agent": "联系代理人 👤",
    "answer_bot_did_that_help": "该回答有帮助吗，还是您在寻找其他信息？",
    "answer_bot_followup_expectations_prefix": "团队将就此事与您联系。",
    "answer_bot_get_more_help": "获得更多帮助 🌐",
    "answer_bot_get_more_help_followup": "此链接可能会有所帮助：",
    "answer_bot_i_have_another_question": "我还有一个问题 🔁",
    "answer_bot_im_not_done_yet": "我还没说完 🔁",
    "answer_bot_keep_waiting": "不，我仍然需要帮助👤",
    "answer_bot_looping_bot_only_first_no_answer_followup": "您可以重新表述您的问题，或获取其他帮助",
    "answer_bot_looping_first_no_answer": "抱歉，作为机器人，我找不到答案。",
    "answer_bot_looping_first_no_answer_followup": "您可以重新表述您的问题，或与我们的团队交谈",
    "answer_bot_looping_no_answer": "抱歉，作为机器人，我找不到答案。您可以采取以下操作",
    "answer_bot_more_answers": "向我显示更多 👀",
    "answer_bot_no_answer_found": "抱歉，我们没有快速的答案。以下是其他一些选项。",
    "answer_bot_resolve_followup": "如果有其他问题，您可以随时在这里回复。",
    "answer_bot_talk_to_person": "与真人交谈 👤",
    "answer_bot_that_answered_my_question": "这回答了我的问题 👍",
    "answer_bot_thats_it": "就是这样 👍",
    "answer_bot_wait_for_the_team": "等待团队 💬",
    "answerbot_ask_for_more_context": "当然。当我们在等待时，您能否分享有关您的情况或问题的更多详细信息？这将帮助我们更快地找到答案，我也可能为您找到更多信息。",
    "answerbot_introduce_sneaky_answer": "感谢您提供额外的背景信息。我找到了一些可能对您有帮助的新信息。",
    "article_search_hint_text": "搜索文章……",
    "ask_a_question": "提出问题",
    "ask_a_question_placeholder": "提出问题…",
    "ask_a_question_with_ellipsis": "请问一个问题……",
    "ask_another_question_with_ellipsis": "再问一个问题……",
    "ask_for_the_team_if_needed": "如果需要请询问团队",
    "ask_me_a_question": "向我提问",
    "ask_our_bot_a_question": "向我们的机器人提问",
    "avatar": "头像",
    "avatars": "头像",
    "bot": "机器",
    "bot_answers_instantly": "机器人会立即回答",
    "bot_expectation_text": "我们的机器人可以回答许多常见问题",
    "bot_intro_default_part_1": "您好！这是机器人在说话。我会在此回答您的问题，但您可以随时选择与我们的团队交谈。",
    "bot_intro_default_part_2": "您今天有什么事吗？",
    "bot_is_still_thinking": "机器人仍在思考……",
    "bot_is_thinking": "机器人正在思考……",
    "browse_collections": "浏览收藏",
    "chat_with_us": "与我们聊天",
    "check_later_for_updates": "请稍后再回来查看更新。",
    "checklist_card_first_step": "第一步",
    "checklist_card_next_step": "下一步",
    "checklist_completed": "已完成",
    "checklist_in_progress_time_remaining_plural": "还剩大约 {minutes} 分钟",
    "checklist_in_progress_time_remaining_singular": "还剩大约 1 分钟",
    "checklist_mark_step_as_complete": "标记为已完成",
    "checklist_not_started_time_remaining_plural": "大约 {minutes} 分钟",
    "checklist_not_started_time_remaining_singular": "大约 1 分钟",
    "checklist_number_of_steps_plural": "{number_of_steps} 步",
    "checklist_number_of_steps_progress_plural": "已完成 {steps_completed} 步，共 {total_steps} 步",
    "checklist_number_of_steps_progress_singular": "已完成 1 步，共 {total_steps} 步",
    "checklist_number_of_steps_singular": "1 步",
    "checklist_sender_name": "共享者：{name}",
    "checklist_step_action_button": "步骤操作按钮",
    "checklist_step_completed": "已完成",
    "contact_support": "联系支持人员",
    "contact_us": "联系我们",
    "continue_the_conversation": "继续对话",
    "conversation_card_office_hours_header": "我们重新上线的时间",
    "conversation_card_reply_time_header": "我们平常的回复时间",
    "conversation_card_reply_time_header_m5": "平常的回复时间",
    "conversation_card_unknown_response_time": "我们会尽快回复",
    "conversation_part_sample_text": "您好，我能提供什么帮助？如有需要，您可以随时选择与我们的团队交谈。",
    "conversations_space_title": "对话",
    "create_a_ticket": "创建工单",
    "currently_no_help_articles": "目前没有可用的帮助文章。",
    "customer_sends_their_first_message": "客户发送他们的第一条消息……",
    "expected_response_delay_10800": "3 小时内",
    "expected_response_delay_120": "2 分钟之内",
    "expected_response_delay_1200": "20 分钟内",
    "expected_response_delay_14400": "4 小时内",
    "expected_response_delay_172800": "2 天",
    "expected_response_delay_180": "3 分钟之内",
    "expected_response_delay_1800": "30 分钟内",
    "expected_response_delay_18000": "5 小时内",
    "expected_response_delay_21600": "6 小时内",
    "expected_response_delay_240": "4 分钟之内",
    "expected_response_delay_259200": "3 天",
    "expected_response_delay_300": "5 分钟内",
    "expected_response_delay_345600": "4 天",
    "expected_response_delay_3600": "1 小时内",
    "expected_response_delay_43200": "12 小时内",
    "expected_response_delay_432000": "5 天",
    "expected_response_delay_518400": "6 天",
    "expected_response_delay_60": "1 分钟之内",
    "expected_response_delay_600": "10 分钟内",
    "expected_response_delay_604800": "1 周内",
    "expected_response_delay_7200": "2 小时内",
    "expected_response_delay_86400": "1 天",
    "expected_response_delay_900": "15 分钟内",
    "expected_response_delay_unknown": "我们会尽快",
    "extra_labels_count": "+ {labels_count} 个标签",
    "for_best_results_provide_detail": "为获得最佳结果，请提供尽可能多的详细信息",
    "generating_answer": "正在生成答案……",
    "generic_triage_default": "我们只需要更多信息即可将您与合适的人联系起来",
    "get_an_instant_answer": "即时获取答案",
    "get_help": "获取帮助",
    "go_to": "前往 {app_name}",
    "help_space_title": "帮助",
    "home_header_mobile_hello_firstname": "{firstname}，您好。",
    "home_header_mobile_hello_there": "您好",
    "home_space_title": "主页",
    "how_can_we_help": "我们能提供什么帮助？",
    "how_would_you_rate_this": "您如何为此内容评分？",
    "learn_more": "了解更多",
    "message_placeholder": "发消息...",
    "messages_space_title": "消息",
    "messenger_bot_reply_time": "我们的机器人会立即回复",
    "messenger_settings_introduction": "我们能提供什么帮助？",
    "multiple_articles": "{total_articles} 篇文章",
    "multiple_collections": "{total_collection} 个收藏",
    "new_conversation": "新对话",
    "news": "新闻",
    "news_empty_title": "尚无消息",
    "news_latest_subtitle": "来自团队 {team_name}",
    "news_latest_title": "最新",
    "news_older_title": "较旧",
    "news_space_title": "新闻",
    "newsfeed": "新闻推送",
    "no_articles_to_display": "暂无文章",
    "no_help_articles_to_display": "无帮助文章",
    "no_results_for_searchterm": "没有关于“{searchTerm}”的结果",
    "no_tasks": "没有任务",
    "no_tasks_available_currently": "目前没有任务。",
    "ok_the_team_typically_replies_in_day": "好的，团队通常在一天之内回复。",
    "ok_the_team_typically_replies_in_hours": "好的，团队通常在几小时内回复。",
    "ok_the_team_typically_replies_in_minutes": "好的，团队通常在几分钟内回复。",
    "ok_the_team_typically_replies_in_under_n_hours": "好的，团队通常在 {hours} 小时内回复。",
    "ok_the_team_typically_replies_in_under_n_min": "好的，团队通常在 {minutes} 分钟内回复。",
    "ok_the_team_typically_replies_in_week": "好的，团队通常在一周之内回复。",
    "our_bot_and_team_can_help": "我们的机器人和团队可以提供帮助",
    "our_bot_answers_instantly": "我们的机器人会立即回答",
    "powered_by_intercom": "由 Intercom 提供支持",
    "previous_bot_hands_over": "来自上一个 bot 的内容……",
    "proactive_expected_response_delay_10800": "通常在 3 小时之内回复",
    "proactive_expected_response_delay_120": "通常在 2 分钟之内回复",
    "proactive_expected_response_delay_1200": "通常在 20 分钟之内回复",
    "proactive_expected_response_delay_14400": "通常在 4 小时之内回复",
    "proactive_expected_response_delay_172800": "通常在 2 天之内回复",
    "proactive_expected_response_delay_180": "通常在 3 分钟之内回复",
    "proactive_expected_response_delay_1800": "通常在 30 分钟之内回复",
    "proactive_expected_response_delay_18000": "通常在 5 小时之内回复",
    "proactive_expected_response_delay_21600": "通常在 6 小时之内回复",
    "proactive_expected_response_delay_240": "通常在 4 分钟之内回复",
    "proactive_expected_response_delay_259200": "通常在 3 天之内回复",
    "proactive_expected_response_delay_300": "通常在 5 分钟之内回复",
    "proactive_expected_response_delay_345600": "通常在 4 天之内回复",
    "proactive_expected_response_delay_3600": "通常在 1 小时之内回复",
    "proactive_expected_response_delay_43200": "通常在 12 小时之内回复",
    "proactive_expected_response_delay_432000": "通常在 5 天之内回复",
    "proactive_expected_response_delay_518400": "通常在 6 天之内回复",
    "proactive_expected_response_delay_60": "通常在 1 分钟之内回复",
    "proactive_expected_response_delay_600": "通常在 10 分钟之内回复",
    "proactive_expected_response_delay_604800": "通常在 1 周之内回复",
    "proactive_expected_response_delay_7200": "通常在 2 小时之内回复",
    "proactive_expected_response_delay_86400": "通常在 1 天之内回复",
    "proactive_expected_response_delay_900": "通常在 15 分钟之内回复",
    "proactive_expected_response_delay_unknown": "会尽快回复",
    "profile_location": "{time} 在 {location}",
    "recent_ticket": "最近的工单",
    "recent_tickets": "最近的工单",
    "search_browse_empty_state_text": "抱歉，我们找不到任何相应的结果。请尝试改写您的搜索字词。",
    "search_for_help": "搜索帮助",
    "see_all": "查看所有对话",
    "see_all_conversations": "查看您的所有对话",
    "see_previous_conversations": "查看您以前的对话",
    "send": "发送",
    "send_a_message": "发送消息…",
    "send_a_message_reply_time": "平常的回复时间是",
    "send_us_a_message": "向我们发送消息",
    "set_expectations_bot_email_header": "您会在这里以及在电子邮件中收到回复：",
    "set_expectations_bot_office_hours_header": "团队恢复服务的时间：",
    "set_expectations_bot_sms_header": "您会在这里以及通过短信收到回复：",
    "set_expectations_replies_to_email": "您将在此处 (<b>{user_email}</b>) 收到回复。",
    "set_expectations_replies_to_email_without_bold_text": "您将在此处 ({user_email}) 收到回复",
    "set_expectations_team_reply_asap": "团队会尽快回复。",
    "shared_by": "共享者：",
    "show_less": "显示更少",
    "single_article": "1 篇文章",
    "single_collection": "1 个收藏",
    "source": "来源",
    "sources": "来源",
    "start_a_conversation": "开始对话",
    "start_conversation": "开始新对话",
    "still_generating_answer": "仍在生成答案……",
    "still_thinking": "仍在思考……",
    "still_working_on_it": "仍在努力解答……",
    "tasks_space_title": "任务",
    "team_name_proactive_expected_response_delay_unknown": "{app_name} 会尽快回复",
    "teammate_changes_team_assignment_of_a_conversation": "队友已更改了对话分配...",
    "tell_us_what_you_need": "告诉我们您需要什么帮助，我们的机器人将尽力回答",
    "the_team_can_help_if_needed": "团队可以根据需要提供帮助",
    "the_team_typically_replies_in_10800": "团队通常在 3 小时内回复",
    "the_team_typically_replies_in_120": "团队通常在 2 分钟之内回复",
    "the_team_typically_replies_in_1200": "团队通常在 20 分钟内回复",
    "the_team_typically_replies_in_14400": "团队通常在 4 小时内回复",
    "the_team_typically_replies_in_172800": "团队通常在 2 天内回复",
    "the_team_typically_replies_in_180": "团队通常在 3 分钟之内回复",
    "the_team_typically_replies_in_1800": "团队通常在 30 分钟内回复",
    "the_team_typically_replies_in_18000": "团队通常在 5 小时内回复",
    "the_team_typically_replies_in_21600": "团队通常在 6 小时内回复",
    "the_team_typically_replies_in_240": "团队通常在 4 分钟之内回复",
    "the_team_typically_replies_in_259200": "团队通常在 3 天内回复",
    "the_team_typically_replies_in_300": "团队通常在 5 分钟内回复",
    "the_team_typically_replies_in_345600": "团队通常在 4 天内回复",
    "the_team_typically_replies_in_3600": "团队通常在 1 小时内回复",
    "the_team_typically_replies_in_43200": "团队通常在 12 小时内回复",
    "the_team_typically_replies_in_432000": "团队通常在 5 天内回复",
    "the_team_typically_replies_in_518400": "团队通常在 6 天内回复",
    "the_team_typically_replies_in_60": "团队通常在 1 分钟之内回复",
    "the_team_typically_replies_in_600": "团队通常在 10 分钟内回复",
    "the_team_typically_replies_in_604800": "团队通常在 1 周内回复",
    "the_team_typically_replies_in_7200": "团队通常在 2 小时内回复",
    "the_team_typically_replies_in_86400": "团队通常在 1 天内回复",
    "the_team_typically_replies_in_900": "团队通常在 15 分钟内回复",
    "the_team_typically_replies_in_day": "团队通常在一天之内回复。",
    "the_team_typically_replies_in_hours": "团队通常在几小时内回复。",
    "the_team_typically_replies_in_minutes": "团队通常在几分钟内回复。",
    "the_team_typically_replies_in_under_n_hours": "团队通常在 {hours} 小时内回复。",
    "the_team_typically_replies_in_under_n_min": "团队通常在 {minutes} 分钟内回复。",
    "the_team_typically_replies_in_unknown": "团队会尽快回复",
    "the_team_typically_replies_in_week": "团队通常在一周之内回复。",
    "thinking": "正在思考……",
    "tickets_cta_text": "对这个工单有疑问吗？",
    "tickets_have_a_question": "对这个问题有疑问吗？",
    "tickets_space_title": "工单",
    "time_future_1h": "1 小时",
    "time_future_2h": "2 小时",
    "time_future_30m": "30 分钟",
    "time_future_3h": "3 小时",
    "time_future_day_0": "周一",
    "time_future_day_1": "周二",
    "time_future_day_2": "周三",
    "time_future_day_3": "周四",
    "time_future_day_4": "周五",
    "time_future_day_5": "周六",
    "time_future_day_6": "周日",
    "time_future_later_today": "今天晚些时候",
    "time_future_next_week": "下周",
    "time_future_tomorrow": "明天",
    "try_use_different_keywords": "尝试使用不同的关键字或输入您的完整问题",
    "upfront_collection_form_close_out": "您可以随时返回此处查看回应并发送更新。",
    "upfront_collection_form_introduction": "分享一些背景信息，以帮助团队更好更快地做出回应。",
    "upfront_collection_form_submission_confirmation": "感谢您发送此背景信息。为了节省以后的时间，欢迎您立即添加更多详细信息。",
    "upfront_collection_form_subtitle": "向团队发送一些背景信息",
    "upfront_collection_form_subtitle_submitted": "他们会牢记这些背景信息",
    "upfront_collection_form_title": "更快获得帮助",
    "upfront_collection_form_title_submitted": "已发送给团队！",
    "view_all": "查看全部",
    "view_conversation": "查看对话",
    "we_are_here_to_help": "我们随时为您提供帮助",
    "we_run_on_intercom": "我们在 Intercom 上运行",
    "when_a_teammate_changes_the_state_of_a_conversation": "当队友更改对话状态时……",
    "working_on_it": "正在努力解答……",
    "you_are_all_up_to_date": "你们都深陷其中！",
    "your_checklists": "您的清单",
    "your_conversations": "您的对话",
    "your_recent_conversations": "最近的对话",
    "your_recent_messages": "最近的消息"
  }
};